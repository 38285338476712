import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { PartSearchComponent } from '../part-search/part-search.component';

@Component({
  selector: 'app-spare-commission-payment',
  templateUrl: './spare-commission-payment.component.html',
  styleUrls: ['./spare-commission-payment.component.scss']
})
export class SpareCommissionPaymentComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public partRows: any = [];
  public vehComPaymentLists: any = [];
  public maxDate:any = new Date();
  constructor(
    private dialogRef: MatDialogRef<SpareCommissionPaymentComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.inlineFilter = {
        COMM_PAY_NO: '',
        COMM_PAY_DT:'',
        SAL_THR_ASC_ID : '',
        SaleThroughASCName:'',
        COMM_AMT:'',
        INV_TYPE: '',
        PAY_VCHR_ID:'',
      };
    }
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  searchList() {
      this.getSpareCommissionPaymentList();
  }
  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'tvsInvoiceSearch') {
      if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
      this.selected[0].TYPE = this.part.invoiceTypeId;
    }
    let closeObj = { 
      obj: this.selected[0],
      typeId: 411
    };
    this.dialogRef.close(closeObj);
  }

  //grn-tvs invoice popup
  getSpareCommissionPaymentList() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Booking From Date is empty');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Booking to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
    reqObj.COMM_PAY_NO  = this.part.COMM_PAY_NO  ? Number(this.part.COMM_PAY_NO ) : null;
    reqObj.SAL_THR_ASC_ID  = this.part.SAL_THR_ASC_ID  ? Number(this.part.SAL_THR_ASC_ID ) : null;
    reqObj.BOOKING_DATE_FROM = fromDateFormat;
    reqObj.BOOKING_DATE_TO = toDateFormat;
    reqObj.INV_TYPE =2;
    this.vehComPaymentLists = [];
    this.masterServices.post(reqObj, '/Accounts/SearchVehicleSpareCommissionPaymentList').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.vehComPaymentLists = resp.vehComPaymentList;
      } else if (resp && resp.statusCode == 204) {
        this.vehComPaymentLists = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}


import { EnquiryDet, followupList } from 'src/app/api-services/dto/enquiry';
import { SubLedger, GeneralLedger } from 'src/app/api-services/dto/ledger-det'
import { VehicleAccessory,RackBinDetail } from 'src/app/api-services/dto/booking-accessory'
import { VehicleScheme } from 'src/app/api-services/dto/vehicle-scheme'

export class Booking
{
    DEALER_ID: number;
    BRANCH_ID: number;
    BOOKING_ID: number;
    BOOKING_NO: number;
    BOOKING_DATE: any;
    RefCustName: string;
    BOOKING_TYPE: boolean;  
    ENQUIRY_ID: number;
    ENQUIRY_NO: number;
    FIN_YEAR: number;
    QUOTATION_ID?: number;  
    QUOTATION_NO?: number;
    REF_CUST_ID?: number;
    REF_CUST_TY_ID: number;
    REF_CUSTOMER_TYPE:any;
    RTO_ID?: number;
    CUSTOMER_ID: number;
    END_USER_ID?: number;
    IS_FORMC?: boolean;
    CUSTOMER_NAME:any;
    CUSTOMER_TYPE:any;
    BOOKING_TYPE_DESC:any;
   // FOLLOWUP_ENQ: boolean;
    SALE_MODE: number;
    CUST_MNG_INSR: boolean;
    INS_TYPE_ID?: number;
    INS_COMP_ID?: number;
    INSR_CNOTE_GIVEN: boolean;
    CUST_MNGD_REG: boolean;
    SPL_REG_REQ: string;
    REGIS_TYPE_ID?: number;
    DEL_WOUT_REG: boolean;
    DLR_DEL_DATE?: any;
    CUST_DEL_DATE?: any;
  //  HpDocList: BookingHpDocChkDO[];
    BookingTaxList: BookingPartTax[];
    TOT_UNIT_PRICE?: number;
    TOT_LINE_DISC?: number;
    TOT_TAX_VAL?: number;
    TOT_SUB_TOT1?: number;
    TOT_SUB_TOT2?: number;
    TOT_BILL_DISC?: number;
    TOT_BRDG_AMT?: number;
    TOT_EXCH_AMT?: number;
    TOT_HP_AMT?: number;
    TOT_ADV_AMT?: number;
    TOT_RFND_AMT?: number;
    DISC_VALUE?: number;
    TOT_ACC_CHRGS?: number;
    TOT_REG_CHRGS?: number;
    TOT_AMT_PAID?: number;
    TOT_AMT_DUE?: number;
    TOT_AMT_PNDG?: number;
    COMMENTS: string; 
    SLF_ARNGD_HP: boolean;
    REASON_ID?: number;
    REMARKS: string; 
    REASON_CANCELLATION:any;
    COMM_PAID: boolean;
    BULK_INVOICE_ID?: number;
    STATUS: number;
    STATUS_DESC: any;
    CREATED_BY?: number;
    CREATED_ON?: Date;
    MODIFIED_BY?: number;
    MODIFIED_ON?: Date;
    ACTIVE: boolean;
    ExchangeCompanyId?: number;
    Type: number;
    COUNTRY_CODE:string;
    SL_CODE: number; 
    IS_THRU_MULTI_INVOICE?: boolean; 
    INTERNET_ENQUIRY_ID: number;
    BOOK_AMT?: number 
    ENQUIRY_MODE: string;
    RefCustomerType:string;
    ROW_STATE: any;
    QUOTATION_DATE?:any;
    ENQUIRY_DATE?:any;
    END_USER:any;
    INTERNET_BOOKING_AMT?:number;
    BookingPartList: BookingPart[];
  //   CustomerDetails: CustomerDO;
    // EndUserDetails: CustomerDO;
    BookPartDetailsList: BookingPartDetails[];
    ExchangeBookList: ExchangeBooking[];
    BookHPDetails: BookingHPDetail;
    SelfHPDetails: BookingSelfHp;
    // Voucher: VoucherDO;
    
    // BookingExchangePart: ExchangeBookingDO;
    // VehicleInvoiceList: VehicleInvoiceDO[];
    // VehicleAllotment: VehicleAllotmentDO;
    // AllotmentDetilList: AllotmentDetailDO[];
    // OpenValueList: OpenValueDo[];
}

export class BookingPartDetails
{
    RunningNo: number;
    DEALER_ID: number;
    BRANCH_ID: number;
    BOOK_PART_ID: number;
    PART_ID: string;
    DESCRIPTION: string;
    MODEL_ID: string;
    UNIT_PRICE: number;
    STOCK_AVAILABLE: number;
    STOCK_IN_TRANSIT?: number;
    BOOKED_QTY: number;
    RESV_QTY?: number;
    ALLOTED_QTY?: number;
    PENDING_QTY?: number;
    EX_SHRM_PRICE?: number;
    SCHEME_DISC?: number;
    DISC_VALUE?: number;
    MASTER_DISC?: number;
    MANUAL_DISC?: number;
    TAX?: number;
    CGST?: number ;
    SGST?: number ;
    IGST?: number;
    UTGST?: number;
    CESS?: number;
    HSN_CODE: string;
    HSN_ID?: number;
    TAX_AMOUNT: number;
    ACC_CHARGES?: number;
    REG_CHARGES?: number;
    INS_CHARGES?: number;
    OTH_CHARGES?: number;
    ACCESS_LOCATION_ID?: number;
    TOTAL_AMOUNT: number;
   // ModelPart: ModelPartDO;
    ROW_STATE: any;
    VEHICLE_ID: number;
    STATUS: number;
    ALLOT_VEH_ID?: number;
    PART_DESC: string;
    SERIES: string;
    VEHICLE_SCH_ID?:number;
    ROW_SELECT?:Boolean;
    BookingPartTaxList: BookingPartTax[];
    BookingSchemeList: BookingScheme[];
    modelPartList: Array<any> = [];
   // vehModelList: Array<any> = [];
    AppVehicleSchemeList: VehicleScheme[];
    SelectedVehicleSchemeList: VehicleScheme[];
    AccessoryList: VehicleAccessory[];
    // AllotmentList: AllotmentDetailDO[];
    
}

export class BookingPart
{
    DEALER_ID: number;
    BRANCH_ID: number;
    BOOK_PART_ID: number;
    BOOKING_ID: number;
    SCHEME_DISC?: number;
    PART_ID: string;
    BOOKED_QTY?: number;
    RESV_QTY?: number ;
    ALLOTED_QTY?: number;
    PENDING_QTY?: number;
    IN_TAX_BASE?: number;
    DISC_VALUE?: number;
    MANUAL_DISC?: number;
    TAX: number | null;
    EX_SHRM_PRICE?: number;
    ACC_CHARGES?: number;
    REG_CHARGES?: number;
    INS_CHARGES?: number ;
    OTH_CHARGES?: number;
    ACCESS_LOCATION_ID: number;
    ROW_STATE: any;
    VEHICLE_ID: number;
    BookingPartTaxList: BookingPartTax[];
    BookingSchemeList: BookingScheme[];
 VehicleSchemeList: VehicleScheme[];
}

export class BookingPartTax
{
    DEALER_ID: number;
    BRANCH_ID: number;
    BOOK_PART_TAX_ID: number;
    BOOK_PART_ID: number;
    DESCRIPTION: string;
    TAX_PERC: number;
    APPLIED_AMT: number;
    ROW_STATE: any;
    TaxValue?: number;
    TAX_TYPE_ID?: number;
}

export class BookingScheme
{
    DEALER_ID: number;
    BRANCH_ID: number;
    BOOK_SCHEME_ID: number;
    BOOK_PART_ID: number;
    VEHICLE_SCH_ID: number;
    ROW_STATE: any;
}

export class BookingHPDetail
{
    DEALER_ID: number;
    BRANCH_ID: number;
    BOOK_HP_DET_ID: number;
    BOOKING_ID: number;
    HP_SCH_ID?: number ;
    HP_AMT_APPR?: number;
    EMI: number ;
    PERIOD: number ;
    PERIOD_TYPE: boolean;
    INTEREST?: number;
    HP_AMT_REQ?: number;
    HP_DOC_STAT: boolean;
    REL_NOTE: boolean;
    REL_NOTE_NO: string;
    REL_NOTE_DT?: any;
    PROPOS_DATE?: any;
    REASON_ID?: number;
    DOWN_PAYMENT: number;
    FIN_COMP_ID: number;
    ROW_STATE: any; 
}

export class BookingSelfHp
{
    DEALER_ID: number;
    BRANCH_ID: number;
    BOOKING_ID: number;
    IS_DLR_HP_REL_NOT_RECV: boolean;
    LOAN_AMOUNT?: number;
    COMPANY: string;
    EMI?: number;
    NO_OF_PERIOD?: number;
    INTEREST?: number;
    PERIOD_TYPE: any;
    DOWN_PAYMENT?: number;
    IS_DLR_HP: boolean;
    IS_SELF_HP: boolean;
    HP_SCHEME: string;
    FIN_COMP_ID?: number;
    PROPOS_DATE?: Date ;
    REASON_ID?: number ;
    HP_SCH_ID?: number;
    REL_NOTE: boolean;
    REL_NOTE_NO: string;
    DOC_NAME: string;
    REL_NOTE_DT?: any;
    TOTAL_ADJ_VAL?: number;
    ROW_STATE: any;
    CountofSelfHP: number;
    DP_PRICE_LIST: number;
    DP_BAYAR?: number;
    IS_NEW_SCHEME?: boolean;
    DOWN_PAY_AMT?: number;   
}

export class ExchangeBooking
{
    DEALER_ID: number;
    BRANCH_ID: number;
    EXCH_BOOK_VEH_ID: number;
    BOOKING_ID: number;
    BROKER_ID?: any;
    MODEL_ID: string;
    LOCATION_ID?: number;
    REG_NO: string;
    DEALER_MANAGED: boolean;
    EX_DOC_CHK: boolean;
    MANU_YEAR?: number;
    FRAME_NO: string;
    ENGINE_NO: string;
    RCVD_DATE?: Date;
    EXPECT_PRICE?: number;
    OFFER_PRICE?: number;
    BEST_PRICE?:number;
    BRDG_AMT?: number;
    EX_NOTE_NO?: number;
    EX_NOTE_AMT?: number;
    DELI_NOTE_NO?: number;
    DELI_NOTE_GNR: boolean;
    DELI_NOTE_STAT: boolean;
    DEL_NT_TO_CUST: boolean;
    DECL_STMT: string;
    EX_DOC_HANDED: boolean;
    STATUS: boolean;
    REASON_ID?: number;
    TOTAL_ADJ_VAL?: number;
    EMPLOYEE_ID?: number;
    ExchangeDocumentList: ExchangeBookingDoc[];
}

export class ExchangeBookingDoc
{
    DEALER_ID: number;
    BRANCH_ID: number;
    EXCH_BOOK_DOC_CHK_ID: number;
    EXCH_BOOK_VEH_ID: number;
    DOCUMENT_ID: number;
    RECVD_FROM_CUST: boolean;
    GIVEN_TO_BROK: boolean;
    Active: boolean;
    DocumentReceived: boolean;
}

export class BookingSearch
{
    DEALER_ID: number;
    BRANCH_ID: number;
    BOOKING_ID: number;
    BOOKING_DATE: Date;
    ENQUIRY_DATE: Date;
    ENQUIRY_MODE_ID: number;
    COMBINE_ENQUIRYMODE_ID: string;
    ENQUIRY_MODE: string;
    REMARKS: string;
    BOOKING_DATE_FROM?: Date;
    BOOKING_DATE_TO?: Date;
    CUST_NAME: string;
    BOOKING_NO: number;
    ENQUIRY_NO: number;
    CustomerType: string;
    CUSTOMER_ID: number;
    QUOTATION_NO?: number;
    QUOTATION_DATE?: Date;
    STATUS: number;
    VoucherValue: number;
    ENQUIRY_ID: number;
    QUOTATION_ID?: number;
    END_USER_NAME: string;
    STATUS_DESC: string;
    CUST_TY_ID: number;
    DISC_VALUE: number;
    REF_CUST_NAME: string;
    BOOKED_QTY: number;
    FIN_YEAR: number;
    MOBILE_NO: string;
    CONTACT_NO_2: string;
}

export class VehicleAllotment
{
    DEALER_ID: number;
    BRANCH_ID: number;
    ALLOTMENT_ID: number;
    ALLOTMENT_NO: number;
    ALLOTMENT_DATE: Date;
    BOOKING_ID: number;
    CUSTOMER_ID?: number;
    END_USER_ID?: number;
    STATUS: number;
    REMARKS: string;
    INVOICED: boolean;
    PRINT_COUNT: number;
    CREATED_BY?: number;
    CREATED_ON?: Date;
    MODIFIED_BY?: number;
    MODIFIED_ON?: Date;
    ACTIVE: boolean;
    FIN_YEAR: number;
    ROW_STATE: string;
    AllotedVehicleList: AllotmentDetail[];
    BOOKLET_NO: string;
    REASON: string;
    REASON_ID: number;
    IS_THRU_MULTI_INVOICE?: boolean;
    SelectedVehicleList: Vehicle[];
}

export class AllotmentDetail
{
    RunningId: number;
    DEALER_ID: number;
    BRANCH_ID: number;
    ALLOT_VEH_ID: number;
    ALLOTMENT_ID: number;
    ALLOTMENT_NO: number;
    ALLOTMENT_DATE: Date;
    BOOK_PART_ID: number;
    STORAGE_LOC: number;
    PART_ID: string;
    VEHICLE_ID: number;
    VEHICLE_INVOICED: boolean;
    ACCESS_SEL: boolean;
    LIKE_DT_OF_REGIS?: Date;
    ACC_FIT_DATE?: Date;
    DELIVERY_DATE?: Date;
    DESCRIPTION: string;
    BOOKLET_NO: string;
    REASON: string;
    REASON_ID?: number;
    AMC_ID?: number;
    TOT_AMC_AMT?: number;
    AMCTax?: number;
    AMCPRICE?: number;
    WARRANTY_PERIOD: number;
    COMP_WARRANTY_PERIOD: number;
    ITEM_TYPE_ID?: number;
    tax_category?: number;
    BOOKING_NO: number;
    BOOKING_DATE:Date;
    BOOKED_QTY:number;
    BOOKING_VALUE:number;
    ALLOTED_QTY: number;
    PART_DESC:string;
    CUST_NAME:string;
    CUSTOMER_TYPE:string;
    CUSTOMER_ID: number;
    CUST_CAT_ID: number;
}

export class AllotmentAccessoryDO
{
    DEALER_ID: number;
    BRANCH_ID: number;
    ALLOT_ACCESS_ID: number;
    ALLOT_VEH_ID: number;
    MANUAL_DISC?: number;
    SPARE_PART_ID?: number;
    QUANTITY?: number;
    RATE?: number;
    SCHEME_DISC?: number;
    DISC_VALUE?: number;
    TAX?: number;
    TAX_VALUE: number;
    NON_TAX_VALUE: number;
    LAB_CHARGE: number;
    ALLOTED: boolean;
}

export class AllotmentPartStock
{
    DEALER_ID: number;
    BRANCH_ID: number;
    PartId: string;
    PartDesc: string;
    AvailableQty: number;
    ResevedQty: number;
    AllotedQty: number;
    VehicleList: Vehicle[];
    AllotedVehicleList: Vehicle[];
}



export class Vehicle {
    REG_NO: string;
    MODEL_ID: string;
    PART_ID: string;
    ENGINE_NO: string;
    FRAME_NO: string;
    PROD_MON: string;
    PROD_YR: string;
    KEY_NO: string;
    Service_Booklet_No: string;
    SALE_DATE: any;
    STATUS: string;
    INS_COMP_ID: string;
    ROW_STATE: string;
    DEALER_ID:number;
    GRN_DATE?:Date;
    VEHICLE_ID:number;
    DESCRIPTION:string;
}

/**
 * Created BY HMSPL 
 * Last Modified By Balaji on 31/10/2018
 * Last Modified Description : Validating service appointment
 */
import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TabsetComponent } from 'ngx-bootstrap/tabs/tabset.component';
import { LoginService } from 'src/app/api-services/login.service';
import { ServiceApp, SeriveAppointFrameNoData, FollowUp, VehicleDO, CustomerDetails } from '../../../api-services/dto/service-appointment'
import { data } from '../../../api-services/utils/static-data'
import { ServiceAppoitmentService } from '../../../api-services/service-appoitment.service';
import { JobCardSearchComponent } from '../../../shared/job-card-search/job-card-search.component';
import { CommonService } from '../../../api-services/common.service';
import { ServiceReminderService } from 'src/app/api-services/service-reminder.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-service-appointment-create',
  templateUrl: './service-appointment-create.component.html',
  styleUrls: ['./service-appointment-create.component.scss']
})
export class ServiceAppointmentCreateComponent implements OnInit {
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  @Input() inputParam: any;
  @Output() emitDialogData = new EventEmitter();
  public lang: any;
  public serviceApp = new ServiceApp();
  public seriveAppointFrameNoData = new SeriveAppointFrameNoData();
  public vehicleDO = new VehicleDO();
  public customerDetails = new CustomerDetails();
  public followUpType: Array<any> = [];
  public JobTypeList: Array<any> = [];
  public stateParams: any = '';
  public isCreate: boolean = true;
  public isShowFollowup: boolean = false;
  public minDate =  new Date(new Date().setDate(new Date().getDate() + 0));
  public maxDate = new Date(new Date().setDate(new Date().getDate() + 2));
  public jcStatus = 0;
  public isShowPageLoader: boolean = false;
  public refFrameNo = '';
  public refReg = '';
  public mcat = 0;
  public  isapptcatempty = false;
  public leakages = [];
  // public followUpObj = new FollowUp();
  public appointDetails = {
    // APPOINT_NO: '',
    DATE_OF_CREATE: '',
    APPOINT_STATUS: '',
    APPOINT_CLOSURE_TIME: ''
  }
  public followUpHeader = [];
  public isDisablePsf: boolean = false;
  public isViewMode: boolean = false;
  public appId: any = '';
  public serviceList: Array<any> = [];
  public appointmentCatrogry: Array<any> = [];
  public empList: Array<any> = [];
  public followUpEmpList: Array<any> = [];
  constructor(private toastr: ToastrService, private router: Router,
    private route: ActivatedRoute, public dialog: MatDialog,
    private serviceAppoitmentService: ServiceAppoitmentService
    ,private loginService:LoginService, private serviceReminderService: ServiceReminderService) {
  }
  /**
   * Init function 
   */
  ngOnInit() {
 
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      this.followUpHeader = [
        {
          name: this.lang.SERVICES.followUpNo,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SERVICES.followUpDate + ' *',
          width: 'wmpx-150'
        },
        {
          name: this.lang.SERVICES.employeeBy + ' *',
          width: 'wmpx-200'
        },
        {
          name: this.lang.SERVICES.followUpType + ' *',
          width: 'wmpx-150'
        },
        {
          name: this.lang.SERVICES.followUpDetail + ' *',
          width: 'wmpx-200'
        },
        {
          name: this.lang.SERVICES.leakage + ' *',
          width: 'wmpx-200'
        },
        {
          name: this.lang.SERVICES.nextFollowUpDate,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SERVICES.rescheduleDate,
          width: 'wmpx-200'
        },
        {
          name: '',
          width: 'wmpx-50'
        }
      ];
      if (CommonService.getServAppData()) {
        let appData = CommonService.getServAppData();
        this.serviceList = appData.ServiceList;
        
        this.appointmentCatrogry = appData.AppointmentList;
        this.serviceApp.APT_CAT_ID = 2;
        this.empList = appData.EmployeeList.filter(key => {
          if (key.EMP_GROUP_ID == 2) {
            return key;
          }
        });
        this.followUpType = data.FollowUpType;
        this.leakages = data.leakages;
        this.serviceApp.FollowUp = [];
        if (this.inputParam && this.inputParam.frameNo) {
          this.serviceApp.FRAME_NO = this.inputParam.frameNo;
          this.onSearchFrameNoRegNo('frameNo', this.inputParam.frameNo);
          this.isDisablePsf = true;
        }
        /**
         * Get param details from the url
         */
        this.route.queryParams.subscribe(params => {
          this.stateParams = params;
          this.appId = params.appId;
          if (params.frameNo && params.jobTypeId && params.empId) {
            this.isCreate = true;
            this.serviceApp.FRAME_NO = params.frameNo;
            this.getFrameRegNoDetails('frameNo');
          } else {
            if (this.appId) {
              this.isCreate = false;
              this.getAppointmentByAppId();
              if (params.type == 'VIEW') {
                this.isViewMode = true;
              }
            } else {
              this.isCreate = true;
            }
          }
        });

        
      }
    }
  }
  AptCatValidation() {        
      if (this.serviceApp.APT_CAT_ID == 1) {
        this.toastr.error(this.lang.Message.SERVICE_APP.INVALID_APPT_CAT);
        this.serviceApp.APT_CAT_ID = this.mcat ; 
        this.mcat = this.mcat - 1;  
        this.isapptcatempty = true;
      }   
      else
      {
        this.isapptcatempty = false;
      }
  }
  /**
   * Search appointment no in the popup
   * @param {String} str 
   */
  onSearch(str) {
    let data = { key: str };
    const dialogRef = this.dialog.open(JobCardSearchComponent, {
      width: '1200px',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let refObj = result.obj;
        if (refObj && result.category == 'frameNo') {
          this.serviceApp.FRAME_NO = refObj.FRAME_NO;
          this.onSearchFrameNoRegNo(result.category, this.serviceApp.FRAME_NO);
        } else {
          if(result.REG_NO)
          {
          this.serviceApp.REGIS_NO = result.REG_NO;
          this.onSearchFrameNoRegNo(result.category, this.serviceApp.REGIS_NO);
          }
          else if(refObj.FRAME_NO)
          {
            result.category = 'frameNo';
            this.serviceApp.FRAME_NO = refObj.FRAME_NO;
            this.onSearchFrameNoRegNo(result.category, this.serviceApp.FRAME_NO);
           
          }
        }
      }
    });
  }
  /**
   * Get Vehicle Details based on the frame no and reg no
   * @param {String} category //Frame No or Reg No
   * @param {String} modelVal //Input Value
   */
  onSearchFrameNoRegNo(category, modelVal) {
    if (modelVal && category == 'frameNo') {
      this.refFrameNo = modelVal;
      this.getFrameRegNoDetails(category);
    }
    if (modelVal && category == 'regno') {
      this.refReg = modelVal;
      this.getFrameRegNoDetails(category);
    }
  }
  /**
   * Get appointment details based on appointment id
   */
  getAppointmentByAppId() {
    
    this.isShowPageLoader = true;
    let params = new HttpParams();
    params = params.set("DealerID", CommonService.getUserData().DEALER_ID)
      .set("BranchID", CommonService.getUserData().BRANCH_ID)
      .set("AppId", this.appId)
      .set("CountryCode", CommonService.getDealerData().COUNTRY_CODE)
      .set("UserId", CommonService.getUserData().USER_ID)
    this.serviceAppoitmentService.getAppointmentByAppId(params).subscribe((resp: any) => {
      if (resp && resp.data && resp.statusCode === 200) {
        this.serviceApp.FRAME_NO = resp.data.ServiceDetails.FRAME_NO;
        this.serviceApp.REGIS_NO = resp.data.ServiceDetails.REGIS_NO;
        this.serviceApp.JOB_TYPE_ID = resp.data.ServiceDetails.JOB_TYPE_ID;
        this.serviceApp.APPOINT_DATE = CommonService.setDateTime(resp.data.ServiceDetails.APPOINT_DATE);
        this.serviceApp.SERV_MODE_ID = resp.data.ServiceDetails.SERV_MODE_ID;
        this.serviceApp.APPOINTMENT_BY = parseInt(resp.data.ServiceDetails.APPOINTMENT_BY);
        this.serviceApp.APT_CAT_ID = resp.data.ServiceDetails.APT_CAT_ID;
        this.serviceApp.SERV_PREF = resp.data.ServiceDetails.SERV_PREF;
        this.serviceApp.APPOINTMENT_NO = resp.data.ServiceDetails.APPOINTMENT_NO ? resp.data.ServiceDetails.APPOINTMENT_NO : '';
        this.serviceApp.APPOINTMENT_ID = resp.data.ServiceDetails.APPOINTMENT_ID ? resp.data.ServiceDetails.APPOINTMENT_ID : '';
        this.serviceApp.CARE_CAMP_SCH_ID = resp.data.ServiceDetails.CARE_CAMP_SCH_ID ? resp.data.ServiceDetails.CARE_CAMP_SCH_ID : '';
        this.serviceApp.END_USER_ID = resp.data.ServiceDetails.END_USER_ID;
        this.serviceApp.CUSTOMER_ID = resp.data.ServiceDetails.CUSTOMER_ID;
        this.serviceApp.VEHICLE_ID = resp.data.ServiceDetails.VEHICLE_ID;
        this.serviceApp.REMARKS = resp.data.ServiceDetails.REMARKS;
        this.appointDetails.DATE_OF_CREATE = resp.data.ServiceDetails.CREATED_ON;
        this.jcStatus = resp.data.ServiceDetails.JOB_STATUS;
        if (resp.data.ServiceDetails.STATUS > 0) {
          this.isViewMode = true;
        }
        //View Fields
        this.appointDetails.APPOINT_STATUS = resp.data.ServiceDetails.STATUS;
        if (resp.data.CustomerDet) {
          this.customerDetails.CUSTOMER_ID = resp.data.CustomerDet.CUSTOMER_ID;
          this.customerDetails.CUST_NAME = resp.data.CustomerDet.CUST_NAME;
          this.customerDetails.CUSTOMER_TYPE = resp.data.CustomerDet.CUSTOMER_TYPE;
          this.customerDetails.MOBILE_NO = resp.data.CustomerDet.MOBILE_NO;
          this.customerDetails.EMAIL_ADDRESS = resp.data.CustomerDet.EMAIL_ADDRESS;
          this.customerDetails.CommunicationAddress = resp.data.CustomerDet.CommunicationAddress;
        }
        if (resp.data.VehicleDetails) {
          this.vehicleDO.REG_NO = resp.data.VehicleDetails.REG_NO;
          this.vehicleDO.UNIQUE_NO = resp.data.VehicleDetails.UNIQUE_NO;
          this.vehicleDO.FRAME_NO = resp.data.VehicleDetails.FRAME_NO;
          this.vehicleDO.MODEL_DESC = resp.data.VehicleDetails.MODEL_DESC;
          this.vehicleDO.CUM_ODOM_READ = resp.data.VehicleDetails.CUM_ODOM_READ;
          
          this.vehicleDO.SALE_DATE = CommonService.generalFormat(resp.data.VehicleDetails.SALE_DATE);
         
        }
        if (resp.data.JobTypeList) {
          this.JobTypeList = resp.data.JobTypeList ? resp.data.JobTypeList : [];
        }
        if (resp.data.FollowList && resp.data.FollowList.length > 0) {
          this.serviceApp.FollowUp = resp.data.FollowList.filter(val => {
            val.FOLLOWUP_DATE = CommonService.setDateTime(val.FOLLOWUP_DATE);
            val.EXP_VISIT_DT = CommonService.setDateTime(val.EXP_VISIT_DT);
            val.RESCHEDULE_DATE = CommonService.setDateTime(val.RESCHEDULE_DATE);
            val.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
            return val;
          });
        } else {
          this.serviceApp.FollowUp = [];
        }
      } else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
      if (resp && resp.statusCode == 401) {
        this.loginService.logout();
      }
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
      if (error.status == 401) {
        this.loginService.logout();
      }
    });
  }

   /**
   * Get Vehicle Details based on the frame no and reg no
   * @param {String} category //Frame No or Reg No  
   */
  getFrameRegNoDetails(category) {
    
    this.isShowPageLoader = true;
    let params = new HttpParams();
    params = params.set("DealerID", CommonService.getUserData().DEALER_ID)
      .set("FrameNo", this.serviceApp.FRAME_NO && category == 'frameNo' ? this.serviceApp.FRAME_NO : '')
      .set("RegNo", this.serviceApp.REGIS_NO && category == 'regno'? this.serviceApp.REGIS_NO : '')
      .set("Countrycode", CommonService.getDealerData().COUNTRY_CODE)
      .set("BranchId", CommonService.getUserData().BRANCH_ID)
      .set("UserId", CommonService.getUserData().USER_ID)
    this.serviceAppoitmentService.GetInfoBasedOnFrameNoRegNo(params).subscribe((resp: any) => {
      if (resp && resp.data && resp.statusCode === 200) {
        
        this.vehicleDO = resp.data.vehicleDO;
        this.customerDetails = resp.data.CustomerDetails;
        this.JobTypeList = resp.data.JobTypeList ? resp.data.JobTypeList : [];
        this.serviceApp.FRAME_NO = this.vehicleDO.FRAME_NO;
        this.serviceApp.REGIS_NO = this.vehicleDO.REG_NO;
        this.serviceApp.JOB_TYPE_ID = this.vehicleDO.JOB_TYPE_ID;
        this.serviceApp.END_USER_ID = this.vehicleDO.END_USER_ID;
        this.serviceApp.CUSTOMER_ID = this.vehicleDO.CUSTOMER_ID;
        this.serviceApp.VEHICLE_ID = this.vehicleDO.VEHICLE_ID;
     
        if (this.stateParams.frameNo && this.stateParams.jobTypeId && this.stateParams.empId) {
          this.serviceApp.APPOINTMENT_BY = parseInt(this.stateParams.empId);
        }
        if (this.inputParam && this.inputParam.frameNo) {
          this.serviceApp.JOB_TYPE_ID = 27;
          this.serviceApp.SERV_MODE_ID = 1;
        }
      } else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
      if (resp && resp.statusCode == 401) {
        this.loginService.logout();
      }
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
      if (error.status == 401) {
        this.loginService.logout();
      }
    });
  }
  // checkIsValid() {
  //   if (this.serviceApp.FRAME_NO && this.serviceApp.JOB_TYPE_ID && this.serviceApp.APPOINT_DATE && this.serviceApp.SERV_MODE_ID && this.serviceApp.APT_CAT_ID) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
  closeDialog() {
    this.emitDialogData.emit(undefined);
  }
  // /**
  // * Get all dropdown related list
  // */
  // getAllDropdownInfo() {
  //   let params = new HttpParams();
  //   params = params.set('DealerId', CommonService.getUserData().DEALER_ID)
  //     .set('BranchId', CommonService.getUserData().BRANCH_ID)
  //     .set('BusinessTypeId', '1');
  //   this.serviceReminderService.getAdvancedSearchData(params).subscribe((resp: any) => {
  //     if (resp && resp.data && resp.statusCode === 200) {
  //       this.leakages = resp.data.ServiceReasonList && resp.data.ServiceReasonList.length > 0 ?
  //         resp.data.ServiceReasonList.filter(val => {
  //           return val.ACTIVE == true && val.IS_NEW == true
  //         }) : [];
  //     } else {
  //       this.toastr.error(resp.message);
  //     }
  //   });
  // }
  // getLeakageInfo(event, i) {
  //   const selectEl = event.target;
  //   const val = selectEl.options[selectEl.selectedIndex].getAttribute('obj');
  //   this.serviceApp.FollowUp[i].selectedLeakage = val;
  // }
  /**
    * Validating followup details
  */
  validateFollowup() {
    let valid = true;
    for (let i = 0; i < this.serviceApp.FollowUp.length; i++) {
      if (!this.serviceApp.FollowUp[i].FOLLOWUP_DATE) {
        this.toastr.warning(this.lang.Message.SERVICE_APP.INVALID_FOLLOWUPDT);
        return valid = false;
      }
  
      if (!this.serviceApp.FollowUp[i].EMPLOYEE_ID) {
        this.toastr.warning(this.lang.Message.SERVICE_REMAINDER.INVALID_EMPID);
        return valid = false;
      }
      if (!this.serviceApp.FollowUp[i].COMMENTS) {
        this.toastr.warning(this.lang.Message.SERVICE_APP.INVALID_FOLLOWUPDET);
        return valid = false;
      }
      if (!this.serviceApp.FollowUp[i].LEAKAGE_ID) {
        this.toastr.warning(this.lang.Message.SERVICE_REMAINDER.INVALID_LEAKAGE);
        return valid = false;
      }
      if (this.serviceApp.FollowUp[i].FOLLOWUP_DATE && this.serviceApp.FollowUp[i].EXP_VISIT_DT) {
        if (CommonService.isFromLessthanto(this.serviceApp.FollowUp[i].FOLLOWUP_DATE, this.serviceApp.FollowUp[i].EXP_VISIT_DT)) {
          this.toastr.warning(this.lang.Message.SERVICE_APP.NEXTFLLDATE_LESS);
          this.serviceApp.FollowUp[i].EXP_VISIT_DT = '';
          return valid = false;
        }
      }
      if (this.serviceApp.FollowUp[i].LEAKAGE_ID == '3' && !this.serviceApp.FollowUp[i].EXP_VISIT_DT) {
        this.toastr.warning(this.lang.Message.SERVICE_REMAINDER.INVALID_NEXTFLLDATE);
        return valid = false;
      }
    }
    return valid;
  }
  /**
   * Add Followup
   * @param category 
   */
  addFollowup() {
    if (this.validateFollowup()) {
      let req = {
        ROW_STATE: '',
        SERV_APP_FOLLOWUP_ID: '',
        DEALER_ID: '',
        BRANCH_ID: '',
        EMPLOYEE_ID: '',
        FOLLOWUP_NO: '',
        FOLLOWUP_DATE: '',
        FOLLOWUP_TYPE: false,
        COMMENTS: '',
        LEAKAGE_ID: '',
        EXP_VISIT_DT: '',
        RESCHEDULE_DATE: '',
        selectedLeakage: false,
        uniqId: Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1))

      }
      this.serviceApp.FollowUp.unshift(req);
    }
  }
  /**
   * Remove followup based on index
   * @param {Number} index 
  */
  removeFollowup(index) {
    let reqData = { header: this.lang.GENERAL.delete + ' ' + this.lang.SERVICES.followup, message: this.lang.GENERAL.deleteAlert, btnName1: this.lang.GENERAL.yes, btnName2: this.lang.GENERAL.cancel }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: reqData,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.serviceApp.FollowUp.splice(index, 1);
        this.serviceApp.FollowUp[index].uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1))
      }
    });
  }
  validateAppointment() {
    let valid = true;
    let currentDate = CommonService.setDateTime(new Date);
    currentDate = currentDate.toString();
    currentDate = currentDate.slice(0,15);
    let seletedDate = this.serviceApp.APPOINT_DATE;
    seletedDate = seletedDate.toString();
    seletedDate = seletedDate.slice(0,15);
    if(seletedDate == currentDate ){
      this.toastr.error("Appointment Date Should Not be in Current Date");
      return;
    }
    if (this.appId && this.serviceApp && this.serviceApp.FollowUp && this.serviceApp.FollowUp.length > 0) {
      if (!this.validateFollowup()) {
        return valid = false;
      }
    }
    return valid;
  }
  /**
   * Create Appointment
   * @param category 
   */
  createAppointment(category) {
        if (this.validateAppointment()) {
      this.isShowPageLoader = true;
      let refCcust : any = JSON.parse(JSON.stringify(this.customerDetails));
      let request: any = JSON.parse(JSON.stringify(this.serviceApp));
      request.DEALER_ID = CommonService.getUserData().DEALER_ID;
      request.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
      request.SERV_PREF = request.SERV_PREF;
      request.CREATED_BY = CommonService.getUserData().USER_ID;
      request.MODIFIED_BY = CommonService.getUserData().USER_ID;
      request.REGIS_NO = request.REGIS_NO;
      request.FollowUp = request.FollowUp;
      request.CARE_CAMP_SCH_ID = request.CARE_CAMP_SCH_ID ? request.CARE_CAMP_SCH_ID : 0;
      request.APPOINTMENT_ID = request.APPOINTMENT_ID ? request.APPOINTMENT_ID : 0;
      request.APPOINTMENT_NO = request.APPOINTMENT_NO ? request.APPOINTMENT_NO : 0;
      request.CUR_FOLLOWUP_NO = request.FollowUp.length;
      request.CUST_ARRIVAL_DATE = request.APPOINT_DATE;
      request.ACTIVE = true;         
      refCcust.CONTACT_NO  =this.customerDetails.MOBILE_NO;
      refCcust.CUST_NAME  =this.customerDetails.CUST_NAME;
      request.Customer= refCcust;     
      request.ROW_STATE = category == 'modify' ? 'Modified' : 'Created';
      if (request.FollowUp.length > 0) {
        let reasonId = 0;
        request.FollowUp = request.FollowUp.filter((val, index) => {
          if (!val.SERV_APP_FOLLOWUP_ID) {
            val.ROW_STATE = 'Created';
            val.FOLLOWUP_NO = index + 1;
            val.SERV_APP_FOLLOWUP_ID = 0;
            val.DEALER_ID = CommonService.getUserData().DEALER_ID;
            val.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
            if (val.LEAKAGE_ID == 4) {
              reasonId = 4;
            }
            return val;
          }
        });
        request.REASON_ID = reasonId;
        request.STATUS = reasonId == 4 ? 1 : 0;
      } else {
        request.FollowUp = [];
        request.REASON_ID = 0;
        request.STATUS = request.STATUS ? request.STATUS : 0;
      }
      let finalReq = {
        _serviceApp: request
      }
      
      this.serviceAppoitmentService.createServiceAppointment(finalReq._serviceApp).subscribe((resp: any) => {
        if (resp  && resp.data && resp.statusCode === 200) {
          if (this.isDisablePsf) {
            finalReq._serviceApp.APPOINTMENT_ID = resp.data;
            finalReq._serviceApp.APPOINTMENT_NO = resp.data;
            this.emitDialogData.emit(finalReq._serviceApp);
          } else {
            if (category == 'create') {
              this.toastr.success(this.lang.Message.SERVICE_APP.SAVE_APP);
            } else if (category == 'modify') {
              this.toastr.success(this.lang.Message.SERVICE_APP.UPDATE_APP);
            }
            this.goBack();
          }
        } else {
          this.toastr.error(resp.message);
          this.isShowPageLoader = false;
        }
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }
  goBack() {
    if (window.history && window.history.state && window.history.state.navigationId && window.history.state.navigationId === 1) {
      this.router.navigate(['service/serviceappointment']);
    } else {
      window.history.back();
    }
  }
  /**
  * Navigate create JC
  */
  navigateTo(appNo) {
    this.router.navigate(['service/jobcard/jobcard-create'], { queryParams: { appNo: appNo } });
    if (this.inputParam) {
      this.emitDialogData.emit(undefined);
    }
  }
}

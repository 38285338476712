import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { MasterService } from '../../api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import {
  VoucherAccDetail,
  LedgerEntryDet,
  PaymentVoucher
} from 'src/app/api-services/dto/payment-voucher';
import { JobCardSearchComponent } from '../job-card-search/job-card-search.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Data, Router } from '@angular/router';
import { JobCardService } from 'src/app/api-services/job-card.service';
import { VehicleReturnService } from '../../api-services/vehicle-return.service';
import { VoucherType } from 'src/app/api-services/utils/static-data';
import { VoucherTypes } from '../../api-services/dto/enumDO';
import { filter } from 'rxjs-compat/operator/filter';
import { LoginService } from 'src/app/api-services/login.service';

declare var moment;
@Component({
  selector: 'app-payment-voucher',
  templateUrl: './payment-voucher.component.html',
  styleUrls: ['./payment-voucher.component.scss']
})
export class PaymentVoucherComponent implements OnInit {
  // Code added By Anil for Vehicle return on 23-04-2021
  public voucherTypeList = [];

  getVoucherType() {
    let businessType = CommonService.getDealerData().BUSINESS_TYPE_ID;
    // this.vehicleReturnService.getVoucherTypeList(businessType).subscribe((resp:any) => {
    this.masterService.get('', '/Voucher/FetchVoucherType').subscribe(
      (resp: any) => {
        if (resp && resp.VoucherTypeList != null) {
          this.voucherTypeList = resp.VoucherTypeList;
          if (this.voucherTypeList && this.voucherTypeList.length > 0) {
            this.paymentVoucher.VCHR_TYPE_ID = "901";
          }

        }
      })

  }

  getDetailsForVehicleReturn(INV_RET_ORD_NO: number, COMPANY_ID: number) {
    let reqParams = {
      "DEALER_ID": CommonService.getDealerData().DEALER_ID,
      "BRANCH_ID": CommonService.getDealerData().BRANCH_ID,
      "INV_RET_ORD_NO": INV_RET_ORD_NO,
      //"INVOICE_TYPE_ID": INVOICE_TYPE_ID,
      //"IS_ACCT_SRCH":false,
      "COMPANY_ID": COMPANY_ID
    }

    this.vehicleReturnService.searchVehicleReturn(reqParams).subscribe((resp: any) => {

      if (resp && resp.data != null && resp.statusCode == 200) {
        // ddlVoucherType.SelectedIndex = 2;
        // ddlVoucherType.Enabled = false;
        // ddlVoucherType_SelectedIndexChanged(null, null);
        // ddlCompanyNo.Enabled = false;
        // ddlPartyCategory.SelectedValue = Convert.ToInt32(PartyCategory.Customer).ToString();
        // txtDocumentNo.Text = Convert.ToString(_VehReturnList[0].INV_RET_ORD_NO);
        // rdpDocumentDate.SelectedDate = _VehReturnList[0].RET_DATE;
        // txtPartyCode.Text = Convert.ToString(_VehReturnList[0].CUSTOMER_ID);
        // txtPartyName.Text = _VehReturnList[0].CUST_NAME;
        // txtVoucherValue.Text = Convert.ToString(_VehReturnList[0].TOT_AMT_RET);
        // ddlCreditLimitType.SelectedIndex = 1;
        // ddlCreditLimitType.Enabled = false;
        // txtDr.Text = Convert.ToString(_VehReturnList[0].TOT_AMT_RET);
        // txtCr.Text = Convert.ToString(_VehReturnList[0].TOT_AMT_RET);

        if (resp.data.length > 0) {
          let firstObj = resp.data[0];
          this.paymentVoucher.DOCUMENT_ID = firstObj.INV_RET_ORD_NO;
          this.paymentVoucher.DOC_DATE = CommonService.setDateTime(firstObj.RET_DATE);
          this.paymentVoucher.PARTY_CODE = firstObj.CUSTOMER_ID;
          this.paymentVoucher.PARTY_NAME = firstObj.CUST_NAME;
          this.paymentVoucher.VCHR_VALUE = firstObj.TOT_AMT_RET;
          this.paymentVoucher.CREDIT_LIMIT_TYPE = "1";
          this.paymentVoucher.DR = firstObj.TOT_AMT_RET;
          this.paymentVoucher.CR = firstObj.TOT_AMT_RET;
          // this.paymentVoucher.VCHR_TYPE_ID="2";
          this.paymentVoucher.PARTY_CAT = "1";

        }

      }
    })

  }

  // End
  public lang: any;
  @Input() voucherInfo: any = {};
  @Input() isView: any;
  @Input() voucherType: any;
  @Input() paymentDetail: any;
  public paymentHeader: any;
  public isShowPageLoader: boolean;
  public paymentMaster: any = [];
  public docDate: any = new Date();
  public isActivePopup: any;
  public paymentVoucher = new PaymentVoucher();
  public voucherAccDetail = new VoucherAccDetail();
  public ledgerEntryDet = new LedgerEntryDet();
  // public financialYear: any = [];
  public slCodeDetails: any = [];
  public voucherDetails: any = {};
  public maxDate: any = new Date();

  public finalStatusList: any = {}; // for voucherNo

  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private masterService: MasterService,
    public commonService: CommonService,
    public toastr: ToastrService,
    private jobCardService: JobCardService,
    private vehicleReturnService: VehicleReturnService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public dataPayment: any,
    public dialogRef: MatDialogRef<PaymentVoucherComponent>
  ) {

  }

  filteredVoucherTypeListForVehicleReturn(voucherTypeList: any) {
    if (voucherTypeList && voucherTypeList.length > 0) {

      let filtererdList = voucherTypeList.filter(x => x.VCHR_TYPE_ID == VoucherTypes.Vehicle_Sales_Return_Payment)
      alert(filtererdList)
    }

  }
  closeDialog() {
    this.dialogRef.close();
  }
  savePaymnentVoucher() {
    let reqParams = {
      "DEALER_ID": CommonService.getDealerData().DEALER_ID,
      "BRANCH_ID": CommonService.getDealerData().BRANCH_ID,
      "CREATED_BY": CommonService.getUserData().USER_ID,
      "VOUCHER_DT": CommonService.generalFormatYYYYMMDD(this.paymentVoucher.VOUCHER_DT),
      "VCHR_TYPE_ID": this.paymentVoucher.VCHR_TYPE_ID,
      "VCHR_VALUE": this.paymentVoucher.VCHR_VALUE,
      "VCHR_STATUS": 0,// assign value in webapi
      "FIN_YEAR": this.paymentVoucher.FIN_YEAR,
      "COMPANY_ID": this.paymentVoucher.COMPANY_ID,
      "PAYMENT_MODE_ID": this.paymentVoucher.PAYMENT_MODE_ID,
      "DOCUMENT_ID": this.paymentVoucher.DOCUMENT_ID,
      "DOC_DATE": CommonService.generalFormatYYYYMMDD(this.paymentVoucher.DOC_DATE),
      "PARTY_CODE": this.paymentVoucher.PARTY_CODE,
      "PARTY_NAME": this.paymentVoucher.PARTY_NAME,
      "PARTY_CAT": this.paymentVoucher.PARTY_CAT,
      "INSTR_NO": this.paymentVoucher.INSTR_NO,
      "INSTR_DATE": CommonService.generalFormatYYYYMMDD(this.paymentVoucher.INSTR_DATE),
      "INSTR_AMT": this.paymentVoucher.INSTR_AMT,
      "INSTRUMENT_ON": this.paymentVoucher.INSTRUMENT_ON,
      "ACCOUNT_NO": this.paymentVoucher.ACCOUNT_NO,
      "BANK_BRANCH": this.paymentVoucher.BANK_BRANCH,
      "NARRATION": this.paymentVoucher.NARRATION,
      "UNRECON_VAL": this.paymentVoucher.UNRECON_VAL,
      "CRED_LMT_TYPE": this.paymentVoucher.CREDIT_LIMIT_TYPE,
      "BANK_ID": this.paymentVoucher.BANK_ID,
      "TDS_APPLIED": this.paymentVoucher.TDS_APPLIED,
      "ACTIVE": true,

    }

    this.vehicleReturnService.savePaymmentVoucher(reqParams).subscribe((resp: any) => {
      if (resp && resp.data != null && resp.statusCode == 200) {
        this.toastr.success(resp.message);
      }
      else if (resp.data == null && resp.statusCode == 500) {
        this.toastr.error(resp.message);

      }

    })

  }
  ngOnInit() {
    if (CommonService.getLang()) {

      this.lang = CommonService.getLang();
      this.paymentHeader = [
        {
          name: this.lang.UVD.glCode,
          width: 'wmpx-150'
        },
        {
          name: this.lang.UVD.glDesc,
          width: 'wmpx-200'
        },
        {
          name: this.lang.UVD.slCode,
          width: 'wmpx-150'
        },
        {
          name: this.lang.UVD.slDesc,
          width: 'wmpx-150'
        },
        {
          name: this.lang.UVD.value,
          width: 'wmpx-150'
        },
        {
          name: this.lang.UVD.isDebit,
          width: 'wmpx-150'
        }
      ];
      // for (let index = moment().year(); index >= 2009; index--) {
      //   this.financialYear.push({ value: index });
      // }
      // this.paymentVoucher.FIN_YEAR = this.financialYear.length > 0 ? this.financialYear[0].value : '';
      // this.getVoucherType(); // commented there is no api
      this.paymentVoucher.VoucherAccDetail = [];
      this.paymentVoucher.PAYMENT_MODE_ID = '1';
      this.paymentVoucher.CREDIT_LIMIT_TYPE = '1';
      this.paymentVoucher.VCHR_TYPE_ID = '901';
      this.paymentVoucher.PARTY_CAT = '1';
      this.paymentVoucher.VOUCHER_DT = new Date();
      this.paymentVoucher.INSTR_DATE = new Date();
      this.paymentVoucher.DOCUMENT_ID = this.voucherInfo.enquiryNo;
      this.paymentVoucher.DOC_DATE = this.voucherInfo.enquiryDate ? new Date(this.voucherInfo.enquiryDate) : '';
      this.paymentVoucher.VCHR_VALUE = this.voucherInfo.finalProcuredValue ? this.voucherInfo.finalProcuredValue : '';
      this.getMasterPayment();
      this.financialYears();

      // this.receviceMessage(); 
      if (this.isView == true) {
        if (this.paymentDetail.details && this.paymentDetail.details.value) {
          this.voucherDetails.documentId = this.paymentDetail.details.value.id ? this.paymentDetail.details.value.id : '';
          this.voucherDetails.documentNo = this.paymentDetail.details.value.voucherNo ? this.paymentDetail.details.value.voucherNo : '';
          this.voucherDetails.voucherTypeId = this.paymentDetail.details.value.voucherTypeId ?
            this.paymentDetail.details.value.voucherTypeId : '';
          this.getViewDetails();
        }
      } else {
        this.ProcurementFinalStatus();
      }
    }

  }

  ProcurementFinalStatus() {
    if (this.voucherInfo.enquiryNo) {
      this.isShowPageLoader = true;
      this.masterService.post('', '/Procurement/getProcurementFinalStatus?enquiryNo=' + this.voucherInfo.enquiryNo + '&dealerId=' + CommonService.getUserData().DEALER_ID + '&branchId=' + CommonService.getUserData().BRANCH_ID).subscribe(
        (resp: any) => {
          this.finalStatusList = resp.finalStatusData;
          // this.voucherDetails.documentId = resp.finalStatusData[0].voucherNo > 0 ? resp.finalStatusData[0].voucherNo : '';
          // this.voucherDetails.voucherTypeId = resp.finalStatusData[0].voucherTypeId ? resp.finalStatusData[0].voucherTypeId : '';
          let finalStatusCheck: boolean = false;
          this.finalStatusList.forEach(data => {
            if (data.voucherNo > 0 && data.voucherTypeId == 901 && !finalStatusCheck) {
              this.voucherDetails.documentId = data.voucherNo > 0 ? data.voucherNo : '';
              this.voucherDetails.voucherTypeId = data.voucherTypeId ? data.voucherTypeId : '';
              // this.getViewDetails();
              finalStatusCheck = true;
            }
          });
          if(finalStatusCheck){
            this.getViewDetails();
          }
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }, error => {
          if (error.status == 401) {
            this.loginService.logout();
          }
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        }
      )
    }
  }

  // ngAfterViewInit() {
  //   this.getViewDetails();
  // }
  // ngAfterViewInit() {

  // }

  receviceMessage() {
    this.commonService.getMessage().subscribe(message => {
      //  if (message && message.name === 'isActive') {
      //     this.isActivePopup = message.name;
      //  }
    });
  }
  financialYears() {
    let finYear;
    let today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      finYear = (today.getFullYear() - 1);
    } else {
      finYear = today.getFullYear();
    }
    this.paymentVoucher.FIN_YEAR = finYear;
  }
  getMasterPayment() {
    this.isShowPageLoader = true;
    this.masterService.post('', '/Voucher/GetVoucherBasicinfo?dealerID=' + CommonService.getUserData().DEALER_ID).subscribe(
      resp => {
        if (resp) {
          this.isShowPageLoader = false;
          this.paymentMaster = resp;
          if (this.paymentMaster.companyData && this.paymentMaster.companyData.length) {
            this.paymentVoucher.COMPANY_ID = this.paymentMaster.companyData[0].companyID;
            this.paymentVoucher.company_name = this.paymentMaster.companyData[0].companyName;
          }
          if (this.voucherInfo && this.voucherInfo.mobileNumber) {
            this.searchCustomer(this.voucherInfo.mobileNumber)
          }
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      }
    )
    console.log("Voucher INFO",this.voucherInfo);
  }
  getSlCode() {
    if (!this.paymentVoucher.PARTY_CODE) {
      this.toastr.error('Party  code is empty');
      return;
    };
    let slcodeObj: any = {};
    slcodeObj = {
      'DEALER_ID': CommonService.getUserData().DEALER_ID,
      'Ref_Id': this.paymentVoucher.PARTY_CODE
    };
    this.isShowPageLoader = true;
    this.masterService.post(slcodeObj, '/Voucher/GetSLCodeByCustomerID').subscribe(
      (resp: any) => {
        if (resp) {
          this.slCodeDetails = resp.SLdetails.length > 0 ? resp.SLdetails : [];
          this.isShowPageLoader = false;
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      }
    );
  }
  getAccountMapping() {
    let reqMapping: any = {};
    let mapError: any = 0;
    if (!this.paymentVoucher.COMPANY_ID) {
      this.toastr.error('Company Id is empty');
      mapError += 1;
    }
    if (!this.paymentVoucher.DOCUMENT_ID) {
      this.toastr.error('Document Id is empty');
      mapError += 1;
    }
    if (!this.paymentVoucher.PAYMENT_MODE_ID) {
      this.toastr.error('Payment mode is empty');
      mapError += 1;
    }
    if (!this.paymentVoucher.VCHR_TYPE_ID) {
      this.toastr.error('Voucher type is empty');
      mapError += 1;
    }
    if (mapError > 0) {
      return;
    }
    reqMapping = {
      'DEALER_ID': CommonService.getUserData().DEALER_ID,
      'COMPANY_ID': this.paymentVoucher.COMPANY_ID,
      'DOC_ID': 9, // default value ...
      'payment_mode_id': this.paymentVoucher.PAYMENT_MODE_ID,
      'VOUCHER_TYPE': this.paymentVoucher.VCHR_TYPE_ID,
      'bank_id': this.paymentVoucher.BANK_ID
      // 'bank_id':  null
    };
    this.isShowPageLoader = true;
    this.masterService.post(reqMapping, '/Voucher/GetAccountMapping').subscribe(
      (resp: any) => {
        if (resp) {
          this.isShowPageLoader = false;
          //this.paymentVoucher.VoucherAccDetail = resp.Accountdetails.length > 0? resp.Accountdetails:[];
          setTimeout(() => {

            if (resp.Accountdetails && resp.Accountdetails.length > 0 && this.slCodeDetails && this.slCodeDetails.length > 0) {
              this.paymentVoucher.VoucherAccDetail = [
                {
                  GL_CODE: resp.Accountdetails[0].debit_gl_id ? resp.Accountdetails[0].debit_gl_id : '',
                  Gen_Desc: resp.Accountdetails[0].Gen_Desc_Debt ? resp.Accountdetails[0].Gen_Desc_Debt : '',
                  SL_CODE: this.slCodeDetails[0].SL_CODE,
                  Sub_Desc: this.slCodeDetails[0].Sub_Desc,
                  ACC_VALUE: '',
                  VCHR_VALUE: this.paymentVoucher.VCHR_VALUE.toString(),
                  CREDIT_LIMIT_TYPE: '1',
                  DEBIT_CREDIT: '',
                  IS_DEBIT: true
                },
                {
                  GL_CODE: resp.Accountdetails[0].credit_gl_id ? resp.Accountdetails[0].credit_gl_id : '',
                  Gen_Desc: resp.Accountdetails[0].Gen_Desc ? resp.Accountdetails[0].Gen_Desc : '',
                  SL_CODE: '',
                  Sub_Desc: '',
                  ACC_VALUE: '',
                  VCHR_VALUE: this.paymentVoucher.VCHR_VALUE.toString(),
                  CREDIT_LIMIT_TYPE: '1',
                  DEBIT_CREDIT: '',
                  IS_DEBIT: false
                }];
            }
            this.isShowPageLoader = false;
            // if (this.slCodeDetails &&  this.slCodeDetails.length > 0) {
            //   this.paymentVoucher.VoucherAccDetail.filter((key:any) => {
            //     this.slCodeDetails.filter( slkey => {
            //     if(key.IS_DEBIT == true) {
            //       key.SL_CODE  = slkey.SL_CODE;
            //       key.Sub_Desc = slkey.Sub_Desc;
            //     }              
            //     });
            //   });
            // }
          }, 2000);
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      }
    )
  }
  printVoucher() {
    let printObj: any = {};
    // if (!this.voucherDetails.documentNo || !this.voucherDetails.documentId) {
    //   this.toastr.error('Please complete payment');
    //   return;
    // }
    // printObj = {
    //   "DEALER_ID": CommonService.getUserData().DEALER_ID,
    //   "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
    //   // "VOUCHER_ID": "2915",
    //   "VOUCHER_ID": this.voucherDetails.documentId
    // }
    this.isShowPageLoader = true;
    this.masterService.get(printObj, '/Voucher/PrintVoucherByID?DEALER_ID='
      + CommonService.getUserData().DEALER_ID + '&BRANCH_ID=' + CommonService.getUserData().BRANCH_ID + '&VOUCHER_ID=' +
      this.voucherDetails.documentId).subscribe(
        (resp: any) => {
          if (resp && resp.statusMessage) {
            this.isShowPageLoader = false;
            CommonService.printData(resp.printString);
            // this.toastr.success(resp.statusMessage);
          }
        },
        error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        }
      )
  }
  getViewDetails() {
    let viewdltError: any = 0;
    // if (!this.paymentVoucher.DOC_TYPE) {
    //   this.toastr.error('Document type is empty');
    //   viewdltError += 1;
    // }
    //  if (this.paymentVoucher.VCHR_TYPE_ID) {
    //   this.toastr.error('Voucher id is empty');
    //   viewdltError += 1;
    // }
    // if (viewdltError > 0) {
    //   return;
    // }
    let viewObj = {
      'DEALER_ID': CommonService.getUserData().DEALER_ID,
      'BRANCH_ID': CommonService.getUserData().BRANCH_ID,
      'VOUCHER_ID': this.voucherDetails.documentId,
      // 'DOC_TYPE': this.paymentVoucher.DOC_TYPE,
      'DOC_TYPE': 9,
      // 'VCHR_TYPE_ID': this.paymentVoucher.VCHR_TYPE_ID,
      // "VCHR_TYPE_ID": 901
      'VCHR_TYPE_ID': this.voucherDetails.voucherTypeId
    };
    // let viewObj = {
    //           "DEALER_ID":10891,
    //           "BRANCH_ID":1,
    //           "VOUCHER_ID":"2893",
    //            "DOC_TYPE":2,
    //           "VCHR_TYPE_ID":201
    //           };
    this.isShowPageLoader = true;
    this.masterService.post(viewObj, '/Voucher/GetVoucherByID').subscribe(
      // this.masterService.post(viewObj, '/Voucher/GetVoucherByID?dealerID=' + CommonService.getUserData().subscribe(
      (resp: any) => {
        if (resp) {
          this.paymentVoucher = resp.VoucherSearchList[0];
          this.paymentVoucher.VoucherAccDetail = resp.VoucherSearchList[0] && resp.VoucherSearchList[0].LEDGER_ENTRY_DET.length ?
            resp.VoucherSearchList[0].LEDGER_ENTRY_DET : [];
          this.paymentVoucher.DOC_DATE = resp.VoucherSearchList[0].DOC_DATE ?
            CommonService.generalFormat(resp.VoucherSearchList[0].DOC_DATE) : '';
          this.paymentVoucher.CREDIT_LIMIT_TYPE = '1';
          this.paymentVoucher.VOUCHER_DT = resp.VoucherSearchList[0].VOUCHER_DT ?
            CommonService.generalFormat(resp.VoucherSearchList[0].VOUCHER_DT) : '';
          this.paymentVoucher.DOCUMENT_ID = resp.VoucherSearchList[0].DOCUMENT_ID;
          this.paymentVoucher.INSTR_DATE = resp.VoucherSearchList[0].INSTR_DATE ?
            CommonService.generalFormat(resp.VoucherSearchList[0].INSTR_DATE) : '';
          this.isShowPageLoader = false;
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      }
    );
  }
  //search by  customer 
  searchCustomer(mobileNo) {
    this.paymentVoucher.PARTY_CODE = this.voucherInfo.customerId;
    this.paymentVoucher.PARTY_NAME = this.voucherInfo.customerName;
    let params = new HttpParams();
    params = params.set('DealerId', CommonService.getUserData().DEALER_ID)
      .set('CustName', this.paymentVoucher.PARTY_NAME)
      .set('SalepersonId', '-1')
      .set('Address', this.voucherInfo.address)
      .set('PhoneNo', mobileNo)
      .set('CustCode', this.paymentVoucher.PARTY_CODE)
      .set('CustTypeId', '')
      .set('DLR_COUNTRY_CODE', CommonService.getDealerData().COUNTRY_CODE)

    this.masterService.get(params, '/Service/SearchCustomer').subscribe((resp: any) => {
      // this.jobCardService.searchCustomer(params).subscribe((resp: any) => {
      if (resp && resp.statusCode === 200) {
        if (resp.data && resp.data.length > 0)
          this.paymentVoucher.PARTY_CODE = resp.data[0].CUSTOMER_ID;
        this.paymentVoucher.PARTY_NAME = resp.data[0].CUST_NAME;
        if (this.paymentVoucher.PARTY_CODE && this.paymentVoucher.PARTY_NAME) {
          this.getSlCode();
          this.getAccountMapping();
        }
      } else if (resp.message) {
        this.toastr.error(resp.message);
      }
    }, error => {
      this.toastr.error(error.error.Message);
    });
  }
  assignLedgerEntryDetails() {
    if (this.paymentVoucher && this.paymentVoucher.VoucherAccDetail.length > 0) {
      this.paymentVoucher.VoucherAccDetail.filter((key: any) => {
        this.paymentVoucher.LedgerEntryDet.push(
          {
            'DEALER_ID': CommonService.getUserData().DEALER_ID.toString(),
            'VOUCHER_DATE': CommonService.getReqDateFormat(this.paymentVoucher.VOUCHER_DT),
            //  'VOUCHER_DATE':   '2019-11-29',
            'FIN_YEAR': this.paymentVoucher.FIN_YEAR.toString(),
            'VOUCHER_SUB_TYPE': this.paymentVoucher.VCHR_TYPE_ID,
            'PARTY_CAT': this.paymentVoucher.PARTY_CAT,
            'PARTY_CODE': this.paymentVoucher.PARTY_CODE.toString(),
            'VOUCHER_STATUS': this.paymentVoucher.VCHR_STATUS ? this.paymentVoucher.VCHR_STATUS.toString() : '1',
            'NARRATION': this.paymentVoucher.NARRATION,
            'DOC_ID': this.paymentVoucher.DOCUMENT_ID,
            'payment_mode_id': this.paymentVoucher.PAYMENT_MODE_ID,
            'VOUCHER_TYPE': this.paymentVoucher.VCHR_TYPE_ID,       // need to check
            'COMPANY_ID': this.paymentVoucher.COMPANY_ID.toString(),
            'bank_id': this.paymentVoucher.BANK_ID ? this.paymentVoucher.BANK_ID.toString() : null,
            'GL_CODE': key.GL_CODE,
            'Gen_Desc': key.Gen_Desc,
            'SL_CODE': key.SL_CODE,
            'Sub_Desc': key.Sub_Desc,
            'VCHR_VALUE': key.VCHR_VALUE,
            'IS_DEBIT': key.IS_DEBIT
            // 'GL_CODE': '11600001',
            // 'Gen_Desc': 'VEHICLE CUSTOMER',
            // 'SL_CODE': '0',
            // 'Sub_Desc': 'VEHICLE',
            // 'VCHR_VALUE': '7000',
            // 'IS_DEBIT': 'false'
          }
        )
      });
    }
  }
  validatePayment() {
    let paymentError: any = 0;
    if (!this.paymentVoucher.VCHR_TYPE_ID) {
      this.toastr.error('Voucher Type is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.FIN_YEAR) {
      this.toastr.error('Finance Year is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.COMPANY_ID) {
      this.toastr.error('Company Number  is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.company_name) {
      this.toastr.error('Company Name  is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.PAYMENT_MODE_ID) {
      this.toastr.error('Payment Mode  is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.DOCUMENT_ID) {
      this.toastr.error('Document No is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.DOC_DATE) {
      this.toastr.error('Document Date is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.DOC_DATE) {
      this.toastr.error('Document Date is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.CREDIT_LIMIT_TYPE) {
      this.toastr.error('Credit Limit is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.VCHR_VALUE) {
      this.toastr.error('Voucher Value is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.VOUCHER_DT) {
      this.toastr.error('Voucher Date is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.PARTY_CAT) {
      this.toastr.error('Party Category is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.PARTY_CODE) {
      this.toastr.error('Party Code is empty');
      paymentError += 1;
    }
    if (!this.paymentVoucher.PARTY_NAME) {
      this.toastr.error('Party Name is empty');
      paymentError += 1;
    }
    if (this.paymentVoucher.PAYMENT_MODE_ID == '4' || this.paymentVoucher.PAYMENT_MODE_ID == '6') {
      if (!this.paymentVoucher.BANK_ID) {
        this.toastr.error('Bank Name is empty');
        paymentError += 1;
      }
    }
    if (paymentError > 0) {
      return false;
    }
    if (this.paymentVoucher.PAYMENT_MODE_ID && this.paymentVoucher.PAYMENT_MODE_ID == '4' || this.paymentVoucher.PAYMENT_MODE_ID == '6') {
      let instrError: any = 0;
      if (!this.paymentVoucher.INSTR_NO) {
        this.toastr.error('Instrument No  is empty');
        instrError += 1;
      }
      if (!this.paymentVoucher.INSTR_DATE) {
        this.toastr.error('Instrument Date is empty');
        instrError += 1;
      }
      if (!this.paymentVoucher.INSTR_AMT) {
        this.toastr.error('Instrument Value is empty');
        instrError += 1;
      }
      if (!this.paymentVoucher.ACCOUNT_NO) {
        this.toastr.error('Account No is empty');
        instrError += 1;
      }
      if (!this.paymentVoucher.BANK_BRANCH) {
        this.toastr.error('Issuing Branch is empty');
        instrError += 1;
      }
      if (instrError > 0) {
        return false;
      }
    }

    //error check for account details
    let accEror: any = 0;
    if (this.paymentVoucher.VoucherAccDetail.length > 0) {
      this.paymentVoucher.VoucherAccDetail.filter(accKey => {
        if (!accKey.GL_CODE || !accKey.Gen_Desc || !accKey.VCHR_VALUE) {
          accEror += 1;
        }
        if (!accKey.SL_CODE && accKey.IS_DEBIT == true) {
          accEror += 1;
        }
      })
      if (accEror > 0) {
        this.toastr.error('Account details is  empty');
        return false;
      }
    }
    // error check for ledger entry details
    let LedgerEntryError: any = 0;
    // console.log(this.paymentVoucher.LedgerEntryDet.length);
    // if( this.paymentVoucher.LedgerEntryDet.length > 0) {
    //   this.paymentVoucher.LedgerEntryDet.filter(accKey => {
    //     console.log(accKey);
    //     if(!accKey.GL_CODE  || !accKey.Gen_Desc || !accKey.VCHR_VALUE) {
    //       LedgerEntryError += 1;
    //     }
    //     if (!accKey.SL_CODE && accKey.IS_DEBIT == 'true' ) {
    //       LedgerEntryError += 1;
    //     }
    //   })
    //   if(LedgerEntryError > 0){
    //     this.toastr.error('Ledger details is empty');
    //     return false;
    //   }
    // }  
    return true;
  }
  savePayment() {
    if (this.validatePayment() != true) {
      return;
    }
    this.paymentVoucher.LedgerEntryDet = [];
    this.assignLedgerEntryDetails();
    // this.assignLedgerEntryDetails();
    let createObj: any = {};
    // createObj = this.paymentVoucher;
    createObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    createObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    // createObj.VOUCHER_ID = this.paymentVoucher.VOUCHER_ID ? this.paymentVoucher.VOUCHER_ID : 0;
    // createObj.VOUCHER_NO = this.paymentVoucher.VOUCHER_NO ? Number(this.paymentVoucher.VOUCHER_NO) : 0;
    createObj.VOUCHER_ID = 0;
    createObj.VOUCHER_NO = 0;
    createObj.CREATED_BY = CommonService.getUserData().USER_ID.toString();
    // createObj.VOUCHER_DT = CommonService.setDateTime(this.paymentVoucher.VOUCHER_DT);
    createObj.VOUCHER_DT = new Date();
    // createObj.VOUCHER_DT = '2019-11-29';
    createObj.VCHR_TYPE_ID = Number(this.paymentVoucher.VCHR_TYPE_ID);
    createObj.VCHR_VALUE = Number(this.paymentVoucher.VCHR_VALUE);
    // createObj.VCHR_STATUS = this.paymentVoucher.VCHR_STATUS ? this.paymentVoucher.VCHR_STATUS : 1;// not ui need check
    createObj.VCHR_STATUS = 1;
    createObj.FIN_YEAR = this.paymentVoucher.FIN_YEAR.toString();
    createObj.COMPANY_ID = this.paymentVoucher.COMPANY_ID.toString();
    createObj.PAYMENT_MODE_ID = this.paymentVoucher.PAYMENT_MODE_ID;
    createObj.DOCUMENT_ID = this.paymentVoucher.DOCUMENT_ID;
    createObj.DOC_TYPE = this.paymentVoucher.DOC_TYPE ? this.paymentVoucher.DOC_TYPE : 9; // not in ui need to add
    createObj.DOC_DATE = CommonService.setDateTime(this.paymentVoucher.DOC_DATE);
    createObj.BASE_DOC_TYPE = this.paymentVoucher.BASE_DOC_TYPE ? Number(this.paymentVoucher.BASE_DOC_TYPE) : '6';
    createObj.PARTY_CODE = this.paymentVoucher.PARTY_CODE.toString();
    createObj.PARTY_NAME = this.paymentVoucher.PARTY_NAME;
    createObj.INSTR_NO = this.paymentVoucher.INSTR_NO ? this.paymentVoucher.INSTR_NO : null;
    createObj.INSTR_DATE = this.paymentVoucher.INSTR_DATE ? CommonService.setDateTime(this.paymentVoucher.INSTR_DATE) : null;
    createObj.INSTR_AMT = this.paymentVoucher.INSTR_AMT ? this.paymentVoucher.INSTR_AMT : null;
    createObj.INSTRUMENT_ON = this.paymentVoucher.INSTRUMENT_ON ? this.paymentVoucher.INSTRUMENT_ON : null;
    createObj.ACCOUNT_NO = this.paymentVoucher.ACCOUNT_NO ? this.paymentVoucher.ACCOUNT_NO : null;
    createObj.PARTY_CAT = Number(this.paymentVoucher.PARTY_CAT);
    createObj.CRED_CARD_TY_ID = this.paymentVoucher.CRED_CARD_TY_ID ? this.paymentVoucher.CRED_CARD_TY_ID : null;
    createObj.CRED_CARD_EXP_DT = this.paymentVoucher.CRED_CARD_EXP_DT ? this.paymentVoucher.CRED_CARD_EXP_DT : null;
    createObj.APPROVAL_NO = this.paymentVoucher.APPROVAL_NO ? this.paymentVoucher.APPROVAL_NO : null;
    createObj.BANK_BRANCH = this.paymentVoucher.BANK_BRANCH ? this.paymentVoucher.BANK_BRANCH : null;
    createObj.BANK_ID = this.paymentVoucher.BANK_ID ? this.paymentVoucher.BANK_ID : null;
    createObj.BASE_VOUCHER_ID = this.paymentVoucher.BASE_VOUCHER_ID ? this.paymentVoucher.BASE_VOUCHER_ID : null;
    createObj.NARRATION = this.paymentVoucher.NARRATION;
    createObj.UNRECON_VAL = this.paymentVoucher.UNRECON_VAL ? this.paymentVoucher.UNRECON_VAL : '0';
    createObj.CRED_LMT_TYPE = this.paymentVoucher.CRED_LMT_TYPE ? Number(this.paymentVoucher.CRED_LMT_TYPE) : 1;
    createObj.TDS_APPLIED = this.paymentVoucher.TDS_APPLIED ? this.paymentVoucher.TDS_APPLIED : 'false',
      createObj.ACTIVE = this.paymentVoucher.ACTIVE ? this.paymentVoucher.ACTIVE : 'true';
    // createObj.VOUCHER_ACC_DETAILS = this.paymentVoucher.VoucherAccDetail.length > 0 ? this.paymentVoucher.VoucherAccDetail : [];
    let voucherDtl: any = [];
    if (this.paymentVoucher.VoucherAccDetail.length > 0) {
      this.paymentVoucher.VoucherAccDetail.filter(val => {
        voucherDtl.push({
          'GL_CODE': val.GL_CODE,
          'SL_CODE': val.SL_CODE,
          'ACC_VALUE': val.VCHR_VALUE,
          'CREDIT_LIMIT_TYPE': '1', // need  key default 1
          'IS_DEBIT': val.IS_DEBIT
        });
      });
    }
    createObj.VOUCHER_ACC_DETAILS = voucherDtl.length > 0 ? voucherDtl : [];
    createObj.LEDGER_ENTRY_DET = this.paymentVoucher.LedgerEntryDet.length > 0 ? this.paymentVoucher.LedgerEntryDet : [];

    this.isShowPageLoader = true;
    this.masterService.post(createObj, '/Voucher/CreatePayment').subscribe(
      (resp: any) => {
        if(resp && resp.statusCode == 200){
          this.toastr.success(resp.statusMessage);
          // this.voucherDetails = resp;
          this.commonService.sendMessage('finalStatus');
          this.isShowPageLoader = false;
          this.router.navigate(['uvd/procurement']);
        } else {
          this.toastr.error(resp.statusMessage);
          this.isShowPageLoader = false;
        }
        this.isShowPageLoader = false;
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      });
  }
  reversePayment() {
    this.paymentVoucher.LedgerEntryDet = [];
    this.assignLedgerEntryDetails();
    let reverseObj: any = {};
    if (this.validatePayment() != true) {
      return;
    }
    // createObj = this.paymentVoucher;
    if (!this.voucherDetails.documentNo || !this.voucherDetails.documentId) {
      this.toastr.error('Please complete payment');
      return;
    }
    reverseObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reverseObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reverseObj.CREATED_BY = CommonService.getUserData().USER_ID.toString();
    reverseObj.VOUCHER_DT = new Date();
    // reverseObj.VCHR_TYPE_ID = Number(this.paymentVoucher.VCHR_TYPE_ID);
    reverseObj.VCHR_TYPE_ID = 119; //  default key 
    reverseObj.VCHR_VALUE = Number(this.paymentVoucher.VCHR_VALUE);
    reverseObj.FIN_YEAR = this.paymentVoucher.FIN_YEAR.toString();
    reverseObj.COMPANY_ID = this.paymentVoucher.COMPANY_ID;
    reverseObj.PAYMENT_MODE_ID = this.paymentVoucher.PAYMENT_MODE_ID;
    // reverseObj.DOCUMENT_ID = this.paymentVoucher.DOCUMENT_ID;
    reverseObj.DOCUMENT_ID = this.voucherDetails.documentId;
    // reverseObj.DOC_DATE = CommonService.setDateTime(this.paymentVoucher.DOC_DATE);
    reverseObj.DOC_DATE = CommonService.setDateTime(this.paymentVoucher.DOC_DATE);
    reverseObj.BASE_DOC_TYPE = this.paymentVoucher.BASE_DOC_TYPE ? Number(this.paymentVoucher.BASE_DOC_TYPE) : '6';
    reverseObj.PARTY_CODE = this.paymentVoucher.PARTY_CODE;
    reverseObj.INSTR_NO = this.paymentVoucher.INSTR_NO ? this.paymentVoucher.INSTR_NO : null;
    reverseObj.INSTR_DATE = this.paymentVoucher.INSTR_DATE ? CommonService.setDateTime(this.paymentVoucher.INSTR_DATE) : null;
    reverseObj.INSTR_AMT = this.paymentVoucher.INSTR_AMT ? this.paymentVoucher.INSTR_AMT : null;
    reverseObj.INSTRUMENT_ON = this.paymentVoucher.INSTRUMENT_ON ? this.paymentVoucher.INSTRUMENT_ON : null;
    reverseObj.ACCOUNT_NO = this.paymentVoucher.ACCOUNT_NO ? this.paymentVoucher.ACCOUNT_NO : null;
    reverseObj.PARTY_CAT = Number(this.paymentVoucher.PARTY_CAT);
    reverseObj.CRED_CARD_TY_ID = this.paymentVoucher.CRED_CARD_TY_ID ? this.paymentVoucher.CRED_CARD_TY_ID : null;
    reverseObj.CRED_CARD_EXP_DT = this.paymentVoucher.CRED_CARD_EXP_DT ? this.paymentVoucher.CRED_CARD_EXP_DT : null;
    reverseObj.APPROVAL_NO = this.paymentVoucher.APPROVAL_NO ? this.paymentVoucher.APPROVAL_NO : null;
    reverseObj.BANK_BRANCH = this.paymentVoucher.BANK_BRANCH ? this.paymentVoucher.BANK_BRANCH : null;
    reverseObj.BANK_ID = this.paymentVoucher.BANK_ID ? this.paymentVoucher.BANK_ID : null;
    reverseObj.BASE_VOUCHER_ID = this.paymentVoucher.BASE_VOUCHER_ID ? this.paymentVoucher.BASE_VOUCHER_ID : null;
    reverseObj.NARRATION = this.paymentVoucher.NARRATION;
    reverseObj.CRED_LMT_TYPE = this.paymentVoucher.CRED_LMT_TYPE ? Number(this.paymentVoucher.CRED_LMT_TYPE) : 0;
    reverseObj.TDS_APPLIED = this.paymentVoucher.TDS_APPLIED ? this.paymentVoucher.TDS_APPLIED : 'false',
      reverseObj.ACTIVE = this.paymentVoucher.ACTIVE ? this.paymentVoucher.ACTIVE : 'true';
    // reverseObj.VOUCHER_NO = this.paymentVoucher.VOUCHER_NO?Number(this.paymentVoucher.VOUCHER_NO):0;
    reverseObj.VOUCHER_NO = this.voucherDetails.documentNo; // getting from payment
    reverseObj.REV_VCHR_TYPE_ID = 901; // need add key
    reverseObj.VCHR_STATUS = this.paymentVoucher.VCHR_STATUS ? this.paymentVoucher.VCHR_STATUS : 1;// not ui need check;
    reverseObj.REVERSE_VCHR_STATUS = 3; //this.paymentVoucher.REVERSE_VCHR_STATUS // need add key
    reverseObj.DOC_TYPE = this.paymentVoucher.DOC_TYPE ? this.paymentVoucher.DOC_TYPE : 1;
    reverseObj.REV_DOC_TYPE = 9 // this.paymentVoucher.REV_DOC_TYPE
    reverseObj.PARTY_NAME = this.paymentVoucher.PARTY_NAME;
    reverseObj.APPLIED_TDS_AMOUNT = 0; // this.paymentVoucher.APPLIED_TDS_AMOUNT
    // reverseObj.VOUCHER_ACC_DETAILS = this.paymentVoucher.VoucherAccDetail.length > 0 ? this.paymentVoucher.VoucherAccDetail : [];
    let voucherDtl: any = [];
    if (this.paymentVoucher.VoucherAccDetail.length > 0) {
      this.paymentVoucher.VoucherAccDetail.filter(val => {
        voucherDtl.push({
          'GL_CODE': val.GL_CODE,
          'SL_CODE': val.SL_CODE,
          'ACC_VALUE': val.VCHR_VALUE,
          'CREDIT_LIMIT_TYPE': '1', // need  key default 1
          'IS_DEBIT': val.IS_DEBIT
        });
      });
    }
    reverseObj.VOUCHER_ACC_DETAILS = voucherDtl.length > 0 ? voucherDtl : [];
    reverseObj.LEDGER_ENTRY_DET = this.paymentVoucher.LedgerEntryDet.length > 0 ? this.paymentVoucher.LedgerEntryDet : [];
    this.isShowPageLoader = true;
    this.masterService.post(reverseObj, '/Voucher/ReversePayment').subscribe(
      (resp: any) => {
        if (resp) {
          this.isShowPageLoader = false;
          this.toastr.success(resp.statusMessage);
          this.closeDialog();
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      });
  }
  reverseReceipt() {
    this.paymentVoucher.LedgerEntryDet = [];
    this.assignLedgerEntryDetails();
    let reverseReceiptObj: any = {};
    if (this.validatePayment() != true) {
      return;
    }
    reverseReceiptObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reverseReceiptObj.COMPANY_ID = this.paymentVoucher.COMPANY_ID.toString();
    reverseReceiptObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reverseReceiptObj.VOUCHER_NO = this.paymentVoucher.VOUCHER_NO ? this.paymentVoucher.VOUCHER_NO : 117;
    reverseReceiptObj.CREATED_BY = CommonService.getUserData().USER_ID.toString(); // to  string 
    reverseReceiptObj.VOUCHER_DT = CommonService.setDateTime(this.paymentVoucher.VOUCHER_DT);
    reverseReceiptObj.VCHR_TYPE_ID = Number(this.paymentVoucher.VCHR_TYPE_ID);
    reverseReceiptObj.REV_VCHR_TYPE_ID = 201; // need add key
    reverseReceiptObj.VCHR_VALUE = Number(this.paymentVoucher.VCHR_VALUE);
    reverseReceiptObj.VCHR_STATUS = this.paymentVoucher.VCHR_STATUS ? this.paymentVoucher.VCHR_STATUS : 2;
    reverseReceiptObj.FIN_YEAR = this.paymentVoucher.FIN_YEAR.toString();
    reverseReceiptObj.REVERSE_VCHR_STATUS = 3; //this.paymentVoucher.REVERSE_VCHR_STATUS // need add key
    reverseReceiptObj.PAYMENT_MODE_ID = this.paymentVoucher.PAYMENT_MODE_ID;
    reverseReceiptObj.DOCUMENT_ID = this.paymentVoucher.DOCUMENT_ID;
    reverseReceiptObj.DOC_TYPE = this.paymentVoucher.DOC_TYPE ? this.paymentVoucher.DOC_TYPE : 2;
    reverseReceiptObj.REV_DOC_TYPE = "1"  // this.paymentVoucher.REV_DOC_TYPE
    reverseReceiptObj.DOC_DATE = CommonService.setDateTime(this.paymentVoucher.DOC_DATE);
    reverseReceiptObj.BASE_DOC_TYPE = this.paymentVoucher.BASE_DOC_TYPE ? Number(this.paymentVoucher.BASE_DOC_TYPE) : '6';
    reverseReceiptObj.PARTY_CODE = this.paymentVoucher.PARTY_CODE.toString();
    reverseReceiptObj.PARTY_NAME = this.paymentVoucher.PARTY_NAME;
    reverseReceiptObj.INSTR_NO = this.paymentVoucher.INSTR_NO ? this.paymentVoucher.INSTR_NO : null;
    reverseReceiptObj.INSTR_DATE = this.paymentVoucher.INSTR_DATE ? CommonService.setDateTime(this.paymentVoucher.INSTR_DATE) : null;
    reverseReceiptObj.INSTR_AMT = this.paymentVoucher.INSTR_AMT ? this.paymentVoucher.INSTR_AMT : null;
    reverseReceiptObj.INSTRUMENT_ON = this.paymentVoucher.INSTRUMENT_ON ? this.paymentVoucher.INSTRUMENT_ON : null;
    reverseReceiptObj.ACCOUNT_NO = this.paymentVoucher.ACCOUNT_NO ? this.paymentVoucher.ACCOUNT_NO : null;
    reverseReceiptObj.PARTY_CAT = Number(this.paymentVoucher.PARTY_CAT);
    reverseReceiptObj.CRED_CARD_TY_ID = this.paymentVoucher.CRED_CARD_TY_ID ? this.paymentVoucher.CRED_CARD_TY_ID : null;
    reverseReceiptObj.CRED_CARD_EXP_DT = this.paymentVoucher.CRED_CARD_EXP_DT ? this.paymentVoucher.CRED_CARD_EXP_DT : null;
    reverseReceiptObj.APPROVAL_NO = this.paymentVoucher.APPROVAL_NO ? this.paymentVoucher.APPROVAL_NO : null;
    reverseReceiptObj.BANK_BRANCH = this.paymentVoucher.BANK_BRANCH ? this.paymentVoucher.BANK_BRANCH : null;
    reverseReceiptObj.BANK_ID = this.paymentVoucher.BANK_ID ? this.paymentVoucher.BANK_ID : null;
    reverseReceiptObj.BASE_VOUCHER_ID = this.paymentVoucher.BASE_VOUCHER_ID ? this.paymentVoucher.BASE_VOUCHER_ID : null;
    reverseReceiptObj.NARRATION = this.paymentVoucher.NARRATION;
    reverseReceiptObj.CRED_LMT_TYPE = this.paymentVoucher.CRED_LMT_TYPE ? Number(this.paymentVoucher.CRED_LMT_TYPE) : 1;
    reverseReceiptObj.TDS_APPLIED = this.paymentVoucher.TDS_APPLIED ? this.paymentVoucher.TDS_APPLIED : 'false',
      reverseReceiptObj.ACTIVE = this.paymentVoucher.ACTIVE ? this.paymentVoucher.ACTIVE : 'true';
    reverseReceiptObj.APPLIED_TDS_AMOUNT = 0; // this.paymentVoucher.APPLIED_TDS_AMOUNT
    // reverseObj.VOUCHER_ID = this.paymentVoucher.VOUCHER_ID;
    // reverseObj.UNRECON_VAL = this.paymentVoucher.UNRECON_VAL ? this.paymentVoucher.UNRECON_VAL : '0';
    reverseReceiptObj.VOUCHER_ACC_DETAILS = this.paymentVoucher.VoucherAccDetail.length > 0 ? this.paymentVoucher.VoucherAccDetail : [];
    reverseReceiptObj.LEDGER_ENTRY_DET = this.paymentVoucher.LedgerEntryDet.length > 0 ? this.paymentVoucher.LedgerEntryDet : [];

    reverseReceiptObj = {
      "DEALER_ID": 10891,
      "BRANCH_ID": 1,
      "VOUCHER_NO": 117,
      "CREATED_BY": "67808",
      "VOUCHER_DT": "2019-11-18",
      "VCHR_TYPE_ID": 217,
      "REV_VCHR_TYPE_ID": 101,
      "VCHR_VALUE": 7000,
      "VCHR_STATUS": 2,
      "FIN_YEAR": "2019",
      "REVERSE_VCHR_STATUS": 3,
      "COMPANY_ID": "4133",
      "PAYMENT_MODE_ID": "1",
      "DOCUMENT_ID": "26262",
      "DOC_TYPE": 2,
      "REV_DOC_TYPE": "1",
      "DOC_DATE": "2019-11-18",
      "BASE_DOC_TYPE": "6",
      "PARTY_CODE": "1572606",
      "PARTY_NAME": "DINESH VERMA",
      "INSTR_NO": null,
      "INSTR_DATE": null,
      "INSTR_AMT": null,
      "INSTRUMENT_ON": null,
      "ACCOUNT_NO": null,
      "PARTY_CAT": 1,
      "CRED_CARD_TY_ID": null,
      "CRED_CARD_EXP_DT": null,
      "APPROVAL_NO": null,
      "BANK_BRANCH": null,
      "BANK_ID": null,
      "BASE_VOUCHER_ID": null,
      "NARRATION": "sang",
      "CRED_LMT_TYPE": 1,
      "TDS_APPLIED": "false",
      "APPLIED_TDS_AMOUNT": 0,
      "ACTIVE": "true",
      "VOUCHER_ACC_DETAILS": [
        {
          "GL_CODE": "11800001",
          "SL_CODE": "6225442",
          "ACC_VALUE": "7000",
          "CREDIT_LIMIT_TYPE": "1",
          "DEBIT_CREDIT": "false"
        },
        {
          "GL_CODE": "11600001",
          "SL_CODE": 0,
          "ACC_VALUE": "7000",
          "CREDIT_LIMIT_TYPE": "1",
          "DEBIT_CREDIT": "true"
        }
      ],
      "LEDGER_ENTRY_DET": [
        {
          "DEALER_ID": "10891",
          "VOUCHER_DATE": "2019-11-18",
          "FIN_YEAR": "2019",
          "VOUCHER_SUB_TYPE": "119",
          "PARTY_CAT": "1",
          "PARTY_CODE": "1724131",
          "VOUCHER_STATUS": "1",
          "NARRATION": "dsf",
          "VOUCHER_TYPE": "1",
          "COMPANY_ID": "4133",
          "GL_CODE": "11800001",
          "SL_CODE": "6225438",
          "VCHR_VALUE": "5000",
          "IS_DEBIT": "false"
        },
        {
          "DEALER_ID": "10891",
          "VOUCHER_DATE": "2019-11-18",
          "FIN_YEAR": "2019",
          "VOUCHER_SUB_TYPE": "119",
          "PARTY_CAT": "1",
          "PARTY_CODE": "1724131",
          "VOUCHER_STATUS": "1",
          "NARRATION": "dsf",
          "VOUCHER_TYPE": "1",
          "COMPANY_ID": "4133",
          "GL_CODE": "11600001",
          "SL_CODE": null,
          "VCHR_VALUE": "5000",
          "IS_DEBIT": "true"
        }
      ]
    };
    this.masterService.post(reverseReceiptObj, '/Voucher/ReverseReceipt').subscribe(
      (resp: any) => {
        console.log('result respone', resp);
        this.toastr.success(resp.statusMessage);
      });
  }
  // closeDialog(): void {
  //this.dialo
  //  this.dialogRef.close(false);
  // this.commonService.sendMessage('close');
  // }
}

@Component({
  selector: 'app-payment-voucher-dialog',
  template: `<app-payment-voucher [isView]="isView" [paymentDetail]="paymentDetails" ></app-payment-voucher>`,

})
export class CustomDialog {
  public isActivePopup: any = false;
  public isShowPageLoader: any = false;
  @Input() paymentDetail: any;
  public paymentDetails: any;
  isView = true;
  constructor(
    public dialogRef: MatDialogRef<CustomDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private toastr: ToastrService
  ) {
    this.isActivePopup = true;
    this.paymentDetails = {
      details: this.data,
      isActive: 'view'
    }
    // this.commonService.sendMessage(viewObj);
    this.commonService.getMessage().subscribe(message => {
      if (message && message.name === 'close') {
        this.onClose();
      }
    });
  }

  onClose(): void {
    this.dialogRef.close(false);
  }
}


/**
 * Created BY HMSPL 
 * Job Card Service Class
 * Last Last Modified By Balaji on 10/10/2018
 * Last Modified Description : Created JOb Card related services
 */

 import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { RequestService } from 'src/app/api-services/request.service';
 
 @Injectable({
     providedIn: 'root'
 })
 export class JobCardService {
     constructor(private httpClient: HttpClient) {
     }
     /**
      * Get Job Card List
      * @param {Object} params
      * return Object
      */
     getJobCardList(params) {
         const resData = RequestService.WebApiReq('/JobCard/SearchJobCardDetails');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
 
          //added by ankit anjan
 /**
      * Susband and Cancel
      */
  SaveWarrantyTagForJc(params) 
  {
     const resData = RequestService.postApiURLReq('/JobCard/SaveWarrantyTagForJc');
     return this.httpClient.post(resData.url, params, { headers: resData.headers });
 }
 
  /**
      * Print Job Card
      * @param {Object} params
      * return Object
      */
     GetJcDetailsForWarrantyTagJc(params) {
         const resData = RequestService.getApiURLReq('/JobCard/GetjcDeatilsForWarrantyTagJobcard');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     
     /**
      * Print Job Card
      * @param {Object} params
      * return Object
      */
  GetFreeTextdeatilsOfWarrantyTag(params) {
     const resData = RequestService.getApiURLReq('/JobCard/GetFreeTextdeatilsOfWarrantyTag');
     return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
 }
 
 
     /**
      * Get Frame Details
      * @param {Object} params
      * return Object
      */
     getFrameDetails(params) {
         const resData = RequestService.getApiURLReq('/Service/GetFrameDetails1');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Vehicle Frame Details
      * @param {Object} params
      * return Object
      */
     getVehicleFrameDetails(params) {
         const resData = RequestService.getApiURLReq('/Service/ChkFrame');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Frame Details
      * @param {Object} params
      * return Object
      */
     getAppointDetails(params) {
         const resData = RequestService.getApiURLReq('/Service/SearchServAppointmentDetforJC');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Search Frame Details
      * @param {Object} params
      * return Object
      */
     searchFrameDetails(params) {
         const resData = RequestService.getApiURLReq('/Service/VehicleSearch');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Search Appointment No
      * @param {Object} params
      * return Object
      */
     searchAppointmentNo(params) {
         const resData = RequestService.getApiURLReq('/Service/SearchServiceAppDetails');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     PartNumberDescriptionWithStock(params) {
         const resData = RequestService.getApiURLReq('/Service/PartNumberDescriptionWithStock');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
      * Save customer
      * @param {Object} params
      * return Object
      */
     saveCustomer(params) {
         const resData = RequestService.postApiURLReq('/JobCard/SaveCustomervehicleForJobCard');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Save Job Card
      * @param {Object} params
      * return Object
      */
     saveJobCardDetails(params) {
         const resData = RequestService.postApiURLReq('/JobCard/SaveJobCardDetails');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
        // return this.httpClient.post(resData.url, params);
     }
 
 
      /**
      * Save Job Card
      * @param {Object} params
      * return Object
      */
     saveMandatoryJobCardDetails(params) {
         const resData = RequestService.postApiURLReq('/JobCard/saveMandatoryJobCardDetails');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
 
     /**
      * get Job Card Details
      * @param {Object} params
      * return Object
      */
     getJobCardDetails(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PopulateJobCardDetailsAngular?');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Complaint Group
      * @param {Object} params
      * return Object
      */
     getComplaintGroup(params) {
         const resData = RequestService.getApiURLReq('/JobCard/GetComplaintGroupApi');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Complaint List
      * @param {Object} params
      * return Object
      */
     getComplaintList(params) {
         const resData = RequestService.getApiURLReq('/JobCard/LoadComplaintsByGroupId');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Labour Code
      * @param {Object} params
      * return Object
      */
     getJobCardLabourCode(params) {
         const resData = RequestService.getApiURLReq('/Service/LoadLabourApi');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     getalljobs(params) {
         const resData = RequestService.getReq('/Service/LoadJobtype');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Job Card Labour
      * @param {Object} params
      * return Object
      */
     getJobCardLabour(params) {
         const resData = RequestService.getApiURLReq('/JobCard/GetJobCardlabour');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Job Card Labour Details
      * @param {Object} params
      * return Object
      */
     getJobCardLabourDetails(params) {
         const resData = RequestService.getApiURLReq('/Service/GetLabourDetailsApi');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Job Card Labour Details
      * @param {Object} params
      * return Object
      */
     deleteJobCardLabour(params) {
         const resData = RequestService.getApiURLReq('/JobCard/DeleteJobCardLabour');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Save Job Card Labour
      * @param {Object} params
      * return Object
      */
     saveJobCardLabour(params) {
         const resData = RequestService.postApiURLReq('/JobCard/SaveJobCardLabour');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Save Job Card Part
      * @param {Object} params
      * return Object
      */
     saveJobCardPart(params) {
         const resData = RequestService.postApiURLReq('/JobCard/SaveIssueSparesToJobcard');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Bulk Save
      * @param {Object} params
      * return Object
      */
     bulkSave(params) {
         const resData = RequestService.postApiURLReq('/JobCard/SaveBulkIssueSparesToJobcard');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Load AMC
      * @param {Object} params
      * return Object
      */
     loadAMC(params) {
         const resData = RequestService.getApiURLReq('/Service/loadAMCType');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Complete Job Card
      * @param {Object} params
      * return Object
      */
     completeJobCard(params) {
         const resData = RequestService.postApiURLReq('/JobCard/JobCardComplete');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Get Job Part Numbers
      * @param {Object} params
      * return Object
      */
     getPartNumbers(params) {
         const resData = RequestService.getApiURLReq('/Service/LoadPartNumbers');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     getPartNumber(params) {
         const resData = RequestService.uvdReq('/Service/LoadPartNumbers');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 /**
      * Get Job Part Master List
      * @param {Object} params
      * return Object
      */
     getEcatalogParts(params) {
         const resData = RequestService.getApiURLReq('/JobCard/ECatalogParts');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
 
     /**
      * Get Job Part Master List
      * @param {Object} params
      * return Object
      */
     getPartMasterList(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PopulateIssueSparePartDetailsById');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Job Part Details
      * @param {Object} params
      * return Object
      */
     getPartDetails(params) {
         const resData = RequestService.getApiURLReq('/Service/GetSelectedPartDetails');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     LoadDefIssueParts(params) {
         const resData = RequestService.getApiURLReq('/JobCard/GetDefaultIssueParts');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Estimate Part Details
      * @param {Object} params
      * return Object
      */
     getEstimatePartDetails(params) {
         const resData = RequestService.getApiURLReq('Service/EstimatedCostPart');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Calculate spare tax
      * @param {Object} request
      * return Object
      */
     calculateSpareTax(request) {
         const resData = RequestService.getApiURLReq('/JobCard/CalculateSpareTax');
         return this.httpClient.get(resData.url, { params: request, headers: resData.headers });
     }
     /**
      * Print Gate Pass
      * @param {Object} params
      * return Object
      */
     printGatePass(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PrintGatePass');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Resend Coupon
      * @param {Object} request
      * return Object
      */
     resendCoupon(request) {
         const resData = RequestService.postApiURLReq('/JobCard/Resend_OTP');
         return this.httpClient.post(resData.url, request, { headers: resData.headers });
     }
     /**
      * Print Job Card
      * @param {Object} params
      * return Object
      */
     printJobCard(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PrintJobcard');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Tyre Print
      * @param {Object} params
      * return Object
      */
     tyrePrint(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PopulateTyrePrint');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Warrenty Tag
      * @param {Object} params
      * return Object
      */
     warrentyTag(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PrintWarrantyTag');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
      * Search Customer
      */
     searchCustomer(params) {
         const resData = RequestService.getApiURLReq('/Service/SearchCustomer');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
      * Get Estimation List
      */
 
     getEstimationList(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PopulateJobCardEstimatedDetails');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Reason List
      */
 
     getReasonList(params) {
         const resData = RequestService.getApiURLReq('/Service/loadReasonsForCancelJC');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Get Status List
      */
 
     getStatusList(params) {
         const resData = RequestService.getApiURLReq('/Service/GetJobCardStatusList');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Susband and Cancel
      */
     susbandAndCancel(params) {
         const resData = RequestService.postApiURLReq('/JobCard/SaveJobcardStatus');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Revoke
      */
     jobCardRevoke(params) {
         const resData = RequestService.postApiURLReq('/JobCard/JobCardRevoke');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Save Job Card Estimation
      */
     saveDetailedEstimation(params) {
         const resData = RequestService.postApiURLReq('/JobCard/SaveJobcardEstimation');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
     /**
      * Save Job Card Estimation
      */
     getDetailedEst(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PopulateJobPartLabourEstimation');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * Load State
      */
     getStateList(params) {
         const resData = RequestService.getApiURLReq('/Service/LoadState');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
      * RackList
      */
     getRackList(params) {
         const resData = RequestService.getReq('PopulateRackandBinDetails');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     /**
     * Load State
     */
     getCentralMobiledetails(params) {
         const resData = RequestService.getApiURLReq('/Service/LoadCentralDetByMobile');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     getCentralFramedetails(params) {
         const resData = RequestService.getApiURLReq('/Service/LoadCentralDetByframe');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
     // /**
     //  * Calculate Tax
     //  */
     // calculateTax(params){
     //     const resData = RequestService.postReq('CalculateSpareTax');
     //     return this.httpClient.post(resData.url, params);                
     // }
     /**
      * Get Mobile No exists in any other job card for same dealer
      * @param {Object} params
      * return Object    
      */
     checkMobileNoExists(params) {
         const resData = RequestService.getApiURLReq('/Service/CheckMobileNumberExists');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
 /**
      * Get Mobile No exists in any other job card for same dealer
      * @param {Object} params
      * return Object
      */
     loadPMPMsg(params) {
         const resData = RequestService.getApiURLReq('/JobCard/LoadPMPMessage');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
      * Get Job Part Details
      * @param {Object} params
      * return Object
      */
     getPartDetailsJobCardNew(params) {
         const resData = RequestService.getApiURLReq('/Service/GetSelectedPartDetailsJobCardNew');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
 
      /**
      * Get Job Part Details
      * @param {Object} params
      * return Object
      */
     PartNumberDescription(params) {
         const resData = RequestService.getApiURLReq('/Service/PartNumberDescription');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
  * RackList
  */
     getRackListJobCardNew(params) {
         const resData = RequestService.getApiURLReq('/Service/PopulateRackandBinDetailsJobCardNew');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
  * Calculate spare tax
  * @param {Object} request
  * return Object
  */
     calculateSpareTaxNew(request) {
         const resData = RequestService.postApiURLReq('/Service/CalculateSpareTaxNew');
         return this.httpClient.post(resData.url, request, { headers: resData.headers });
     }
 
     /**
      * Get Job Card Labour Details
      * @param {Object} params
      * return Object
      */
     getJobCardLabourDetailsNew(params) {
         const resData = RequestService.getApiURLReq('/JobCard/GetLabourDetailsApiNew');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
  * Load AMC
  * @param {Object} params
  * return Object
  */
     loadAMCNew(params) {
         const resData = RequestService.getApiURLReq('/JobCard/loadAMCTypeJobCardNew');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
       /**
  * Load AMC
  * @param {Object} params
  * return Object
  */
        loadCWIAMCNew(params) {
         const resData = RequestService.getApiURLReq('/JobCard/loadCWIAMCTypeJobCardNew');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
      * Dlr Amc Change Event
      * @param {Object} params
      * return Object
      */
     dlrAMCChangeEvent(request) {
         const resData = RequestService.getApiURLReq('/JobCard/GetLabourLineChangesafterDLRAMC');
         return this.httpClient.get(resData.url, { params: request, headers: resData.headers });
     }
 
     /**
      * Print Job Card Estimation
      */
     printJobCardEstimation(params) {
         const resData = RequestService.getApiURLReq('/JobCard/PrintCostEstimation');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
    /**
      * Get pending jobcard details for status updation
      */
     getPendingJobcardDetails(params) {
         const resData = RequestService.getApiURLReq('/JobCard/GetpendingjobcardDetails');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
      * Get pending jobcard details for status updation
      */
     getJobPendingJobcardDetails(params) {
         const resData = RequestService.getApiURLReq('/JobCard/GetJobpendingjobcardDetails');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
     /**
      * Get pending jobcard details for status updation
      */
     getJobcardMandatoryPartNumber(params) {
         const resData = RequestService.getApiURLReq('/JobCard/GetJobcardMandatoryPartNumber');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
     }
 
         /**
      * Update pending jobcard status
      * @param {Object} params
      * return Object
      */
     updatePendingJobcardStatus(params) {
         const resData = RequestService.postApiURLReq('/JobCard/UpdatePendingJobcardStatus');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
        /**
     * Get Recall Refit Parts
     * @param {Object} request
     * return Object
     */
    getRecallRefitParts(request) {
     const resData = RequestService.getApiURLReq('/JobCard/RecallRefitParts');
     return this.httpClient.get(resData.url, { params: request, headers: resData.headers });
     }
 
      /**
     * Get Recall Refit Status
     * @param {Object} request
     * return Object
     */
    getRecallRefitStatus(request) {
     const resData = RequestService.getApiURLReq('/JobCard/getRecallRefitStatus');
     return this.httpClient.get(resData.url, { params: request, headers: resData.headers });
     }
 
     /**
     * Get Recall Refit Status
     * @param {Object} request
     * return Object
     */
    saveRecallRefitRejectReason(request) {
     const resData = RequestService.postApiURLReq('/JobCard/updateRecallRefitRejectReason');
     return this.httpClient.post(resData.url, request, { headers: resData.headers });
     }
     /**
     * Update Recall Refit Part 
     */
    updateRecallRefit(request) {
     const resData = RequestService.postApiURLReq('/JobCard/updateRecallRefitParts');
     return this.httpClient.post(resData.url, request, { headers: resData.headers });
     }
 
     /**
      * PMP Parts Get Based on Job Card
      * @param {Object} params
      * return Object
      */
     pmpPartsGet(params) {
         const resData = RequestService.getApiURLReq('/Service/CheckPMPMandPartsList');
         return this.httpClient.get(resData.url, { params: params, headers: resData.headers });;
     }
 
     /**
      * PMP Cancel Reason Save
      * @param {Object} params
      * return Object
      */
      pmpCancelSave(params) { 
         const resData = RequestService.postApiURLReq('/Service/SavePMPReasons');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
 
     /**
      * Issue Spares Validation
      * @param {Object} params
      * return Object
      */
      checkIssueMode(params) {
         const resData = RequestService.postApiURLReq('/Service/CheckIssueMode');
         return this.httpClient.post(resData.url, params, { headers: resData.headers });
     }
 
        // //Code Changed By Anil- START
        getQuickRepairJobDetails() {
         const resData = RequestService.getApiURLReq('/JobCard/QuickRepairJobDetails');
         return this.httpClient.get(resData.url, { headers: resData.headers });
     } 
      //Code Changed By Anil- END
 }
 
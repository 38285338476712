import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-rack-master',
  templateUrl: './create-rack-master.component.html',
  styleUrls: ['./create-rack-master.component.scss']
})
export class CreateRackMasterComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public location: any;
  public rackDesc: any;
  public defaultRack: boolean = false;
  public rackList: any;
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateRackMasterComponent>) { }

  ngOnInit() {
    if (this.data.type == "MODIFY") {
      this.rackDesc = this.data.value.RACK_DESC
      this.location = this.data.value.LOCATION_ID;
      this.defaultRack = this.data.value.DEF_RACK;
    }
    this.getMasterData();
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  getMasterData() {
    this.isShowPageLoader = true;
    this.masterService.get('', '/DMSMaster/RackMaster?dealerId=' +
      CommonService.getUserData().DEALER_ID + '&branchId=' + CommonService.getUserData().BRANCH_ID).subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.masterData = resp.storageLocationList;
          }
          if (resp.statusCode == 204) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        })
  }

  saveRackData() {
    this.isShowPageLoader = true;
    let reqObj: any = {
      "DEALER_ID": CommonService.getUserData().DEALER_ID,
      "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
      "RACK_ID": 0,
      "LOCATION_ID": this.location,
      "RACK_DESC": this.rackDesc,
      "DEF_RACK": this.defaultRack,
      "DESCRIPTION": "",
    };
    if (this.data.type == "MODIFY") {
      reqObj.RACK_ID = this.data.value.RACK_ID;
      reqObj.DESCRIPTION = this.data.value.DESCRIPTION;
    }
    if(this.location == -1){
      this.toastr.info("Select One Location");
      this.isShowPageLoader = false;
      return;
    }
    else{
      this.masterData.filter(key => {
        if(this.location == key.LOCATION_ID){
          reqObj.DESCRIPTION = key.DESCRIPTION
        }
      })
    }
    if(this.rackDesc == ""){
      this.toastr.info("Rack Descreption Shoudn't Empty");
      this.isShowPageLoader = false;
      return;
    }
    this.masterService.post(reqObj, '/DMSMaster/SaveRack').subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.rackList = resp;
            this.dialogRef.close(this.rackList);
            this.toastr.success(resp.statusMessage)
          }
          if(resp.statusCode == 400){
            this.toastr.info(resp.statusMessage)
          }
          if (resp.statusCode == 204) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        })
  }

  closePopup() {
    this.dialogRef.close();
  }
}

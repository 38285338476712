import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent implements OnInit {
  public lang: any;
  public isActive: any;

  constructor(
    private dialogRef: MatDialogRef<InformationDialogComponent>,
    private toastr: ToastrService,
    private masterSales: MasterSales,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  selectedItem() {
    let closeObj = { obj: 'Yes' };
    this.dialogRef.close(closeObj);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}

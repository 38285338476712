import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { CommonService } from 'src/app/api-services/common.service';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-mobile-otp-dialog',
  templateUrl: './mobile-otp-dialog.component.html',
  styleUrls: ['./mobile-otp-dialog.component.scss']
})
export class MobileOtpDialogComponent implements OnInit {

  public lang: any;
  public searchLoader: any;
  public customerData: any = {};

  //timer
  public countDown: Subscription;
  public counter = 300;
  public tick = 1000;
  public type: any;

  constructor(public dialogRef: MatDialogRef<MobileOtpDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public toastr: ToastrService, private masterService: MasterService,) { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.customerData = this.data.customerDetails;
      this.type = this.data.type;
      this.customerData.smsNotify = true;
      this.otpTimer();
    }
  }

  verifyOtp() {
    if (this.customerData.OTPNumber && this.customerData.OTPNumber.length < 5) {
      this.toastr.error('Please Enter 5 digit OTP');
      return;
    }
    // this.isShowPageLoader = false;
    let reqObj: any = {
      dealerId: CommonService.getUserData().DEALER_ID,
      branchId: CommonService.getUserData().BRANCH_ID,
      OTPNumber: Number(this.customerData.OTPNumber),
      customerId: this.customerData.customerId,
      frameNo: this.customerData.frameNo,
      invoiceType: this.type == 'SALES' ? 1 : 2,
      smsDate: new Date(),
      customerName: this.customerData.customerName,
      mobileNo: this.customerData.mobileNo
    }
    if (this.type == 'SALES') {
      reqObj.modelName = this.customerData.modelName;
      reqObj.dealerName =  CommonService.getDealerData().DEALERSHIP_NAME;
      reqObj.dealerMobile =  CommonService.getDealerData().PHONE_NO;
    }
    this.masterService.post(reqObj, '/Sales/InvoiceOTPValidation').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.toastr.success('OTP verified successfully');
          let closeObj = { obj: resp };
          this.dialogRef.close(closeObj);
        } else if (resp && resp.statusCode == 204) {
          this.toastr.error('Invalid OTP');
        }
      },
      error => {
        // this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }

  resendOtp() {
    this.countDown.unsubscribe();
    this.customerData.OTPNumber = '';
    let objReq: any = {};
    objReq.dealerId = this.customerData.dealerId;
    objReq.branchId = this.customerData.branchId;
    objReq.customerId = this.customerData.customerId;
    objReq.customerName = this.customerData.customerName;
    objReq.documentId = this.customerData.documentId;
    objReq.frameNo = this.customerData.frameNo;
    objReq.invoiceType = this.customerData.invoiceType;
    objReq.mobileNo = this.customerData.mobileNo;
    objReq.smsDate = new Date();
    objReq.dealerName = this.customerData.dealerName;
    this.masterService.post(objReq, '/Sales/InvoiceOTPCreation').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.otpTimer();
          this.toastr.success('OTP sent to your mobile number');
        }
      },
      error => {
        // this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }
  //timer 
  otpTimer() {
    this.counter = 600;
    this.tick = 1000;
    this.countDown = this.getCounter(this.tick).subscribe(() => {
      this.counter--;
      if (this.counter <= 0) {
        this.countDown.unsubscribe();
        return;
      }
    });
  }

  getCounter(tick) {
    return timer(0, tick);
  }

  transform(value: number): string {
    //MM:SS format
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );

    // for HH:MM:SS
    //const hours: number = Math.floor(value / 3600);
    //const minutes: number = Math.floor((value % 3600) / 60);
    //return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.countDown.unsubscribe();
    this.countDown = null;
  }
}

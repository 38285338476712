/**
 * Created BY HMSPL 
 * Service Reminder Service Class
 * Last Modified By Dharvesh on 08/09/2018
 * Last Modified Description : Created Service Reminder related services
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceReminderService {
    constructor(private httpClient: HttpClient) {
    }
    /**
     * Get Service Reminder List
     * @param {Object} request 
     * return Object
    */
    getServiceReminder(request) {
        const resData = RequestService.postReq('SearchServiceReminder');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    /**
     * Get advance search dropdown data
     * @param {Object} params 
     * return Object
    */
    getAdvancedSearchData(params) {
        const resData = RequestService.getReq('ServiceRemainderAdvanceDropDowns');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get advanced Search data
     * @param {Object} request 
     * return Object
    */
    getAdvancedSearchRemainderList(request) {
        const resData = RequestService.postReq('AdvanceServiceRemainderSearchList');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
     /**
     * Save remainder and get remainder ID
     * @param {Object} request 
     * return Object
    */
    saveRemainder(request) {
        const resData = RequestService.postReq('ButtonFollowupClick');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    /**
     * Get All remainder details
     * @param {Object} params 
     * return Object
    */
    getReminderDetails(params) {
        const resData = RequestService.getReq('PopulateRemainder');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Save followup
     * @param {Object} request 
     * return Object
    */
    saveReminder(request) {
        const resData = RequestService.postReq('Save_Followup_Remainder');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    /**
     * Save followup
     * @param {Object} request 
     * return Object
    */
    sendSMS(request) {
        const resData = RequestService.postReq('SendServRemSMS');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
}
import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PartCostRootObject } from '../../api-services/dto/part-cost';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { PartSearchComponent } from '../part-search/part-search.component';
import { LoginService } from 'src/app/api-services/login.service';
@Component({
  selector: 'app-warehouse-part-cost',
  templateUrl: './warehouse-part-cost.component.html',
  styleUrls: ['./warehouse-part-cost.component.scss']
})
export class WarehousePartCostComponent implements OnInit {
  public lang: any;
  public isShowPageLoader: boolean = false;
  public isWarehouse: boolean = false;
  public vendorName: any;
  public partDetails = new PartCostRootObject();
  public masterDetails: any;
  public countryCode: any;
  @ViewChild("helpTemplate") helpTemplate: TemplateRef<any>;
  public taxDialogRef: MatDialogRef<any, any>;
  public taxInfo: any = {};
  public warehousePaidPercentage: number;
  public wareHouseList: any = [];
  constructor(public dialogRef: MatDialogRef<WarehousePartCostComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    private masterService: MasterService,
    private loginService: LoginService,
    public toastr: ToastrService) { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      if (localStorage.getItem('userData')) {
        let userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.d && userData.d.data && userData.d.data.User) {
          this.countryCode = userData.d.data.COUNTRY_CODE;
        }
      }
      this.partDetails.cgst = 0;
      this.partDetails.igst = 0;
      this.partDetails.sgst = 0;
      if (this.data) {
        this.wareHouseList = this.data.wareHouseList;
        console.log(this.data)
        this.masterDetails = this.data.masterData;
        this.partDetails = this.data.partInfo;
        this.isWarehouse = this.data.wareHouseCost;
        // this.partDetails.partId = this.data.partInfo.partNo;
        // this.partDetails.partName = this.data.partInfo.partName;
        this.partDetails.manufactureId = this.data.partInfo.manufacturerId;
        this.vendorName = this.data.vendorInfo.vendorName;
        this.partDetails.vendorId = this.data.vendorInfo.vendorId;
        // this.partDetails.warehouseId = "";
        if (this.data.partInfo.active == undefined) {
          this.partDetails.active = true;
        }
        else {
          this.partDetails.active = this.data.partInfo.active;
        }
      }
    }
    if (this.data && (this.data.mode == "MODIFY" || this.data.mode == "VIEW") && this.data.type == "partMasterCost") {
      this.partDetails = this.data.partInfo;
      this.partDetails.manufactureId = this.data.manufactureId;
      this.partDetails.vendorId = this.data.vendorInfo.vendorId;
      this.vendorName = this.data.vendorInfo.vendorId;
      this.partDetails.cgst = this.data.partInfo.cgst;
      this.partDetails.igst = this.data.partInfo.igst;
      this.partDetails.sgst = this.data.partInfo.sgst;
      this.partDetails.warehouseId = this.data.partInfo.warehouseId;
      this.invoiceAMT();
      this.partDetails.validFrom = CommonService.setDateTime(this.data.partInfo.validFrom);
    }
    else {
      this.partDetails.validFrom = new Date();
    }
  }
  close() {
    this.dialogRef.close(false);
  }
  validatePartCost() {
    let costError: any = 0;
    if (!this.partDetails.partNo) {
      this.toastr.error('Part No is empty');
      costError++;
    }
    if (!this.partDetails.partName) {
      this.toastr.error('Part Name is empty');
      costError++;
    }
    if (!this.partDetails.manufactureId) {
      this.toastr.error('Manufacture Name is empty ');
      costError++;
    }
    if (!this.partDetails.warehouseId) {
      this.toastr.error('Warehouse Id is empty ');
      costError++;
    }
    if (!this.vendorName) {
      this.toastr.error('Vendor name is empty');
      costError++;
    }
    if (!this.partDetails.invoicePrice) {
      this.toastr.error('Part cost is empty');
      costError++;
    }
    if (this.data.type == 'partMasterCost') {
      if (!this.partDetails.dealerPaidPercentage) {
        this.toastr.error('Dealer Paid Tax  is empty');
        costError++;
      }
      // if (!this.partDetails.tvsPaidPercentage) {
      //   this.toastr.error('TVS Paid Percentage Tax  is empty');
      //   costError++;
      // }
      if (!this.partDetails.dealerPaidTax) {
        this.toastr.error('Dealer Paid Tax  is empty');
        costError++;
      }
      // if(!this.partDetails.tvsPaidTax) {
      //   this.toastr.error('TVS Paid Tax is empty');
      //   costError++;
      // }
    }
    if (!this.partDetails.partCost) {
      this.toastr.error('Part Cost is empty');
      costError++;
    }
    if (costError > 0) {
      return false;
    } else {
      return true;
    }
  }
  invoiceAMT() {
    this.isShowPageLoader = true;
    if (this.partDetails.partId == null || this.partDetails.partId == undefined) {
      this.toastr.info("Part No is Missing");
      this.isShowPageLoader = false;
      return;
    }
    if (this.partDetails.vendorId == null || this.partDetails.vendorId == undefined ||
      this.partDetails.vendorId == "") {
      this.toastr.info("Vendor Name is Missing");
      this.isShowPageLoader = false;
      return;
    }
    if (this.partDetails.warehouseId == null || this.partDetails.warehouseId == undefined ||
      this.partDetails.warehouseId == "") {
      this.toastr.info("Warehouse is Missing");
      this.isShowPageLoader = false;
      return;
    }
    if (this.partDetails.invoicePrice == null || this.partDetails.invoicePrice == undefined) {
      this.toastr.info("Invoice Price is Missing");
      this.isShowPageLoader = false;
      return;
    }
    let reqObj = {
      "warehouseId": this.partDetails.warehouseId,
      "partId": Number(this.partDetails.partId) ? Number(this.partDetails.partId) : 0,
      "vendorId": Number(this.partDetails.vendorId) ? Number(this.partDetails.vendorId) : 0,
      "InitialPrice": Number(this.partDetails.invoicePrice) ? Number(this.partDetails.invoicePrice) : 0
    }
    this.masterService.post(reqObj, '/WarehouseSpareParts/gethsndetails').subscribe((resp: any) => {
      this.partDetails.partCost = resp.finalValue;
      this.partDetails.cgst = resp.cgst;
      this.partDetails.igst = resp.igst;
      this.partDetails.sgst = resp.sgst;
      this.warehousePaidPercentage = resp.cgst + resp.igst + resp.sgst;
      this.isShowPageLoader = false;
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }
  saveWarehousePartCost() {
    this.isShowPageLoader = true;
    if (this.partDetails.partId == null || this.partDetails.partId == undefined) {
      this.toastr.info("Part No is Missing");
      this.isShowPageLoader = false;
      return;
    }
    if (this.partDetails.vendorId == null || this.partDetails.vendorId == undefined) {
      this.toastr.info("Vendor Name is Missing");
      this.isShowPageLoader = false;
      return;
    }
    if (this.partDetails.warehouseId == null || this.partDetails.warehouseId == undefined) {
      this.toastr.info("Warehouse is Missing");
      this.isShowPageLoader = false;
      return;
    }
    if (this.partDetails.invoicePrice == null || this.partDetails.invoicePrice == undefined) {
      this.toastr.info("Invoice Price is Missing");
      this.isShowPageLoader = false;
      return;
    }
    if (this.partDetails.partCost == null || this.partDetails.partCost == undefined) {
      this.toastr.info("Landed Cost is Missing");
      this.isShowPageLoader = false;
      return;
    }
    let reqObj = {
      "warehouseId": this.partDetails.warehouseId ? this.partDetails.warehouseId : 0,
      "rowState": "I",
      "spareCostId": 0,
      "partId": this.partDetails.partId ? this.partDetails.partId : 0,
      "partNO": "",
      "manufactureId": this.partDetails.manufactureId,
      "vendorId": Number(this.partDetails.vendorId),
      "invoicePrice": Number(this.partDetails.invoicePrice),
      "validFrom": this.partDetails.validFrom,
      "warehousePaidTax": 0,
      "warehousePaidPercentage": 0,
      "actualPrice": Number(this.partDetails.partCost),
      "userId": this.partDetails.userId,
      "active": this.partDetails.active,
      "countryCode": "IN",
      "cgst": this.partDetails.cgst?this.partDetails.cgst:0,
      "sgst": this.partDetails.sgst?this.partDetails.sgst:0,
      "igst": this.partDetails.igst?this.partDetails.igst:0,
    }
    if (this.data.mode == "MODIFY") {
      reqObj.rowState = "U";
      reqObj.spareCostId = this.data.partInfo.spareCostId;
    }
    reqObj.warehousePaidTax = Number(this.partDetails.partCost) - Number(this.partDetails.invoicePrice);
    reqObj.warehousePaidPercentage = this.warehousePaidPercentage;
    this.masterService.post(reqObj, '/WarehouseSpareParts/SaveAndUpdateSpareCost').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.dialogRef.close({ msg: 'costDone', partInfo: this.partDetails });
          this.toastr.success(resp.statusMessage);
        }
        if (resp.statusCode == 306) {
          this.toastr.success(resp.statusMessage);
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    );
  }
  /**
     * part search pop up
     */
  partSearch() {
    const dialogRef = this.dialog.open(PartSearchComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: { value: 'data', type: 'warehousePartCost' }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        console.log(result);
        if (this.data && this.data.type == "partMasterCost") {
          if (result.obj.manufactureId == 1) {
            this.toastr.info('Price cannot be set for TVS Spare Parts')
            return;
          }
          this.partDetails.manufactureId = result.obj.manufactureId;
          if (this.masterDetails.manufacturerList && this.masterDetails.manufacturerList.length > 0) {
            this.masterDetails.manufacturerList = this.masterDetails.manufacturerList.filter(val => {
              return result.obj.manufactureId === val.manufacturerId
            })
          }
        }
        this.partDetails.partId = result.obj.partId;
        this.partDetails.partNo = result.obj.partNo;
        this.partDetails.partName = result.obj.partDescription;
      }
    });
  }


  addTaxInfo() {
    this.taxInfo = {};
    this.taxDialogRef = this.dialog.open(this.helpTemplate, {
      width: '50%',
      height: 'auto',
      disableClose: true
    });
    this.taxDialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });

  }

  setInvoicePrice() {
    this.partDetails.invoicePrice = this.taxInfo.costPrice;
    this.partDetails.dealerPaidPercentage = 0;
    this.partDetails.tvsPaidPercentage = 0;
    this.partDetails.dealerPaidTax = 0
    this.partDetails.tvsPaidTax = 0;
    this.partDetails.partCost = 0;
    this.taxDialogRef.close();

  }

  taxCalculation() {
    if (this.taxInfo.totalAmt && this.taxInfo.taxPer) {
      this.taxInfo.costPrice = (Number(this.taxInfo.totalAmt) * 100) / (Number(this.taxInfo.taxPer) + 100);
      if (this.taxInfo.costPrice) {
        this.taxInfo.costPrice = this.taxInfo.costPrice.toFixed(2);
      }
    }
  }

}
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { LoginService } from 'src/app/api-services/login.service';
import { CommonService } from 'src/app/api-services/common.service';
import { JobCardService } from '../../api-services/job-card.service';
import { ServiceAppoitmentService } from '../../api-services/service-appoitment.service';
import { JobCardInvoiceService } from '../../api-services/job-card-invoice.service';
import { MasterService } from 'src/app/api-services/master.service';
declare var moment: any;
@Component({
  selector: 'app-delivery-chalan-no-search-grn',
  templateUrl: './delivery-chalan-no-search-grn.component.html',
  styleUrls: ['./delivery-chalan-no-search-grn.component.scss']
})
export class DeliveryChalanNoSearchGrnComponent implements OnInit {
  public lang: any;
  public isActive: any;
  public selected = [];
  public isShowPageLoader: boolean = false;
  public key:any = "";
  public dcNo: any = "";
  public jcNo: any = "";
  public vehicleNo: any = "";
  public vendorName: any = "";
  public toDate: any = "";
  public fromDate: any = "";
  constructor(private toastr: ToastrService, private jobCardService: JobCardService,
    private serviceAppoitmentService: ServiceAppoitmentService,
    private jobCardInvoiceService: JobCardInvoiceService,
    private masterServices: MasterService, private loginService: LoginService,
    private dialogRef: MatDialogRef<DeliveryChalanNoSearchGrnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {
    if (CommonService.getLang()) {
      this.key = this.data.key;
      this.lang = CommonService.getLang();
      this.isActive = this.data.key; // for getting search key 
      /**
       * @param {String} isActive
       * adding search column values
       * Returns an array.
       */
      if (this.isActive == 'JcNo') {
        this.data = {
          columns: [
            { name: this.lang.PART.jcNo, prop: 'JcNo' },
            { name: this.lang.PART.jcDate, prop: 'JcDate' },
            { name: this.lang.PART.dcNo, prop: 'DcNo' },           
            { name: this.lang.SALES.vendorName, prop: 'VendorName' },
            { name: this.lang.SERVICES.frameNo, prop: 'Frameo' },
          ]
        };
      }
      if (this.isActive == 'DcNo') {
        this.data = {
          columns: [
            { name: this.lang.PART.dcNo, prop: 'DcNo' },
            { name: this.lang.PART.dcDate, prop: 'DcDate' },
            { name: this.lang.PART.jcNo, prop: 'JcNo' },
            { name: this.lang.SALES.vendorName, prop: 'VendorName' },
            { name: this.lang.SERVICES.frameNo, prop: 'Frameo' },
          ]
        };
      }
      this.searchItem();
    }
  }

  /**
    * @param {String} searchItem
    * get search table data
    * Returns an array.
    */
  searchItem() {
    // if(this.isActive == 'JcNo'){
    //   if(this.jcNo == '' ||this.jcNo == null || this.jcNo == undefined){
    //     this.toastr.info("Please Fill One Search Criteria");
    //     return;
    //   } 
    // }
    // if(this.isActive == 'DcNo'){
    //   if(this.dcNo == '' ||this.dcNo == null || this.dcNo == undefined){
    //     this.toastr.info("Please Fill One Search Criteria");
    //     return;
    //   } 
    // }
    let url = 'DCGRN/searchJCDC?DealerId=' + CommonService.getUserData().DEALER_ID + '&BranchId=' + CommonService.getUserData().BRANCH_ID + '&DcNo=' + this.dcNo + '&JcNo=' + this.jcNo + '&VehicleFrameNo=' + this.vehicleNo + '&VendorName=' + this.vendorName;
    if(this.key == 'DcNo')
    {
      url = url + '&FromDate=' + CommonService.getReqDateFormat(this.fromDate) + '&ToDate=' + CommonService.getReqDateFormat(this.toDate)
    }
    else
    {
      url = url + '&FromDateJC=' + CommonService.getReqDateFormat(this.fromDate) + '&ToDateJC=' + CommonService.getReqDateFormat(this.toDate)
    }
    this.isShowPageLoader = true;
    this.masterServices.get('', url
    // 'DCGRN/searchJCDC?DealerId=' 
    //     + CommonService.getUserData().DEALER_ID
    //     + '&BranchId=' 
    //     + CommonService.getUserData().BRANCH_ID
    //     + '&DcNo=' 
    //     + this.dcNo  
    //     + '&JcNo=' 
    //     + this.jcNo 
    //     + '&VehicleFrameNo='
    //     + this.vehicleNo
    //     + '&VendorName='
    //     + this.vendorName
        // + '&FromDate='
        // + this.key == 'DcNo' ? CommonService.getReqDateFormat(this.fromDate) : ''
        // + '&ToDate='
        // + this.key == 'DcNo' ? CommonService.getReqDateFormat(this.toDate) : ''
        // + '&FromDateJC='
        // + this.key == 'DcNo' ? CommonService.getReqDateFormat(this.fromDate) : ''
        // + '&ToDateJC='
        // + this.key == 'DcNo' ? CommonService.getReqDateFormat(this.toDate) : ''
     ).subscribe(
                (resp: any) => {
                  this.data.rows = [];
                  if(resp.StatusCode == 200){
                    this.isShowPageLoader = false;
                    this.data.rows = resp.ListJCDcSearch.map( x => {
                      return {
                        ...x,
                        DcDate: moment(x.DcDate).format("DD/MM/yyyy"),
                        JcDate: moment(x.JcDate).format("DD/MM/yyyy")
                      }
                    });
                  }
                  if(resp.ListJCDcSearch.length == 0){
                    this.toastr.info("No Data Available");
                  }
                },
                error => {
                    this.isShowPageLoader = false;
                    this.toastr.error(error.error.Message);
                }
            )
  }

  /**
  * @param {String} selected
  * Get selected details
  */
  selectItem() {
    let closeObj = { obj: this.selected[0], category: this.isActive };
    this.dialogRef.close(closeObj);
  }
  /**
 * @param {String} 
 * close popup search dialog
 */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
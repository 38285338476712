/**
 * Created BY HMSPL 
 * Last Modified By Balaji on 24/08/2018 
 * Last Modified By Licko  on 24/08/2023
 * Last Modified Description : Written API for menu data
 */
import { Component, ViewChild, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatSidenav } from '@angular/material/sidenav';
import { ToastrService } from 'ngx-toastr';
import { TooltipConfig } from "ngx-bootstrap";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

//Custom Service or components
import { listAnimation } from '../../api-services/utils/animation'
import { MenuService } from '../../api-services/menu.service';
import { CommonService } from '../../api-services/common.service';
import { SessionService } from 'src/app/api-services/session.service';
import { data } from 'src/app/api-services/utils/static-data';
import * as _ from 'underscore';
import { Key } from 'selenium-webdriver';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';


export function getAlertConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    placement: 'right',
    container: 'body'
  });
}

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
  providers: [{ provide: TooltipConfig, useFactory: getAlertConfig }],
  animations: [listAnimation]
})

@Injectable({
  providedIn: 'root'
})
export class MasterComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  lang: any;
  isShowCustomize = false;
  isDeskView = true;
  searchText = '';
  isOpen = false;
  quickMenuList: Array<any> = [];
  refQuickMenuArr: Array<any> = [];
  menuList: any = [];
  dynTransform;
  refMenu = [];
  refMenuCopy =[];
  mainModule = data.dmsModules;
  subMenu = data.subMenu;
  isShowMainMenu = true;
  recentMenuList=[];
  slideConfig = { "slidesToShow":4, "slidesToScroll": 1 };
  public isDealerLogin:any = localStorage.isDealerLogin;
  public isDriveXLogin:any = localStorage.isDriveXLogin;
  public isDriveXPartsLogin:any = localStorage.isDriveXPartsLogin;
  public isDriveXAccountsLogin:any = localStorage.isDriveXAccountsLogin;
  constructor(private httpClient: HttpClient,
    private router: Router,private loginService:LoginService,
    private menuService: MenuService,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private masterService: MasterService) {
    if (!localStorage.getItem('menuData')) {
      if(this.isDealerLogin == "false"){
        this.getMenuData();
      }
    } else {
      this.menuList = this.getActualMenu(JSON.parse(localStorage.getItem('menuData')));
    }
    if(this.isDriveXLogin == "true" && this.isDriveXPartsLogin == "true"){
      this.getDrivexPartsMenu();
    }else if(this.isDriveXLogin == "true" && this.isDriveXAccountsLogin == "true"){
      this.getDrivexAccountsMenu();
    }
    this.sessionService.checkIfIdle();
  }
  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      if(localStorage.getItem('recentMenu')){
        this.recentMenuList = JSON.parse(localStorage.getItem('recentMenu'))
      }else{
        this.recentMenuList = [];
      }
      if (window.innerWidth >= 320 && window.innerWidth <= 480) {
        this.slideConfig.slidesToShow = 1;
        this.isDeskView = false;
      }else{
        this.isDeskView=true;
      }
      if (JSON.parse(localStorage.getItem('customizeMenu'))) {
        setTimeout(() => {
          this.quickMenuList = JSON.parse(localStorage.getItem('customizeMenu'));
          this.assignCustomiseFlag();
        }, 1000)
      }
      if(localStorage.getItem('currentMenu')){
        this.mainModule.filter(key =>{
          if(key.name == localStorage.getItem('currentMenu')){
            key.isActive = true;
          }
        })
        this.menuList.filter(key =>{
          if(key.name == localStorage.getItem('currentMenu')){
            this.refMenu = key.menu;
          }
        })
      }else{
        this.mainModule.filter(key =>{
          if(key.name == 'Service'){
            key.isActive = true;
          }
        })
        this.menuList.filter(key =>{
          if(key.name == 'Service'){
            this.refMenu = key.menu;
          }
        })
      }
    }
  }
  // checkIfIdle() {
  //   this.idle.setIdle(10);
  //   this.idle.setTimeout(300);
  //   this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  //   this.idle.onTimeoutWarning.subscribe((countdown: number) => {
  //     console.log('TimeoutWarning: ' + countdown);
  //   });
  //   this.idle.onTimeout.subscribe(() => {
  //     // this.idle.stop();
  //     // localStorage.clear();
  //     // this.toastr.error(this.lang.GENERAL.sessionExpired);
  //     // this.router.navigate(['/session/signin']);
  //     // console.log('Logout success')
  //   });
  //   this.idle.watch();
  //   console.log(this.sessionService.getInterceptorVal());

  // }
  openChanged(e) {
    console.log('open change', e)
  }
  /**
    * Menu Data  
   */
  getMenuData() {
    let reqParam = new HttpParams();
    reqParam = reqParam.set("DealerID", CommonService.getUserData().DEALER_ID)
      .set("BranchID", CommonService.getUserData().BRANCH_ID)
      .set("UserID", CommonService.getUserData().USER_ID)
    this.menuService.getMenuData(reqParam).subscribe((resp: any) => {
      if (resp && resp.data && resp.statusCode === 200) {
        localStorage.setItem('menuData', JSON.stringify(resp.data));
        this.menuList = this.getActualMenu(resp.data);
      } else {
        this.toastr.error(resp.message);
      }
      if (resp && resp.statusCode == 401) {
        this.loginService.logout();
      }
      }, error => {
      if (error.status == 401) {
        this.loginService.logout();
      }
      this.toastr.error(error.error.Message);
    });
  }
  getDrivexPartsMenu(){
    this.masterService.get('', '/Menu/GetDrivexMenuModules?isAccounts=false').subscribe(
        (resp: any) => {
          localStorage.setItem('menuData', JSON.stringify(resp.data));
          this.menuList = this.getActualMenu(resp.data);
         }, error => {
          if (error.status == 401) {
            this.loginService.logout();
          }
          this.toastr.error(error.statusText);
        }
      );
  }
  getDrivexAccountsMenu(){
    this.masterService.get('', '/Menu/GetDrivexMenuModules?isAccounts=true').subscribe(
        (resp: any) => {
          localStorage.setItem('menuData', JSON.stringify(resp.data));
          this.menuList = this.getActualMenu(resp.data);
         }, error => {
          if (error.status == 401) {
            this.loginService.logout();
          }
          this.toastr.error(error.statusText);
        }
      );
  }
  /**
    * Combining 
    * @param {Object} event
   */
  getActualMenu(data) {
    let actualMenu = [];
    data.filter(key => {
      this.mainModule.map(item => {
        if (key.MODULE_NAME == item.name) {
          item['_MenuList'] =key._MenuList;
          actualMenu.push(item);
        }
      });
    });
    actualMenu.filter(item =>{
      let menuName = [];
      let pluckMenuName;
      let subMenu = [];
      menuName = _.pluck(item._MenuList,'SUB_GROUP')
      pluckMenuName = _.uniq(menuName);
      if(pluckMenuName.length){
        pluckMenuName.filter(key =>{
        let subMenuObj ={
          name : key,
          subMenu : []
        }
          subMenu.push(subMenuObj);
        })
      }
      item['menu'] = subMenu;
    });
    actualMenu.filter(item =>{
      item.menu.filter(then =>{
        item._MenuList.filter(key =>{
          if(then.name == key.SUB_GROUP){
            then.subMenu.push(key);
          }
        })
      })
    })
    actualMenu.filter(item =>{
      item.menu.filter(then =>{
        then.subMenu.filter(cpObj =>{
          if(then.name == null){
            then.name = item.name;
          }
          this.subMenu.filter(key =>{
            if(cpObj.MENU_NAME.toLowerCase() == key.name.toLowerCase() && then.name.toLowerCase() == key.menuName.toLowerCase()){
              Object.assign(cpObj,key);
            }
          })
        })
      });
      delete item._MenuList;
    })
    actualMenu.filter(item =>{
      if(item.name == 'Service'){
        this.refMenu = item.menu;
      }
    });
    this.refMenuCopy = actualMenu;
    console.log('actualMenu',actualMenu);
    return actualMenu;
  }

  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }
  beforeChange(e) {
    // console.log('beforeChange');
  }

  afterChange(e) {
    this.isShowMainMenu = false;
    setTimeout(() =>{
      this.isShowMainMenu = true;
    },500)
  }

  close() {
    // console.log(this.sidenav)
    this.sidenav.close();
  }

  toggleCustomize() {
    this.isShowCustomize = !this.isShowCustomize;
    this.refQuickMenuArr = JSON.parse(JSON.stringify(this.quickMenuList))
    // this.assignCustomiseFlag();
  }

  openSideNav() {
    // console.log(this.sidenav)
    this.sidenav.open();
  }

  openedChange() {
    this.isOpen = true;
    this.dynTransform = '60px';
  }
  closedStart() {
    this.isOpen = false;
    var myDiv = document.getElementById('sidenav');
    this.dynTransform = '0px';
    myDiv.scrollTop = 0;
  }

  // selectCustomize(menuName, childName) {
  //   this.refMenu.filter(key => {
  //     if (key.name == menuName) {
  //       key.subMenu.filter(item => {
  //         if (item.name == childName) {
  //           if (this.quickMenuList.length < 6) {
  //             this.quickMenuList.push(item)
  //             item.isCustomize = true;
  //           } else {
  //           }
  //         }
  //       })
  //     }
  //   })
  // }

  // removeCustomize(menuName, childName) {
  //   this.refMenu.filter(key => {
  //     if (key.name == menuName) {
  //       key.subMenu.filter(item => {
  //         if (item.name == childName) {
  //           if (this.quickMenuList && this.quickMenuList.length > 0) {
  //             this.quickMenuList.filter(menu => {
  //               if (menu.name == childName) {
  //                 this.quickMenuList.splice(this.quickMenuList.indexOf(menu), 1);
  //                 item.isCustomize = false;
  //               }
  //             })
  //           }
  //         }
  //       })
  //     }
  //   })
  // }

  removeQuickList(obj) {
    this.quickMenuList.filter(key => {
      if (key.name == obj.name) {
        this.quickMenuList.splice(this.quickMenuList.indexOf(obj), 1);
        this.refMenu.filter(item => {
          if (item.name == obj.menuName) {
            item.subMenu.filter(menu => {
              if (menu.name == obj.name) {
                menu.isCustomize = false;
              }
            })
          }
        })
      }
    })
  }
  navigateMenu(name) {
    this.menuList.filter(item =>{
      if(item.name == name){
        item.isActive = true;
        this.refMenu = item.menu;
      }else{
        item.isActive = false;
      }
    })
    localStorage.setItem('currentMenu',name);
  }
  navigateTo(state, menuName, childName,menuObj) {
    if (!this.isShowCustomize) {
      if(menuName == "Call Log" && childName == "Call Log"){
        window.open(state, "_blank");
      }
      else{
        this.router.navigate([state]);
      }
      if(this.recentMenuList.length == 0){
        this.recentMenuList.push(menuObj)
      }else if(this.recentMenuList.length == 5){
        if(this.recentMenuList.indexOf(menuObj) == -1){
        this.recentMenuList.splice(0,1);
        this.recentMenuList.push(menuObj);
        }
      }else{
        if(this.recentMenuList.indexOf(menuObj) == -1){
          this.recentMenuList.push(menuObj);
        }
      }
      localStorage.setItem('recentMenu',JSON.stringify(this.recentMenuList));
      console.log('recent',this.recentMenuList);
      this.close();
    } else {
      this.refMenu.filter(key => {
        if (key.name == menuName) {
          key.subMenu.filter(item => {
            if (item.name == childName) {
              if (!item.isCustomize) {
                if (this.quickMenuList && this.quickMenuList.length < 6) {
                  this.quickMenuList.push(item)
                  item.isCustomize = true;
                }
              } else {
                if (this.quickMenuList && this.quickMenuList.length > 0) {
                  this.quickMenuList.filter(menu => {
                    if (menu.name == childName) {
                      this.quickMenuList.splice(this.quickMenuList.indexOf(menu), 1);
                      item.isCustomize = false;
                    }
                  })
                }
              }
            }
          })
        }
      })
    }
  }

  saveCustomize() {
    localStorage.setItem('customizeMenu', JSON.stringify(this.quickMenuList));
    this.isShowCustomize = false;
  }
  // cancelCustomize() {
  //   this.isShowCustomize = false;
  //   this.quickMenuList = this.refQuickMenuArr;
  //   // this.assignCustomiseFlag();
  // }

  assignCustomiseFlag() {
    if (this.quickMenuList && this.quickMenuList.length > 0) {
      this.quickMenuList.filter(key => {
        this.refMenu.filter(item => {
          if (item.name == key.menuName) {
            item.subMenu.filter(then => {
              if (then.name == key.name) {
                then.isCustomize = key.isCustomize;
                // console.log(JSON.stringify(then))
              } else {
                // then.isCustomize = false;
              }
            })
            // console.log(JSON.stringify(item))            
          }
        })
      });
    }
  }

  getTriangle(bgColor) {
    return `
    .activeTri {
      position: relative;
      &:after {
          position: absolute;
          content: '';
          top: 108%;
          left: 36%;
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 12px solid`+ bgColor + `
      }
  }`
  }
  /**
   * Menu Search
   */
  searchMenu() {
    let finalArr:any;
    let currentMenuName;
    if(localStorage.getItem('currentMenu')){
      currentMenuName = localStorage.getItem('currentMenu');
    }else{
      currentMenuName = 'Service';
    }
    var copyarr: any = JSON.parse(JSON.stringify(this.refMenuCopy));
    copyarr.filter(key =>{
      if(key.name == currentMenuName){
        finalArr = key.menu.filter(menu => {
          return menu.subMenu = menu.subMenu.filter((item1: any) => {
            return item1['name'].toLowerCase().toString().search(this.searchText.toLowerCase()) !== -1;
          });
        });
      }
    })
    this.refMenu = finalArr;
  }

  openDashboard(){
    this.router.navigate(['homedashboard/home-dashboard']);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { CustomiseTableComponent } from 'src/app/shared/customise-table/customise-table.component';


@Component({
  selector: 'app-sales-hp-history',
  templateUrl: './sales-hp-history.component.html',
  styleUrls: ['./sales-hp-history.component.scss']
})
export class SalesHpHistoryComponent implements OnInit {
  public lang: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public HpHistoryList: any = [];
  public bookingId: number;
  public dealerId: number;
  public branchId: number;

  constructor(
    public dialogRef: MatDialogRef<SalesHpHistoryComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    this.bookingId = data.bookingId;
    this.branchId = data.branchId;
    this.dealerId = data.dealerId;
  }

  ngOnInit() {
    this.lang = CommonService.getLang();
    this.loadHPHistory();
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  loadHPHistory() {
    var locData: any;
    let reqObj: any = {};
    reqObj.DEALER_ID = this.dealerId;
    reqObj.BRANCH_ID = this.branchId;
    reqObj.BOOKING_ID = this.bookingId;
    this.masterSales.post(reqObj, '/Sales/LoadHpHistorybyBookingId').subscribe(
      resp => {
        locData = resp;
        this.HpHistoryList = locData.data.length > 0 ? locData.data : [];
      },
      error => {
        this.toastr.error('Location not loaded')
      }
    )
  }

}

import { Component, Inject, OnInit } from '@angular/core';
 
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/api-services/login.service';

@Component({
  selector: 'app-search-credit-debit-num',
  templateUrl: './search-credit-debit-num.component.html',
  styleUrls: ['./search-credit-debit-num.component.scss']
})
 

export class SearchCreditDebitNumComponent implements OnInit {
  public lang: any;
  public isShowPageLoader: boolean;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public bankrows: any = [];
  public reconcilation: any = {};
  public ClaimLists: any = [];
  public masterData: any = {};//jr;
  public voucherType: any = 4; 
  vouchertypelist:any=[];
  public recon: any = {};

  constructor( private masterService: MasterService,
    public toastr: ToastrService,private loginService:LoginService,
    private dialogRef: MatDialogRef<SearchCreditDebitNumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (CommonService.getLang()) {
        this.lang = CommonService.getLang();
      }
     }


     
  ngOnInit() {
    this.getMasterData();
  }


        /**
    * Filter each columns
    * @param {String} value
    * @param {String} prop
   */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  

  selectedItem()
  {
    let closeObj = { 
      obj: this.selected[0],
      typeId: this.data
    };

    console.log("selected line",closeObj)
   this.dialogRef.close(closeObj);

  }
   // created by surya on 27-12-2021
  //below is used to get financial year and comapny name
  getMasterData() {
    this.isShowPageLoader = false;
    this.masterService.get('', '/Accounts/GetAccountVoucherMasterData?dealerId=' + CommonService.getUserData().DEALER_ID +
      '&branchId	=' + CommonService.getUserData().BRANCH_ID + '&voucherType	=' + this.voucherType + '&countryCode=' +
      CommonService.getDealerData().COUNTRY_CODE).subscribe(
        (resp: any) => {
          if (resp && resp.statusCode === 200) {
            this.masterData = resp;
            console.log("masterdata",this.masterData)

            let reqObj={};

            for(var i=0;i<this.masterData.voucherTypeList.length;i++)
            {
              if(this.masterData.voucherTypeList[i].VoucherId==501)
              {
                 reqObj={
                   "VoucherId":this.masterData.voucherTypeList[i].VoucherId,
                   "VoucherName":this.masterData.voucherTypeList[i].VoucherName
                 } 

                 this.vouchertypelist.push(reqObj)
              }
              if(this.masterData.voucherTypeList[i].VoucherId==502)
              {
                reqObj={
                  "VoucherId":this.masterData.voucherTypeList[i].VoucherId,
                  "VoucherName":this.masterData.voucherTypeList[i].VoucherName
                } 

                this.vouchertypelist.push(reqObj)
              }
              if(this.masterData.voucherTypeList[i].VoucherId==509)
              {
                reqObj={
                  "VoucherId":this.masterData.voucherTypeList[i].VoucherId,
                  "VoucherName":this.masterData.voucherTypeList[i].VoucherName
                } 

                this.vouchertypelist.push(reqObj)
              }
              if(this.masterData.voucherTypeList[i].VoucherId==510)
              {
                reqObj={
                  "VoucherId":this.masterData.voucherTypeList[i].VoucherId,
                  "VoucherName":this.masterData.voucherTypeList[i].VoucherName
                } 

                this.vouchertypelist.push(reqObj)
              }

            }

            console.log("voucherlist",this.vouchertypelist)
            //this.debitVoucherData.FIN_YEAR = this.masterData.financialYearList[1].YearId;
            this.isShowPageLoader = false;
             
          } else {
            this.toastr.error(resp.statusMessage);
            this.isShowPageLoader = false;
          }
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }, error => {
          if (error.status == 401) {
            this.loginService.logout();
          }
          this.toastr.error(error.error.Message);
          this.isShowPageLoader = false;
        });
  }

  searchlist()
  {
    this.isShowPageLoader = true;
    let reqObj: any = {};
   

    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;   
    reqObj.PARTY_CODE=1
    reqObj.PARTY_CAT=3
    reqObj.VCHR_TYPE_ID=this.recon.VCHR_TYPE_ID ;//change later
    reqObj.DOC_TYPE=4;
    reqObj.VOUCHER_NO=this.recon.claimNo;
    reqObj.DOC_NO=this.recon.docNo

    this.ClaimLists = [];
    this.masterService.post(reqObj,'CreditDebitNoteRecon/GetCreditDebitReconciliationVoucherDetails').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
this.bankrows=resp.CreditDebitVoucherList;
        this.isShowPageLoader=false;
console.log("reconlist",resp);

      }
      else if (resp && resp.statusCode == 204) {
        this.ClaimLists = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    });

   
   


  }



}
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterDataService } from 'src/app/api-services/master-data.service';
import { RecallRefitRejectionComponent } from '../recall-refit-rejection/recall-refit-rejection.component';

@Component({
  selector: 'app-recall-refit-dialog',
  templateUrl: './recall-refit-dialog.component.html',
  styleUrls: ['./recall-refit-dialog.component.scss']
})

export class RecallRefitDialogComponent implements OnInit {
  
  @Input() displayData: any = [];
  public RecallRemarks: any;
  
  constructor(private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    // private masterService: MasterService,
    public route: ActivatedRoute,
    private MasterDataService: MasterDataService,
    public dialogRef: MatDialogRef<RecallRefitDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.displayData = this.data;
  }
  ngOnInit() 
  {
    debugger;
    this.RecallRemarks = this.displayData.userData.REMARKS + ' ' + this.displayData.userData.PART_NO + ' ' + this.displayData.userData.DESCRIPTION;

  }
  submit()
  {
    this.dialogRef.close();
  }
  openReasonDialog()
  {
    const dialogRef = this.dialog.open(RecallRefitRejectionComponent, {
      width: '500px',
      data: { userData: this.displayData },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      debugger;
      if (result && result!=undefined) {
        this.dialogRef.close();
      }
    });
  }
}

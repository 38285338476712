import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-stock-transfer-grn',
  templateUrl: './stock-transfer-grn.component.html',
  styleUrls: ['./stock-transfer-grn.component.scss']
})
export class StockTranferGrn implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public apsGrn: any = {};
  public part: any={};
  public vendorLists: any = [];
  public vendorRows: any = [];
  public sparesGRNPaymentLists: any = [];
  public isShowPageLoader: boolean;
  public masterData: any = {};

  constructor(
    private dialogRef: MatDialogRef<StockTranferGrn>,
    private masterService: MasterService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  
  if (this.data) {
    this.isActive = this.data && this.data.type ? this.data.type : '';
    this.inlineFilter = {
      SPARE_GRN_NO:'',
      GRN_VALUE:'',
      invoiceNo:'',
      vendorName:'',
    };
  }
}

  ngOnInit() {       
    {
      let reqObj: any = {};
      reqObj.dealerId = CommonService.getUserData().DEALER_ID;      
      reqObj.branchId = CommonService.getUserData().BRANCH_ID;
      reqObj.vendorId = CommonService.getUserData().vendorId;
      reqObj.vendorName="";
      reqObj.vendorTypeId=4;
      reqObj.active=true;
      reqObj.APS_Code = this.apsGrn.APS_Code;
      reqObj.invoiceNo = this.apsGrn.invoiceNo;
      reqObj.fromdate = this.apsGrn.fromdate ? CommonService.getReqDateFormat(this.apsGrn.fromdate):'';
      reqObj.todate =  this.apsGrn.todate ? CommonService.getReqDateFormat(this.apsGrn.todate):'';
      for (const key in reqObj) {
        if (reqObj[key] == '') {
          delete reqObj[key];
        }
      }
      this.isShowPageLoader = true;
      this.masterService.get('', '/PartStock/GetVendorList?dealerId=' + CommonService.getUserData().DEALER_ID +
      '&branchId	=' + CommonService.getUserData().BRANCH_ID).subscribe(
        (resp: any) => {
          if (resp && resp.statusCode == 200) {
                  this.isShowPageLoader = false;
                  this.vendorLists = resp.vendorList ;
                
                } 
                else if (resp && resp.statusCode == 204) {
                  this.vendorLists = [];
                  this.isShowPageLoader = false;
                  return this.toastr.error(resp.statusMessage);
                }
                else {
                  this.isShowPageLoader = false;
                }
              }, error => {
                this.isShowPageLoader = false;
                this.toastr.error(error.error.Message);
              })
      // this.masterService.post(reqObj, '/DMSMaster/VendorMaster/SearchVendor').subscribe(
      //   (resp: any) => {
         
      //     if (resp && resp.statusCode == 200) {
      //       this.isShowPageLoader = false;
      //       this.vendorLists = resp.vendorList ;
          
      //     } 
      //     else if (resp && resp.statusCode == 204) {
      //       this.vendorLists = [];
      //       this.isShowPageLoader = false;
      //       return this.toastr.error(resp.statusMessage);
      //     }
      //     else {
      //       this.isShowPageLoader = false;
      //     }
      //   }, error => {
      //     this.isShowPageLoader = false;
      //     this.toastr.error(error.error.Message);
      //   })
      }
    if (this.data) {
      this.vendorLists = this.data.vendorList && this.data.vendorList.length > 0 ? this.data.vendorList : [];
      this.vendorLists.forEach(value => {
        if(this.data.vendorName == value) {
          this.apsGrn.APS_Code = value.APS_Code;
        }
      });
      
    }
    
  }

  apsGrnSearch() {
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reqObj.invoiceNo = this.apsGrn.invoiceNo;
    reqObj.VENDOR_NAME= (<HTMLSelectElement>document.getElementById('vendorname')).value;
    reqObj.COMPANY_ID=0;
    reqObj.GRN_VALUE=0;
    reqObj.INVOICE_NO=0;
    reqObj.SPARE_GRN_ID=0;
    reqObj.ACCT_SRCH_TVS=0;
    reqObj.SPARE_GRN_NO=this.apsGrn.SPARE_GRN_NO;
    reqObj.VENDOR_NAME="";
    reqObj.GRN_DATE_FROM= fromDateFormat;
    reqObj.GRN_DATE_TO= toDateFormat;
    reqObj.FIN_YEAR=CommonService.getDealerData().FIN_YEAR;
    reqObj.fromdate = this.apsGrn.fromdate ? CommonService.getReqDateFormat(this.apsGrn.fromdate):'';
    reqObj.todate =  this.apsGrn.todate ? CommonService.getReqDateFormat(this.apsGrn.todate):'';
    this.isShowPageLoader = true;
    this.masterService.post(reqObj, '/Accounts/SearchSparesGRNPaymentList').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.sparesGRNPaymentLists = resp.sparesGRNPaymentList ;
        
        } 
        else if (resp && resp.statusCode == 204) {
          this.vendorLists=[];
          this.sparesGRNPaymentLists = [];
          this.isShowPageLoader = false;
          return this.toastr.error(resp.statusMessage);
        }
        else {
          this.isShowPageLoader = false;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      })
     
    }
  selectedItem() {
      if (this.selected[0].STATUS == 2 || this.selected[0].STATUS == 3) {
      this.toastr.error('GRN is completed.Please select the another invoice...');
      return;
    }
    let closeObj = { obj: this.selected[0]};
    this.dialogRef.close(closeObj);
  }

  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { EnquiryDet } from 'src/app/api-services/dto/enquiry'


@Component({
  selector: 'app-sales-enquiry',
  templateUrl: './sales-enquiry.component.html',
  styleUrls: ['./sales-enquiry.component.scss']
})
export class SalesEnquiryComponent implements OnInit {


  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public sales: any = {};
  public salesRows: any = [];
  public filterObj: any = {
    enquiryNo: '',
    customerName: '',
    fromDate: '',
    toDate: ''
  }
  constructor(
    private dialogRef: MatDialogRef<SalesEnquiryComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService, private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      // this.isActive ="enquiryNo";


    }
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  validateSearch() {
    // alert(this.filterObj.toDate);
    if ( (this.filterObj.fromDate ==null || this.filterObj.fromDate == "") && ( this.filterObj.toDate == null || this.filterObj.toDate == "") && (this.filterObj.enquiryNo==null || this.filterObj.enquiryNo==undefined || this.filterObj.enquiryNo == "") && (this.filterObj.customerName == undefined || this.filterObj.customerName == null || this.filterObj.customerName == "") ){

     // alert(this.lang.Message.ENQUIRY.VALIDATE_SEARCH);
      this.toastr.warning(this.lang.Message.Enquiry.VALIDATE_SEARCH);
      return false;
    }
    return true;
  }
  searchList() {
    if (this.validateSearch()) {
      this.isShowPageLoader = true;
      let reqObj: any = {};
      reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
      reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
      reqObj.FROM_DATE = this.filterObj.fromDate ? CommonService.getReqDateFormat(this.filterObj.fromDate) : null;
      reqObj.TO_DATE = this.filterObj.toDate ? CommonService.getReqDateFormat(this.filterObj.toDate) : null;
      reqObj.ENQUIRY_NO = this.filterObj.enquiryNo != null ? this.filterObj.enquiryNo : '';
      reqObj.CUST_NAME = this.filterObj.CUST_NAME != null ? this.filterObj.CUST_NAME : '';

      this.masterSales.post(reqObj, '/Sales/GetEnquiryList').subscribe((resp: any) => {
        if (resp && resp.statusCode === 200 && resp.data) {
          this.salesRows = resp.data.length > 0 ? resp.data : [];

          this.isShowPageLoader = false;

        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
      });
    }
  }
  /**
   * select search list
   */
  selectedItem() {
    let closeObj = { obj: this.selected[0] };
    this.dialogRef.close(closeObj);
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }

}

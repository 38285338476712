/**
 * Created BY HMSPL 
 * Session Service Class
 * Last Modified By Balaji on 13/12/2018
 * Last Modified Description :Session Service Call
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public lang: any;
    constructor(private idle: Idle,
        private router: Router,
        private toastr: ToastrService, ) {
        if (CommonService) {
            this.lang = CommonService.getLang();
        }
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.getItem('userData') && localStorage.getItem('lang') && localStorage.getItem('langId')) {
            return next.handle(req).pipe(tap(event => {
                if (event.type == 0) {
                    this.idle.stop();
                } else {
                    this.idle.watch();
                }
            }, error => {
                this.idle.watch();
            })
            )
        } else {
            return next.handle(req);
        }
    }
    checkIfIdle() {
        this.idle.setIdle(10);
        this.idle.setTimeout(3000);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onTimeoutWarning.subscribe((countdown: number) => {
            // console.log('TimeoutWarning: ' + countdown);
        });
        this.idle.onTimeout.subscribe(() => {
            this.idle.stop();
            localStorage.clear();
            this.toastr.error(this.lang.GENERAL.sessionExpired);
            this.router.navigate(['/session/dealer']);
        });
        this.idle.watch();
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { SimpleChange } from '@angular/core';

@Component({
  selector: 'app-filter-card',
  template: `
  <mat-card  class="{{activeClass}}">
    <h3 [ngStyle]="{color:color}">
    {{count | units}}
    </h3>
    <div class="title">
      <div class="bullet-point" [ngStyle]="{background:color}">
      </div>
      <span title="{{status}}">{{status}}</span>
    </div>
</mat-card>
<div class="hori-line">
</div>
  `,
  styleUrls: ['./filter-card.component.scss']
})
export class FilterCardComponent implements OnInit {
  @Input() isActive: string;
  @Input() status: string;
  @Input() count: Number
  @Input() color: String;
  @Input() index: Number;
  activeClass: any = 'left';
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(change: any) {
    // if (change.index && change.index.previousValue && change.index.currentValue) {
    //   this.activeClass = change.index.previousValue > change.index.currentValue ? 'right' : 'left';
    // }
  }

}

/**
 * Created BY HMSPL 
 * Job Card Service Class
 * Last Modified By Balaji on 24/08/2018
 * Last Modified Description : Created Menu Service
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    constructor(private httpClient: HttpClient) {

    }
    /**
     * Get Menu's List
     * @param {Object} request
     * return Object
     */
    getMenuData(params) {
        const resData = RequestService.uvdReq('/Menu/GetMenuModules');
       //const resData = RequestService.getApiURLReq('GetMenuModules');
        return this.httpClient.get(resData.url, { params: params });
    }
}

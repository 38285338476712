import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';

@Component({
  selector: 'app-invoice-detail-hp-voucher',
  templateUrl: './invoice-detail-hp-voucher.component.html',
  styleUrls: ['./invoice-detail-hp-voucher.component.scss']
})
export class InvoiceDetailHpVoucherComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public partRows: any = [];
  public invoiceLists: any = [];
  constructor(
    private dialogRef: MatDialogRef<InvoiceDetailHpVoucherComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.inlineFilter = {        
        // invoice_no:'',
        // // INVOICE_DATE:'',
        // // booking_no:'',
        // // Booking_date:'',
        // // HPBALANCEAMT:'',       
        // // cust_name: '',
        // STATUS: '',
      };
      
      this.status = [
        {
          name: 'Open',
          value: '1'
        },
        {
          name: 'Closed',
          value: '2'
        },
        {
          name: 'Cancelled',
          value: '3'
        },
        {
          name: 'Allotted',
          value: '4'
        },
        {
          name: 'Invoiced',
          value: '5'
        }
      ];
    }
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  searchList() {
      this.getBookingAdvanceSearch();
  }
  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'tvsInvoiceSearch') {
      if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
      this.selected[0].TYPE = this.part.invoiceTypeId;
    }
    let closeObj = { 
      obj: this.selected,
      typeId: this.data.voucherId
    };
    this.dialogRef.close(closeObj);
  }

  //grn-tvs invoice popup
  getBookingAdvanceSearch() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Booking From Date is empty');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Booking to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
    reqObj.INVOICE_NO = this.part.INVOICE_NO ? Number(this.part.INVOICE_NO) : null;
    reqObj.FROM_DATE = fromDateFormat;
    reqObj.TO_DATE = toDateFormat;
    reqObj.FIN_YEAR = reqObj.FIN_YEAR = CommonService.getDealerData().FIN_YEAR;
    reqObj.cust_name = "";
    this.invoiceLists = [];
    this.masterServices.post(reqObj, '/Accounts/SearchSelfHPVehicleInvoiceList').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.invoiceLists = resp.invoiceList ? resp.invoiceList : [];
      } else if (resp && resp.statusCode == 204) {
        this.invoiceLists = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

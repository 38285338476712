/**
 * Created  BY HMSPL
 * Last Last Modified By Balaji on 10/10/2018
 * Last Modified Description : Added Comment
 */
import { Component } from '@angular/core';
import { UserIdleService } from "angular-user-idle";
import { LoginService } from './api-services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private userIdle: UserIdleService,private loginService:LoginService) {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => console.log("Going to logout", count));
    this.userIdle.onTimeout().subscribe(() => {
      this.loginService.logout();
      window.location.reload();
    });
  }
  ngOnDestroy() {
  }
}

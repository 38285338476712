import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';

import { data } from '../../../api-services/utils/static-data';
import { ServiceReminderService } from '../../../api-services/service-reminder.service';
import { CommonService } from '../../../api-services/common.service';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

  public isShowLoader = false;
  public isShowPageLoader = true;
  public lang: any;
  public staticData: any;
  public advanceSearch: any;
  public appData: any;
  public reqObj = {
    isFollowup: false,
    isInvDate: false,
    jcType: 0,
    dueFrom: CommonService.getCurrentDate(),
    dueTo: CommonService.addDayToCurrentDate(3),
    remNoFrom: '0',
    remNoTo: '0',
    remDtFrom: '',
    remDtTo: '',
    flwupNoFrom: '0',
    flwupNoTo: '0',
    flwUpDtFrom: '',
    flwUpDtTo: '',
    custTypeStr: [],
    isInclRemNotSent: false,
    isActive: false,
    remMode: -1,
    custIdFrom: '0',
    custIdTo: '0',
    custName: ''
  };
  public subReqObj = {
    FRAMENO: '',
    STATUSID: -1,
    REASON_ID: -1,
    FOLL_TY_ID: -1,
    ACTIVE: false,
    flwEmpId: -1
  };
  public otherReqObj: any = {
    modelList: [],
    categList: [],
    areaList: [],
    amcType: []
  };
  public dropdownData: any = '';
  constructor(public dialogRef: MatDialogRef<AdvancedSearchComponent>,
    private serviceReminderService: ServiceReminderService,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      this.getAllDropdownInfo();
      this.staticData = data;
      if (CommonService.getServAppData()) {
        this.appData = CommonService.getServAppData();
      }
    }
  }
  /**
   * Get all dropdown related list
   */
  getAllDropdownInfo() {
    let params = new HttpParams();
    params = params.set('DealerId', CommonService.getUserData().DEALER_ID)
      .set('BranchId', CommonService.getUserData().BRANCH_ID)
      .set('BusinessTypeId', '1');
    this.serviceReminderService.getAdvancedSearchData(params).subscribe((resp: any) => {
      if (resp && resp.statusCode === 200) {
        this.dropdownData = resp.data;
      }
      else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
    });
  }
  /**
  * @param {String} 
  * close popup search dialog
  */
  closeDialog(): void {
    if (this.advanceSearch) {
      this.advanceSearch.unsubscribe();
    }
    this.dialogRef.close();
  }
  /**
   * Advanced search data
   */
  getAdvancedSearchData() {
    this.isShowLoader = true;
    let obj: any = JSON.parse(JSON.stringify({
      _AdvSearchDo: this.reqObj,
      _AdvSearchOptimized: this.subReqObj,
      modelList: this.otherReqObj.modelList,
      categList: this.otherReqObj.categList,
      areaList: this.otherReqObj.areaList,
      DealerId: CommonService.getUserData().DEALER_ID,
      BranchId: CommonService.getUserData().BRANCH_ID,
      amcType: this.otherReqObj.amcType,
    }));
    let total = 0;
    obj.amcType.map(function (val) {
      total += parseInt(val);
      return;
    });
    obj.amcType = total;
    if (obj._AdvSearchDo.custTypeStr && obj._AdvSearchDo.custTypeStr.length > 0) {
      obj._AdvSearchDo.custTypeStr = '8,9,11,' + obj._AdvSearchDo.custTypeStr.join();
    } else {
      obj._AdvSearchDo.custTypeStr = '8,9,11';
    }
    obj._AdvSearchDo.remNoFrom = parseInt(this.reqObj.remNoFrom ? this.reqObj.remNoFrom : '0');
    obj._AdvSearchDo.remNoTo = parseInt(this.reqObj.remNoTo ? this.reqObj.remNoTo : '0');
    obj._AdvSearchDo.flwupNoFrom = parseInt(this.reqObj.flwupNoFrom ? this.reqObj.flwupNoFrom : '0');
    obj._AdvSearchDo.flwupNoTo = parseInt(this.reqObj.flwupNoTo ? this.reqObj.flwupNoTo : '0');
    obj._AdvSearchDo.custIdFrom = parseInt(this.reqObj.custIdFrom ? this.reqObj.custIdFrom : '0');
    obj._AdvSearchDo.custIdTo = parseInt(this.reqObj.custIdTo ? this.reqObj.custIdTo : '0');
    obj._AdvSearchDo.isActive = this.subReqObj.ACTIVE;
    obj._AdvSearchDo.remMode = this.reqObj.remMode;
    obj._AdvSearchDo.dueFrom = this.reqObj.dueFrom ? CommonService.getReqDateFormat(this.reqObj.dueFrom) : '';
    obj._AdvSearchDo.dueTo = this.reqObj.dueTo ? CommonService.getReqDateFormat(this.reqObj.dueTo) : '';
    this.advanceSearch = this.serviceReminderService.getAdvancedSearchRemainderList(obj).subscribe((resp: any) => {
      if (resp && resp.d && resp.d.statusCode === 200) {
        this.dialogRef.close(resp.d.data);
      }
      else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
      this.isShowLoader = false;
    })
  }
}

export class branchData {
    BRANCH_ID:any;
    BRANCH_NAME:any;
    IS_ACTIVE:any;
    SAP_CODE:any;
    ALT_ADDRESS_ID:any;
    ALT_ADDRESS1:any;
    ALT_ADDRESS2:any;
    ALT_ADDRESS3:any;
    ALT_STATE:any;
    ALT_PIN_CODE:any;
    ALT_PHONE_NO:any;
    ALT_WORKSHOP_PHONE_NO:any;
    ALT_PART_PHONE_NO:any;
    ALT_CITY:any;
    ALT_FAX:any;
    ALT_IS_SERVICE:any;
    ALT_IS_SPARE:any;
    PRI_ADDRESS_ID:any;
    PRI_ADDRESS1:any;
    PRI_ADDRESS2:any;
    PRI_ADDRESS3:any;
    PRI_STATE:any;
    PRI_PIN_CODE:any;
    PRI_PHONE_NO:any;
    PRI_WORKSHOP_PHONE_NO:any;
    PRI_PART_PHONE_NO:any;
    PRI_CITY:any;
    PRI_FAX:any;
    PRI_EXPRESS_SERVICE:any;
    PRI_EXPERT_WHEEL:any;
    PRI_PICK_DROP:any;
    PRI_MILEAGE_CHECK:any;
}
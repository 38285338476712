import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

@Injectable({
  providedIn: 'root'
})
export class ExportToExcelService {
  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json['sampleData']);
    // const worksheetForManagers: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json['Managers']);
    // const workbook: XLSX.WorkBook = { Sheets: { 'ByTheTeam': worksheetForTeam, 'ByTheManagers': worksheetForManagers}, SheetNames: ['ByTheTeam', 'ByTheManagers'] };
    const workbook: XLSX.WorkBook = { Sheets: { 'vehicleProcurement': worksheet}, SheetNames: ['vehicleProcurement'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { JobCardService } from '../../api-services/job-card.service';
import { SaveLabour, LabourList, DlrAMCChange } from '../../api-services/dto/job-card';
import { data } from '../../api-services/utils/static-data';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CommonService } from '../../api-services/common.service';
import { LoginService } from 'src/app/api-services/login.service';
declare var $: any;

@Component({
  selector: 'app-issue-labour',
  templateUrl: './issue-labour.component.html',
  styleUrls: ['./issue-labour.component.scss']
})
export class IssueLabourComponent implements OnInit {
  @ViewChild('createLabour') isValidLabour: any;
  @Output() totalLabVal = new EventEmitter();
  @Input() jcId: any;
  @Input() vehicleId: any;
  @Input() isRootDisabled = false;
  @Input() labourCharges: any = ''
  @Input() custState: any;
  labourHeader = [];
  labourCode: Array<any> = [];
  labourfilter: Array<any> = [];
  issueModeList: Array<any> = [];
  AMCTypeList: Array<any> = [];
  localAMCList: Array<any> = [];
  saveLabour = new SaveLabour();
  dlrAMCChange = new DlrAMCChange();
  labour = new LabourList();
  labourTotalVal;
  labourTotalIssued;
  isShowPageLoader = false;
  isDisableLabour;
  lang: any;
  isShwoAMCType = true;
  public amcId: any;
  public dlrAmcId: any;
  stateFlagId = 'DEFAULT';
  constructor(private toastr: ToastrService, private jobCardService: JobCardService
    , public dialog: MatDialog, private loginService:LoginService) { }
  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      // this.labourHeader = [{
      //   name: this.lang.SERVICES.labourCode + ' *',
      //   width: 'wmpx-150'
      // },
      // {
      //   name: this.lang.SERVICES.issueMode + ' *',
      //   width: 'wmpx-150'
      // },
      // {
      //   name: this.lang.SERVICES.qty + ' *',
      //   width: 'wmpx-150'
      // },
      // {
      //   name: this.lang.SERVICES.descrp,
      //   width: 'wmpx-200'
      // },
      // {
      //   name: this.lang.SERVICES.category,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.rate,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.charge,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.schemeDisc,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.masterDisc,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.cgstInPer,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.sgstInPer,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.igstInPer,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.kfc,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.taxRate,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.hsnCode,
      //   width: 'wmpx-100'
      // },
      // {
      //   name: this.lang.SERVICES.memoNo,
      //   width: 'wmpx-100'
      // },
      // // {
      // //   name: this.lang.GENERAL.status,
      // //   width: 'wmpx-100'
      // // },
      // {
      //   name: '',
      //   width: 'wmpx-50'
      // }];
      this.issueModeList = data.issueMode;
      this.saveLabour.LabourList = [];
      if (this.jcId) {
        // this.getJobCardLabourCode();
        // this.getAMCList();
        // this.getJCList();
      }
    }
    if (this.labourCharges != '') {
      this.isShwoAMCType = false;
    } else {
      this.isShwoAMCType = true;
    }
  }

  //check state 

  checkState() {
    if (this.custState && CommonService.getDealerData().STATE_ID) {
      if (this.custState == CommonService.getDealerData().STATE_ID) {
        if (this.custState == 'KER' && CommonService.getDealerData().STATE_ID == 'KER') {
          this.stateFlagId = 'GST_KER';
        } else {
          this.stateFlagId = 'GST';
        }
      } else if (this.custState != CommonService.getDealerData().STATE_ID) {
        if (CommonService.getDealerData().STATE_ID == 'KER') {
          this.stateFlagId = 'IGST_KER';
        } else {
          this.stateFlagId = 'IGST';
        }
      } else {
        this.stateFlagId = 'DEFAULT';
      }
      if (this.stateFlagId == 'GST_KER') {
        this.labourHeader = [
          {
            name: this.lang.SERVICES.labourCode + ' *',
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.qty,
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.descrp,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.category,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.rate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.charge,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.cgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.sgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.kfcInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxRate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.memoNo,
            width: 'wmpx-100'
          },
          {
            name: '',
            width: 'wmpx-50'
          }
        ];
      } else if (this.stateFlagId == 'GST') {
        this.labourHeader = [
          {
            name: this.lang.SERVICES.labourCode + ' *',
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.qty,
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.descrp,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.category,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.rate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.charge,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.cgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.sgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxRate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.memoNo,
            width: 'wmpx-100'
          },
          {
            name: '',
            width: 'wmpx-50'
          }
        ];
      } else if (this.stateFlagId == 'IGST_KER') {
        this.labourHeader = [
          {
            name: this.lang.SERVICES.labourCode + ' *',
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.qty,
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.descrp,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.category,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.rate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.charge,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.igstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.utgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.kfcInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxRate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.memoNo,
            width: 'wmpx-100'
          },
          {
            name: '',
            width: 'wmpx-50'
          }
        ];
      } else if (this.stateFlagId == 'IGST') {
        this.labourHeader = [
          {
            name: this.lang.SERVICES.labourCode + ' *',
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.qty,
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.descrp,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.category,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.rate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.charge,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.igstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.utgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxRate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.memoNo,
            width: 'wmpx-100'
          },
          {
            name: '',
            width: 'wmpx-50'
          }
        ];
      } else {
        this.labourHeader = [
          {
            name: this.lang.SERVICES.labourCode + ' *',
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.qty,
            width: 'wmpx-150'
          },
          {
            name: this.lang.SERVICES.descrp,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.category,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.rate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.charge,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.cgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.sgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.igstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.utgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxRate,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.memoNo,
            width: 'wmpx-100'
          },
          {
            name: '',
            width: 'wmpx-50'
          }
        ];
      }
    }
  }

  getJobCardLabourCode() {
    let params = new HttpParams();
    params = params.set('DealerId', CommonService.getUserData().DEALER_ID)
    .set("BranchId", CommonService.getUserData().BRANCH_ID)
         .set("UserId", CommonService.getUserData().USER_ID);
    this.jobCardService.getJobCardLabourCode(params).subscribe((resp: any) => {
      this.labourCode = resp.data;
      this.labourfilter = this.labourCode;
      if (resp && resp.statusCode == 401) {
        this.loginService.logout();
      }
    }, error => {
      this.toastr.error(error.error.Message);
      if (error.status == 401) {
        this.loginService.logout();
      }
    });
  }

  getAMCList() {
    let params = new HttpParams();
    params = params.set('Dealer_id', CommonService.getUserData().DEALER_ID)
      .set('CountryCode', CommonService.getDealerData().COUNTRY_CODE)
      .set('VehicleId', this.vehicleId)
      .set('BranchId', CommonService.getUserData().BRANCH_ID)
      .set('UserId', CommonService.getUserData().USER_ID)
    this.jobCardService.loadAMCNew(params).subscribe((resp: any) => {
      if (resp && resp.data && resp.data.length > 0) {
        resp.data.filter(key => {
          if (key.DEALER_ID == 0) {
            this.AMCTypeList.push(key);
          } else {
            if (key.DEALER_ID == CommonService.getUserData().DEALER_ID) {
              this.localAMCList.push(key);
            }
          }
        });
        if (this.AMCTypeList.length > 0) {
          this.amcId = this.AMCTypeList[0].AMC_ID;
        }
        else { this.amcId = ""; }
        if (this.localAMCList.length > 0) {
          this.dlrAmcId = this.localAMCList[0].AMC_ID;
        }
        else { this.dlrAmcId = ""; }
      }
      else { this.amcId = ""; this.dlrAmcId = ""; }
      if (resp && resp.statusCode == 401) {
        this.loginService.logout();
      }
    },
      error => {
        this.toastr.error(error.error.Message);
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
  }

  getJCList() {
    if (this.jcId) {
      this.isShowPageLoader = true;
      let params = new HttpParams();
      params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
        .set('BranchID', CommonService.getUserData().BRANCH_ID)
        .set('JobCardID', this.jcId)
        .set('UserId', CommonService.getUserData().USER_ID);
      this.jobCardService.getJobCardLabour(params).subscribe((resp: any) => {

        if (resp && resp.data && resp.data.STATUS > 1) {
          this.isRootDisabled = true;
        }
        else {
          this.isRootDisabled = false;
        }
        if (resp && resp.data && resp.data.JobCardLabourList && resp.data.JobCardLabourList.length > 0) {
          this.saveLabour.LabourList = [];
          resp.data.JobCardLabourList.filter(key => {
            if (key) {
              let refLabourObj = new LabourList();
              refLabourObj.DISC_AMT = key.DISC_AMT;
              refLabourObj.BRANCH_ID = key.BRANCH_ID;
              refLabourObj.JOB_CARD_ID = key.JOB_CARD_ID;
              refLabourObj.LABOUR_ID = key.LABOUR_ID;
              refLabourObj.MODE_ID = key.MODE_ID;
              refLabourObj.JCARD_LAB_ID = key.JCARD_LAB_ID;
              refLabourObj.QUANTITY = key.QUANTITY;
              refLabourObj.DESCRIPTION = key.LabourDetails.DESCRIPTION;
              refLabourObj.CATEGORY = key.LabourDetails.LABOUR_CAT;
              refLabourObj.LABOUR_RATE = key.LabourDetails.LABOUR_RATE;
              refLabourObj.LABOUR_CHARGES = key.LABOUR_CHARGES; //+ (key.QUANTITY * key.TAX_VALUE);
              refLabourObj.SCHEME_DISC = key.SCHEME_DISC;
              refLabourObj.DISC_VALUE = key.DISC_VALUE;
              refLabourObj.TAX_VALUE = key.TAX_VALUE;
              refLabourObj.IGST_TAX_RATE = key.IGST_TAX_RATE ? key.IGST_TAX_RATE : 0;
              refLabourObj.HSN_CODE = key.HSN_CODE;
              refLabourObj.OUTWORK_MEMO_ID = key.OUTWORK_MEMO_ID;
              refLabourObj.STATUS = key.STATUS;
              refLabourObj.CGST_TAX_RATE = key.CGST_TAX_RATE ? key.CGST_TAX_RATE : 0;
              refLabourObj.CGST_TAX_RATE = key.CGST_TAX_RATE ? key.CGST_TAX_RATE : 0;
              refLabourObj.SGST_TAX_RATE = key.SGST_TAX_RATE ? key.SGST_TAX_RATE : 0;
              refLabourObj.UTGST_TAX_RATE = key.UTGST_TAX_RATE ? key.UTGST_TAX_RATE : 0;
              refLabourObj.CESS_TAX_RATE = key.CESS_TAX_RATE ? key.CESS_TAX_RATE : 0;
              refLabourObj.TAX_RATE = key.TAX_RATE ? key.TAX_RATE : 0;
              refLabourObj.SchemeDetailsList = key.SchemeDetailsList;
              refLabourObj.SCH_TYPE = key.SCH_TYPE;
              refLabourObj.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
              this.saveLabour.LabourList.push(refLabourObj);
              this.calculateAmount();
            }
          });
        } else {
          this.saveLabour.LabourList = [];
          let refLabourObj = new LabourList();
          refLabourObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
          refLabourObj.ROW_STATE = 'Created';
          refLabourObj.MODE_ID = 1;
          refLabourObj.LABOUR_ID = '';
          refLabourObj.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
          this.saveLabour.LabourList.push(refLabourObj);
          this.calculateAmount();
        }
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }
  /**
   * Get alll labour details 
   * @param index 
   */
  getLabourDetails(index) {
    this.labourfilter = this.labourCode;
    let localParam = this.saveLabour.LabourList[index];
    let refLabourId = 0;
    this.saveLabour.LabourList.filter(key => {
      if (this.saveLabour.LabourList.indexOf(key) != index) {
        if (key.LABOUR_ID == localParam.LABOUR_ID) {
          refLabourId++;
        }
      }
    });
    if (refLabourId == 0) {
      if (this.jcId && localParam.QUANTITY && localParam.MODE_ID && localParam.LABOUR_ID) {
        this.isShowPageLoader = true;
        let params = new HttpParams();
        params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
          .set('BranchID', CommonService.getUserData().BRANCH_ID)
          .set('JobCardID', this.jcId)
          .set('Qty', localParam.QUANTITY)
          .set('IssueMode', localParam.MODE_ID)
          .set('LabourID', localParam.LABOUR_ID)
          .set('StateID', CommonService.getDealerData().STATE_ID)
          .set('CountryCode', CommonService.getDealerData().COUNTRY_CODE)
          .set('DlrAMCType', this.dlrAmcId)
          .set('UserId', CommonService.getUserData().USER_ID)
        this.jobCardService.getJobCardLabourDetailsNew(params).subscribe((resp: any) => {
          if (resp && resp.data && resp.statusCode === 200 && resp.data.LabourDetails) {
            this.saveLabour.LabourList[index].DISC_AMT = resp.data.DISC_AMT;
            this.saveLabour.LabourList[index].CATEGORY = resp.data.LabourDetails.LABOUR_CAT;
            this.saveLabour.LabourList[index].DESCRIPTION = resp.data.LabourDetails.DESCRIPTION;
            this.saveLabour.LabourList[index].LABOUR_RATE = resp.data.LABOUR_RATE; //resp.data.LabourDetails.LABOUR_RATE;
            this.saveLabour.LabourList[index].LABOUR_CHARGES = resp.data.LABOUR_CHARGES; //resp.data.LABOUR_CHARGES + (resp.data.QUANTITY * resp.data.TAX_VALUE);
            this.saveLabour.LabourList[index].SCHEME_DISC = resp.data.SCHEME_DISC;
            this.saveLabour.LabourList[index].DISC_VALUE = resp.data.DISC_VALUE;
            this.saveLabour.LabourList[index].QUANTITY = resp.data.QUANTITY;
            this.saveLabour.LabourList[index].TAX_VALUE = resp.data.TAX_VALUE;
            //this.saveLabour.LabourList[index].IGST_TAX_RATE = resp.data.IGST_TAX_RATE ? resp.data.IGST_TAX_RATE : 0;
            this.saveLabour.LabourList[index].HSN_CODE = resp.data.HSN_CODE;
            this.saveLabour.LabourList[index].OUTWORK_MEMO_ID = resp.data.OUTWORK_MEMO_ID;
            this.saveLabour.LabourList[index].STATUS = resp.data.STATUS;
            this.saveLabour.LabourList[index].CGST_TAX_RATE = resp.data.CGST_TAX_RATE ? resp.data.CGST_TAX_RATE : 0;
            this.saveLabour.LabourList[index].SGST_TAX_RATE = resp.data.SGST_TAX_RATE ? resp.data.SGST_TAX_RATE : 0;
            this.saveLabour.LabourList[index].IGST_TAX_RATE = resp.data.IGST_TAX_RATE ? resp.data.IGST_TAX_RATE : 0;
            this.saveLabour.LabourList[index].UTGST_TAX_RATE = resp.data.UTGST_TAX_RATE ? resp.data.UTGST_TAX_RATE : 0;
            this.saveLabour.LabourList[index].CESS_TAX_RATE = resp.data.CESS_TAX_RATE ? resp.data.CESS_TAX_RATE : 0;
            this.saveLabour.LabourList[index].TAX_RATE = resp.data.TAX_RATE ? resp.data.TAX_RATE : 0;
            this.saveLabour.LabourList[index].SchemeDetailsList = resp.data.SchemeDetailsList;
            this.saveLabour.LabourList[index].SCH_TYPE = resp.data.SCH_TYPE;
            this.calculateAmount();
            this.isShowPageLoader = false;
            this.isDisableLabour = false;
          } else {
            this.toastr.error(resp.message);
            this.isShowPageLoader = false;
            this.saveLabour.LabourList[index].LABOUR_ID = '';
            this.saveLabour.LabourList[index].QUANTITY = '';
          }
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }, error => {
          this.toastr.error(error.error.Message);
          this.isShowPageLoader = false;
          if (error.status == 401) {
            this.loginService.logout();
          this.saveLabour.LabourList[index].LABOUR_ID = '';
          
          }
        });
      }
    } else {
      this.toastr.error(this.lang.Message.JOB_CARD.EXIST_LAB_CODE);
      this.saveLabour.LabourList[index].LABOUR_ID = '';
    }
  }
  /**
   * Add Labour
   */
  addLabour() {
    this.labourfilter = this.labourCode;
    if (this.saveLabour.LabourList.length == 0) {
      let refLabourObj = new LabourList();
      refLabourObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
      refLabourObj.MODE_ID = 1;
      refLabourObj.QUANTITY = 1;
      refLabourObj.LABOUR_ID = '';
      refLabourObj.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
      refLabourObj.ROW_STATE = 'Created';
      this.saveLabour.LabourList.push(refLabourObj);
      $('.inlineTable').scrollTop($('.inlineTable')[0].scrollHeight);
    } else if (this.validateLabour(this.saveLabour.LabourList)) {
      let refLabourObj = new LabourList();
      refLabourObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
      refLabourObj.QUANTITY = 1;
      refLabourObj.MODE_ID = 1;
      refLabourObj.LABOUR_ID = '';
      refLabourObj.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
      refLabourObj.ROW_STATE = 'Created';
      this.saveLabour.LabourList.push(refLabourObj);
      $('.inlineTable').scrollTop($('.inlineTable')[0].scrollHeight);
    } else {
      this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_LABOURCODE);
    }
  }
  /**
   * Delete Labour based on index
   * @param {Number} index
   */
  deleteLabour(index) {

    let reqData = { header: this.lang.GENERAL.delete + ' ' + this.lang.SERVICES.labour, message: this.lang.GENERAL.deleteAlert, btnName1: this.lang.GENERAL.yes, btnName2: this.lang.GENERAL.cancel }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: reqData,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.saveLabour.LabourList[index].JCARD_LAB_ID) {
          let params = new HttpParams();
          params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
            .set('BranchID', CommonService.getUserData().BRANCH_ID)
            .set('JobCardID', this.jcId)
            .set('JCardLabID', this.saveLabour.LabourList[index].JCARD_LAB_ID)
            .set('LabID', this.saveLabour.LabourList[index].LABOUR_ID)
            .set('UserId', CommonService.getUserData().USER_ID);
          this.jobCardService.deleteJobCardLabour(params).subscribe((resp: any) => {

            if (resp && resp.statusCode == 200 && resp.data) {
              this.saveLabour.LabourList.splice(index, 1);
              this.calculateAmount();
              this.toastr.success(this.lang.Message.JOB_CARD.LABOUR_DELETE);
            } else {
              this.toastr.error(resp.message);
              if (resp && resp.statusCode == 401) {
                this.loginService.logout();
              }
            }
          }, error => {
            this.toastr.error(error.error.Message);
            this.isShowPageLoader = false;
            if (error.status == 401) {
              this.loginService.logout();
            }
          });
        }
        else {
          this.saveLabour.LabourList.splice(index, 1);
          this.calculateAmount();
          this.toastr.success(this.lang.Message.JOB_CARD.LABOUR_DELETE);
        }
      }
    });
  }
  /**
   * Calculate total labour and total issued labour
   */
  calculateAmount() {

    let labourTotalVal: number = 0;
    let labourTotalIssued: number = 0;
    if (this.saveLabour.LabourList && this.saveLabour.LabourList.length > 0) {
      this.saveLabour.LabourList.filter(val => {
        // if (val.LABOUR_ID && (val.MODE_ID != 2 && val.MODE_ID != 3 && val.MODE_ID != 4 && val.MODE_ID != 5) && val.QUANTITY) {
        //   labourTotalVal += val.LABOUR_CHARGES ? parseFloat(val.LABOUR_CHARGES) : 0;
        //   //labourTotalVal += val.TAX_VALUE ?  parseFloat(val.TAX_VALUE) : 0;       
        //   labourTotalIssued += val.QUANTITY ? parseFloat(val.QUANTITY) : 0;

        // }
        let dtempLab1: number = 0;
        let dtempLab: number = 0;
        let dTotalLabour: number = 0;

        if (val.LABOUR_ID && (val.MODE_ID == 1 || val.MODE_ID == 7) && val.QUANTITY) {
          if (parseFloat(val.DISC_AMT) > 0) {
            dtempLab1 = (parseFloat(val.QUANTITY) * parseFloat(val.LABOUR_RATE)) - ((parseFloat(val.QUANTITY) * (parseFloat(val.DISC_AMT) / parseFloat(val.QUANTITY)))) - parseFloat(val.DISC_VALUE);
            dtempLab = parseFloat(val.TAX_VALUE) * parseFloat(val.QUANTITY);
            dTotalLabour += dtempLab + dtempLab1;

          }
          else {
            dTotalLabour += (parseFloat(val.QUANTITY) * parseFloat(val.LABOUR_RATE)) + (parseFloat(val.QUANTITY) * parseFloat(val.TAX_VALUE)) - parseFloat(val.DISC_AMT) - parseFloat(val.DISC_VALUE);
          }
          labourTotalVal += dTotalLabour;//val.LABOUR_CHARGES ? parseFloat(val.LABOUR_CHARGES) : 0;       
          //labourTotalIssued += val.QUANTITY ? parseFloat(val.QUANTITY) : 0;
        }
      });
    } else {
      this.saveLabour.LabourList = [];
    }
    this.labourTotalVal = labourTotalVal.toFixed(2);
    this.totalLabVal.emit(this.labourTotalVal);
    //    this.labourTotalIssued = labourTotalIssued.toFixed(2);
    this.getLabourCount();
    this.getTotalLabourCount();
  }
  getIsValidLabour() {

    if (this.getLabourCount() > 0 && this.isValidLabour.dirty && this.isValidLabour.valid && !this.isDisableLabour) {
      return true;
    } else {
      return false;
    }
  }
  getLabourCount(): any {
    let labCount = this.saveLabour.LabourList.filter(val => {
      return val.LABOUR_ID && val.MODE_ID && val.QUANTITY;
    });
    return labCount.length;
  }

  getTotalLabourCount() {
    let labCount = this.saveLabour.LabourList.filter(val => {
      return val.LABOUR_ID && val.MODE_ID && val.QUANTITY;
    });
    this.labourTotalIssued = labCount.length;
  }
  /**
  * Validate Labour 
  * @param {Object} labour
  */
  validateLabour(labour) {
    let valid = true;
    if (labour.length == 0) {
      this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_LABOUR);
      return valid = false;
    }
    if (labour.length > 0) {
      labour.filter(val => {
        if (!val.LABOUR_ID || val.LABOUR_ID <= -1
          || !val.MODE_ID || val.MODE_ID <= -1 || !val.QUANTITY) {
          this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_LABOURCODE);
          return valid = false;
        }
      });
    }
    return valid;
  }
  /**
   * Save Labour
   */
  saveLabours() {
    this.labourfilter = this.labourCode;
    if (this.validateLabour(this.saveLabour.LabourList)) {
      this.isShowPageLoader = true;
      this.saveLabour.DealerID = CommonService.getUserData().DEALER_ID;
      this.saveLabour.BranchID = CommonService.getUserData().BRANCH_ID;
      this.saveLabour.JobCardID = this.jcId;
      this.saveLabour.UserId = CommonService.getUserData().USER_ID;
      this.jobCardService.saveJobCardLabour(this.saveLabour).subscribe((resp: any) => {
        if (resp && resp.statusCode == 200 && resp.data) {
          this.toastr.success(this.lang.Message.JOB_CARD.LABOUR_SUCCESS);
        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
        this.isDisableLabour = true;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }

  /**
   * Dlr AMC Change Event
   */
  ddlAMCChange() {
    if (this.saveLabour.LabourList.length > 0) {
      let request = {
        "DealerID": CommonService.getUserData().DEALER_ID,
        "BranchID": CommonService.getUserData().BRANCH_ID,
        "JobCardID": this.jcId,
        "DlrAMCType": this.dlrAmcId,
        "CountryCode": CommonService.getDealerData().COUNTRY_CODE,
        "StateID": CommonService.getDealerData().STATE_ID,
        "UserId": CommonService.getUserData().USER_ID,
        LabourList: this.saveLabour.LabourList
      }
      this.isShowPageLoader = true;
      this.jobCardService.dlrAMCChangeEvent(request).subscribe((resp: any) => {

        if (resp  && resp.statusCode == 200 && resp.data && resp.data.length > 0) {

          resp.data.filter(key => {
            if (key) {
              this.saveLabour.LabourList.filter(item => {
                if (item.LABOUR_ID == key.LABOUR_ID) {
                  item.SCHEME_DISC = key.SCHEME_DISC;
                  item.SCH_TYPE = key.SCH_TYPE;
                  item.DISC_AMT = key.DISC_AMT;
                  item.DISC_VALUE = key.DISC_VALUE;
                  item.TAX_RATE = key.TAX_RATE ? key.TAX_RATE : 0;
                  item.CGST_TAX_RATE = key.CGST_TAX_RATE ? key.CGST_TAX_RATE : 0;
                  item.SGST_TAX_RATE = key.SGST_TAX_RATE ? key.SGST_TAX_RATE : 0;
                  item.IGST_TAX_RATE = key.IGST_TAX_RATE ? key.IGST_TAX_RATE : 0;
                  item.UTGST_TAX_RATE = key.UTGST_TAX_RATE ? key.UTGST_TAX_RATE : 0;
                  item.CESS_TAX_RATE = key.CESS_TAX_RATE ? key.CESS_TAX_RATE : 0;
                  item.TAX_VALUE = key.TAX_VALUE ? key.TAX_VALUE : 0;
                }
              })
            }
          });
          this.calculateAmount();
        }
        else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }

  filterlabourList(index, event) {
    let localparam = this.saveLabour.LabourList[index];
    if (event.code != 'ArrowUp' && event.code != 'ArrowDown') {
    this.labourfilter = Object.assign([], this.labourCode).filter(
      item => item.name.toLowerCase().indexOf(localparam.LABOUR_ID.toLowerCase()) > -1
    );
    }
  }

  allowQuantity(evt) {
    evt = evt || window.event;
    var charCode = evt.which || evt.keyCode;
    var charStr = String.fromCharCode(charCode);
    if (/^[0-9]{1,100000}$/.test(charStr)) {
      return true;
    } else {
      return false;
    }
  }
}

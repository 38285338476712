import { Component, Inject, OnInit } from '@angular/core';

import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { PartSearchComponent } from '../part-search/part-search.component';
import { OutworkProcessService } from 'src/app/api-services/outwork-process.service';

@Component({
  selector: 'app-vendor-outwork-bill',
  templateUrl: './vendor-outwork-bill.component.html',
  styleUrls: ['./vendor-outwork-bill.component.scss']
})
 

export class VendorOutworkBillComponent implements OnInit {

  public agencyNames: any = [];//jr

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any;
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public partRows: any = [];

  public Outworkpaysearchlist: any = [];


  constructor(
    private dialogRef: MatDialogRef<VendorOutworkBillComponent>,
    private toastr: ToastrService,
    private outworkService: OutworkProcessService,
    private masterServices: MasterService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      
      
      this.status = [
        {
          name: 'Open',
          value: '1'
        },
        {
          name: 'Closed',
          value: '2'
        },
        {
          name: 'Cancelled',
          value: '3'
        },
        {
          name: 'Allotted',
          value: '4'
        },
        {
          name: 'Invoiced',
          value: '5'
        }
      ];
    }
  }

  ngOnInit() {
    this.inlineFilter = {
      pmt_no: '',
      pmt_date:'',
      vendor_name: '',
      pmt_status: '',
      
    };
    this.getAgencyList(); //jr
  }

  //created by surya on 28-12-2021
getAgencyList() {
  let params = new HttpParams();
  params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
    .set('BranchID', CommonService.getUserData().BRANCH_ID)
    .set('UserID', CommonService.getUserData().USER_ID)
    .set('VendorCatID', "2");
  this.outworkService.getOutworkMemoAgencyNamesList(params).subscribe((resp: any) => {
    if (resp && resp.statusCode === 200) {
      this.agencyNames = resp.data;
      
    } else {
      this.toastr.error(resp.message);
      if (resp && resp.statusCode == 401) {
        //this.loginService.logout();
      }
    }
  }, error => {
    this.toastr.error(error.error.Message);
    if (error.status == 401) {
    //  this.loginService.logout();
    }
   // this.isShowPageLoader = false;
  });
}

  closeDialog() {
    this.dialogRef.close(false);
  }

  // searchList() {
  //     this.getAscCouponClaimPaymentList();
  // }
  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'tvsInvoiceSearch') {
      if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
      this.selected[0].TYPE = this.part.invoiceTypeId;
    }
    let closeObj = { 
      obj: this.selected[0],
      // typeId: this.data.voucherId
    };
    this.dialogRef.close(closeObj);
  }

  //grn-tvs invoice popup
  searchList() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Booking From Date is empty');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Booking to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;    
    reqObj.ASC_ID = CommonService.getDealerData().ASC_ID;
    reqObj.pmt_id = this.part.ClaimREFNO ? Number(this.part.ClaimREFNO) : null;
    reqObj.PAY_DATE_FROM = fromDateFormat;
    reqObj.PAY_DATE_TO = toDateFormat;
    reqObj.cust_ty_id=0;
    reqObj.Vehicle_Id=0;
    reqObj.VENDOR_ID=this.part.VENDOR_ID;
    reqObj.ASC_CPN_CLM_NO=0;
    reqObj.COUPON_NOTES="";
    reqObj.DLR_CPN_CLM_ID="";
    reqObj.DLR_CPN_CLM_NO="";
    reqObj.CLAIM_VALUE="";
    reqObj.ClaimREFNO="";
    reqObj.STATUS=0;
    reqObj.DLR_CLAIM_STATUS=0;
    reqObj.SEQ_NO=0;
    reqObj.CREATED_BY="";
    reqObj.MODIFIED_BY="";
    reqObj.CREATE_BY=0;
    reqObj.MODIFY_BY=0;
    reqObj.ACTIVE=true;
    reqObj.FIN_YEAR=CommonService.getDealerData().FIN_YEAR;
    reqObj.OPEN_VALUE=0;
    reqObj.CouponSummaryXML="";
    reqObj.STATUS1=0;
    reqObj.STATUS2=0;
    reqObj.BRANCH_NAME="";
    reqObj.CLAIM_STATUS="";
    reqObj.ASCName="";
    reqObj.chkSelect="";
    reqObj.CONTACT_NO="";
    reqObj.ADDRESS_LINE_1="";
    reqObj.ROW_STATE=0;


    reqObj.ASCName = this.part.ASCName ? this.part.ASCName : null;
    // this.Outworkpaysearchlist = [];
    this.masterServices.post(reqObj, '/Voucher/Searchcreditnoteoutworkpay').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.Outworkpaysearchlist = resp.searchoutworkpay;
        console.log("outworkpaylist",this.Outworkpaysearchlist);
      } else if (resp && resp.statusCode == 204) {
        this.Outworkpaysearchlist = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}
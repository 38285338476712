import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { MasterService } from '../../api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import {
  VoucherAccDetail,
  LedgerEntryDet,
  ReceiptVoucher
} from 'src/app/api-services/dto/receipt-voucher';
import { JobCardSearchComponent } from '../job-card-search/job-card-search.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
declare var moment;


@Component({
  selector: 'app-receipt-voucher',
  templateUrl: './receipt-voucher.component.html',
  styleUrls: ['./receipt-voucher.component.scss']
})
export class ReceiptVoucherComponent implements OnInit {

  public lang: any;
  @Input() voucherInfo: any = {};
  @Input() isView: any = true;
  public paymentHeader: any;
  public isShowPageLoader: boolean;
  public paymentMaster: any = [];
  public docDate: any = new Date();
  public isActivePopup: any;
  public receiptVoucher = new ReceiptVoucher();
  public voucherAccDetail = new VoucherAccDetail();
  public ledgerEntryDet = new LedgerEntryDet();
  // public financialYear: any = [];
  public voucherDetails: any = {};
  public slCodeDetails: any = [];
  public maxDate: any = new Date();
  public voucherList: Array<any> = [];
  // public voucherTypes :any = [
  //   {
  //     id: '901',
  //     name: 'UVD Booking Advance'
  //   },
  //   {
  //     id: '902',
  //     name: 'Procurement Payments'
  //   }
  // ];
  public voucherTypes: any = [
    {
      id: '903',
      name: 'UVD Booking Advance'
    },
    {
      id: '904',
      name: 'UVD Invoice Receipt'
    }
  ];
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  isActiveView: boolean;

  constructor(
    private masterService: MasterService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReceiptVoucherComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public commonService: CommonService,
    public toastr: ToastrService,
  ) {
    this.receiptVoucher.VoucherAccDetail = [];
  }

  ngOnInit() {
    console.log(this.voucherInfo,'okk');
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      this.paymentHeader = [
        {
          name: this.lang.UVD.glCode,
          width: 'wmpx-150'
        },
        {
          name: this.lang.UVD.glDesc,
          width: 'wmpx-200'
        },
        {
          name: this.lang.UVD.slCode,
          width: 'wmpx-150'
        },
        {
          name: this.lang.UVD.slDesc,
          width: 'wmpx-150'
        },
        {
          name: this.lang.UVD.value,
          width: 'wmpx-150'
        },
        {
          name: this.lang.UVD.isDebit,
          width: 'wmpx-150'
        }
      ];
      this.receiptVoucher.PAYMENT_MODE_ID = '1';
      this.receiptVoucher.CREDIT_LIMIT_TYPE = '1'; 

      this.receiptVoucher.PARTY_CAT = '1';
      this.receiptVoucher.DOC_DATE = new Date();
      this.receiptVoucher.VOUCHER_DT = new Date();
      this.receiptVoucher.INSTR_DATE = new Date();
      

      if (this.data && this.data.dmsType == 'UVDAdvance' ) {
        this.voucherList =  [
          {
            VCHR_TYPE_ID: '903',
            DESCRIPTION: 'UVD Booking Advance'
          }
        ];        
        this.receiptVoucher.VCHR_TYPE_ID = '903';
      }

      else  if (this.data && this.data.dmsType == 'UVDReceipt' )
      {
        this.voucherList =  [         
          {
            VCHR_TYPE_ID: '904',
            DESCRIPTION: 'UVD Invoice Receipt'
          }
        ];        
        this.receiptVoucher.VCHR_TYPE_ID = '904';
      }
      else {
        this.voucherList = CommonService.getSalesAppData().VoucherList;
        if(this.voucherList && this.voucherList.length>0){
        var itm1 = this.voucherList.filter(h => h.VCHR_TYPE_ID != 903);
        var itm2 = itm1.filter(h => h.VCHR_TYPE_ID != 904);
        this.voucherList = itm2;
        }
        if (this.data) {
            this.receiptVoucher.VCHR_TYPE_ID = this.data.vchrTypeId;
        }
      }

      if (this.data && this.data.type == 'viewReceipt') {
        this.isActiveView = true;
        this.voucherDetails.documentId = this.data.value.id ? this.data.value.id : '';
        this.voucherDetails.documentNo = this.data.value.voucherNo ? this.data.value.voucherNo : '';
        this.getViewDetails();
      } else {
        this.receiptVoucher.DOCUMENT_ID = this.data && this.data.value ? this.data.value.salesENQNO : '';
        this.receiptVoucher.VCHR_VALUE = this.data && this.data.vehicleCost ? this.data.vehicleCost : '';
        this.receiptVoucher.PARTY_CODE = this.data && this.data.value ? this.data.value.userInfo.customerId : '';
        this.receiptVoucher.PARTY_NAME = this.data && this.data.value ? this.data.value.userInfo.customerName : '';
        setTimeout(() => {
          this.getSlCode();
        }, 500);
      }


    
      
      if (this.data && this.data.type == 'reverse') {
       
        this.isActiveView = true;
        let voucherList =  [
          {
            VCHR_TYPE_ID: '903',
            DESCRIPTION: 'UVD Booking Advance'
          },
          {
            VCHR_TYPE_ID: '904',
            DESCRIPTION: 'UVD Invoice Receipt'
          }
        ]; 
        this.voucherList = [];
          voucherList.filter( val =>{
             if(this.data.receiptInfo.voucherTypeId.toString()  == val.VCHR_TYPE_ID ) {
               this.voucherList.push(val);
             }
           });
console.log(this.data);
        this.voucherDetails.documentId = this.data.receiptInfo.id ? this.data.receiptInfo.id : '';
        this.voucherDetails.documentNo = this.data.receiptInfo.voucherNo ? this.data.receiptInfo.voucherNo : '';
        this.receiptVoucher.VCHR_TYPE_ID = this.data.receiptInfo.voucherTypeId ?
                                           this.data.receiptInfo.voucherTypeId.toString(): '';
                                          
        this.getViewDetails();
      }
      if (this.data && this.data.type == 'UVDJobcardReceipt') {
           
       this.voucherList =  [
          {
            VCHR_TYPE_ID: '102',
            DESCRIPTION: 'Job card Advance'
          },
          {
            VCHR_TYPE_ID: '111',
            DESCRIPTION: 'Service Spares Invoice Receipt'
          }
        ]; 
        this.receiptVoucher.VCHR_TYPE_ID = '102';
      }

      if (this.data && this.data.type == 'JobCardReverse') {
       
        this.isActiveView = true;
        let voucherList =  [
          {
            VCHR_TYPE_ID: '102',
            DESCRIPTION: 'Job card Advance'
          },
          {
            VCHR_TYPE_ID: '111',
            DESCRIPTION: 'Service Spares Invoice Receipt'
          }
        ]; 
        this.voucherList = [];
          voucherList.filter( val =>{
             if(this.data.receiptInfo.VCHR_TYPE_ID.toString()  == val.VCHR_TYPE_ID ) {
               this.voucherList.push(val);
             }
           });
        this.voucherDetails.documentId = this.data.receiptInfo.VOUCHER_ID ? this.data.receiptInfo.VOUCHER_ID : '';
        this.voucherDetails.documentNo = this.data.receiptInfo.VOUCHER_NO ? this.data.receiptInfo.VOUCHER_NO : '';
        this.receiptVoucher.VCHR_TYPE_ID = this.data.receiptInfo.VCHR_TYPE_ID ?
                                           this.data.receiptInfo.VCHR_TYPE_ID.toString(): '';
                                          
        this.getViewDetails();
      }

      this.getMasterPayment();
      this.financialYears();
    }

    
  }
  checkVoucherValue() {
  if(Number(this.receiptVoucher.VCHR_VALUE) > Number(this.data.vehicleCost) ) {
      this.receiptVoucher.VCHR_VALUE =  this.data.vehicleCost;
  }
  }
  setDocumentNo(){
       if( this.receiptVoucher.VCHR_TYPE_ID == '102'){        
        this.receiptVoucher.DOCUMENT_ID = this.data && this.data.value ? this.data.value.jcId : '';
       } else if (this.receiptVoucher.VCHR_TYPE_ID == '111') {
        this.receiptVoucher.DOCUMENT_ID = this.data && this.data.value ? this.data.value.invoiceId : '';
        this.receiptVoucher.VCHR_VALUE = this.data && this.data.vehicleCost ? this.data.vehicleCost : '';
      }
  }
  financialYears() {
    let finYear;
    let today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      finYear = (today.getFullYear() - 1);
    } else {
      finYear = today.getFullYear();
    }
    this.receiptVoucher.FIN_YEAR = finYear;
    
  }

  getMasterPayment() {
    this.isShowPageLoader = true;
    this.masterService.post('', '/Voucher/GetVoucherBasicinfo?dealerID=' + CommonService.getUserData().DEALER_ID).subscribe(
      resp => {
        if (resp) {
          this.isShowPageLoader = false;
          this.paymentMaster = resp;
          if (this.paymentMaster.companyData && this.paymentMaster.companyData.length) {
            this.receiptVoucher.COMPANY_ID = this.paymentMaster.companyData[0].companyID;
            this.receiptVoucher.company_name = this.paymentMaster.companyData[0].companyName;
          }
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      }
    )
  }

  getSlCode() {
    if (this.data && this.data.type == 'reverse' || this.data.type == 'JobCardReverse') {
      return;
    }
    if (!this.receiptVoucher.PARTY_CODE) {
      this.toastr.error('Party  code is empty');
      return;
    };
    let slcodeObj: any = {};
    slcodeObj = {
      'DEALER_ID': CommonService.getUserData().DEALER_ID,
      'Ref_Id': this.receiptVoucher.PARTY_CODE
    };
    this.isShowPageLoader = true;
    this.masterService.post(slcodeObj, '/Voucher/GetSLCodeByCustomerID').subscribe(
      (resp: any) => {
        if (resp) {
          this.slCodeDetails = resp.SLdetails.length > 0 ? resp.SLdetails : [];
          this.isShowPageLoader = false;
          this.getAccountMapping();
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      }
    );
  }
  getAccountMapping() {
    let reqMapping: any = {};
    let mapError: any = 0;
    if (!this.receiptVoucher.COMPANY_ID) {
      this.toastr.error('Company Id is empty');
      mapError += 1;
    }
    if (!this.receiptVoucher.DOCUMENT_ID) {
      this.toastr.error('Document Id is empty');
      mapError += 1;
    }
    if (!this.receiptVoucher.PAYMENT_MODE_ID) {
      this.toastr.error('Payment mode is empty');
      mapError += 1;
    }
    if (!this.receiptVoucher.VCHR_TYPE_ID) {
      this.toastr.error('Voucher type is empty');
      mapError += 1;
    }
    if (mapError > 0) {
      return;
    }
    reqMapping = {
      'DEALER_ID': CommonService.getUserData().DEALER_ID,
      'COMPANY_ID': this.receiptVoucher.COMPANY_ID,
      'DOC_ID': 9, // default value ...
      'payment_mode_id': this.receiptVoucher.PAYMENT_MODE_ID,
      'VOUCHER_TYPE': this.receiptVoucher.VCHR_TYPE_ID,
      'bank_id': this.receiptVoucher.BANK_ID
    };
    if (this.data && this.data.type == 'UVDJobcardReceipt') {
    reqMapping.DOC_ID =1;
    }
    this.isShowPageLoader = true;
    this.receiptVoucher.VoucherAccDetail = [];
    this.masterService.post(reqMapping, '/Voucher/GetAccountMapping').subscribe(
      (resp: any) => {
        if (resp) {
          setTimeout(() => {
            if (resp.Accountdetails && resp.Accountdetails.length > 0 && this.slCodeDetails && this.slCodeDetails.length > 0) {
              this.receiptVoucher.VoucherAccDetail = [
                {
                  GL_CODE: resp.Accountdetails[0].debit_gl_id ? resp.Accountdetails[0].debit_gl_id : '',
                  Gen_Desc: resp.Accountdetails[0].Gen_Desc_Debt ? resp.Accountdetails[0].Gen_Desc_Debt : '',
                  SL_CODE: this.slCodeDetails[0].SL_CODE,
                  Sub_Desc: this.slCodeDetails[0].Sub_Desc,
                  ACC_VALUE: '',
                  VCHR_VALUE: this.receiptVoucher.VCHR_VALUE.toString(),
                  CREDIT_LIMIT_TYPE: '1',
                  DEBIT_CREDIT: '',
                  IS_DEBIT: true
                },
                {
                  GL_CODE: resp.Accountdetails[0].credit_gl_id ? resp.Accountdetails[0].credit_gl_id : '',
                  Gen_Desc: resp.Accountdetails[0].Gen_Desc ? resp.Accountdetails[0].Gen_Desc : '',
                  SL_CODE: '',
                  Sub_Desc: '',
                  ACC_VALUE: '',
                  VCHR_VALUE: this.receiptVoucher.VCHR_VALUE.toString(),
                  CREDIT_LIMIT_TYPE: '1',
                  DEBIT_CREDIT: '',
                  IS_DEBIT: false
                }];
              this.isShowPageLoader = false;
            }
          }, 2000);
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      }
    )
  }
  printVoucher() {
    let printObj: any = {};
    if (!this.voucherDetails.documentNo || !this.voucherDetails.documentId) {
      this.toastr.error('Please complete payment');
      return;
    }
    this.isShowPageLoader = true;
    this.masterService.get(printObj, '/Voucher/PrintVoucherByID?DEALER_ID='
      + CommonService.getUserData().DEALER_ID + '&BRANCH_ID=' + CommonService.getUserData().BRANCH_ID + '&VOUCHER_ID=' +
      this.voucherDetails.documentId).subscribe(
        (resp: any) => {
          if (resp && resp.statusMessage) {
            this.isShowPageLoader = false;
            CommonService.printData(resp.printString);
            // this.toastr.success(resp.statusMessage);
          }
        },
        error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        }
      )
  }
  getViewDetails() {
    let viewdltError: any = 0;
    // if (!this.receiptVoucher.DOC_TYPE) {
    //   this.toastr.error('Document type is empty');
    //   viewdltError += 1;
    // } else if (this.receiptVoucher.VCHR_TYPE_ID) {
    //   this.toastr.error('Voucher id is empty');
    //   viewdltError += 1;
    // }
    // if (viewdltError > 0) {
    //   return;
    // }
  
    let viewObj = {
      'DEALER_ID': CommonService.getUserData().DEALER_ID,
      'BRANCH_ID': CommonService.getUserData().BRANCH_ID,
      'VOUCHER_ID': this.voucherDetails.documentId,
      'DOC_TYPE': 9,
      'VCHR_TYPE_ID': this.receiptVoucher.VCHR_TYPE_ID
    };
    if (this.data && this.data.type == 'JobCardReverse') {
      viewObj.DOC_TYPE =1;
      }
      this.isShowPageLoader = true;
    this.masterService.post(viewObj, '/Voucher/GetVoucherByID').subscribe(
      (resp: any) => {
        if (resp) {
          this.isShowPageLoader = false;
          this.receiptVoucher = resp.VoucherSearchList[0];
          this.receiptVoucher.VoucherAccDetail = resp.VoucherSearchList[0] && resp.VoucherSearchList[0].LEDGER_ENTRY_DET
            && resp.VoucherSearchList[0].LEDGER_ENTRY_DET.length ?
            resp.VoucherSearchList[0].LEDGER_ENTRY_DET : [];
          this.receiptVoucher.DOC_DATE = resp.VoucherSearchList[0].DOC_DATE ? CommonService.generalFormat(resp.VoucherSearchList[0].DOC_DATE) : '';
          this.receiptVoucher.VOUCHER_DT = resp.VoucherSearchList[0].VOUCHER_DT ? CommonService.generalFormat(resp.VoucherSearchList[0].VOUCHER_DT) : '';
          this.receiptVoucher.DOCUMENT_ID = resp.VoucherSearchList[0].DOCUMENT_ID;
          this.receiptVoucher.INSTR_DATE = resp.VoucherSearchList[0].INSTR_DATE ? CommonService.generalFormat(resp.VoucherSearchList[0].INSTR_DATE) : '';
          this.receiptVoucher.VCHR_TYPE_ID = resp.VoucherSearchList[0].VCHR_TYPE_ID.toString();
          this.receiptVoucher.CREDIT_LIMIT_TYPE = '1';
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      }
    );
  }

  //search by  customer name
  searchCustomer(str) {
    let data = { key: str };
    const dialogRef = this.dialog.open(JobCardSearchComponent, {
      width: '1200px',
      data: data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.obj) {
        this.receiptVoucher.PARTY_CODE = result.obj.CUSTOMER_ID;
        this.receiptVoucher.PARTY_NAME = result.obj.CUST_NAME;
        this.getSlCode();
        this.getAccountMapping();
      }
    });
  }
  validatePayment() {
    let paymentError: any = 0;
    if (!this.receiptVoucher.VCHR_TYPE_ID) {
      this.toastr.error('Voucher Type is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.FIN_YEAR) {
      this.toastr.error('Finance Year is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.COMPANY_ID) {
      this.toastr.error('Company Number  is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.company_name) {
      this.toastr.error('Company Name  is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.PAYMENT_MODE_ID) {
      this.toastr.error('Payment Mode  is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.DOCUMENT_ID) {
      this.toastr.error('Document No is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.DOC_DATE) {
      this.toastr.error('Document Date is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.DOC_DATE) {
      this.toastr.error('Document Date is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.CREDIT_LIMIT_TYPE) {
      this.toastr.error('Credit Limit is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.VCHR_VALUE) {
      this.toastr.error('Voucher Value is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.VOUCHER_DT) {
      this.toastr.error('Voucher Date is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.PARTY_CAT) {
      this.toastr.error('Party Category is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.PARTY_CODE) {
      this.toastr.error('Party Code is empty');
      paymentError += 1;
    }
    if (!this.receiptVoucher.PARTY_NAME) {
      this.toastr.error('Party Name is empty');
      paymentError += 1;
    }
    if (this.receiptVoucher.PAYMENT_MODE_ID == '4' || this.receiptVoucher.PAYMENT_MODE_ID == '6') {
      if (!this.receiptVoucher.BANK_ID) {
        this.toastr.error('Bank Name is empty');
        paymentError += 1;
      }
    }
    if (paymentError > 0) {
      return false;
    }
    if (this.receiptVoucher.PAYMENT_MODE_ID && this.receiptVoucher.PAYMENT_MODE_ID == '4' || this.receiptVoucher.PAYMENT_MODE_ID == '6') {
      let instrError: any = 0;
      if (!this.receiptVoucher.INSTR_NO) {
        this.toastr.error('Instrument No  is empty');
        instrError += 1;
      }
      if (!this.receiptVoucher.INSTR_DATE) {
        this.toastr.error('Instrument Date is empty');
        instrError += 1;
      }
      if (!this.receiptVoucher.INSTR_AMT) {
        this.toastr.error('Instrument Value is empty');
        instrError += 1;
      }
      if (!this.receiptVoucher.ACCOUNT_NO) {
        this.toastr.error('Account No is empty');
        instrError += 1;
      }
      if (!this.receiptVoucher.BANK_BRANCH) {
        this.toastr.error('Issuing Branch is empty');
        instrError += 1;
      }
      if (instrError > 0) {
        return false;
      }
    }
    //error check for account details
    let accEror: any = 0;
    if (this.receiptVoucher.VoucherAccDetail.length > 0) {
      this.receiptVoucher.VoucherAccDetail.filter(accKey => {
        if (!accKey.GL_CODE || !accKey.Gen_Desc || !accKey.VCHR_VALUE) {
          accEror += 1;
        }
        if (!accKey.SL_CODE && accKey.IS_DEBIT == true) {
          accEror += 1;
        }
      })
      if (accEror > 0) {
        this.toastr.error('Account details is  empty');
        return false;
      }
    }
    // error check for ledger entry details
    let LedgerEntryError: any = 0;
    // console.log(this.receiptVoucher.LedgerEntryDet.length);
    // if( this.receiptVoucher.LedgerEntryDet.length > 0) {
    //   this.receiptVoucher.LedgerEntryDet.filter(accKey => {
    //     console.log(accKey);
    //     if(!accKey.GL_CODE  || !accKey.Gen_Desc || !accKey.VCHR_VALUE) {
    //       LedgerEntryError += 1;
    //     }
    //     if (!accKey.SL_CODE && accKey.IS_DEBIT == 'true' ) {
    //       LedgerEntryError += 1;
    //     }
    //   })
    //   if(LedgerEntryError > 0){
    //     this.toastr.error('Ledger details is empty');
    //     return false;
    //   }
    // }  
    return true;
  }

  savePayment() {
    console.log(this.receiptVoucher);
    if (this.validatePayment() != true) {
      return;
    }

    this.receiptVoucher.LedgerEntryDet = [];
    this.assignLedgerEntryDetails();
    let createObj: any = {};
    createObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    createObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    createObj.VOUCHER_ID = this.receiptVoucher.VOUCHER_ID ? this.receiptVoucher.VOUCHER_ID : 0;
    createObj.VOUCHER_NO = this.receiptVoucher.VOUCHER_NO ? Number(this.receiptVoucher.VOUCHER_NO) : 0;
    createObj.CREATED_BY = CommonService.getUserData().USER_ID.toString();
    createObj.VOUCHER_DT = CommonService.setDateTime(this.receiptVoucher.VOUCHER_DT);
    createObj.VCHR_TYPE_ID = Number(this.receiptVoucher.VCHR_TYPE_ID);
    // createObj.VCHR_TYPE_ID = 903; //  default  value 
    createObj.VCHR_VALUE = Number(this.receiptVoucher.VCHR_VALUE);
    createObj.VCHR_STATUS = this.receiptVoucher.VCHR_STATUS ? this.receiptVoucher.VCHR_STATUS : 1;// not ui need check
    createObj.FIN_YEAR = this.receiptVoucher.FIN_YEAR.toString();
    createObj.COMPANY_ID = this.receiptVoucher.COMPANY_ID.toString();
    createObj.PAYMENT_MODE_ID = this.receiptVoucher.PAYMENT_MODE_ID;
    createObj.DOCUMENT_ID = this.receiptVoucher.DOCUMENT_ID;
    createObj.DOC_TYPE = this.receiptVoucher.DOC_TYPE ? this.receiptVoucher.DOC_TYPE : 9; // not in ui need to add

   
    if (this.data && this.data.type == 'UVDJobcardReceipt') {
      createObj.DOC_TYPE = 1 ; 
      }
    createObj.DOC_DATE = CommonService.setDateTime(this.receiptVoucher.DOC_DATE);
    createObj.BASE_DOC_TYPE = this.receiptVoucher.BASE_DOC_TYPE ? Number(this.receiptVoucher.BASE_DOC_TYPE) : '6';
    createObj.PARTY_CODE = this.receiptVoucher.PARTY_CODE.toString();
    createObj.PARTY_NAME = this.receiptVoucher.PARTY_NAME;
    createObj.INSTR_NO = this.receiptVoucher.INSTR_NO ? this.receiptVoucher.INSTR_NO : null;
    createObj.INSTR_DATE = this.receiptVoucher.INSTR_DATE ? CommonService.setDateTime(this.receiptVoucher.INSTR_DATE) : null;
    createObj.INSTR_AMT = this.receiptVoucher.INSTR_AMT ? this.receiptVoucher.INSTR_AMT : null;
    createObj.INSTRUMENT_ON = this.receiptVoucher.INSTRUMENT_ON ? this.receiptVoucher.INSTRUMENT_ON : null;
    createObj.ACCOUNT_NO = this.receiptVoucher.ACCOUNT_NO ? this.receiptVoucher.ACCOUNT_NO : null;
    createObj.PARTY_CAT = Number(this.receiptVoucher.PARTY_CAT);
    createObj.CRED_CARD_TY_ID = this.receiptVoucher.CRED_CARD_TY_ID ? this.receiptVoucher.CRED_CARD_TY_ID : null;
    createObj.CRED_CARD_EXP_DT = this.receiptVoucher.CRED_CARD_EXP_DT ? this.receiptVoucher.CRED_CARD_EXP_DT : null;
    createObj.APPROVAL_NO = this.receiptVoucher.APPROVAL_NO ? this.receiptVoucher.APPROVAL_NO : null;
    createObj.BANK_BRANCH = this.receiptVoucher.BANK_BRANCH ? this.receiptVoucher.BANK_BRANCH : null;
    createObj.BANK_ID = this.receiptVoucher.BANK_ID ? this.receiptVoucher.BANK_ID : null;
    createObj.BASE_VOUCHER_ID = this.receiptVoucher.BASE_VOUCHER_ID ? this.receiptVoucher.BASE_VOUCHER_ID : null;
    createObj.NARRATION = this.receiptVoucher.NARRATION;
    createObj.UNRECON_VAL = this.receiptVoucher.UNRECON_VAL ? this.receiptVoucher.UNRECON_VAL : '0';
    createObj.CRED_LMT_TYPE = this.receiptVoucher.CRED_LMT_TYPE ? Number(this.receiptVoucher.CRED_LMT_TYPE) : 1;
    createObj.TDS_APPLIED = this.receiptVoucher.TDS_APPLIED ? this.receiptVoucher.TDS_APPLIED : 'false',
      createObj.ACTIVE = this.receiptVoucher.ACTIVE ? this.receiptVoucher.ACTIVE : 'true';
    // createObj.VOUCHER_ACC_DETAILS = this.receiptVoucher.VoucherAccDetail.length > 0 ? this.receiptVoucher.VoucherAccDetail : [];
    let voucherDtl: any = [];
    if (this.receiptVoucher.VoucherAccDetail.length > 0) {
      this.receiptVoucher.VoucherAccDetail.filter(val => {
        voucherDtl.push({
          'GL_CODE': val.GL_CODE,
          'SL_CODE': val.SL_CODE,
          'ACC_VALUE': val.VCHR_VALUE,
          'CREDIT_LIMIT_TYPE': '1',
          'IS_DEBIT': val.IS_DEBIT
        });
      });
    }
    createObj.VOUCHER_ACC_DETAILS = voucherDtl.length > 0 ? voucherDtl : [];
    createObj.LEDGER_ENTRY_DET = this.receiptVoucher.LedgerEntryDet.length > 0 ? this.receiptVoucher.LedgerEntryDet : [];
    this.masterService.post(createObj, '/Voucher/CreatePayment').subscribe(
      (resp: any) => {
        this.toastr.success(resp.statusMessage);
        this.commonService.sendMessage('finalStatus');
        // this.voucherDetails=resp;S
        this.dialogRef.close(resp.documentNo);
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      });
  }
  assignLedgerEntryDetails() {
    if (this.receiptVoucher && this.receiptVoucher.VoucherAccDetail.length > 0) {
      this.receiptVoucher.VoucherAccDetail.filter((key: any) => {
        this.receiptVoucher.LedgerEntryDet.push(
          {
            'DEALER_ID': CommonService.getUserData().DEALER_ID.toString(),
            // 'VOUCHER_DATE': CommonService.setDateTime(this.receiptVoucher.VOUCHER_DT),
            'VOUCHER_DATE': new Date(),
            'FIN_YEAR': this.receiptVoucher.FIN_YEAR.toString(),
            // 'VOUCHER_SUB_TYPE': this.receiptVoucher.VCHR_TYPE_ID, 
            // 'VOUCHER_SUB_TYPE': '903',
            'VOUCHER_SUB_TYPE': this.data && this.data.type == 'UVDJobcardReceipt' || this.data.type == 'JobCardReverse' ? this.receiptVoucher.VCHR_TYPE_ID:'903',
            'PARTY_CAT': this.receiptVoucher.PARTY_CAT,
            'PARTY_CODE': this.receiptVoucher.PARTY_CODE.toString(),
            'VOUCHER_STATUS': this.receiptVoucher.VCHR_STATUS ? this.receiptVoucher.VCHR_STATUS.toString() : '1',
            'NARRATION': this.receiptVoucher.NARRATION,
            'DOC_ID': this.receiptVoucher.DOCUMENT_ID,
            'payment_mode_id': this.receiptVoucher.PAYMENT_MODE_ID,
            'VOUCHER_TYPE': this.receiptVoucher.VCHR_TYPE_ID,
            // 'VOUCHER_TYPE': '903',
            'COMPANY_ID': this.receiptVoucher.COMPANY_ID.toString(),
            'bank_id': this.receiptVoucher.BANK_ID ? this.receiptVoucher.BANK_ID.toString() : null,
            'GL_CODE': key.GL_CODE,
            'Gen_Desc': key.Gen_Desc,
            'SL_CODE': key.SL_CODE,
            'Sub_Desc': key.Sub_Desc,
            'VCHR_VALUE': key.VCHR_VALUE,
            'IS_DEBIT': key.IS_DEBIT
          }
        )
      });
    }
  }
  reverseReceipt() {
    this.receiptVoucher.LedgerEntryDet = [];
    this.assignLedgerEntryDetails();
    let reverseReceiptObj: any = {};
    if (this.validatePayment() != true) {
      return;
    }
    reverseReceiptObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reverseReceiptObj.COMPANY_ID = this.receiptVoucher.COMPANY_ID.toString();
    reverseReceiptObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    // reverseReceiptObj.VOUCHER_NO = this.receiptVoucher.VOUCHER_NO?this.receiptVoucher.VOUCHER_NO:117;
    reverseReceiptObj.VOUCHER_NO = this.voucherDetails.documentNo;
    reverseReceiptObj.CREATED_BY = CommonService.getUserData().USER_ID.toString();
    // reverseReceiptObj.VOUCHER_DT = CommonService.setDateTime(this.receiptVoucher.VOUCHER_DT);
    reverseReceiptObj.VOUCHER_DT = new Date();
    // reverseReceiptObj.VCHR_TYPE_ID = Number(this.receiptVoucher.VCHR_TYPE_ID);
    reverseReceiptObj.VCHR_TYPE_ID = 217;
    reverseReceiptObj.REV_VCHR_TYPE_ID = 201;
    if (this.data && this.data.type == 'reverse') {
      reverseReceiptObj.REV_VCHR_TYPE_ID = this.receiptVoucher.VCHR_TYPE_ID;
    }
    if (this.data && this.data.type == 'JobCardReverse') {
      reverseReceiptObj.VCHR_TYPE_ID = 119;
    }
    
    // if (this.data && this.data.type == 'JobCardReverse') {
    //   reverseReceiptObj.REV_VCHR_TYPE_ID = this.receiptVoucher.VCHR_TYPE_ID;
    //   }
    reverseReceiptObj.VCHR_VALUE = Number(this.receiptVoucher.VCHR_VALUE);
    reverseReceiptObj.VCHR_STATUS = this.receiptVoucher.VCHR_STATUS ? this.receiptVoucher.VCHR_STATUS : 2;
    reverseReceiptObj.FIN_YEAR = this.receiptVoucher.FIN_YEAR.toString();
    reverseReceiptObj.REVERSE_VCHR_STATUS = 3;
    reverseReceiptObj.PAYMENT_MODE_ID = this.receiptVoucher.PAYMENT_MODE_ID;
    // reverseReceiptObj.DOCUMENT_ID = this.voucherDetails.documentId;
    if (this.data && this.data.type == 'reverse'  || this.data.type == 'JobCardReverse') {
    reverseReceiptObj.DOCUMENT_ID = this.receiptVoucher.DOCUMENT_ID; 
    }
   
     reverseReceiptObj.DOC_TYPE = this.receiptVoucher.DOC_TYPE ? this.receiptVoucher.DOC_TYPE : 2;
     if (this.data && this.data.type == 'JobCardReverse') {
      reverseReceiptObj.DOC_TYPE =1;
     }
    reverseReceiptObj.REV_DOC_TYPE = "1";
    // reverseReceiptObj.DOC_DATE = CommonService.setDateTime(this.receiptVoucher.DOC_DATE);
    reverseReceiptObj.DOC_DATE = new Date();
    reverseReceiptObj.BASE_DOC_TYPE = this.receiptVoucher.BASE_DOC_TYPE ? Number(this.receiptVoucher.BASE_DOC_TYPE) : '6';
    reverseReceiptObj.PARTY_CODE = this.receiptVoucher.PARTY_CODE.toString();
    reverseReceiptObj.PARTY_NAME = this.receiptVoucher.PARTY_NAME;
    reverseReceiptObj.INSTR_NO = this.receiptVoucher.INSTR_NO ? this.receiptVoucher.INSTR_NO : null;
    reverseReceiptObj.INSTR_DATE = this.receiptVoucher.INSTR_DATE ? CommonService.setDateTime(this.receiptVoucher.INSTR_DATE) : null;
    reverseReceiptObj.INSTR_AMT = this.receiptVoucher.INSTR_AMT ? this.receiptVoucher.INSTR_AMT : null;
    reverseReceiptObj.INSTRUMENT_ON = this.receiptVoucher.INSTRUMENT_ON ? this.receiptVoucher.INSTRUMENT_ON : null;
    reverseReceiptObj.ACCOUNT_NO = this.receiptVoucher.ACCOUNT_NO ? this.receiptVoucher.ACCOUNT_NO : null;
    reverseReceiptObj.PARTY_CAT = Number(this.receiptVoucher.PARTY_CAT);
    reverseReceiptObj.CRED_CARD_TY_ID = this.receiptVoucher.CRED_CARD_TY_ID ? this.receiptVoucher.CRED_CARD_TY_ID : null;
    reverseReceiptObj.CRED_CARD_EXP_DT = this.receiptVoucher.CRED_CARD_EXP_DT ? this.receiptVoucher.CRED_CARD_EXP_DT : null;
    reverseReceiptObj.APPROVAL_NO = this.receiptVoucher.APPROVAL_NO ? this.receiptVoucher.APPROVAL_NO : null;
    reverseReceiptObj.BANK_BRANCH = this.receiptVoucher.BANK_BRANCH ? this.receiptVoucher.BANK_BRANCH : null;
    reverseReceiptObj.BANK_ID = this.receiptVoucher.BANK_ID ? this.receiptVoucher.BANK_ID : null;
    reverseReceiptObj.BASE_VOUCHER_ID = this.receiptVoucher.BASE_VOUCHER_ID ? this.receiptVoucher.BASE_VOUCHER_ID : null;
    reverseReceiptObj.NARRATION = this.receiptVoucher.NARRATION;
    reverseReceiptObj.CRED_LMT_TYPE = this.receiptVoucher.CRED_LMT_TYPE ? Number(this.receiptVoucher.CRED_LMT_TYPE) : 1;
    reverseReceiptObj.TDS_APPLIED = this.receiptVoucher.TDS_APPLIED ? this.receiptVoucher.TDS_APPLIED : 'false',
      reverseReceiptObj.ACTIVE = this.receiptVoucher.ACTIVE ? this.receiptVoucher.ACTIVE : 'true';
    reverseReceiptObj.APPLIED_TDS_AMOUNT = 0;
    reverseReceiptObj.VOUCHER_ACC_DETAILS = this.receiptVoucher.VoucherAccDetail.length > 0 ? this.receiptVoucher.VoucherAccDetail : [];
    reverseReceiptObj.LEDGER_ENTRY_DET = this.receiptVoucher.LedgerEntryDet.length > 0 ? this.receiptVoucher.LedgerEntryDet : [];
    let voucherDtl: any = [];
    if (this.receiptVoucher.VoucherAccDetail.length > 0) {
      this.receiptVoucher.VoucherAccDetail.filter(val => {
        voucherDtl.push({
          'GL_CODE': val.GL_CODE,
          'SL_CODE': val.SL_CODE,
          'ACC_VALUE': val.VCHR_VALUE,
          'CREDIT_LIMIT_TYPE': '1',
          'IS_DEBIT': val.IS_DEBIT
        });
      });
    }
    reverseReceiptObj.VOUCHER_ACC_DETAILS = voucherDtl.length > 0 ? voucherDtl : [];
    this.masterService.post(reverseReceiptObj, '/Voucher/ReverseReceipt').subscribe(
      (resp: any) => {
        this.toastr.success(resp.statusMessage);
        this.dialogRef.close(false);
      });
  }
  closeDialog(): void {
    this.dialogRef.close(false);
  }
}



// @Component({
//   selector: 'app-payment-voucher-dialog',
//   template: `<app-payment-voucher [isView]="isView" ></app-payment-voucher>`,

// })
// export class CustomDialog {
//   public isActivePopup: any = false;
//   @Input() paymentDetails: any;
//   isView = true;
//   constructor(
//     public dialogRef: MatDialogRef<CustomDialog>,
//     @Inject(MAT_DIALOG_DATA)
//     public data: any,
//     public commonService: CommonService,
//     private toastr: ToastrService
//   ) {
//     this.isActivePopup = true;
//     this.commonService.sendMessage('isActive');
//     this.commonService.getMessage().subscribe(message => {
//       //   console.log("receive message::",message);
//       //  if (message && message.name === 'isActive') {
//       //     this.isActivePopup = message.name;
//       //  }
//       if (message && message.name === 'close') {
//         this.onClose();
//       }
//     });
//     // }
//   }

//   onClose(): void {
//     this.dialogRef.close(false);
//   }
// }

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../api-services/common.service';
import { moment } from 'ngx-bootstrap/chronos/test/chain';

@Component({
  selector: 'app-filter-input',
  template: `
  <div style='display: flex;flex-direction: column;'>
            <div (click)='sort()'>
              <span class='datatable-header-cell-label'>{{name}}</span>
              <span class='sort-btn datatable-icon-up' *ngIf="sortType === 'desc'"></span>
              <span class='sort-btn datatable-icon-down' *ngIf="sortType === 'asc'"></span>
            </div>
            <input type='text' placeholder='{{lang?.GENERAL?.search}}' [(ngModel)]='modelVal' [OnlyNumber]="numberOnly == true?true:null" (keyup)='filterByNames($event.target.value)'>
          </div>
  `,
  styles: [`@media (min-width: 1200px) {
    input{margin: 8px 0px;}
  }`]
})

export class FilterInputComponent implements OnInit {
  public lang: any;
  @Input() name: string;
  @Input() modelVal: any;
  @Input() sortType: string;
  @Input() numberOnly: boolean;
  @Output() sortEvent = new EventEmitter<any>();
  @Output() changeEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  sort() {
    this.sortEvent.emit();
  }
  filterByNames(value) {
    this.changeEvent.emit(value);
  }
}

@Component({
  selector: 'app-filter-date',
  template: `
  <div style='display: flex;flex-direction: column;'>
            <div (click)='sort()'>
              <span class='datatable-header-cell-label'>{{name}}</span>
              <span class='sort-btn datatable-icon-up' *ngIf="sortType === 'desc'"></span>
              <span class='sort-btn datatable-icon-down' *ngIf="sortType === 'asc'"></span>
            </div>
            <div class='date'>
            <p-calendar showButtonBar='true' (onClearClick) = 'selectDate()' 
            (onSelect)='selectDate()' appendTo='body' class='searchDatepicker jc-search'
            [(ngModel)]='modelVal' readonlyInput='true'
            name='Date' [showIcon]='true' dateFormat='dd/mm/yy'
            placeholder='{{lang?.GENERAL?.selectDate}}'
                        [monthNavigator]='true' [yearNavigator]='true' yearRange='2000:2030'>
            </p-calendar>
            </div>
          </div>
  `,
  styles: [`@media (min-width: 1200px) {p-calendar{    
    margin: 8px 0px;}.date{ position:relative;display:flex;  } 
  }`]
})
export class FilterDateComponent implements OnInit {
  public lang: any;
  @Input() name: string;
  @Input() sortType: string;
  @Input() modelVal: string;
  @Output() sortEvent = new EventEmitter<any>();
  @Output() changeEvent = new EventEmitter<any>();
  public isShowClose = false;
  constructor() { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  sort() {
    this.sortEvent.emit();
  }
  selectDate() {
    if (this.modelVal) {
      this.changeEvent.emit(moment(this.modelVal).format('YYYY-MM-DD'));
    } else {
      this.changeEvent.emit('');
    }
  }
}

// import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-select',
  template: `
  <div style='display: flex;flex-direction: column;'>
            <div (click)='sort()'>
              <span class='datatable-header-cell-label'>{{name}}</span>
              <span class='sort-btn datatable-icon-up' *ngIf="sortType === 'desc'"></span>
              <span class='sort-btn datatable-icon-down' *ngIf="sortType === 'asc'"></span>
            </div>
            <select class='form-control' [(ngModel)]='modelVal' (change)='changeSelect(modelVal)'>
            <option value=''>{{lang?.GENERAL?.select}}</option>
            <option *ngFor='let item of items' value='{{item.value}}'>{{item.name}}</option>
          </select>
          </div>
  `,
  styles: ['@media (min-width: 1200px) {select{    margin: 8px 0px;}}']
})
export class FilterSelectComponent implements OnInit {

  @Input() name: string;
  @Input() items: any = [];
  @Input() sortType: string;
  @Input() modelVal: any;
  @Output() sortEvent = new EventEmitter<any>();
  @Output() changeEvent = new EventEmitter<any>();
  lang: any;
  constructor() { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  sort() {
    this.sortEvent.emit();
  }
  changeSelect(value) {
    if (value === 'Select') {
      this.changeEvent.emit('');
    } else {
      if(this.modelVal && isNaN(parseInt(this.modelVal))){
      this.changeEvent.emit(this.modelVal ? this.modelVal : '');
      }else{
        this.changeEvent.emit(this.modelVal ? parseInt(this.modelVal) : '');
      }
    }
  }
}

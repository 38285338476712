/**
 * Created BY HMSPL 
 * Last Last Modified By Balaji on 10/10/2018
 * Last Modified Description : Added Comment
 */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TooltipModule } from 'ngx-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { FormsModule } from '@angular/forms';

/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 * Custom Component
 */
import { AppComponent } from './app.component';
import { MasterComponent } from './layout/master/master.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AppRoutes } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule, MatTableModule } from '@angular/material';
import { SessionService } from './api-services/session.service';
import { UserIdleModule } from 'angular-user-idle';
import { ChartsModule } from 'ng2-charts';
import { importType } from '@angular/compiler/src/output/output_ast';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: SessionService, multi: true },
];
@NgModule({
  declarations: [
    MasterComponent,
    AppComponent,
    AuthComponent
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    MatSidenavModule,
    MatTableModule,
    ChartsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TooltipModule.forRoot(),
    UserIdleModule.forRoot({idle: 1600, timeout: 6, ping: 6})
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {

 }

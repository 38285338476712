/**
* Created BY HMSPL 
* Login Service Class
* Last Last Modified By Balaji on 10/10/2018
* Last Modified Description : Created login related services
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';
import { MasterService } from 'src/app/api-services/master.service';
import { MasterSales } from 'src/app/api-services/master.sales';

@Injectable({
    providedIn: 'root'
})
export class MasterDataService {
    constructor(private httpClient: HttpClient,
        private masterService: MasterService,
        private masterSales: MasterSales,) {

    }
    /**
    * Get App Data
    * @param {Object} request
    * return Object
    */
    getAppData(request) {
        
        const resData = RequestService.getApiURLReq('/Service/StaticServiceDropDowns');
        return this.httpClient.get(resData.url, { params: request, headers: resData.headers });
    }

    /**
    * Get static data for Employee Master Screen
    * @param {Object} request
    * return Object
    */
    loadStaticEmployeeMaster(request) {
        const resData = RequestService.getApiURLReq('/Master/StaticEmployeeMasterDropDown' + request);
        return this.httpClient.get(resData.url, { params: request, headers: resData.headers });
    }

    /**
    * Search Employee According to search criterion
    * @param {Object} request
    * return Object
    */
    searchEmployee(request) {
        const resData = RequestService.getReq('SearchEmployeeMaster?' + request);
        return this.httpClient.get(resData.url);
    }

    /**
    * Load Employee Master Details According to Employee Id
    * @param {Object} request
    * return Object
    */
    loadEmployeeMasterDetails(request) {
        const resData = RequestService.getReq('loadEmployeeMasterDetails?' + request);
        return this.httpClient.get(resData.url);
    }

    /**
    * Save Employee Master
    * @param {Object} request
    * return Object
    */

    saveEmployeeMasterDetails(request) {
        const resData = RequestService.postReq('saveEmployeeMasterDetails');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }

    /**
    * Search User Mapping According to search criterion
    * @param {Object} request
    * return Object
    */
    searchUserMap(request) {
        const resData = RequestService.getReq('searchUserMapping?' + request);
        return this.httpClient.get(resData.url);
    }
    /**
    * Load User Mapping Details
    * @param {Object} request
    * return Object
    */
    loadUserMapping(request) {
        const resData = RequestService.getReq('loadUserMapping?' + request);
        return this.httpClient.get(resData.url);
    }
    /**
    * Load All Employee
    * @param {Object} request
    * return Object
    */
    LoadReferralEmployee(request) {
        const resData = RequestService.getReq('LoadReferralEmployee?' + request);
        return this.httpClient.get(resData.url);
    }
    /**
    * Save User Mapping
    * @param {Object} request
    * return Object
    */
    saveUserMapping(request) {
        const resData = RequestService.postReq('saveUserMapping');
        debugger;
        return this.httpClient.post(resData.url, request);
    }
    /**
  * Search Employee Attendance According to date
  * @param {Object} request
  * return Object
  */
    searchEmpAttendance(request) {
        const resData = RequestService.getReq('searchEmpAttendance?' + request);
        return this.httpClient.get(resData.url);
    }
    /**
    * Save Employee Attendance
    * @param {Object} request
    * return Object
    */
    saveEmpAttendance(request) {
        const resData = RequestService.postReq('saveEmpAttendance');
        return this.httpClient.post(resData.url, request);
    }
    /**
    * Search Employee Attendance According to date
    * @param {Object} request
    * return Object
    */
    checkEmpAttendance(request) {
        const resData = RequestService.getReq('checkEmpAttendance?' + request);
        return this.httpClient.get(resData.url);
    }


    // Vehicle Master
    saveVehicleMasterDetails(request) {
        const resData = RequestService.postApiURLReq('/Vehicle/SaveVehicle');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });

    }




    getVehicleDetailsBySearch(request) {

        const resData = RequestService.getApiURLReq('/Vehicle/VehicleSearch?' + request);
        return this.httpClient.get(resData.url);

    }

    getVehicleDetailsByFrameNo(request) {
        const resData = RequestService.getApiURLReq('/Vehicle/GetFrameDetails?' + request);
        return this.httpClient.get(resData.url);

    }

    getVehicleDetailsByVehicleID(request) {
        const resData = RequestService.getApiURLReq('/Vehicle/GetVehicleDetails?' + request);
        return this.httpClient.get(resData.url);

    }

    //

    // Customer Master
    //* Save Employee Master
    // * @param {Object} request
    //  * return Object
    //  */
    loadCustomerMasterDetails(request,param) {
        const resData = RequestService.uvdReq('/DMSMaster/Customer/GetCustomerDetailsForViewModify?' + request);
        return this.httpClient.get(resData.url, { params: param, headers: resData.headers });
        // const resData = RequestService.getApiURLReq('/DMSMaster/Customer/GetCustomerDetailsForViewModify?' + request);
        // return this.httpClient.get(resData.url);

    }


    getCustomerDetailsBySearch(request,param) {
        const resData = RequestService.uvdReq('/DMSMaster/Customer/CustomerSearch?' + request);
        return this.httpClient.get(resData.url, { params: param, headers: resData.headers });
        // const resData = RequestService.getApiURLReq('/DMSMaster/Customer/CustomerSearch?' + request);
        // return this.httpClient.get(resData.url);
    }
    getAdvancedSearchCustomerMasterList(request) {
        const resData = RequestService.uvdReq('/DMSMaster/Customer/AdvanceSearchCustomerMasterList');
        return this.httpClient.post(resData.url, request,{headers:resData.headers});
        // const resData = RequestService.postApiURLReq('/DMSMaster/Customer/AdvanceSearchCustomerMasterList');
        // return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }

    getSalesPersonSearchDetail(request){
        return this.httpClient.post("", request, { headers: {} });;
    }


    saveCustomerMasterDetails(request) {
        const resData = RequestService.uvdReq('/DMSMaster/Customer/SaveCustomer');
        return this.httpClient.post(resData.url, request,{headers:resData.headers});
        // const resData = RequestService.postApiURLReq('/DMSMaster/Customer/SaveCustomer');
        // return this.httpClient.post(resData.url, request, { headers: resData.headers });

    }

    getLedgerCodeForCustomerMaster(request,param) {
        const resData = RequestService.uvdReq('/DMSMaster/Customer/GetLedgerDetails?' + request);
        return this.httpClient.get(resData.url, { params: param, headers: resData.headers });
        // const resData = RequestService.getApiURLReq('/DMSMaster/Customer/GetLedgerDetails?' + request);
        // return this.httpClient.get(resData.url);

    }



    // //

    // // Customer Master
    // //* Save Employee Master
    // // * @param {Object} request
    // //  * return Object
    // //  */
    // loadCustomerMasterDetails(request) {
    //     const resData = RequestService.getApiURLReq('/Customer/GetCustomerDetailsForViewModify?' + request);
    //     return this.httpClient.get(resData.url);

    // }


    // getCustomerDetailsBySearch(request) {
    //     const resData = RequestService.getApiURLReq('/Customer/CustomerSearch?' + request);
    //     return this.httpClient.get(resData.url);
    // }
    // getAdvancedSearchCustomerMasterList(request) {
    //     const resData = RequestService.postApiURLReq('/Customer/AdvanceSearchCustomerMasterList');
    //     return this.httpClient.post(resData.url, request, { headers: resData.headers });
    // }

    // getSalesPersonSearchDetail(request){
    //     return this.httpClient.post("", request, { headers: {} });;
    // }


    // saveCustomerMasterDetails(request) {
    //     const resData = RequestService.postApiURLReq('/Customer/SaveCustomer');
    //     return this.httpClient.post(resData.url, request, { headers: resData.headers });

    // }

    // getLedgerCodeForCustomerMaster(request) {
    //     const resData = RequestService.getApiURLReq('/Customer/GetLedgerDetails?' + request);
    //     return this.httpClient.get(resData.url);

    // }


    


    //

    // Other Vendor GRN

    getLocationStorage(request) {
        const resData = RequestService.getApiURLReq('/OtherVendorGRN/LocationList?' + request);
        return this.httpClient.get(resData.url);

    }


    getModelPartList(request) {
        const resData = RequestService.getApiURLReq('/OtherVendorGRN/ModelPartList?' + request);
        return this.httpClient.get(resData.url);

    }

    checkOnFrameChangeDetail(request) {

        const resData = RequestService.postApiURLReq('/OtherVendorGRN/OnFrameChangeDetailCheck');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });

    }

    checkOnBasicPriceChangeDetailCheck(request) {
        const resData = RequestService.postApiURLReq('/OtherVendorGRN/OnBasicPriceChangeDetailCheck');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });

    }
    checkOnQuantityChangeDetailCheck(request) {
        const resData = RequestService.postApiURLReq('/OtherVendorGRN/OnQuantityChangeDetailCheck');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });

    }
    checkOnInputTaxUnitValueChangeDetailCheck(request) {
        const resData = RequestService.postApiURLReq('/OtherVendorGRN/OnInputTaxUnitValueChangeDetailCheck');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    checkotherTaxChangeDetailCheck(request) {
        const resData = RequestService.postApiURLReq('/OtherVendorGRN/otherTaxChangeDetailCheck');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    tcsAmountAfterCheckboxClick(request) {
        const resData = RequestService.postApiURLReq('/OtherVendorGRN/TCSApplicableCheckChanged');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    frameEngineNoDetail(request) {
        const resData = RequestService.postApiURLReq('/OtherVendorGRN/FrameEngineNoDetail');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }

    saveOtherVendorGRNDetails(request) {
        const resData = RequestService.postApiURLReq('/OtherVendorGRN/SaveOtherVendorGRNDetail');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });

    }

    searchOtherVendorGRNDetails(request) {
        const resData = RequestService.postApiURLReq("/OtherVendorGRN/getOtherVendorGRNDetail");
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    viewOtherVendorGRN(request) {
        const resData = RequestService.postApiURLReq("/OtherVendorGRN/viewOtherVendorGRNDetail");
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }


    //


    GetPurchaseInvoiceHalp(request) {
        const resData = RequestService.WebApiReq('/Parts/SearchPurchaseInvoiceHalp?' + request);
        return this.httpClient.get(resData.url);
    }

    GetSalesInvoiceLisrt(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/GetSalesTvsGrn?' + request);
        return this.httpClient.get(resData.url);
    }
    GetPartsDetails(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/GetDamagePartsDetails?' + request);
        return this.httpClient.get(resData.url);
    }
    GetGrnPartsDetailsForview(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/GetSalesGRNDetailsForView?' + request);
        return this.httpClient.get(resData.url);
    }
    GetGrnDetailsForview(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/GetSalesGRNHeaderDetailsForView?' + request);
        return this.httpClient.get(resData.url);
    }
    SavepdiForsaleGrn(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/PopulateSalesGRNDetails?' + request);
        return this.httpClient.get(resData.url);
    }
    GetGeneralStorageLocationsForVeh(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/GetGeneralStorageLocationsForVeh?' + request);
        return this.httpClient.get(resData.url);
    }
    Checkpdi(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/chkPDIDone?' + request);
        return this.httpClient.get(resData.url);
    }
    GetInsuranceCompanyDetails(request) {
        const resData = RequestService.WebApiReq('/ServiceJobCard/GetInsuranceCompanyDetails?' + request);
        return this.httpClient.get(resData.url);
    }
    GetJobCardDetailsToVerifyAmcWarrantyClaim(request) {
        const resData = RequestService.WebApiReq('/ServiceJobCard/GetJobCardDetailsToVerifyAmcWarrantyClaim?' + request);
        return this.httpClient.get(resData.url);
    }

    GetTvsGrntype(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/GetGRNType?' + request);
        return this.httpClient.get(resData.url);
    }
    GetDamageclaimtype(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/loadDamageType?' + request);
        return this.httpClient.get(resData.url);
    }
    GetSalesInvoiceDetailsList(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/GetSalesTvsInvoiceDetails?' + request);
        return this.httpClient.get(resData.url);
    }
    checkinvoice(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/Checkinvoiceexist?' + request);
        return this.httpClient.get(resData.url);
    }
    GetInvoicedeatilsFromInvoiceNo(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/GetInvoicedeatilsFromInvoiceNo?' + request);
        return this.httpClient.get(resData.url);
    }
    GetSalesTvsGrnList(request) {
        const resData = RequestService.WebApiReq('/SalesGrn/SearchSalesGRNDetails?' + request);
        return this.httpClient.get(resData.url);
    }
    Changepassword(request) {
        const resData = RequestService.getReq('ChangePassword?' + request);
        return this.httpClient.get(resData.url);
    }
    GetPurchaseInvoiceHalpDetails(request) {
        const resData = RequestService.WebApiReq('/Parts/SearchPurchaseInvoiceHalpDetails?' + request);
        return this.httpClient.get(resData.url);
    }
    GetLocationForHalp(request) {
        const resData = RequestService.WebApiReq('/Parts/GetLocationForHalp?' + request);
        return this.httpClient.get(resData.url);
    }
    GetRackBinForHalp(request) {
        const resData = RequestService.WebApiReq('/Parts/GetRcakBinForHalp?' + request);
        return this.httpClient.get(resData.url);
    }
    createHalpAccessoryGRN(request) {
        const resData = RequestService.WebApiReq('/Parts/CreateHalpAccessoryGRN' + request);
        return this.httpClient.get(resData.url);
    }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/api-services/common.service';

@Component({
    selector: 'app-view-sales-scheme',
    template: `
  <div class="estimateCost">
  <div class="estimateCostHeader">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="col-lg-6 col-sm-6 mt-6 pl-8">
          <span class="estimateHeader">
            {{data.type == 'COMPLAINT' ? lang?.SERVICES?.complaints : data.type == 'SCHEMES' ?
            lang?.SERVICES?.viewSchemes : ''}}
          </span>
        </div>
        <div class="col-lg-6 col-sm-6 flex align-right">
          <button type="button" (click)="resetScheme()" *ngIf="data.type == 'SCHEMES'" class="cancelBtn mr-10">{{lang?.GENERAL?.clear}}</button>
          <button type="button" (click)="dialogRef.close()" *ngIf="data.type != 'SCHEMES'" class="cancelBtn mr-10">{{lang?.GENERAL?.ok}}</button>
          <button [disabled]="!viewSchemes.dirty" class="searchSelectBtn" *ngIf="data.type == 'SCHEMES'" (click)="selectItem()" type="button">
            {{lang?.GENERAL?.select}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <form name="form" #viewSchemes="ngForm">
  <div class="row" *ngIf="data.type == 'SCHEMES'">
    <div class="col-lg-12 col-sm-12 flex align-center">
      <div class="schemes inlineTable row mlr-0 mb-12">
        <div class="inlineHeader">
          <div class="head-column wper-30">
            {{lang?.GENERAL?.select}}
          </div>
          <div class="head-column wper-70">
            {{lang?.SERVICES?.partDescription}}
          </div>
        </div>
        <div class="inlineBody" *ngFor="let scheme of data.appSchemeList;let i=index;let i=index">
          <div class="inlineColumn wper-30">
            <!-- <input type="checkbox" id="scheme{{i}}" class="magic-checkbox-circle" [(ngModel)]="scheme.IS_SELECT"
              name="scheme{{i}}" />
            <label class="cLabel" for="scheme{{i}}"></label> -->
            <label class="magic-radio deactive">
            <input type="radio" name="scheme{{i}}" [value]="scheme.VEHICLE_SCH_ID" [(ngModel)]="VEHICLE_SCH_ID" >  
             <span></span>
            </label>
          </div>
          <div class="inlineColumn wper-70">
            {{scheme?.DESCRIPTION}}
          </div>
        </div>
      </div>
    </div>
  </div>
  </form>
</div>
    `,
    styles: [`
    .estimateCost {
        background-color: white;
    }
    .estimateCostHeader {
        border-radius: 8px 8px 0 0;
        background-color: #ecf2f6;
        padding: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .estimateHeader {
        opacity: 0.9;
        color: #202020;
        font-family: var(--fontName);
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .inlineTable.complaint .head-column, .inlineTable.complaint .inlineColumn{
      width: 25% !important;
    }
    .inlineTable.complaint .inlineHeader{
      width:100% !important;
    }
    .schemes{
      width:90% !important;
      margin: 10px;
    }
    .schemes .inlineHeader {
      width:100% !important;
    }
    .schemes.inlineTable .inlineBody .inlineColumn:first-child .cLabel:before {
      top: -9px;
  }
    `]
})
export class ViewSalesScheme implements OnInit {
    public lang: any;
    public complaintHeader: any;
    public schemeHeader: any;
    public rowIndex: any;
    public selectedSchemeList: Array<any> = [];
    public applSchemeList: Array<any> = [];
    public VEHICLE_SCH_ID:any;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
        public dialogRef: MatDialogRef<ViewSalesScheme>) {
        this.applSchemeList = this.data.appSchemeList;
        this.selectedSchemeList = this.data.selectedSchemeList;
        this.rowIndex = this.data.rowIndex;
        this.VEHICLE_SCH_ID=this.data.VEHICLE_SCH_ID;   
       // alert(this.VEHICLE_SCH_ID);
    }

    ngOnInit() {
        if (CommonService.getLang()) {
            this.lang = CommonService.getLang();

            this.schemeHeader = [{
                name: this.lang.SERVICES.compGrp,
                width: 'wper-50'
            },
            {
                name: this.lang.SERVICES.complaints,
                width: 'wmpx-150'
            },]
        }
    }
    selectItem() {
        let total = 0;
        let noOfVehSold = 0;
        let selectedItem: Array<any> = [];
        let vehicleSchemeId=0;
        //alert(this.VEHICLE_SCH_ID);
        this.data.appSchemeList.filter(val => {
            //   alert(val.TOT_DISC_AMT);    
            if (val.TOT_DISC_AMT && val.VEHICLE_SCH_ID==this.VEHICLE_SCH_ID ) {
                total += parseFloat(val.TOT_DISC_AMT);
            }
            noOfVehSold = val.NO_VEH_SOLD;
            vehicleSchemeId=val.VEHICLE_SCH_ID;
            selectedItem = val;
            //   console.log(this.rowIndex);
            //   console.log(selectedItem);
        });
        this.dialogRef.close({ schemeAmt: total, noOfVehSold: noOfVehSold, vehSchemeId: vehicleSchemeId, selectedRowIndex: this.rowIndex, selectedScheme: selectedItem });
    }
    resetScheme() {
        this.dialogRef.close({schemeAmt: 0, noOfVehSold: 0, vehSchemeId: 0,selectedRowIndex:-1, selectedScheme: null });
    }
}

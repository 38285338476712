import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService } from 'src/app/api-services/common.service';
@Component({
  selector: 'app-report-popup',
  templateUrl: './report-popup.component.html',
  styleUrls: ['./report-popup.component.scss']
})
export class ReportPopupComponent implements OnInit {
  public lang: any;
  public url: SafeResourceUrl;
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    if(this.data){
      console.log("URL",this.data);
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data);
    }
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

}

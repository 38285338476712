import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PartCostRootObject } from '../../api-services/dto/part-cost';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { PartSearchComponent } from '../part-search/part-search.component';
import { LoginService } from 'src/app/api-services/login.service';
@Component({
  selector: 'app-grn-part-cost',
  templateUrl: './grn-part-cost.component.html',
  styleUrls: ['./grn-part-cost.component.scss']
})
export class GrnPartCostComponent implements OnInit {
  public lang:any;
  public isShowPageLoader:boolean = false;
  public vendorName:any;
  public partDetails = new PartCostRootObject();
  public masterDetails: any;
  public countryCode:any;
  @ViewChild("helpTemplate") helpTemplate: TemplateRef<any>;
  public taxDialogRef: MatDialogRef<any, any>;
  public taxInfo:any ={};
  constructor( public dialogRef: MatDialogRef<GrnPartCostComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    private masterService: MasterService,
    private loginService:LoginService,
    public toastr: ToastrService) { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      if (localStorage.getItem('userData')) {
        let userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.d && userData.d.data && userData.d.data.User) {
          this.countryCode = userData.d.data.COUNTRY_CODE;
        }
      }
      if(this.data) {
        console.log(this.data)
      this.masterDetails = this.data.masterData;
      this.partDetails = this.data.partInfo;
      // this.partDetails.partId = this.data.partInfo.partNo;
      // this.partDetails.partName = this.data.partInfo.partName;
      this.partDetails.manufactureId = this.data.partInfo.manufacturerId;
      this.vendorName = this.data.vendorInfo.vendorName;
      this.partDetails.vendorId =  this.data.vendorInfo.vendorId;
      this.partDetails.validFrom =  new Date();
      this.partDetails.active =  this.data.partInfo.active;
      }
    }
    if(this.data && (this.data.mode=="MODIFY" || this.data.mode=="VIEW") &&  this.data.type=="partMasterCost" ){
      this.partDetails = this.data.partInfo;
      this.partDetails.manufactureId = this.data.manufactureId;
      this.partDetails.vendorId = this.data.vendorInfo.vendorId;
      this.vendorName = this.data.vendorInfo.vendorId;
    }
  }
close() {
  this.dialogRef.close(false);
}
validatePartCost(){
   let costError:any = 0;
   if (!this.partDetails.partNo) {
     this.toastr.error('Part No is empty');
     costError++; 
   }
   if (!this.partDetails.partName) {
    this.toastr.error('Part Name is empty');
    costError++;
  }
  if (!this.partDetails.manufactureId){
    this.toastr.error('Manufacture Name is empty ');
    costError++;
  }
  if (!this.vendorName){
    this.toastr.error('Vendor name is empty');
    costError++;
  }
  if (!this.partDetails.invoicePrice) {
    this.toastr.error('Part cost is empty');
    costError++;
  }
  if(this.data.type == 'partMasterCost'){
  if (!this.partDetails.dealerPaidPercentage) {
    this.toastr.error('Dealer Paid Tax  is empty');
    costError++;
  }
  // if (!this.partDetails.tvsPaidPercentage) {
  //   this.toastr.error('TVS Paid Percentage Tax  is empty');
  //   costError++;
  // }
  if (!this.partDetails.dealerPaidTax) {
    this.toastr.error('Dealer Paid Tax  is empty');
    costError++;
  }
  // if(!this.partDetails.tvsPaidTax) {
  //   this.toastr.error('TVS Paid Tax is empty');
  //   costError++;
  // }
  }
  if(!this.partDetails.partCost) {
    this.toastr.error('Part Cost is empty');
    costError++;
  }
  if (costError > 0) {
    return false;
  } else {
    return true;
  }
}
calculateTVSTax(){
  // if(this.partDetails.invoicePrice && this.partDetails.tvsPaidPercentage){
  this.partDetails.tvsPaidTax =  Number(this.partDetails.invoicePrice * (this.partDetails.tvsPaidPercentage / 100));
  // this.partDetails.tvsPaidTax = this.partDetails.tvsPaidTax ? Number(this.partDetails.tvsPaidTax) : 0;
  this.calculateDealerTax();
  this.partDetails.tvsPaidTax = this.partDetails.tvsPaidTax ? Number(this.partDetails.tvsPaidTax.toFixed(2)) : 0;
  // }
}
calculateDealerTax() {
//  if (this.partDetails.invoicePrice && this.partDetails.dealerPaidPercentage ) {
  this.partDetails.dealerPaidTax = this.partDetails.invoicePrice * (this.partDetails.dealerPaidPercentage / 100);
  this.partDetails.dealerPaidTax = this.partDetails.dealerPaidTax ? Number(this.partDetails.dealerPaidTax.toFixed(2)):0;
  
  // if (this.partDetails.dealerPaidTax && this.partDetails.tvsPaidTax && this.partDetails.invoicePrice ) {
   this.partDetails.partCost = Number(this.partDetails.invoicePrice) +
                               Number(this.partDetails.dealerPaidTax) +
                               Number(this.partDetails.tvsPaidTax);
    this.partDetails.partCost = this.partDetails.partCost?Number(this.partDetails.partCost.toFixed(2)):0;
  // }
//  }
}
savePartCost(){
   console.log(this.partDetails);
   if(this.validatePartCost() == false) {
     return;
   }
   let reqPartCost:any = {};
    reqPartCost.dealerId  =   CommonService.getUserData().DEALER_ID;
    reqPartCost.partId = this.partDetails.partId;
    reqPartCost.manufactureId = this.partDetails.manufactureId;
    reqPartCost.vendorId = this.partDetails.vendorId;
    reqPartCost.invoicePrice = Number(this.partDetails.invoicePrice);
    reqPartCost.validFrom  = this.partDetails.validFrom;
    reqPartCost.dealerPaidTax = this.partDetails.dealerPaidTax;
    reqPartCost.tvsPaidTax = this.partDetails.tvsPaidTax;
    reqPartCost.dealerPaidPercentage = Number(this.partDetails.dealerPaidPercentage);
    reqPartCost.tvsPaidPercentage = Number(this.partDetails.tvsPaidPercentage);
    reqPartCost.partCost = this.partDetails.partCost;
    reqPartCost.userId  = this.partDetails.userId;
    reqPartCost.active = this.partDetails.active;
    reqPartCost.countryCode = CommonService.getDealerData().COUNTRY_CODE;
   this.isShowPageLoader = true;
   this.masterService.post(reqPartCost, '/SparePartGRN/createSparePartCost').subscribe(
    (resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.dialogRef.close(this.partDetails);
        this.toastr.success(resp.statusMessage);
      }
      if( resp.statusCode == 306 ) {
        this.toastr.success(resp.statusMessage);
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.statusMessage);
    }
  );
}


savePartMasterCost(){
  if(this.data && this.data.mode=='MODIFY' && this.data.partInfo.isUpdate == false){
    this.toastr.info('Cost Details cannot be modify for TVS Spare Parts');
    return
  }
  if(this.validatePartCost() == false) {
    return;
  }
  let reqPart:any = {};   
  reqPart.rowState = this.data.mode=='MODIFY'?'U':'I';
  reqPart.spareCostId= this.data.mode=='MODIFY'?this.data.partInfo.spareCostId:null;
  reqPart.dealerId =  CommonService.getUserData().DEALER_ID; 
  reqPart.branchId = CommonService.getUserData().BRANCH_ID;
  reqPart.partId = this.partDetails.partId;
  reqPart.partNo = this.partDetails.partNo;
  reqPart.manufactureId =this.partDetails.manufactureId;
  reqPart.vendorId =this.partDetails.vendorId;
  reqPart.invoicePrice = this.partDetails.invoicePrice;
  reqPart.validFrom = new Date(this.partDetails.validFrom);
  reqPart.dealerPaidTax =this.partDetails.dealerPaidTax;
  reqPart.tvsPaidTax = this.partDetails.tvsPaidTax;
  reqPart.dealerPaidPercentage = this.partDetails.dealerPaidPercentage;
  reqPart.tvsPaidPercentage = this.partDetails.tvsPaidPercentage;
  reqPart.partCost =this.partDetails.partCost;
  reqPart.userId =CommonService.getUserData().USER_ID;
  reqPart.active =this.partDetails.active;
  reqPart.countryCode = CommonService.getDealerData().COUNTRY_CODE;
  this.isShowPageLoader = true;
  this.masterService.post(reqPart, '/SpareParts/SaveAndUpdateSpareCost').subscribe(
   (resp: any) => {
     if (resp && resp.statusCode == 200) {
       this.isShowPageLoader = false;
      //  this.dialogRef.close(this.partDetails);
      this.dialogRef.close({msg:'costDone',partInfo:this.partDetails});
      this.toastr.success(resp.statusMessage);
     }
     else{
      this.isShowPageLoader = false;
      this.toastr.error(resp.statusMessage);
     }
     if( resp.statusCode == 306 ) {
       this.toastr.success(resp.statusMessage);
     }
   }, error => {
     this.isShowPageLoader = false;
     this.toastr.error(error.statusMessage);
   }
 );
}
/**
   * part search pop up
   */
  partSearch() {
    const dialogRef = this.dialog.open(PartSearchComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: { value: 'data', type: 'partSearch' }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        console.log(result);
        if(this.data && this.data.type=="partMasterCost"){
        if(result.obj.manufactureId == 1) {
          this.toastr.info('Price cannot be set for TVS Spare Parts')
          return;
        }
          this.partDetails.manufactureId = result.obj.manufactureId;
          if(this.masterDetails.manufacturerList&& this.masterDetails.manufacturerList.length >0 ){
            this.masterDetails.manufacturerList = this.masterDetails.manufacturerList.filter( val =>{
              return  result.obj.manufactureId === val.manufacturerId
            })
          }
         }
        this.partDetails.partId =result.obj.partId;
        this.partDetails.partNo =result.obj.partNo;
        this.partDetails.partName =result.obj.partDescription;
      }
    });
  }



  getPartList() {
    if(!this.partDetails.partNo){
      //  this.toastr.info('Please enter at least one search criteria');
       return;
     }
     this.isShowPageLoader = true;
 this.masterService.get('', '/SpareParts/SearchSparesCostDetails?dealerId=' + CommonService.getUserData().DEALER_ID +
 '&countryCode='+  CommonService.getDealerData().COUNTRY_CODE+
 '&branchId='+   CommonService.getUserData().BRANCH_ID+
 '&partNo='+  (this.partDetails.partNo ? this.partDetails.partNo : '')
  +'&partDesc='+''
  +'&manufId=' + 0
  +'&validFrom='+'').subscribe(
   (resp:any) => {
   this.isShowPageLoader = false;
   if (resp && resp.statusCode === 200 && resp.sparesCostList) {
    if( resp.sparesCostList.length> 0) {
      if(resp.sparesCostList[0].MANUF_ID==1){
        this.partDetails.partNo ='';
        this.toastr.info('Invalid Part Number')
        return;
      } 
      this.partDetails.partId =resp.sparesCostList[0].SPARE_PART_ID
      this.partDetails.partNo = resp.sparesCostList[0].PART_NO ;
      this.partDetails.partName = resp.sparesCostList[0].DESCRIPTION ;
      this.partDetails.manufactureId = resp.sparesCostList[0].MANUF_ID ;  
      
      if(this.masterDetails.manufacturerList&& this.masterDetails.manufacturerList.length >0 ){
        this.masterDetails.manufacturerList = this.masterDetails.manufacturerList.filter( val =>{
          return  this.partDetails.manufactureId === val.manufacturerId
        })
      }
   
    }
     this.isShowPageLoader = false;
   } else {
    this.partDetails.partNo='';
    this.toastr.info('Invalid Part Number')
   }
   this.isShowPageLoader = false;
   if (resp && resp.statusCode == 401) {
     this.loginService.logout();
   }
   }, error => {
   if (error.status == 401) {
     this.loginService.logout();
   }
   this.toastr.error(error.error.Message);
   this.isShowPageLoader = false;
 });
}

addTaxInfo() {
  this.taxInfo ={} ;
  this.taxDialogRef = this.dialog.open(this.helpTemplate, {
   width: '50%',
   height: 'auto',
   disableClose: true
});
this.taxDialogRef.afterClosed().subscribe(result => {
 if (result) {

 }
});

}

setInvoicePrice(){
 this.partDetails.invoicePrice = this.taxInfo.costPrice;
 this.partDetails.dealerPaidPercentage =0;
 this.partDetails.tvsPaidPercentage =0;
 this.partDetails.dealerPaidTax =0
 this.partDetails.tvsPaidTax =0;
 this.partDetails.partCost =0;
 this.taxDialogRef.close();
 
}

taxCalculation(){
  if(this.taxInfo.totalAmt && this.taxInfo.taxPer){
  this.taxInfo.costPrice =(Number(this.taxInfo.totalAmt)*100)/(Number(this.taxInfo.taxPer)+100);
  if(this.taxInfo.costPrice){
  this.taxInfo.costPrice = this.taxInfo.costPrice.toFixed(2);
  }
  }
}

}

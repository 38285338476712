import { Component, OnInit, Input, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { MasterService } from 'src/app/api-services/master.service';
// import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-updatejobcard-status',
  templateUrl: './updatejobcard-status.component.html',
  styleUrls: ['./updatejobcard-status.component.scss'],
})
export class UpdateJobcardStatusComponent implements OnInit {
  public lang: any;
  public isActive: any;
  public reasonList = [];
  public reasonID: any;
  public isShowPageLoader: any = false;
  
  constructor(
    private masterService: MasterService,
    private masterSales: MasterSales,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpdateJobcardStatusComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public commonService: CommonService,
    public toastr: ToastrService
  ) {
  }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }

    if (CommonService.getServAppData()) {
        this.reasonList = CommonService.getServAppData().JobcardCancelReason;
        this.reasonID = -1;
        //this.invoiceObj.COMPANY_ID = this.companyList.length == 1 ? this.companyList[0].COMPANY_ID : '';
      }
  }

  selectedItem() {
      if (this.reasonID > 0) {
        let closeObj = { obj: this.reasonID };
        this.dialogRef.close(closeObj);
      } else {
        this.toastr.warning(this.lang.SERVICES.selectReason);
      }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}

 
import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/api-services/login.service';
import { searchData } from 'src/app/api-services/dto/account-bankData';
@Component({
  selector: 'app-search-bank',
  templateUrl: './search-bank.component.html',
  styleUrls: ['./search-bank.component.scss']
})
export class SearchBankComponent implements OnInit {
  public lang: any;
  public isShowPageLoader: boolean = false;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public bankrows: any = [];
  public searchBankData: any = [];
  public searchData = new searchData();
  public banktypes: any = [
    {
      "id":"1",
      "desc": "Current Account"
    },
    {
      "id":"2",
      "desc": "Overdraft Account"
    },
    {
      "id":"3",
      "desc": "Cash Credit Account"
    }
  ];

  constructor( 
    private masterServices: MasterService,
    public toastr: ToastrService,
    private loginService:LoginService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<SearchBankComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (CommonService.getLang()) {
        this.lang = CommonService.getLang();
      }
     }

        /**
    * Filter each columns
    * @param {String} value
    * @param {String} prop
   */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  
  ngOnInit() {
  }

  searchBankDetails(){
    let reqObj: any = {};
  this.isShowPageLoader = true;
  reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
  reqObj.BANK_AC_TY_ID = this.searchData.banktype;
  reqObj.BANK_NAME = this.searchData.vendorName;
  reqObj.ACC_NO = this.searchData.bankAccountNo;
    this.masterServices.post(reqObj,'/BankRecon/BankReconciliationDetailSearch').subscribe(
      (resp: any) => {
        this.searchBankData = resp.BankReconDetailList;
        this.isShowPageLoader = false;
      },
      error => {
        if (error.status == 401) {
          this.loginService.logout();
        }
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
      });
  }

  /**
  * @param {String} selected
  * Get selected details
  */
   selectItem() {
    let closeObj = { obj: this.searchBankData[0] };
    this.dialogRef.close(closeObj);
  }
}

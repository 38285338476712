import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';

@Component({
  selector: 'app-rack',
  templateUrl: './rack.component.html',
  styleUrls: ['./rack.component.scss']
})
export class RackComponent implements OnInit {
  public lang: any;
  rackHeader = []
  rackList = [];
  partNo;
  reqQty;
  constructor(private toastr: ToastrService, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public dialogRef: MatDialogRef<RackComponent>) {
    if (this.data) {
      this.rackList = this.data.data;
      this.partNo = this.data.partNo;
      this.reqQty = this.data.reqQty;
    }
  }
  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      this.rackHeader = [
        {
          name: this.lang.SERVICES.rackId,
          width: 'w-100'
        },
        {
          name: this.lang.SERVICES.rackDesc,
          width: 'w-100'
        },
        {
          name: this.lang.SERVICES.reqQty,
          width: 'w-100'
        },
        {
          name: this.lang.SERVICES.manufacture,
          width: 'w-100'
        },
        {
          name: this.lang.SERVICES.avlQty,
          width: 'w-100'
        },
        {
          name: this.lang.SERVICES.cost,
          width: 'w-100'
        },
        {
          name: 'MRP',
          width: 'w-100'
        },
        {
          name: this.lang.SERVICES.taxable,
          width: 'w-100'
        }
      ];
    }
  }
  /**
   * Validate and close Racklist
   */
  saveRacklist() {
    let refQty: any = 0;
    let rackCount:any = 0;
    this.data.data.filter(key => {
      if (key.RequiredQty > 0) {
        refQty += parseFloat(key.RequiredQty);
        rackCount ++;
      }
    });
    if(rackCount != 1){
      this.toastr.error("Enter any One Rack");
      return;
    }
    if (parseFloat(refQty) == parseFloat(this.data.reqQty)) {
      this.dialogRef.close(this.data.data);
    } else {
      this.toastr.error(this.lang.Message.JOB_CARD.INVALID_REQTY);
    }
  }
  changeRequiredQty(rack, index) {
    if (Number(rack.RequiredQty)) {
      if (rack.RequiredQty > rack.FreeStockQty) {
        //console.log('Please enter quantity less than the available quantity'); 
        this.toastr.error(this.lang.Message.JOB_CARD.INVALID_RACK_QTY);
        this.rackList[index].RequiredQty = '';
      }
    }
    else {
      this.toastr.error(this.lang.Message.JOB_CARD.INVALID_REQTUANTITY);
      this.rackList[index].RequiredQty = '';
    }
  }
  allowQuantity(evt, type) {
    evt = evt || window.event;
    var charCode = evt.which || evt.keyCode;
    var charStr = String.fromCharCode(charCode);
    if (/^[0-9]{1,100000}$/.test(charStr) && (type == 'EA' || type == 'each')) {
      return true;
    } else if (/^[0-9.,]+$/.test(charStr) && (type != 'EA' && type != 'each')) {
      return true;
    } else {
      return false;
    }
  }
}

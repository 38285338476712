import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-spare-grn-payment-jv',
  templateUrl: './spare-grn-payment-jv.component.html',
  styleUrls: ['./spare-grn-payment-jv.component.scss']
})
export class SparesGrnPaymentJvComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public apsGrn: any = {};
  public part: any={};
  public vendorLists: any = [];
  public vendorRows: any = [];
  public sparesGRNPaymentLists: any = [];
  public isShowPageLoader: boolean;

  constructor(
    private dialogRef: MatDialogRef<SparesGrnPaymentJvComponent>,
    private masterService: MasterService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  
  if (this.data) {
    this.isActive = this.data && this.data.type ? this.data.type : '';
    this.inlineFilter = {
      SPARE_GRN_NO:'',
      GRN_VALUE:'',
      INVOICE_NO:'',
      VENDOR_NAME:'',
    };
  }
}

  ngOnInit() {   
    {
      let reqObj: any = {};
      reqObj.dealerId = CommonService.getUserData().DEALER_ID;      
      reqObj.branchId = CommonService.getUserData().BRANCH_ID;
      reqObj.vendorTypeId= null;
      reqObj.active=true;
      this.isShowPageLoader = true;
      this.masterService.post(reqObj, '/DMSMaster/VendorMaster/SearchVendor').subscribe(
        (resp: any) => {
         
          if (resp && resp.statusCode == 200) {
            this.isShowPageLoader = false;
            this.vendorLists = resp.vendorList ;
          
          } 
          else if (resp && resp.statusCode == 204) {
            this.vendorLists = [];
            this.isShowPageLoader = false;
            return this.toastr.error(resp.statusMessage);
          }
          else {
            this.isShowPageLoader = false;
          }
        }, error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        })
      }
    if (this.data) {
      this.vendorLists = this.data.vendorList && this.data.vendorList.length > 0 ? this.data.vendorList : [];
      this.vendorLists.forEach(value => {
        if(this.data.vendorName == value) {
          this.apsGrn.APS_Code = value.APS_Code;
        }
      });
      
    }
    
  }

  apsGrnSearch() {
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reqObj.SPARE_GRN_NO = this.part.SPARE_GRN_NO ? Number(this.part.SPARE_GRN_NO) : 0;
    reqObj.VENDOR_ID = this.part.VENDOR_ID ? this.part.VENDOR_ID : 0;
    reqObj.FromCREATED_ON= fromDateFormat;
    reqObj.ToCREATED_ON= toDateFormat;
    reqObj.FIN_YEAR=CommonService.getDealerData().FIN_YEAR;
    this.isShowPageLoader = true;
    this.masterService.post(reqObj, '/Accounts/SearchSparesGRNPaymentList').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.sparesGRNPaymentLists = resp.sparesGRNPaymentList ;
        
        } 
        else if (resp && resp.statusCode == 204) {
          this.vendorLists=[];
          this.sparesGRNPaymentLists = [];
          this.isShowPageLoader = false;
          return this.toastr.error(resp.statusMessage);
        }
        else {
          this.isShowPageLoader = false;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      })
     
    }
  selectedItem() {
    this.dialogRef.close({data: this.selected[0]});
  }

  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

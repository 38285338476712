import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { LoginService } from 'src/app/api-services/login.service';
import { CommonService } from 'src/app/api-services/common.service';
import { JobCardService } from '../../api-services/job-card.service';
import { ServiceAppoitmentService } from '../../api-services/service-appoitment.service';
import { JobCardInvoiceService } from '../../api-services/job-card-invoice.service';
import { MasterService } from 'src/app/api-services/master.service';
declare var moment: any;
@Component({
  selector: 'app-delivery-chalan-no-search',
  templateUrl: './delivery-chalan-no-search.component.html',
  styleUrls: ['./delivery-chalan-no-search.component.scss']
})
export class DeliveryChalanNoSearchComponent implements OnInit {
  public lang: any;
  public isActive: any;
  public selected = [];
  public isShowPageLoader: boolean = false;
  public jobCardNo: any = '';
  public vehicleNo: any = '';
  public fromDate: any = '';
  public toDate: any = '';
  constructor(private toastr: ToastrService, private jobCardService: JobCardService,
    private serviceAppoitmentService: ServiceAppoitmentService,
    private jobCardInvoiceService: JobCardInvoiceService,
    private masterServices: MasterService, private loginService: LoginService,
    private dialogRef: MatDialogRef<DeliveryChalanNoSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {
    if (CommonService.getLang()) {
      this.searchItem();
      this.lang = CommonService.getLang();
      this.isActive = this.data.key; // for getting search key 
      // if (this.data && this.data.type == 'partCustomerSearch') {
      //   console.log("partTrue::");
      //   this.partSearchKey = 'partCustomerSearch';
      // } else if (this.data && this.data.type == 'UVDServicesAppointment') {
      //   this.partSearchKey = 'UVDServicesAppointment';
      // }
      /**
       * @param {String} isActive
       * adding search column values
       * Returns an array.
       */
      if (this.isActive == 'JcNo') {
        this.data = {
          columns: [
            { name: this.lang.SERVICES.jobCardNo, prop: 'JobcardNo' },
            { name: this.lang.SERVICES.jcDate, prop: 'JobcardDate'},
            { name: this.lang.UVD.procurementNo, prop: 'ProcurementNo' },
            { name: this.lang.UVD.vehicleId, prop: 'UVDVehileId' },
            { name: this.lang.PART.UVDVehileRegId, prop: 'UVDVehileRegId' }            
          ]
        };
      }
    }
  }

  /**
    * @param {String} searchItem
    * get search table data
    * Returns an array.
    */
  searchItem() {
    this.isShowPageLoader = true;
        this.masterServices.post('', 
        '/DeliveryChallan/getProcurementList?DealerId=' 
        + CommonService.getUserData().DEALER_ID
        + '&BranchId=' 
        + CommonService.getUserData().BRANCH_ID
        + '&JcId='
        + this.jobCardNo
        + '&StartDate='
        + CommonService.getReqDateFormat(this.fromDate)
        + '&EndDate=' 
        + CommonService.getReqDateFormat(this.toDate)
        + '&vehicleFrameNo='
        + this.vehicleNo
        ).subscribe(
                (resp: any) => {
                  this.data.rows = [];
                  if(resp.StatusCode == 200){
                    this.data.rows = resp.procurementLists.map( x => {
                      return {
                        ...x,
                        JobcardDate: moment(x.JobcardDate).format("DD/MM/yyyy")
                      }
                    });
                    
                    if(resp.procurementLists.length == 0){
                      this.toastr.info("No Data Available");
                    }
                  }
                },
                error => {
                    this.isShowPageLoader = false;
                    this.toastr.error(error.error.Message);
                }
            )
  }

  /**
  * @param {String} selected
  * Get selected details
  */
  selectItem() {
    let closeObj = { obj: this.selected[0], category: this.isActive };
    this.dialogRef.close(closeObj);
  }
  /**
 * @param {String} 
 * close popup search dialog
 */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
import { HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { RowState } from 'src/app/api-services/dto/enumDO';
import { MasterDataService } from 'src/app/api-services/master-data.service';

@Component({
  selector: 'app-user-mapping',
  templateUrl: './user-mapping.component.html',
  styleUrls: ['./user-mapping.component.scss']
})
export class UserMappingComponent implements OnInit {

  @Input() displayData: any = [];
  public userData: any = [];
  public isShowPageLoader: any=false;
  public userList: any;
  public userDo:any;
  public EMPLOYEE: any;
  public BRANCHES: any;
  public isUserEdit: any = true;
  public ROLES: any;
  public confirmPwd: any;
  public isCreate: any;
  
  constructor(private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    // private masterService: MasterService,
    public route: ActivatedRoute,
    private MasterDataService: MasterDataService,
    public dialogRef: MatDialogRef<UserMappingComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.displayData = this.data.userData;
  }
  ngOnInit()
  {
    debugger;
    if(this.displayData.length==0)
    {
      this.isCreate = 1;
    }
    else
    {
      if(this.displayData.isView==true)
      {
        this.isUserEdit = false;
      }
      this.isCreate = 0;
    }
    this.loadStaticEmployeeMaster();
  }

  loadUserMapping()
  {
    if(this.displayData.LOGIN_ID==null && this.displayData.LOGIN_ID == undefined)
    {
      this.displayData.LOGIN_ID = '';
    }
    if(this.displayData.USER_ID==null && this.displayData.USER_ID == undefined)
    {
      this.displayData.USER_ID = 0;
    }
    if(this.displayData.BRANCH_ID==null && this.displayData.BRANCH_ID== undefined)
    {
      this.displayData.BRANCH_ID = 0;
    }
    let reqParam = new HttpParams();
      reqParam = reqParam.set("DealerId", CommonService.getUserData().DEALER_ID)
        .set("loginId",String(this.displayData.LOGIN_ID))
        .set("BranchID",this.displayData.BRANCH_ID)
        .set("UserId",String(this.displayData.USER_ID))
        
        this.isShowPageLoader = true;
        this.MasterDataService.loadUserMapping(reqParam).subscribe((resp:any)=>{
            if(resp && resp.statusCode ===200 && resp.data){
             
                this.userDo = resp.data._userList;
                this.userList = resp.data._listUser;
                this.userDo = this.userDo.filter(x => x.BRANCH_ID == this.displayData.BRANCH_ID);
                this.ROLES = resp.data._listRole;
                this.EMPLOYEE = resp.data._listEmployee;
                if(this.displayData.LOGIN_ID !=0)
                {
                  this.displayData = resp.data._userDet;
                  if(this.userList.length>0)
                  {
                    this.displayData.EMPLOYEE_ID = this.userDo[0].EMPLOYEE_ID;
                  }
                  else
                  {
                    this.displayData.EMPLOYEE_ID = -1;
                  }
                  this.confirmPwd = this.displayData.PASSWORD;
                }
            }
            this.isShowPageLoader = false;
          }, error => {
            this.toastr.error(error.Message);
            this.isShowPageLoader = false;
          });
        }
        
        loadStaticEmployeeMaster(){
          debugger;
          let reqParam = new HttpParams();
          reqParam = reqParam.set("DealerId", CommonService.getUserData().DEALER_ID)
            .set("BranchId", CommonService.getUserData().BRANCH_ID)
            .set("CountryCode", CommonService.getDealerData().COUNTRY_CODE)
        
          this.isShowPageLoader = true;
          this.MasterDataService.loadStaticEmployeeMaster(reqParam).subscribe((resp:any)=>{
            if(resp && resp.statusCode ===200 && resp.data){
              debugger;
              this.BRANCHES = resp.data.BranchList.length > 0 ? resp.data.BranchList : [];
              this.loadUserMapping();
            }
            // this.isShowPageLoader = false;
          }, error => {
            this.toastr.error(error.error.Message);
            this.isShowPageLoader = false;
          });
        }
    submit()
    {
      debugger;
      if(this.displayData.BRANCH_ID==null || this.displayData.BRANCH_ID==0 || this.displayData.BRANCH_ID == -1)
      {
        return this.toastr.warning("Please select Branch");
      }
      else
      {
        if(this.displayData.LOGIN_ID==null || this.displayData.LOGIN_ID=='')
        {
          return this.toastr.warning("Please Enter Login Id");
        }
        else
        {
          if(this.displayData.EMPLOYEE_ID==null || this.displayData.EMPLOYEE_ID==-1 || this.displayData.EMPLOYEE_ID==0)
          {
            return this.toastr.warning("Please select Employee");
          }
          else 
          {
            if(this.displayData.USER_FULL_NAME==null || this.displayData.USER_FULL_NAME=='')
            {
              return this.toastr.warning("Please Enter User Name");
            }
            else
            {
              if(this.displayData.ROLE_ID==null || this.displayData.ROLE_ID==-1 || this.displayData.ROLE_ID==0)
              {
                return this.toastr.warning("Please select Role");
              }
              else
              {
                if(this.displayData.EMAIL_ID==null || this.displayData.EMAIL_ID=='')
                {
                  return this.toastr.warning("Please Enter Email Id");
                }
                else 
                {
                  if(this.displayData.PASSWORD==null || this.displayData.PASSWORD =='')
                  {
                    return this.toastr.warning("Please Enter Password");
                  }
                  else
                  {
                    if(this.confirmPwd==null || this.confirmPwd =='')
                    {
                      return this.toastr.warning("Please Enter Confirm Password");
                    }
                    else
                    {
                      if(this.confirmPwd!=this.displayData.PASSWORD)
                      {
                        return this.toastr.warning("Confirm Password is not same as Password");
                      }
                      else
                      {
                        if(this.isCreate == 1)
                        {
                          this.displayData.ROW_STATE = RowState.Created;
                        }
                        else
                        {
                          this.displayData.ROW_STATE = RowState.Modified;
                        }
                        let req = {
                          "_User":{
                            "DEALER_ID": CommonService.getUserData().DEALER_ID,
                            "BRANCH_ID": this.displayData.BRANCH_ID,
                            "LOGIN_ID": this.displayData.LOGIN_ID,
                            "USER_ID": this.displayData.USER_ID,
                            "USER_FULL_NAME": this.displayData.USER_FULL_NAME,
                            "EMAIL_ID": this.displayData.EMAIL_ID,
                            "ACTIVE":true,
                            "PASSWORD": this.displayData.PASSWORD,
                            "ROLE_ID": this.displayData.ROLE_ID,
                            "ROW_STATE": this.displayData.ROW_STATE,
                            "EMPLOYEE_ID": this.displayData.EMPLOYEE_ID
                            }
                          }
                          this.isShowPageLoader = true;
                          this.MasterDataService.saveUserMapping(req).subscribe((resp:any)=>{
                            if(resp.d && resp.d.statusCode === 200 && resp.d.data){
                               this.toastr.success(resp.d.message);
                            } 
                            this.isUserEdit = false;
                            this.isShowPageLoader = false;
                          }, error => {
                            this.toastr.error(error.error.Message);
                            this.isShowPageLoader = false;
                          });
                          
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
}



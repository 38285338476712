import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ServiceRequestService } from 'src/app/api-services/service-request';
import { CommonService } from 'src/app/api-services/common.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/api-services/login.service';
import { HttpParams } from '@angular/common/http';
import { JobCardService } from '../../api-services/job-card.service';
import { RepairRequestDO } from 'src/app/api-services/dto/job-card';
declare var $: any;
@Component({
  selector: 'app-create-repair-request',
  templateUrl: './create-repair-request.component.html',
  styleUrls: ['./create-repair-request.component.scss']
})
export class CreateRepairRequestComponent implements OnInit {
  public isShowLoader = false;
  public isRootDisabled = true;
  public lang: any;
  public maxDate: any = new Date();
  public responsibleby = [];
  public responsiblePerson = [];
  public isShowVOR : boolean = false;
  public isDisable: boolean = false;
  public isShowPageLoader: boolean = false;
  public alphaPattern = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$';
  public minDate: any = CommonService.setDateTime(new Date());
  partsHeader = [];
  refPartList = [];
  orderType: Array<any> = [];
  orderRemarks: Array<any> = [];
  isDisablePart;
  public BusinessType: any;
  public TempRequest: Array<RepairRequestDO> = [];
  repairrequest = {
    "DEALER_ID": CommonService.getUserData().DEALER_ID,
    "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
    "REPAIR_REQUEST_ID": this.data.REPAIR_REQUEST_ID,
    "CUSTOMER_ID": this.data.CUSTOMER_ID,
    "VEHICLE_ID": this.data.VEHICLE_ID,
    "END_USER_ID": this.data.END_USER_ID,
    "PageType": this.data.PageType,
    "COMMITMENT_GIVEN_BY": this.data.COMMITMENT_GIVEN_BY,
    "COMMITMENT_GIVEN": this.data.COMMITMENT_GIVEN,
    "COMPLAINT_NOTES": this.data.COMPLAINT_NOTES,
    "COMMITMENT_DATE": this.data.COMMITMENT_DATE,
    "RESPONSIBLE_PERSON": this.data.RESPONSIBLE_PERSON,
    "PartsList": this.data._PartsAngular,
    "INVOICE_NO": this.data.INVOICE_NO,
    "SEND_SMS": 1,
    "ADVANCE_AMT": '',
    "CONTACT_NO" : this.data.CONTACT_NO,
    "TEAMLEADER_ID" : this.data.TEAMLEADER_ID
  };

  stateList: Array<any> = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateRepairRequestComponent>,
    private toastr: ToastrService,
    private ServRequest: ServiceRequestService,
    private loginService: LoginService,
    private jobCardService: JobCardService) {
  }
  ngOnInit() {
    this.lang = CommonService.getLang();
    this.BusinessType = CommonService.getDealerData().BUSINESS_TYPE_ID;
    // if ((this.BusinessType == 1 || this.BusinessType == 2)) {
    //   this.orderType = [
    //     { ORDER_TO: "VOR Order" },
    //     { ORDER_TO: "Stock Order" },
    //     { ORDER_TO: "No Order Required" }
    //   ]
    // }
    // else {
    //   this.orderType = [
    //     { ORDER_TO: "Stock Order" },
    //     { ORDER_TO: "No Order Required" }
    //   ]
    // }
    this.orderRemarks = [
      { REMARKSVAL: "Order Already Placed" },
      { REMARKSVAL: "Parts in Transit" },
      { REMARKSVAL: "Arranged from co dealers / vendor" },
      { REMARKSVAL: "Supported by TVSM /AO4" },
      { REMARKSVAL: "Arranged from kit" }
    ]
    this.partsHeader = [
      {
        name: this.lang.SERVICES.partNo + ' *',
        width: 'wmpx-200'
      },
      {
        name: this.lang.SERVICES.partDescription + ' *',
        width: 'wmpx-250'
      },
      {
        name: this.lang.SERVICES.qty + ' *',
        width: 'wmpx-80'
      },
      {
        name: this.lang.PART.availableQty + ' *',
        width: 'wmpx-100'
      },
      {
        name: 'Order To *',
        width: 'wmpx-150'
      },
      {
        name: 'Remarks *',
        width: 'wmpx-200'
      },
      {
        name: '',
        width: 'wmpx-50'
      }
    ];


    let salesappData = CommonService.getSalesAppData();
    this.responsibleby = salesappData.employeeSubGroupList.filter(key => {
      if (key.EMP_SUBGRP_ID == 126 || key.EMP_SUBGRP_ID == 134 || key.EMP_SUBGRP_ID == 136 ||
        key.EMP_SUBGRP_ID == 147 || key.EMP_SUBGRP_ID == 170 || key.EMP_SUBGRP_ID == 159) {
        return key;
      }
    });
    if((this.BusinessType == 1 || this.BusinessType == 2) 
    && (this.repairrequest.PageType == 'IssueSpares' || this.repairrequest.PageType == 'PSF'))
    {
      this.isShowVOR = true;
    }   
    // this.responsibleby = appData.EmployeeList.filter(key => {
    //   if (key.EMP_GROUP_ID == 2) {
    //     return key;
    //   }
    // });
    // this.responsiblePerson = appData.EmployeeList.filter(key => {
    //   if (key.EMP_SUBGRP_ID == 134 || key.EMP_SUBGRP_ID == 136 || key.EMP_SUBGRP_ID == 147) {
    //     return key;
    //   }
    // });
    this.responsiblePerson = [];
    this.repairrequest.SEND_SMS = 1;
    var curdate = CommonService.getCurrentDateTimetemp();
    this.minDate = CommonService.setDateTime(curdate);
    if (this.data.REPAIR_REQUEST_ID > 0) {
      var commitdate = CommonService.generalFormat(this.data.COMMITMENT_DATE);
      this.repairrequest.COMMITMENT_DATE = CommonService.setDateTime(commitdate);
      if (this.data.PartsList != null && this.data.PartsList.length > 0) {
        this.refPartList = this.data.PartsList;
      }
      else {
        this.addPart();
      }
    }
    else {
      this.addPart();
    }
  }

  empSubgroupChange(event: any) {
    let val = event.target.value;
    this.repairrequest.RESPONSIBLE_PERSON = "";
    let appData = CommonService.getSalesAppData();
    if (val != "" && val > 0) {
      this.responsiblePerson = appData.AllEmpList.filter(key => {
        if (key.EMP_SUBGRP_ID == val) {
          return key;
        }
      });
    }
    else {
      this.responsiblePerson = [];
    } 
  }

  ClosePage() {
    this.dialogRef.close(false);
  }

  /**
* Save Service Request
* @param {Object} invoiceObj
* @param {Number} index
*/
  saveServiceRequest() {
    this.isDisablePart = true;
    if (this.validatesave()) {
      let partIssueList = [];
      if (this.refPartList.length > 0) {
        this.refPartList.forEach((val) => {
          if (val.SPARE_PART_ID > 0) {
            let enqDet = {
              "DEALER_ID": CommonService.getUserData().DEALER_ID,
              "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
              "SPARE_PART_ID": val.SPARE_PART_ID,
              "CREATED_BY": CommonService.getUserData().USER_ID,
              "REQUIRED_QTY": val.QUANTITY,
              "AVAILABLE_QTY": val.AVAILABLE_QTY,
              "ORDER_TO": val.ORDER_TO,
              "REMARKS": val.REMARKS
            }
            partIssueList.push(enqDet);
          }
        });
      }
      let saveObject = {
        "DEALER_ID": CommonService.getUserData().DEALER_ID,
        "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
        "COUNTRY_CODE": CommonService.getDealerData().COUNTRY_CODE,
        "BusinessTypeSettingsSPARE_PO": CommonService.getDealerData().BusinessSettings.SPARE_PO,
        "BusinessType": CommonService.getDealerData().BUSINESS_TYPE_ID,
        "REPAIR_REQUEST_ID": this.data.REPAIR_REQUEST_ID,
        "COMMITMENT_GIVEN_BY": this.repairrequest.COMMITMENT_GIVEN_BY,
        "CUSTOMER_ID": this.data.CUSTOMER_ID,
        "VEHICLE_ID": this.data.VEHICLE_ID,
        "COMMITMENT_GIVEN": this.repairrequest.COMMITMENT_GIVEN,
        "COMPLAINT_NOTES": this.repairrequest.COMPLAINT_NOTES,
        "ENDUSER_ID": this.data.END_USER_ID,
        "STATUS": 0,
        "CREATED_BY": CommonService.getUserData().USER_ID,
        "MODIFIED_BY": CommonService.getUserData().USER_ID,
        "ACTIVE": 1,
        "COMMITMENT_DATE": CommonService.generalFormatYYYYMMDD(this.repairrequest.COMMITMENT_DATE),
        "INVOICE_NO": this.data.INVOICE_NO,
        "PageType": this.data.PageType,
        "RESPONSIBLE_PERSON": this.repairrequest.RESPONSIBLE_PERSON,
        "PartList": partIssueList,
        "SEND_SMS": this.repairrequest.SEND_SMS,
        "ADVANCE_AMT": this.repairrequest.ADVANCE_AMT,
        "CONTACT_NO" : this.repairrequest.CONTACT_NO,
        "TEAMLEADER_ID" : this.repairrequest.TEAMLEADER_ID
      }
      this.isShowPageLoader = true;
      this.ServRequest.saveServiceRequest(saveObject).subscribe((resp: any) => {
        if (resp && resp.statusCode && resp.statusCode == 200) {
          this.toastr.success(resp.data.RepReqIDMessage);
          if (resp.data.StockOrderPoIDMessage && resp.data.StockOrderPoIDMessage != "") {
            setTimeout(() => {
              this.toastr.success(resp.data.StockOrderPoIDMessage);
            }, 2000)
          }
          if (resp.data.VorOrderPoIDMessage && resp.data.VorOrderPoIDMessage != "") {
            setTimeout(() => {
              this.toastr.success(resp.data.VorOrderPoIDMessage);
            }, 3000)
          }          
          if (resp.data.ErrorMsg && resp.data.ErrorMsg != "") {
            setTimeout(() => {
              this.toastr.warning(resp.data.ErrorMsg);
            }, 6000)
          }
          this.isShowPageLoader = false;
          setTimeout(() => {
            this.dialogRef.close(resp);
          }, 5000)

        } else {
          this.toastr.error(resp.message);
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }
      }, error => {
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
        this.toastr.error(error.error.Message);
      });
    }
  }
  validatesave() {
    let valid = true;
    if (this.repairrequest.COMMITMENT_GIVEN_BY == "") {
      this.toastr.warning("Please Select Responsible By");
      this.isDisablePart = false;
      return valid = false;;
    }
    if (this.repairrequest.RESPONSIBLE_PERSON == "") {
      this.toastr.warning("Please Select Responsible Person");
      this.isDisablePart = false;
      return valid = false;;
    }
    let partIssueList = [];
    if (this.refPartList.length > 0) {
      this.refPartList.forEach((val) => {
        if (val.SPARE_PART_ID > 0) {
          let enqDet = {
            "DEALER_ID": CommonService.getUserData().DEALER_ID,
            "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
            "SPARE_PART_ID": val.SPARE_PART_ID,
            "PART_NO": val.PART_NO,
            "CREATED_BY": CommonService.getUserData().USER_ID,
            "REQUIRED_QTY": val.QUANTITY,
            "AVAILABLE_QTY": val.AVAILABLE_QTY,
            "ORDER_TO": val.ORDER_TO,
            "REMARKS": val.REMARKS
          }
          partIssueList.push(enqDet);
        }
      });
    }
    if (partIssueList.length > 0) {
      partIssueList.forEach((val) => {
        if (val.SPARE_PART_ID > 0) {
          if (val.REQUIRED_QTY == "0" || val.REQUIRED_QTY == "") {
            this.toastr.warning("Please Enter Qty for the Part No : " + val.PART_NO + "");
            this.isDisablePart = false;
            return valid = false;
          }
          else if (val.ORDER_TO == "") {
            this.toastr.warning("Please Select Order To for the Part No : " + val.PART_NO + "");
            this.isDisablePart = false;
            return valid = false;
          }
          else if (val.ORDER_TO == "No Order Required" && val.REMARKS == "") {
            this.toastr.warning("Please Select Remarks for the Part No : " + val.PART_NO + "");
            this.isDisablePart = false;
            return valid = false;
          }
          else if (val.ORDER_TO == "VOR Order" && (val.REQUIRED_QTY <= 0 || val.REQUIRED_QTY > 5)) {
            this.toastr.warning("Please Enter Qty without Exceeding 5 for the Part No : " + val.PART_NO + "");
            this.isDisablePart = false;
            return valid = false;            
          }
        }
      });
    } 
    return valid;
  }

  /**
  * Search part no
  * @param {number} index 
  * @param {Object}event 
  */
  getPartNo(index, event) {
    if (event.code != 'ArrowUp' && event.code != 'ArrowDown') {
      let localParam = this.refPartList[index];
      if (localParam && localParam.PART_NO && localParam.PART_NO.length >= 3) {
        let params = new HttpParams();
        params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
          .set('Country', CommonService.getDealerData().COUNTRY_CODE)
          .set('BranchID', CommonService.getUserData().BRANCH_ID)
          .set('partNo', localParam.PART_NO)
          .set('LocID', "1")
          .set("Is_Lubricant", null);
        this.jobCardService.getPartNumbers(params).subscribe((resp: any) => {
          if (resp && resp.data && resp.statusCode === 200) {
            this.refPartList[index].partNoList = resp.data;
          } else {
            this.toastr.error(resp.message);
            if (resp && resp.statusCode == 401) {
              this.loginService.logout();
            }
          }
        }, error => {
          this.toastr.error(error.error.Message);
          if (error.status == 401) {
            this.loginService.logout();
          }
        });
      }
    }
  }

  /**
* Get part details based on required quantity
* @param {Number} index 
*/
  getPartDetails(index, obj) {
    let localParam = this.refPartList[index];
    let refPartId = 0;
    this.refPartList.filter(key => {
      if (this.refPartList.indexOf(key) != index) {
        if (key.PART_NO == localParam.PART_NO) {
          refPartId++;
        }
      }
    });
    if (refPartId == 0 || obj) {
      if (localParam.PART_NO) {
        this.isShowPageLoader = true;
        let params = new HttpParams();
        params = params.set('SparePartID', localParam.PART_NO)
          .set('DealerId', CommonService.getUserData().DEALER_ID)
          .set('BranchId', CommonService.getUserData().BRANCH_ID)
          .set('COUNTRY_CODE', CommonService.getDealerData().COUNTRY_CODE)
          .set('UserId', CommonService.getUserData().USER_ID);
        this.jobCardService.PartNumberDescriptionWithStock(params).subscribe((resp: any) => {
          if (resp && resp.data && resp.statusCode === 200) {
            this.refPartList[index].SPARE_PART_ID = resp.data.SPARE_PART_ID;
            this.refPartList[index].DESCRIPTION = resp.data.DESCRIPTION;
            this.refPartList[index].PART_NO = resp.data.PART_NO;
            this.refPartList[index].QTY = resp.data.QTY;
            this.refPartList[index].AVAILABLE_QTY = resp.data.AvailableQty;
            this.isShowPageLoader = true;
          } else {
            this.toastr.error(resp.message);
          }
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }, error => {
          this.toastr.error(error.error.Message);
          this.isShowPageLoader = false;
          if (error.status == 401) {
            this.loginService.logout();
          }
        });
      }
    }
  }


  addPart() {
    if (this.refPartList.length < 3) {
      let lastElem = this.refPartList[this.refPartList.length - 1];
      if (this.refPartList.length == 0 || lastElem.SPARE_PART_ID != 0) {
        let partIssueDet = new RepairRequestDO();
        partIssueDet.DEALER_ID = CommonService.getUserData().DEALER_ID;
        partIssueDet.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
        partIssueDet.PART_NO = "";
        partIssueDet.DESCRIPTION = "";
        partIssueDet.QUANTITY = 1;
        partIssueDet.AVAILABLE_QTY = 0;
        partIssueDet.ORDER_TO = "";
        partIssueDet.REMARKS = "";
        partIssueDet.REMARKSTEXT = "";
        partIssueDet.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
        this.refPartList.push(partIssueDet);
        $('.inlineTable').scrollTop($('.inlineTable')[0].scrollHeight);
      }
    }
    else {
      this.toastr.error("More than 3 Line item cannot be added");
    }
  }

  /**
 * Delete Line Item based on index
 * @param {Number} index
 */
  deleteLineitem(index) {
    this.refPartList.splice(index, 1);
    this.toastr.success("Line Item Removed");
  }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { JobCardService } from 'src/app/api-services/job-card.service';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ServiceAppoitmentService } from 'src/app/api-services/service-appoitment.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';

@Component({
  selector: 'app-frame-number-search',
  templateUrl: './frame-number-search.component.html',
  styleUrls: ['./frame-number-search.component.scss']
})
export class FrameNumberSearchComponent implements OnInit {
  public lang: any;
  public searchLoader: any;
  public popupHeader: any = [];
  public popupdata: any = [];
  public framenoData: any = [];

  constructor(private toastr: ToastrService, private jobCardService: JobCardService,
    private serviceAppoitmentService: ServiceAppoitmentService,
    private masterServices: MasterService,private loginService:LoginService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<FrameNumberSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
  }
  ngOnInit() {
    if(this.data){
      this.popupdata = this.data.value;
      console.log("Popupdata",this.popupdata);
      
    }
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  framenoDetail(){
    let closeObj = { obj: this.popupdata };
    this.dialogRef.close(closeObj);
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
}
export const themes = {
  grdYellowRed:{
    '--background':'linear-gradient( 135deg, #FEC163 10%, #DE4313 100%)',
    '--bg-header':'linear-gradient( 135deg, #FEC163 10%, #DE4313 100%)',
    '--text-color':'#e04e1a',
    '--inner-header':'linear-gradient( 135deg, #FEC163 10%, #DE4313 100%)',
    isGradiant:true
  },
  grdRedPink:{
    '--background':'linear-gradient( 70.5deg,  rgba(214,35,35,1) 2.7%, rgba(190,81,184,1) 97.8% )',
    '--bg-header':'linear-gradient( 70.5deg,  rgba(214,35,35,1) 2.7%, rgba(190,81,184,1) 97.8% )',
    '--text-color':'#c93b71',
    '--inner-header':'linear-gradient( 70.5deg,  rgba(214,35,35,0.5) 2.7%, rgba(190,81,184,0.5) 97.8% )',
    isGradiant:true
  },
  grdBlueGreen:{
    '--background':'linear-gradient( 121.7deg,  rgba(41,116,250,1) 22.9%, rgba(67,212,255,1) 69% )',
    '--bg-header':'linear-gradient( 121.7deg,  rgba(41,116,250,1) 22.9%, rgba(67,212,255,1) 69% )',
    '--text-color':'#2974fa',
    '--inner-header':'linear-gradient( 121.7deg, rgba(41, 116, 250, 0.5) 22.9%, rgba(67, 212, 255, 0.5) 69% )',
    isGradiant:true
  },
  grdBrown:{
    '--background':'radial-gradient( circle farthest-corner at 9.2% 43.4%,  rgba(0,0,0,1) 0%, rgba(175,0,123,1) 100.2% )',
    '--bg-header':'radial-gradient( circle farthest-corner at 9.2% 43.4%,  rgba(0,0,0,1) 0%, rgba(175,0,123,1) 100.2% )',
    '--text-color':'#6b004b',
    '--inner-header':'radial-gradient( circle farthest-corner at 9.2% 43.4%,  rgba(0,0,0,0.5) 0%, rgba(175,0,123,0.5) 100.2%)',
    isGradiant:true
  },
  grdDark:{
    '--background':'radial-gradient( circle 902px at 10% 20%,  rgba(18,50,90,1) 0%, rgba(207,199,252,1) 100.2% )',
    '--bg-header':'radial-gradient( circle 902px at 10% 20%,  rgba(18,50,90,1) 0%, rgba(207,199,252,1) 100.2% )',
    '--text-color':'#25416a',
    '--inner-header':'radial-gradient( circle 902px at 10% 20%,  rgba(18,50,90,0.5) 0%, rgba(207,199,252,0.5) 100.2% )',
    isGradiant:true
  },
  grdGreen:{
    '--background':'radial-gradient( circle 759px at 14% 22.3%,  rgba(10,64,88,1) 0%, rgba(15,164,102,1) 90% )',
    '--bg-header':'radial-gradient( circle 759px at 14% 22.3%,  rgba(10,64,88,1) 0%, rgba(15,164,102,1) 90% )',
    '--text-color':'#0c675d',
    '--inner-header':'radial-gradient( circle 759px at 14% 22.3%,  rgba(10,64,88,0.5) 0%, rgba(15,164,102,0.5) 90% )',
    isGradiant:true
  },
  grdMulti:{
    '--background':'radial-gradient( circle 780px at 37.8% 100.3%,  rgba(19,55,115,1) 2.2%, rgba(32,7,80,1) 20.2%, rgba(27,88,111,1) 58.6%, rgba(115,88,44,1) 75%, rgba(99,19,90,1) 89.6%, rgba(12,51,76,1) 96.1% )',
    '--bg-header':'radial-gradient( circle 780px at 37.8% 100.3%,  rgba(19,55,115,1) 2.2%, rgba(32,7,80,1) 20.2%, rgba(27,88,111,1) 58.6%, rgba(115,88,44,1) 75%, rgba(99,19,90,1) 89.6%, rgba(12,51,76,1) 96.1% )',
    '--text-color':'#200951',
    '--inner-header':'radial-gradient( circle 780px at 37.8% 100.3%,  rgba(19,55,115,0.5) 2.2%, rgba(32,7,80,0.5) 20.2%, rgba(27,88,111,0.5) 58.6%, rgba(115,88,44,0.5) 75%, rgba(99,19,90,0.5) 89.6%, rgba(12,51,76,0.5) 96.1% )',
    isGradiant:true
  },
  grdInsta:{
    '--background':'linear-gradient(#833ab4, #fd1d1d, #fcb045)',
    '--bg-header':'linear-gradient(to right, #833ab4, #fd1d1d, #fcb045)',
    '--text-color':'#94369e',
    '--inner-header':'linear-gradient(to right, #833ab47a, #fd1d1d82, #fcb0457a)',
    isGradiant:true
  },
  grdMemari:{
    '--background':'linear-gradient(#aa4b6b, #6b6b83, #3b8d99)',
    '--bg-header':'linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)',
    '--text-color':'#428795',
    '--inner-header':'linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99)',
    isGradiant:true
  }
};
export const fonts = {
  roboto:{
    'link':'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700',
    'fontName':'Roboto'
  },
  bellefair:{
    'link':'https://fonts.googleapis.com/css?family=Bellefair&display=swap',
    'fontName':'Bellefair'
  },
  underdog:{
    'link':'https://fonts.googleapis.com/css?family=Underdog&display=swap" rel="stylesheet',
    'fontName':'Underdog'
  },
  offside:{
    'link':'https://fonts.googleapis.com/css?family=Offside&display=swap',
    'fontName':'Offside'
  },
  unkempt:{
    'link':'https://fonts.googleapis.com/css?family=Unkempt&display=swap" rel="stylesheet',
    'fontName':'Unkempt'
  },
  iceland:{
    'link':'https://fonts.googleapis.com/css?family=Iceland&display=swap" rel="stylesheet',
    'fontName':'Iceland'
  },
  julee:{
    'link':'https://fonts.googleapis.com/css?family=Julee&display=swap" rel="stylesheet',
    'fontName':'Julee'
  },
  pridi:{
    'link':'https://fonts.googleapis.com/css?family=Pridi&display=swap" rel="stylesheet',
    'fontName':'Pridi'
  },
  mogra:{
    'link':'https://fonts.googleapis.com/css?family=Mogra&display=swap" rel="stylesheet',
    'fontName':'Mogra'
  },
  electrolize:{
    'link':'https://fonts.googleapis.com/css?family=Electrolize&display=swap" rel="stylesheet',
    'fontName':'Electrolize'
  }
}

import { Component, OnInit,Inject, ViewChild, ElementRef  } from '@angular/core';
import { NgForm } from '@angular/forms'
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { MasterSales } from 'src/app/api-services/master.sales';

@Component({
  selector: 'app-digi-sign',
  templateUrl: './digi-sign.component.html',
  styleUrls: ['./digi-sign.component.scss']
})
export class DigiSignComponent implements OnInit {
  @ViewChild('form') form: ElementRef;
  name = 'Set iframe source';
  url: string = "";
  urlSafe: SafeResourceUrl;
  public lang: any;
  public isShowPageLoader: any = false;
  constructor(
    public dialogRef: MatDialogRef<DigiSignComponent>,public dialog: MatDialog,
    private toastr: ToastrService,
    private masterServices: MasterService, private masterSales: MasterSales,public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      if (CommonService.getLang()) {
        this.lang = CommonService.getLang();
      }
      this.url = this.data;
    }
  
  ngOnInit() {

   // this.url = "https://qatvs.truecopy.in/allsign/v1/widgetsign.tc?uuid=QATVS10891_1_87_HOS12121&cs=5AB2974F3C79D3B9&sid=B0C75E806569514C";
    //setTimeout(_ => this.form.nativeElement.submit());
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  cancelDialog() {
    this.dialogRef.close("true");
}
}

import { Component, Inject, OnInit } from '@angular/core';
 
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { PartSearchComponent } from '../part-search/part-search.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-debit-coupon-warranty-claim',
  templateUrl: './debit-coupon-warranty-claim.component.html',
  styleUrls: ['./debit-coupon-warranty-claim.component.scss']
})


export class DebitCouponWarrantyClaimComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any;
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public partRows: any = [];
  public debitnotecouponlist: any = [];
  constructor(
    private dialogRef: MatDialogRef<DebitCouponWarrantyClaimComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      
      
      this.status = [
        {
          name: 'Open',
          value: '1'
        },
        {
          name: 'Closed',
          value: '2'
        },
        {
          name: 'Cancelled',
          value: '3'
        },
        {
          name: 'Allotted',
          value: '4'
        },
        {
          name: 'Invoiced',
          value: '5'
        }
      ];
    }
  }

  ngOnInit() {
    this.inlineFilter = {
      DLR_CPN_CLM_NO: '',
DEALER_CLAIM_DATE:'',
CLAIM_VALUE:'',
status:''
 
        };
  }

  closeDialog() {
    this.dialogRef.close(false);
    this.isShowPageLoader=false;
  }

  // searchList() {
  //     this.getAscCouponClaimPaymentList();
  // }
  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'tvsInvoiceSearch') {
      if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
      this.selected[0].TYPE = this.part.invoiceTypeId;
    }
    let closeObj = { 
      obj: this.selected[0]
       
    };
    this.dialogRef.close(closeObj);
  }

  //grn-tvs invoice popup
  searchList() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Booking From Date is empty');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Booking to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;    
    //reqObj.ASC_ID = CommonService.getDealerData().ASC_ID;
   // reqObj.ASC_WAR_CLAIM_NO = this.part.ClaimREFNO ? Number(this.part.ClaimREFNO) : null;
    reqObj.CLAIM_DATE_FROM = fromDateFormat;
    reqObj.CLAIM_DATE_TO = toDateFormat;
    
    reqObj.DLR_CPN_CLM_ID="";
    reqObj.DLR_CPN_CLM_NO=this.part.ClaimREFNO ? Number(this.part.ClaimREFNO) : null;;
    reqObj.CLAIM_VALUE="";
    reqObj.ClaimREFNO="";
    reqObj.STATUS=0;
    reqObj.DLR_CLAIM_STATUS=0;
    reqObj.SEQ_NO=0;
    reqObj.CREATED_BY="";
    reqObj.MODIFIED_BY="";
    reqObj.CREATE_BY=0;
    reqObj.MODIFY_BY=0;
    reqObj.ACTIVE=true;
    reqObj.FIN_YEAR=CommonService.getDealerData().FIN_YEAR;
    reqObj.OPEN_VALUE=0;
    reqObj.ROW_STATE=0;


    //reqObj.ASCName = this.part.ASCName ? this.part.ASCName : null;
    this.debitnotecouponlist = [];

    if(this.data.creditordebit==1)
    {
      //change this to asc warranty claim
      this.masterServices.post(reqObj, '/Voucher/Searchdebitnotedealercouponlaim').subscribe((resp: any) => {
        if (resp && resp.statusCode == 200) {
  
          console.log("debit dealer COUPON claim",resp)
  
          let reqobj1={}
          // for(var i=0;i<resp.dealercouponclaim.length;i++)
          // {
          //    reqobj1={
          //     ASC_WAR_CLAIM_NO: resp.dealercouponclaim[i].DLR_CPN_CLM_NO,
          //     ASCName:'',
          //     CLAIM_DATE: resp.dealercouponclaim[i].DEALER_CLAIM_DATE,
          //     CLAIM_VALUE:resp.dealercouponclaim[i].CLAIM_VALUE,
          //     ASC_ID: '',
          //     status:resp.dealercouponclaim[i].status
          //   };

          //   this.debitnotecouponlist.push(reqobj1)
          // }

          this.debitnotecouponlist=resp.dealercouponclaim;
          console.log("couponreqobjlist1",resp.dealercouponclaim)
          console.log("couponreqobjlist",this.debitnotecouponlist)
          this.isShowPageLoader = false;
         // this.debitnotecouponlist = resp.dealercouponclaim;
          console.log("dealer warranty claim",this.debitnotecouponlist)
        } else if (resp && resp.statusCode == 204) {
          this.debitnotecouponlist = [];
          this.isShowPageLoader = false;
          return this.toastr.error(resp.statusMessage);
        }
        else {
          this.isShowPageLoader = false;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      })
    }

   

  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

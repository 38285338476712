export class CIC {
  DEALER_ID: number;
  BRANCH_ID: number;
  CIC_ID: number;
  ACTIVE: boolean;
  VEHICLE_ID: number;
  ENQUIRY_ID: number;
  CUST_TY_ID: number;
  CUST_NAME: string;
  OWNER_IS_USER: boolean;
  SON_WIFE_DAU_OF: string;
  Rel_with_EndUser: string;
  ROW_STATE: string;
  FRAME_NO: string;
  CUSTOMER_ID: number;
  END_USER_ID: number;
  NUMBER_KTP: number | null;
  CONTACT_NO: string;
  STD_CODE: string;
  MOBILE_NO: string;
  MOBILE_NO_2: string;
  ADDRESS_LINE_1: string;
  ADDRESS_LINE_2: string;
  ADDRESS_LINE_3: string;
  AREA_ID: number | null;
  CITY: string;
  PIN_CODE: string;
  District: string;
  STATE_ID: string;
  EMAIL_ADDRESS: string;
  GENDER: string;
  Age: number;
  DOB: Date | string;
  MARRIED: boolean;
  MARRIED_DATE: any;
  Comm_Language: number;
  PROFESSION_ID: number | null;
  EDUCATION_ID: number | null;
  INCOME_ID: number | null;
  CIC_BEST_PRICE: number;
  CIC_COMPANYID: number;
  CIC_DOWNPAYMENT: number;
  CIC_EMI: number;
  CIC_EXCH_TYPE: number;
  CIC_FRAME_NO: string;
  CIC_INTEREST_RATE: number;
  CIC_LOAN_AMOUNT: number;
  CIC_OLD_VEHICLE: string;
  CIC_PROD_YEAR: number;
  SALE_MODE: number;
  CIC_TENURE: number;
  OLD_VEHICLE: boolean;
  EXIST_MODEL_ID: string;
  NAME_STNK: string;
  ADDRESS_STNK: string;
  FromPage: string;
  CIC_EXCH_MODEL_ID: string;
  CIC_EXCH_FRAME_NO: string;
  CIC_EXCH_PROD_YEAR: number;
  CIC_EXCH_OFFER_PRICE: number;
  CIC_STATUS: number;
  CIC_SALE_MODE: number;
  BOOKING_TYPE: boolean;
  BOOKING_ID: number;
  BOOKING_NO: number;
  MODEL_ID: string;
  VEHDESCRIPTION: string;
  PART_ID: string;
  PARTDESCRIPTION: string;
  ENGINE_NO: string;
  FIN_YEAR: number;
  SALE_DATE: Date | string;
  VehicleModel: string;
  IS_EV_VEH: boolean;
  USER_NAME: string;
  STD_EXTN: string;
  AADHAR_NO: string;
  PAN_NO: string;
  userID: number;
  AwareList: any;
  CharacterList: any;
}

export class AwareSourceDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  ENQUIRY_ID: number;
  SOURCE_ID: number;
  SOURCE_DESC: string;
  COUNTRY_CODE: string;
  ACTIVE: boolean;
  VEHICLE_ID: number;
}

export class PrefCharactDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  ENQUIRY_ID: number;
  CHARAC_ID: number;
  VEHICLE_ID: number;
}

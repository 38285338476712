import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';


@Component({
  selector: 'app-customise-table',
  templateUrl: './customise-table.component.html',
  styleUrls: ['./customise-table.component.scss']
})
export class CustomiseTableComponent implements OnInit {
  public lang: any;
  @Input() tableName: string;
  @Input() headerObj: any;

  refListArr: any = [];

  constructor(private toastr: ToastrService, public dialogRef: MatDialogRef<CustomiseTableComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.tableName = this.data.tableName;
    this.refListArr = this.data.headerObj;
  }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  applyFilter() {
    let refListLen = this.refListArr.filter(val => {
      return val.model === true;
    });
    if (refListLen.length >= 5) {
      this.dialogRef.close(this.refListArr);
    } else {
      this.toastr.error(this.lang.SERVICES.minFiveCoumn, this.lang.GENERAL.customiseTable);
    }
  }

}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/api-services/common.service';
import { MasterService } from 'src/app/api-services/master.service';
import { InvoiceRootObject, SalesPartInfo, SalesInfo } from 'src/app/api-services/dto/invoice';
import { ActivatedRoute, Router } from '@angular/router';
import { ReceiptVoucherComponent } from '../receipt-voucher/receipt-voucher.component';
import { MobileOtpDialogComponent } from '../mobile-otp-dialog/mobile-otp-dialog.component';
import { ConditionalExpr } from '@angular/compiler';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @Input() invoiceDetail: any;
  @Input() invoiceNo: any;

  public lang: any;
  public sectionArr: Array<any> = [];
  public partHeader: Array<any> = [];
  public taxReportHeader: Array<any> = [];
  public receiptHeader: Array<any> = [];
  public stepper: Array<any> = [];
  public jcCategory = 1;
  public isShowPageLoader: boolean = false;
  public enqId: any;
  public shrmPrice: number;
  public manDisc: number;
  public iscreate: boolean = true;
  public invoiceId: any;
  public totadvanceRecpt: any = 0;
  public totinvoiceRecpt: any = 0;
  public totamtReceived: any = 0;
  public totamtpending: any = 0;
  // public salesInfo = new InvoiceRootObject();
  public prcCost: number;
  public partInfo = new SalesPartInfo();
  public salesInfo = new SalesInfo();
  public masterData: any;
  public partDetails: any = [];
  public receiptDetails: any = [];
  public paymentMode: any = [
    {
      id: 1,
      name: 'Cash'
    },
    {
      id: 4,
      name: 'Cheque'
    },
    {
      id: 6,
      name: 'NEFT / RTGS'
    }
  ];
  public taxVal: number;
  public subTotal: number;
  public finalTotal: number;
  //vehcile info
  public selectedVehicleInfo: any = {};
  public salesEQNO: any;

  constructor(
    private toastr: ToastrService,
    public dialog: MatDialog,
    private masterServices: MasterService,
    public route: ActivatedRoute,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InvoiceComponent>
  ) { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      this.stepper = [
        {
          name: this.lang.SERVICES.open,
          bg: '#03a872',
          isActive: false,
          isActiveLine: false,
          code: -1
        },
        {
          name: this.lang.GENERAL.close,
          bg: '#5a7ad5',
          isActive: false,
          isActiveLine: false,
          code: 0
        },
        {
          name: this.lang.GENERAL.cancel,
          bg: '#a73c9e',
          isActive: false,
          isActiveLine: false,
          code: 1
        }
      ];
      this.sectionArr = [
        {
          id: 1,
          name: this.lang.SALES.basicInfo,
          isActive: true,
          isDisabled: false,
          isActiveStatus: false
        },
        {
          id: 2,
          name: this.lang.SALES.partDetails,
          isActive: false,
          isDisabled: false,
          isActiveStatus: false
        },
        {
          id: 3,
          name: this.lang.SALES.receiptDetails,
          isActive: false,
          isDisabled: false,
          isActiveStatus: false
        },
        {
          id: 4,
          name: this.lang.SALES.crmReedemption,
          isActive: false,
          isDisabled: false,
          isActiveStatus: false
        }];
      this.partHeader = [
        {
          name: this.lang.SERVICES.vehiNo,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SERVICES.partNo,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SALES.partDesc,
          width: 'wmpx-150'
        },
        // {
        //   name: this.lang.SALES.frameNo,
        //   width: 'wmpx-100'
        // },
        {
          name: this.lang.SALES.price,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SALES.schemeDisc,
          width: 'wmpx-150'
        },
        // {
        //   name: this.lang.SALES.manualDisc,
        //   width: 'wmpx-150'
        // },
        {
          name: this.lang.SALES.cgst,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SALES.sgst,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SALES.IGST,
          width: 'wmpx-150'
        }
        // {
        //   name: this.lang.SALES.retroModelPrice,
        //   width: 'wmpx-150'
        // }
      ];
      this.receiptHeader = [
        {
          name: this.lang.PART.voucherNo,
          width: 'wmpx-50'
        },
        {
          name: this.lang.PART.voucherDate,
          width: 'wmpx-150'
        },
        {
          name: this.lang.PART.voucherValue,
          width: 'wmpx-100'
        },
        {
          name: this.lang.PART.voucherType,
          width: 'wmpx-150'
        },
        {
          name: this.lang.PART.paymentMode,
          width: 'wmpx-100'
        },
        {
          name: this.lang.PART.instrumentNo,
          width: 'wmpx-100'
        },
        {
          name: this.lang.PART.instrumentDate,
          width: 'wmpx-150'
        },

        // {
        //   name: this.lang.PART.instrumentAmount,
        //   width: 'wmpx-100'
        // },
        {
          name: this.lang.PART.bankName,
          width: 'wmpx-100'
        },
        // {
        //   name: this.lang.PART.companyCode,
        //   width: 'wmpx-100'
        // },
        {
          name: this.lang.PART.voucherStatus,
          width: 'wmpx-100'
        }
        //{
        //   name: this.lang.PART.adjustedAmount,
        //   width: 'wmpx-100'
        // }, {
        //   name: this.lang.PART.bankReconcilationNo,
        //   width: 'wmpx-100'
        // }, {
        //   name: this.lang.PART.accountReconcilationNo,
        //   width: 'wmpx-100'
        // }
      ];

      if (this.invoiceDetail.salesEnquiryStatus == 4) {
        // this.route.queryParams.subscribe(params => {
        this.enqId = this.data.value.salesEnquiryId;
        // this.enqId = params.salesEQNo;
        if (this.enqId) {
          this.getMasterData(); // getting master datas
        }
        // });
        if (this.invoiceNo) {
          this.getViewDetails();
          this.iscreate = false;
        }
        else {
          this.iscreate = true;
        };

        this.summary();
      }
      if (this.invoiceDetail.salesEnquiryStatus == 2) {
        // this.route.queryParams.subscribe(params => {
        this.enqId = this.data.value.salesEnquiryId;
        // this.enqId = params.salesEQNo;
        if (this.enqId) {
          this.getMasterData();
          // this.summary();
        }
        // });
      }
      // if(!this.invoiceNo){
      //  this.salesInfo.BILL_TYPE = 2;
      //  this.salesInfo.PAYMENT_MODE_ID = 0;
      // }

    }
  }
  financialYears() {
    let finYear;
    let today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      finYear = (today.getFullYear() - 1);
    } else {
      finYear = today.getFullYear();
    }
    this.salesInfo.FIN_YEAR = finYear;
  }
  addReceipt() {
    // let reqData = {
    //   salesENQNO: this.invoiceDetail.enquiryNumber,
    //   vehicleInfo: {
    //     vehicleCost: this.invoiceDetail.advAmount
    //   }
    // }

    this.summary();

    let reqData = {
      'IsInvoice': 1,
      'vehicleCost': this.totamtpending,
      'dmsType': 'UVDReceipt',
      'value': {
        'salesENQNO': this.invoiceDetail.enquiryNumber,
        'userInfo': {
          'customerId': this.salesInfo.CUSTOMER_ID,
          'customerName': this.salesInfo.CUST_NAME
        },
      },
    }

    const dialogRef = this.dialog.open(ReceiptVoucherComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'paymentPopUpData',
      disableClose: true,
      data: reqData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);

        this.getViewDetails();
      } else {
        console.log('The dialog was closed');
      }
    });

  }
  getMasterData() {
    // this.enqId = 124;
    this.masterServices.post('', '/Sales/GetSalesInvoiceInitialInfo?dealerId='
      + CommonService.getUserData().DEALER_ID + '&branchId='
      + CommonService.getUserData().BRANCH_ID + '&enquiryid=' + this.enqId).subscribe((resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.masterData = resp;
          this.salesInfo = resp.SalesInfo;
          this.salesInfo.COMPANY_ID = this.masterData.companyList.length > 0 ? this.masterData.companyList[0].companyID : ''
          this.salesInfo.PAYMENT_MODE_ID = 0;
          this.salesInfo.BILL_TYPE = 2;
          this.salesInfo.INVOICE_DATE = new Date();
          this.partDetails = resp.SalesInfo.SalesPartInfo && resp.SalesInfo.SalesPartInfo.length > 0 ? resp.SalesInfo.SalesPartInfo : [];
          // this.salesInfo.VEH_SAL_ENQ_ID = this.invoiceDetail.enquaryNumber;

          this.receiptDetails = resp.SalesInfo.ReceiptInfo && resp.SalesInfo.ReceiptInfo.length > 0 ? resp.SalesInfo.ReceiptInfo : [];
          this.salesInfo.VEH_SAL_ENQ_ID = this.enqId;
          this.financialYears();
          this.summary();
        }
      },
        error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        }
      );
  }
  getViewDetails() {
    this.isShowPageLoader = true;
    this.totadvanceRecpt = 0;
    this.totinvoiceRecpt = 0;
    this.totamtReceived = 0;
    this.totamtpending = 0;
    this.masterServices.post('', '/Sales/GetSalesInvoice?dealerId='
      + CommonService.getUserData().DEALER_ID + '&branchId='
      + CommonService.getUserData().BRANCH_ID + '&invoiceid=' + this.invoiceNo).subscribe((resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.salesInfo = resp.SalesInfo;
          this.salesInfo.BILL_TYPE = resp.SalesInfo.BILL_TYPE;
          this.isShowPageLoader = false;
          this.partDetails = resp.SalesInfo && resp.SalesInfo.SalesPartInfo.length > 0 ? resp.SalesInfo.SalesPartInfo : [];

          this.receiptDetails = resp.SalesInfo.ReceiptInfo && resp.SalesInfo.ReceiptInfo.length > 0 ? resp.SalesInfo.ReceiptInfo : [];
          if (resp.SalesInfo && resp.SalesInfo.ReceiptInfo)
            resp.SalesInfo.ReceiptInfo.filter(key => {
              if (key.VOUCHER_TYPE == 'UVD Booking Advance') {

                this.totadvanceRecpt += key.VOUCHER_VALUE;
              }
              if (key.VOUCHER_TYPE == 'UVD Invoice Receipt') {
                this.totinvoiceRecpt += key.VOUCHER_VALUE;
              }
            });
          this.totamtReceived = this.totadvanceRecpt + this.totinvoiceRecpt;
          this.totamtpending = this.finalTotal - this.totamtReceived;
          this.summary();
        }

      },
        error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        }
      );
    this.totamtReceived = this.totadvanceRecpt + this.totinvoiceRecpt;
    this.totamtpending = this.finalTotal - this.totamtReceived;
    // this.summary();

  }
  selectedMatTab(event) {
    if (event.index == 4) {
      this.summary();
    }

  }
  summary() {

    // setTimeout(() => {
      let total: any;
      let tax: any;
      if (this.partDetails.length > 0) {
        total = this.partDetails[0].EX_SHRM_PRICE;

      
        if (this.partDetails[0].CGST_PERC && this.partDetails[0].CGST_PERC) {
          // this.taxVal = (this.partDetails[0].CGST) + (this.partDetails[0].SGST);
          this.shrmPrice = this.partDetails[0].EX_SHRM_PRICE - this.partDetails[0].MANUAL_DISC - (this.salesInfo.DISC_VALUE ? this.salesInfo.DISC_VALUE : 0);
          this.taxVal = Number(Number(this.shrmPrice) - Number(this.salesInfo.ProcurementCost));
          console.log('this.taxVal -',this.taxVal);
          let totalTaxPer = (Number(this.partDetails[0].CGST_PERC) + Number(this.partDetails[0].SGST_PERC));
          let CalTax = Number(totalTaxPer) > 0 ? Number(this.taxVal * totalTaxPer / (100 + totalTaxPer)) : 0;
          let SepCalTax = CalTax / 2;

          //  if(Number(this.taxVal)> 0){
          this.partDetails[0].CGST = Number(Number(SepCalTax).toFixed(2));
          this.partDetails[0].SGST = Number(Number(SepCalTax).toFixed(2));

          this.taxVal = Number(this.partDetails[0].CGST + this.partDetails[0].SGST);
          //  }
          debugger
          console.log(this.partDetails[0].CGST, this.partDetails[0].SGST)
        }
        else {
          if(this.partDetails[0].IGST_PERC){

            this.shrmPrice = this.partDetails[0].EX_SHRM_PRICE - this.partDetails[0].MANUAL_DISC - (this.salesInfo.DISC_VALUE ? this.salesInfo.DISC_VALUE : 0);
            this.taxVal = Number(Number(this.shrmPrice) - Number(this.salesInfo.ProcurementCost))
            let totalTaxPer = Number(this.partDetails[0].IGST_PERC);
            this.taxVal = Number(totalTaxPer) > 0 ? Number(Number(this.taxVal * totalTaxPer / (100 + totalTaxPer)).toFixed(2)) : 0;
            //  if(Number(this.taxVal)> 0){
            this.partDetails[0].IGST = this.taxVal;
          }
        }
        this.shrmPrice = this.partDetails[0].EX_SHRM_PRICE;
        this.prcCost = this.salesInfo.ProcurementCost;
        // this.taxVal = this.shrmPrice - this.prcCost;
        this.taxVal = this.partDetails[0].CGST + this.partDetails[0].SGST + this.partDetails[0].IGST + this.partDetails[0].UTGST;
        this.manDisc = this.partDetails[0].MANUAL_DISC;
        this.subTotal = this.shrmPrice - this.manDisc;
        this.finalTotal = this.subTotal - (this.salesInfo.DISC_VALUE ? this.salesInfo.DISC_VALUE : 0);
        if (this.invoiceDetail.advAmount) {
          if (this.totamtReceived == 0) {
            this.totamtReceived = this.totamtReceived + this.invoiceDetail.advAmount;
          }
          if (this.totadvanceRecpt == 0) {
            this.totadvanceRecpt = this.invoiceDetail.advAmount;
          }

        }
        if (this.totamtReceived) {
          this.totamtpending = this.finalTotal - this.totamtReceived;
        }

      }
    // }, 500);
  }
  validate() {
    let invoiceError = 0;
    if (!this.salesInfo.FIN_YEAR) {
      this.toastr.error('Please add finanical year');
      invoiceError++;
    }

    if (!this.salesInfo.PAYMENT_MODE_ID && this.salesInfo.BILL_TYPE == 1) {
      this.toastr.error('Please enter payment mode');
      invoiceError++;
    }
    if (!this.salesInfo.BILL_TYPE
      || this.salesInfo.BILL_TYPE == 0
      || this.salesInfo.BILL_TYPE == null
      || this.salesInfo.BILL_TYPE == undefined) {
      this.toastr.error('Please select bill type');
      invoiceError++;
    }
    if (!this.salesInfo.VEH_SAL_ENQ_ID) {
      this.toastr.error('Booking No is empty');
      invoiceError++;
    }
    if (!this.salesInfo.CUSTOMER_ID) {
      this.toastr.error('Customer id is empty');
      invoiceError++;
    }
    // if (!this.salesInfo.REFERAL_ID) {
    //   this.toastr.error('Referal id is empty');
    //   invoiceError++;
    // }
    // if (!this.salesInfo.DISC_VALUE) {
    //   this.toastr.error('Discount  value is empty');
    //   invoiceError++;
    // }
    // if (!this.salesInfo.TOT_AMT) {
    //   this.toastr.error('Total amount is is empty');
    //   invoiceError++;
    // }
    if ((this.salesInfo.HP_UNRECON_VAL == 0 ||
      !this.salesInfo.HP_UNRECON_VAL ||
      this.salesInfo.HP_UNRECON_VAL == null ||
      this.salesInfo.HP_UNRECON_VAL == undefined) && this.invoiceDetail.hpRequire == '1') {
      this.toastr.error('HPA value is empty ');
      invoiceError++;
    }
    // if (!this.salesInfo.ROUND_OFF) {
    //   this.toastr.error('Round of value is empty');
    //   invoiceError++;
    // }
    if (!this.salesInfo.DELIVERED_BY || this.salesInfo.DELIVERED_BY == -1) {
      this.toastr.error('Deliverd By is empty');
      invoiceError++;
    }
    // if (!this.salesInfo.REMARKS) {
    //   this.toastr.error('Remarks is empty');
    //   invoiceError++;
    // }
    if (this.partDetails.length == 0) {
      this.toastr.error('Part details is empty');
      invoiceError++;
    }
    if (invoiceError > 0) {
      return false;
    } else {
      return true;
    }
  }


  sendOTP() {
    console.log(this.partDetails.length,  this.partDetails[0].EX_SHRM_PRICE);
    if (this.partDetails.length > 0 && this.partDetails[0].EX_SHRM_PRICE <= 0) {
      this.toastr.warning(this.lang.Message.JOBCARD_INV.INVALID_INVVALUE)
      return;
    }
    if (this.validate() == false) {
      return;
    }
    let customerDetails = JSON.parse(JSON.stringify(this.invoiceDetail));
    let objReq: any = {};
    if (customerDetails.mobileNumber) {
      objReq.dealerId = CommonService.getUserData().DEALER_ID;
      objReq.branchId = CommonService.getUserData().BRANCH_ID;
      objReq.customerId = customerDetails.customerTypeId;
      objReq.customerName = customerDetails.customerName;
      objReq.documentId = this.salesInfo.ENQUIRY_NO;
      objReq.frameNo = this.salesInfo.SalesPartInfo[0].FRAME_NO;
      objReq.invoiceType = 1;
      objReq.mobileNo = customerDetails.mobileNumber;
      objReq.smsDate = new Date();
      objReq.dealerName = CommonService.getDealerData().DEALERSHIP_NAME;
      objReq.modelName = customerDetails.modelName;
    }
    this.masterServices.post(objReq, '/Sales/InvoiceOTPCreation').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.toastr.success('OTP sent to your mobile number');
          this.isShowPageLoader = false;
        } else {
          this.toastr.info(resp.statusMessage);
          this.isShowPageLoader = false;
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }
  //mobileotp popup
  //otp popup
  // mobileOtp(objReq) {
  //   this.masterServices.post(objReq, '/Sales/InvoiceOTPCreation').subscribe(
  //     (resp: any) => {
  //       if (resp && resp.statusCode == 200) {
  //         this.toastr.success('OTP sent to your mobile number');
  //         const dialogRef = this.dialog.open(MobileOtpDialogComponent, {
  //           width: '25%',
  //           height: 'auto',
  //           disableClose: true,
  //           data: {
  //             customerDetails: objReq,
  //             type: 'SALES'
  //           }
  //         });
  //         dialogRef.afterClosed().subscribe(result => {
  //           if (result && result.obj.statusCode == 200) {
  //             console.log("this.salesInfo:::", this.salesInfo);
  //             let reqInvoice: any = {};
  //             reqInvoice.partinfo = {};
  //             reqInvoice.partinfo.Taxinfo = {};
  //             reqInvoice.DEALER_ID = CommonService.getUserData().DEALER_ID;
  //             reqInvoice.COMPANY_ID = this.salesInfo.COMPANY_ID.toString();
  //             reqInvoice.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
  //             reqInvoice.FIN_YEAR = this.salesInfo.FIN_YEAR.toString();
  //             reqInvoice.PAYMENT_MODE_ID = this.salesInfo.PAYMENT_MODE_ID;
  //             reqInvoice.INVOICE_DATE = new Date();
  //             reqInvoice.BILL_TYPE = Number(this.salesInfo.BILL_TYPE);
  //             reqInvoice.VEH_SAL_ENQ_ID = this.salesInfo.VEH_SAL_ENQ_ID.toString();
  //             reqInvoice.CUSTOMER_ID = this.salesInfo.CUSTOMER_ID.toString();
  //             reqInvoice.REFERAL_ID = this.salesInfo.REFERAL_ID.toString();
  //             reqInvoice.END_USER_ID = this.salesInfo.CUSTOMER_ID.toString();
  //             reqInvoice.DISC_VALUE = this.salesInfo.DISC_VALUE ? this.salesInfo.DISC_VALUE.toString() : 0;
  //             reqInvoice.TOT_AMT = this.finalTotal;
  //             reqInvoice.HP_UNRECON_VAL = this.salesInfo.HP_UNRECON_VAL.toString();
  //             reqInvoice.ROUND_OFF = this.salesInfo.ROUND_OFF.toString();
  //             reqInvoice.DELIVERED_BY = this.salesInfo.DELIVERED_BY ? this.salesInfo.DELIVERED_BY.toString() : '';
  //             reqInvoice.REMARKS = this.salesInfo.REMARKS ? this.salesInfo.REMARKS.toString() : '';
  //             reqInvoice.USER_ID = CommonService.getUserData().USER_ID.toString();
  //             reqInvoice.partinfo.VEHICLE_ID = this.partDetails.length > 0 ? this.partDetails[0].VEHICLE_ID.toString() : '';
  //             reqInvoice.partinfo.PART_ID = this.partDetails.length > 0 ? this.partDetails[0].PART_ID.toString() : '';
  //             reqInvoice.partinfo.QTY = this.partDetails.length > 0 ? this.partDetails[0].QTY.toString() : '';
  //             reqInvoice.partinfo.MANUAL_DISC = this.partDetails.length > 0 ? this.partDetails[0].MANUAL_DISC.toString() : '';
  //             reqInvoice.partinfo.Taxinfo.CGST = this.partDetails.length > 0 ? this.partDetails[0].CGST.toString() : '';
  //             reqInvoice.partinfo.Taxinfo.SGST = this.partDetails.length > 0 ? this.partDetails[0].SGST.toString() : '';
  //             reqInvoice.partinfo.Taxinfo.IGST = this.partDetails.length > 0 ? this.partDetails[0].IGST.toString() : '';
  //             reqInvoice.partinfo.Taxinfo.UTGST = this.partDetails.length > 0 ? this.partDetails[0].UTGST.toString() : '';
  //             reqInvoice.partinfo.Taxinfo.CGST_PERC = this.partDetails.length > 0 ? this.partDetails[0].CGST_PERC.toString() : 0;
  //             reqInvoice.partinfo.Taxinfo.SGST_PERC = this.partDetails.length > 0 ? this.partDetails[0].SGST_PERC.toString() : 0;
  //             reqInvoice.partinfo.Taxinfo.IGST_PERC = this.partDetails.length > 0 ? this.partDetails[0].IGST_PERC.toString() : 0;
  //             reqInvoice.partinfo.Taxinfo.UTGST_PERC = this.partDetails.length > 0 ? this.partDetails[0].UTGST_PERC.toString() : 0;
  //             this.masterServices.post(reqInvoice, '/Sales/createSalesInvoice').subscribe((resp: any) => {
  //               if (resp) {
  //                 this.toastr.success(resp.statusMessage);
  //                 this.goBack();
  //               }
  //             },
  //               error => {
  //                 this.isShowPageLoader = false;
  //                 this.toastr.error(error.error.statusMessage);
  //               }
  //             );

  //           } else {
  //             return;
  //           }
  //         });
  //       } else {
  //         this.toastr.success(resp.statusMessage);
  //       }
  //     },
  //     error => {
  //       this.isShowPageLoader = false;
  //       this.toastr.error(error.statusMessage);
  //     }
  //   )
  // }

  completeSales() {
    let completeObj = {
      "dealerId": CommonService.getUserData().DEALER_ID,
      "branchId": CommonService.getUserData().BRANCH_ID,
      "salesEnquiryNumber": this.salesInfo.VEH_SAL_ENQ_ID.toString(),
      "vehicleId": this.partDetails[0].VEHICLE_ID.toString(),
      "userId": CommonService.getUserData().USER_ID
    }
    this.masterServices.post(completeObj, '/Sales/completeStatus').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.toastr.success(resp.statusMessage);
        } else {
          this.isShowPageLoader = false;
          this.toastr.warning(resp.statusMessage)
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }

  saveInvoice() {
    // if (this.partDetails.length > 0 && this.partDetails[0].EX_SHRM_PRICE <= 0) {
    //   this.toastr.warning(this.lang.Message.JOBCARD_INV.INVALID_INVVALUE)
    //   return;
    // }
    // if (this.validate() == false) {
    //   return;
    // }
    // //mobileotp
    // let customerDetails = JSON.parse(JSON.stringify(this.invoiceDetail));
    // if (customerDetails.mobileNumber) {
    //   let objReq: any = {};
    //   objReq.dealerId = CommonService.getUserData().DEALER_ID;
    //   objReq.branchId = CommonService.getUserData().BRANCH_ID;
    //   objReq.customerId = customerDetails.customerTypeId;
    //   objReq.customerName = customerDetails.customerName;
    //   objReq.documentId = this.salesInfo.ENQUIRY_NO;
    //   objReq.frameNo = this.salesInfo.SalesPartInfo[0].FRAME_NO;
    //   objReq.invoiceType = 1;
    //   objReq.mobileNo = customerDetails.mobileNumber;
    //   objReq.smsDate = new Date();
    //   objReq.dealerName = CommonService.getDealerData().DEALERSHIP_NAME;
    //   objReq.modelName = customerDetails.modelName;
    //   this.mobileOtp(objReq);
    // }

    if (this.salesInfo.DELIVERED_BY == null || this.salesInfo.DELIVERED_BY == undefined ||
      this.salesInfo.DELIVERED_BY == 0) {
      this.isShowPageLoader = false;
      this.toastr.info("Please Enter Delivered By");
      return;
    }
    console.log("this.salesInfo:::", this.salesInfo);
    let reqInvoice: any = {};
    reqInvoice.partinfo = {};
    reqInvoice.partinfo.Taxinfo = {};
    reqInvoice.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reqInvoice.COMPANY_ID = this.salesInfo.COMPANY_ID.toString();
    reqInvoice.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reqInvoice.FIN_YEAR = this.salesInfo.FIN_YEAR.toString();
    reqInvoice.PAYMENT_MODE_ID = this.salesInfo.PAYMENT_MODE_ID;
    reqInvoice.INVOICE_DATE = new Date();
    reqInvoice.BILL_TYPE = Number(this.salesInfo.BILL_TYPE);
    reqInvoice.VEH_SAL_ENQ_ID = this.salesInfo.VEH_SAL_ENQ_ID.toString();
    reqInvoice.CUSTOMER_ID = this.salesInfo.CUSTOMER_ID.toString();
    reqInvoice.REFERAL_ID = this.salesInfo.REFERAL_ID.toString();
    reqInvoice.END_USER_ID = this.salesInfo.CUSTOMER_ID.toString();
    reqInvoice.DISC_VALUE = this.salesInfo.DISC_VALUE ? this.salesInfo.DISC_VALUE.toString() : 0;
    reqInvoice.TOT_AMT = this.finalTotal;
    reqInvoice.HP_UNRECON_VAL = this.salesInfo.HP_UNRECON_VAL.toString();
    reqInvoice.ROUND_OFF = this.salesInfo.ROUND_OFF.toString();
    reqInvoice.DELIVERED_BY = this.salesInfo.DELIVERED_BY ? this.salesInfo.DELIVERED_BY.toString() : '';
    reqInvoice.REMARKS = this.salesInfo.REMARKS ? this.salesInfo.REMARKS.toString() : '';
    reqInvoice.USER_ID = CommonService.getUserData().USER_ID.toString();
    reqInvoice.partinfo.VEHICLE_ID = this.partDetails.length > 0 ? this.partDetails[0].VEHICLE_ID.toString() : '';
    reqInvoice.partinfo.PART_ID = this.partDetails.length > 0 ? this.partDetails[0].PART_ID.toString() : '';
    reqInvoice.partinfo.QTY = this.partDetails.length > 0 ? this.partDetails[0].QTY.toString() : '';
    reqInvoice.partinfo.MANUAL_DISC = this.partDetails.length > 0 ? this.partDetails[0].MANUAL_DISC.toString() : '0';
    reqInvoice.partinfo.Taxinfo.CGST = this.partDetails.length > 0 ? this.partDetails[0].CGST.toString() : '';
    reqInvoice.partinfo.Taxinfo.SGST = this.partDetails.length > 0 ? this.partDetails[0].SGST.toString() : '';
    reqInvoice.partinfo.Taxinfo.IGST = this.partDetails.length > 0 ? this.partDetails[0].IGST.toString() : '';
    reqInvoice.partinfo.Taxinfo.UTGST = this.partDetails.length > 0 ? this.partDetails[0].UTGST.toString() : '';
    reqInvoice.partinfo.Taxinfo.CGST_PERC = this.partDetails.length > 0 ? this.partDetails[0].CGST_PERC.toString() : 0;
    reqInvoice.partinfo.Taxinfo.SGST_PERC = this.partDetails.length > 0 ? this.partDetails[0].SGST_PERC.toString() : 0;
    reqInvoice.partinfo.Taxinfo.IGST_PERC = this.partDetails.length > 0 ? this.partDetails[0].IGST_PERC.toString() : 0;
    reqInvoice.partinfo.Taxinfo.UTGST_PERC = this.partDetails.length > 0 ? this.partDetails[0].UTGST_PERC.toString() : 0;
    reqInvoice.SMS_OTP = '1234';//this.salesInfo.MOBILE_OTP.toString();
    // console.log("reqInvoice:::", reqInvoice);
    // for( const key in reqInvoice) {
    //   if(reqInvoice[key] =='') {
    //     delete reqInvoice[key];
    //   }
    // }

    
    this.masterServices.post(reqInvoice, '/Sales/createSalesInvoice').subscribe((resp: any) => {
      if (resp) {
        this.toastr.success(resp.statusMessage);
        this.goBack();
      }
    },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.statusMessage);
      }
    );
  }
  goBack() {
    // this.router.navigate(['/uvd/uvd-sales']);
    this.dialogRef.close();
  }
  print() {
    let printObj: any = {};
    this.isShowPageLoader = true;
    this.masterServices.get(printObj, '/Sales/PrintSalesInvoice?DEALER_ID='
      + CommonService.getUserData().DEALER_ID + '&BRANCH_ID=' + CommonService.getUserData().BRANCH_ID + '&INVOICE_ID=' +
      this.invoiceDetail.invoiceId).subscribe(
        (resp: any) => {
          if (resp && resp.statusMessage) {
            this.isShowPageLoader = false;
            CommonService.printData(resp.printString);
            // this.toastr.success(resp.statusMessage);
          }
        },
        error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        }
      )
  }

}

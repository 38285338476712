/**
 * Created BY HMSPL 
 * Login Service Class
 * Last Last Modified By Balaji on 10/10/2018
 * Last Modified Description : Created login related services
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';
import { LogoutDialogComponent } from '../shared/nav-bar/nav-bar.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from './common.service';


@Injectable({
    providedIn: 'root'
})
export class LoginService {
    lang: any;
    constructor(private httpClient: HttpClient,public dialog: MatDialog,public toastr:ToastrService,public router:Router) {
        this.lang = CommonService.getLang();
    }
    /**
     * Get Dealer Branch Name
     * @param {Object} request
     * return Object
     */
    getDealerBranchName(reqParans) {
        const resData = RequestService.getReq('/Menu/LoadBranch2?' + reqParans);
        return this.httpClient.get(resData.url, {
            headers: resData.headers
        });
    }

    getDealerBranchNameApi(request) {
        const resData = RequestService.postApiURLReq('/Menu/LoadBranch2');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    /**
     * Login user
     * @param {Object} request
     * return Object
     */
    login(request,url) {
        const resData = RequestService.loginpostReq(url);
       return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    loginWebApi(request,url) {
        const resData = RequestService.loginWebApiReq(url);
       return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
   
    token(request,url) { 
      const resData = RequestService.tokenReq(url);
     return this.httpClient.post(resData.url, request, { headers: resData.headers });
  }
  tokenGenerationServiceSales(request,url) {
    const resData = RequestService.tokenReqServiceSales(url);
   return this.httpClient.post(resData.url, request, { headers: resData.headers });
}
    logout() {
            this.toastr.error("Authentication Failed");
            sessionStorage.removeItem('authToken');
          
            this.router.navigate(['/session/dealer']);
            localStorage.clear();
      }
      logout1() {
       
        sessionStorage.removeItem('authToken');
        this.router.navigate(['/session/dealer']);
        localStorage.clear();
  }
}

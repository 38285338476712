import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-company-details',
  templateUrl: './add-company-details.component.html',
  styleUrls: ['./add-company-details.component.scss']
})
export class AddCompanyDetailsComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public companyData = {
    "COMPANY_ID": '',
    "COMPANY_NAME": '',
    "ACTIVE": false,
    "FIN_YR_CLOSE_ALLWD": false,
  };
  public companyList = [{
    "DEALER_ID": CommonService.getUserData().DEALER_ID,
    "COMPANY_ID": '',
    "COMPANY_NAME": '',
    "ACTIVE": false,
    "FIN_YR_CLOSE_ALLWD": false,
    "ROW_STATE": 0
  }];
  constructor(private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCompanyDetailsComponent>) { }

  ngOnInit() {
    this.data.value
    this.getCompanyDetails();
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  closePopup() {
    this.dialogRef.close();
  }

  getCompanyDetails() {
    this.isShowPageLoader = true;
    let userData = JSON.parse(localStorage.getItem('userData'));
    this.masterService.get('', '/DMSMaster/CompanyList?DealerId=' +
      CommonService.getUserData().DEALER_ID).subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.masterData = resp.companyList;
          }
          if (resp.statusCode == 204) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        });
  }

  addCompanyDetails() {
    this.isShowPageLoader = true;
    if (this.companyData.COMPANY_ID == '') {
      this.isShowPageLoader = false;
      this.toastr.info("Choose One Company ID");
      return;
    }
    this.masterData.filter(key=>{
      if(key.COMPANY_ID == this.companyData.COMPANY_ID){
        this.companyData.COMPANY_NAME = key.COMPANY_NAME
      }
    });
    this.companyList[0].ACTIVE = this.companyData.ACTIVE;
    this.companyList[0].COMPANY_ID = this.companyData.COMPANY_ID;
    this.companyList[0].COMPANY_NAME = this.companyData.COMPANY_NAME;
    this.companyList[0].FIN_YR_CLOSE_ALLWD = this.companyData.FIN_YR_CLOSE_ALLWD;
    this.dialogRef.close(this.companyList);
    this.isShowPageLoader = false;
  }

}


export class ComplaintList {
    DEALER_ID: number;
    BRANCH_ID: number;
    JOB_COMPLAINT_ID: number;
    JobCardId: number;
    CUST_VOICE: string;
    OBSRV_GRP_ID: number;
    OBSRV_ID: number;
    OBSERVATION: string;
    COMPL_GRP_ID: any;
    COMPLAINT_ID: any;
    ACTION_ID: number;
    STATUS: number;
    COMP_GRP_LINK_ID: number;
    IS_MANDATORY: string;
    IS_REWORK: string;
    IS_RECALL_REFIT: string;
    HO_HIGHLIGHT: string;
    uniqId: number;
    ObservationGroupDet: ObservationGroupDet;
    ObservationDetails: ObservationDetails;
    ComplaintDetails: ComplaintDetails;
    ComplaintGroupDet: ComplaintGroupDet;
    complaintArr: any[];
}

export class ObservationGroupDet {
    OBSRV_GRP_ID: number;
}

export class ObservationDetails {
    OBSRV_ID: number;
}

export class ComplaintDetails {
    COMPLAINT_ID: number;
}
export class ComplaintGroupDet {
    COMPL_GRP_ID: any;
}

export class JobCard {
    DEALER_ID: number;
    BRANCH_ID: number;
    CREATED_BY: number;
    STATUS: number;
    MODIFIED_BY: number;
    JOB_CARD_ID: number;
    JOB_CARD_NO: number;
    PREV_JOB_TYPE_ID: number;
    FSC_OTP: string;
    IS_MASTER_MOBILE: boolean;
    TICKET_NO: string;
    ACTIVE: boolean;
    JOB_CARD_DATE: any;
    JOB_TYPE_ID: number;
    SERV_MODE_ID: number;
    PAYMENT_MODE_ID: number;
    CARE_CAMP_SCH_ID: any;
    CARE_CAMP_DESC: any;
    KILOMETERS: number;
    PETROL_LEVEL: number;
    DLR_REF_NO: string;
    VULCROVE_NO: string;
    CPN_REC: boolean;
    EXT_WAR_APPL: boolean;
    LOCAL_AMC: number;
    REGIS_NO: string;
    FRAME_NO: string;
    IS_CWI_EXT_WARRANTY: boolean;
    REMARKS: string;
    CUSTOMER_ID: number;
    END_USER_ID: number;
    SERV_REM_RECV: boolean;
    CC_REM_RECV: boolean;
    IS_MTBF: boolean;
    IS_RECALL_REFIT: boolean;
    EST_AMT: number;
    JOB_START_TIME: any;
    JOB_END_TIME: any;
    DEL_PROM_TIME: any;
    CUST_REQ_DEL_TIME: any;
    EST_DEL_TIME: any;
    VEHICLE_IN_TIME: any;
    VEHICLE_OUT_TIME: any;
    BAY_ID: string;
    MECH_ID: string;
    TEAMLEADER_ID: string;
    WATERWASH_EMP_ID: string;
    APPOINTMENT_ID: any;
    VEHICLE_ID: number;
    ISVEH_CAME_TO_SAMEDLR_FORJC: boolean;
    POLICY_NO: string;
    AMC_TYPE: number;
    WARRANTY_BOOKELET_NO: string;
    CWI_WARR_TILL_DATE: string;
    SERVICE_APPLICABLE: boolean;
    PICKUP_DROP: boolean;
    WATER_WASH: boolean;
    CHAIN_OVER_ROLL: boolean;
    CO_CHECK: boolean;
    KEY_NO: string;
    ROAD_TEST_BY: number;
    AMC_VEH_ID: number;
    OIL: number;
    CO: number;
    RPM: number;
    ROW_STATE: string;
    ZMCACTIVE: boolean;
    ZMCVALIDFROM: string;
    ZMCVALIDTO: string;
    ComplaintList: ComplaintList[];
    AMC_ID: number;
    JOB_CARD_EXISTS: any;
    SourceType: any;
    JOB_CAT_ID:any;
    // Code Changed By Anil- START

    stdCheckDOList:StandardCheckDO[];
    //Code Changed By Anil- END
}

export class Customer {
    DEALER_ID: number;
    BRANCH_ID: number;
    CUSTOMER_ID: number;
    CUST_TY_ID: number;
    CUST_GRP_ID: number;
    EMAIL_ADDRESS: string;
    DATE_OF_BIRTH: any;
}

export class CommnAddressPhone {
    CONTACT_NO: string;
    CONTACT_NO_2: string;
    PHONE_TYPE: number;
    CUSTOMER_ID: number;
    IS_VALID: boolean;
}

export class Mobile {
    CONTACT_NO: string;
    CONTACT_NO_2: string;
    PHONE_TYPE: number;
    CUSTOMER_ID: number;
    IS_VALID: boolean;
}

export class EndUser {
    CUSTOMER_ID: number;
    EMAIL_ADDRESS: string;
    DATE_OF_BIRTH: string;
}

export class CommnAddressPhoneEndUser {
    CONTACT_NO: string;
    CONTACT_NO_2: string;
    PHONE_TYPE: number;
    CUSTOMER_ID: number;
    IS_VALID: boolean;
}

export class MobileEndUser {
    CONTACT_NO: string;
    CONTACT_NO_2: string;
    PHONE_TYPE: number;
    CUSTOMER_ID: number;
    IS_VALID: boolean;
}

export class VehicleDetails {
    VEHICLE_ID: number;
    FRAME_NO: string;
    REG_NO: string;
    Service_Booklet_No: string;
    MODEL_ID: string;
    WARRANTY_BOOKELET_NO: string;
    POLICY_NO: string;
    CWI_WARR_TILL_DATE: string;
    SALE_DATE: string;
    AMC_TYPE: number;
    CATEGORY_ID:string;
}

export class InsuranceDetails {
    DEALER_ID: number;
    BRANCH_ID: number;
    INS_HAND_BY: boolean;
    INS_COMP_ID: number;
    INS_POLICY_NO: string;
    INS_POLICY_DT: string;
    INS_VALID_TILL: string;
    MAX_INS_AMT: number;
    ORGN_EST_AMT: number;
    ACC_CLAIM_AMT: number;
    APP_EST_AMT: number;
    SURVEY_BY: string;
    SURVEY_ON: string;
    GST_NO: string;

}

export class EstimationDetails {
    DEALER_ID: number;
    BRANCH_ID: number;
    CREATED_ON: string;
    CREATED_BY: number;
    TOT_LAB_EST_AMT: number;
    TOT_SPARES_EST_AMT: number;
    MAX_INS_AMT: number;
    ORGN_EST_AMT: number;
    ACC_CLAIM_AMT: number;
    APP_EST_AMT: number;
}

export class PayerDetails {
    DEALER_ID: number;
    BRANCH_ID: number;
    PAYER_ID: number;
    PAYER_NAME: string;
    ADDRESS: string;
    PHONE_NO: string;
    MOBILE_NO: string;
    REMARKS: string;
    PAYMENT_MODE_ID: number;
}

export class JCAMC {
    SERVICE_APPLICABLE: boolean;
    PICKUP_DROP: boolean;
    WATER_WASH: boolean;
    CHAIN_OVER_ROLL: boolean;
    CO_CHECK: boolean;
}

export class ZMC {
    ACTIVE: boolean;
    VALID_FROM: string;
    VALID_TO: string;
}

export class JCRootObject {
    JobCardDO: JobCard;
    Customer: Customer;
    CommnAddressPhone: CommnAddressPhone;
    Mobile: Mobile;
    EndUser: EndUser;
    CommnAddressPhoneEndUser: CommnAddressPhoneEndUser;
    MobileEndUser: MobileEndUser;
    VehicleDetails: VehicleDetails;
    InsuranceDetails: InsuranceDetails;
    EstimationDetails: EstimationDetails;
    PayerDetails: PayerDetails;
    JCAMC: JCAMC;
    ZMC: ZMC;
    _Static: any;
    StJobCard:any;
    CountryCode: String;
    SourceType: any;
    UserId: String;
}

export class LabourEstList {
    DEALER_ID: number;
    BRANCH_ID: number;
    JobCardCostEstId: any;
    LAB_EST_ID: number;
    LABOUR_ID: number;
    QUANTITY: number;
    MODE_ID: number;
    LABOUR_RATE: number;
    TAX: number;
    CGST: number;
    SGST: number;
    IGST: number;
    UGST: number;
    CESS: number;
    ROW_STATE: string;
    IsDeleted: string;
    DESCRIPTION: string;
    LabourCatDesc: string;
    LABOUR_CHARGES: number;
    HSN_CODE: string;
    IS_DELETED: boolean;
}
export class PartEstList {
    BRANCH_ID: number;
    SPARE_EST_ID: number;
    SPARE_PART_ID: number;
    PART_NO: string;
    QUANTITY: number;
    MODE_ID: number;
    PART_CHARGE: number;
    TAX: number;
    UNIT_PRICE: number;
    UNIT_VALUE: number;
    TAXABLE: boolean;
    MANUF_ID: number;
    BLC_QTY: number;
    CGST: number;
    SGST: number;
    HSN_CODE: number;
    IGST: number;
    UGST: number;
    CESS: number;
    ROW_STATE: string;
    DESCRIPTION: string;
    TYPE: string;
    JobCardCostEstId: any;
    IS_DELETED: string;
    IsDeleted: boolean;
}
export class JobEstimation {
    JOB_CARD_ID: number;
    ROW_STATE: string;
    DEALER_ID: number;
    BRANCH_ID: number;
    EST_DATE: string;
    MODIFIED_BY: number;
    MODIFIED_ON: string;
    EST_TYPE: boolean;
    SERV_MARGIN_ID: string;
    TOT_SPARES_EST_AMT: number;
    TOT_LAB_EST_AMT: number;
    SUP_EST_NO: string;
    MARGIN_PER: string;
    CREATED_BY: number;
    CREATED_ON: string;
    LabourEstList: LabourEstList[];
    PartEstList: PartEstList[];
    USER_ID:number;
}

export class SaveDetailedEstimation {
    JobEstimation: JobEstimation;
}
export class CommunicationAddress {
    CUSTOMER_ID: number;
    ADDRESS_TYPE: number;
    ADDRESS_LINE_1: string;
    ADDRESS_LINE_2: string;
    ADDRESS_LINE_3: string;
    CITY: string;
    AREA_ID: number;
    STATE_ID: string;
    LANDMARK?: any;
    PIN_CODE?: any;
    District?: any;
    IS_COMM_ADD_VALID: boolean;
    SAME_AS_COMM: boolean;
    CompleteAddress: string;
    STATE_NAME?: any;
    AREA_NAME: string;
}

export class FrameMobile {
    CUSTOMER_ID: number;
    PHONE_TYPE: number;
    CONTACT_NO?: any;
    EXTN?: any;
    IS_PREFERRED: boolean;
    IS_VALID?: any;
    STD_CODE?: any;
    ROW_STATE: number;
    MOBILE_NO: string;
    CONTACT_NO_2?: any;
    IS_VALID_CONTACT_2?: any;
}

export class ResidencePhone {
    CUSTOMER_ID: number;
    PHONE_TYPE: number;
    CONTACT_NO?: any;
    EXTN?: any;
    IS_PREFERRED: boolean;
    IS_VALID?: any;
    STD_CODE?: any;
    ROW_STATE: number;
    MOBILE_NO: string;
    CONTACT_NO_2?: any;
    IS_VALID_CONTACT_2?: any;
}

export class VehicleCustomerDO {
    GST_IN_NO?: any;
    AADHAR_NO?: any;
    STATE_ID: string;
    CUSTOMER_ID: number;
    DEALER_ID: number;
    SALUTATION?: any;
    CUST_NAME: string;
    SON_WIFE_DAU_OF?: any;
    DATE_OF_BIRTH?: any;
    CUST_DATE_OF_BIRTH?: any;
    YEAR_OF_BIRTH: number;
    GENDER: string;
    IS_PROSPECT: boolean;
    CUST_GRP_ID: number;
    CUST_TY_ID: number;
    EMAIL_ADDRESS?: any;
    IS_EMAIL_ID_VALID: boolean;
    EMPL_TYPE?: any;
    PROFESSION_ID: number;
    INCOME_ID?: any;
    MOBILE_NO: string;
    EDUCATION_ID: number;
    CUST_TAX_CAT_ID: number;
    REF_CUST_ID?: any;
    SEND_SERV_REM: boolean;
    CUST_LIKE_TO_VST: boolean;
    HANDICAPPED: boolean;
    TVS_CUSTOMER_ID?: any;
    FOLL_TY_ID?: any;
    OLD_CUST_ID?: any;
    MARRIED: boolean;
    ANNIV_DATE?: any;
    CREDIT_CARD: boolean;
    IS_SL_CODE_GEN: boolean;
    CUST_SERV_TY_ID: boolean;
    VEH_CRED_LIM: number;
    SPR_CRED_LIM: number;
    GEN_CRED_LIM: number;
    SPR_MARGIN: number;
    SL_CODE: number;
    REMARKS?: any;
    Comm_Language: string;
    Rel_with_EndUser?: any;
    NUMBER_KTP?: any;
    CREATED_BY: number;
    CREATED_ON: Date;
    MODIFIED_BY?: any;
    MODIFIED_ON?: any;
    ACTIVE: boolean;
    ROW_STATE: number;
    FullAddress?: any;
    CustType: number;
    CommunicationAddress: CommunicationAddress;
    OfficeAddress?: any;
    ResidenceAddress?: any;
    RegistrationAddress?: any;
    CommnAddressPhone?: any;
    Mobile: FrameMobile;
    OfficePhone1?: any;
    OfficePhone2?: any;
    OfficeFaxNo?: any;
    ResidencePhone: ResidencePhone;
    RegnAddressPhone?: any;
    Enduser?: any;
    CIC?: any;
    InstitutionDetails?: any;
    ContactList?: any;
    AddressList?: any;
    EndUserList?: any;
    VehicleList?: any;
    LedgerList?: any;
    LEDGER_VALUES?: any;
    CUSTOMER_TYPE: string;
    CUSTOMER_GROUP: string;
    PaymentDueVehicle: number;
    PaymentDueSpares: number;
    PaymentDueGeneric: number;
    CustomerType?: any;
    IsLastSale: boolean;
    GROUP?: any;
    CUST_CAT_ID: number;
    AvailableCreditLimitForVehicle: number;
    AvailableCreditLimitForSpares: number;
    AvailableCreditLimitForGeneric: number;
    OLD_VEHICLE: boolean;
    EXIST_MODEL_ID?: any;
    DLR_COUNTRY_CODE?: any;
    CONT_PERSON?: any;
    ConsumedCredit?: any;
    GEN_CRED_CON: number;
    CompleteAddress?: any;
    AREA_NAME: string;
    CONTACT_NO?: any;
    MODLE_DESCRIPTION?: any;
    AGE: number;
    FRAME_NO?: any;
    ENGINE_NO?: any;
    SALE_DATE?: any;
    LAST_JOB_CARD_DATE?: any;
    chkSelect: boolean;
    CREDIT_LIMIT_DAYS: number;
    VENDOR_ID: number;
    EMPLOYEE_ID: number;
    SERIES?: any;
    CATEGORY?: any;
    CITY: string;
    NAME?: any;
    BLOCK_FSC: boolean;
    BLOCK_WARRANTY: boolean;
    END_USER_ID: number;
    FromPage?: any;
    CustomerSPMargin?: any;
    PAN_NO?: any;
    CUSTOMER_PAN_COUNT?: any;
    APS_CODE?: any;
}

export class VehicleEndCustomerDO {
    CUSTOMER_ID: number;
    ENDUSR_DATE_OF_BIRTH?: any;
    DEALER_ID: number;
    SALUTATION?: any;
    CUST_NAME?: any;
    GENDER?: any;
    CUST_GRP_ID?: any;
    CUST_TY_ID: number;
    EMAIL_ADDRESS?: any;
    MOBILE_NO?: any;
    MOBILE_NO_2?: any;
    CONTACT_NO?: any;
    STD_CODE?: any;
    CUST_TAX_CAT_ID?: any;
    SL_CODE?: any;
    CompleteAddress?: any;
    LANDMARK?: any;
    CUSTOMER_TYPE?: any;
    CUSTOMER_GROUP?: any;
    AREA_ID?: any;
}

export class JCVehicleDO {
    Dealer_ID: number;
    VEHICLE_ID: number;
    BOOKING_ID: number;
    IS_RECALL_REFIT_VEH: boolean;
    FRAME_NO: string;
    HSN_CODE?: any;
    IS_AD_TO_AD_BILLED: boolean;
    ENGINE_NO: string;
    KEY_NO?: any;
    PART_ID: string;
    MODEL_ID: string;
    SubCat_DESC?: any;
    FRONT_TYRE_MAKE?: any;
    REAR_TYRE_MAKE?: any;
    BATTERY_MAKE?: any;
    BATTERY_NO?: any;
    FIELD_1?: any;
    FIELD_2?: any;
    FIELD_3?: any;
    FIELD_4?: any;
    FIELD_5?: any;
    TVS_INV_PRICE: number;
    REG_NO: string;
    SALE_DATE: any;
    PLANT_ID: string;
    AMC_BOOKLET_NO?: any;
    PROD_MON: number;
    PROD_YR: number;
    GRN_DATE?: any;
    UNIQUE_NO?: any;
    CUR_ODOM_READ: number;
    CUM_ODOM_READ: number;
    REPLACED_AT: number;
    LAST_JOB_CARD_DATE: Date;
    AMC_ID: number;
    VALID_FROM: Date;
    VALID_TILL: Date;
    INS_TYPE_ID: number;
    INS_COMP_ID: number;
    INS_CUST_ID: string;
    INS_POLICY_NO: string;
    INS_POLICY_DT: Date;
    INS_VALID_FROM: Date;
    INS_VALID_TILL: Date;
    IS_VEH_REGI: boolean;
    IS_CST_PUR: boolean;
    EX_SHRM_PRICE: number;
    DLR_PAID_TAX: number;
    REF_CUST_ID?: any;
    SALE_THR_ASC_ID: number;
    STATUS: number;
    BASIC_PRICE: number;
    INPUT_TAX_VALUE: number;
    Service_Booklet_No?: any;
    IsBookletnoEdit?: any;
    CREATED_BY: number;
    CREATED_ON: Date;
    MODIFIED_BY: number;
    MODIFIED_ON: Date;
    ACTIVE: boolean;
    IS_OLD_VEHICLE: boolean;
    IS_THRU_UPLOAD: boolean;
    PART_DESC: string;
    MODEL_DESC: string;
    MAX_INS_AMT: number;
    MAX_REPAIR_AMT: number;
    VehicleEndUser?: any;
    EndUserList?: any;
    EndUserList1?: any;
    CustEndUserList?: any;
    VehicleCustomerEndUserList?: any;
    CustomerDetails?: any;
    ROW_STATE: number;
    Part?: any;
    DESCRIPTION?: any;
    IsSelected: boolean;
    ALLOTED_QTY: number;
    WARRANTY_BOOKELET_NO: string;
    POLICY_NO: string;
    AMC_TYPE: number;
    RunningNO: number;
    VehicleInvoiceStatus: number;
    Vehiclestaus: number;
    CWI_WARR_TILL_DATE?: any;
    CWI_WARR_FROM_DATE?: any;
    CGST_AMT: number;
    SGST_AMT: number;
    IGST_AMT: number;
    UTGST_AMT: number;
    CGST_P?: any;
    SGST_P?: any;
    IGST_P?: any;
    UGST_P?: any;
    JOB_CARD_EXISTS: number;
    MCS_TYPE_ID: number;
    MANUF_DESC: string;
}

export class JobTypeList {
    JOB_TYPE_ID: number;
    AUTO_ALLOT: boolean;
    JOB_CAT_ID: number;
    COUPON: boolean;
    LABOUR_CHRG: boolean;
    DESCRIPTION: string;
    POST_WAR_DAYS: number;
    MAX_FOLLOWUPS: number;
    PROFIT_CHECK: boolean;
    ACTIVE: boolean;
    JOB_DESC?: any;
    LinkID: number;
    STATUS: number;
}

export class JCServiceAppointmentDO {
    DEALER_ID: number;
    BRANCH_ID: number;
    APPOINTMENT_ID: number;
    SERV_PREF?: any;
    END_USER_ID: number;
    CUSTOMER_ID: number;
    VEHICLE_ID: number;
    JOB_TYPE_ID: number;
    APT_CAT_ID: number;
    CARE_CAMP_SCH_ID: number;
    CARE_CAMP_DESC: string;
    SERV_MODE_ID: number;
    APPOINTMENT_BY?: any;
    CUST_ARRIVAL_DATE: Date;
    APPOINTMENT_NO: number;
    REGIS_NO?: any;
    REASON_ID: number;
    STATUS: number;
    APPOINT_DATE: Date;
    CUR_FOLLOWUP_NO: number;
    CREATED_BY: number;
    CREATED_ON: Date;
    MODIFIED_BY: number;
    MODIFIED_ON: Date;
    ACTIVE: boolean;
    ROW_STATE: number;
    FROM_DATE: Date;
    TO_DATE: Date;
    NEXT_FOLLO_FROM: Date;
    NEXT_FOLLO_TO: Date;
    EMP_ID: number;
    COMBINE_EMPIDS?: any;
    FRAME_NO?: any;
    REMARKS?: any;
    RATING: string;
    Customer?: any;
    ServiceMode?: any;
    JobType?: any;
    Vehicle?: any;
    CustomerPhone?: any;
    VehModel?: any;
    VehSubCateg?: any;
    FollowUp?: any;
}

export class JCServiceAppointmentExistDO {
    DEALER_ID: number;
    BRANCH_ID: number;
    APPOINTMENT_ID: number;
    SERV_PREF?: any;
    END_USER_ID: number;
    CUSTOMER_ID: number;
    VEHICLE_ID: number;
    JOB_TYPE_ID: number;
    APT_CAT_ID: number;
    CARE_CAMP_SCH_ID: number;
    SERV_MODE_ID: number;
    APPOINTMENT_BY?: any;
    CUST_ARRIVAL_DATE: Date;
    APPOINTMENT_NO: number;
    REGIS_NO?: any;
    REASON_ID: number;
    STATUS: number;
    APPOINT_DATE: Date;
    CUR_FOLLOWUP_NO: number;
    CREATED_BY: number;
    CREATED_ON: Date;
    MODIFIED_BY: number;
    MODIFIED_ON: Date;
    ACTIVE: boolean;
    ROW_STATE: number;
    FROM_DATE: Date;
    TO_DATE: Date;
    NEXT_FOLLO_FROM: Date;
    NEXT_FOLLO_TO: Date;
    EMP_ID: number;
    COMBINE_EMPIDS?: any;
    FRAME_NO?: any;
    REMARKS?: any;
    RATING?: any;
    Customer?: any;
    ServiceMode?: any;
    JobType?: any;
    Vehicle?: any;
    CustomerPhone?: any;
    VehModel?: any;
    VehSubCateg?: any;
    FollowUp?: any;
}

export class VehicleSearch {
    Dealer_ID: number;
    BRANCH_ID: number;
    VEHICLE_ID: number;
    JOB_TYPE_ID: number;
    FRAME_NO: string;
    ROW_STATE: number;
    IS_RECALL_REFIT_VEH: boolean;
    ENGINE_NO: string;
    KEY_NO?: any;
    REG_NO: string;
    Service_Booklet_No?: any;
    SALE_DATE: Date;
    PART_DESC: string;
    MODEL_DESC: string;
    CUST_NAME: string;
    END_USER: string;
    CUSTOMER_ID: number;
    END_USER_ID: number;
    MODEL_ID: string;
    CUR_ODOM_READ: number;
    CUM_ODOM_READ: number;
    UNIQUE_NO: number;
    IS_ALL_DEALER: boolean;
    IS_OLD_VEHICLE: boolean;
    STATUS: number;
    CONTACT_NO?: any;
    LAST_JOB_CARD_DATE: Date;
    SUBCATEGORY_ID?: any;
    JCComplaintList: ComplaintList[];
    VehicleCustomerDO: VehicleCustomerDO;
    VehicleEndCustomerDO: VehicleEndCustomerDO;
    JCVehicleDO: JCVehicleDO;
    JobTypeList: JobTypeList[];
    JCServiceAppointmentDO: JCServiceAppointmentDO;
    JCServiceAppointmentExistDO: JCServiceAppointmentExistDO;
    Vehstatus: number;
    CRM_WELCOME_KIT_NO?: any;
    COUNTRY_CODE?: any;
    IS_HIGH_VEH: number;
    strdate: Date;
    enddt: Date;
    validdt: Date;
    JOB_CARD_EXISTS: number;
    MANUF_DESC: string;
    PREV_JOB_CAT_ID: number;
    REF_INVOICE: boolean;
    CATEGORY_ID:string;
}

export class DealerAMC {
    DEALER_ID: number;
    AMC_ID: number;
    DESCRIPTION: string;
    TOT_AMC_AMT: number;
    VALID_FROM: Date;
    VALID_TILL: Date;
    GL_CODE: number;
    GL_DESC?: any;
    REMARKS?: any;
    TVS_AMC_ID: number;
    TAX_CATEGORY: number;
    ITEM_TYPE_ID: number;
    WARRANTY_PERIOD: number;
    COMP_WARRANTY_PERIOD: number;
    AMC_DESC_TYPE: number;
    CREATED_BY: number;
    CREATED_ON: Date;
    MODIFIED_BY: number;
    MODIFIED_ON: Date;
    NO_OF_SERVICE_APP: number;
    NO_OF_WASH_FREE: number;
    NO_OF_CO_CHECK: number;
    NO_OF_CHAIN_OVER_ROLL: number;
    NO_OF_PICKUP_DROP: number;
    ACTIVE: boolean;
    COUNTRY_CODE: string;
    MODEL_ID?: any;
    VEHICLE_ID: number;
    REG_NO?: any;
    END_USER_NAME?: any;
    STATUS: number;
    CUSTOMER_ID: number;
    CUST_NAME?: any;
    FRAME_NO?: any;
    AMC_VEH_ID: number;
    AMC_VEH_NO?: any;
    AMC_REF_DATE: Date;
    SL_CODE: number;
    SL_DESC?: any;
    SubLedger?: any;
    AMCDiscountList?: any;
    ROW_STATE: number;
    FROM_DATE?: any;
    TO_DATE?: any;
    AMCTaxValueList?: any;
}

export class FrameDetailsObj {
    VehicleSearch: VehicleSearch;
    TVSAmc?: any;
    DealerAMC: DealerAMC[];
}


//Issue Labour


export class SchemeDetailsList {
    SERV_SCH_ID: string;
}

export class LabourList {
    BRANCH_ID: string;
    IS_Existed: boolean;
    JOB_CARD_ID: string;
    ROW_STATE: string;
    LABOUR_ID: string;
    JC_LABOUR_CODE: string;
    REQUIRED_QTY: any;
    JCARD_LAB_ID: string;
    QUANTITY: any;
    LABOUR_RATE: string;
    LABOUR_CHARGES: string;
    SCHEME_DISC: string;
    OUTWORK_MEMO_ID: string;
    DISC_AMT: string;
    DISC_VALUE: string;
    STATUS: string;
    MODE_ID: any;
    TAX_RATE: string;
    CGST_TAX_RATE: string;
    SGST_TAX_RATE: string;
    IGST_TAX_RATE: string;
    UTGST_TAX_RATE: string;
    TAX_VALUE: string;
    SCH_TYPE: string;
    HSN_CODE: string;
    DESCRIPTION: string;
    CATEGORY: string;
    uniqId: number;
    SchemeDetailsList: SchemeDetailsList[];
    CESS_TAX_RATE: string;
}

export class SaveLabour {
    DealerID: number;
    BranchID: number;
    JobCardID: number;
    LabourList: LabourList[];
    Rowstate: string;
    UserId:number;
}


export class RackBinDetailsList {
    DEALER_ID: number;
    BRANCH_ID: number;
    SPARE_PART_ID: number;
    LOCATION_ID: number;
    RackNo: number;
    MRP: number;
    PartRKBNID: number;
    RequiredQty: number;
    ReturnQty: number;
    MODIFIED_BY: number;
    isTaxable: number;
    PURCHASE_COST: number;
    DLR_PAID_TAX: number;
}

export class PartIssueDet {
    IS_Existed: boolean;
    JOB_CARD_ID: number;
    RACK_ID: number;
    PART_RK_BN_ID: number;
    JCARD_SPARE_ID: number;
    BRANCH_ID: number;
    DEALER_ID: number;
    SPARE_PART_ID: number;
    PART_NO: number;
    JC_PART_NO: string;
    MANUF_ID: number;
    isTaxable: number;
    REQ_QTY_ENTERED: number;
    MODE_ID: number;
    itemCategory: number;
    Part_Cost: number;
    Rate: number;
    RRP: number;
    MRP: number;
    REQ_QTY: any;
    REQUIRED_QTY: any;
    DISC_AMT: number;
    DISC_VALUE: number;
    TAX_VALUE: number;
    TAX_RATE: number;
    LabSchemeType: number;
    LOCATION_ID: number;
    PartRKBNID: number;
    CGST: number;
    SGST: number;
    IGST: number;
    UTGST: number;
    ITEM_TYPE_ID: number;
    ITEM_TAX_CAT_ID: number;
    RackBinDetailsList: RackBinDetailsList[];
    NetREQ_QTY: string;
    UNIT_PRICE: string;
    PART_DESCRIPTION: string;
    JC_PART_DESCRIPTION: string;
    Is_Lubricant:any;
    NetISSUED_QTY: number;
    ISSUED_QTY: number;
    RET_QTY: string;
    QTY_TobeReturned: string;
    SchemeDiscount_Value: string;
    MASTER_DISCOUNT_VALUE: string;
    HSN_CODE: string;
    UOM_ID: string;
    IS_ZMC: boolean;
    WARRANTY_APPLICABLE: boolean;
    PART_TYPE_ID: number;
    partNoList: any;
    JUSTIFICATION: any;
    Pending: any;
    AMC_DISC_AMT: any;
    MASTER_DISC_PERC: any;
    COST_PLUS: any;
    COST_PLUS_MARGIN: any;
    TAX_FIELD1: number;
    DLR_WAR_CLM_NO: number;
    DLR_TRAN_CLAIM_NO: number;
    Approved_by: string;
    IS_WEAR_TEAR: boolean;
    FROM_KM: number;
    TO_KM: number;
    NO_OF_VALID_DAYS: number;
    PURCHASE_COST: number;
    DLR_PAID_TAX: number;
}

export class JobCardDet {
    JOB_CARD_ID: number;
}

export class SavePart {
    PartIssueDet: PartIssueDet;
    JobCardDet: JobCardDet;
    SparePricePickup: string;
    CountryCode: string;
    uniqId: number;
}

export class JobCardStatusDetails {
    REASON_ID: number;
    STATUS_DATE: string;
    REMARKS?: any;
}
export class CustomerDetails {
    CUSTOMER_ID: any;
    DATE_OF_BIRTH?: any;
    mobile: Mobile;
    EMAIL_ADDRESS: string;
}
export class JobCardAMCDO {
    SERVICE_APPLICABLE: boolean;
    PICKUP_DROP: boolean;
    WATER_WASH: boolean;
    CHAIN_OVER_ROLL: boolean;
    CO_CHECK: boolean;
}

export class MandatoryPartsDO {
    PART_NO: string;
    SPARE_PART_ID: Number;
    PART_DESCRIPTION: String;
    QUANTITY: Number;
    JOB_CARD_ID: Number;
    DEALER_ID: Number;
    BRANCH_ID: Number;
    uniqId: number;
}

export class JobCardDO {
    DEALER_ID: string;
    BRANCH_ID: string;
    FSC_OTP?: any;
    CPN_REC: boolean;
    IS_MASTER_MOBILE: boolean;
    TICKET_NO: string;
    STATUS: string;
    SERVICE_APPLICABLE: boolean;
    WATER_WASH: boolean;
    CO_CHECK: boolean;
    CHAIN_OVER_ROLL: boolean;
    PICKUP_DROP: boolean;
    VEHICLE_ID: string;
    LOCAL_AMC?: any;
    JOB_CARD_ID: string;
    JOB_TYPE_ID: string;
    MODIFIED_BY: string;
    JOB_START_TIME: string;
    JOB_END_TIME: string;
    REGIS_NO: string;
    MECH_ID: string;
    TEAMLEADER_ID: string;
    REMARKS: string;
    VEHICLE_OUT_TIME?: any;
    JobCardStatusDetails: JobCardStatusDetails;
    ComplaintList: ComplaintList[];
    AMC_VEH_ID: Number;
    CustomerDetails: CustomerDetails;
    EndUserDetails: EndUser;
    UserId:number; // Code Changed By Anil- START

    stdCheckDOList:StandardCheckDO[];
    //Code Changed By Anil- END
}
// Code Changed By Anil- START
export class StandardCheckDO
{
    DEALER_ID:number;  
    BRANCH_ID :number; 
    JOB_CARD_ID :number; 
    SUB_JC_REQUEST_ID :string
    COUNTRY_CODE :string;
    USER_ID :number; 


}
// Code Changed By Anil-END
export class CompleteJob {
    DealerId: number;
    BranchId: number;
    sessCpnAllow: boolean;
    JobCardDO: JobCardDO;
    _Static: any;
    CountryCode: String;
    VehicleDetails: VehicleDetails;
    JCAMC: JCAMC;
    UserId:number;
    StJobCard: any;
}



export class SusbandJobCard {
    JOB_CARD_ID: number;
    MODIFIED_BY: number;
    STATUS: number;
    DEALER_ID: number;
    BRANCH_ID: number;
    USER_ID:number;
}

export class SusbandStatus {
    DEALER_ID: number;
    BRANCH_ID: number;
    REASON_ID: number;
    REMARKS: string;
    STATUS_DATE: any;
    JOB_CARD_ID: number;
}

export class SaveSusband {
    JobCard: SusbandJobCard;
    JCStatus: SusbandStatus;
    _JobCard:SusbandJobCard;
    _Status:SusbandStatus;
    Reason_type: number;
    UserId:number;
}
export class DlrAMCChange {
    DealerID: number;
    BranchID: number;
    JobCardID: number;
    LabourList: LabourList[];
    DlrAMCType: string;
    StateID: string;
    CountryCode: string;
}

export class PendingJobcardstatusDO {
    DEALER_ID: number;
    BRANCH_ID: number;
    JOB_CARD_ID: number;
    JOB_CARD_NO: number;
    JOB_CARD_DATE: Date | string;
    CUST_NAME: string;
    REGIS_NO: string;
    JOB_TYPE_ID: number;
    DESCRIPTION: string;
    CLOSURE_STATUS: number | null;
    CLOSURE_DESC: string;
    CLOSURE_REMARKS: string;
    CLOSURE_EXPDATE: Date | string | null;
    IS_SELECT: boolean;
    USER_ID: number;
    UPDATE_CLOSURE_STATUS: number | null;
    UPDATE_CLOSURE_REMARKS: string;
}

export class WarrantyTagforjc {
    DEALER_ID: number;
    BRANCH_ID: number;
    UserId: number;
    JOB_CARD_ID: number;
    SPARE_PART_ID: number;
    PART_NO: string;
    FRAME_NO: string;
    CUST_VOICE: string;
    DTC_CODE: string;
    FAILURE_SYMPTOMS: string;
    TECH_OBSERVATION: string;
}


export class PendingstatusRootObject {
    pendingjobcardList: PendingJobcardstatusDO[];
}

export class PmpSparesDO {
    DEALER_ID: number;
    BRANCH_ID: number;
    VEHICLE_ID: number;
    JOB_CARD_ID: number;
    REASON: string;
    REASON_RMKS: string;
    SPARE_PART_ID: number;
    PART_NO: string;
    DESCRIPTION: string;
    REASON_ID: number;
    uniqId: any;
}

export class StaticIssueModeDO {
    MCS_TYPE_ID: number;
    CATEGORY_ID: string;
    MODEL_ID: string;
    ISSUE_MODE_ID: number;
    IS_ZMC: boolean;
    IS_WEAR_TEAR: boolean;
    FROM_KM: any;
    TO_KM: any;
    NO_OF_VALID_DAYS: number;
    MANUF_ID: number;
    COUNTRY_CODE: string;
    SALE_DATE: any;
    PART_TYPE_ID: string;
    FRAME_NO: string;
    KILOMETERS: any;
    IS_CWI_EXT_WARRANTY: boolean;
    SPARE_PART_WARRANTY_APPLICABLE: boolean;
    JOB_TYPE_ID: number;
    IsOLDVehicle: boolean
    INS_HAND_BY: number;
    IS_RECALL_REFIT: boolean;
    IS_EXT_WAR: boolean;
    MANUF_DATE: any;
    PROD_YR: any;
    PROD_MON: any;
    PART_NO:string;
    DEALER_ID :any;
    BRANCH_ID :string;
    USER_ID : string;
}

export class RepairRequestDO {
    DEALER_ID: Number;
    BRANCH_ID: Number;
    PART_NO: string;
    DESCRIPTION: String;
    SPARE_PART_ID: Number;    
    QUANTITY: Number;
    AVAILABLE_QTY: Number;  
    ORDER_TO: String;
    REMARKS: String;
    REMARKSTEXT: String;    
    uniqId: number;
}


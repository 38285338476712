import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { LoginService } from 'src/app/api-services/login.service';
import { CommonService } from 'src/app/api-services/common.service';
import { JobCardService } from '../../api-services/job-card.service';
import { ServiceAppoitmentService } from '../../api-services/service-appoitment.service';
import { JobCardInvoiceService } from '../../api-services/job-card-invoice.service';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-job-card-search',
  templateUrl: './job-card-search.component.html',
  styleUrls: ['./job-card-search.component.scss']
})
export class JobCardSearchComponent implements OnInit {
  public search: any = {
    'Dealer_id': '',
    'RegName': '',
    'CustName': '',
    'FrameNo': '',
    'EngineNo': '',
    'BookletNo': '',
    'chkAllDealer': '',
    'FromDt': '',
    'ToDt': '',
    'AppNo': '',
    'RegNo': '',
  };
  public salesPerson = [];
  public searchForm: any = {};
  public lang: any;
  public selected = [];
  public resultData = null;
  public dealerDetails = [];
  public isActive: any;
  public searchLoader: any;
  public searchtext = '';
  public partSearchKey: any;
  public isFrameNo: boolean = false;

  constructor(private toastr: ToastrService, private jobCardService: JobCardService,
    private serviceAppoitmentService: ServiceAppoitmentService,
    private jobCardInvoiceService: JobCardInvoiceService,
    private masterServices: MasterService, private loginService: LoginService,
    private dialogRef: MatDialogRef<JobCardSearchComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      if (CommonService.getServAppData()) {
        this.salesPerson = CommonService.getServAppData().SalesPersonList;
      }
      this.search.SalepersonId = '';
      this.isActive = this.data.key; // for getting search key 
      if (this.data && this.data.type == 'partCustomerSearch') {
        console.log("partTrue::");
        this.partSearchKey = 'partCustomerSearch';
      } else if (this.data && this.data.type == 'UVDServicesAppointment') {
        this.partSearchKey = 'UVDServicesAppointment';
      }
      /**
       * @param {String} isActive
       * adding search column values
       * Returns an array.
       */
      if (this.isActive == 'regno') {
        this.data = {
          columns: [
            { name: this.lang.SERVICES.regNo, prop: 'REG_NO' },
            { name: this.lang.SERVICES.partDescrp, prop: 'PART_DESC' },
            { name: this.lang.SERVICES.customerName, prop: 'CUST_NAME' },
            { name: this.lang.SERVICES.endUserName, prop: 'END_USER' },
            { name: this.lang.SERVICES.frameNo, prop: 'FRAME_NO' },
            { name: this.lang.SERVICES.engineNo, prop: 'ENGINE_NO' },
            { name: this.lang.SERVICES.serBookltNo, prop: 'Service_Booklet_No' },
            { name: this.lang.SERVICES.saleDate, prop: 'SALE_DATE' }
          ]
        };
      } else if (this.isActive == 'frameNo') {
        this.data = {
          columns: [
            { name: this.lang.SERVICES.regNo, prop: 'REG_NO' },
            { name: this.lang.SERVICES.partDescrp, prop: 'PART_DESC' },
            { name: this.lang.SERVICES.customerName, prop: 'CUST_NAME' },
            { name: this.lang.SERVICES.endUserName, prop: 'END_USER' },
            { name: this.lang.SERVICES.frameNo, prop: 'FRAME_NO' },
            { name: this.lang.SERVICES.engineNo, prop: 'ENGINE_NO' },
            { name: this.lang.SERVICES.serBookltNo, prop: 'Service_Booklet_No' },
            { name: this.lang.SERVICES.saleDate, prop: 'SALE_DATE' }
          ]
        };
      } else if (this.isActive == 'serBookltNo') {
        this.data = {
          columns: [
            { name: this.lang.SERVICES.regNo, prop: 'REG_NO' },
            { name: this.lang.SERVICES.partDescrp, prop: 'PART_DESC' },
            { name: this.lang.SERVICES.customerName, prop: 'CUST_NAME' },
            { name: this.lang.SERVICES.endUserName, prop: 'END_USER' },
            { name: this.lang.SERVICES.frameNo, prop: 'FRAME_NO' },
            { name: this.lang.SERVICES.engineNo, prop: 'ENGINE_NO' },
            { name: this.lang.SERVICES.serBookltNo, prop: 'Service_Booklet_No' },
            { name: this.lang.SERVICES.saleDate, prop: 'SALE_DATE' }
          ]
        }
      } else if (this.isActive == 'appointment') {
        this.data = {
          columns: [
            { name: this.lang.SERVICES.appointmentNo, prop: 'APPOINTMENT_NO' },
            { name: this.lang.SERVICES.appointmentDate, prop: 'APPOINT_DATE' },
            { name: this.lang.SERVICES.regNo, prop: 'REGIS_NO' },
            { name: this.lang.SERVICES.customerId, prop: 'Customer.CUSTOMER_ID' },
            { name: this.lang.SERVICES.customerName, prop: 'Customer.CUST_NAME' },
            { name: this.lang.SERVICES.serMode, prop: 'ServiceMode.SERV_DESC' },
            { name: this.lang.SERVICES.jobType, prop: 'JobType.JOB_DESC' },
            { name: this.lang.SERVICES.frameNo, prop: 'FRAME_NO' }
          ]
        }
      } else if (this.isActive == 'customerId' || this.isActive == 'ascCode') {
        this.data = {
          columns: [
            { name: this.lang.SERVICES.customerId, prop: 'CUSTOMER_ID' },
            { name: this.lang.SERVICES.customerName, prop: 'CUST_NAME' },
            { name: this.lang.SERVICES.customerType, prop: 'CustomerType' },
            { name: this.lang.GENERAL.address, prop: 'CommunicationAddress.ADDRESS_LINE_1' },
            { name: this.lang.SERVICES.area, prop: 'CommunicationAddress.AREA_NAME' },
            { name: this.lang.GENERAL.phnNo, prop: 'Mobile.PHONE_TYPE' },
            { name: this.lang.GENERAL.mblNo1, prop: 'Mobile.MOBILE_NO' },
            { name: this.lang.GENERAL.mblNo2, prop: 'Mobile.CONTACT_NO_2' }
          ]
        };
      }
      else if (this.isActive == 'jobCard') {
        this.data = {
          columns: [
            { name: this.lang.SERVICES.appointmentNo, prop: 'APPOINTMENT_NO' },
            { name: this.lang.SERVICES.appointmentDate, prop: 'APPOINT_DATE' },
            { name: this.lang.SERVICES.regNo, prop: 'REGIS_NO' },
            { name: this.lang.SERVICES.customerId, prop: 'Customer.CUSTOMER_ID' },
            { name: this.lang.SERVICES.customerName, prop: 'Customer.CUST_NAME' },
            { name: this.lang.SERVICES.serMode, prop: 'ServiceMode.SERV_DESC' },
            { name: this.lang.SERVICES.jobType, prop: 'JobType.JOB_DESC' },
            // { name: this.lang.SERVICES.modelDescrp, prop: 'REG_NO' },
            { name: this.lang.SERVICES.frameNo, prop: 'FRAME_NO' }
          ]
        }
      } else if (this.isActive == 'jobCardInvoice') {
        this.data = {
          columns: [
            { name: this.lang.SERVICES.jobCardNo, prop: 'JOB_CARD_ID' },
            { name: this.lang.SERVICES.jobCardDate, prop: 'JOB_CARD_DATE' },
            { name: this.lang.SERVICES.customerId, prop: 'CUSTOMER_ID' },
            { name: this.lang.SERVICES.customerName, prop: 'CUST_NAME' },
            { name: this.lang.SERVICES.regNo, prop: 'REGIS_NO' },
            { name: this.lang.GENERAL.status, prop: 'STATUS_DESC' },
            { name: this.lang.SERVICES.jobType, prop: 'JOB_TYPE_DESC' },
            { name: this.lang.SERVICES.frameNo, prop: 'FRAME_NO' }
          ]
        }
      } else {
        console.log('default');
      }
    }
  }
  /**
  * @param {String} selected
  * Get selected details
  */
  selectItem() {
    let closeObj = { obj: this.selected[0], category: this.isActive };
    this.dialogRef.close(closeObj);
  }
  /**
   * @param {String} checkValidation 
   * get search table data
   * Returns an either true or false.
   */
  checkValid() {
    if (this.search.Dealer_id ||
      this.search.RegName ||
      this.search.jobCardId ||
      this.search.customerId ||
      this.search.CustName ||
      this.search.FrameNo ||
      this.search.EngineNo ||
      this.search.BookletNo ||
      this.search.chkAllDealer ||
      this.search.FromDt ||
      this.search.ToDt ||
      this.search.AppNo ||
      this.search.RegNo ||
      this.search.CustName ||
      this.search.SalepersonId ||
      this.search.Address ||
      this.search.PhoneNo ||
      this.search.CustCode) {
      return false;
    } else {
      return true;
    }
  }
  checkFrameNo() {
    this.isFrameNo = true;
  }
  /**
   * Validate searchDetails
   */
  validateSearchDetails() {
    let valid = true;
    if ((this.isActive == 'appointment' || this.isActive == 'jobCard' || this.isActive === 'jobCardInvoice')) {
      if (this.search.FromDt && !this.search.ToDt) {
        this.toastr.warning(this.lang.Message.GENERAL.INVALID_DATE);
        return valid = false;
      } else if (!this.search.FromDt && this.search.ToDt) {
        this.toastr.warning(this.lang.Message.GENERAL.INVALID_DATE);
        return valid = false;
      }
    }
    return valid;
  }
  /**
  * @param {String} searchItem
  * get search table data
  * Returns an array.
  */
  searchItem() {
    if (this.isFrameNo == true) {
      if (this.search.FrameNo.length > 0 && this.search.FrameNo.length <= 8 ) {
        this.toastr.warning("Frame No Atleast 8 character");
        return;
      }
      else{
        this.isFrameNo = false;
      }
    }
    if(this.isFrameNo == false) {
      if (this.validateSearchDetails()) {
        this.searchLoader = true;
        if (this.partSearchKey == 'partCustomerSearch' && this.isActive == 'customerId') {
          let params = new HttpParams();
          let reqObj = {};
          reqObj = {
            dealerId: CommonService.getUserData().DEALER_ID,
            customerName: this.search.CustName,
            customerCode: this.search.CustCode,
            phoneNumber: this.search.PhoneNo,
            address: this.search.Address,
            employeeId: this.search.SalepersonId,
          }
          for (const key in reqObj) {
            if (reqObj[key] == '') {
              delete reqObj[key];
            }
          }
          // params = params.set('dealerId', CommonService.getUserData().DEALER_ID)
          //   .set('customerName', this.search.CustName ? this.search.CustName : '')
          //   .set('customerCode', this.search.CustCode ? this.search.CustCode : '')
          //   .set('phoneNumber', this.search.PhoneNo ? this.search.PhoneNo : '')
          //   .set('address', this.search.Address ? this.search.Address : '')
          //   .set('employeeId', this.search.SalepersonId ? this.search.SalepersonId : '')
          this.masterServices.post(reqObj, '/directInvoice/customerSearchList').subscribe((resp: any) => {
            if (resp && resp.statusCode == 200) {
              this.searchLoader = false;
              this.data.rows = [];
              if (resp.customerList && resp.customerList.length > 0) {
                resp.customerList.filter(key => {
                  this.data.rows.push(
                    {
                      CUST_NAME: key.customerName,
                      CUSTOMER_ID: key.customerId,
                      customerTypeId: key.customerTypeId,
                      slCode: key.slCode,
                      CustomerType: key.customerType,
                      Mobile: {
                        PHONE_TYPE: key.contactNo,
                        MOBILE_NO: key.mobileNo
                      },
                      CommunicationAddress: {
                        ADDRESS_LINE_1: key.address1,
                        ADDRESS_LINE_2: key.address2,
                        ADDRESS_LINE_3: key.address3,
                        AREA_NAME: key.areaName
                      }
                    }
                  );
                });
              }
            }
          }, error => {
            this.showError('', error);
          });
        } else if (this.partSearchKey == 'UVDServicesAppointment' && (this.isActive == 'frameNo' || this.isActive == 'regno')) {
          let reqObj = {};
          reqObj = {
            Dealer_id: CommonService.getUserData().DEALER_ID,
            CustName: this.search.CustName,
            RegName: this.search.RegName,
            FrameNo: this.search.FrameNo,
            EngineNo: this.search.EngineNo,
            BookletNo: this.search.BookletNo,
            chkAllDealer: this.search.chkAllDealer
          }
          for (const key in reqObj) {
            if (reqObj[key] == '') {
              delete reqObj[key];
            }
          }
          this.masterServices.post(reqObj, '/ServiceAppointment/VehicleSearch').subscribe((resp: any) => {
            if (resp && resp.statusCode == 200) {
              this.searchLoader = false;
              this.data.rows = [];
              if (resp.VehicleList && resp.VehicleList.length > 0) {
                this.data.rows = resp.VehicleList;
              }
            } else {
              this.searchLoader = false;
              this.toastr.info(resp.statusMessage);
            }
          }, error => {
            this.showError('', error);
          });
        }
        else if (this.isActive == 'appointment') {
          let aptParams = new HttpParams();
          aptParams = aptParams.set('DealerID', CommonService.getUserData().DEALER_ID)
            .set('BranchID', CommonService.getUserData().BRANCH_ID)
            .set('AppNo', this.search.AppNo)
            .set('RegNo', this.search.RegNo)
            .set('FromDt', this.search.FromDt ? CommonService.getReqDateFormat(this.search.FromDt).slice(0, 10) : '')
            .set('ToDt', this.search.ToDt ? CommonService.getReqDateFormat(this.search.ToDt).slice(0, 10) : '')
            .set('NxtfollFrmDt', '')
            .set('NxtfollToDt', '')
            .set('EmpID', '')
            .set('CountryCode', CommonService.getDealerData().COUNTRY_CODE)
            .set('UserId', CommonService.getUserData().USER_ID);
          this.jobCardService.searchAppointmentNo(aptParams).subscribe((resp: any) => {
            this.showError(resp, '');
          }, error => {
            this.showError('', error);
          });
        } else if (this.isActive == 'frameNo' || this.isActive == 'regno' || this.isActive == 'serBookltNo') {
          let params = new HttpParams();
          params = params.set('Dealer_id', CommonService.getUserData().DEALER_ID)
            .set('CustName', this.search.CustName)
            .set('RegName', this.search.RegName)
            .set('FrameNo', this.search.FrameNo)
            .set('EngineNo', this.search.EngineNo)
            .set('BookletNo', this.search.BookletNo)
            .set('chkAllDealer', this.search.chkAllDealer)
            .set('BranchId', CommonService.getUserData().BRANCH_ID)
            .set('UserId', CommonService.getUserData().USER_ID);
          this.jobCardService.searchFrameDetails(params).subscribe((resp: any) => {
            this.showError(resp, '');
          }, error => {
            this.showError('', error);
          });
        } else if (this.isActive == 'customerId' || this.isActive == 'ascCode') {
          let params = new HttpParams();
          params = params.set('DealerId', CommonService.getUserData().DEALER_ID)
            .set('CustName', this.search.CustName ? this.search.CustName : '')
            .set('SalepersonId', this.search.SalepersonId ? this.search.SalepersonId : '-1')
            .set('Address', this.search.Address ? this.search.Address : '')
            .set('PhoneNo', this.search.PhoneNo ? this.search.PhoneNo : '')
            .set('CustCode', this.search.CustCode ? this.search.CustCode : '')
            .set('CustTypeId', this.isActive == 'ascCode' ? 'ASCFSC' : '')
            .set('DLR_COUNTRY_CODE', CommonService.getDealerData().COUNTRY_CODE)
            .set('BranchId', CommonService.getUserData().BRANCH_ID)
            .set('UserId', CommonService.getUserData().USER_ID)
          this.jobCardService.searchCustomer(params).subscribe((resp: any) => {
            this.showError(resp, '');
            this.searchLoader = false;
          }, error => {
            this.showError('', error);
          });
        } else if (this.isActive == 'jobCardInvoice') {
          let params = new HttpParams();
          params = params.set('DealerId', CommonService.getUserData().DEALER_ID)
            .set('JobCardNo', this.search.jobCardId ? this.search.jobCardId : '')
            .set('BranchID', CommonService.getUserData().BRANCH_ID)
            .set('JobFromDate', this.search.FromDt ? CommonService.getReqDateFormat(this.search.FromDt) : '')
            .set('JobToDate', this.search.ToDt ? CommonService.getReqDateFormat(this.search.ToDt) : '')
            .set('CustomerID', this.search.customerId ? this.search.customerId : '')
            .set('UserId', CommonService.getUserData().USER_ID)
          this.jobCardInvoiceService.getAllJobCardList(params).subscribe((resp: any) => {
            this.showError(resp, '');
          }, error => {
            this.showError('', error);
          });
        }

      }
    }

  }
  showError(resp, error) {
    if (resp && resp.statusCode === 200) {
      this.data.rows = resp.data;
    }
    else if (resp && resp.statusCode == 401) {
      this.loginService.logout();
    }
    else if (resp.message) {
      if (error.status == 401) {
        this.loginService.logout();
      }
      this.toastr.error(resp.message);
    } else {
      if (error.status == 401) {
        this.loginService.logout();
      }
      this.toastr.error(error.error.Message);
    }
    this.searchLoader = false;
  }
  /**
 * @param {String} 
 * close popup search dialog
 */
  closeDialog(): void {
    this.dialogRef.close();
  }
}



/**
 * Created BY V.Dineshkumar 
 * Approval List Service
 * Last Last Modified By V.Dineshkumar on 25/11/2019
 * Last Modified Description : Created Approval related services
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class ApprovalService {
    constructor(private httpClient: HttpClient) {
    }
    /**
     * Get Pending List for Approval
     * @param {Object} params
     * return Object
     */
    getApprovalsPending(params) {       
        const resData = RequestService.getApiURLReq('/ServApp/ApprovalList');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }

    /**
     * Get Pending List for Allocation
     * @param {Object} params
     * return Object
     */
    getPNDApprovals(params) {
        const resData = RequestService.getApiURLReq('/ServApp/PNDApprovalList');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });        
    } 
     
    /**
     * Update Approval Status
     * @param {Object} params
     * return Object
     */
    updateApprovals(request) {      
        const resData = RequestService.postApiURLReq('/ServApp/PostAppointmentStatus');
         return this.httpClient.post(resData.url, request, { headers: resData.headers }); 
    }


    /**
 * Insert Allocation Details
 * @param {Object} params
 * return Object
 */
    updateAllocation(request) {      
        const resData = RequestService.postApiURLReq('/ServApp/PostPNDEOWApproval');
         return this.httpClient.post(resData.url, request, { headers: resData.headers }); 
    }

}
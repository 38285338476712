import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-warehouse-excel-upload',
  templateUrl: './warehouse-excel-upload.component.html',
  styleUrls: ['./warehouse-excel-upload.component.scss']
})
export class WarehouseExcelUploadComponent implements OnInit {
  public isShowPageLoader: boolean = false;
  public lang: any;
  public rawHeader: any = [];
  public tabledata: any = [];
  public exceltoJson: any = {};
  public tableheader: any = [];
  public orderList: any = [];
  public partExcel: any;
  public result: any = [];
  public isActiveResult: boolean = false;
  constructor(private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WarehouseExcelUploadComponent>) { }

  ngOnInit() {
    this.partExcel = this.data.value;
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }


  onFileChange(event: any) {
    this.isShowPageLoader = true;
    this.exceltoJson = {};
    let headerJson = {};
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    // if (target.files.length !== 1) {
    //   throw new Error('Cannot use multiple files');
    // }
    const reader: FileReader = new FileReader();
    if (target.files.length != 0) {
      reader.readAsBinaryString(target.files[0]);
      console.log("filename", target.files[0].name);
      this.exceltoJson['filename'] = target.files[0].name;
      reader.onload = (e: any) => {
        this.isShowPageLoader = true;
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        for (var i = 0; i < wb.SheetNames.length; ++i) {
          const wsname: string = wb.SheetNames[i];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
          this.exceltoJson[`sheet${i + 1}`] = data;
          const headers = this.get_header_row(ws);
          headerJson[`header${i + 1}`] = headers;
          console.log("json", headers)
        }
        this.exceltoJson['headers'] = headerJson;
        console.log("result", this.exceltoJson);
        this.tableheader = this.exceltoJson.headers.header1;

        console.log("header", this.tableheader);
        for (var i = 0; i < this.exceltoJson.sheet1.length; i++) {
          if(this.partExcel == true){
            this.tabledata.push({
              partNo: '',
              partDesc: '',
              itemType: '',
              Weight: '',
              uom: '',
              hsnCode: '',
              itemTaxCategory: '',
              manufacturerName: ''
            });
            this.tabledata[i].partNo = this.exceltoJson.sheet1[i]["Part No"] ? this.exceltoJson.sheet1[i]["Part No"] : null;
            this.tabledata[i].partDesc = this.exceltoJson.sheet1[i]["Part Description"] ? this.exceltoJson.sheet1[i]["Part Description"] : null;
            this.tabledata[i].itemType = this.exceltoJson.sheet1[i]["Item Type"] ? this.exceltoJson.sheet1[i]["Item Type"] : null;
            this.tabledata[i].Weight = this.exceltoJson.sheet1[i]["Weight"] ? this.exceltoJson.sheet1[i]["Weight"] : null;
            this.tabledata[i].uom = this.exceltoJson.sheet1[i]["UOM"] ? this.exceltoJson.sheet1[i]["UOM"] : null;
            this.tabledata[i].hsnCode = this.exceltoJson.sheet1[i]["HSN Code"] ? this.exceltoJson.sheet1[i]["HSN Code"] : null;
            this.tabledata[i].itemTaxCategory = this.exceltoJson.sheet1[i]["Item Tax Category"] ? this.exceltoJson.sheet1[i]["Item Tax Category"] : null;
            this.tabledata[i].manufacturerName = this.exceltoJson.sheet1[i]["Manufacturer Name"] ? this.exceltoJson.sheet1[i]["Manufacturer Name"] : null;  
          }
        }
        // console.log("tabledata Before", this.tabledata);
        // if (this.tabledata.length > 1) {
        //   this.tabledata.pop();
        // }
        console.log("tabledata", this.tabledata);
        this.isShowPageLoader = false;
      };
    } else {
      this.tabledata = [];
      this.isShowPageLoader = false;
    }
  }

  get_header_row(sheet) {
    this.isShowPageLoader = true;
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
      // console.log("cell",cell)
      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
    }
    this.isShowPageLoader = false;
    return headers;
  }

  excelUpload() {
    this.isShowPageLoader = true;
    if (this.tabledata.length == 0) {
      this.toastr.info("No Data Available");
      this.isShowPageLoader = false;
      return;
    }
    let reqObj: any = {
      "countryCode": "IN",
      "userId": 0,
      "spareparts": this.tabledata
    }
    this.masterService.post(reqObj, '/WarehouseSpareParts/PartsUploadFromExcel').subscribe(
      (resp: any) => {
        this.isShowPageLoader = true;
        if (resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.isActiveResult = true;
          this.result = resp;
          this.toastr.success(resp.statusMessage);
        }
        if (resp.statusCode == 400) {
          this.toastr.error(resp.statusMessage);
          this.isShowPageLoader = false;
          // this.dialogRef.close();
        }
        this.isShowPageLoader = false;
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.resp.statusMessage);
      })
  }

  sampleExcel() {
    var host = document.location.origin;
    let link = document.createElement("a");
    link.download = "warehousePartSample.xlsx";
    // link.href = "src/assets/sparePartSample.xlsx"; // Local
    link.href = host + "/assets/sparePartSample.xlsx"; // Live
    link.click();
  }

}
import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { PartCostRootObject } from '../../api-services/dto/part-cost';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { PartSearchComponent } from '../part-search/part-search.component';
@Component({
  selector: 'app-part-price',
  templateUrl: './part-price.component.html',
  styleUrls: ['./part-price.component.scss']
})
export class PartPriceComponent implements OnInit {
  public lang:any;
  public isShowPageLoader:boolean = false;
  public vendorName:any;
  public partDetails:any  ={}
  public masterDetails: any;

  constructor( public dialogRef: MatDialogRef<PartPriceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    private masterService: MasterService,
    public toastr: ToastrService) { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();      
      if(this.data) {
      this.masterDetails = this.data.masterData;
      this.partDetails = this.data.partInfo && this.data.partInfo.length > 0 ?this.data.partInfo[0]:{};     
      this.partDetails.active =  this.data.partInfo[0].active;
      }
    }
  }
close() {
  this.dialogRef.close(false);
}
validatePartCost(){
   let costError:any = 0;
   if(!this.partDetails.partId){
    this.toastr.error('Part Id is empty');
    costError++; 
   }
   if (!this.partDetails.partNo) {
     this.toastr.error('Part No is empty');
     costError++; 
   }
   if (!this.partDetails.partDescription) {
    this.toastr.error('Part Description is empty');
    costError++;
  }
  if (!this.partDetails.manufactureId){
    this.toastr.error('Manufacture Name is empty ');
    costError++;
  }
 
  if (!this.partDetails.cost) {
    this.toastr.error('Part cost is empty');
    costError++;
  }
  if (!this.partDetails.validFrom) {
    this.toastr.error('Part validFrom  is empty');
    costError++;
  }
  // if (!this.partDetails.validFrom) {
  //   this.toastr.error('Part validFrom  is empty');
  //   costError++;
  // }
  if(this.data && !this.data.type){   
    if (!this.partDetails.margin) {
      this.toastr.error('Part margin  is empty');
      costError++;
    }
  }
    if (!this.partDetails.MRP) {
    this.toastr.error('Part MRP  is empty');
    costError++;
  }
   
 
  if (costError > 0) {
    return false;
  } else {
    return true;
  }
}

savePartPrice(){
  // if(this.data && this.data.type=='MODIFY' && this.partDetails.isUpdate == false){
  //   this.toastr.info('Price Details cannot be modify for TVS Spare Parts')
  // }
   if(this.validatePartCost() == false) {
     return;
   }
   let reqPartPrice:any = {};   
   reqPartPrice.rowState = this.data && this.data.type == 'MODIFY' ? 'U':'I';
   reqPartPrice.sparePriceId=  this.data && this.data.type == 'MODIFY'? this.partDetails.priceId:null,
   reqPartPrice.dealerId =  CommonService.getUserData().DEALER_ID; 
   reqPartPrice.branchId = CommonService.getUserData().BRANCH_ID;
   reqPartPrice.partId = this.partDetails.partId;
   reqPartPrice.partNo = this.partDetails.partNo;
   reqPartPrice.manufactureId =this.partDetails.manufactureId;
   reqPartPrice.validFrom = new Date(this.partDetails.validFrom);
   reqPartPrice.margin =this.partDetails.margin;
   reqPartPrice.MRP = this.partDetails.MRP;
   reqPartPrice.cost =this.partDetails.cost;
   reqPartPrice.userId =CommonService.getUserData().USER_ID;
   reqPartPrice.active =this.partDetails.active;
   reqPartPrice.countryCode = CommonService.getDealerData().COUNTRY_CODE;
   
    
   this.isShowPageLoader = true;
   this.masterService.post(reqPartPrice, '/SpareParts/SaveAndUpdateSparePrice').subscribe(
    (resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.dialogRef.close({msg:'priceDone',partInfo:this.partDetails});
        this.toastr.success(resp.statusMessage);
      }
      else{
        this.isShowPageLoader = false;
        // this.dialogRef.close(this.partDetails);
        this.toastr.error(resp.statusMessage);
      }
      if( resp.statusCode == 306 ) {
        this.toastr.success(resp.statusMessage);
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.statusMessage);
    }
  );
}
/**
   * part search pop up
   */
  partSearch() {

    const dialogRef = this.dialog.open(PartSearchComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: { value: 'data', type: 'partSearch' }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {        
      //  if(result.obj.manufactureId == 1) {
      //   this.toastr.info('Price cannot be set for TVS Spare Parts')
      //   return;
      // }
        this.partDetails.manufactureId = result.obj.manufactureId;
        this.partDetails.partId =result.obj.partId;
        this.partDetails.partNo =result.obj.partNo;
        this.partDetails.partDescription =result.obj.partDescription;
      }
    });
  }

}

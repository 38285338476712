import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {
    public lang: any;
    public isShowPageLoader: any = false;
    locationList: any = [];
  partLocation: any ={};
  rackList: any = [];
    constructor(
      private masterService: MasterService,
      public toastr: ToastrService,
      public dialogRef: MatDialogRef<AddLocationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        if (CommonService.getLang()) {
          this.lang = CommonService.getLang();
        }
    }
    ngOnInit() {
     this.getLocationlist();
     this.partLocation.rack = '';
    }
    getRackList() {
      if (!this.partLocation.location) {
        return this.toastr.warning('Please select location');
      } else {
      this.isShowPageLoader = true;
      let params = new HttpParams();
      params = params.set('dlrid', CommonService.getUserData().DEALER_ID)
      .set('branchid', CommonService.getUserData().BRANCH_ID)
      .set('locid', this.partLocation.location.LOCATION_ID)
      this.masterService.get(params, '/SpareParts/GetRackList').subscribe((resp: any) => {
        if (resp && resp.statusCode === 200 && resp.racklist) {
          this.rackList = resp.racklist;
          this.isShowPageLoader = false;
        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
      });
    }
    }
    getLocationlist() {     
      this.isShowPageLoader = true;
      let params = new HttpParams();
      params = params.set('dlrid', CommonService.getUserData().DEALER_ID)
      .set('branchid', CommonService.getUserData().BRANCH_ID)      
      this.masterService.get(params, '/SpareParts/GetStorageLocation').subscribe((resp: any) => {
        if (resp && resp.statusCode === 200 && resp.storagelocList) {
          this.locationList = resp.storagelocList.length > 0 ? resp.storagelocList :[];
          this.partLocation.location = this.locationList.length > 0 ?this.locationList[0]:''
          if(this.partLocation.location)
          this.getRackList();
          this.isShowPageLoader = false;
        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
      });
    }
    addLocation(){
      this.dialogRef.close(this.partLocation);
    }
    }

  
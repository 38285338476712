import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-brand-master',
  templateUrl: './create-brand-master.component.html',
  styleUrls: ['./create-brand-master.component.scss']
})
export class CreateBrandMasterComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public brandId = 0;
  public brandName = "";
  public status : boolean = true;
  public isEdit: boolean = false;
  public isView: boolean = false;
  public buttonName: string = "Save";
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateBrandMasterComponent>) { }

  ngOnInit() {
    console.log(this.data);    
    if (this.data.type == "MODIFY") {
      this.brandId = this.data.value.BrandId;
      this.brandName = this.data.value.BrandName;
      this.status = this.data.value.Active;
      this.isEdit = true;
      this.isView = false;
      this.buttonName = "Update";
    }
    else if(this.data.type == "VIEW"){
      this.brandId = this.data.value.BrandId;
      this.brandName = this.data.value.BrandName;
      this.status = this.data.value.Active;
      this.isEdit = true;
      this.isView = true;
      this.buttonName = "Update";
    }
    
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

 

  saveData() {
    this.isShowPageLoader = true;
    if(this.brandName == "" || this.brandName == null){
      this.isShowPageLoader = false;
      this.toastr.info("Please enter a Brandname");
      return ""
    }
    let reqObj: any = {
        "BrandId": this.brandId,
        "BrandName": this.brandName,
        "Active": this.status
      };
    
    this.masterService.post(reqObj, '/masterManagement/createBrand').subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {           
            this.dialogRef.close('Hi');
            this.toastr.success(resp.statusMessage)
          }
          if(resp.statusCode == 400){
            this.toastr.info(resp.statusMessage)
          }
          if (resp.statusCode == 204 || resp.statusCode == 205) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        })
  }

  closePopup() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-hsn-code-master',
  templateUrl: './create-hsn-code-master.component.html',
  styleUrls: ['./create-hsn-code-master.component.scss']
})
export class CreateHsnCodeMasterComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public HsnId: any;
  public HsnCode:any;
  public HsnDescription:any;
  public ItemTypeId:any;
  public Active : boolean = false;
  public itemtypeList: any = [];
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateHsnCodeMasterComponent>) { }

  ngOnInit() {
    
    this.getMasterData();
    this.ItemTypeId = "";
    if (this.data.type == "MODIFY" || this.data.type == "VIEW") {
      this.HsnId = this.data.value.HsnId;
      this.HsnCode = this.data.value.HsnCode;
      this.HsnDescription = this.data.value.HsnDescription;
      this.Active = this.data.value.Active;
    }
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  getMasterData() {
    this.masterService.get('', '/WarehouseSpareParts/PartMasterInitialinfo?dealerId=' + '100000013' +
      '&branchId=' + '1').subscribe(
        (resp: any) => {
          if (resp && resp.statusCode === 200) {
            this.itemtypeList = resp.itemtypeList;
            this.ItemTypeId = this.data.value.ItemTypeId;
          } else {
            this.toastr.error(resp.message);
          }
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }, error => {
          if (error.status == 401) {
            this.isShowPageLoader = false;
            this.loginService.logout();
          }
          this.toastr.error(error.error.statusMessage);
          this.isShowPageLoader = false;
        });
  }

  saveHSNCode() {
    this.isShowPageLoader = true;
    let reqObj: any = 
    {
      "HsnId":  this.HsnId?this.HsnId:0,
      "HsnCode": this.HsnCode?this.HsnCode:'',
      "Active": this.Active?this.Active:false,
      "ItemTypeId":this.ItemTypeId?this.ItemTypeId:'',
      "HsnDescription": this.HsnDescription?this.HsnDescription:'',
    };
    if(this.HsnCode == "" || this.HsnCode == null || this.HsnCode == undefined){
      this.toastr.info("HSN Code Should not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if(this.HsnDescription == "" || this.HsnDescription == null || this.HsnDescription == undefined){
      this.toastr.info("HSN Description Should not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if(this.ItemTypeId == "" || this.ItemTypeId == null || this.ItemTypeId == undefined){
      this.toastr.info("ItemTypeId Should not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    this.masterService.post(reqObj, '/masterManagement/createHSN').subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.dialogRef.close(true);
            this.toastr.success(resp.statusMessage)
          }
          if(resp.statusCode == 400){
            this.toastr.info(resp.statusMessage)
          }
          if (resp.statusCode == 204 || resp.statusCode == 205) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.isShowPageLoader = false;
            this.toastr.error(error.error.statusMessage);
          }
        })
  }

  closePopup() {
    this.dialogRef.close();
  }
}

import { VehicleAccessory, RackBinDetail } from 'src/app/api-services/dto/booking-accessory';
export class AngBookingInvoiceDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  BOOKING_ID: number;
  BOOKING_NO: number;
  BOOKING_DATE: Date | string;
  ENQUIRY_ID: number;
  ENQUIRY_NO: number;
  QUOTATION_ID: number | null;
  ALLOTMENT_DATE: Date | string;
  CUSTOMER_ID: number;
  END_USER_ID: number;
  SALE_MODE: number;
  CUST_MNG_INSR: boolean;
  INS_TYPE_ID: number | null;
  INS_COMP_ID: number | null;
  INSR_CNOTE_GIVEN: boolean;
  INS_POLICY_DT: Date | string | null;
  INS_VALID_TILL: Date | string | null;
  INS_POLICY_NO: string;
  CUST_MNGD_REG: boolean;
  RTO_ID: number | null;
  REGIS_TYPE_ID: number | null;
  INV_STATUS: number;
  IS_RETURNED: number;
  DEL_WOUT_REG: boolean;
  DLR_DEL_DATE: Date | string | null;
  CUST_DEL_DATE: Date | string | null;
  TOT_AMT_PAID: number | null;
  TOT_AMT_DUE: number | null;
  TOT_AMT_PNDG: number | null;
  SPL_REG_REQ: string;
  RefCustName: string;
  REF_CUST_ID: number | null;
  REF_CUST_TY_ID: number;
  ALLOTMENT_ID: number | null;
  VEHICLE_ID: number;
  ACC_CHARGES: number | null;
  REG_CHARGES: number | null;
  INS_CHARGES: number | null;
  OTH_CHARGES: number | null;
  ACCESS_LOCATION_ID: number | null;
  BillType: number;
  rowstate: string;
  PAYMENT_MODE_ID: number;
  ResProofCollected: boolean;
  ResProofRejected: boolean;
  Tools: boolean;
  UserManual: boolean;
  DuplicateKey: boolean;
  DeliveryRemarks: string;
  DeliveredBy: number;
  DeliveryTime: any;
  SpecialRequest: string;
  COMPANY_ID: number;
  UserID: number | null;
  CrmRefCode: string;
  emanual: boolean;
  welcomeKitNo: string;
  T_AvaiCreditLmt: number;
  Invoice_ID: number;
  Invoice_NO: number;
  COUNTRY_CODE: string;
  IS_RETRO_FIT: boolean;
  customer: AngCustomerDO;
  Controls: AngControlsDO;
  Invoice: AngInvoiceDO;
  HPDetails: AngBookingHPDetailDO;
  selfHPDetails: AngBookingSelfHpDO;
  registrationDetails: AngRegInvoiceDo;
  accessoryDetails: AngVehAccessory;
  voucherList: VoucherDO[];
  BatteryDetailsList: EVVehicleBatteryDO[];
  EV_INSTALL_COST: number;
  IS_EV_VEH: boolean;
  INTERNET_ENQUIRY_ID: number;
  EInvoiceUploadClrTax : boolean;
  JsonDownload : boolean;
  EInvoiceUploadClrTaxEnabled : boolean;
  JsonDownloadEnabled : boolean;

  IsInitiatied: boolean;
  IsCompleted: boolean;
}

export class AngCustomerDO {
  DEALERID: number;
  CUSTOMER_ID: number;
  CUST_NAME: string;
  CONTACT_NO: string;
  REF_CUST_TY_ID: number;
  CUST_TY_ID: number;
  CUST_GRP_ID: number;
  AVAIL_CREDIT_LIMIT: number;
  SL_CODE: number | null;
  CompleteAddress: string;
  CUST_CAT_ID: number;
  CUST_TAX_CAT_ID: number | null;
  END_USER_ID: number | null;
  IsCSDCustomer: boolean;
  STATE_ID: string;
  GST_IN_NO: string;
  PAN_NO:String;
}

export class AngControlsDO {
  Drp_WelcomeKitNo: boolean;
  Lbl_KitNo: boolean;
  Lbl_CrmRefCustCode: boolean;
  Txt_CrmRefCode: boolean;
  IsRegInvAllowed: boolean;
  IsAccInvAllowed: boolean;
  IsRetroFitment: boolean;
  EManualCheck: boolean;
  IsInitiatied:boolean;
  IsCompleated:boolean;
  IsCompleted:boolean;
}

export class AngInvoiceDO {
  DEALER_ID: number;
  BranchID: number;
  CUSTOMER_ID: number;
  INVOICE_DATE: Date | string;
  T_ExShowroomPrice: number;
  T_LineLevelDiscounts: number;
  T_VehicleTaxAmount: number;
  T_BillLevelDisc: number;
  T_TotalAccCharges: number;
  T_TotalRegCharges: number;
  T_Total1: number;
  T_Total2: number;
  T_RoundOff: number;
  T_TotalAmount: number;
  T_AmtPending: number;
  T_CrmDiscount: number;
  T_TotalAmtRecvd: number;
  T_UtiizedBookingAdv: number | null;
  T_HeaderDiscount: number;
  T_RedemptionCode: string;
  T_AmtRecvdAtInvLvl: number | null;
  VehicleDetails: AngInvoiceVehicleDetailDO[];
  USER_ID: number;
  SALES_DISC_AMT: number;
}

export class AngInvoiceVehicleDetailDO {
  INVOICE_DATE: Date | string;
  QTY: number | null;
  MODEL_ID: string;
  PART_ID: string;
  DESCRIPTION: string;
  VEHICLE_ID: number;
  FRAME_NO: string;
  ENGINE_NO: string;
  Service_Booklet_No: string;
  KEY_NO: string;
  EX_SHRM_PRICE: number;
  SERIES: string;
  BOOKLET_NO: string;
  REASON: string;
  REASON_ID: number;
  AMC_ID: number | null;
  AMCPRICE: number | null;
  TOT_AMC_AMT: number | null;
  COMP_WARRANTY_PERIOD: number;
  WARRANTY_PERIOD: number;
  AMCTax: number | null;
  tax_category: number | null;
  ITEM_TYPE_ID: number;
  _DummyActualDiscount: number;
  _DummyDiscountID: number;
  ActualDiscount: number;
  DISCOUNT_ID: number;
  HSN_CODE: string;
  HSN_ID: number;
  VEH_MARGIN: number;
  ManualDiscount: number;
  schemeDiscount: number;
  TotalAmount: number;
  RETRO_FITMENT_PRICE: number;
  ACCESS_LOC_ID: number;
  ACC_CHARGES: number;
  InvoicePrice: AngInvoicePriceDO;
  vehicleSchemes: AngVehicleSchemeDO[];
  AccessoryList: VehicleAccessory[];
}

export class AngInvoicePriceDO {
  ManualDiscount: number;
  MasterDiscount: number;
  SchemeDiscount: number;
  SGST: number;
  IGST: number;
  CGST: number;
  UTGST: number;
  Cess: number;
  TaxAmount: number;
  Tax: number;
  ApplicapleTaxes: TaxValueDO[];
}

export class TaxValueDO {
  TAX_APPLIED_OD: number;
  TAX_TYPE: TaxTypeDO;
  TAX_VALUE: number;
  TAX_TYPE_ID: number;
  PRINT_IN_INVOICE: boolean;
  TAX_RATE: number;
  GLCode: number;
  RunningNO: number;
  DESCRIPTION: string;
}

export class TaxTypeDO {
  TAX_TYPE_ID: number;
  DESCRIPTION: string;
  ACTIVE: boolean | null;
}

export class AngVehicleSchemeDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  DEALER_CONT_AMT: number | null;
  ACTIVE: boolean;
  DESCRIPTION: string;
  DISPLAY_DISC: boolean;
  IS_SELECT: boolean;
  IS_SRC_SCH: boolean;
  ITEM_TYPE_ID: number;
  LedgerList: AngGeneralLedgerDO[];
  NO_VEH_SOLD: number | null;
  ROW_STATE: string;
  SL_CODE: number;
  TOT_DISC_AMT: number | null;
  TVS_CONT_AMT: number | null;
  VEHICLE_SCH_ID: number;
  VEHI_SCH_TY_ID: number;
  SubLedgerDetails: AngSubLedgerDO;
}

export class AngGeneralLedgerDO {
  ACC_GRP_ID: number;
  AccGrpDescription: string;
  DESCRIPTION: string;
  GL_CODE: number;
}

export class AngSubLedgerDO {
  DESCRIPTION: string;
  SL_CODE: number;
  GLList: AngGeneralLedgerDO[];
}

export class AngBookingHPDetailDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  BOOK_HP_DET_ID: number;
  BOOKING_ID: number;
  HP_SCH_ID: number | null;
  HP_AMT_APPR: number | null;
  EMI: number | null;
  PERIOD: number | null;
  PERIOD_TYPE: boolean;
  INTEREST: number | null;
  HP_AMT_REQ: number | null;
  HP_DOC_STAT: boolean;
  REL_NOTE: boolean;
  REL_NOTE_NO: string;
  REL_NOTE_DT: Date | string | null;
  PROPOS_DATE: Date | string | null;
  REASON_ID: number | null;
  DOWN_PAYMENT: number | null;
  FIN_COMP_ID: number | null;
  ROW_STATE: string;
}

export class AngBookingSelfHpDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  BOOKING_ID: number;
  IS_DLR_HP_REL_NOT_RECV: boolean;
  LOAN_AMOUNT: number | null;
  COMPANY: string;
  EMI: number | null;
  NO_OF_PERIOD: number | null;
  INTEREST: number | null;
  PERIOD_TYPE: boolean;
  DOWN_PAYMENT: number | null;
  IS_DLR_HP: boolean;
  IS_SELF_HP: boolean;
  HP_SCHEME: string;
  FIN_COMP_ID: number | null;
  PROPOS_DATE: Date | string | null;
  REASON_ID: number | null;
  HP_SCH_ID: number | null;
  REL_NOTE: boolean;
  REL_NOTE_NO: string;
  DOC_NAME: string;
  REL_NOTE_DT: Date | string | null;
  TOTAL_ADJ_VAL: number | null;
  ROW_STATE: string;
  CountofSelfHP: number;
  DP_PRICE_LIST: number | null;
  DP_BAYAR: number | null;
  IS_NEW_SCHEME: boolean | null;
  DOWN_PAY_AMT: number | null;
}

export class AngRegInvoiceDo {
  T_TotalRegChrgs: number;
  T_TotalManualDiscount: number;
  T_TotalMasterDiscount: number;
  Part_desc: string;
  Invoice_qty: number;
  RegChrgDetails: AngVehicleInvoicePartDetDO[];
}

export class AngVehicleInvoicePartDetDO {
  DISCOUNT_ID: number | null;
  IN_TAX_BASE: number | null;
  Discount: number | null;
  DISC_VALUE: number | null;
  ActualDiscount: number | null;
  MANUAL_DISC: number | null;
  ModifyDiscount: number | null;
  TAX: number | null;
  INS_CNOTE_NO: number | null;
  INS_CNOTE_DT: Date | string | null;
  REG_AMT_PAID: number | null;
  REG_AMT_RET: number | null;
  PRINT_COUNT: number | null;
  temp_reg_id: number | null;
  expiry_date: Date | string | null;
  Is_returned: number | null;
  QTY: number | null;
  description: string;
  amount: number;
  VEHICLE_SCH_ID: number;
  regis_charge_id: number | null;
  REGIS_TYPE_ID: number;
  Rate: number | null;
  Inv_Regn_Id: number | null;
  COUNT: number;
  HSRP_PART: string;
  Is_Select: boolean;
  Row_State: string;
  ITEM_TYPE_ID: number | null;
  HSN_CODE: string;
  CGST: number | null;
  SGST: number | null;
  IGST: number | null;
  UTGST: number | null;
  CESS: number | null;
  TOTAL: number | null;
  INVOICE_ID: number;
  INVOICE_NO: number;
}

export class AngVehAccessory {
  T_Acc_Amount: number;
  Part_desc: string;
  qty: number;
  customerId: number;
  custGrp: number;
  LocationId: number;
  accessoryList: AngVehicleAccessoryDO[];
}

export class AngVehicleAccessoryDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  VEH_ACCESS_ID: number;
  ACCESSORY_ID: number;
  PART_ID: string;
  QUANTITY: number;
  DEF_ACCESS: boolean;
  ACTIVE: boolean;
  PART_NO: string;
  DESCRIPTION: string;
  LOCAL_DESCR: string;
  MANUF_ID: number;
  DISC_PERC: number;
  ITEM_TAX_CAT_ID: number;
  CUST_TY_ID: number;
  CUST_GRP_ID: number;
  ITEM_TYPE_ID: number;
  TOTAL_QUANTITY: number;
  PRICE: number;
  New_Price: number;
  MRP: number;
  Cost: number;
  FREE_OF_COST: boolean;
  SCHEME_DISCOUNT: number;
  SCHEME_PERC: number;
  DISCOUNT: number;
  MANUAL_DISCOUNT: number;
  TAX_RATE: number;
  CGST_RATE: number;
  SGST_RATE: number;
  IGST_RATE: number;
  UTGST_RATE: number;
  TAX_AMOUNT: number;
  ROW_STATE: string;
  MANUFACTURER_NAME: string;
  AvailableQty: number;
  AvailableQtyLocation: number;
  RackBinList: AngRackBinDetailDO[];
  RackListtoReduceStock: AngRackBinDetailDO[];
  ApplicableSchemeList: SpareSchemeDO[];
  SelectedSchemeList: SpareSchemeDO[];
  MasterDiscDetails: AngVehicleDiscountDO;
  TaxList: AngTaxValueDO[];
  TotalPrice: number;
  NetTotalPrice: number;
  RackID: number;
  Count: number;
  IsSelected: boolean;
  Location_ID: number;
  HSN_CODE: string;
  IsModified: boolean;
}

export class AngRackBinDetailDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  SPARE_PART_ID: number;
  RackBinDetailsID: number;
  PartRKBNID: number;
  RackNo: number;
  RackDesc: string;
  CostPlus: number;
  Qty: number;
  ReturnQty: number;
  RequiredQty: number;
  IssuedQty: number;
  NetIssuedQty: number;
  NetRequiredQty: number;
  AvailableQty: number;
  QtyToBlock: number;
  QtyToFree: number;
  RowState: string;
  SafetyStock: number;
  FreeStockQty: number;
  FreeQty: number;
  BlockedQty: number;
  MANUFACTURERID: number;
  ManufacturerName: string;
  isTaxable: boolean;
  Cost: number;
  MRP: number;
  UNIT_PRICE: number;
  RRP: number;
  COST_PLUS: number;
  COST_PLUS_MARGIN: number | null;
  IsPartLocationDefault: boolean;
  IsLocationDefault: boolean;
  LOCATION_ID: number;
  IssueMode: number;
  LabSchemeType: number;
  taxRate: number;
  Rate: number;
  DefRack: boolean;
  RETURN_PART_DET_ID: number;
  PART_RK_BN_ID: number;
  IS_THRO_PARTS_RET: boolean;
}

export class SpareSchemeDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  SPARE_SCH_ID: number;
  SPARE_INV_PART_ID: number;
  SPDESCRIPTION: string;
  SPARE_SCH_TY_ID: number | null;
  VALID_FROM: Date | string | null;
  VALID_TILL: Date | string | null;
  DISPLAY_DISC: boolean;
  ITEM_TYPE_ID: number | null;
  PRINT_DESC: string;
  SALE_MODE_ID: number | null;
  MAT_TYPE_ID: number | null;
  MAT_GRP_ID: string;
  PURCHASE_TYPE: boolean | null;
  FSN_CLASS: number | null;
  CUST_TY_ID: number | null;
  CUST_GRP_ID: number | null;
  DISC_PERC: number | null;
  SCHEME_PERC: number;
  SL_CODE: number;
  CREATED_BY: number | null;
  CREATED_ON: Date | string;
  MODIFIED_BY: number | null;
  MODIFIED_ON: Date | string | null;
  ACTIVE: boolean;
  ROW_STATE: string;
  SparesList: string;
  SchemeValue: number;
  SchemeAmount: number;
  LedgerList: GeneralLedgerDO[];
  Itemtype: ItemTypeDO;
  MaterialGrp: MaterialGroupDO;
  MaterialTyp: MaterialTypeDO;
  CustomerTyp: CustomerTypeDO;
  CustomerGrp: CustomerGroupDO;
  SubLedgerDetails: SubLedgerDO;
  SchemeType: SpareSchemeTypeDO;
  GL_CODE: number;
  DESCRIPTION: string;
  ACC_GRP_ID: number;
  AccGrpDescription: string;
  SparePart: any;
  SPARE_PART_ID: number;
  SaleMode: string;
  Ledger: SubLedgerDO;
  FSN: string;
  IS_SELECT: boolean;
  INV_ACCESS_ID: number;
  Scm_Amt: number;
}

export class GeneralLedgerDO {
  GL_CODE: number;
  DESCRIPTION: string;
  ACC_GRP_ID: number;
  SL_REQ: boolean;
  ACTIVE: boolean;
  AccGrpDescription: string;
  DEALER_ID: number;
  ROW_STATE: string;
}

export class ItemTypeDO {
  ITEM_TYPE_ID: number;
  DESCRIPTION: string;
  ITEM_CATEGORY: number;
  SERV_TAX_APPL: boolean;
  CST_PURCHASE: boolean;
  ACTIVE: boolean;
  ITEM_DESC: string;
}

export class MaterialGroupDO {
  MAT_GRP_ID: string;
  DESCRIPTION: string;
  ACTIVE: boolean;
  MAT_DESC: string;
}

export class MaterialTypeDO {
  MAT_TYPE_ID: number;
  DESCRIPTION: string;
  CONSIDER_MRP: boolean;
  TAX_PERC: number | null;
  ACTIVE: boolean;
  MATTYPR_DESC: string;
}

export class CustomerTypeDO {
  CUST_TY_ID: number;
  CUST_CAT_ID: number;
  DESCRIPTION: string;
  LAST_SALE: boolean;
  IS_FIRST_TIME: boolean;
  Active: boolean;
  LedgerList: GeneralLedgerDO[];
  CUST_TYPE: string;
  CUSTTYPE_DESCRIPTION: string;
}

export class CustomerGroupDO {
  CUST_GRP_ID: number;
  CUST_CAT_ID: number;
  DESCRIPTION: string;
  DEFAULT_GROUP: boolean;
  Active: boolean;
  CUST_GRP: string;
  CUSTGRP_DESCRIPTION: string;
}

export class SubLedgerDO {
  DEALER_ID: number;
  SL_CODE: number;
  GLList: GeneralLedgerDO[];
  DESCRIPTION: string;
  PARTY_CAT: number;
  ADDRESS_LINE_1: string;
  ADDRESS_LINE_2: string;
  ADDRESS_LINE_3: string;
  CITY: string;
  STATE_ID: string;
  PIN_CODE: string;
  PHONE_NO: string;
  IS_MAPPED: boolean;
  CREATED_BY: number;
  CREATED_ON: Date | string;
  MODIFIED_BY: number;
  MODIFIED_ON: Date | string;
  ACTIVE: boolean;
  ROW_STATE: string;
}

export class SpareSchemeTypeDO {
  DEALER_ID: number;
  SPARE_SCH_TY_ID: number;
  DESCRIPTION: string;
  ACTIVE: boolean;
  ROW_STATE: string;
  SCHTYP_DESC: string;
}

export class AngVehicleDiscountDO {
  DISCOUNT_ID: number;
  ITEM_TYPE_ID: number | null;
  DISC_AMT: number | null;
  LedgerList: GeneralLedgerDO[];
}

export class AngTaxValueDO {
  TAX_APPLIED_OD: number;
  TAX_VALUE: number;
  TAX_TYPE_ID: number;
  PRINT_IN_INVOICE: boolean;
  TAX_RATE: number;
  GLCode: number;
  DESCRIPTION: string;
}

export class VoucherDO {
  DEALER_ID: number;
  BRANCH_ID: number;
  VOUCHER_ID: number | null;
  VOUCHER_NO: number | null;
  VOUCHER_DT: Date | string;
  FIN_YEAR: number | null;
  COMPANY_ID: number | null;
  VCHR_TYPE_ID: number | null;
  REV_VCHR_TYPE_ID: number | null;
  REV_DOC_TYPE: number | null;
  REC_VCHR_TYPE_ID: number | null;
  PAY_VCHR_TYPE_ID: number | null;
  REC_DOC_TYPE: number | null;
  PAY_DOC_TYPE: number | null;
  DOC_TYPE: number | null;
  DOC_NO: number | null;
  PAYMENT_MODE_ID: number | null;
  CRED_LMT_TYPE: number | null;
  BANK_ID: number | null;
  DOCUMENT_ID: number | null;
  DOC_DATE: Date | string | null;
  BASE_DOC_TYPE: number;
  PARTY_CAT: number | null;
  PARTY_CODE: number | null;
  PARTY_NAME: string;
  VCHR_VALUE: number | null;
  UNRECON_VAL: number | null;
  VCHR_STATUS: number | null;
  REVERSE_VCHR_STATUS: number | null;
  INSTR_NO: string;
  INSTR_DATE: Date | string | null;
  INSTR_AMT: number | null;
  INSTRUMENT_ON: string;
  BANK_BRANCH: string;
  ACCOUNT_NO: string;
  CRED_CARD_TY_ID: number | null;
  CRED_CARD_EXP_DT: Date | string | null;
  APPROVAL_NO: string;
  BANK_RECON_ID: number | null;
  BANK_RECON_NO: number | null;
  ACC_RECON_ID: number | null;
  ACC_RECON_NO: number | null;
  CR_DR_ADJ: boolean;
  NARRATION: string;
  BASE_VOUCHER_ID: number | null;
  IS_BOUNCED: number | null;
  BOUNCED_BANK_RECON_ID: number | null;
  TDS_APPLIED: boolean;
  APPLIED_TDS_AMOUNT: number;
  CREATED_BY: number | null;
  CREATED_ON: Date | string | null;
  MODIFIED_BY: number | null;
  MODIFIED_ON: Date | string | null;
  ACTIVE: boolean;
  ROW_STATE: string;
  CompanyList: any;
  VoucherType: string;
  Credit_Card_Type: string;
  Bank_Recon_Status: number | null;
  COMPANY_CODE: string;
  company_name: string;
  PaymentMode: string;
  AccReconStatusID: number;
  AccReconStatus: string;
  FROM_DATE: Date | string | null;
  TO_DATE: Date | string | null;
  VOUCHER_ACC_DETAILS: any;
  ACCOUNT_RECON_DET: any;
  LEDGER_ENTRY_DET: any;
  STATUS: number | null;
  ADJUST_AMOUNT: number;
  OpenDocumentValue: number;
  UnReconciledValue: number;
  VoucherCommonList: any;
  VStatus: string;
  MailDetail: any;
  AccountReconciliation: any;
  AccountReconciliationDetail: any;
  SL_CODE: number;
  BANK_NAME: string;
  BALANCE_AMT: number;
  EMPLOYEE_ID: number | null;
}

export class EVVehicleBatteryDO
{
    ACTIVE: boolean;
    BATTERY_NO: string;
    CHARGER_NO: string;
    CREATED_BY: number;
    CREATED_ON: Date | string;
    EV_VEH_BATTERY_ID: number;
    MODIFYED_BY: number | null;
    MODIFYED_ON: Date | string | null;
    MOTOR_NO: string;
    VEHICLE_ID: number;
}
import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { CustomiseTableComponent } from 'src/app/shared/customise-table/customise-table.component';
import { VehicleAllotment, AllotmentDetail, AllotmentPartStock, Vehicle } from 'src/app/api-services/dto/booking'
import { VehicleAccessory, RackBinDetail } from 'src/app/api-services/dto/booking-accessory'
import { RackComponent } from '../rack/rack.component';
import { ViewSpareScheme } from 'src/app/shared/spare-scheme/spare-scheme';


@Component({
  selector: 'app-sales-accessories',
  templateUrl: './sales-accessories.component.html',
  styleUrls: ['./sales-accessories.component.scss']
})
export class SalesAccessoriesComponent implements OnInit {
  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public bookingData: any;
  public storageLoc: any = [];
  public vehAccessList: Array<VehicleAccessory> = [];
  public partDesc: any;
  public bookedQty: any;
  public storLocId: any;
  public saleOrderType: any;
  public TotalAmount: any;
  public selIndex: any;
  public isDisable: boolean = false;
  public isHeadDisb: boolean = true;
  public isRootDisabled: boolean;
  public isView: boolean;

  constructor(
    public dialogRef: MatDialogRef<SalesAccessoriesComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private masterServices: MasterService, private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    this.bookingData = this.data;
  }
  ngOnInit() {
    this.lang = CommonService.getLang();
    this.inlineFilter = {
      PART_NO: '',
      DESCRIPTION: '',
      QUANTITY: ''
    };
    this.loadLocation();
    this.partDesc = this.bookingData.PART_DESC;
    this.bookedQty = this.bookingData.BOOKED_QTY;
    this.saleOrderType = 'Counter';
    if (this.bookingData.ScreenType == 'Quotation' && this.bookingData.PageType == 'MODIFY') {
      this.isDisable = true;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  loadLocation() {
    var locData: any;
    this.masterSales.get('', '/Sales/LoadStorageLocationForAccessories?DEALER_ID=' + CommonService.getDealerData().DEALER_ID + '&BRANCH_ID=' + CommonService.getDealerData().BRANCH_ID).subscribe(
      resp => {
        locData = resp;
        this.storageLoc = locData.data;
        this.storLocId = this.storageLoc.length >= 1 ? this.storageLoc[0].LOCATION_ID : '';
        if (this.bookingData.AccessList != null && this.bookingData.AccessList.length > 0) {
          this.vehAccessList = this.bookingData.AccessList;
        } else {
          this.displayAccessoryMapped(null);
        }

      },
      error => {
        this.toastr.error('Location not loaded')
      }
    )
  }

  displayAccessoryMapped(event) {
    var partData: any;
    // const selectEl = event.target;
    // const val = selectEl.value;
    //alert(this.storLocId);
    let reqObj: any = {};
    reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reqObj.BOOKING_ID = this.bookingData.BOOKING_ID;
    reqObj.PartId = this.bookingData.PART_ID;
    reqObj.CUSTOMER_ID = this.bookingData.CUSTOMER_ID;
    reqObj.DealerState = this.bookingData.DEALER_STATE;
    reqObj.DealerCountry = this.bookingData.DEALER_COUNTRY;
    reqObj.LocationID = this.storLocId;
    this.isShowPageLoader = true;
    this.masterSales.post(reqObj, '/Sales/GetVehicleAssessory').subscribe(
      resp => {
        this.isShowPageLoader = false;
        partData = resp;
        if (partData.data == null) {
          this.toastr.warning('No Accessories mapped for this model');
          this.dialogRef.close();
        }
        else {
          this.vehAccessList = partData.data;
          if (this.vehAccessList.length < 0) {
            this.toastr.warning('No Accessories mapped for this model');
            this.dialogRef.close();
          }
        }
        //console.log(this.vehAccessList);
        // if (this.allotPartStock) {
        //   this.vehicleStockList = partData.data.VehicleList;
        //   this.vehPartStockList = this.vehicleStockList.filter(h => h.PART_ID == this.allotmentDet.PART_ID);
        // }
      },
      error => {
        this.toastr.error('Accessories not loaded')
      }

    )
    // this.isShowPageLoader = false;
  }

  changeQty(qty) {
   // alert(qty);
  }

  showRackList(RackList, PartNo, Qty, i) {
    if (RackList == null || RackList.length <= 0) {
      this.toastr.warning('Stock not available for selected the line item');
      return;
    }
    this.selIndex = i;
    const dialogRef = this.dialog.open(RackComponent, {
      width: '60%',
      minHeight: '250px',
      maxHeight: '600px',
      height: 'auto',
      data: {
        data: RackList,
        partNo: PartNo,
        reqQty: Qty,
        rowIndex: i
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
     console.log(result);
      //  alert(result.selectedScheme);
      if (result && result.length > 0) {
        var selectedItem: any = result.filter(item => item.RequiredQty > 0);
        this.vehAccessList[this.selIndex].RackListtoReduceStock = new Array<RackBinDetail>();
        this.vehAccessList[this.selIndex].RackListtoReduceStock = selectedItem;
        this.vehAccessList[this.selIndex].IsSelected = true;
        this.vehAccessList[this.selIndex].BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
        this.vehAccessList[this.selIndex].DEALER_ID = CommonService.getDealerData().DEALER_ID;
       
        let reqObj: any = {};
        reqObj.AccessoryDet = this.vehAccessList[this.selIndex];
        reqObj.CustomerDet = this.bookingData.CustomerDet;
        reqObj.Dealer_State = this.bookingData.DEALER_STATE;
        reqObj.Dealer_Country = this.bookingData.DEALER_COUNTRY;
        this.isShowPageLoader = true;
        var partData: any;
        this.masterSales.post(reqObj, '/Sales/ApplyTaxOnVehAccessorySelectedRackBin').subscribe(
          resp => {
            this.isShowPageLoader = false;
            partData = resp;
            this.vehAccessList[this.selIndex] = partData.data;
            this.vehAccessList[this.selIndex].IsSelected = true;
            this.vehAccessList[this.selIndex].BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
            this.vehAccessList = [...this.vehAccessList]; // For Reloading the Grid Values
            this.calculateLineTotal();
            console.log(this.vehAccessList.length);
            // if (this.allotPartStock) {
            //   this.vehicleStockList = partData.data.VehicleList;
            //   this.vehPartStockList = this.vehicleStockList.filter(h => h.PART_ID == this.allotmentDet.PART_ID);
            // }
          },
          error => {

            this.toastr.error('Apply Tax on Selected Rack Bin Failed. ')
          }
        )
      }

    })
  }

  calculateLineTotal() {
    let price=0;
    if (this.vehAccessList.length > 0) {
      this.vehAccessList.forEach((itm) => {
        if (itm.RackListtoReduceStock !=null && itm.RackListtoReduceStock.length>0) {    
          price=price+itm.TotalPrice;
        }
      })
    }
  //  this.TotalAmount=price;
    this.TotalAmount=Math.round(price);
  }
  showRackBinPopup() {
    if (this.selected.length <= 0) {
      this.toastr.warning('Please Select the line item');
      return;
    }
    let index = 0;
    let matchIndex = 0;
    this.vehAccessList.forEach((itm) => {
      if (itm.VEH_ACCESS_ID == this.selected[0].VEH_ACCESS_ID) {
        matchIndex = index;
      }
      index = index + 1;
    })
    if (this.selected[0].RackBinList == null || this.selected[0].RackBinList.length <= 0) {
      this.toastr.warning('Stock not available for selected the line item');
      return;
    }
    this.showRackList(this.selected[0].RackBinList, this.selected[0].PART_NO, this.selected[0].QUANTITY, matchIndex);

  }

  showScheme() {
    if (this.selected.length <= 0) {
      this.toastr.warning('Please Select the line item');
      return;
    }
    let index = 0;
    let matchIndex = 0;
    this.vehAccessList.forEach((itm) => {
      if (itm.SPARE_SCH_ID == this.selected[0].SPARE_SCH_ID) {
        matchIndex = index;
      }
      index = index + 1;
    });
    const dialogRef = this.dialog.open(ViewSpareScheme, {
      width: '30%',
      minHeight: '250px',
      maxHeight: '500px',
      height: 'auto',
      data: {
        type: 'SCHEMES',
        appSchemeList: this.selected[0].ApplicableSchemeList,
        selectedSchemeList: this.selected[0].SelectedSchemeList,
        rowIndex: matchIndex,
        SPARE_SCH_ID: this.selected[0].SPARE_SCH_ID
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      //   alert(result.schemeAmt);
      //  alert(result.vehSchemeId);
      if (result && result.schemeAmt > 0) {

        this.vehAccessList[matchIndex].SelectedSchemeList = result.selectedScheme;
        this.vehAccessList[matchIndex].SCHEME_DISCOUNT = result.schemeAmt;
        this.vehAccessList[matchIndex].SPARE_SCH_ID = result.vehSchemeId;
        // this.changeBookingPart(event, i, "SchemeDisc");
      }
      else if (result && result.schemeAmt == 0) {
        this.vehAccessList[matchIndex].SelectedSchemeList = result.selectedScheme;
        this.vehAccessList[matchIndex].SCHEME_DISCOUNT = result.schemeAmt;
        this.vehAccessList[matchIndex].SPARE_SCH_ID = result.vehSchemeId;
      } else {
        this.toastr.warning('Scheme Percentage is Empty')
      }
    })
  }

  saveAccessorySelected() {
    let rackSelected = 0;
    let vehAccDet = new Array<VehicleAccessory>();
    this.vehAccessList.forEach((itm) => {
      if (itm.RackListtoReduceStock != null && itm.RackListtoReduceStock.length > 0) {
        rackSelected = 1;
        vehAccDet.push(itm);
      }
    })
    if (rackSelected == 1) {
      let closeObj: any = {};
      closeObj.objVehAccList = this.vehAccessList;
      closeObj.Loc_Id = this.storLocId;
      console.log(closeObj);
    //  let closeObj = { objVehAcc: this.vehAccessList; Loc_Id: this.vehAccessList };
      this.toastr.success('Selected Accessories added to model part, Click Save to proceed.');
      this.dialogRef.close(closeObj);
    }
    else {
      this.toastr.warning('Rack Bin Selection not completed ');
    }
  }

}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js';
import 'zone.js/dist/long-stack-trace-zone.js';
import cssVars from 'css-vars-ponyfill';


if (environment.production) {
  enableProdMode();
}

cssVars({
  include: 'style',
  onlyLegacy: false,
  watch: true,
  onComplete(cssText, styleNode, cssVariables) {
    // console.log(cssText);
  }
});


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

import { Component, OnInit, Inject } from "@angular/core";
import { CommonService } from "src/app/api-services/common.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MasterService } from "src/app/api-services/master.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AngRegInvoiceDo } from "src/app/api-services/dto/vehicle-inv";
import { MasterSales } from "src/app/api-services/master.sales";

@Component({
  selector: "app-sales-search",
  templateUrl: "./registration-insurance-invoice.component.html",
  styleUrls: ["./registration-insurance-invoice.component.scss"],
})
export class RegistrationInsuranceInvoiceComponent implements OnInit {
  public lang: any;
  public selected = [];
  public partHeader: Array<any> = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public sales: any = {};
  public salesRows: any = [];
  public partDetails: any = [];
  public regDetails = new AngRegInvoiceDo();
  public filterObj: any = {
    enquiryNo: "",
    customerName: "",
    fromDate: "",
    toDate: "",
  };
  constructor(
    private dialogRef: MatDialogRef<RegistrationInsuranceInvoiceComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    private router: Router,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : "";
      this.regDetails = this.data.regCharges;
      this.partDetails = this.regDetails.RegChrgDetails;
    }
  }

  ngOnInit() {
    this.partHeader = [
      {
        name: this.lang.GENERAL.select,
        width: "wmpx-150",
      },
      {
        name: this.lang.SALES.description,
        width: "wmpx-300",
      },
      {
        name: this.lang.SALES.price,
        width: "wmpx-150",
      },
      {
        name: this.lang.SALES.schemeDisc,
        width: "wmpx-150",
      },
      {
        name: this.lang.SALES.discount,
        width: "wmpx-150",
      },
      {
        name: this.lang.SALES.ManualDis,
        width: "wmpx-150",
      },
    ];
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  searchList() {
    if (this.isActive === "enquiryNo") {
    }
  }
  /**
   * select search list
   */
  selectedItem() {
    let closeObj = { obj: this.regDetails };
    this.dialogRef.close(closeObj);
  }

  calculatePrice(index) {
    let reqObj: any;
    this.regDetails.RegChrgDetails = this.partDetails;
    reqObj = this.regDetails;
    let respInv: any;
    this.isShowPageLoader = true;
    this.masterSales
      .post(this.regDetails, "/Sales/UpdateRegistrationInsPrice")
      .subscribe((resp) => {
        respInv = resp;
        this.isShowPageLoader = false;
        if (respInv && respInv.statusCode === 200 && respInv.data && respInv.message === 'Success') {
          this.regDetails = respInv.data;
        } else if (respInv && respInv.statusCode === 500) {
          this.toastr.warning(respInv.message);
          this.isShowPageLoader = false;
        } else {
          this.toastr.warning(respInv.data);
          this.isShowPageLoader = false;
        }
      });
  }
  /**
   * Filter each columns
   * @param {String} value
   * @param {String} prop
   */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

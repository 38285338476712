import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import { dealerDetails } from 'src/app/api-services/dto/dealerDetails';
@Component({
  selector: 'app-create-dealer-master',
  templateUrl: './create-dealer-master.component.html',
  styleUrls: ['./create-dealer-master.component.scss']
})
export class CreateDealerMasterComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public dealerDetails: any = new dealerDetails();
  public countryList: any;
  public dealerClassList: any;
  public busunessTypeList: any;
  public areaList: any;
  public vendorTypeList: any;
  public territoryList: any;
  public activeAD: boolean = false;
  constructor(private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateDealerMasterComponent>) { }

  ngOnInit() {
    this.getMasterdata();
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  updateMasterData() {
    this.isShowPageLoader = true;
    if (this.data.type == "MODIFY") {
      this.dealerDetails.countryId = this.data.value.COUNTRY_CODE;
    }
    this.masterService.get('', '/Dealer/DealerMaster?countryId=' + this.dealerDetails.countryId)
      .subscribe(
        (resp: any) => {
          this.masterData = resp;
          this.busunessTypeList = resp.busunessTypeList;
          this.areaList = resp.areaList;
          this.territoryList = resp.territoryList;
          if (this.data.type == "MODIFY") {
            this.isShowPageLoader = true;
            this.dealerDetails.dealerId = this.data.value.DEALER_ID;
            this.masterService.get('', '/Dealer/GetDealerDetails?dealerId=' + this.dealerDetails.dealerId)
              .subscribe(
                (resp: any) => {
                  console.log("Dealer Details", resp.dealerDetails);
                  this.dealerDetails.dateOfCreation = CommonService.generalFormat(resp.dealerDetails.CREATED_ON);
                  this.dealerDetails.dealershipName = resp.dealerDetails.DEALERSHIP_NAME;
                  this.dealerDetails.tvsDealerId = resp.dealerDetails.TVS_DEALER_ID;
                  for (var i = 0; i < this.dealerClassList.length; i++) {
                    if (resp.dealerDetails.DLR_CLASS == i) {
                      this.dealerDetails.dealerClass = this.dealerClassList[i];
                    }
                  }
                  this.busunessTypeList.filter(key => {
                    if (resp.dealerDetails.BUSINESS_TYPE_ID == key.BUSINESS_TYPE_ID) {
                      this.dealerDetails.businessType = key.BUSINESS_TYPE_ID;
                    }
                  });
                  this.territoryList.filter(key => {
                    if (resp.dealerDetails.TERRITORY == key.SAP_ID) {
                      this.dealerDetails.territory = key.SAP_ID;
                    }
                  });
                  this.dealerDetails.cityCode = resp.dealerDetails.CITY_CODE;
                  this.dealerDetails.vehicleSaleType = resp.dealerDetails.DealerConfigSetting.IS_EV_NRML;
                  this.dealerDetails.internetBand = resp.dealerDetails.DLR_INFRASTRUCTURE;
                  if(resp.dealerDetails.BusinessSettings.FSC_CLAIM == 1){
                    this.dealerDetails.fscClaim = "SAP"
                  }
                  if(resp.dealerDetails.BusinessSettings.WARRANTY == 1){
                    this.dealerDetails.warrantyClaim = "SAP"
                  }
                  if(resp.dealerDetails.BusinessSettings.SPARE_PO == 1){
                    this.dealerDetails.sparePO = "SAP"
                  }
                  if(resp.dealerDetails.BusinessSettings.SPARE_CLIAM == 1){
                    this.dealerDetails.spareClaim = "SAP"
                  }
                  if(resp.dealerDetails.BusinessSettings.SPARE_GRN_TVS == 1){
                    this.dealerDetails.spareTVS = "SAP"
                  }
                  if(resp.dealerDetails.BusinessSettings.SPARE_GRN_AD == 5){
                    this.dealerDetails.spareGrnAD = "NA"
                  }
                  if(resp.dealerDetails.BusinessSettings.SALES_GRN == 1){
                    this.dealerDetails.salesGrn = "SAP"
                  }
                  if(resp.dealerDetails.BusinessSettings.SALES_GRN_AD == 5){
                    this.dealerDetails.salesGrnAD = "NA"
                  }
                  this.dealerDetails.isActive = resp.dealerDetails.ACTIVE;
                  this.dealerDetails.fscClaimAPS = resp.dealerDetails.IS_CLAIM_TO_APS;
                  this.dealerDetails.line1 = resp.dealerDetails.ADDRESS_LINE_1;
                  this.dealerDetails.line2 = resp.dealerDetails.ADDRESS_LINE_2;
                  this.dealerDetails.line3 = resp.dealerDetails.ADDRESS_LINE_3;
                  this.dealerDetails.city = resp.dealerDetails.CITY;
                  this.dealerDetails.state = resp.dealerDetails.STATE_ID;
                  this.dealerDetails.phoneNo = resp.dealerDetails.PHONE_NO;
                  this.dealerDetails.fax = resp.dealerDetails.FAX;
                  this.dealerDetails.emailId = resp.dealerDetails.EMAIL_ID;
                  this.dealerDetails.pinCode = resp.dealerDetails.PIN_CODE;
                  this.dealerDetails.area = resp.dealerDetails.AREA_ID;
                  this.dealerDetails.vcLimit = resp.dealerDetails.VEH_CRED_LIM;
                  this.dealerDetails.scLimit = resp.dealerDetails.SPR_CRED_LIM;
                  this.dealerDetails.vcLimitFI = resp.dealerDetails.FI_VEH_CRED_LIM;
                  this.dealerDetails.scLimitFI = resp.dealerDetails.FI_SPR_CRED_LIM;
                  this.dealerDetails.yearStart = resp.dealerDetails.ACC_FINYEAR_START_MONTH;
                  this.dealerDetails.yearEnd = resp.dealerDetails.ACC_FINYEAR_END_MONTH;
                  this.dealerDetails.panNo = resp.dealerDetails.PAN_NO;
                  this.dealerDetails.GSTINNO = resp.dealerDetails.GST_IN_NO;
                  this.dealerDetails.municipalNo = resp.dealerDetails.MUN_APP_NO;
                  this.dealerDetails.contactPerson = resp.dealerDetails.DEALER_NAME;
                  this.dealerDetails.field1 = resp.dealerDetails.FIELD_1;
                  this.dealerDetails.field2 = resp.dealerDetails.FIELD_2;
                  this.dealerDetails.field3 = resp.dealerDetails.FIELD_3;                  
                  this.isShowPageLoader = false;                
                }, error => {
                  if (error.status == 401) {
                    this.loginService.logout();
                  }
                  this.isShowPageLoader = false;
                  this.toastr.error(error.statusText);
                }
              );
          }
          this.isShowPageLoader = false;
        }, error => {
          if (error.status == 401) {
            this.loginService.logout();
          }
          this.isShowPageLoader = false;
          this.toastr.error(error.statusText);
        }
      );
  }

  getMasterdata() {
    this.isShowPageLoader = true;
    this.masterService.get('', '/Dealer/DealerMaster?countryId=' + "")
      .subscribe(
        (resp: any) => {
          if (this.data.type == "CREATE") {
            this.dealerDetails.countryId = -1;
          }
          this.dealerDetails.vcLimit = 0;
          this.dealerDetails.dealerType = 1;
          this.dealerDetails.scLimit = 0;
          this.dealerDetails.vcLimitFI = 0;
          this.dealerDetails.scLimitFI = 0;
          this.dealerDetails.isActive = true;
          this.dealerDetails.fscClaimAPS = false;
          this.dealerDetails.dealerClass = -1;
          this.dealerDetails.vendorType = -1;
          this.dealerDetails.businessType = -1;
          this.dealerDetails.vehicleSaleType = 0;
          this.dealerDetails.state = -1;
          this.dealerDetails.territory = -1;
          this.dealerDetails.cityCode = -1;
          this.dealerDetails.area = -1;
          this.dealerDetails.dateOfCreation = new Date();
          this.masterData = resp;
          this.countryList = resp.countryList;
          this.dealerClassList = resp.dealerClassList;
          this.vendorTypeList = resp.vendorTypeList;
          this.dealerDetails.yearEnd = resp.finYearEnd;
          this.dealerDetails.yearStart = resp.finYearStart;
          if (this.data.type == "MODIFY") {
            this.updateMasterData();
          }
          if (resp == null || resp == undefined || resp == '') {
            this.loginService.logout();
          }
          this.isShowPageLoader = false;
        }, error => {
          if (error.status == 401) {
            this.loginService.logout();
          }
          this.isShowPageLoader = false;
          this.toastr.error(error.statusText);
        }
      )
  }

  selectCountry(countryId) {
    this.dealerDetails.countryId = countryId;
    this.updateMasterData();
  }

  businessTypeChange(){
    if(this.dealerDetails.businessType == 3 || this.dealerDetails.businessType == 4 ||
      this.dealerDetails.businessType == 15){
        this.activeAD = true;
      }
      else{
        this.activeAD = false;
      }
  }

  panDuplicateCheck(){
    this.isShowPageLoader = true;
    let reqObj = {
      "dealerId": this.dealerDetails.dealerId ? this.dealerDetails.dealerId : 0,
      "panNo": this.dealerDetails.panNo,
      "countryCode": ""
    }
    this.countryList.filter(key=>{
      if(key.COUNTRY_ID == this.dealerDetails.countryId){
        reqObj.countryCode = key.COUNTRY_NAME;
      }
    });
    this.masterService.get(reqObj, '/Dealer/PANDuplicateCheck')
      .subscribe(
        (resp: any) => {
          if(resp.statusCode == 200){
            this.toastr.success(resp.statusMessage);
          }
          if(resp.statusCode == 306){
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        }, error => {
          if (error.status == 401) {
            this.loginService.logout();
          }
          this.isShowPageLoader = false;
          this.toastr.error(error.statusText);
        }
      );
  }

  saveDealerDetails() {
    this.isShowPageLoader = true;
    if (this.dealerDetails.countryId == -1) {
      this.toastr.info("Country is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    // if (this.dealerDetails.dealerId == null || this.dealerDetails.dealerId == undefined || this.dealerDetails.dealerId == '') {
    //   this.toastr.info("Dealer ID Shoud Not Be Empty");
    //   this.isShowPageLoader = false;
    //   return;
    // }
    if (this.dealerDetails.dealershipName == null || this.dealerDetails.dealershipName == undefined || this.dealerDetails.dealershipName == '') {
      this.toastr.info("Dealership Name Shoud Not Be Empty");
      this.isShowPageLoader = false;
      return;
    }
    // if (this.dealerDetails.tvsDealerId == null || this.dealerDetails.tvsDealerId == undefined || this.dealerDetails.tvsDealerId == '') {
    //   this.toastr.info("TVS Dealer ID Name Shoud Not Be Empty");
    //   this.isShowPageLoader = false;
    //   return;
    // }
    if (this.dealerDetails.dealerClass == -1) {
      this.toastr.info("Dealer Class is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    for (var i = 0; i < this.dealerClassList.length; i++) {
      if (this.dealerDetails.dealerClass == this.dealerClassList[i]) {
        this.dealerDetails.dealerClass = i;
      }
    }
    if (this.dealerDetails.businessType == -1) {
      this.toastr.info("Business Type is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if (this.dealerDetails.state == -1) {
      this.toastr.info("State is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if (this.dealerDetails.area == -1) {
      this.toastr.info("Area is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if (this.dealerDetails.isActive == true) {
      if (this.dealerDetails.countryId == "IN" || this.dealerDetails.countryId == "3W") {
        if (this.dealerDetails.panNo == "" || this.dealerDetails.panNo == null || this.dealerDetails.panNo == undefined) {
          this.toastr.info("Pan No Should Not Be Empty");
          this.isShowPageLoader = false;
          return;
        }
      }
    }
    if (this.dealerDetails.territory == -1) {
      if (this.dealerDetails.countryId != "MY") {
        this.toastr.info("Territory Should Not Be Empty");
        this.isShowPageLoader = false;
        return;
      }
    }
    if(this.activeAD == true){
      if (this.dealerDetails.mainDealerCode == null || this.dealerDetails.mainDealerCode == undefined || 
        this.dealerDetails.mainDealerCode == '') {
        this.toastr.info("Main Dealer Code Shoud Not Be Empty");
        this.isShowPageLoader = false;
        return;
      }
      if (this.dealerDetails.mainDealerName == null || this.dealerDetails.mainDealerName == undefined || 
        this.dealerDetails.mainDealerName == '') {
        this.toastr.info("Main Dealer Name Shoud Not Be Empty");
        this.isShowPageLoader = false;
        return;
      }
      if (this.dealerDetails.ADcustomerID == null || this.dealerDetails.ADcustomerID == undefined || 
        this.dealerDetails.ADcustomerID == '') {
        this.toastr.info("AD Customer ID Shoud Not Be Empty");
        this.isShowPageLoader = false;
        return;
      }
      if (this.dealerDetails.vendorName == null || this.dealerDetails.vendorName == undefined || 
        this.dealerDetails.vendorName == '') {
        this.toastr.info("Vendor Name Shoud Not Be Empty");
        this.isShowPageLoader = false;
        return;
      }
      if (this.dealerDetails.vendorType == null || this.dealerDetails.vendorType == undefined || 
        this.dealerDetails.vendorType == '') {
        this.toastr.info("Vendor Type Shoud Not Be Empty");
        this.isShowPageLoader = false;
        return;
      }
    }
    let reqObj = {
      "dealerObj": {
        "DEALER_TYPE_ID": this.dealerDetails.dealerType,
        "COUNTRY_CODE": this.dealerDetails.countryId,
        "MODIFIED_BY": 0, // while create - 0 or service userid
        // "CREATED_BY": CommonService.getUserData().USER_ID, // service userid
        "CREATED_BY": 3 ,//static
        "ROW_STATE": 0, //crete 0 modify 1
        "DEALER_ID": this.dealerDetails.dealerId ? this.dealerDetails.dealerId : 0,
        "TVS_DEALER_ID": this.dealerDetails.tvsDealerId ? this.dealerDetails.tvsDealerId : "",
        "BUSINESS_TYPE_ID": this.dealerDetails.businessType,
        "DEALER_NAME": this.dealerDetails.contactPerson,
        "DEALERSHIP_NAME": this.dealerDetails.dealershipName,
        "EMAIL_ID": this.dealerDetails.emailId ? this.dealerDetails.emailId : '' ,
        "ADDRESS_LINE_1": this.dealerDetails.line1 ? this.dealerDetails.line1 : '',
        "ADDRESS_LINE_2": this.dealerDetails.line2 ? this.dealerDetails.line2 : '',
        "ADDRESS_LINE_3": this.dealerDetails.line3 ? this.dealerDetails.line3 : '',
        "CITY": this.dealerDetails.city ? this.dealerDetails.city : '',
        "STATE_ID": this.dealerDetails.state,
        "PHONE_NO": this.dealerDetails.phoneNo,
        "FAX": this.dealerDetails.fax ? this.dealerDetails.fax : '',
        "PIN_CODE": this.dealerDetails.pinCode ? this.dealerDetails.pinCode : '',
        "PAN_NO": this.dealerDetails.panNo.toUpperCase(), // To Upper
        "DLR_CLASS": this.dealerDetails.dealerClass,
        "MUN_APP_NO": this.dealerDetails.municipalNo ? this.dealerDetails.municipalNo : '',
        "FI_VEH_CRED_LIM": this.dealerDetails.vcLimitFI,
        "FI_SPR_CRED_LIM": this.dealerDetails.scLimitFI,
        "VEH_CRED_LIM": this.dealerDetails.vcLimit,
        "SPR_CRED_LIM": this.dealerDetails.scLimit,
        "FIELD_1": this.dealerDetails.field1 ? this.dealerDetails.field1 : '',
        "FIELD_2": this.dealerDetails.field2 ? this.dealerDetails.field2 : '',
        "FIELD_3": this.dealerDetails.field3 ? this.dealerDetails.field3 : '',
        "GST_IN_NO":this.dealerDetails.GSTINNO? this.dealerDetails.GSTINNO:'',
        "ACC_FINYEAR_START_MONTH": this.dealerDetails.yearStart,
        "ACC_FINYEAR_END_MONTH": this.dealerDetails.yearEnd,
        "ACTIVE": this.dealerDetails.isActive,
        "DLR_INFRASTRUCTURE": this.dealerDetails.internetBand ? this.dealerDetails.internetBand : '',
        "TERRITORY": this.dealerDetails.territory,
        "CITY_CODE": this.dealerDetails.cityCode,
        "AREA_ID": this.dealerDetails.area,
        "IS_CLAIM_TO_APS": this.dealerDetails.fscClaimAPS,
        "BRANCH_ID": 0, // default
        "START_RANGE": 1, // default
        "END_RANGE": -1, // default
        "FIN_YEAR": 0, // based on ACC_FINYEAR_START_MONTH and current month
        "DealerConfigSetting": {
          "TVS_SPARE_GRN_FETCH": null, // default
          "WARRANTY_CLAIM": null, // default
          "IS_EV_NRML": this.dealerDetails.vehicleSaleType // Vehicle Sale Type dropdown val         
        },
        "DealerAscMapDetails": {
          "AMD_APS_CODE": this.dealerDetails.mainDealerCode ? this.dealerDetails.mainDealerCode : '', // Main dealer code 
          "AD_ID": this.dealerDetails.ADcustomerID ? this.dealerDetails.ADcustomerID : '',
          "AMD_APS_NAME": this.dealerDetails.mainDealerName ? this.dealerDetails.mainDealerName : '',
          "IS_DEFAULT": true, // default
          "ACTIVE": true // default
        }
      },
      "vendorObj": {
        "VENDOR_NAME": this.dealerDetails.vendorName,
        "VENDOR_TY_ID": this.dealerDetails.vendorType,
        "STATE_ID": this.dealerDetails.state,
        "COUNTRY_CODE": this.dealerDetails.countryId,
        "PAY_TERM_ID": 1, //default
        "VENDOR_REF_NO": this.dealerDetails.mainDealerCode,
        "ADDRESS": "" // default
      }
    }
    if (this.data.type == "MODIFY") {
      reqObj.dealerObj.MODIFIED_BY = CommonService.getUserData().USER_ID;
      reqObj.dealerObj.ROW_STATE = 1;
    }
    var CurrMonth = new Date().getMonth();
    var Year = new Date().getFullYear();
    if (CurrMonth < this.dealerDetails.yearStart) {
      reqObj.dealerObj.FIN_YEAR = Year - 1;
    }
    else {
      reqObj.dealerObj.FIN_YEAR = Year;
    }
    if (this.dealerDetails.phoneNo == null || this.dealerDetails.phoneNo == undefined) {
      reqObj.dealerObj.PHONE_NO = "";
    }
    if (this.dealerDetails.contactPerson == null || this.dealerDetails.contactPerson == undefined) {
      reqObj.dealerObj.DEALER_NAME = "";
    }
    if(this.dealerDetails.businessType != 3 && this.dealerDetails.businessType != 4 &&
      this.dealerDetails.businessType != 15){
        reqObj.vendorObj = null;
      }
    this.masterService.post(reqObj, '/Dealer/DealerSaveOrModify').subscribe((resp: any) => {
      if (resp && resp.statusCode === 200) {
        this.dialogRef.close();
        this.toastr.success(resp.statusMessage);
      } else {
        this.toastr.error(resp.statusMessage);
      }
      this.isShowPageLoader = false;
      if (resp && resp.statusCode == 401) {
        this.loginService.logout();
      }
    }, error => {
      if (error.status == 401) {
        this.loginService.logout();
      }
      this.toastr.error(error.error.statusMessage);
      this.isShowPageLoader = false;
    });
  }

  closePopup() {
    this.dialogRef.close();
  }

}

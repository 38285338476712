export class OptionList {
    valueMapId: number;
    answer: string;
}

export class CheckListData {
    valueID: number;
    optionList: OptionList[];
}

export class DocumentList {
    documentId: number;
    deduction: number;
    validTill: Date;
    receivedStatus: string;
    docFileName: string;
}

export class Procurement {
    dealerId: number;
    branchId: number;
    companyId: number;
    enquiryNo: number;
    enquiryModeId: number;
    enquiryDate: any;
    employeeId: number;
    enquiryStatus :any;
    customerTypeId: number;
    customerName: string;
    cityId: string;
    areaId: string;
    locationId:any;
    systemSuggestValue:any;
    mobileNo: string;
    address: string;
    partName:string;
    customerExpectedVal: number;
    brandId: any;
    modelId: string;
    year: string;
    partId: string;
    registerNumber: string;
    runningKms: number;
    evaluationVal?: number;
    noofOwners: number;
    frameNumber: string;
    engineNumber: string;
    checkListData: any;
    hpEndorsement: any;
    hpCompanyId: number;
    mobileNumber:any;
    //loanAmount: number;
    loanCompleted: any;
    nocReceived: any;
    hpCancelInRc: any;
    documentListData: any;
    enqueryDate:string;
    brandName :string;
    modelName :string;
    manu_year :any;
    areaName :string;
    regNo :string;
    runningKMS :any;
    contactNo :string;
    city:string;
    stateId:string;
    vehicleRegMonth:any;
    vehicleRegYear:any;
    categoryId:any;
    finalProcuredValue:any;
    //referral key
    tvsDealerId:number;
    referralVehicleEnqId:number;
    engineModel:any;
    pinCode: string;
    BIKE_TYPE_ID: any;
    maxSaleValue?: any;
    procurementCost?: any;
}

export class FollowUpListData {
    followUpDate: Date;
    employeeName: string;
    isContacted: any;
    followUpModeName: string;
    nextFollowUpDate: Date;
    reasonName: string;
    status: string;
    timePreference:any;
    offeredPrice:any;
    remarks:any;
}

export class ProcurementFollowUp {
    statusCode: number;
    statusMessage: string;
    priceQuoted: number;
    lastPrice: number;
    followUpListData: FollowUpListData[];
}

export class ProcurementFollowUpCreate {
    dealerId: number;
    branchId: number;
    enquiryNumber: number;
    lastPrice: number;
    followUpDate: Date;
    employeeId: number;
    is_Contacted: string;
    enquiryModeId: number;
    nextFollowUpDate: Date;
    timePreference: Date;
    reasonId: number;
    lackageId: number;
    statusId:number;
    offeredPrice:number;
    followupType:number;
    remark:any;
    droppedLackageId:number;
}





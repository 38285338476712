import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-spares-receipt-voucher',
  templateUrl: './spares-receipt-voucher.component.html',
  styleUrls: ['./spares-receipt-voucher.component.scss']
})
export class SparesReceiptVoucherComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public partRows: any = [];
  public maxDate:any = new Date();
  public sparesSalesInvoiceRows: any = [];
  constructor(
    private dialogRef: MatDialogRef<SparesReceiptVoucherComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.inlineFilter = {
        SPARE_INV_NO: '',
        Invoice_date: '',
        InvoiceAmount: '',
        CUST_NAME: '',
        CustomerType: '',
      };
    }
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }


  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'tvsInvoiceSearch') {
      if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
      this.selected[0].TYPE = this.part.invoiceTypeId;
    }
    let closeObj = { obj: this.selected[0] };
    this.dialogRef.close(closeObj);
  }

  getSparesSaleInvoiceReceiptListSearch() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Invoice From Date is empty ');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Invoice to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BranchId = CommonService.getDealerData().BRANCH_ID;
    reqObj.SPARE_INV_NO = this.part.SPARE_INV_NO ? Number(this.part.SPARE_INV_NO) : null;
    reqObj.Invoice_date = fromDateFormat;
    reqObj.ToInvoice_date = toDateFormat;
    reqObj.FinYear = CommonService.getDealerData().FIN_YEAR;
    reqObj.VCHR_TYPE_ID = this.data.value.VCHR_TYPE_ID;
    // req.invoice_type = 1;
    reqObj.CUST_NAME = this.part.CUST_NAME ? this.part.CUST_NAME : null;
    this.masterServices.post(reqObj, '/Accounts/SearchSparesSaleInvoiceReceiptList').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.sparesSalesInvoiceRows = resp.sprInvRecpt ? resp.sprInvRecpt : [];
        // this.bookingAdvanceRows.push(resp.booking);
      } else if (resp && resp.statusCode == 204) {
        this.sparesSalesInvoiceRows = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ApprovalService } from 'src/app/api-services/approvals.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../api-services/common.service';

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss']
})
export class RescheduleComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RescheduleComponent>,
    private toastr: ToastrService,
    private ApproveService: ApprovalService) { }
  public isShowPageLoader = false;
  public RESCHEDULE_TIME: any;
  public minDate: any = CommonService.setDateTime(new Date());
  public empDetails = [];
  ngOnInit() {
    let appData = CommonService.getServAppData();
    this.empDetails = appData.EmployeeList.filter(key => {
      if (key.EMP_GROUP_ID == 2) {
        return key;
      }
    });
  }
  /**
   * close the popup
   * @param {Any} frameNo
   */
  ClosePage() {
    this.dialogRef.close(false);
  }

  /**
  * Accept Approval
  * @param {Object} invoiceObj
  * @param {Number} index
  */
  rescheduleAppointment() {
    this.isShowPageLoader = true;
    var appointmentId = this.data.appId;
    let req = {
      "DEALER_ID": CommonService.getUserData().DEALER_ID,
      "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
      "APPOINMENT_ID": appointmentId,
      "IS_APPROVED": true,
      "IS_RESCHEDULED": true,
      "CREATED_BY": CommonService.getUserData().USER_ID,
      "DlrType": 1,
      "APPOINMENT_DATE_TIME": CommonService.getReqDateTimeFormat(this.RESCHEDULE_TIME),
      "EMP_ID": this.empDetails[0].EMPLOYEE_ID
    }
    this.ApproveService.updateApprovals(req).subscribe((resp: any) => {
      if (resp && resp.data && resp.statusCode === 200) {
        this.dialogRef.close(resp);
      }
      this.isShowPageLoader = false;
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ApprovalService } from 'src/app/api-services/approvals.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../api-services/common.service';
@Component({
  selector: 'app-reject-remarks',
  templateUrl: './reject-remarks.component.html',
  styleUrls: ['./reject-remarks.component.scss']
})
export class RejectRemarksComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RejectRemarksComponent>,
    private toastr: ToastrService,
    private ApproveService: ApprovalService) { }
  public isShowPageLoader = false;
  public remarks = '';
  ngOnInit() {
    //console.log(this.data);
  }
  /**
   * close the popup
   * @param {Any} frameNo
   */
  ClosePage() {
    this.dialogRef.close(false);
  }

  /**
 * Accept Approval
 * @param {Object} invoiceObj
 * @param {Number} index
 */
  rejectAppointment() {
    this.isShowPageLoader = true;
    var appointmentId = this.data.appId;
    let req = {
      "DEALER_ID": CommonService.getUserData().DEALER_ID,
      "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
      "APPOINMENT_ID": appointmentId,
      "IS_APPROVED": false,
      "CREATED_BY": CommonService.getUserData().USER_ID,
      "DlrType": 1,
      "REMARKS": this.remarks
    }
    this.ApproveService.updateApprovals(req).subscribe((resp: any) => {
      if (resp && resp.data && resp.statusCode === 200) {
        this.dialogRef.close(resp);
      }
      this.isShowPageLoader = false;
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    });
  }
}

import {Component,Output,EventEmitter,ElementRef, Input} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

declare var $:any;

@Component({
  selector: 'add-photo',
  template: `
       <input type="file" id="randomId" [accept]="acceptType || '*'" (change)="changeListner($event)" class="hide"/>
        <span class="label-text mtb12 ">
            <a href="javascript:;" (click)="uploadPhoto()" class="label-text"><ng-content></ng-content></a>
        </span>
  `,
})
export class PhotoContainerComponent {
  @Output() fileChange = new EventEmitter();
  @Input() setImgType ?: any;
  @Input() mediaSrcType;
  @Input() acceptType :string;
  @Input() readOnly:boolean = false;
  tipText:string;
  randomId =Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
  constructor(private element: ElementRef,public toastr: ToastrService) {}

  uploadPhoto(){
    if(this.readOnly == false){
      var fileUpload = $(this.element.nativeElement).find("input[type='file']");
      fileUpload.trigger("click");
    }
  }
  changeListner(event) {
    if(this.mediaType(event.target.value)){
      var fileSize = event.target.files[0].size / 1048576;
      if(fileSize){
        this.fileChange.emit(event);
      }else{
      this.toastr.warning('Upload a File Lesser Than 5 MB');
      }
    }else{
      this.toastr.warning('Upload a Valid File');
    }
    //this.fileChange.emit(event);
  }
  mediaType(src){
    var ext = this.getExtension(src);
    if(this.mediaSrcType == 'img'){
      var imgSrc = ["jpg","gif","png","jpeg"]
    }
    // else if(this.mediaSrcType == 'mp3'){
    //   var imgSrc = ["mp3", "wav", "wm"];
    // }else if(this.mediaSrcType == 'video'){
    //   var imgSrc = ["avi", "flv", "wmv", "mov", "mp4", "webm", "MOV"]
    // }else if(this.mediaSrcType == 'screenSaver'){
    //   /*== it is combination of video and audio ==*/
    //   var imgSrc = ["jpg","gif","bmp","png","mp4","jpeg","avi", "flv", "wmv", "mov", "mp4", "webm", "MOV"]
    // }
    else{
      imgSrc = ['zip','json','xlsx','xml','pdf']
    }
    var imageArr = imgSrc;
    if(typeof this.setImgType != 'undefined' && this.setImgType.length > 0) {
      imageArr = this.setImgType;
    }
    if(ext){
      if(imageArr.indexOf(ext)!=-1){
        return true;
      }else{
        return false;
      }
    }
  }
  getExtension(filename) {
    var parts = filename.split('.');
    console.log(parts);
    return parts[parts.length - 1];
  }
}


    export class PartCostRootObject {
        dealerId: number;
        partId: number;
        partNo:string;
        partName:string;
        manufactureId: number;
        vendorId: any;
        invoicePrice: number;
        validFrom: any;
        dealerPaidTax: number;
        tvsPaidTax: number;
        dealerPaidPercentage: number;
        tvsPaidPercentage: number;
        partCost: number;
        userId: number;
        active: boolean;
        countryCode: string;
        igst:any;
        cgst:any;
        sgst:any;
        warehouseId:any;
    }




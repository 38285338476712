import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-sales-vehicle-return',
  templateUrl: './sales-vehicle-return.component.html',
  styleUrls: ['./sales-vehicle-return.component.scss']
})
export class SalesVehicleReturnComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public vendorList: any = {};
  public directInvoiceRows: any = [];
  public invoiceNoRows=[];
  public apsRows: any = [];
  public invoiceTypeList: any = [];
  public masterDetails: any;
  public status: any = [];
  public vendorRows: any = [];
  public reginvoiceRows: any = [];
  public partRows: any =[];
  constructor(
    private dialogRef: MatDialogRef<SalesVehicleReturnComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      if(this.isActive == 'invoiceNoSearch')
      {
        this.inlineFilter={
          invoiceNo:'',
          customerName:'',
          invoiceFromDate:'',
          invoiceToDate:''
        }
        

      }
      if (this.isActive == 'invoiceSearch') {
        this.invoiceTypeList = this.data && this.data.value ? this.data.value.invoiceTypeList : [];
        this.part.invoiceTypeId = this.invoiceTypeList.length > 0 ? this.invoiceTypeList[0].invoiceTypeId : '';
        this.inlineFilter = {
          invoiceNo: '',
          invoiceDate: '',
          invoiceValue: '',
          customerName: '',
          customerTypeName: '',
          active: ''
        };
        this.status = [
          {
            name: 'Active',
            value: '1'
          },
          {
            name: 'InActive',
            value: '2'
          }
        ];
      } else if (this.isActive == 'vendorSearch' || this.isActive == 'opVendorSearch' || this.isActive == 'masterVendorSearch') {
        this.inlineFilter = {
          vendorId: '',
          vendorName: '',
          vendorTypeName: ''
        };
        this.masterDetails = this.data && this.data.value ? this.data.value : '';
      } else if (this.isActive == 'apsGrnSearch') {
        this.inlineFilter = {
          invoiceNo: '',
          invoiceDate: '',
          invoiceValue: '',
          grnStatus: ''
        };
        this.status = [{
          name: 'GRN completed',
          value: '0'
        },
        {
          name: 'GRN not completed',
          value: '2'
        }
        ];

        this.vendorList = this.data.vendorList && this.data.vendorList.length > 0 ? this.data.vendorList : [];
      } else if (this.isActive == 'accessorySearch') {
        this.inlineFilter = {
          invoiceNo: '',
          invoiceDate: '',
          grnStatus: ''
        };
        this.status = [{
          name: 'GRN completed',
          value: '1'
        },
        {
          name: 'GRN not completed',
          value: '2'
        }
        ];

        this.vendorList = this.data.vendorList && this.data.vendorList.length > 0 ? this.data.vendorList : [];
        this.part.APS_Code = this.data.vendorList && this.data.vendorList.length > 0 ? this.data.vendorList[0].APS_Code : '';

      } else if (this.isActive == 'reginvoiceSearch') {
        this.inlineFilter = {
          invoice_id: '',
          invoice_no: '',
          invoice_date: '',
          booking_no: '',
          booking_date: '',
          allotment_no: '',
          allotment_date: '',
          cust_name: '',
          cust_type: '',
          STATUS: ''
        };
        this.status = [{
          name: 'Open',
          value: '1'
        },
        {
          name: 'Cancel',
          value: '3'
        }
        ];
      } else if (this.isActive == 'reginvoiceSearch' || this.isActive === 'inventoryPartSearch') {
        this.inlineFilter = {
          partNo: '',
          partDescription: '',
          localPartDescrip: '',
          maunfacturer: '',
          uom: ''
        };
        
      }

    }
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  searchList() {
    if(this.isActive == 'invoiceNoSearch')
      this.getInvoiceNoSearch();
    else if (this.isActive == 'invoiceSearch') {
      this.getDirectInvoiceList();
    } else if (this.isActive === 'vendorSearch') {
      this.venderSearch();
    } else if (this.isActive === 'apsGrnSearch') {
      this.apsGrnSearch();
    } else if (this.isActive === 'opVendorSearch') {
      this.opVenderSearch();
    } else if (this.isActive === 'accessorySearch') {
      this.accessoryInvoiceSearch();
    } else if (this.isActive === 'reginvoiceSearch') {
      this.registrationInvoiceSearch();
    } else if (this.isActive === 'partSearch') {
      this.getMasterSearch();
    } else if (this.isActive === 'masterVendorSearch') {
      this.getVendorSearch();
    } else if (this.isActive ==='inventoryPartSearch'){
      this.getInventorySearch();
    }
  }
  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'apsGrnSearch') {
      if (this.selected[0].grnStatus != 0) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
    }
    let closeObj = { obj: this.selected[0] };
    this.dialogRef.close(closeObj);
  }
  getInvoiceNoSearch()
  {
    this.isShowPageLoader = true;
    let reqObj:any={};
    let toDateFormat:any;
    let fromDateFormat:any;
    fromDateFormat=this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] :'';
    toDateFormat=this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] :'';
    reqObj.DEALER_ID=CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID=CommonService.getDealerData().BRANCH_ID;
    reqObj.invoice_no=this.part.invoiceNo ? Number(this.part.invoiceNo):null;
    reqObj.cust_name=this.part.customerName ? this.part.customerName : null;
    reqObj.FROM_DATE=fromDateFormat;
    reqObj.TO_DATE=toDateFormat;
    reqObj.IS_VEHICLE_RETURN=true;
    reqObj.FIN_YEAR=CommonService.getDealerData().FIN_YEAR;

    this.masterServices.postApiUrl(reqObj,'/VehicleReturn/SearchInvoiceNo').subscribe((resp:any) =>
    {
      if(resp)
      {
        this.isShowPageLoader = false;
        
        if(resp.data !=null)
        {
          this.invoiceNoRows=resp.data.length > 0? resp.data:[];
        }
        else 
          return this.toastr.error(resp.Message);
      }
      else 
      {
        this.isShowPageLoader=false;
      }
    })
  }
  getDirectInvoiceList() {
   let reqObj: any = {};
    let formDateformat: any;
    let toDateformat: any;
    formDateformat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : '';
    toDateformat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : '';
    reqObj.dealerId = CommonService.getDealerData().DEALER_ID;
    reqObj.branchId = CommonService.getDealerData().BRANCH_ID;
    reqObj.invoiceNo = this.part.invoiceNo ? Number(this.part.invoiceNo) : null;
    reqObj.fromDate = this.part.fromdate ? formDateformat : null;
    reqObj.toDate = this.part.todate ? toDateformat : null;
    reqObj.customerName = this.part.customerName ? this.part.customerName : null;
    reqObj.voucherTypeId = 12;
     reqObj.finYear = CommonService.getDealerData().FIN_YEAR;
    reqObj.invoiceTypeId = this.part.invoiceTypeId ? Number(this.part.invoiceTypeId) : null;
    this.masterServices.post(reqObj, '/VehicleReturn/SearchInvoiceNo').subscribe((resp: any) => {
      if (resp && resp.statusCode === 200 && resp.directInvoiceList) {
        this.directInvoiceRows = resp.directInvoiceList.length > 0 ? resp.directInvoiceList : [];
        this.directInvoiceRows.filter(key => {
          if (key.active == true) {
            key.active = '1';
          } else if (key.active == false) {
            key.active = '2';
          }
        });

        this.isShowPageLoader = false;
      } else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
    });
  }
  venderSearch() {
    let reqObj: any = {};
    reqObj.dealerId = CommonService.getUserData().DEALER_ID;
    reqObj = Object.assign({}, reqObj, this.part);
    for (const key in reqObj) {
      if (reqObj[key] == '') {
        delete reqObj[key];
      }
    }
    this.isShowPageLoader = true;
    this.masterServices.post(reqObj, '/SparePartGRN/listVendor').subscribe(
      (resp: any) => {
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 200 && resp.vendorList.length > 0) {
          this.vendorRows = resp.vendorList;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }
  opVenderSearch() {
    let reqObj: any = {};
    reqObj.dealerId = CommonService.getUserData().DEALER_ID;
    reqObj.purchaseOrderType = this.data.typeId;
    reqObj.vendorId = this.part.vendorId;
    reqObj.vendorName = this.part.vendorName;
    reqObj.vendorTypeId = this.part.vendorTypeId;
    this.isShowPageLoader = true;
    this.masterServices.post(reqObj, '/PurchaseOrder/listVendor').subscribe(
      (resp: any) => {
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 200) {
          this.vendorRows = resp.vendorList;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }
  apsGrnSearch() {
    let reqObj: any = {};
    if (!this.part.APS_Code) {
      this.toastr.error('Vendor name is empty');
      return;
    }
    reqObj.dealerId = CommonService.getUserData().DEALER_ID;
    reqObj.branchId = CommonService.getUserData().BRANCH_ID;
    reqObj.APS_Code = this.part.APS_Code;
    reqObj.invoiceNo = this.part.invoiceNo;
    reqObj.fromdate = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate) : '';
    reqObj.todate = this.part.todate ? CommonService.getReqDateFormat(this.part.todate) : '';
    // reqObj = Object.assign(reqObj, this.apsGrn);
    for (const key in reqObj) {
      if (reqObj[key] == '') {
        delete reqObj[key];
      }
    }
    this.isShowPageLoader = true;
    this.masterServices.post(reqObj, '/SparePartGRN/invoiceListSAPCallAPS').subscribe(
      (resp: any) => {
        this.isShowPageLoader = false;
        this.apsRows = [];
        if (resp && resp.statusCode == 200 && resp.invoiceList.length > 0) {
          this.apsRows = resp.invoiceList;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }
  accessoryInvoiceSearch() {
    let reqObj: any = {};
    this.vendorList && this.vendorList.length > 0 ?
      this.vendorList.filter(val => {
        if (this.part.APS_Code == val.APS_Code) {
          reqObj.amdID = val.amdID;
        }
      }) : '';
    let invoiceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Invoice From Date is empty ');
      invoiceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Invoice to Date is empty ');
      invoiceError++;
    }
    if (!this.part.APS_Code) {
      this.toastr.error('Vendor Name is empty');
      invoiceError++;
    }
    if (!this.part.fromdate && !this.part.todate && !this.part.invoiceNo) {
      this.toastr.error('Please enter the search criteria to proceed');
      invoiceError++;
    }
    if (invoiceError > 0) {
      return false;
    }
    reqObj.amd_APS_CODE = this.part.APS_Code;
    reqObj.invoiceNo = this.part.invoiceNo;
    reqObj.fromdate = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate) : '';
    reqObj.todate = this.part.todate ? CommonService.getReqDateFormat(this.part.todate) : '';
    for (const key in reqObj) {
      if (reqObj[key] == '') {
        delete reqObj[key];
      }
    }
    this.isShowPageLoader = true;
    this.masterServices.post(reqObj, '/AccessoryGRN/accessoryInvoiceList').subscribe(
      (resp: any) => {
        this.isShowPageLoader = false;
        this.apsRows = [];
        if (resp && resp.statusCode == 200) {
          this.apsRows = resp.invoiceList;
          if (this.apsRows && this.apsRows.length > 0) {
            this.apsRows.filter(val => {
              val.grnStatus = val.GRNStatus;
            })
          }
        } else {
          this.toastr.error(resp.statusMessage);
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }

  registrationInvoiceSearch() {

    if (!this.part.fromdate && !this.part.todate && !this.part.customerName && !this.part.invoiceNo) {
      return this.toastr.warning(this.lang.Message.GENERAL.selectOneItem);
    } else if ((this.part.fromdate && !this.part.todate) || (!this.part.fromdate && this.part.todate)) {
      return this.toastr.warning(this.lang.Message.GENERAL.INVALID_DATE);
    }
/*if (!this.part.fromdate) {
      return this.toastr.warning(this.lang.Message.GENERAL.selectOneItem);
    } */
    else {
    this.isShowPageLoader = true;
    let params = new HttpParams();
    params = params.set('DEALER_ID', CommonService.getUserData().DEALER_ID)
    .set('BRANCH_ID', CommonService.getUserData().BRANCH_ID)
    .set('InvoiceID', this.part.invoiceNo ? this.part.invoiceNo : 0)
    .set('custName', this.part.customerName ? this.part.customerName : '')
    .set('finYear', CommonService.getDealerData().FIN_YEAR)
    .set('FromDate', this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate) : null)
    .set('ToDate', this.part.todate ? CommonService.getReqDateFormat(this.part.todate) : null);
    this.masterSales.get(params, '/Sales/PopUpsearchVehicleInvoice').subscribe((resp: any) => {
      if (resp && resp.statusCode === 200 && resp.data) {
        this.reginvoiceRows = resp.data;
        this.isShowPageLoader = false;
      } else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
    });
  }
  }
  
  getMasterSearch() {
     this.isShowPageLoader = true;
    let reqObj:any = {};
    reqObj.dealerId=CommonService.getUserData().DEALER_ID;
    reqObj.branchId = CommonService.getUserData().BRANCH_ID;
    reqObj.countryCode =  CommonService.getDealerData().COUNTRY_CODE;
    reqObj = Object.assign(reqObj, this.part);
     for (const key in reqObj) {
      if (reqObj[key] == '') {
        delete reqObj[key];
      }
    }
    this.masterServices.post(reqObj, '/SpareParts/PopupPartMaster').subscribe((resp: any) => {
      if (resp && resp.statusCode === 200 && resp.partList) {
        this.partRows = resp.partList && resp.partList.length > 0?resp.partList:[];
        this.isShowPageLoader = false;
      } else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
    });
  
  }
  getVendorSearch() {
    this.isShowPageLoader = true;
   let reqObj:any = {};
   reqObj.dlrid=CommonService.getUserData().DEALER_ID;
   reqObj.vendorid = this.part.vendorId;
   reqObj.vendorname =  this.part.vendorName;
   reqObj.vendortype = this.part.vendorTypeId; 
    for (const key in reqObj) {
     if (reqObj[key] == '') {
       delete reqObj[key];
     }
   }
   this.masterServices.post(reqObj, '/SpareParts/PopupvendorMaster').subscribe((resp: any) => {
     if (resp && resp.statusCode === 200 && resp.vendorList) {
       if(resp.vendorList && resp.vendorList.length > 0){
         resp.vendorList.filter(val =>{
          this.vendorRows.push({ 
            vendorId : val.vendorid,
            vendorName : val.vendorname,
            vendorTypeName : val.vendortype
          })
         })
        }
       this.isShowPageLoader = false;
     } else {
       this.toastr.error(resp.message);
     }
     this.isShowPageLoader = false;
   }, error => {
     this.toastr.error(error.error.Message);
     this.isShowPageLoader = false;
   });
 
 }

 getInventorySearch() {
  this.isShowPageLoader = true;
 let reqObj:any = {}; 
 reqObj.dealerId=CommonService.getUserData().DEALER_ID;
 reqObj.branchId =CommonService.getUserData().BRANCH_ID
 reqObj.partNo = this.part.partNo;
 reqObj.description =  this.part.partDescription;
 reqObj.localDescription =this.part.localPartDescription
 reqObj.type = null; 
 reqObj.countryCode = CommonService.getDealerData().COUNTRY_CODE;
  for (const key in reqObj) {
   if (reqObj[key] == '') {
     delete reqObj[key];
   }
 }
 this.masterServices.post(reqObj, '/PhysicalInventory/searchPartsPhysicalInventoryPopup').subscribe((resp: any) => {
   if (resp && resp.statusCode === 200 && resp.parts) {
    this.partRows = resp.parts && resp.parts.length > 0?resp.parts:[];
    if (this.partRows && this.partRows.length > 0) {
      this.partRows.filter(val => {
        val.SPARE_PART_ID =val.SPARE_PART_ID,
        val.partNo =val.PART_NO,
        val.partDescription  =val.DESCRIPTION,
        val.localPartDescrip  =val.LOCAL_DESCR,
        val.maunfacturer  =val.MANUFACTURER_NAME,
        val.uom =val.UOM_DESCR
        // val.MANUFACTURER_ID = val.MANUFACTURER_ID,
        // val.UOM_DESCR =val.UOM_DESCR,
        // val.ITEM_TYPE_ID = val.ITEM_TYPE_ID,
        // val.ITEM_TAX_CAT_ID = val.ITEM_TAX_CAT_ID,
        // val.MAT_TYPE_ID = val.MAT_TYPE_ID,
        // val.UOM_ID = val.UOM_ID,
        // val.HSN_CODE =val.HSN_CODE
      })
    }
     this.isShowPageLoader = false;
   } else {
     this.toastr.error(resp.statusMessage);
   }
   this.isShowPageLoader = false;
 }, error => {
   this.toastr.error(error.error.statusMessage);
   this.isShowPageLoader = false;
 });

}
  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }


}

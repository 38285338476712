import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-uom-master',
  templateUrl: './create-uom-master.component.html',
  styleUrls: ['./create-uom-master.component.scss']
})
export class CreateUomMasterComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public UOMId :any;
  public Description : any;
  public Active : boolean = false;
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateUomMasterComponent>) { }

  ngOnInit() {
    if (this.data.type == "MODIFY" || this.data.type == "VIEW") {
      this.UOMId = this.data.value.UOMId
      this.Description = this.data.value.Description;
      this.Active = this.data.value.Active;
    }
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  saveUOM() {
    this.isShowPageLoader = true;
    let reqObj: any = 
    {
      "UOMId": this.UOMId?this.UOMId:'',
      "Description": this.Description?this.Description:'',
      "IsDecimal": true,
      "Active": this.Active?this.Active:false,
      "GstUom": "",
      "EwayUom": ""
    };
    if(this.UOMId == "" || this.UOMId == undefined || this.UOMId == null){
      this.toastr.info("UOM ID Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if(this.Description == "" || this.Description == undefined || this.Description == null){
      this.toastr.info("UOM Descreption Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    this.masterService.post(reqObj, '/masterManagement/createUOM').subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.dialogRef.close(true);
            this.toastr.success(resp.statusMessage)
          }
          if(resp.statusCode == 400){
            this.toastr.info(resp.statusMessage)
          }
          if (resp.statusCode == 204) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.isShowPageLoader = false;
            this.toastr.error(error.error.statusMessage);
          }
        })
  }

  closePopup() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-create-hsn-percentage-master',
  templateUrl: './create-hsn-percentage-master.component.html',
  styleUrls: ['./create-hsn-percentage-master.component.scss']
})
export class CreateHsnPercentageMasterComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public HsnCode: any;
  public HsnDescription: any;
  public Active: boolean = false;
  public CGST: any;
  public SGST: any;
  public IGST: any;
  public UTGST: any;
  public ValidFrom: any;
  public ValidTo: any;
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateHsnPercentageMasterComponent>) { }

  ngOnInit() {
    if (this.data.type == "MODIFY" || this.data.type == "VIEW") {
      this.HsnCode = this.data.value.HsnCode;
      this.HsnDescription = this.data.value.HsnDescription;
      this.CGST = this.data.value.CGST;
      this.SGST = this.data.value.SGST;
      this.IGST = this.data.value.IGST;
      this.UTGST = this.data.value.UTGST;
      this.ValidFrom = CommonService.setDateTime(this.data.value.ValidFrom);
      this.ValidTo = CommonService.setDateTime(this.data.value.ValidTo);
      this.Active = this.data.value.Active;
    }
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  getHSNCodeList(event) {
    if (event.code != 'ArrowUp' && event.code != 'ArrowDown') {
      if (this.HsnCode.length >= 3) {
        this.isShowPageLoader = true;
        let reqObj = {
          "HsNId": 0,
          "HsnCode": this.HsnCode ? this.HsnCode : '',
          "ItemTypeId": '',
          "HsnDescription": ''
        }
        this.masterService.post(reqObj, '/masterManagement/getHSNList').subscribe(
          (resp: any) => {
            if (resp.statusCode == 200) {
              this.masterData = resp.ListHSN;
              if (resp.ListHSN.length == 0) {
                this.toastr.info("No Data Available");
                this.HsnCode = "";
              }
            }
            if (resp.statusCode == 204) {
              this.toastr.info(resp.statusMessage);
            }
            this.isShowPageLoader = false;
          },
          error => {
            if (error.statusCode == 401) {
              this.toastr.error(error.error.Message);
            }
          })
      }
    }
  }
  setDesc(){
    this.masterData.filter(key=>{
      if(key.HsnCode == this.HsnCode){
        this.HsnDescription = key.HsnDescription;
      }
    });
  }
  saveHSNPercentage() {
    this.isShowPageLoader = true;
    let reqObj: any =
    {
      "HsnTaxId": this.data.value.HsnTaxId ? this.data.value.HsnTaxId : 0,
      "HsnCode": this.HsnCode ? this.HsnCode : '',
      "CGST": this.CGST ? this.CGST : 0,
      "SGST": this.SGST ? this.SGST : 0,
      "IGST": this.IGST ? this.IGST : 0,
      "UTGST": this.UTGST ? this.UTGST : 0,
      "ValidFrom": CommonService.getReqDateFormat(this.ValidFrom) ? CommonService.getReqDateFormat(this.ValidFrom) : '',
      "ValidTo": CommonService.getReqDateFormat(this.ValidTo) ? CommonService.getReqDateFormat(this.ValidTo) : '',
      "Active": this.Active ? this.Active : false,
      "SeqNo": 0,
      "HsnDescription": this.HsnDescription ? this.HsnDescription : ''
    }
    if (this.HsnCode == "" || this.HsnCode == null || this.HsnCode == undefined) {
      this.toastr.info("HSN Code Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if (this.HsnDescription == "" || this.HsnDescription == null || this.HsnDescription == undefined) {
      this.toastr.info("HSN Descreption Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if (this.CGST == "" || this.CGST == null || this.CGST == undefined) {
      this.toastr.info("CGST Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if (this.SGST == "" || this.SGST == null || this.SGST == undefined) {
      this.toastr.info("SGST Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if (this.IGST == "" || this.IGST == null || this.IGST == undefined) {
      this.toastr.info("IGST Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if (this.UTGST == "" || this.UTGST == null || this.UTGST == undefined) {
      this.toastr.info("UTGST Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if (this.ValidFrom == "" || this.ValidFrom == null || this.ValidFrom == undefined) {
      this.toastr.info("ValidFrom Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    if (this.ValidTo == "" || this.ValidTo == null || this.ValidTo == undefined) {
      this.toastr.info("ValidTo Shoud not be Empty");
      this.isShowPageLoader = false;
      return;
    }
    this.masterService.post(reqObj, '/masterManagement/createHSNPer').subscribe(
      (resp: any) => {
        if (resp.statusCode == 200) {
          this.dialogRef.close(true);
          this.toastr.success(resp.statusMessage)
        }
        if (resp.statusCode == 400) {
          this.toastr.info(resp.statusMessage)
        }
        if (resp.statusCode == 204) {
          this.toastr.info(resp.statusMessage);
        }
        this.isShowPageLoader = false;
      },
      error => {
        if (error.statusCode == 401) {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.statusMessage);
        }
      })
  }

  closePopup() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vendor-search',
  templateUrl: './vendor-search.component.html',
  styleUrls: ['./vendor-search.component.scss']
})
export class VendorSearchComponent implements OnInit {
  public lang: any;
  public isShowPageLoader: boolean;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public masterDetails: any;
  public vendorRows: any = [];
  public vendor: any = {};

  constructor(
    private masterService: MasterService,
    public toastr: ToastrService,
    private dialogRef: MatDialogRef<VendorSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  ngOnInit() {
    this.getMaster();
    this.inlineFilter = {
      vendorId: '',
      vendorName: '',
      vendorTypeName: ''
    };
  }
  getMaster() {
   this.masterService.get('', '/SparePartGRN/getGRNMasterDetails?dealerId=' + CommonService.getUserData().DEALER_ID
      + '&branchId=' + CommonService.getUserData().BRANCH_ID + '&isAPS=false').subscribe(
        (resp: any) => {
          if (resp && resp.statusCode == 200) {
            this.masterDetails = resp;
            this.isShowPageLoader = false;
          }
        }, error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.statusMessage);
        }
      )
  }
  venderSearch() {
    let reqObj: any = {};
    reqObj.dealerId = CommonService.getUserData().DEALER_ID;
    reqObj = Object.assign({}, reqObj, this.vendor);
    for (const key in reqObj) {
      if (reqObj[key] == '') {
        delete reqObj[key];
      }
    }
    this.isShowPageLoader = true;    
    this.masterService.post(reqObj, '/SparePartGRN/listVendor').subscribe(
      (resp: any) => {
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 200 && resp.vendorList.length > 0) {
          this.vendorRows = resp.vendorList;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.statusMessage);
      }
    )
  }
  selectedItem(){
    let closeObj = { obj: this.selected[0]};
    this.dialogRef.close(closeObj);
  }
   /**
    * Filter each columns
    * @param {String} value
    * @param {String} prop
   */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { EnquiryDet } from 'src/app/api-services/dto/enquiry'

@Component({
  selector: 'app-sales-map-invoice',
  templateUrl: './sales-map-invoice.component.html',
  styleUrls: ['./sales-map-invoice.component.scss']
})
export class SalesMapInvoiceComponent implements OnInit {

    public lang: any;
    public selected = [];
    public isActive: any;
    public isShowPageLoader: any = false;
    public inlineFilter: any = {};
    public customiseTable: any;
    public sales: any = {};
    public salesRows: any = [];
    public filterObj: any = {
      invoiceNo: '',
      frameNo: '',
      fromDate: '',
      toDate: ''
    }
    public enquiryId:any;
    public customerId:any;
    public maxDate = new Date();
    public minDate = new Date(new Date().setDate(new Date().getDate() - 90));

    constructor(
      private dialogRef: MatDialogRef<SalesMapInvoiceComponent>,
      private toastr: ToastrService,
      private masterServices: MasterService, private masterSales: MasterSales,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      if (CommonService.getLang()) {
        this.lang = CommonService.getLang();
      }
      if (this.data) {
       // this.isActive = this.data && this.data.type ? this.data.type : '';
        // this.isActive ="enquiryNo";
        this.enquiryId=this.data.ENQUIRY_ID;
        this.customerId=this.data.CUSTOMER_ID;
      }
    }
  
    ngOnInit() {
    /*  this.inlineFilter = {
        INVOICE_NO: '',
        SALE_DATE: '',
        DUE_DATE: '',
        DESCRIPTION: '',
        CUST_NAME: '',
        FRAME_NO: '',
        CUSTOMER_TYPE_DESC: '',
        ADDRESS: '',
        MOBILE: '',
        IsMapped:''
      };
      */
      this.customiseTable = [
        {
          name: this.lang.SALES.invoiceNo,
          model: true
        },
        {
          name: this.lang.SERVICES.saleDate,
          model: true
        },
        {
          name: this.lang.SERVICES.serviceDueDate,
          model: true
        },
        {
          name: this.lang.SALES.model,
          model: true
        },
        {
          name: this.lang.SALES.customerName,
          model: true
        },
        {
          name: this.lang.SALES.frameNo,
          model: true
        },
        {
          name: this.lang.SALES.customerType,
          model: true
        },
        {
          name: this.lang.SALES.address,
          model: true
        },
        {
          name: this.lang.SALES.mobile,
          model: true
        },
        {
          name: this.lang.SALES.DEALER_ID,
          model: true
        }
      ];
    }
    closeDialog() {
      this.dialogRef.close(false);
    }
    validateSearch() {
      // alert(this.filterObj.toDate);
      // if ( (this.filterObj.fromDate ==null || this.filterObj.fromDate == "") && ( this.filterObj.toDate == null || this.filterObj.toDate == "") && (this.filterObj.enquiryNo==null || this.filterObj.enquiryNo==undefined || this.filterObj.enquiryNo == "") && (this.filterObj.CUST_NAME == undefined || this.filterObj.CUST_NAME == null || this.filterObj.CUST_NAME == "") ){
  
      //   alert(this.lang.Message.ENQUIRY.VALIDATE_SEARCH);
      //   this.toastr.warning(this.lang.Message.Enquiry.VALIDATE_SEARCH);
      //   return false;
      // }
      return true;
    }
    searchList() {
      if (this.validateSearch()) {
        this.isShowPageLoader = true;
        let reqObj: any = {};
        reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
        reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
        reqObj.FromDate = this.filterObj.fromDate ? CommonService.getReqDateFormat(this.filterObj.fromDate) : null;
        reqObj.ToDate = this.filterObj.toDate ? CommonService.getReqDateFormat(this.filterObj.toDate) : null;
        reqObj.INVOICE_NO = this.filterObj.invoiceNo != null ? this.filterObj.invoiceNo : '';
        reqObj.FRAME_NO = this.filterObj.frameNo != null ? this.filterObj.frameNo : '';
  
        this.masterSales.post(reqObj, '/Sales/SearchMapMainDealerInvoice').subscribe((resp: any) => {
          if (resp && resp.statusCode === 200 && resp.data) {
            this.salesRows = resp.data.length > 0 ? resp.data : [];
  
            this.isShowPageLoader = false;
  
          } else {
            this.toastr.error(resp.message);
          }
          this.isShowPageLoader = false;
        }, error => {
          this.toastr.error(error.error.Message);
          this.isShowPageLoader = false;
        });
      }
    }
    /**
     * select search list
     */
    selectedItem() {
      let closeObj = { obj: this.selected[0] };
      this.dialogRef.close(closeObj);
    }
  
     /**
    * Filter each columns
    * @param {String} value
    * @param {String} prop
   */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }

    saveMapInvoice(SelectedRowDet){
      //console.log(SelectedRowDet);
      this.isShowPageLoader = true;
      //alert(this.selected[0].INVOICE_NO);
        let reqObj: any = {};
        reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
        reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
        reqObj.ENQUIRY_ID = this.enquiryId;
        reqObj.MODIFIED_BY = CommonService.getUserData().USER_ID;
        reqObj.TVS_INVOICE_NO =SelectedRowDet.INVOICE_NO;
        reqObj.TVS_INVOICE_DATE =SelectedRowDet.SALE_DATE;
        reqObj.MODEL_ID = SelectedRowDet.MODEL_ID;
        reqObj.PART_ID = SelectedRowDet.PART_ID;
        reqObj.ENGINE_NO = SelectedRowDet.ENGINE_NO;
        reqObj.FRAME_NO = SelectedRowDet.FRAME_NO;
        reqObj.SALE_DATE = SelectedRowDet.SALE_DATE;
        reqObj.CUSTOMER_ID = this.customerId;
        reqObj.MOBILE_NO = SelectedRowDet.MOBILE;
        reqObj.CUSTOMER_NAME = SelectedRowDet.CUST_NAME;
        reqObj.DESCRIPTION=SelectedRowDet.DESCRIPTION;
        reqObj.MAPPED_FRAME_NO=SelectedRowDet.FRAME_NO;
        reqObj.MAPPED_MAIN_DEALER=SelectedRowDet.DEALER_ID;
        console.log(reqObj);
        this.masterSales.post(reqObj, '/Sales/SaveMappedMainDealerEnqInvoice').subscribe((resp: any) => {
          if (resp && resp.statusCode === 200 && resp.data) {

              this.toastr.success(resp.data);
              this.isShowPageLoader = false;
              this.dialogRef.close();
  
          } else {
            this.toastr.error(resp.message);
          }
          this.isShowPageLoader = false;
        }, error => {
          this.toastr.error(error.error.Message);
          this.isShowPageLoader = false;
        });
      
  
    }
  
  }

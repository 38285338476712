import { HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { MasterDataService } from 'src/app/api-services/master-data.service';

@Component({
  selector: 'app-attendance-screen',
  templateUrl: './attendance-screen.component.html',
  styleUrls: ['./attendance-screen.component.scss']
})
export class AttendanceScreenComponent implements OnInit {

  @Input() displayData: any = [];
  
  public isEdit: any = true;
  public attendanceDate: any ;
  public isShowPageLoader: any = false;
  public parent: any = true;
  public EmpAttendanceList: any = [] ;
  public maxDate: any ;
  public currentDate: any ;
  public inlineFilter:any;
  constructor(private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    // private masterService: MasterService,
    public route: ActivatedRoute,
    private MasterDataService: MasterDataService,
    public dialogRef: MatDialogRef<AttendanceScreenComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.displayData = this.data;
  }
  ngOnInit() 
  {
    this.maxDate = new Date();
    this.attendanceDate = CommonService.setDateTime(new Date());
    this.inlineFilter = {
      EMPLOYEE_ID:'',
      EMPLOYEE_NAME:'',
      EMP_SUB_GROUP: '',
      EMP_GROUP: '',
      IS_PRESENT:'',
      IN_TIME:'',
      OUT_TIME:''
    };
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
  */
 filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  searchEmpAttendance()
  {
    debugger;
    console.log(CommonService.getUserData());
    console.log(this.displayData);
    let reqParam = new HttpParams();
    reqParam = reqParam.set("DealerId", CommonService.getUserData().DEALER_ID)
      .set("attendanceDate", moment(this.attendanceDate).format("YYYY-MM-DD"))
      .set("branchId", CommonService.getDealerData().BRANCH_ID)
    this.isShowPageLoader = true;
    this.MasterDataService.searchEmpAttendance(reqParam).subscribe((resp:any)=>{
      if(resp && resp.statusCode ===200 && resp.data){
        debugger;
        this.EmpAttendanceList = resp.data.length > 0 ? resp.data : [];
        this.EmpAttendanceList.forEach(x => {
          if(x.IN_TIME!=null){
            x.IN_TIME = CommonService.setDateTime(CommonService.getReqDateTimeFormat(x.IN_TIME))
          }
          if(x.OUT_TIME!=null){
            x.OUT_TIME = CommonService.setDateTime(CommonService.getReqDateTimeFormat(x.OUT_TIME))
          }
        });
        // this.EmpAttendanceList = this.EmpAttendanceList.filter(x=>x.IN_TIME = CommonService.setDateTime(String(x.IN_TIME)));
        // this.EmpAttendanceList = this.EmpAttendanceList.filter(x=>x.OUT_TIME = CommonService.setDateTime(String(x.OUT_TIME))); 
      }
      this.isShowPageLoader = false;
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
    });
  }
  saveEmpAttendance()
  {
    debugger;
    var isEmpAttendance ;
    var EmpAttendance;
    isEmpAttendance = this.EmpAttendanceList.filter(x=>x.EMPLOYEE_ID== this.displayData.userData.EMPLOYEE_ID);
    if(isEmpAttendance[0].IN_TIME == null || isEmpAttendance[0].OUT_TIME == null)
    {
      this.toastr.warning("Please enter IN_TIME and OUT_TIME");
    }
    else
    {
      EmpAttendance = this.EmpAttendanceList;
      EmpAttendance = EmpAttendance.filter(x=>x.ATTENDANCE_DATE = moment(this.attendanceDate).format("YYYY-MM-DD"));
        let req = {
          "EMPATTENDLIST":EmpAttendance
          }
          this.isShowPageLoader = true;
          this.MasterDataService.saveEmpAttendance(req).subscribe((resp:any)=>{
            if(resp.d && resp.d.statusCode === 200 && resp.d.data){
              localStorage.setItem("isAttendance","1");
              this.dialogRef.close();
              this.toastr.success(resp.d.message);
              
            } 
            this.isEdit = false;
            this.isShowPageLoader = false;
          }, error => {
            this.toastr.error(error.error.Message);
            this.isShowPageLoader = false;
          });
        }
    }
  reloadPage()
  {
    this.EmpAttendanceList = [];
  }
}

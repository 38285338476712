import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { CIC } from 'src/app/api-services/dto/cic';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-sales-cic',
  templateUrl: './sales-cic.component.html',
  styleUrls: ['./sales-cic.component.scss'],
})
export class SalesCICComponent implements OnInit {
  toppingsaware = new FormControl();
  toppingsreason = new FormControl();
  public selectedToppings;
  public lang: any;
  public cicDet = new CIC();
  public isShowPageLoader = false;
  public areaList = [];
  public incomelist = [];
  public educationList = [];
  public stateList = [];
  public custTypeList = [];
  public relationList = [];
  public salemodeList = [];
  public professionList = [];
  public allmodelList = [];
  public hpList = [];
  public language = [];
  public awareSourceList = [];
  public reasonList = [];
  public male = 'M';
  public female = 'F';
  public DateofBirth;
  public maxDate = new Date(new Date().setDate(new Date().getDate() - 6570));
  public maxDateMarried = new Date(new Date().setDate(new Date().getDate() - 1));
  public yearRange = '';
  public yearRangeMarried='';
public isView:boolean=false;

  constructor(
    public dialogRef: MatDialogRef<SalesCICComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.cicDet.BOOKING_ID = this.data.bookingId;
      this.cicDet.CUSTOMER_ID = this.data.customerId;
      this.cicDet.BOOKING_NO = this.data.bookingNo;
      if (this.cicDet.BOOKING_ID > 0) {
        this.getCICdetails();
      }
    }
  }

  ngOnInit() {
    this.lang = CommonService.getLang();
    this.yearRange = `${new Date().getFullYear() - 100}:${new Date().getFullYear() - 18}`;
    this.yearRangeMarried = `${new Date().getFullYear() - 70}:${new Date().getFullYear()}`;
    if (CommonService.getSalesAppData()) {
      this.incomelist = CommonService.getSalesAppData().IncomeList;
      this.cicDet.INCOME_ID =
        this.incomelist.length >= 1 ? this.incomelist[0].INCOME_ID : '';
      this.educationList = CommonService.getSalesAppData().EducationList;
      this.cicDet.EDUCATION_ID =
        this.educationList.length >= 1
          ? this.educationList[0].EDUCATION_ID
          : '';
      this.stateList = CommonService.getSalesAppData().StateList;
      this.cicDet.STATE_ID =
        this.stateList.length >= 1 ? this.stateList[0].STATE_ID : '';
      this.custTypeList = CommonService.getSalesAppData().CustTypeList;
      this.cicDet.CUST_TY_ID =
        this.custTypeList.length >= 1 ? this.custTypeList[0].CUST_TY_ID : '';
      this.relationList = CommonService.getSalesAppData().relationList;
      this.cicDet.Rel_with_EndUser =
        this.relationList.length >= 1 ? this.relationList[0].DESC : '';
      this.salemodeList = CommonService.getSalesAppData().salemodeList;
      this.cicDet.SALE_MODE =
        this.salemodeList.length >= 1 ? this.salemodeList[0].ID : '';
      this.professionList = CommonService.getSalesAppData().ProfessionList;
      this.cicDet.PROFESSION_ID =
        this.professionList.length >= 1
          ? this.professionList[0].PROFESSION_ID
          : '';
      this.allmodelList = CommonService.getSalesAppData().AllModelList;
      this.cicDet.EXIST_MODEL_ID =
        this.allmodelList.length >= 1 ? this.allmodelList[0].MODEL_ID : '';
      this.hpList = CommonService.getSalesAppData().HPList;
      this.cicDet.CIC_COMPANYID =
        this.hpList.length >= 1 ? this.hpList[0].FIN_COMP_ID : '';
      this.language = CommonService.getSalesAppData().language;
      this.cicDet.Comm_Language =
        this.language.length >= 1 ? this.language[0].ID : '';
      this.awareSourceList = CommonService.getSalesAppData().AwareSourceList;
      this.reasonList = CommonService.getSalesAppData().CharacteristicsList;
    }

    if (CommonService.getServAppData()) {
      this.areaList = CommonService.getServAppData().AreaList;
      this.cicDet.AREA_ID =
        this.areaList.length >= 1 ? this.areaList[0].AREA_ID : '';
    }
  }

  getCICdetails() {
    this.isShowPageLoader = true;
    let params = new HttpParams();
    params = params
      .set('DEALER_ID', CommonService.getUserData().DEALER_ID)
      .set('BRANCH_ID', CommonService.getUserData().BRANCH_ID)
      .set('BOOKING_ID', String(this.cicDet.BOOKING_ID))
      .set('CUSTOMER_ID', String(this.cicDet.CUSTOMER_ID))
      .set('BOOKING_NO', String(this.cicDet.BOOKING_NO));
    this.masterSales.get(params, '/Sales/getCICdetails').subscribe(
      (resp: any) => {
        if (
          resp &&
          resp.statusCode === 200 &&
          resp.data &&
          resp.message === 'Success'
        ) {
          this.cicDet = resp.data;
         // alert(resp.data.MARRIED_DATE);
          this.DateofBirth = CommonService.generalFormat(this.cicDet.DOB) != '' ? CommonService.generalFormat(this.cicDet.DOB) : '';
          this.cicDet.MARRIED_DATE = resp.data.MARRIED_DATE != '' ? CommonService.setDateTime(resp.data.MARRIED_DATE) : '';
          this.isShowPageLoader = false;
        } else if (resp && resp.statusCode === 200 && resp.data) {
          this.toastr.error(resp.message);
        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
      },
      (error) => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
      }
    );
  }

  checkOwnerIsUser() {
    let reqObj: any;
    reqObj = this.cicDet;
    let respInv: any;
    this.isShowPageLoader = true;
    this.masterSales
      .post(this.cicDet, '/Sales/UpdateCustomerasEnduser')
      .subscribe((resp) => {
        respInv = resp;
        this.isShowPageLoader = false;
        if (
          respInv &&
          respInv.statusCode === 200 &&
          respInv.data &&
          respInv.message === 'Success'
        ) {
          this.cicDet = respInv.data;
          this.DateofBirth = CommonService.getReqDateFormat(this.cicDet.DOB) != '' ? this.cicDet.DOB : '';
          if (this.cicDet.AREA_ID != null) {
            this.changeAreaName();
          }
        } else if (respInv && respInv.statusCode === 500) {
          this.toastr.warning(respInv.message);
          this.isShowPageLoader = false;
        } else {
          this.toastr.warning(respInv.data);
          this.isShowPageLoader = false;
        }
      });
  }
  changeAreaName() {
    this.areaList.filter((key) => {
      if (key.AREA_ID == this.cicDet.AREA_ID) {
        this.cicDet.STATE_ID = key.STATE_ID;
        this.cicDet.CITY = key.CITY;
        this.cicDet.PIN_CODE = key.PINCODE;
        this.cicDet.District = key.DISTRICT;
      }
    });
  }

  validation() {
    let custError: any = 0;
    const validapattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/;
    if (this.cicDet.USER_NAME == null || this.cicDet.USER_NAME == '') {
      this.toastr.warning('Name of the user and AddressLine 1 is Mandatory');
      custError++;
    }
    if (this.cicDet.AREA_ID == null || this.cicDet.AREA_ID == 0) {
      this.toastr.warning('Please select the Area');
      custError++;
    }
    if (this.cicDet.GENDER == '' || this.cicDet.GENDER == null) {
      this.toastr.warning('Gender is Mandatory');
      custError++;
    }
    if (this.cicDet.IS_EV_VEH == true && (this.cicDet.AADHAR_NO == null || this.cicDet.PAN_NO == null)) {
      this.toastr.warning('Aadhaar Card no and PAN No is Mandatory');
      custError++;
    } else if ((this.cicDet.AADHAR_NO != null && this.cicDet.AADHAR_NO != '') || (this.cicDet.PAN_NO != null && this.cicDet.PAN_NO != '')) {
      if (this.cicDet.AADHAR_NO.length != 12) {
        this.toastr.warning('Please enter valid Aadhaar number & it should be 12 digits');
        custError++;
      }
      if (this.cicDet.PAN_NO.length != 10) {
        this.toastr.warning('Please enter valid PAN number & it should be 10 digits');
        custError++;
      } else if (!validapattern.test(this.cicDet.PAN_NO)) {
          this.toastr.warning('Please enter valid PAN number');
          custError++;
      }
    }
    if (this.DateofBirth == '') {
      this.toastr.warning('DOB is Mandatory');
      custError++;
    }
    if (this.cicDet.MARRIED !=undefined && this.cicDet.MARRIED == true ) {
      if(this.cicDet.MARRIED_DATE ==undefined || this.cicDet.MARRIED_DATE =='') {
      this.toastr.warning('Marriage Aniversary date is mandatory');
      custError++;
      }
    }
    if (this.cicDet.Comm_Language == null || this.cicDet.Comm_Language < 1) {
      this.toastr.warning('Language in Mandatory');
      custError++;
    }
    if (this.cicDet.Age != null && this.cicDet.Age > 0) {
      if (this.cicDet.Age < 18) {
      this.toastr.warning('Age should be greater than 18');
      custError++;
      }
    }
    if (this.cicDet.MOBILE_NO == null || this.cicDet.MOBILE_NO == '') {
      this.toastr.warning('Mobile No  Should not be empty');
      custError++;
    } else if (this.cicDet.MOBILE_NO != null) {
      let firstChar = this.cicDet.MOBILE_NO.charAt(0);
      if( CommonService.getDealerData().COUNTRY_CODE=="IN" ||  CommonService.getDealerData().COUNTRY_CODE =="3W"){
        if (this.cicDet.MOBILE_NO.length > 10 || this.cicDet.MOBILE_NO.length < 10) {
          this.toastr.warning('Mobile No should be atleast 10 digits');
          custError++;
        } else if (firstChar != '4' && firstChar != '5' && firstChar != '6' &&  firstChar != '7' && firstChar != '8' && firstChar != '9') {
          this.toastr.warning('Mobile No should starts with 4 (or) 5 (or) 6 (or) 7 (or) 8 (or) 9');
          custError++;
        }     
      }
      else{
        if (this.cicDet.MOBILE_NO.length < 9 || this.cicDet.MOBILE_NO.length > 12) {
          this.toastr.warning('Mobile No should be between 9 & 11 digits');
          custError++;
        } else if (firstChar != '4' && firstChar != '5' && firstChar != '6' &&  firstChar != '7' && firstChar != '8' && firstChar != '9') {
          this.toastr.warning('Mobile No should starts with 4 (or) 5 (or) 6 (or) 7 (or) 8 (or) 9');
          custError++;
        }   
      }      
    }
    if( CommonService.getDealerData().COUNTRY_CODE=="IN" ||  CommonService.getDealerData().COUNTRY_CODE =="3W"){
      if (this.cicDet.MOBILE_NO_2 != null && this.cicDet.MOBILE_NO_2 != '') {
        let firstChar2 = this.cicDet.MOBILE_NO_2.charAt(0);
        if (this.cicDet.MOBILE_NO == this.cicDet.MOBILE_NO_2) {
          this.toastr.warning('Mobile No 1 and Mobile No 2 should not be Same');
          custError++;
        } else if (this.cicDet.MOBILE_NO_2.length > 10 || this.cicDet.MOBILE_NO_2.length < 10) {
          this.toastr.warning('Mobile No 2 should be atleast 10 digits');
          custError++;
        } else if (firstChar2 != '4' && firstChar2 != '5' && firstChar2 != '6'
         && firstChar2 != '7' && firstChar2 != '8' && firstChar2 != '9') {
          this.toastr.warning('Mobile No 2 should starts with 4 (or) 5 (or) 6 (or) 7 (or) 8 (or) 9');
          custError++;
        }
      }  
    }
    else{
      if (this.cicDet.MOBILE_NO_2 != null && this.cicDet.MOBILE_NO_2 != '') {
        let firstChar2 = this.cicDet.MOBILE_NO_2.charAt(0);
        if (this.cicDet.MOBILE_NO == this.cicDet.MOBILE_NO_2) {
          this.toastr.warning('Mobile No 1 and Mobile No 2 should not be Same');
          custError++;
        } else if (this.cicDet.MOBILE_NO_2.length < 9 ||this.cicDet.MOBILE_NO_2.length > 12) {
          this.toastr.warning('Mobile No 2 should be between 9 & 11 digits');
          custError++;
        } else if (firstChar2 != '4' && firstChar2 != '5' && firstChar2 != '6'
         && firstChar2 != '7' && firstChar2 != '8' && firstChar2 != '9') {
          this.toastr.warning('Mobile No 2 should starts with 4 (or) 5 (or) 6 (or) 7 (or) 8 (or) 9');
          custError++;
        }
      }  
    }
    if (custError > 0) {
      return false;
    } else {
      return true;
    }
  }
  saveCicDetails() {
    if (this.validation()) {
      let reqObj: any;
      if (this.DateofBirth != '') {
        this.cicDet.DOB = this.DateofBirth;
      }
     // alert(this.cicDet.MARRIED_DATE);
      this.cicDet.MARRIED_DATE=this.cicDet.MARRIED_DATE!=null ? CommonService.generalFormatYYYYMMDD(this.cicDet.MARRIED_DATE):null;
      this.cicDet.ROW_STATE =this.cicDet.CIC_ID == 0 ? 'Created' : 'Modified';
     // alert(this.cicDet.MARRIED_DATE);
      reqObj = this.cicDet;
      let respInv: any;
      this.isShowPageLoader = true;
      this.masterSales
        .post(this.cicDet, '/Sales/saveCICDetails')
        .subscribe((resp) => {
          respInv = resp;
          this.isShowPageLoader = false;
          if (respInv && respInv.statusCode === 200 && respInv.data >= 0) {
              this.toastr.success('CIC Saved Successfully');
              this.returnBooking();
          } else if (respInv && respInv.statusCode === 500) {
            this.toastr.warning(respInv.message);
            this.isShowPageLoader = false;
          } else {
            this.toastr.warning(respInv.data);
            this.isShowPageLoader = false;
          }
        });
    }
  }

  returnBooking() {
    let closeObj = { obj: this.cicDet };
    this.dialogRef.close(closeObj);
  }

}

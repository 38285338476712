export class vehicleData {
    FRAME_NO :any;
    REG_NO:any;
    MODEL:any;
    KEY_NO:any;
    PART:any;
    SALES_TROUGH:any;
    ENGINE_NO:any;
    UNIQUE_NO:any;
    SUB_CATEGORY:any;
    REFERRAL_NAME:any;
    SALE_DATE:any;
    SERVICE_BOOKLET_NO:any;
    CWI_POLICY_NO:any;
    CWI_WARRANTY_NO:any;
    INSURANCE_COMPANY:any;
    INSURANCE_TYPE:any;
    INSURANCE_CUST_ID:any;
    INSURANCE_POLICY_NO:any;
    INSURANCE_POLICY_DATE:any;
    INSURANCE_VALID_TO:any;
    AMC_NO:any;
    AMC_DESC:any;
    AMC_VALID_FROM:any;
    AMC_VALID_TO:any;
    REAR_TYRE_NO:any;
    FRONT_TYRE_NO:any;
    REAR_RIGHT_TYRE_NO:any;
    REDUCER_KIT_NO:any;
    BATTERY_MAKE:any;
    BATTERY_NO:any;
    CURRENT_READING:any;
    REPLACED_AT:any;
    CUMULATIVE_READING:any;
    GRN_DATE:any;
    INVOICE_NO:any;
    PRODUCTION_YEAR:any;
    PRODUCTION_MONTH:any;
    TVS_INVOICE_PRICE:any;
    PLANT:any;
    TAX_PAID:any;
    SHOWROOM_PRICE:any;
}
import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales-search',
  templateUrl: './sales-search.component.html',
  styleUrls: ['./sales-search.component.scss']
})
export class SalesSearchComponent implements OnInit {


  public lang: any;
  public isShowPageLoader = false;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public sales: any = {
    fromdate : '',
    todate : ''
  };
  public frmDate: any;
  public salesRows: any = [];
  public status: any = [
    {
      name: 'Open',
      value: '1'
    },
    {
      name: 'Allotted',
      value: '4'
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<SalesSearchComponent>,
    private toastr: ToastrService,
    private masterSales: MasterSales,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.isActive = 'enquiryNo';
    }
  }

  setType(val) {
    this.searchList();
  }
  searchByDate() {
    this.searchList();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  searchList() {

    if (!this.sales.fromdate) {
      return this.toastr.warning(this.lang.Message.GENERAL.selectOneItem);
    } else {
    this.isShowPageLoader = true;
    let params = new HttpParams();

    params = params.set('DEALER_ID', CommonService.getUserData().DEALER_ID)
    .set('BRANCH_ID', CommonService.getUserData().BRANCH_ID)
    .set('BOOKING_NO', '0')
    .set('CUST_NAME', '')
    .set('BOOKING_DATE_FROM', this.sales.fromdate ? CommonService.getReqDateFormat(this.sales.fromdate) : '')
    .set('BOOKING_DATE_TO', this.sales.todate ? CommonService.getReqDateFormat(this.sales.todate) : '');
    this.masterSales.get(params, '/Sales/PopUpSearchBooking').subscribe((resp: any) => {
      if (resp && resp.statusCode === 200 && resp.data) {
        this.salesRows = resp.data.length > 0 ? resp.data : [];
        this.isShowPageLoader = false;
      } else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
    });
  }
  }
  /**
  * @param {String} selected
  * Get selected details
  */
  selectedItem() {
    let closeObj = { obj: this.selected[0] };
    this.dialogRef.close(closeObj);
  }
  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/api-services/common.service';

@Component({
  selector: 'app-view-complaint',
  template: `
  <div class="estimateCost">
  <div class="estimateCostHeader">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="col-lg-6 col-sm-6 mt-6 pl-8">
          <span class="estimateHeader">
            {{data.type == 'COMPLAINT' ? lang?.SERVICES?.complaints : data.type == 'SCHEMES' ?
            lang?.SERVICES?.viewSchemes : ''}}
          </span>
        </div>
        <div class="col-lg-6 col-sm-6 flex align-right">
          <button type="button" (click)="resetScheme()" *ngIf="data.type == 'SCHEMES'" class="cancelBtn mr-10">{{lang?.GENERAL?.clear}}</button>
          <button type="button" (click)="dialogRef.close()" *ngIf="data.type != 'SCHEMES'" class="cancelBtn mr-10">{{lang?.GENERAL?.ok}}</button>
          <button [disabled]="!viewSchemes.dirty" class="searchSelectBtn" *ngIf="data.type == 'SCHEMES'" (click)="selectItem()" type="button">
            {{lang?.GENERAL?.select}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="data.type == 'COMPLAINT'">
    <div class="col-lg-12 col-sm-12">
      <div class="row mlr-0 mt-12">
        <div class="col-lg-12 col-sm-12 flex align-center">
          <div class="complaint inlineTable row mlr-0 mb-12">
            <div class="inlineHeader">
              <div class="head-column" *ngFor="let head of complaintHeader">
                {{head.name}}
              </div>
            </div>
            <div class="complaint inlineBody" *ngFor="let complaint of data.resp;let i=index;let i=index">
              <div class="inlineColumn">
                {{complaint?.CMPLGRP_DESC}}
              </div>
              <div class="inlineColumn">
                {{complaint?.CMPL_DESC}}
              </div>
              <div class="inlineColumn">
                {{complaint?.CUST_VOICE}}
              </div>
              <div class="inlineColumn">
                {{complaint?.OBSERVATION}}
              </div>
              <div class="inlineColumn">
                <input type="checkbox" disabled id="serRemindReceived{{i}}" class="magic-checkbox-circle" [(ngModel)]="complaint.IS_MANDATORY"
                  name="IS_MANDATORY{{i}}" />
                <label class="cLabel" for="serRemindReceived{{i}}"></label>
              </div>
            </div>
            <div class="no-record" *ngIf="data.resp.length==0">
              {{lang?.GENERAL?.noDataDisp}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form name="form" #viewSchemes="ngForm">
  <div class="row" *ngIf="data.type == 'SCHEMES'">
    <div class="col-lg-12 col-sm-12 flex align-center">
      <div class="schemes inlineTable row mlr-0 mb-12">
        <div class="inlineHeader">
          <div class="head-column wper-30">
            {{lang?.GENERAL?.select}}
          </div>
          <div class="head-column wper-70">
            {{lang?.SERVICES?.partDescription}}
          </div>
        </div>
        <div class="inlineBody" *ngFor="let scheme of data.resp;let i=index;let i=index">
          <div class="inlineColumn wper-30">
            <!-- <input type="checkbox" id="scheme{{i}}" class="magic-checkbox-circle" [(ngModel)]="scheme.IS_SELECT"
              name="scheme{{i}}" />
            <label class="cLabel" for="scheme{{i}}"></label> -->
            <!-- dharvesh  -->
            <label class="magic-radio deactive">
            <input type="radio" name="scheme{{i}}" [value]="scheme.SPARE_SCH_ID" [(ngModel)]="SPARE_SCH_ID">  
             <span></span>
            </label>
          </div>
          <div class="inlineColumn wper-70">
            {{scheme?.DESCRIPTION}}
          </div>
        </div>
      </div>
    </div>
  </div>
  </form>
</div>
    `,
  styles: [`
    .estimateCost {
        background-color: white;
    }
    .estimateCostHeader {
        border-radius: 8px 8px 0 0;
        background-color: #ecf2f6;
        padding: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .estimateHeader {
        opacity: 0.9;
        color: #202020;
        font-family: var(--fontName);
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .inlineTable.complaint .head-column, .inlineTable.complaint .inlineColumn{
      width: 25% !important;
    }
    .inlineTable.complaint .inlineHeader{
      width:100% !important;
    }
    .schemes{
      width:90% !important;
      margin: 10px;
    }
    .schemes .inlineHeader {
      width:100% !important;
    }
    .schemes.inlineTable .inlineBody .inlineColumn:first-child .cLabel:before {
      top: -9px;
  }
    `]
})
export class ViewComplaintComponent implements OnInit {
  public lang: any;
  public complaintHeader: any;
  public schemeHeader: any;
  public SPARE_SCH_ID:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewComplaintComponent>) { 
      //dharvesh 
      this.SPARE_SCH_ID = this.data.SPARE_SCH_ID;
    }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      this.complaintHeader = [
        {
          name: this.lang.SERVICES.compGrp,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SERVICES.complaints,
          width: 'wmpx-150'
        },
        {
          name: this.lang.SERVICES.customerVoice,
          width: 'wmpx-200'
        },
        {
          name: this.lang.SERVICES.finalObserv,
          width: 'wmpx-200'
        },
        {
          name: this.lang.SERVICES.isMandatory,
          width: 'wmpx-120'
        }
      ];
      this.schemeHeader = [{
        name: this.lang.SERVICES.compGrp,
        width: 'wper-50'
      },
      {
        name: this.lang.SERVICES.complaints,
        width: 'wmpx-150'
      },]
    }
  }
  selectItem() {
    let total = 0;
    this.data.resp.filter(val => {
      if (val.DISC_PERC && this.SPARE_SCH_ID) {
        total += parseFloat(val.DISC_PERC);
      }
    });
    total = total > 0 ? total : 0;
    this.dialogRef.close({schemePer:total,SPARE_SCH_ID:this.SPARE_SCH_ID});
  }
  resetScheme(){
    this.dialogRef.close({schemePer:0,SPARE_SCH_ID:0});
  }
}

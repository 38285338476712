import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-amc-receipt-voucher',
  templateUrl: './amc-receipt-voucher.component.html',
  styleUrls: ['./amc-receipt-voucher.component.scss']
})
export class AmcReceiptVoucherComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public partRows: any = [];
  public amcReceiptRows: any = [];
  public amcList: any = [];
  
  constructor(
    private dialogRef: MatDialogRef<AmcReceiptVoucherComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.amcList = this.data.masterData.amcList;
      this.inlineFilter = {
        AMC_VEH_NO: '',
        AMC_REF_DATE: '',
        DESCRIPTION: '',
        VALID_FROM: '',
        VALID_TILL: '',
        CUST_NAME: '',
        CUSTOMER_ID: '',
        FRAME_NO: '',
        REG_NO: '',
        STATUS: '',
      };
      this.status = [
        {
          name: 'Open',
          value: '0'
        },
        {
          name: 'Closed',
          value: '1'
        },
        {
          name: 'Cancel',
          value: '2'
        },
      ];
    }
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }


  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'tvsInvoiceSearch') {
      if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
      this.selected[0].TYPE = this.part.invoiceTypeId;
    }
    let closeObj = { obj: this.selected[0] };
    this.dialogRef.close(closeObj);
  }

  //grn-tvs invoice popup
  getAMCReceiptSearch() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Booking From Date is empty ');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Booking to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
    reqObj.AMC_ID = this.part.AMC_ID ? Number(this.part.AMC_ID) : null;
    reqObj.REG_NO = this.part.REG_NO ? this.part.REG_NO : null;
    reqObj.END_USER_NAME = this.part.END_USER_NAME ? this.part.END_USER_NAME : null;
    this.masterServices.post(reqObj, '/Accounts/SearchAMCList').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.amcReceiptRows = resp.amdList ? resp.amdList : [];
      } else if (resp && resp.statusCode == 204) {
        this.amcReceiptRows = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

export class FollowUp {
    ROW_STATE: string;
    SERV_APP_FOLLOWUP_ID: string;
    DEALER_ID: string;
    BRANCH_ID: string;
    EMPLOYEE_ID: string;
    FOLLOWUP_NO: string;
    FOLLOWUP_DATE: string;
    FOLLOWUP_TYPE: Boolean;
    COMMENTS: string;
    LEAKAGE_ID: string;
    EXP_VISIT_DT: string;
    RESCHEDULE_DATE: string;
    selectedLeakage: any;
    uniqId: number;
}

export class ServiceApp {
    DEALER_ID: string;
    BRANCH_ID: string;
    FRAME_NO: string;
    SERV_PREF: string;
    END_USER_ID: Number;
    CUSTOMER_ID: Number;
    VEHICLE_ID: Number;
    JOB_TYPE_ID: Number;
    APT_CAT_ID: Number;
    CARE_CAMP_SCH_ID: Number;
    ROW_STATE: string;
    CREATED_BY: string;
    APPOINTMENT_ID: Number;
    APPOINTMENT_NO: Number;
    MODIFIED_BY: string;
    REASON_ID: Number;
    CUR_FOLLOWUP_NO: string;
    SERV_MODE_ID: Number;
    APPOINTMENT_BY: Number;
    CUST_ARRIVAL_DATE: string;
    REGIS_NO: string;
    STATUS: Number;
    APPOINT_DATE: any;
    ACTIVE: boolean;
    REMARKS:string;
    FollowUp: FollowUp[];
}

export class RootObjectCreateServiceAppointment {
    _serviceApp: ServiceApp;
}

export class VehicleDO {
    Dealer_ID: Number;
    BRANCH_ID: Number;
    VEHICLE_ID: Number;
    JOB_TYPE_ID: any;
    FRAME_NO: string;
    ROW_STATE: Number;
    IS_RECALL_REFIT_VEH: boolean;
    ENGINE_NO: string;
    KEY_NO?: any;
    REG_NO?: any;
    Service_Booklet_No?: any;
    SALE_DATE: string;
    PART_DESC: string;
    MODEL_DESC: string;
    CUST_NAME: string;
    END_USER: string;
    CUSTOMER_ID: Number;
    END_USER_ID: Number;
    MODEL_ID: string;
    CUR_ODOM_READ: Number;
    CUM_ODOM_READ: Number;
    UNIQUE_NO: Number;
    IS_ALL_DEALER: boolean;
    IS_OLD_VEHICLE: boolean;
    STATUS: Number;
    CONTACT_NO?: any;
    LAST_JOB_CARD_DATE: Date;
    SUBCATEGORY_ID: string;
    JCComplaintList?: any;
    VehicleCustomerDO?: any;
    VehicleEndCustomerDO?: any;
    JCVehicleDO?: any;
    JobTypeList?: any;
    JCServiceAppointmentDO?: any;
    JCServiceAppointmentExistDO?: any;
    Vehstatus: Number;
    CRM_WELCOME_KIT_NO?: any;
    COUNTRY_CODE?: any;
    IS_HIGH_VEH: Number;
}

export class CommunicationAddress {
    CUSTOMER_ID: Number;
    ADDRESS_TYPE: Number;
    ADDRESS_LINE_1: string;
    ADDRESS_LINE_2: string;
    ADDRESS_LINE_3?: any;
    CITY: string;
    AREA_ID: Number;
    STATE_ID: string;
    LANDMARK?: any;
    PIN_CODE: string;
    District: string;
    IS_COMM_ADD_VALID: boolean;
    SAME_AS_COMM: boolean;
    CompleteAddress: string;
    STATE_NAME?: any;
    AREA_NAME: string;
}

export class Mobile {
    CUSTOMER_ID: Number;
    PHONE_TYPE: Number;
    CONTACT_NO?: any;
    EXTN?: any;
    IS_PREFERRED: boolean;
    IS_VALID?: any;
    STD_CODE?: any;
    ROW_STATE: Number;
    MOBILE_NO: string;
    CONTACT_NO_2?: any;
    IS_VALID_CONTACT_2?: any;
}

export class ResidencePhone {
    CUSTOMER_ID: Number;
    PHONE_TYPE: Number;
    CONTACT_NO?: any;
    EXTN?: any;
    IS_PREFERRED: boolean;
    IS_VALID?: any;
    STD_CODE?: any;
    ROW_STATE: Number;
    MOBILE_NO: string;
    CONTACT_NO_2?: any;
    IS_VALID_CONTACT_2?: any;
}

export class CustomerDetails {
    GST_IN_NO?: any;
    AADHAR_NO?: any;
    STATE_ID: string;
    CUSTOMER_ID: Number;
    DEALER_ID: Number;
    SALUTATION?: any;
    CUST_NAME: string;
    SON_WIFE_DAU_OF: string;
    DATE_OF_BIRTH?: any;
    CUST_DATE_OF_BIRTH?: any;
    YEAR_OF_BIRTH?: any;
    GENDER: string;
    IS_PROSPECT: boolean;
    CUST_GRP_ID: Number;
    CUST_TY_ID: Number;
    EMAIL_ADDRESS?: any;
    IS_EMAIL_ID_VALID: boolean;
    EMPL_TYPE?: any;
    PROFESSION_ID?: any;
    INCOME_ID?: any;
    MOBILE_NO: string;
    EDUCATION_ID?: any;
    CUST_TAX_CAT_ID: Number;
    REF_CUST_ID?: any;
    SEND_SERV_REM: boolean;
    CUST_LIKE_TO_VST: boolean;
    HANDICAPPED: boolean;
    TVS_CUSTOMER_ID?: any;
    FOLL_TY_ID?: any;
    OLD_CUST_ID?: any;
    MARRIED: boolean;
    ANNIV_DATE?: any;
    CREDIT_CARD: boolean;
    IS_SL_CODE_GEN: boolean;
    CUST_SERV_TY_ID: boolean;
    VEH_CRED_LIM: Number;
    SPR_CRED_LIM: Number;
    GEN_CRED_LIM: Number;
    SPR_MARGIN: Number;
    SL_CODE: Number;
    REMARKS?: any;
    Comm_Language?: any;
    Rel_with_EndUser?: any;
    Number_KTP?: any;
    CREATED_BY: Number;
    CREATED_ON: Date;
    MODIFIED_BY?: any;
    MODIFIED_ON?: any;
    ACTIVE: boolean;
    ROW_STATE: Number;
    FullAddress?: any;
    CustType: Number;
    CommunicationAddress: CommunicationAddress;
    OfficeAddress?: any;
    ResidenceAddress?: any;
    RegistrationAddress?: any;
    CommnAddressPhone?: any;
    Mobile: Mobile;
    OfficePhone1?: any;
    OfficePhone2?: any;
    OfficeFaxNo?: any;
    ResidencePhone: ResidencePhone;
    RegnAddressPhone?: any;
    Enduser?: any;
    CIC?: any;
    InstitutionDetails?: any;
    ContactList?: any;
    AddressList?: any;
    EndUserList?: any;
    VehicleList?: any;
    LedgerList?: any;
    LEDGER_VALUES?: any;
    CUSTOMER_TYPE: string;
    CUSTOMER_GROUP: string;
    PaymentDueVehicle: Number;
    PaymentDueSpares: Number;
    PaymentDueGeneric: Number;
    CustomerType?: any;
    IsLastSale: boolean;
    GROUP?: any;
    CUST_CAT_ID: Number;
    AvailableCreditLimitForVehicle: Number;
    AvailableCreditLimitForSpares: Number;
    AvailableCreditLimitForGeneric: Number;
    OLD_VEHICLE: boolean;
    EXIST_MODEL_ID?: any;
    DLR_COUNTRY_CODE?: any;
    CONT_PERSON?: any;
    ConsumedCredit?: any;
    GEN_CRED_CON: Number;
    CompleteAddress?: any;
    AREA_NAME: string;
    CONTACT_NO?: any;
    MODLE_DESCRIPTION?: any;
    AGE: Number;
    FRAME_NO?: any;
    ENGINE_NO?: any;
    SALE_DATE?: any;
    LAST_JOB_CARD_DATE?: any;
    chkSelect: boolean;
    CREDIT_LIMIT_DAYS: Number;
    VENDOR_ID: Number;
    EMPLOYEE_ID: Number;
    SERIES?: any;
    CATEGORY?: any;
    CITY: string;
    NAME?: any;
    BLOCK_FSC: boolean;
    BLOCK_WARRANTY: boolean;
    END_USER_ID: Number;
    FromPage?: any;
    CustomerSPMargin?: any;
    PAN_NO?: any;
    CUSTOMER_PAN_COUNT?: any;
    APS_CODE?: any;
}

export class SeriveAppointFrameNoData {
    vehicleDO: VehicleDO;
    JobTypeList: any[];
    CustomerDetails: CustomerDetails;
}

export class ServiceAppointmentFrameNoRootObject {
    statusCode: Number;
    message: string;
    data: SeriveAppointFrameNoData;
}




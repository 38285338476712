import { Routes } from '@angular/router';
import { MasterComponent } from './layout/master/master.component';
import { AuthComponent } from './layout/auth/auth.component';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'session/dealer',
  pathMatch: 'full',
},
{
  path: '',
  component: MasterComponent,
  children: [
    {
      path: 'service',
      loadChildren: './service/service.module#ServiceModule'
    },
    {
      path: 'uvd',
      loadChildren: './UVD/uvd.module#UVDModule'
    },
    {
      path: 'uvdreports',
      loadChildren: './uvd-reports/uvd-reports.module#UvdReportsModule'
    },
    {
      path: 'call-log',
      loadChildren: './call-log/call-log.module#CallLogModule'
    },
    {
      path: 'sales',
      loadChildren: './sales/sales.module#SalesModule'
    },
    {
      path: 'master',
      loadChildren: './master/master.module#MasterModule'
    },
    {
      path: 'parts',
      loadChildren: './parts/parts.module#PartsModule'
    },
    {
      path: 'accounts',
      loadChildren: './accounts/accounts.module#AccountsModule'
    },
    {
      path: 'admin',
      loadChildren: './admin/admin.module#AdminModule'
    },
    {
      path: 'homedashboard',
      loadChildren: './homedashboard/homedashboard.module#HomedashboardModule'
    }
  ]
}, {
  path: '',
  component: AuthComponent,
  children: [{
    path: 'session',
    loadChildren: './session/session.module#SessionModule'
  }]
}, {
  path: '**',
  redirectTo: 'session/404'
}];



import { Component, OnInit, Inject } from "@angular/core";
import { CommonService } from "src/app/api-services/common.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { MasterSales } from "src/app/api-services/master.sales";
import { HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "app-vehicle-scheme",
  templateUrl: "./vehicle-scheme.component.html",
  styleUrls: ["./vehicle-scheme.component.scss"],
})
export class VehicleSchemeComponent implements OnInit {
  public lang: any;
  public isActive: any;
  public schemeList: Array<any> = [];
  public selectSchemeList: Array<any> = [];
  public isChanged = false;

  constructor(
    private dialogRef: MatDialogRef<VehicleSchemeComponent>,
    private toastr: ToastrService,
    private masterSales: MasterSales,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.schemeList = this.data.schemeList;
    this.selectSchemeList = this.data.schemeList;
  }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  selectedItem() {
    let closeObj = { obj: this.selectSchemeList };
    this.dialogRef.close(closeObj);
  }

  closeDialog() {
    this.selectSchemeList = this.schemeList;
    this.dialogRef.close(false);
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';

@Component({
    selector: 'app-page-loader',
    template: `
    <div class="loader">
    <div class="content">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="25" value="30">
      </mat-progress-spinner>
      <span>{{lang?.GENERAL?.pleaseWait}} ...</span>
    </div>
  </div>
  `,
    styles: [`.loader{
        position: fixed;
        z-index: 999999999;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, 0.54);
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }
    .content{
        display: flex;
        padding: 20px;
        background: white;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;
    }
    span{
        color: #1c1c1c;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 700;
        padding: 0px 10px;
    }
    :host /deep/ .loader .mat-progress-spinner.mat-accent circle{
        stroke: #00599d;
    }`]
})

export class PageLoaderComponent implements OnInit {
    public lang: any;
    constructor(
        private httpClient: HttpClient
    ) { }

    ngOnInit() {
        if (CommonService.getLang()) {
            this.lang = CommonService.getLang();
        }
        else{
            this.httpClient.get('assets/i18n/' + 'en.json').subscribe(
                langResp => {
                  this.lang =langResp;       
              });
        }
    }
}

import { Component, OnInit, Input, Inject,ViewChild } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-warranty-print',
  templateUrl: './warranty-print.component.html',
  styleUrls: ['./warranty-print.component.scss']
})
export class WarrantyPrintComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public htmlContent: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WarrantyPrintComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public commonService: CommonService,
    public toastr: ToastrService
  ) {
  }

  ngOnInit() {
    // window.print();
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {

      this.htmlContent = this.data;
    }
  }
  closeDialog(): void {
    this.dialogRef.close(false);
  }
  openPrint() {
    let popupWinindow
    let innerContents = this.htmlContent;
    popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no', true);
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print();">' + innerContents + '</body></html>');
    popupWinindow.document.close();
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { ExportToExcelService } from 'src/app/api-services/excel-download';
@Component({
  selector: 'app-vehicle-procurement-excel-upload',
  templateUrl: './vehicle-procurement-excel-upload.component.html',
  styleUrls: ['./vehicle-procurement-excel-upload.component.scss']
})
export class VehicleProcurementExcelUploadComponent implements OnInit {
  public isShowPageLoader: boolean = false;
  public lang: any;
  public rawHeader: any = [];
  public tabledata: any = [];
  public exceltoJson: any = {};
  public tableheader: any = [];
  public partExcel: any;
  public result: any = [];
  public sampleData: any = [];
  public isActiveResult: boolean = false;
  constructor(private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehicleProcurementExcelUploadComponent>,
    public exportToExcelService: ExportToExcelService) { }

  ngOnInit() {
    this.partExcel = this.data.value;
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  vehicleProcurement(obj) {
    var myExcelData: any = {
      sampleData: [],
    }
    myExcelData.sampleData = obj;
    this.exportToExcelService.exportAsExcelFile(myExcelData, 'vehicleProcurement');
  }

  onFileChange(event: any) {
    this.isShowPageLoader = true;
    this.exceltoJson = {};
    let headerJson = {};
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    // if (target.files.length !== 1) {
    //   throw new Error('Cannot use multiple files');
    // }
    const reader: FileReader = new FileReader();
    if (target.files.length != 0) {
      reader.readAsBinaryString(target.files[0]);
      console.log("filename", target.files[0].name);
      this.exceltoJson['filename'] = target.files[0].name;
      reader.onload = (e: any) => {
        this.isShowPageLoader = true;
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        for (var i = 0; i < wb.SheetNames.length; ++i) {
          const wsname: string = wb.SheetNames[i];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
          this.exceltoJson[`sheet${i + 1}`] = data;
          const headers = this.get_header_row(ws);
          headerJson[`header${i + 1}`] = headers;
          console.log("json", headers)
        }
        this.exceltoJson['headers'] = headerJson;
        console.log("result", this.exceltoJson);
        this.tableheader = this.exceltoJson.headers.header1;

        console.log("header", this.tableheader);
        for (var i = 0; i < this.exceltoJson.sheet1.length; i++) {
          this.tabledata.push({
            dealerId: CommonService.getUserData().DEALER_ID,
            branchId: CommonService.getUserData().BRANCH_ID,
            registerNumber: "",
            frameNumber: "",
            engineNumber: "",
            model: "",
            part: "",
            finalProcuredValue: "",
            evaluationVal: "",
            marginVal: '',
            maxSaleVal: '',
            minSaleVal: '',
            runningKMS: "",
            location: "",
            noofOwners: "",
            manu_year: "",
            vehicleRegYear: "",
            status: "",
            reason: ""
          });
          this.tabledata[i].registerNumber = this.exceltoJson.sheet1[i]["Register_No"] ? this.exceltoJson.sheet1[i]["Register_No"] : null;
          this.tabledata[i].frameNumber = this.exceltoJson.sheet1[i]["Frame_No"] ? this.exceltoJson.sheet1[i]["Frame_No"] : null;
          this.tabledata[i].engineNumber = this.exceltoJson.sheet1[i]["Engine_No"] ? this.exceltoJson.sheet1[i]["Engine_No"] : null;
          this.tabledata[i].model = this.exceltoJson.sheet1[i]["Model"] ? this.exceltoJson.sheet1[i]["Model"] : null;
          this.tabledata[i].part = this.exceltoJson.sheet1[i]["Part"] ? this.exceltoJson.sheet1[i]["Part"] : null;
          this.tabledata[i].finalProcuredValue = this.exceltoJson.sheet1[i]["Procurement_Cost"] ? this.exceltoJson.sheet1[i]["Procurement_Cost"] : null;
          this.tabledata[i].evaluationVal = this.exceltoJson.sheet1[i]["Refurbishment_Value"] ? this.exceltoJson.sheet1[i]["Refurbishment_Value"] : null;
          this.tabledata[i].marginVal = this.exceltoJson.sheet1[i]["Margin_Value"] ? this.exceltoJson.sheet1[i]["Margin_Value"] : null;
          this.tabledata[i].maxSaleVal = this.exceltoJson.sheet1[i]["Max_Sale_Value"] ? this.exceltoJson.sheet1[i]["Max_Sale_Value"] : null;
          this.tabledata[i].minSaleVal = this.exceltoJson.sheet1[i]["Min_Sale_Value"] ? this.exceltoJson.sheet1[i]["Min_Sale_Value"] : null;
          this.tabledata[i].runningKMS = this.exceltoJson.sheet1[i]["RunningKMS"] ? this.exceltoJson.sheet1[i]["RunningKMS"] : null;
          this.tabledata[i].location = this.exceltoJson.sheet1[i]["Location"] ? this.exceltoJson.sheet1[i]["Location"] : null;
          this.tabledata[i].noofOwners = this.exceltoJson.sheet1[i]["No_of_Owners"] ? this.exceltoJson.sheet1[i]["No_of_Owners"] : null;
          this.tabledata[i].manu_year = this.exceltoJson.sheet1[i]["Manu_Year"] ? this.exceltoJson.sheet1[i]["Manu_Year"] : null;
          this.tabledata[i].vehicleRegYear = this.exceltoJson.sheet1[i]["Vehicle_Reg_Yr"] ? this.exceltoJson.sheet1[i]["Vehicle_Reg_Yr"] : null;
          this.tabledata[i].status = this.exceltoJson.sheet1[i]["Status"] ? this.exceltoJson.sheet1[i]["Status"] : null;
          this.tabledata[i].reason = this.exceltoJson.sheet1[i]["Reason"] ? this.exceltoJson.sheet1[i]["Reason"] : null;
        }
        // console.log("tabledata Before", this.tabledata);
        // if (this.tabledata.length > 1) {
        //   this.tabledata.pop();
        // }
        console.log("tabledata", this.tabledata);
        this.isShowPageLoader = false;
      };
    } else {
      this.tabledata = [];
      this.isShowPageLoader = false;
    }
  }

  get_header_row(sheet) {
    this.isShowPageLoader = true;
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
      // console.log("cell",cell)
      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
    }
    this.isShowPageLoader = false;
    return headers;
  }

  excelUpload() {
    this.isShowPageLoader = true;
    if (this.tabledata.length == 0) {
      this.toastr.info("No Data Available");
      this.isShowPageLoader = false;
      return;
    }
    let reqObj: any = {};
    reqObj.dealerId = CommonService.getUserData().DEALER_ID,
      reqObj.branchId = CommonService.getUserData().BRANCH_ID,
      reqObj.userId = CommonService.getUserData().USER_ID,
      reqObj.vehicleProcurements = this.tabledata;
    // this.vehicleProcurement(this.tabledata);
    this.masterService.post(reqObj, '/Procurement/VehicleUploadFromExcel').subscribe(
      (resp: any) => {
        this.isShowPageLoader = true;
        if (resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.isActiveResult = true;
          this.result = resp;
          this.toastr.success(resp.statusMessage);
          this.vehicleProcurement(resp.data);
        }
        if (resp.statusCode == 400) {
          this.toastr.error(resp.statusMessage);
          this.isShowPageLoader = false;
          // this.dialogRef.close();
        }
        this.isShowPageLoader = false;
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.resp.statusMessage);
      })
  }
  sampleExcel() {
    var host = document.location.origin;
    let link = document.createElement("a");
    link.download = "vehicleProcurement.xlsx";
    // link.href = "src/assets/vehicleProcurement.xlsx"; // Local
    link.href = host + "/assets/vehicleProcurement.xlsx"; // Live
    link.click();
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { PartSearchComponent } from '../part-search/part-search.component';

@Component({
  selector: 'app-spare-sales-return-payment',
  templateUrl: './spare-sales-return-payment.component.html',
  styleUrls: ['./spare-sales-return-payment.component.scss']
})
export class SpareSalesReturnPaymentComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public partRows: any = [];
  public maxDate:any = new Date();
  public spareSaleReturnLists: any = [];
  constructor(
    private dialogRef: MatDialogRef<SpareSalesReturnPaymentComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.inlineFilter = {
        SALE_RET_NO: '',
        SPARE_INV_NO: '',
        TOT_AMT_RET:'',
        SALE_RET_DT:'',
        CUST_NAME:'',
        INVOICE_VALUE:'',

      };
      
      
    }
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  // searchList() {
  //     this.getSpareSalesReturnPayment();
  // }
  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'Spare_Sales_Return_Payment') {
      // if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
      //   this.toastr.error('GRN is completed.Please select the another invoice...');
      //   return;
      // }
      this.selected[0].TYPE = this.part.INV_RET_ORD_ID;
    }
    let closeObj = { 
      obj: this.selected[0],
      typeId: this.data.VEH_INVOICE_ID
    };
    this.dialogRef.close(closeObj);
  }
  
  //grn-tvs invoice popup
  searchList() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Booking From Date is empty');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Booking to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    // reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.DEALER_ID='10891';
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
    reqObj.INVOICE_TYPE= 2;
    reqObj.COMPANY_ID= null;
    reqObj.SALE_RET_ID=0;
    reqObj.TOT_AMT_RET=0;
    reqObj.SPARE_INV_NO=this.part.SPARE_INV_NO ? Number(this.part.SPARE_INV_NO) : null;
    reqObj.INVOICE_VALUE=0;
    reqObj.CUSTOMER_ID=0;
    reqObj.CUST_NAME="";
    reqObj.SL_CODE=0;
    reqObj.OPEN_VALUE=0;
    reqObj.Fin_Year=CommonService.getDealerData().FIN_YEAR;
    reqObj.SALE_RET_NO = this.part.SALE_RET_NO ? Number(this.part.SALE_RET_NO) : null;
    reqObj.SALE_RET_DT_FROM = fromDateFormat;
    reqObj.SALE_RET_DT_TO = toDateFormat;
    reqObj.CUST_NAME = this.part.CUST_NAME ? this.part.CUST_NAME : null;
    reqObj.IS_ACCT_SRCH = 0;
    this.spareSaleReturnLists = [];
    this.masterServices.post(reqObj, '/Accounts/SearchSpareSaleReturnList').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.spareSaleReturnLists = resp.spareSaleReturnList;
      } else if (resp && resp.statusCode == 204) {
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-service-receipt-voucher',
  templateUrl: './service-receipt-voucher.component.html',
  styleUrls: ['./service-receipt-voucher.component.scss']
})
export class ServiceReceiptVoucherComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public partRows: any = [];
  public maxDate:any = new Date();
  public serviceSparesInvoiceRows: any = [];
  constructor(
    private dialogRef: MatDialogRef<ServiceReceiptVoucherComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.inlineFilter = {
        INVOICE_NO: '',
        INVOICE_DATE: '',
        INVOICE_STATUS: '',
        CUSTOMER_ID: '',
        FRAME_NO: '',
        REG_NO: '',
        MODEL_DESC: '',
        BILL_TYPE: '',
        JOB_CARD_NO: '',
        JOB_CARD_DATE: '',
      };
      this.status = [
        {
          name: 'Active',
          value: 'True'
        },
        {
          name: 'Inactive',
          value: 'False'
        }
      ];
    }
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'tvsInvoiceSearch') {
      if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
      this.selected[0].TYPE = this.part.invoiceTypeId;
    }
    let closeObj = { obj: this.selected[0] };
    this.dialogRef.close(closeObj);
  }

  //grn-tvs invoice popup
  getServiceSparesInvoiceReceiptSearch() {
    let error: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Invoice From Date is empty ');
      error++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Invoice to Date is empty ');
      error++;
    }
    if (error > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
    reqObj.JOB_CARD_NO = this.part.JOB_CARD_NO ? Number(this.part.JOB_CARD_NO) : null;
    reqObj.DATE_FROM = fromDateFormat;
    reqObj.DATE_TO = toDateFormat;
    reqObj.INVOICE_NO = this.part.INVOICE_NO ? Number(this.part.INVOICE_NO) : null;
    this.masterServices.post(reqObj, '/Accounts/SearchServiceSparesInvoiceReceiptList').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.serviceSparesInvoiceRows = resp.serviceSparesInvList ? resp.serviceSparesInvList : [];
        this.serviceSparesInvoiceRows.filter(key => {
          if (key.JOB_CARD_DATE) {
            key.INVOICE_DATE = key.JOB_CARD_DATE;
          }
        });
      } else if (resp && resp.statusCode == 204) {
        this.serviceSparesInvoiceRows = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }

}

/**
 * Created BY Senthilkumar 
 * Master Class for Sales
 * Last Modified By Senthilkumar on 
 * Last Modified Description : Created Sales master service
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';


@Injectable({
    providedIn: 'root'
})
export class MasterSales {
    constructor(private httpClient: HttpClient) {
    }
    /**
     * Sales POST
     * @param {Object} params
     * return Object
    */
    post(params,url) {
        const resData = RequestService.WebApiReq(url);
        return this.httpClient.post(resData.url, params,{headers:resData.headers});
    }
    /**
     * UVD GET
     * @param {Object} request
     * return Object
     */
    get(param,url) {
        const resData = RequestService.WebApiReq(url);
        return this.httpClient.get(resData.url, { params: param, headers: resData.headers });
    }
        /**
     * UVD PUT
     * @param {Object} request
     * return Object
     */
    put(param,url) {
        const resData = RequestService.WebApiReq(url);
        return this.httpClient.put(resData.url,param,{headers:resData.headers});
    }

    delete(url){
        const resData = RequestService.WebApiReq(url);
        return this.httpClient.delete(resData.url,{headers:resData.headers});
    }

    importFile(reqData:any,url,category){
        var respData = RequestService.WebApiReq(url);
        var formData: any = new FormData();
        if(category == 'single'){
            formData.append('file',reqData,reqData['name']);
        }else{
            for(let i=0; i < reqData.length; i++){
                formData.append("file", reqData[i], reqData[i]['name']);
            }
        }
        return this.httpClient.post(respData.url,formData,{headers:respData.headers});
      }

    postReqMaster(params,url) {
        const resData = RequestService.postReq(url);
        return this.httpClient.post(resData.url, params);
    }
    /**
     * UVD GET
     * @param {Object} request
     * return Object
     */
   getReqMaster(param,url) {
        const resData = RequestService.getReq(url);
        return this.httpClient.get(resData.url, { params: param, headers: resData.headers });
    }

    // cancelBooking(request)
    // {
    //   const resData=RequestService.postCancelBookingApi('http://52.172.14.22/TVSBSVIAPI/api/DMSPushCancellationData/DMSPushCancellationData');
    //   return this.httpClient.post(resData.url,request, { headers: resData.headers });
    //  // const resData = RequestService.postReq('saveEmployeeMasterDetails');
    //  // return this.httpClient.post(resData.url, request, { headers: resData.headers });
        
    // }

   
    
}

import { Directive, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { ThemeService } from '../api-services/utils/theme-service';
import { themes, fonts } from '../api-services/dto/theme.const';

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {
  public defaultColor = {
    '--background': '#00599d',
    '--bg-header': '#2c4457',
    '--text-color': '#00599d',
    '--inner-header': '#d7edff',
    isGradiant: false
  }
  private themServiceSubscription: Subscription;
  constructor(private elementRef: ElementRef,
               @Inject(DOCUMENT) private document: any,
    private themService: ThemeService) {
      setTimeout(() =>{
        if (localStorage.getItem('theme')) {
          let presentTheme = JSON.parse(localStorage.getItem('theme'));
            if (presentTheme.isGradiant) {
          this.updateTheme(presentTheme);
            } else {
          this.updateTheme(JSON.parse(localStorage.getItem('colorObj')));
          }
          } else {
          this.updateTheme(this.defaultColor);
        }
          if (localStorage.getItem('font')) {
          this.updatefont(localStorage.getItem('font'));
          } else {
          this.updatefont('roboto');
        }
        this.themService.getActiveTheme()
          .subscribe(themeObj => {  
            this.updateTheme(themeObj);
          });
          this.themService.getActiveFont()
          .subscribe(name => {  
            this.updatefont(name);
          });
      },500)
  }
  ngOnInit() {


  }

  updatefont(name) {
    const element = this.elementRef.nativeElement;
    var font = fonts[name];
    element.style.setProperty('--fontName', font.fontName);
    this.document.body.style.setProperty('--fontName', font.fontName);
    var head = this.document.head || this.document.getElementsByTagName('head')[0];
    let presentFont = this.document.getElementById('dynFont');
    let custFont = this.document.getElementById('customFont');
    if (custFont) {
      head.removeChild(custFont);
    }
    if (presentFont) {
      head.removeChild(presentFont);
    }
    var link = document.createElement('link');
    link.id = 'dynFont';
    link.rel = 'stylesheet';
    link.href = font.link;
    document.head.appendChild(link);
    var font: any = ':root{--fontName:' + font.fontName + '}';
    var style = this.document.createElement('style');
    head.appendChild(style);
    style.type = 'text/css';
    style.id = 'customFont'
    if (style.styleSheet) {
      style.styleSheet.cssText = font;
    } else {
      style.appendChild(document.createTextNode(font));
    }
  }

  updateTheme(themeObj) {
    const element = this.elementRef.nativeElement;
    let them;
    if (themeObj && themeObj.isGradiant) {
      them = themes[themeObj.category];
    } else {
      them = themeObj;
    }
    let styleProp: any;
    let finalStyleProp: any;
    let grdString;
    let finslGrdStyleProp = '';
    for (const key in them) {
      if (key != 'isGradiant') {
        element.style.setProperty(key, them[key]);
        this.document.body.style.setProperty(key, them[key]);
        grdString = key + ':' + them[key] + ';';
        finslGrdStyleProp += grdString;
      }
    }
    if (them) {
      var css;
      var head = this.document.head || this.document.getElementsByTagName('head')[0];
      let oldElem = this.document.getElementById('customColor');
      if (oldElem) {
        head.removeChild(oldElem);
      }
      if (them.isGradiant) {
        css = ':root{' + finslGrdStyleProp + '}';
      } else {
        styleProp = JSON.stringify(them).replace(/[{}"]/g, '');
        finalStyleProp = styleProp.replace(/[,]/g, ';');
        css = ':root{' + finalStyleProp + '}';
      }
      var style = this.document.createElement('style');
        head.appendChild(style);
        style.type = 'text/css';
        style.id = 'customColor'
      if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
    }
  }

  ngOnDestroy() {
    if (this.themServiceSubscription) this.themServiceSubscription.unsubscribe();
  }

}

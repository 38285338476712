import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BusinessOther, DealerOnBoard, DetailsOfBanker, DetailsOfManagement, ManufacturerDetail } from 'src/app/api-services/dto/dealer-onboard';
@Component({
  selector: 'app-dealer-onboard',
  templateUrl: './dealer-onboard.component.html',
  styleUrls: ['./dealer-onboard.component.scss']
})
export class DealerOnboardComponent implements OnInit {

  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public passPortImg: any = [];
  public imageList: any = [];
  public competitorsImgList: any = [];
  public showroomPics :any = [];
  public servicePics: any = [];
  public isView: boolean = false;
  public isModify: boolean = false;
  public dealerDetails = new DealerOnBoard();
  public signatureList1 :any = [];
  public signatureList2 :any = [];
  public signatureList3 :any = [];
  public signatureList4 :any = [];
  public signatureList5 :any = [];
  public proprietorshipImg :any = [];
  public partnershipImg :any = [];
  public privateCompanyImg :any = [];
  public publicCompanyImg :any = [];
  public initialSignImg :any = [];
  public aadharList: any[] = [];
  public pancardList: any[] = [];
  public bankrefList: any[] = [];
  public formsixteenList: any[] = [];
  public affidavitList: any[] = [];
  public gstList: any[] = [];
  public incomevalue = 0;
  public expanseValue = 0;
  public cashProfit = 0;
  public totalInvestment = 0;
  public totalInfra = 0;
  public totalWork = 0;
  public interest = 0;
  public investmentPerMonth = 0;
  public investmentPerYear = 0;
  public documentTypes = ["pdf","doc","docx","txt","xlsx", "xls", "csv"] 

  public len=0;

  dealerTypes: any[] = [{
    name: "Type of Outlet",
    width: 'wmpx-150'
  },
  {
    name: "Manufacturers Name",
    width: 'wmpx-200'
  },
  {
    name: "When Started",
    width: 'wmpx-150'
  },
  {
    name: "Current Sales(MA)",
    width: 'wmpx-150'
  },
  {
    name: "Service In flow (MA",
    width: 'wmpx-150'
  },
  {
    name: "Parts Sales (MA)",
    width: 'wmpx-150'
  },
  {
    name: "Total Turnover",
    width: 'wmpx-150'
  },
  {
    name: "No. of Employees",
    width: 'wmpx-150'
  },
  {
    name: "Constitution",
    width: 'wmpx-150'
  },
  {
    name: "Action",
    width: 'wmpx-100'
  }
]

typeOfBusinessHead: any[] = [{
  name: "Type of Business",
  width: 'wmpx-150'
},
{
  name: "Name of Organisation",
  width: 'wmpx-200'
},
{
  name: "When Started",
  width: 'wmpx-150'
},
{
  name: "Total Turnover",
  width: 'wmpx-150'
},
{
  name: "No. of Employees",
  width: 'wmpx-150'
},
{
  name: "Constitution",
  width: 'wmpx-150'
},
{
  name: "Action",
  width: 'wmpx-100'
}
]

dealerType : ManufacturerDetail = {
  "type_of_outlet": "",
  "manufacturer_name": "",
  "started_at": null,
  "current_sales": null,
  "service_in_flow": null,
  "parts_sales": null,
  "total_turnover": null,
  "no_of_employees": null,
  "constitution": ""
}

typeOfBusiness:BusinessOther = {
  "type_of_business": null,
  "name_of_organisation": "",
  "started_at": null,
  "total_turnover": null,
  "no_of_employees": null,
  "constitution": ""
}
typeOfBankers:DetailsOfBanker = {
  "name_of_banker": "",
  "address": "",
  "telephone_no": "",
  "account_no": "",
  "transaction_since": "",
  "cc_limit": null,
  "cd_limit": null
}
typeOfProperty:DetailsOfManagement = {
  "name": "",
  "percent_of_ownership": null,
  "releationship_with_key_promoter": ""
}
  

  constitutionList: any[] = [
    {
      name: "Properietor",
      value: "Properietor"
    }, 
    {
      name: "Partnership",
      value: "Partnership"
    }, 
    {
      name: " PVT.Ltd",
      value: " PVT.Ltd"
    }]
  typeOfOutlet: any[] = [{
    name: "Dealer",
    value: "Dealer"
  }, {
    name: "ASC",
    value: "ASC"
  },
  {
    name: "Sub Dealer",
    value: "SubDealer"
  }]
  typeOfPropertiesHead: any[] = [{
    name: "Sl. No",
    width: 'wmpx-100'
  },
  {
    name: "Name of Proprietor / Partners / Directors",
    width: 'wmpx-670'
  },
  {
    name: "% of  Ownership",
    width: 'wmpx-150'
  },
  {
    name: "Relationship with Key promoter",
    width: 'wmpx-350'
  }, 
  {
    name: "Action",
    width: 'wmpx-100'
  }
  ]
  
  typeOfBankersHead: any[] = [
  {
    name: "Name of the Banker",
    width: 'wmpx-250'
  },
  {
    name: "Address",
    width: 'wmpx-400'
  },
  {
    name: "Telephone No.",
    width: 'wmpx-150'
  },
  {
    name: "Account No.",
    width: 'wmpx-100'
  },
  {
    name: "Transaction Since",
    width: 'wmpx-150'
  },
  {
    name: "CC Limit (in Lacs)",
    width: 'wmpx-150'
  },
  {
    name: "CD Limit (in Lacs)",
    width: 'wmpx-150'
  },
  {
    name: "Action",
    width: 'wmpx-100'
  }
  ]
  
  refTypeOfProperty:any[] = [this.typeOfProperty]
  refListOfProperties: any[] = [
    {
      facilitykey: "Frontage",
      facitityValue: "AMD",
      minNormsFt: "",
      proposedSpace: "",
      own: "",
      rendted: "",
      leased: "",
      rentAmount: "",
      leaseAmount: "",
      remarks: ""
    },
    {
      facilitykey: "Showroom & Office",
      facitityValue: "AMD",
      minNormsFt: "",
      proposedSpace: "",
      own: "",
      rendted: "",
      leased: "",
      rentAmount: "",
      leaseAmount: "",
      remarks: ""
    },
    {
      facilitykey: "Service Shop",
      facitityValue: "AMD",
      minNormsFt: "",
      proposedSpace: "",
      own: "",
      rendted: "",
      leased: "",
      rentAmount: "",
      leaseAmount: "",
      remarks: ""
    },
    {
      facilitykey: "Parts Counter",
      facitityValue: "AMD",
      minNormsFt: "",
      proposedSpace: "",
      own: "",
      rendted: "",
      leased: "",
      rentAmount: "",
      leaseAmount: "",
      remarks: ""
    },
    {
      facilitykey: "Warehouse",
      facitityValue: "AMD",
      minNormsFt: "",
      proposedSpace: "",
      own: "",
      rendted: "",
      leased: "",
      rentAmount: "",
      leaseAmount: "",
      remarks: ""
    },
    {
      facilitykey: "Vehicle selection",
      facitityValue: "AMD",
      minNormsFt: "",
      proposedSpace: "",
      own: "",
      rendted: "",
      leased: "",
      rentAmount: "",
      leaseAmount: "",
      remarks: ""
    },
    {
      facilitykey: "Total Space",
      facitityValue: "AMD",
      minNormsFt: "",
      proposedSpace: "",
      own: "",
      rendted: "",
      leased: "",
      rentAmount: "",
      leaseAmount: "",
      remarks: ""
    },
  ]
  isEmailValid:boolean = true;
  

  constructor(private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DealerOnboardComponent>) { }

  ngOnInit() {
    this.dealerDetails ={
      "dealer_app_id": null,
      "location": {
          "state": "",
          "area": "",
          "district": "",
          "town": "",
          "lat": "",
          "lon": ""
      },      
      "image_url": "",
      "passport_list": [
        ""
      ],
      "businees_automobile": {
          "types_of_vechile": {
              "two_wheeler": false,
              "three_wheeler": false,
              "passenger_cars": false,
              "commercial_vehicles": false,
              "tractors": false,
              "spare_parts": false
          },
          "manufacturer_details": [
              {
                  "type_of_outlet": "",
                  "manufacturer_name": "",
                  "started_at": null,
                  "current_sales": null,
                  "service_in_flow": null,
                  "parts_sales": null,
                  "total_turnover": null,
                  "no_of_employees": null,
                  "constitution": ""
              }
          ]
      },
      "business_others": [
          {
              "type_of_business": "",
              "name_of_organisation": "",
              "started_at": null,
              "total_turnover": null,
              "no_of_employees": null,
              "constitution": ""
          }
      ],
      "brief_background_key_promoter": {
          "family": "",
          "business": ""
      },
      "key_promoter_details": {
          "name": "",
          "off_address": "",
          "off_pincode": "",
          "off_tel_no": "",
          "res_address": "",
          "res_pincode": "",
          "res_tel_no": "",
          "mobile": "",
          "email": "",
          "tin_no": "",
          "pan_no": "",
          "cst_no": "",
          "dob": null
      },
      "details_proposed_pov_dealership": {
          "name": "",
          "off_address": "",
          "off_pincode": "",
          "off_tel_no": "",
          "isAppliedforTIN": false,
          "tin_no": "",
          "isAppliedforPAN": false,
          "pan_no": "",
          "isAppliedforGST": false,
          "cst_no": "",
          "types_of_constitution": {
              "proprietorship": false,
              "partnership": false,
              "private_limited_co": false,
              "limited_co": false
          },
          "details_of_management": [
              {
                  "name": "",
                  "percent_of_ownership": null,
                  "releationship_with_key_promoter": ""
              }
          ]
      },
      "details_of_bankers": [
          {
              "name_of_banker": "",
              "address": "",
              "telephone_no": "",
              "account_no": "",
              "transaction_since": "",
              "cc_limit": null,
              "cd_limit": null
          }
      ],
      "buisness_plan_povd": {
          "first_year": {
              "moped": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "motor_cycle": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "scooter": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "total_two_wheelers": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "spare_parts": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "service_inflow": {
                  "month": null,
                  "yearly": null,
                  "value": null
              }
          },
          "second_year": {
              "moped": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "motor_cycle": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "scooter": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "total_two_wheelers": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "spare_parts": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "service_inflow": {
                  "month": null,
                  "yearly": null,
                  "value": null
              }
          },
          "third_year": {
              "moped": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "motor_cycle": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "scooter": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "total_two_wheelers": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "spare_parts": {
                  "month": null,
                  "yearly": null,
                  "value": null
              },
              "service_inflow": {
                  "month": null,
                  "yearly": null,
                  "value": null
              }
          },
          "moped": "",
          "motor_cycle": "",
          "scooterrette": "",
          "scooter": "",
          "two_wheeler": "",
          "app_value_per_vehicle": ""
      },
      "manpower": {
          "sales": {
              "indoor_sales_positions": {
                  "receptionists": null,
                  "showroom_sales_executive": null,
                  "delivery_executives": null,
                  "rto_related_work": null,
                  "crm_oms_executive": null
              },
              "outdoor_sales_positions": {
                  "enqyiry_follow_up": null,
                  "direct_marketing": null,
                  "field_activation": null,
                  "institutonal_sales": null,
                  "secondary_network_manager": null
              }
          },
          "service": {
              "category": {
                  "works_manger": null,
                  "service_advisor": null,
                  "floor_supervisor": null,
                  "customer_complaint_executive": null,
                  "mechanics": null,
                  "helper": null,
                  "parts_manager": null,
                  "parts_in_charge": null,
                  "assistants": null
              }
          },
          "spare_parts": {
              "category": {
                  "works_manger": null,
                  "service_advisor": null,
                  "floor_supervisor": null,
                  "customer_complaint_executive": null,
                  "mechanics": null,
                  "helper": null,
                  "parts_manager": null,
                  "parts_in_charge": null,
                  "assistants": null
              }
          }
      },
      "facility": {
          "frontage": {
              "min_norms_in_ft_sqft": null,
              "proposed_space": "",
              "own": "",
              "rented": "",
              "leased": "",
              "rent_in_amount": null,
              "lease_in_amount": null,
              "remarks": ""
          },
          "showroom_office": {
              "min_norms_in_ft_sqft": null,
              "proposed_space": "",
              "own": "",
              "rented": "",
              "leased": "",
              "rent_in_amount": null,
              "lease_in_amount": null,
              "remarks": ""
          },
          "service_shop": {
              "min_norms_in_ft_sqft": null,
              "proposed_space": "",
              "own": "",
              "rented": "",
              "leased": "",
              "rent_in_amount": null,
              "lease_in_amount": null,
              "remarks": ""
          },
          "parts_counter": {
              "min_norms_in_ft_sqft": null,
              "proposed_space": "",
              "own": "",
              "rented": "",
              "leased": "",
              "rent_in_amount": null,
              "lease_in_amount": null,
              "remarks": ""
          },
          "warehouse": {
              "min_norms_in_ft_sqft": null,
              "proposed_space": "",
              "own": "",
              "rented": "",
              "leased": "",
              "rent_in_amount": null,
              "lease_in_amount": null,
              "remarks": ""
          },
          "vechile_selection": {
              "min_norms_in_ft_sqft": null,
              "proposed_space": "",
              "own": "",
              "rented": "",
              "leased": "",
              "rent_in_amount": null,
              "lease_in_amount": null,
              "remarks": ""
          },
          "total_space": {
              "min_norms_in_ft_sqft": null,
              "proposed_space": "",
              "own": "",
              "rented": "",
              "leased": "",
              "rent_in_amount": null,
              "lease_in_amount": null,
              "remarks": ""
          }
      },
      "proposed_captial_structure_pov_dealership": {
          "showroom": {
              "land": {
                  "own_fund": null,
                  "bank_finance": null,
                  "friend_relative": null,
                  "total": null
              },
              "building": {
                  "own_fund": null,
                  "bank_finance": null,
                  "friend_relative": null,
                  "total": null
              }
          },
          "workshop_parts": {
              "land": {
                  "own_fund": null,
                  "bank_finance": null,
                  "friend_relative": null,
                  "total": null
              },
              "building": {
                  "own_fund": null,
                  "bank_finance": null,
                  "friend_relative": null,
                  "total": null
              },
              "tools_equipment": {
                  "own_fund": null,
                  "bank_finance": null,
                  "friend_relative": null,
                  "total": null
              }
          },
          "retail_identity": {
              "own_fund": null,
              "bank_finance": null,
              "friend_relative": null,
              "total": null
          },
          "working_capital": {
              "vechiles": {
                  "own_fund": null,
                  "bank_finance": null,
                  "friend_relative": null,
                  "total": null
              },
              "spare_accessories": {
                  "own_fund": null,
                  "bank_finance": null,
                  "friend_relative": null,
                  "total": null
              }
          },
          "dms": {
              "own_fund": null,
              "bank_finance": null,
              "friend_relative": null,
              "total": null
          },
          "others": {
              "own_fund": null,
              "bank_finance": null,
              "friend_relative": null,
              "total": null
          }
      },
      "initial_investments": {
          "infrastructure": {
              "rental_advance": null,
              "building_modification": null,
              "retail_identify_element": null,
              "workshop_tools_equipment": null,
              "computers": null,
              "dms_software": null,
              "others": null
          },
          "working_capital": {
              "vehicles": null,
              "spares_oil": null,
              "accessories": null,
              "provision_for_receiables": null,
              "others": null
          },
          "self_declaration": {
              "date": null,
              "signature": ""
          }
      },
      "income_expense_statement": {
          "income": {
              "income_from_sales": {
                  "vehicle_margin": {
                      "moped": {
                          "unit": null,
                          "margin": null,
                          "gross_margin": null
                      },
                      "motor_cycles": {
                          "unit": null,
                          "margin": null,
                          "gross_margin": null
                      },
                      "scooterette": {
                          "unit": null,
                          "margin": null,
                          "gross_margin": null
                      },
                      "scooter": {
                          "unit": null,
                          "margin": null,
                          "gross_margin": null
                      },
                      "two_wheeler": {
                          "unit": null,
                          "margin": null,
                          "gross_margin": null
                      }
                  },
                  "margin_from_accessories": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  },
                  "finance_margin": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  },
                  "insurance_margin": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  },
                  "service_charges_for_registration": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  }
              },
              "income_from_service": {
                  "paid_service_labour": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  },
                  "fsr_reimbursements": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  },
                  "income_from_outside_labour": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  },
                  "income_from_scrap_sales": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  }
              },
              "income_from_parts_oil": {
                  "income_from_parts": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  },
                  "income_from_oil": {
                      "unit": null,
                      "margin": null,
                      "gross_margin": null
                  }
              }
          },
          "expences": {
              "rent": {
                  "showroom": null,
                  "work_shop_spares": null
              },
              "establishment_exps": {
                  "electricity": null,
                  "telephone": null,
                  "water": null,
                  "workshop_fuel_consumables": null,
                  "official_conveyance": null,
                  "admin_stationery_exps": null,
                  "dms_maintenance_charges": null,
                  "misc": null
              },
              "salary_incentives": null,
              "promotional_exps": {
                  "sales_promotional_exps": null,
                  "service_promotional_exps": null
              },
              "secondary_channel_commissions": null,
              "interest": null
          }
      },
      "images": {
          "proprietorship": "",
          "proprietorship_list": [
              ""
          ],         
          "partnership": "",
          "partnership_list": [
              ""
          ],
          "private_limited_company": [
              ""
          ],
          "public_limited_company": [
              ""
          ],
          "photographs": {
              "frontage": "",
              "right_side": "",
              "left_side": "",
              "opposite_side": "",
              "exterior_service_shop": "",
              "interior_service_shop": "",
              "exterior_spare_parts": ""
          },
          "competitors": [],
          "blueprint_showroom": "",
          "blueprint_showroom_list": [],
          "blueprint_service_shop": "",
          "blueprint_service_shop_list": [],
          "aadhar_photo": [],
          "pan_photo": [],
          "bank_reference_letters": [],
          "gst_details": [],
          "form16": [],
          "affidavit": [],
          "gst_documents":  []
        },
          "agreement": {
          "agreed_person": "",
          "agrement_date": null,
          "place": "",
          "agreementDealers": [
            {
              "name": "",
              "signature": ""
            },
            {
              "name": "",
              "signature": ""
            },
            {
              "name": "",
              "signature": ""
            },
            {
              "name": "",
              "signature": ""
            },
            {
              "name": "",
              "signature": ""
            }
          ]
      },
      "user_id": null
  }
    if(this.data.type == "MODIFY"){
      this.isModify = true;
      this.isView = false;
      this.dealerDetails.dealer_app_id = this.data.value.dealer_app_id;
      this.viewDealerDetails();
    }
    if(this.data.type == "VIEW"){
      this.isModify = false;
      this.isView = true;
      this.dealerDetails.dealer_app_id = this.data.value.dealer_app_id;
      this.viewDealerDetails();
    }
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  onEmailChangeEvent(event:any)
  {
     let value = event.target.value;
     const filter=/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
     if(filter.test(value))
      {
       this.isEmailValid = false;
       return true;
      } 
    else
     {           
       this.isEmailValid = true;
        return false;   
     }
  }
  isDocumentExisted(docType){
    return this.documentTypes.includes(docType);
  }
  //  isMobileInvalid(event:any,controlName:string)
  //    {
  //      let mobileNo= event.target.checked;
  //      if(mobileNo !=undefined)
  //      {
  //            if(controlName == 'CONTACT_NO')
  //            {
  //                if(mobileNo)
  //                  this.isMobileValid=true;
  //                else
  //                  this.isMobileValid=false;
  //            }
  //            else if(controlName == 'CONTACT_NO_2')
  //            {
  //              if(mobileNo)
  //                  this.isMobile2Valid=true;
  //                else
  //                  this.isMobile2Valid=false;
  //            }
  //            else if(controlName == 'EMAIL_ID')
  //            {
  //              if(mobileNo)
  //                  this.isEmailIdValid=true;
  //                else
  //                  this.isEmailIdValid=false;
  //            }
  //      }
  //    }
  saveDealerDetails(){
    // if(this.imageList.length < 4){
    //   this.toastr.info("4 Pics Need to Upload in Photographs");
    //   return;
    // }
    // if (this.dealerDetails) {
    //   if (!this.dealerDetails.key_promoter_details.cst_no) {
    //     this.toastr.info("Please fill CST NO");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.dob) {
    //     this.toastr.info("Please fill Date of Birth");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.email) {
    //     this.toastr.info("Please fill E-mail");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.mobile) {
    //     this.toastr.info("Please fill Mobile");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.name) {
    //     this.toastr.info("Please fill Name");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.off_address) {
    //     this.toastr.info("Please fill Office Address");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.off_pincode) {
    //     this.toastr.info("Please fill Office pincode");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.off_tel_no) {
    //     this.toastr.info("Please fill Office Tell NO");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.pan_no) {
    //     this.toastr.info("Please fill Office Pan No");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.res_address) {
    //     this.toastr.info("Please fill Res Address");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.res_pincode) {
    //     this.toastr.info("Please fill Res Pincode");
    //   }
    //   if (!this.dealerDetails.key_promoter_details.res_tel_no) {
    //     this.toastr.info("Please fill Res Tell No");
    //   }
    // }
    
    // Facility
    // let keys = Object.keys(this.dealerDetails.facility);
    // for (let key of keys) {
    //   this.refListOfProperties.forEach((item) => {
    //     if ((item.facilitykey).toLowerCase() === key) {
    //       this.dealerDetails.facility[key] = item;
    //     }
    //   })

    // }
   
    let reqObj= this.dealerDetails;
    reqObj.initial_investments.self_declaration.signature = this.initialSignImg.length > 0 ? this.initialSignImg[0].imgId : null;
    reqObj.images.photographs.frontage = this.imageList.length > 0  && this.imageList[0].imgId?this.imageList[0].imgId:'';
    reqObj.images.photographs.left_side = this.imageList.length > 1  && this.imageList[1].imgId?this.imageList[1].imgId:'';
    reqObj.images.photographs.right_side = this.imageList.length > 2  && this.imageList[2].imgId?this.imageList[2].imgId:'';
    reqObj.images.photographs.opposite_side = this.imageList.length > 3  && this.imageList[3].imgId?this.imageList[3].imgId:'';
    reqObj.images.competitors = this.competitorsImgList.map(x => x.imgId);
    reqObj.images.blueprint_showroom_list = this.showroomPics.map(x => x.imgId);//this.showroomPics.length > 0 && this.showroomPics[0].imgId?this.showroomPics[0].imgId:'';
    reqObj.images.blueprint_service_shop_list = this.servicePics.map(x => x.imgId);  
    reqObj.images.proprietorship_list = this.proprietorshipImg.map(x => x.imgId) ;//this.proprietorshipImg.length > 0 ? this.proprietorshipImg[0].imgId : '';
    reqObj.passport_list = this.passPortImg.map(x => x.imgId) ;
    reqObj.images.partnership_list = this.partnershipImg.map(x => x.imgId) ;// this.partnershipImg.length > 0 ? this.partnershipImg[0].imgId : '';
    reqObj.images.private_limited_company = this.privateCompanyImg.map(x => x.imgId);
    reqObj.images.public_limited_company = this.publicCompanyImg.map(x => x.imgId);    
    reqObj.images.aadhar_photo = this.aadharList.map(x => {
      return {doc_url: x.imgId, type: x.docType}
    }) 
    reqObj.images.pan_photo = this.pancardList.map(x => {
      return {doc_url: x.imgId, type: x.docType}
    }) 
    reqObj.images.bank_reference_letters = this.bankrefList.map(x => {
      return {doc_url: x.imgId, type: x.docType}
    })
    reqObj.images.gst_details = this.gstList.map(x => {
      return {doc_url: x.imgId, type: x.docType}
    }) 
    reqObj.images.form16 = this.formsixteenList.map(x => {
      return {doc_url: x.imgId, type: x.docType}
    }) 
    reqObj.images.affidavit = this.affidavitList.map(x => {
      return {doc_url: x.imgId, type: x.docType}
    })    
    reqObj.images.gst_documents = this.gstList.map(x => {
      return {doc_url: x.imgId, type: x.docType}
    })
    if(this.isModify){
      this.masterService.post(reqObj, '/DealerOnboarding/update').subscribe(
      (resp: any) => {
        if(resp){
          this.isModify = true;
          this.masterData = resp.dealerList;
          this.dialogRef.close('success');
          this.toastr.success("Dealer updated successfully.");
        }
        if(resp.statusCode == 204){
          this.toastr.info(resp.statusMessage);
        }
        this.isShowPageLoader = false;
      },     
      error => {
        if (error.statusCode == 401) {
          this.toastr.error(error.error.Message);
        }
      });
    }
    else
    {
      this.masterService.post(reqObj, '/DealerOnboarding/add').subscribe(
      (resp: any) => {
        if(resp){
          this.isModify = true;
          this.masterData = resp.dealerList;
          this.dialogRef.close('success');
          this.toastr.success("Dealer created successfully.");
        }
        if(resp.statusCode == 204){
          this.toastr.info(resp.statusMessage);
        }
        this.isShowPageLoader = false;
      },
      error => {
        if (error.statusCode == 401) {
          this.toastr.error(error.error.Message);
        }
      });
    }
    this.len=this.dealerDetails.passport_list.length;
    
  }

  
  importDocument(event, name, i) {
    this.masterService.importFile(event.target.files, '/Image/uploadDealerOnboardingImage?imageType='+name, 'multiple').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200 && resp.imageFileList && resp.imageFileList.length) {
          // this.toastr.success(resp.statusMessage);
          // fixing for image upload issue 
          let targetFiles = [];
          targetFiles = event.target.files;
          let fileType = targetFiles[0].type;
          let fileName = targetFiles[0].name;
          let ext = fileName.substr(fileName.lastIndexOf('.')+1);
          resp.imageFileList.filter((el, key) => {
            console.log(el, key);
            let obj: any = {};
            let base64: any;
            var reader = new FileReader();
            reader.readAsDataURL(targetFiles[key]);
            reader.onload = function () {
              base64 = reader.result;
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
            setTimeout(() => {
              obj = {
                type: 'add',
                imgSrc: base64,
                imgId: el,
                imageUrl: el,
                indexVal: '',
                docType: ext 
              };
              if(name == "PASSPORT"){                
                this.passPortImg.push(obj);                
              }
              if(name == "SHOWROOM"){
                // this.showroomPics = [];
                this.showroomPics.push(obj);
              }
              if(name == "SIGNATURE1"){
                this.signatureList1 = [];
                this.signatureList1.push(obj);
                this.dealerDetails.agreement.agreementDealers[0].signature = obj.imgId;
              }
              if(name == "SIGNATURE2"){
                this.signatureList2 = [];
                this.signatureList2.push(obj);
                this.dealerDetails.agreement.agreementDealers[1].signature = obj.imgId;
              }
              if(name == "SIGNATURE3"){
                this.signatureList3 = [];
                this.signatureList3.push(obj);
                this.dealerDetails.agreement.agreementDealers[2].signature = obj.imgId;
              }
              if(name == "SIGNATURE4"){
                this.signatureList4 = [];
                this.signatureList4.push(obj);
                this.dealerDetails.agreement.agreementDealers[3].signature = obj.imgId;
              }
              if(name == "SIGNATURE5"){
                this.signatureList5 = [];
                this.signatureList5.push(obj);
                this.dealerDetails.agreement.agreementDealers[4].signature = obj.imgId;
              }
              if(name == "SERVICE"){
                // this.servicePics = [];
                this.servicePics.push(obj);
              }
              console.log(name)
              if(name == "INITIALIMG"){
                this.initialSignImg = [];
                this.initialSignImg.push(obj);
              }
              console.log(this.initialSignImg)
              if(name == "PROPRIETORSHIP"){
                // this.proprietorshipImg = [];
                this.proprietorshipImg.push(obj);
              }
              if(name == "PARTNERSHIP"){
                // this.partnershipImg = [];
                this.partnershipImg.push(obj);
              }
              if(name == "IMGLIST"){
                if(this.imageList.length < 4){
                  this.imageList.push(obj);
                }
              }
              if(name == "PRIVATE"){
                this.privateCompanyImg.push(obj);
              }
              if(name == "PUBLIC"){
                this.publicCompanyImg.push(obj);
              }
              if(name == "COMPERTITORS_IMG"){
                // this.competitorsImgList = [];
                this.competitorsImgList.push(obj);
              }
              if(name == "Adhercard"){
                // this.competitorsImgList = [];
                this.aadharList.push(obj);
              }
              if(name == "pancard"){
                this.pancardList.push(obj)
              }
              if(name == "Bankref"){
                this.bankrefList.push(obj)
              }
              if(name == "Form16"){
                this.formsixteenList.push(obj)
              }

              if(name == "Affidavit"){
                this.affidavitList.push(obj)
              }
              if(name == "GST"){
                this.gstList.push(obj)
              }
              
            }, 200);
          });
          if(resp.statusMessage != "Image Upload Sucessfully."){
            this.toastr.error(resp.statusMessage);
          }
        }
        else {
          this.toastr.error(resp.statusMessage);
        }

        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        if (error.error.statusCode == 406) {
          this.toastr.error(error.error.statusMessage);
        } else if (error.error.ExceptionMessage == 'Maximum request length exceeded.') {
          this.toastr.error('Maximum size of the image is exceeded');
        } else {
          this.toastr.error(error.statusMessage);
        }
        if (error.status == 401) {
          this.loginService.logout();
        }
        this.isShowPageLoader = false;
      }
    )
  }
  deleteImage(img,name){
    if(name== "PUBLIC"){
      this.publicCompanyImg.splice(this.publicCompanyImg.indexOf(img), 1);
    }
    if(name== "PRIVATE"){
      this.privateCompanyImg.splice(this.privateCompanyImg.indexOf(img), 1);
    }
    if(name== "PHOTOS"){
      this.imageList.splice(this.imageList.indexOf(img), 1);
    }
    if(name== "COMPERTITORS_IMG"){
      this.competitorsImgList.splice(this.competitorsImgList.indexOf(img), 1);
    }
    if(name == "SERVICE"){
      this.servicePics.splice(this.servicePics.indexOf(img), 1);
    }
    if(name== "SHOWROOM"){
      this.showroomPics.splice(this.showroomPics.indexOf(img), 1);
    }
    if(name == "PROPRIETORSHIP"){
      // this.proprietorshipImg = [];
      this.proprietorshipImg.splice(this.proprietorshipImg.indexOf(img), 1);
    }
    if(name == "PARTNERSHIP"){
      // this.partnershipImg = [];
      this.partnershipImg.splice(this.partnershipImg.indexOf(img), 1);
    }
    if(name == "Adhercard"){
      this.aadharList.splice(this.aadharList.indexOf(img), 1);
    }
    if(name == "pancard"){
      this.pancardList.splice(this.pancardList.indexOf(img), 1);
    }
    if(name == "Bankref"){
      this.bankrefList.splice(this.bankrefList.indexOf(img), 1);
    }
    if(name == "Form16"){
      this.formsixteenList.splice(this.formsixteenList.indexOf(img), 1);
    }

    if(name == "Affidavit"){
      this.affidavitList.splice(this.affidavitList.indexOf(img), 1);
    }
    if(name == "GST"){
      this.gstList.splice(this.gstList.indexOf(img), 1);
    }
    if(name == "PASSPORT"){
      this.passPortImg.splice(this.passPortImg.indexOf(img), 1);
      this.len=this.dealerDetails.passport_list.length-1;
    }
  }
  deleteImg(name) {
    // if(name== "COMPERTITORS_IMG"){
    //   this.competitorsImgList = [];
    // }   
      // if(name== "SHOWROOM"){
      //   this.showroomPics =[];
      // }
      // if(name == "SERVICE"){
      //   this.servicePics=[];
      // }
      if(name == "INITIALIMG"){
        this.initialSignImg = [];
      }
      if(name == "SIGNATURE1"){
        this.signatureList1 = [];
        this.dealerDetails.agreement.agreementDealers[0].signature =null;
      }
      if(name == "SIGNATURE2"){
        this.signatureList2 = [];
        this.dealerDetails.agreement.agreementDealers[1].signature =null;
      }
      if(name == "SIGNATURE3"){
        this.signatureList3 = [];
        this.dealerDetails.agreement.agreementDealers[2].signature =null;
      }
      if(name == "SIGNATURE4"){
        this.signatureList4 = [];
        this.dealerDetails.agreement.agreementDealers[3].signature =null;
      }
      if(name == "SIGNATURE5"){
        this.signatureList5 = [];
        this.dealerDetails.agreement.agreementDealers[3].signature =null;
      }
      // if(name == "PROPRIETORSHIP"){
      //   this.proprietorshipImg = [];
      // }
      // if(name == "PARTNERSHIP"){
      //   this.partnershipImg = [];
      // }
  }

  getImgPath(img) {
    // return environment.uvdHost + '/VehicleImages/' + img;
    //return environment.ImageHost + img;
    return environment.uvdHost + '/Image/image/file/download?fileName=' +img;
  }

  getPath(img) {
    // return environment.uvdHost + '/VehicleImages/' + img;
    //return environment.ImageHost + img;
    return environment.uvdHost + '/Image/image/file/download?fileName=' +img;
  }
  

  addDealer(){
    this.dealerDetails.businees_automobile.manufacturer_details =[...this.dealerDetails.businees_automobile.manufacturer_details, this.dealerType] 
    // .push(JSON.parse( JSON.stringify( this.dealerType)));
  }

  deleteDealer(i){
    this.dealerDetails.businees_automobile.manufacturer_details.splice(i, 1);
  }
  
  addBusiness(){
    this.dealerDetails.business_others.push(JSON.parse( JSON.stringify( this.typeOfBusiness)))
  }

  deleteBusiness(i){
    this.dealerDetails.business_others.splice(i, 1);
  }



  addProperties(){    		
    this.dealerDetails.details_proposed_pov_dealership.details_of_management.push(JSON.parse( JSON.stringify( this.typeOfProperty)));
  }
  deleteProperties(i){
    this.dealerDetails.details_proposed_pov_dealership.details_of_management.splice(i, 1);
  }

  addBankers(){
    this.dealerDetails.details_of_bankers.push(JSON.parse(JSON.stringify( this.typeOfBankers)));
  }
  deleteBankers(i){
    this.dealerDetails.details_of_bankers.splice(i, 1);
  }


  parseIntValue(value){
    return value ? parseFloat(value) : 0
  }
  sumFirstYear(){
   return this.parseIntValue(this.dealerDetails.buisness_plan_povd.first_year.moped.value) + 
   this.parseIntValue(this.dealerDetails.buisness_plan_povd.first_year.motor_cycle.value) +
   this.parseIntValue(this.dealerDetails.buisness_plan_povd.first_year.scooter.value) + 
   this.parseIntValue(this.dealerDetails.buisness_plan_povd.first_year.total_two_wheelers.value) +
   this.parseIntValue(this.dealerDetails.buisness_plan_povd.first_year.spare_parts.value) +
   this.parseIntValue(this.dealerDetails.buisness_plan_povd.first_year.service_inflow.value)
  }

  sumSecondYear(){
    return this.parseIntValue(this.dealerDetails.buisness_plan_povd.second_year.moped.value) + 
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.second_year.motor_cycle.value) +
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.second_year.scooter.value) + 
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.second_year.total_two_wheelers.value) +
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.second_year.spare_parts.value) +
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.second_year.service_inflow.value)
   }

   sumThirdYear(){
    return this.parseIntValue(this.dealerDetails.buisness_plan_povd.third_year.moped.value) + 
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.third_year.motor_cycle.value) +
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.third_year.scooter.value) + 
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.third_year.total_two_wheelers.value) +
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.third_year.spare_parts.value) +
    this.parseIntValue(this.dealerDetails.buisness_plan_povd.third_year.service_inflow.value)
   }

   sumOfOwnFund(){
    return this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.showroom.land.own_fund)+
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.showroom.building.own_fund) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.land.own_fund) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.building.own_fund) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.tools_equipment.own_fund) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.retail_identity.own_fund) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.working_capital.vechiles.own_fund) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.working_capital.spare_accessories.own_fund) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.dms.own_fund) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.others.own_fund)
   }
   sumOfBanksOrFriendsInstutaions(){
    return this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.showroom.land.bank_finance)+
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.showroom.building.bank_finance) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.land.bank_finance) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.building.bank_finance) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.tools_equipment.bank_finance) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.retail_identity.bank_finance) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.working_capital.vechiles.bank_finance) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.working_capital.spare_accessories.bank_finance) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.dms.bank_finance) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.others.bank_finance)
   }
   sumOfFriendOrRelativesInstutaions(){
    return this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.showroom.land.friend_relative)+
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.showroom.building.friend_relative) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.land.friend_relative) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.building.friend_relative) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.tools_equipment.friend_relative) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.retail_identity.friend_relative) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.working_capital.vechiles.friend_relative) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.working_capital.spare_accessories.friend_relative) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.dms.friend_relative) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.others.friend_relative)
   }

   sumOfTotal(){
    return this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.showroom.land.total)+
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.showroom.building.total) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.land.total) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.building.total) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.workshop_parts.tools_equipment.total) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.retail_identity.total) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.working_capital.vechiles.total) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.working_capital.spare_accessories.total) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.dms.total) +
    this.parseIntValue(this.dealerDetails.proposed_captial_structure_pov_dealership.others.total)
   }

   sumOfVehicleMargin(){
    return this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.two_wheeler.gross_margin) 
    // +
    // this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.motor_cycles.gross_margin) +
    // this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.scooterette.gross_margin) +
    // this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.scooter.gross_margin)
   }

   sumOfTotalAccessories(){
      return this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_sales.margin_from_accessories.gross_margin)+
      this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_sales.finance_margin.gross_margin)+
      this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_sales.insurance_margin.gross_margin)+
      this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_sales.service_charges_for_registration.gross_margin)
   }

   sumOfTotalIncome(){
    return this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_service.paid_service_labour.gross_margin)
    + this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_service.fsr_reimbursements.gross_margin) 
    + this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_service.income_from_outside_labour.gross_margin)
    + this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_service.income_from_scrap_sales.gross_margin)
   }

   sumOfTotalIncomeParts (){
    return this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_parts_oil.income_from_parts.gross_margin)+
    this.parseIntValue(this.dealerDetails.income_expense_statement.income.income_from_parts_oil.income_from_oil.gross_margin)
   }
   sumOfRent(){
    return this.parseIntValue(this.dealerDetails.income_expense_statement.expences.rent.showroom)+
    this.parseIntValue(this.dealerDetails.income_expense_statement.expences.rent.work_shop_spares)
   }

   sumOfEstablishment (){
    return this.parseIntValue(this.dealerDetails.income_expense_statement.expences.establishment_exps.electricity) +
    this.parseIntValue(this.dealerDetails.income_expense_statement.expences.establishment_exps.telephone) +
    this.parseIntValue(this.dealerDetails.income_expense_statement.expences.establishment_exps.water) +
    this.parseIntValue(this.dealerDetails.income_expense_statement.expences.establishment_exps.workshop_fuel_consumables) +
        this.parseIntValue(this.dealerDetails.income_expense_statement.expences.establishment_exps.official_conveyance) +
        this.parseIntValue(this.dealerDetails.income_expense_statement.expences.establishment_exps.admin_stationery_exps) +
        this.parseIntValue(this.dealerDetails.income_expense_statement.expences.establishment_exps.dms_maintenance_charges) +
        this.parseIntValue(this.dealerDetails.income_expense_statement.expences.establishment_exps.misc);     
   }

   sumOfExpense()
    {
      return this.parseIntValue(this.dealerDetails.income_expense_statement.expences.salary_incentives)+
      this.parseIntValue(this.dealerDetails.income_expense_statement.expences.promotional_exps.sales_promotional_exps)+
      this.parseIntValue(this.dealerDetails.income_expense_statement.expences.promotional_exps.service_promotional_exps)+
      this.parseIntValue(this.dealerDetails.income_expense_statement.expences.secondary_channel_commissions)+
      this.parseIntValue(this.dealerDetails.income_expense_statement.expences.interest)
    }
   
    sumOfExpansiveTotal(){
      this.expanseValue = this.sumOfRent() + this.sumOfEstablishment() + this.sumOfExpense();
      this.calculation();
      return  this.expanseValue;
    }
    sumofTotalIncomeValue(){
      this.incomevalue = this.sumOfVehicleMargin() + this.sumOfTotalAccessories() + this.sumOfTotalIncome() + this.sumOfTotalIncomeParts();
      this.calculation();
      return this.incomevalue;
    }
    investmentPercent(){
      this.investmentPerMonth = Number(((this.cashProfit/this.totalInvestment)*100).toFixed(2))
      this.investmentPerYear = Number((((this.cashProfit*12)/this.totalInvestment)*100).toFixed(2))
    }
    calculation(){
      // if(this.expanseValue > 0 && this.incomevalue >0)
      // {
        this.cashProfit = (this.incomevalue - this.expanseValue);
        if(this.cashProfit !== 0 && this.totalInvestment > 0){
          this.investmentPercent()
        }
      // }
    }

    sumOfUnitMargin(type) {
      if(type === 'Moped') {
        // this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.moped.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.moped.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.moped.margin;
      }
      else if(type === 'twoWheeler'){
        this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.two_wheeler.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.two_wheeler.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.two_wheeler.margin;
      }
      else if(type === 'MotorCycles') {
        // this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.motor_cycles.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.motor_cycles.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.motor_cycles.margin;
      }
      else if(type === "Scooterrette")
      {
        // this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.scooterette.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.scooterette.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.scooterette.margin;
      }
      else if(type === "Scooter")
      {
        //  this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.scooter.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.scooter.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.vehicle_margin.scooter.margin;
      }
      else if(type === 'MarginfromAccessories'){
        this.dealerDetails.income_expense_statement.income.income_from_sales.margin_from_accessories.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.margin_from_accessories.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.margin_from_accessories.margin;
      }
      else if(type === 'FinanceMargin'){
        this.dealerDetails.income_expense_statement.income.income_from_sales.finance_margin.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.finance_margin.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.finance_margin.margin;
      }
      else if(type === 'InsuranceMargin')
      {
              this.dealerDetails.income_expense_statement.income.income_from_sales.insurance_margin.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.insurance_margin.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.insurance_margin.margin;
      }
      else if(type === 'ServiceChargesForRegistration')
      {
        this.dealerDetails.income_expense_statement.income.income_from_sales.service_charges_for_registration.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_sales.service_charges_for_registration.unit * this.dealerDetails.income_expense_statement.income.income_from_sales.service_charges_for_registration.margin;
      }
      else if(type === 'PaidServiceLabour'){
        this.dealerDetails.income_expense_statement.income.income_from_service.paid_service_labour.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_service.paid_service_labour.unit * this.dealerDetails.income_expense_statement.income.income_from_service.paid_service_labour.margin;
      }
      else if(type === 'FSRReimbursements'){
        this.dealerDetails.income_expense_statement.income.income_from_service.fsr_reimbursements.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_service.fsr_reimbursements.unit * this.dealerDetails.income_expense_statement.income.income_from_service.fsr_reimbursements.margin;
      }
      else if(type === 'Incomefromoutsidelabour'){
        this.dealerDetails.income_expense_statement.income.income_from_service.income_from_outside_labour.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_service.income_from_outside_labour.unit * this.dealerDetails.income_expense_statement.income.income_from_service.income_from_outside_labour.margin;
      }
      else if(type === 'Incomefromscrapsales')
      {
        this.dealerDetails.income_expense_statement.income.income_from_service.income_from_scrap_sales.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_service.income_from_scrap_sales.unit * this.dealerDetails.income_expense_statement.income.income_from_service.income_from_scrap_sales.margin;
      }
      else if(type === 'IncomefromParts')
      {
        this.dealerDetails.income_expense_statement.income.income_from_parts_oil.income_from_parts.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_parts_oil.income_from_parts.unit * this.dealerDetails.income_expense_statement.income.income_from_parts_oil.income_from_parts.margin;
      }
      else if(type === 'IncomefromOil'){
        this.dealerDetails.income_expense_statement.income.income_from_parts_oil.income_from_oil.gross_margin = this.dealerDetails.income_expense_statement.income.income_from_parts_oil.income_from_oil.unit * this.dealerDetails.income_expense_statement.income.income_from_parts_oil.income_from_oil.margin;
      }
    }
    
    sumOfInfrastructure(){
      const inv = this.parseIntValue(this.dealerDetails.initial_investments.infrastructure.rental_advance)+
      this.parseIntValue(this.dealerDetails.initial_investments.infrastructure.building_modification)+
      this.parseIntValue(this.dealerDetails.initial_investments.infrastructure.retail_identify_element)+
      this.parseIntValue(this.dealerDetails.initial_investments.infrastructure.workshop_tools_equipment)+
      this.parseIntValue(this.dealerDetails.initial_investments.infrastructure.computers)+
      this.parseIntValue(this.dealerDetails.initial_investments.infrastructure.dms_software)+
      this.parseIntValue(this.dealerDetails.initial_investments.infrastructure.others);
      
      if(this.totalInfra !== inv){
        this.totalInfra = inv;
        this.sumOfInvestments();
      }
      return inv;
    }

    sumOfWorkingCapital(){
      const inv = this.parseIntValue(this.dealerDetails.initial_investments.working_capital.vehicles)+
            this.parseIntValue(this.dealerDetails.initial_investments.working_capital.spares_oil)+
            this.parseIntValue(this.dealerDetails.initial_investments.working_capital.accessories)+
            this.parseIntValue(this.dealerDetails.initial_investments.working_capital.provision_for_receiables)+
            this.parseIntValue(this.dealerDetails.initial_investments.working_capital.others);
        
        if(this.totalWork!== inv){
          this.totalWork = inv;
          this.sumOfInvestments();
        }
        return inv;
    }

    sumOfInvestments(){
      this.totalInvestment = this.totalInfra + this.totalWork;
      if(this.cashProfit !== 0 && this.totalInvestment > 0){
        this.investmentPercent()
      }
    }
    DownloadPDf() {
      let tab = window.open();
      this.masterService.getPDF('','/DealerOnboarding/PDFDownload?dealer_app_id=' + this.dealerDetails.dealer_app_id)
        .subscribe((data:any) => {
          const fileUrl = URL.createObjectURL(data);
          tab.location.href = fileUrl;
        });
    }
   viewDealerDetails(){
    this.isShowPageLoader = true;
    this.masterService.get('', '/DealerOnboarding/view?dealer_app_id=' +this.dealerDetails.dealer_app_id).subscribe(
        (resp: any) => {
          
          //resp.agreement.agreementDealers = this.dealerDetails.agreement.agreementDealers;
          this.dealerDetails = resp;
          
          this.passPortImg = this.dealerDetails.passport_list ? this.dealerDetails.passport_list.map(x =>
          {
            return  {imgId : x,imageUrl: x , imgSrc:x, type:'update',
            docType: 'img'}
          }) : [];
          this.initialSignImg = this.dealerDetails.initial_investments.self_declaration.signature ? [{
            imgId : this.dealerDetails.initial_investments.self_declaration.signature,
            imageUrl: this.dealerDetails.initial_investments.self_declaration.signature,
             imgSrc: this.dealerDetails.initial_investments.self_declaration.signature, 
             type:'update',
             docType: 'img'
            }] : []
          if(this.dealerDetails.images.photographs.frontage){
            this.imageList.push(
              {
                imgId : this.dealerDetails.images.photographs.frontage,
                imageUrl: this.dealerDetails.images.photographs.frontage ,
                 imgSrc:this.dealerDetails.images.photographs.frontage, 
                 type:'update',
                 docType: 'img'
              }
            )
          }
          if(this.dealerDetails.images.photographs.left_side){
            this.imageList.push(
              {
                imgId : this.dealerDetails.images.photographs.left_side,
                imageUrl: this.dealerDetails.images.photographs.left_side ,
                 imgSrc:this.dealerDetails.images.photographs.left_side, 
                 type:'update',
                 docType: 'img'
              }
            )
          }
          if(this.dealerDetails.images.photographs.right_side){
            this.imageList.push(
              {
                imgId : this.dealerDetails.images.photographs.right_side,
                imageUrl: this.dealerDetails.images.photographs.right_side ,
                 imgSrc:this.dealerDetails.images.photographs.right_side, 
                 type:'update',
                 docType: 'img'
              }
            )
          }
          if(this.dealerDetails.images.photographs.opposite_side){
            this.imageList.push(
              {
                imgId : this.dealerDetails.images.photographs.opposite_side,
                imageUrl: this.dealerDetails.images.photographs.opposite_side ,
                 imgSrc:this.dealerDetails.images.photographs.opposite_side, 
                 type:'update',
                 docType: 'img'
              }
            )
          }
          this.competitorsImgList = this.dealerDetails.images.competitors.map((x) =>{
            return  {imgId : x,imageUrl: x , imgSrc:x, type:'update',
            docType: 'img'}
          });
          // this.showroomPics = this.dealerDetails.images.blueprint_showroom ?
          //  [{imgId : this.dealerDetails.images.blueprint_showroom,imageUrl: this.dealerDetails.images.blueprint_showroom , imgSrc:this.dealerDetails.images.blueprint_showroom, type:'update'}] : []
          
           this.showroomPics = this.dealerDetails.images.blueprint_showroom_list ? this.dealerDetails.images.blueprint_showroom_list.map(x =>{
            return  {imgId : x,imageUrl: x , imgSrc:x, type:'update',
            docType: 'img'}
          }): []
           // this.servicePics = this.dealerDetails.images.blueprint_service_shop ?  [{imgId : this.dealerDetails.images.blueprint_service_shop,imageUrl: this.dealerDetails.images.blueprint_service_shop , imgSrc:this.dealerDetails.images.blueprint_service_shop, type:'update'}]: [];
          this.servicePics = this.dealerDetails.images.blueprint_service_shop_list ? this.dealerDetails.images.blueprint_service_shop_list.map(x =>{
            return  {imgId : x,imageUrl: x , imgSrc:x, type:'update',
            docType: 'img'}
          }) : [];
          this.proprietorshipImg = this.dealerDetails.images.proprietorship_list ? this.dealerDetails.images.proprietorship_list.map(x =>{
            return  {imgId : x,imageUrl: x , imgSrc:x, type:'update',
            docType: 'img'}
          }) : [];
          //this.dealerDetails.images.proprietorship?  [{imgId : this.dealerDetails.images.proprietorship,imageUrl: this.dealerDetails.images.proprietorship , imgSrc:this.dealerDetails.images.proprietorship, type:'update'}]: [];
          this.partnershipImg = this.dealerDetails.images.partnership_list ? this.dealerDetails.images.partnership_list.map(x =>{
            return  {imgId : x,imageUrl: x , imgSrc:x, type:'update',
            docType: 'img'}
          }): []; 
          // ? [{
          //   imgId : this.dealerDetails.images.partnership,
          //   imageUrl: this.dealerDetails.images.partnership , 
          //   imgSrc:this.dealerDetails.images.partnership, type:'update'}]: []


          this.publicCompanyImg = this.dealerDetails.images.public_limited_company.map(x =>{
            return  {imgId : x,imageUrl: x , imgSrc:x, type:'update',
            docType: 'img'}
          });
          this.privateCompanyImg = this.dealerDetails.images.private_limited_company.map( (x) =>{
            return  {imgId : x,imageUrl: x , imgSrc:x, type:'update',
            docType: 'img'}
          });
          this.aadharList = this.dealerDetails.images.aadhar_photo.map( (x) =>{
            return  {
              imgId : x.doc_url,
              imageUrl: x.doc_url, 
              imgSrc:x.doc_url, 
              type:'update',
              docType: x.type
            }
          });
          this.pancardList = this.dealerDetails.images.pan_photo.map( (x) =>{
            return  {
              imgId : x.doc_url,
              imageUrl: x.doc_url, 
              imgSrc:x.doc_url, 
              type:'update',
              docType: x.type
            }
          });
          this.bankrefList = this.dealerDetails.images.bank_reference_letters.map( (x) =>{
            return  {
              imgId : x.doc_url,
              imageUrl: x.doc_url, 
              imgSrc:x.doc_url, 
              type:'update',
              docType: x.type
            }
          });
          this.gstList = this.dealerDetails.images.gst_details.map( (x) =>{
            return  {
              imgId : x.doc_url,
              imageUrl: x.doc_url, 
              imgSrc:x.doc_url, 
              type:'update',
              docType: x.type
            }
          });

          this.formsixteenList = this.dealerDetails.images.form16.map( (x) =>{
            return  {
              imgId : x.doc_url,
              imageUrl: x.doc_url, 
              imgSrc:x.doc_url, 
              type:'update',
              docType: x.type
            }
          });
          this.affidavitList = this.dealerDetails.images.affidavit.map( (x) =>{
            return  {
              imgId : x.doc_url,
              imageUrl: x.doc_url, 
              imgSrc:x.doc_url, 
              type:'update',
              docType: x.type
            }
          });
          this.gstList = this.dealerDetails.images.gst_documents.map( (x) =>{
            return  {
              imgId : x.doc_url,
              imageUrl: x.doc_url, 
              imgSrc:x.doc_url, 
              type:'update',
              docType: x.type
            }
          });
          console.log(this.publicCompanyImg, this.privateCompanyImg);

          if(this.dealerDetails.agreement.agreementDealers.length > 0){
            this.signatureList1 = this.dealerDetails.agreement.agreementDealers[0].signature ? [
              {
                imgId : this.dealerDetails.agreement.agreementDealers[0].signature,
                imageUrl: this.dealerDetails.agreement.agreementDealers[0].signature,
                imgSrc: this.dealerDetails.agreement.agreementDealers[0].signature,
                type:'update',
                docType: 'img'
              }
            ] : [];
            this.signatureList2 = this.dealerDetails.agreement.agreementDealers[1].signature ? [
              {
                imgId : this.dealerDetails.agreement.agreementDealers[1].signature,
                imageUrl: this.dealerDetails.agreement.agreementDealers[1].signature,
                imgSrc: this.dealerDetails.agreement.agreementDealers[1].signature,
                type:'update',
                docType: 'img'
              }
            ] : [];
            this.signatureList3 =this.dealerDetails.agreement.agreementDealers[2].signature ? [
              {
                imgId : this.dealerDetails.agreement.agreementDealers[2].signature,
                imageUrl: this.dealerDetails.agreement.agreementDealers[2].signature,
                imgSrc: this.dealerDetails.agreement.agreementDealers[2].signature,
                type:'update',
                docType: 'img'
              }
            ] : [];
            this.signatureList4 =this.dealerDetails.agreement.agreementDealers[3].signature ? [
              {
                imgId : this.dealerDetails.agreement.agreementDealers[3].signature,
                imageUrl: this.dealerDetails.agreement.agreementDealers[3].signature,
                imgSrc: this.dealerDetails.agreement.agreementDealers[3].signature,
                type:'update',
                docType: 'img'
              }
            ] : []
            this.signatureList5 =this.dealerDetails.agreement.agreementDealers[4].signature ? [
              {
                imgId : this.dealerDetails.agreement.agreementDealers[4].signature,
                imageUrl: this.dealerDetails.agreement.agreementDealers[4].signature,
                imgSrc: this.dealerDetails.agreement.agreementDealers[4].signature,
                type:'update',
                docType: 'img'
              }
            ]: []
          }
          
          this.dealerDetails.businees_automobile.manufacturer_details = 
          this.dealerDetails.businees_automobile.manufacturer_details.map((x) => {
            return {
              ...x,
              started_at : x.started_at ? new Date(x.started_at) : null
            }
          });
          this.dealerDetails.business_others = 
          this.dealerDetails.business_others.map((x) => {
            return {
              ...x,
              started_at : x.started_at ? new Date(x.started_at) : null
            }
          })
          this.dealerDetails.key_promoter_details.dob = this.dealerDetails.key_promoter_details.dob ? new Date(this.dealerDetails.key_promoter_details.dob) : null
          this.dealerDetails.details_of_bankers = this.dealerDetails.details_of_bankers.map(x => {
            return {
              ...x,
              transaction_since: x.transaction_since ? new Date(x.transaction_since): null
            }
          })
          this.dealerDetails.initial_investments.self_declaration.date = this.dealerDetails.initial_investments.self_declaration.date ? new Date(this.dealerDetails.initial_investments.self_declaration.date): null;
          this.dealerDetails.agreement.agrement_date = this.dealerDetails.agreement.agrement_date ? new Date(this.dealerDetails.agreement.agrement_date): null;
          this.isShowPageLoader = false;
          this.len=this.dealerDetails.passport_list.length;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
          this.isShowPageLoader = false;
        });
   }

  closePopup() {
    this.dialogRef.close();
  }

}
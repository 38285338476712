import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-grn-aps-search',
  templateUrl: './grn-aps-search.component.html',
  styleUrls: ['./grn-aps-search.component.scss']
})
export class GrnApsSearchComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public apsGrn: any = {};
  public vendorList: any = [];
  public vendorRows: any = [

  ];
  // public status: any = [
  //   {
  //     name: 'GRN completed',
  //     value: '0'
  //   },
  //   {
  //     name: 'GRN not completed',
  //     value: '2'
  //   }
  // ]
  public status: any = [
    {
      name: 'GRN Not Done',
      value: '0'
    },
    {
      name: 'GRN Completed',
      value: '2'
    },
    {
      name: 'Completed',
      value: '3'
    },
    {
      name: 'Partially Done',
      value: '4'
    }
  ];
  public isShowPageLoader: boolean;

  constructor(
    private dialogRef: MatDialogRef<GrnApsSearchComponent>,
    private masterService: MasterService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  ngOnInit() {
    if (this.data) {
      if (this.data.value == "driveX") {
        this.vendorList = [];
        for (var i = 0; i < this.data.vendorList.length; i++) {
          this.vendorList.push({
            'APS_Code': 0,
            'APS_Name': ''
          });
          this.vendorList[i].APS_Code = this.data.vendorList[i].vendorId;
          this.vendorList[i].APS_Name = this.data.vendorList[i].vendorName;
        }
        this.apsGrn.APS_Code = this.data.vendorList[0].vendorId;
        if(this.data.isDrivexGRN){
          this.apsGrn.APS_Code = this.data.vendorList[1].vendorId;
        }
        if(this.data.vendorId){
          this.apsGrn.APS_Code = this.data.vendorId;
        }
      }
      else if(this.data.value == "warehouse"){
        this.vendorList = this.data.vendorList.map( x=> {
          return {
            APS_Code: x.vendorId,
            APS_Name: x.vendorName
          }
        });
        if(this.data.vendorName){
          this.apsGrn.APS_Code = this.data.vendorName
        }
      }
      
      else {
        this.vendorList = this.data.vendorList && this.data.vendorList.length > 0 ? this.data.vendorList : [];
        this.vendorList.forEach(value => {
          if (this.data.vendorName == value) {
            this.apsGrn.APS_Code = value.APS_Code;
          }
        });
      }
    }
    this.inlineFilter = {
      purchaseOrderId: '',
      invoiceNo: '',
      invoiceDate: '',
      invoiceValue: '',
      grnStatus: ''
    };
  }

  apsGrnSearch() {
    let reqObj: any = {};
    if (!this.apsGrn.APS_Code) {
      this.toastr.error('Vendor name is empty');
      return;
    }
    reqObj.dealerId = CommonService.getUserData().DEALER_ID;
    reqObj.branchId = CommonService.getUserData().BRANCH_ID;
    reqObj.invoiceNo = this.apsGrn.invoiceNo ? this.apsGrn.invoiceNo :null;
    //reqObj.newInvoiceNo=this.apsGrn.newInvoiceNo?this.apsGrn.newInvoiceNo:null;
    reqObj.fromdate = this.apsGrn.fromdate ? CommonService.getReqDateFormat(this.apsGrn.fromdate) : null;
    reqObj.todate = this.apsGrn.todate ? CommonService.getReqDateFormat(this.apsGrn.todate) : null;
    reqObj.warehouseId = null;
    if(this.data.isDrivexGRN){
      reqObj.isFromDrivexGRN = true;
    }else{
      reqObj.isFromDrivexGRN = false;
    }
    // reqObj = Object.assign(reqObj, this.apsGrn);
    // for (const key in reqObj) {
    //   if (reqObj[key] == '') {
    //     delete reqObj[key];
    //   }
    // }
    this.isShowPageLoader = true;
    if (this.data.value == "driveX") {
      reqObj.vendorId = this.apsGrn.APS_Code;
      this.masterService.post(reqObj, '/SparePartGRN/listDrivexInvoice').subscribe(
        (resp: any) => {
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 200) {
            this.vendorRows = [];
            this.vendorRows = resp.invoiceList;
          }
        }, error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.statusMessage);
        }
      )
    }
    else if(this.data.value === "warehouse"){
      reqObj.vendorId = this.apsGrn.APS_Code;
      this.masterService.post(reqObj, '/Warehouse/Parts/listGRNInvoice').subscribe(
        (resp: any) => {
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 200) {
            this.vendorRows = [];
            this.vendorRows = resp.invoiceList;
          }
        }, error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.statusMessage);
        }
      )
    }
    else {
      
    reqObj.APS_Code = this.apsGrn.APS_Code;
      this.masterService.post(reqObj, '/SparePartGRN/invoiceListSAPCallAPS').subscribe(
        (resp: any) => {
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 200 && resp.invoiceList.length > 0) {
            this.vendorRows = resp.invoiceList;
          }
        }, error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.statusMessage);
        }
      )
    }
  }
  selectedItem() {
    if (this.selected[0].grnStatus == 2 || this.selected[0].grnStatus == 3) {
      this.toastr.error('GRN is completed.Please select the another invoice...');
      return;
    }
    let closeObj = { obj: this.selected[0], vendorId: this.apsGrn.APS_Code };
    if(localStorage.getItem('isDriveXLogin')){
      localStorage.setItem('DEALER_ID', this.selected[0].dealerId);
      localStorage.setItem('BRANCH_ID', this.selected[0].branchId);
    }
    this.dialogRef.close(closeObj);
  }

  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { JobCardSearchComponent } from 'src/app/shared/job-card-search/job-card-search.component';
import { CustomerRootObject, Customer, CommunicationAddress, Vehicle, CommnAddressPhone } from '../../api-services/dto/customer';
import { JobCardService } from '../../api-services/job-card.service';
import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../api-services/common.service';
import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { copyObj } from '@angular/animations/browser/src/util';
import { MasterService } from 'src/app/api-services/master.service';
import { Procurement, ProcurementFollowUp, ProcurementFollowUpCreate } from 'src/app/api-services/dto/uvd-procurement';
import { LoginService } from 'src/app/api-services/login.service';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {
  public lang: any;
  public createCustomer: any = {};
  public selectedCustomer: any;
  public customerType = [];
  public maxDate: any = new Date();
  public areaList = [];
  public isDisable: boolean = false;
  public issaledtDisable : boolean = false;
  public procurement = new Procurement();
  public isotherTVS : boolean = true;
  public isShowPageLoader: boolean = false;
  public masterData: any;
  public alphaPattern = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$';
  public modelName: any = '';
  public partName: any;
  public modelList: Array<any> = [];
  public colorList: Array<any> = [];
  refFrameNo;
  refModelName;
  refPartName;
  public taxType = [{
    id: 1,
    name: 'Normal'
  }, {
    id: 2,
    name: 'No Tax'
  }, {
    id: 3,
    name: 'GST EXEMPT'
  }];
  stateList: Array<any> = [];
  customerRootObject = new CustomerRootObject();
  customerObj = new Customer();
  communicationAddress = new CommunicationAddress();
  vehicle = new Vehicle();
  commnAddressPhone = new CommnAddressPhone();
  constructor(private masterService: MasterService,private jobCardService: JobCardService
    , @Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService
    , public dialog: MatDialog, private loginService:LoginService,
    public dialogRef: MatDialogRef<CreateCustomerComponent>) {
  }

  ngOnInit() {
    this.lang = CommonService.getLang();
  
    if (this.data && this.data.frameNo) {
      this.vehicle.FRAME_NO = this.data.frameNo;
      this.getFrameDetails(true);
     
       
      if(this.data && this.data.type == 'uvdCustomer' ) {
        this.getMasterData();         
    }
  }
  if (CommonService.getServAppData()) {
    this.customerType = CommonService.getServAppData().CustTypeList;
    this.areaList = CommonService.getServAppData().AreaList;
  }
      this.customerObj.CUST_TAX_CAT_ID = 1;
     
      this.getStateList();
      this.vehicle.DEALER_ID = CommonService.getUserData().DEALER_ID;
      this.vehicle.STATUS = '5';
      this.vehicle.ROW_STATE = 'Created';
      this.customerObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
      this.customerObj.CUST_TY_ID = 8;
      this.customerObj.CUST_GRP_ID = 8;
      this.customerObj.GENDER = 'M';
      this.customerObj.CREDIT_CARD = false;
      this.customerObj.MARRIED = false;
      this.customerObj.SEND_SERV_REM = true;
      this.customerObj.SL_CODE = 0;
      this.customerObj.ROW_STATE = 'Created';
      this.customerObj.CREATED_BY = CommonService.getUserData().USER_ID;
      this.customerObj.MODIFIED_BY = CommonService.getUserData().USER_ID;
      this.commnAddressPhone.PHONE_TYPE = 6;
      this.commnAddressPhone.IS_VALID = true;
     
    
  }
  /**
   * Get Status List
   */
  getStateList() {
    let params = new HttpParams();
    params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
      .set('CountryCode', CommonService.getDealerData().COUNTRY_CODE)
      .set('BranchID', CommonService.getUserData().BRANCH_ID)
      .set('UserID', CommonService.getUserData().USER_ID)
    this.jobCardService.getStateList(params).subscribe(
      (resp: any) => {
        this.stateList = resp.data;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      },
      error => {
        this.toastr.error(error.error.Message);
        if (error.status == 401) {
          this.loginService.logout();
        }
      }
    )
  }

  
  getModel(event) {
    // if(!this.modelName) {
    //   this.modelList=[];
    //   return;
    // } 
    if (this.procurement.brandId == '' || this.procurement.brandId == undefined) {
      this.toastr.warning('Please select vehicle brand');
      return;
    }
    this.procurement.modelId = '';
    // if (event.code != 'ArrowUp' && event.code != 'ArrowDown' && this.procurement.brandId != undefined && this.modelName && this.modelName.trim() !='' && this.modelName.length >= 3) {
    this.masterService.get('', '/masterManagement/getModelList?BarndId=' + this.procurement.brandId + '&search=' + this.modelName).subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {          
          this.modelList = resp.vehicleModelList;
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.statusMessage);
      }
    )
    // }
  }
  getColor(event) {    
    if (this.procurement.modelId == '' || this.procurement.modelId == undefined) {
      this.toastr.warning('Please select vehicle brand');
      return;
    } else if (this.partName == '' || this.partName == undefined) {
      this.partName = '';
    }
    this.procurement.partId = '';
    // if (event.code != 'ArrowUp' && event.code != 'ArrowDown' && this.partName && this.partName.trim() !='' && this.partName.length >= 3 ) {
    this.masterService.post('', '/Procurement/getColorList?modelId=' + this.procurement.modelId + '&search=' + this.partName).subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {          
          this.colorList = resp.colorData;
        }
      },
      error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.statusMessage);
      }
    )
    // }
  }

  selectVehicleYear() {
    // this.modelList.filter(key => {
    //   if (key.modelName == this.modelName) {
    //     this.procurement.manu_year = key.vehicleMfgYear;
    //   }
    // })
    this.getBasePrice();
  }

  getBasePrice() {
       this.modelList.filter(key => {
      if (key.modelName == this.modelName) {
        this.procurement.modelId = key.modelId;
      }
    });

     
  }
  resetModel() {
    this.procurement.modelId = '';
   // this.modelName = '';
    this.procurement.manu_year = 0;
    this.procurement.systemSuggestValue = 0;
   // this.modelList = [];
  }

  getMasterData() {
    
    this.isShowPageLoader = true;
    this.masterService.post('', '/Procurement/getProcurementDetails?dealerID=' + CommonService.getUserData().DEALER_ID
     +'&branchId='+ CommonService.getUserData().BRANCH_ID).subscribe(
      resp => {
        
          this.masterData = resp;
          this.masterData.brandData.splice(0, 1);
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      });
    }


 
  /**
   * @param {object} customerObj
   * save object
   * Returns an object.
   */
  saveCustomer(isValid) {
    
    if (isValid) {

      if (this.commnAddressPhone.CONTACT_NO == undefined || this.commnAddressPhone.CONTACT_NO == null || this.commnAddressPhone.CONTACT_NO == '') {
        this.toastr.warning("Mobile no is mandatory.");
        return false;
      }
      else {
        if( CommonService.getDealerData().COUNTRY_CODE=="IN" ||  CommonService.getDealerData().COUNTRY_CODE =="3W"){
          var firstLet=this.commnAddressPhone.CONTACT_NO.substring(0,1);
          if (this.commnAddressPhone.CONTACT_NO.length >10 || this.commnAddressPhone.CONTACT_NO.length < 10) {
            this.toastr.warning("Mobile no should be 10 digits.");
            return false;
          }
          else if((firstLet != '5' && firstLet != '6' && firstLet != '7' && firstLet != '8' &&  firstLet != '9')  && (CommonService.getDealerData().COUNTRY_CODE == "IN" || CommonService.getDealerData().COUNTRY_CODE == "3W")){
            this.toastr.warning("Mobile no should start with 5,6,7,8,9.");
            return false;
          }
        }
        else{
          var firstLet=this.commnAddressPhone.CONTACT_NO.substring(0,1);
          if (this.commnAddressPhone.CONTACT_NO.length < 9 && this.commnAddressPhone.CONTACT_NO.length > 12) {
            this.toastr.warning("Mobile no should be between 9 and 11 digits.");
            return false;
          }
          else if((firstLet != '5' && firstLet != '6' && firstLet != '7' && firstLet != '8' &&  firstLet != '9') ){
            this.toastr.warning("Mobile no should start with 5,6,7,8,9.");
            return false;
          }
        }
       
      }
      if (this.commnAddressPhone.CONTACT_NO_2 != undefined && this.commnAddressPhone.CONTACT_NO_2 != null && this.commnAddressPhone.CONTACT_NO_2 != '') {
        if( CommonService.getDealerData().COUNTRY_CODE=="IN" ||  CommonService.getDealerData().COUNTRY_CODE =="3W"){
          var firstLet=this.commnAddressPhone.CONTACT_NO_2.substring(0,1);
          if (this.commnAddressPhone.CONTACT_NO_2.length >10 || this.commnAddressPhone.CONTACT_NO_2.length < 10) {
            this.toastr.warning("Mobile no 2 should be 10 digits.");
            return false;
          }
          else if((firstLet != '5' && firstLet != '6' && firstLet != '7' && firstLet != '8' &&  firstLet != '9') ){
            this.toastr.warning("Mobile no 2 should start with 5,6,7,8,9.");
            return false;
          }
        }
        else{
          var firstLet=this.commnAddressPhone.CONTACT_NO_2.substring(0,1);
          if (this.commnAddressPhone.CONTACT_NO_2.length < 9 && this.commnAddressPhone.CONTACT_NO_2.length > 12) {
            this.toastr.warning("Mobile no 2 should be between 9 and 11 digits.");
            return false;
          }
          else if((firstLet != '5' && firstLet != '6' && firstLet != '7' && firstLet != '8' &&  firstLet != '9') ){
            this.toastr.warning("Mobile no 2 should start with 5,6,7,8,9.");
            return false;
          }
        }
       
      }


      let obj: any;
      this.customerObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
if(this.refModelName=='')
{
  this.vehicle.MODEL_ID = this.procurement.modelId;
  this.colorList.filter(key => {
    if (key.partDescription == this.partName) {
      this.vehicle.PART_ID = key.partId;
    }
  });   
}
     
      let refVehicleVal = JSON.parse(JSON.stringify(this.vehicle));
      if (refVehicleVal && refVehicleVal.SALE_DATE && refVehicleVal.SALE_DATE != '0001-01-01T00:00:00') {
        refVehicleVal.SALE_DATE = moment(refVehicleVal.SALE_DATE).format('YYYY-MM-DD');
      } else {
        refVehicleVal.SALE_DATE = '';
      }
      this.isShowPageLoader = true;
      obj = {
        Vehicle: refVehicleVal,
        Customer: this.customerObj,
        CommunicationAddress: this.communicationAddress,
        CommnAddressPhone: this.commnAddressPhone
      };
      obj.CommunicationAddress.ADDRESS_TYPE = 1;   
      obj.DealerId=CommonService.getUserData().DEALER_ID;
      obj.BranchId=CommonService.getUserData().BRANCH_ID;
      obj.UserId=CommonService.getUserData().USER_ID;
      if (this.data && this.data.type == 'uvdCustomer' ){
      this.masterService.post(obj, '/Service/SaveCustomervehicleForJobCard').subscribe(
        (resp: any) => {
      if (resp.statusCode == 200 && resp.data) {
        this.dialogRef.close(resp.data);
        this.toastr.success(this.lang.Message.JOB_CARD.SAVE_CUSTOMER);
      }  else {       
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
    }, error => {
      console.log(error);
      this.isShowPageLoader = false;
      this.toastr.error(error.error.message);
    });
      }else{    
      this.jobCardService.saveCustomer(obj).subscribe((resp: any) => { 
      if (resp.statusCode == 200 && resp.data) {
       this.dialogRef.close(resp.data);
       this.toastr.success(this.lang.Message.JOB_CARD.SAVE_CUSTOMER);
      }
      else  if (resp && resp.statusCode == 401) {
        this.loginService.logout();
      }
      else {
       this.toastr.error(resp.message);
     }
     this.isShowPageLoader = false;
   }, error => {
     this.isShowPageLoader = false;
     if (error.status == 401) {
      this.loginService.logout();
    }
     this.toastr.error(error.error.Message);
   });
   }
  }

  }
  changeAreaName() {
    
    this.areaList.filter(key => {
      // if (key.AREA_NAME == masterPartprice) {
        if (key.AREA_ID == this.communicationAddress.AREA_ID) {
        this.communicationAddress.STATE_ID = key.STATE_ID;
        this.communicationAddress.CITY = key.CITY;
        this.communicationAddress.PIN_CODE = key.PINCODE;
        this.communicationAddress.District = key.DISTRICT;
      }
    });
  }
  /**
   * @param {String} str
   * open  Search Dialog
   * Returns an object.
   */
  searchCustomer(str) {
    let data = { key: str };
    const dialogRef = this.dialog.open(JobCardSearchComponent, {
      width: '1200px',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selectedCustomer = result;
      if (result && result.obj) {
        //console.log(result.obj);
        this.mapDetails(result.obj);
      }
    });
  }

  mapDetails(data) {
     console.log(data);
    this.isDisable = true;
    this.customerObj.CUST_NAME = data.CUST_NAME;
    // masterPartprice = data.CommunicationAddress.AREA_NAME;
    this.communicationAddress.AREA_ID = data.CommunicationAddress.AREA_ID;
    // if (masterPartprice) {
      if(this.communicationAddress.AREA_ID){
      this.changeAreaName();
    }
    this.customerObj.CUST_TY_ID = data.CUST_TY_ID;
    this.communicationAddress.STATE_ID = data.CommunicationAddress.STATE_ID;
    this.customerObj.CUST_GRP_ID = data.CUST_GRP_ID;
    this.customerObj.CUSTOMER_ID = data.CUSTOMER_ID;
    this.customerObj.GENDER = data.GENDER;
    this.commnAddressPhone.PHONE_TYPE = data.Mobile.PHONE_TYPE;
    this.communicationAddress.ADDRESS_LINE_1 = data.CommunicationAddress.ADDRESS_LINE_1;
    this.communicationAddress.District = data.CommunicationAddress.District;
    this.communicationAddress.CITY = data.CommunicationAddress.CITY;
    this.communicationAddress.PIN_CODE = data.CommunicationAddress.PIN_CODE;
    this.commnAddressPhone.CONTACT_NO = data.Mobile.MOBILE_NO;
    this.commnAddressPhone.CONTACT_NO_2 = data.Mobile.CONTACT_NO_2;
  }
onmobile()
{
  this.issaledtDisable  = false;
  let params = new HttpParams();
  params = params.set('MoblieNo', this.commnAddressPhone.CONTACT_NO )
  .set('DealerId', CommonService.getUserData().DEALER_ID)
  .set('BranchId', CommonService.getUserData().BRANCH_ID)
  .set('UserId', CommonService.getUserData().USER_ID)
      this.jobCardService.getCentralMobiledetails(params).subscribe(
    (resp: any) => {
      // start changes add by sangeetha on 17-05-2019
      if (resp.data.CUST_NAME !="")
      {
        
        if ( resp.data.IS_CHANGE_ALLOW == false && resp.data.SALE_DATE)
        {
        this.issaledtDisable  = true;
        }
        else
        {
          this.issaledtDisable  = false;
        }
        if (resp.data.SALE_DATE) {
          this.vehicle.SALE_DATE = CommonService.setDateTime(resp.data.SALE_DATE);
        }
        
      this.customerObj.CUST_NAME = resp.data.CUST_NAME;
      this.vehicle.FRAME_NO = resp.data.FRAME_NO;
     
      this.getFrameDetails(false);
        
      this.commnAddressPhone.CONTACT_NO_2 = resp.data.CONTACT_NO_2;
      this.communicationAddress.ADDRESS_LINE_1 = resp.data.ADDRESS_LINE_1;
      // this.customerObj.AREA_NAME =this.areaList[0].AREA_NAME ;
      this.communicationAddress.AREA_ID = this.areaList && this.areaList.length> 0 ? this.areaList[0].AREA_ID:0;
      // if (this.customerObj.AREA_NAME) {
        if( this.communicationAddress.AREA_ID){
        this.changeAreaName();
      }
    }
     // end changes add by sangeetha on 17-05-2019
    },
    error => {
      this.toastr.error(error.error.Message);
      if (error.status == 401) {
        this.loginService.logout();
      }
    }
  )
}
onframe()
{
  this.issaledtDisable  = false;
  let params = new HttpParams();
  params = params.set('Frameno', this.vehicle.FRAME_NO )
  .set('DealerId', CommonService.getUserData().DEALER_ID)
  .set('BranchId', CommonService.getUserData().BRANCH_ID)
  .set('UserId', CommonService.getUserData().USER_ID)
      this.jobCardService.getCentralFramedetails(params).subscribe(
    (resp: any) => {
      // start changes add by sangeetha on 17-05-2019
      if (resp.data.SALE_DATE)
      {
        
        if ( resp.data.IS_CHANGE_ALLOW == false )
        {
        this.issaledtDisable  = true;
        }
        else
        {
          this.issaledtDisable  = false;
        }        
      this.vehicle.SALE_DATE = CommonService.setDateTime(resp.data.SALE_DATE);
      }
      else
      {
        this.issaledtDisable  = false;
      }
      
      if(resp.data.CUST_NAME)
      {
      this.customerObj.CUST_NAME =  resp.data.CUST_NAME;
      this.commnAddressPhone.CONTACT_NO =  resp.data.CONTACT_NO;    
      this.commnAddressPhone.CONTACT_NO_2 =  resp.data.CONTACT_NO_2;
      this.communicationAddress.ADDRESS_LINE_1 = resp.data.ADDRESS_LINE_1;
      // this.customerObj.AREA_NAME =this.areaList[0].AREA_NAME ;      
      // if (masterPartprice) {
        this.communicationAddress.AREA_ID = this.areaList && this.areaList.length> 0? this.areaList[0].AREA_ID:0;
          if( this.communicationAddress.AREA_ID){
        this.changeAreaName();
      }
    }    
     // end changes add by sangeetha on 17-05-2019
    },
    error => {
      this.toastr.error(error.error.Message);
      if (error.status == 401) {
        this.loginService.logout();
      }
    }
  )
}
/**
   * @param {boolean} isRefresh
   * open  Search Dialog
   * Returns an object.
   */

  getFrameDetails(isRefresh) {
    this.isotherTVS = true;
    if (!this.vehicle.FRAME_NO)
    {
      this.vehicle.MODEL_ID = "";
      this.vehicle.PART_ID = "";
      this.vehicle.ENGINE_NO = "";
      this.vehicle.SALE_DATE = null;
      this.refPartName = "";
      this.refModelName = "";
    }
    if (this.vehicle.FRAME_NO && this.refFrameNo != this.vehicle.FRAME_NO) {
      this.refFrameNo = this.vehicle.FRAME_NO;
      let params = new HttpParams();
      params = params.set('FrameNo', this.vehicle.FRAME_NO)
      .set('DealerID', CommonService.getUserData().DEALER_ID)
      // .set('CountryCode', CommonService.getDealerData().COUNTRY_CODE)
      .set('BranchID', CommonService.getUserData().BRANCH_ID)
      .set('UserID', CommonService.getUserData().USER_ID)
      // .set('RegNo', this.vehicle.REG_NO ? this.vehicle.REG_NO : '')
      this.isShowPageLoader = true;
      this.jobCardService.getVehicleFrameDetails(params).subscribe((resp: any) => {
        
        if (resp && resp.data && resp.statusCode === 200) {
          // this.vehicle.FRAME_NO = resp.data.FRAME_NO;
          // this.vehicle.REG_NO = resp.data.REG_NO;
          this.vehicle.MODEL_ID = resp.data.MODEL_ID;
          this.vehicle.PART_ID = resp.data.PART_ID;
          // this.vehicle.PROD_MON = resp.data.VehicleSearch.JCVehicleDO.PROD_MON;
          // this.vehicle.PROD_YR = resp.data.VehicleSearch.JCVehicleDO.PROD_YR;
          // this.vehicle.INS_COMP_ID = resp.data.VehicleSearch.JCVehicleDO.INS_COMP_ID;
          this.vehicle.ROW_STATE = 'Created';
          this.vehicle.ENGINE_NO = resp.data.ENGINE_NO;
          // this.vehicle.KEY_NO = resp.data.VehicleSearch.JCVehicleDO.KEY_NO;
          if (resp.data.SALE_DATE) {
            this.vehicle.SALE_DATE = CommonService.setDateTime(resp.data.SALE_DATE);
          }
          // this.vehicle.Service_Booklet_No = resp.data.VehicleSearch.JCVehicleDO.Service_Booklet_No;
          this.refPartName = resp.data.PART_DESC;
          this.refModelName = resp.data.MODEL_DESC;
          // start changes add by sangeetha on 17-05-2019
          if (isRefresh == true)
          {
          this.onframe();
          }
          this.resetModel();
          this.modelName = "";
          this.partName = "";
        } 
        else if (resp && resp.statusCode === 500 && this.data.type != 'uvdCustomer') {         
          this.toastr.error(resp.message);
        }         
        else if (this.data.type == 'uvdCustomer'){
          this.isotherTVS = false;       
          this.refPartName = "";
      this.refModelName = "";
        }
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        if (error.status == 401) {
          this.loginService.logout();
        }
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      });
    } else {

    }
  }
  allowAlphaNumeric(evt) {
    evt = evt || window.event;
    var charCode = evt.which || evt.keyCode;
    var charStr = String.fromCharCode(charCode);
    if (/[a-z0-9]/i.test(charStr)) {
      return true;
    }
    else {
      return false;
    }
  }
}

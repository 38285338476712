export class dealerDetails {
    countryId:any;
    dealerId:any;
    dealerType:any;
    dealershipName:any;
    tvsDealerId:any;
    dealerClass:any;
    businessType:any;
    dateOfCreation:any;
    vehicleSaleType:any;
    isActive:any;
    fscClaimAPS:any;
    mapInvoice:any;
    salesGrnAMD:any;
    line1:any;
    line2:any;
    line3:any;
    city:any;
    state:any;
    territory:any;
    phoneNo:any;
    fax:any;
    emailId:any;
    pinCode:any;
    cityCode:any;
    area:any;
    fscClaim:any;
    warrantyClaim:any;
    sparePO:any;
    spareClaim:any;
    spareTVS:any;
    salesGrn:any;
    spareGrnAD:any;
    salesGrnAD:any;
    vcLimit:any;
    scLimit:any;
    vcLimitFI:any;
    scLimitFI:any;
    yearStart:any;
    yearEnd:any;
    panNo:any;
    municipalNo:any;
    contactPerson:any;
    internetBand:any;
    field1:any;
    field2:any;
    field3:any;
    mainDealerCode:any;
    mainDealerName:any;
    ADcustomerID:any;
    vendorName:any;
    vendorType:any;
    GSTINNO:any;
}
import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../api-services/common.service'
@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss']
})
export class ListCardComponent implements OnInit {
  public lang: any;
  constructor() { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

}

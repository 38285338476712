import { Directive,ElementRef,OnChanges,HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyAlphaNumeric]'
})
export class OnlyAlphaNumericDirective {

  constructor(private el:ElementRef) {
    //el.nativeElement.style.color = "red";
   // el.nativeElement.style.fontWeight = "bolder";
   // el.nativeElement.style.border = "2px double Red";

   }
   @HostListener("keydown",['$event']) ngOnChanges(event)
   {
     //
     //let elementValue=this.el.nativeElement.value;
    // console.log(elementValue);
    // var letters = /^[0-9a-zA-Z]+$/; 
    // if(letters).te
   // event.keyCode < 48 || event.keyCode > 57)
      //if (/^[0-9a-zA-Z]+$/.test(elementValue)) {
       // = ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || keyCode == 32 || (keyCode >= 97 && keyCode <= 122) || (specialKeys.indexOf(e.keyCode) != -1 && e.charCode != e.keyCode));
     if(((event.keyCode >=48 && event.keyCode <=57) && event.keyCode !=16)|| 
        (event.keyCode >=65 && event.keyCode <=90)
        || (event.keyCode >=97 && event.keyCode <=122)
        || event.keyCode ==8 || event.keyCode==9)
        {
              return true;
        } else {
              event.preventDefault();
              return false;
            }
    
   }

  

}

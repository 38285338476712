export enum RowState {
    Created,
    Modified,
    Deleted,
    None,
    Cancel,
    View
}

export enum LanguageForCommuincation
    {
        TAMIL = 1,
        ENGLISH = 2,
        HINDI = 3,
        BENGALI = 4,
        GUJARATHI = 5,
        KANNADA=6,
        PUNJABI=7,
        TELUGU=8,
        MALAYALAM=9,
        BAHASA_INDONESIA=10
    }
export enum CustomerType
{
        PGM = 1,
        DirectASC = 2,
        IndirectASC = 3,
        MultiBrandOutlets = 4,
        PartsRetailer = 5,
        PartsWholesaler = 6,
        RuralOutlet = 7,
        Individual = 8,
        CSD = 9,
        Broker = 10,
        Institution = 11,
        ExchangeBroker = 12,
        Dealer = 13
}

export enum AddressType
{
    Communication=1,
    Office=2,
    Residence=3,
    Registration=4
}

export enum PhoneTypes
    {
        Communication = 1,
        Office1 = 2,
        Office2 = 3,
        Residence = 4,
        Registration = 5,
        Cellphone = 6,
        Fax = 7
    }

export enum BusinessType
{
    MainDealer_IN = 1,
    APS_IN = 2,
    DirectAD_IN = 3,
    IndirectAD_IN = 4,
    MainDealer_3W = 5,
    Dealer_3W = 6,
    MainDealer_ID = 7,
    Dealer_ID = 8,
    APS_ID = 9,
    MainDealer_BD = 10,
    APS_3W = 11,
    DirectAD_3W = 12,
    IndirectAD_LK = 13,
    IndirectAD_LK_3W = 14,
    DirectIndirectAD_IN = 15,
    MainDeale_PH = 16,
    MainDealer_ID3W = 22,
    MainDealer_LA = 19,
    MainDealer_CB = 25,
    Dealer_ID3W = 31,
    Dealer_PH = 17
}
export enum Manufacturer
{
    TVS = 1,
    Others = 2,
    BajajAuto = 3,
    HeroHonda = 4,
    Honda = 5,
    Kinetic = 6,
    LML = 7,
    Suzuki = 8,
    HeroMotor = 9,
    HeroMajestic = 10,
    Yamaha = 11,
    Escort = 12,
    Eicher = 13,
    OtherOE = 14,

}
export enum Gender
{
    Male = 1,
    Female = 2
}

export enum ItemType
{
        Vehicle = 1,
        Registration_LTRT = 2,
        Registration_Insurance = 3,
        Registration_Incidental = 4,
        Registration_Service = 5,
        Accessory = 6,
        Spares = 7,
        Consumables = 8,
        Lubricants = 9,
        Labour = 10,
        Outwork = 11,
        Waterwash = 12,
        AMC = 13,
        Registration_GreenCard = 14,
        Registration_SmartCard = 15,
        Registration_Others = 16,
        WelcomeKIT = 17
}

export enum ItemTaxCat
    {
        Taxable = 1,
        NoTax = 2,
        ServiceTax = 3,
        TaxableVehicle = 4,
        TaxableFastener = 5,
        TaxableLubricant = 6,
        TaxableAccessory = 7,
        GstExempt=8
    }

export enum PaymentMode
{
        Cash = 1,
        CreditCard = 2,
        DD = 3,
        Cheque = 4,
        Transfer = 5,
        NEFT_RTGS = 6,
        BHARATHQR = 7,
        GOOGLEPAY = 8,
        PHONEPAY = 9,
        PAYTM = 10

}
export enum InvoiceStatus
{
  Open=1,
  Close=2,
  Cancel=3

}
export enum InvoiceType
{
        VehicleInvoice = 1,
        SparesInvoice = 2,
        REGISTRATIONINVOICE = 3,
        ACCESSORYINVOICE = 4,
        INSURANCEINVOICE = 5,
        JobCardInvioice = 6,
        SERVICEWATERWASHINVOICE = 7,
        SERVICEOUTWORKINVOICE = 8,
        ServiceSparesInvoice = 9,
        ServiceAccessoryInvoice = 10,
        SparesAccessoryInvoice = 11,
        TAXINVOICE = 12,
        TradeInvoice = 13,
        AmcInvoice = 14,
        VehiclePurchaseReturn = 15,
        CRMWelcomeKit = 16

}
export enum VoucherTypes
{
    Booking_Advance_Refund = 201,
        Vehicle_Sales_Return_Payment = 204,
        Spares_Sales_Return_Payment = 206,
        Spares_GRN_Payment = 212,
        Miscellaneous_Payment = 217,
        Booking_Advance = 101,
        JobCardAdvance = 102,
        JobcardRefund = 203,
        Vehicle_Sale_Invoice_Receipt = 104,
        Vehicle_Registration_Invoice_Receipt = 105,
        Vehicle_Insurance_Invoice_Receipt = 106,
        Vehicle_Accessories_Invoice_Receipt = 107,
        Spares_Sale_Invoice_Receipt = 113,
        Service_Spares_Invoice_Receipt = 111,
        Miscellaneous_Receipt = 119,
        Booking_General_Refund = 202,
        Self_Managed_HP = 802,
        AMCReceipt = 103,
        BrokerSaleReceipt = 115,
        VendorAdvancePayment = 213,
        InsurancePayment = 214,
        HPRefund = 215,
        OnAccountPayment = 216,
        CommisionPayment = 209,
        VehicleCommissionPayment = 221,
        SparesCommissionPayment = 222,
        BookingDiscountPayment = 218,
        RegistrationPayment = 219,
        DiscountPayment = 220,
        OutworkPayment = 210,
        RegistrationReceipt = 120,
        ReceiptAdjustmentPayment = 208,
        ASC_Coupon_Claim_Payment = 223,
        ASCWarrantyClaimPayment = 224,
        // Voucher Type Added By Gopinath for OnAccount Reconciliation Process
        OnAccountReceipt = 118,
        HPCompanyReceipt = 116,
        ASCWarrantyClaim = 402,

        // Code Added by Gopinath for 7.3 release

        // COde Added By Gopinath for 8.08 release
        
        DealerCouponClaim =501,
        DealerWarrantyClaim=502,
        DealerTransitClaim=503,

        // COde Added By Gopinath for 8.08 release

        Vehicle_Accessories_Return_Payment = 205,
        Vendor_Bill_Payment = 211,
        WelcomeKitReceipt = 121,
        UVD_Booking_Advance=903,
        UVD_Invoice_Receipt=904
}
// Code Added By Anil For Multi Vehicle Invoice
export enum EnquiryType
{
   Single=1,
   Bulk=2

}
export enum BillType
{
    Cash=1,
    Credit=2
}

// Enum added By Anil 02-08-2021
export enum EnquiryDocumentStatus
{
   
    Open=1,
    Closed=2,
    Booked=3,
    Mapped=4,
    Expired=5
}
export enum EnquirySaleMode
{
    HP=1,
    Exchange=2,
    HPandExchange=3,
    Cash=4,
    SelfHP=5
    
}
export enum SaleMode
{
        Bulk = 1,
        Counter = 2,
        FOC = 3,
        JobCardIssue = 4,
        Warranty = 5
}
export enum RegisterationType
{
    Normal = 1,
    Temporary = 2,
    Special = 3
}
export enum Bookingstatus
{

    Open=1,
    Closed=2,
    Cancelled=3,
    Allotment=4,
    Invoiced=5
}
//



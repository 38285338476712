import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';

@Component({
  selector: 'app-quick-detail',
  templateUrl: './quick-detail.component.html',
  styleUrls: ['./quick-detail.component.scss']
})
export class QuickDetailComponent implements OnInit {
  @Input() type;
  @Input() rawDetails;
  @Input() totalAmt;
  lang: any;
  constructor() { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

}

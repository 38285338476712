export class DealerOnBoard {
    dealer_app_id: any
    location: Location
    image_url: any
    businees_automobile: BusineesAutomobile
    business_others: BusinessOther[]
    brief_background_key_promoter: BriefBackgroundKeyPromoter
    key_promoter_details: KeyPromoterDetails
    details_proposed_pov_dealership: DetailsProposedPovDealership
    details_of_bankers: DetailsOfBanker[]
    buisness_plan_povd: BuisnessPlanPovd
    manpower: Manpower
    facility: Facility
    proposed_captial_structure_pov_dealership: ProposedCaptialStructurePovDealership
    initial_investments: InitialInvestments
    income_expense_statement: IncomeExpenseStatement
    images: Images
    agreement: Agreement
    user_id: any
    passport_list:any[]
  }
  
  export class Location {
    state: any
    area: any
    district: any
    town: any
    lat: any
    lon: any
  }
  
  export class BusineesAutomobile {
    types_of_vechile: TypesOfVechile
    manufacturer_details: ManufacturerDetail[]
  }
  
  export class TypesOfVechile {
    two_wheeler: any
    three_wheeler: any
    passenger_cars: any
    commercial_vehicles: any
    tractors: any
    spare_parts: any
  }
  
  export class ManufacturerDetail {
    type_of_outlet: any
    manufacturer_name: any
    started_at: any
    current_sales: any
    service_in_flow: any
    parts_sales: any
    total_turnover: any
    no_of_employees: any
    constitution: any
  }
  
  export class BusinessOther {
    type_of_business: any
    name_of_organisation: any
    started_at: any
    total_turnover: any
    no_of_employees: any
    constitution: any
  }
  
  export class BriefBackgroundKeyPromoter {
    family: any
    business: any
  }
  
  export class KeyPromoterDetails {
    name: any
    off_address: any
    off_pincode: any
    off_tel_no: any
    res_address: any
    res_pincode: any
    res_tel_no: any
    mobile: any
    email: any
    tin_no: any
    pan_no: any
    cst_no: any
    // isAppliedforGST: boolean
    // isAppliedforTIN: boolean
    // isAppliedforPAN: boolean
    dob: any
  }
  
  export class DetailsProposedPovDealership {
    name: any
    off_address: any
    off_pincode: any
    off_tel_no: any
    tin_no: any
    pan_no: any
    cst_no: any
    isAppliedforGST: boolean
    isAppliedforTIN: boolean
    isAppliedforPAN: boolean
    types_of_constitution: TypesOfConstitution
    details_of_management: DetailsOfManagement[]
  }
  
  export class TypesOfConstitution {
    proprietorship: any
    partnership: any
    private_limited_co: any
    limited_co: any
  }
  
  export class DetailsOfManagement {
    name: any
    percent_of_ownership: any
    releationship_with_key_promoter: any
  }
  
  export class DetailsOfBanker {
    name_of_banker: any
    address: any
    telephone_no: any
    account_no: any
    transaction_since: any
    cc_limit: any
    cd_limit: any
  }
  
  export class BuisnessPlanPovd {
    first_year: FirstYear
    second_year: SecondYear
    third_year: ThirdYear
    moped: any
    motor_cycle: any
    scooterrette: any
    scooter: any
    two_wheeler: any
    app_value_per_vehicle: any
  }
  
  export class FirstYear {
    moped: Moped
    motor_cycle: MotorCycle
    scooter: Scooter
    total_two_wheelers: TotalTwoWheelers
    spare_parts: SpareParts
    service_inflow: ServiceInflow
  }
  
  export class Moped {
    month: any
    yearly: any
    value: any
  }
  
  export class MotorCycle {
    month: any
    yearly: any
    value: any
  }
  
  export class Scooter {
    month: any
    yearly: any
    value: any
  }
  
  export class TotalTwoWheelers {
    month: any
    yearly: any
    value: any
  }
  
  export class SpareParts {
    month: any
    yearly: any
    value: any
  }
  
  export class ServiceInflow {
    month: any
    yearly: any
    value: any
  }
  
  export class SecondYear {
    moped: Moped2
    motor_cycle: MotorCycle2
    scooter: Scooter2
    total_two_wheelers: TotalTwoWheelers2
    spare_parts: SpareParts2
    service_inflow: ServiceInflow2
  }
  
  export class Moped2 {
    month: any
    yearly: any
    value: any
  }
  
  export class MotorCycle2 {
    month: any
    yearly: any
    value: any
  }
  
  export class Scooter2 {
    month: any
    yearly: any
    value: any
  }
  
  export class TotalTwoWheelers2 {
    month: any
    yearly: any
    value: any
  }
  
  export class SpareParts2 {
    month: any
    yearly: any
    value: any
  }
  
  export class ServiceInflow2 {
    month: any
    yearly: any
    value: any
  }
  
  export class ThirdYear {
    moped: Moped3
    motor_cycle: MotorCycle3
    scooter: Scooter3
    total_two_wheelers: TotalTwoWheelers3
    spare_parts: SpareParts3
    service_inflow: ServiceInflow3
  }
  
  export class Moped3 {
    month: any
    yearly: any
    value: any
  }
  
  export class MotorCycle3 {
    month: any
    yearly: any
    value: any
  }
  
  export class Scooter3 {
    month: any
    yearly: any
    value: any
  }
  
  export class TotalTwoWheelers3 {
    month: any
    yearly: any
    value: any
  }
  
  export class SpareParts3 {
    month: any
    yearly: any
    value: any
  }
  
  export class ServiceInflow3 {
    month: any
    yearly: any
    value: any
  }
  
  export class Manpower {
    sales: Sales
    service: Service
    spare_parts: SpareParts4
  }
  
  export class Sales {
    indoor_sales_positions: IndoorSalesPositions
    outdoor_sales_positions: OutdoorSalesPositions
  }
  
  export class IndoorSalesPositions {
    receptionists: any
    showroom_sales_executive: any
    delivery_executives: any
    rto_related_work: any
    crm_oms_executive: any
  }
  
  export class OutdoorSalesPositions {
    enqyiry_follow_up: any
    direct_marketing: any
    field_activation: any
    institutonal_sales: any
    secondary_network_manager: any
  }
  
  export class Service {
    category: Category
  }
  
  export class Category {
    works_manger: any
    service_advisor: any
    floor_supervisor: any
    customer_complaint_executive: any
    mechanics: any
    helper: any
    parts_manager: any
    parts_in_charge: any
    assistants: any
  }
  
  export class SpareParts4 {
    category: Category2
  }
  
  export class Category2 {
    works_manger: any
    service_advisor: any
    floor_supervisor: any
    customer_complaint_executive: any
    mechanics: any
    helper: any
    parts_manager: any
    parts_in_charge: any
    assistants: any
  }
  
  export class Facility {
    frontage: Frontage
    showroom_office: ShowroomOffice
    service_shop: ServiceShop
    parts_counter: PartsCounter
    warehouse: Warehouse
    vechile_selection: VechileSelection
    total_space: TotalSpace
  }
  
  export class Frontage {
    min_norms_in_ft_sqft: any
    proposed_space: any
    own: any
    rented: any
    leased: any
    rent_in_amount: any
    lease_in_amount: any
    remarks: any
  }
  
  export class ShowroomOffice {
    min_norms_in_ft_sqft: any
    proposed_space: any
    own: any
    rented: any
    leased: any
    rent_in_amount: any
    lease_in_amount: any
    remarks: any
  }
  
  export class ServiceShop {
    min_norms_in_ft_sqft: any
    proposed_space: any
    own: any
    rented: any
    leased: any
    rent_in_amount: any
    lease_in_amount: any
    remarks: any
  }
  
  export class PartsCounter {
    min_norms_in_ft_sqft: any
    proposed_space: any
    own: any
    rented: any
    leased: any
    rent_in_amount: any
    lease_in_amount: any
    remarks: any
  }
  
  export class Warehouse {
    min_norms_in_ft_sqft: any
    proposed_space: any
    own: any
    rented: any
    leased: any
    rent_in_amount: any
    lease_in_amount: any
    remarks: any
  }
  
  export class VechileSelection {
    min_norms_in_ft_sqft: any
    proposed_space: any
    own: any
    rented: any
    leased: any
    rent_in_amount: any
    lease_in_amount: any
    remarks: any
  }
  
  export class TotalSpace {
    min_norms_in_ft_sqft: any
    proposed_space: any
    own: any
    rented: any
    leased: any
    rent_in_amount: any
    lease_in_amount: any
    remarks: any
  }
  
  export class ProposedCaptialStructurePovDealership {
    showroom: Showroom
    workshop_parts: WorkshopParts
    retail_identity: RetailIdentity
    working_capital: WorkingCapital
    dms: Dms
    others: Others
  }
  
  export class Showroom {
    land: Land
    building: Building
  }
  
  export class Land {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class Building {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class WorkshopParts {
    land: Land2
    building: Building2
    tools_equipment: ToolsEquipment
  }
  
  export class Land2 {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class Building2 {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class ToolsEquipment {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class RetailIdentity {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class WorkingCapital {
    vechiles: Vechiles
    spare_accessories: SpareAccessories
  }
  
  export class Vechiles {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class SpareAccessories {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class Dms {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class Others {
    own_fund: any
    bank_finance: any
    friend_relative: any
    total: any
  }
  
  export class InitialInvestments {
    infrastructure: Infrastructure
    working_capital: WorkingCapital2
    self_declaration: SelfDeclaration
  }

  export class SelfDeclaration{
    date: any
    signature: any
  }
  
  export class Infrastructure {
    rental_advance: any
    building_modification: any
    retail_identify_element: any
    workshop_tools_equipment: any
    computers: any
    dms_software: any
    others: any
  }
  
  export class WorkingCapital2 {
    vehicles: any
    spares_oil: any
    accessories: any
    provision_for_receiables: any
    others: any
  }
  
  export class IncomeExpenseStatement {
    income: Income
    expences: Expences
  }
  
  export class Income {
    income_from_sales: IncomeFromSales
    income_from_service: IncomeFromService
    income_from_parts_oil: IncomeFromPartsOil
  }
  
  export class IncomeFromSales {
    vehicle_margin: VehicleMargin
    margin_from_accessories: MarginFromAccessories
    finance_margin: FinanceMargin
    insurance_margin: InsuranceMargin
    service_charges_for_registration: ServiceChargesForRegistration
  }
  
  export class VehicleMargin {
    two_wheeler: TwoWheeler
    moped: Moped4
    motor_cycles: MotorCycles
    scooterette: Scooterette
    scooter: Scooter4
  }
  export class TwoWheeler {
    unit: any
    margin: any
    gross_margin: any
  }
  export class Moped4 {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class MotorCycles {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class Scooterette {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class Scooter4 {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class MarginFromAccessories {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class FinanceMargin {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class InsuranceMargin {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class ServiceChargesForRegistration {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class IncomeFromService {
    paid_service_labour: PaidServiceLabour
    fsr_reimbursements: FsrReimbursements
    income_from_outside_labour: IncomeFromOutsideLabour
    income_from_scrap_sales: IncomeFromScrapSales
  }
  
  export class PaidServiceLabour {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class FsrReimbursements {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class IncomeFromOutsideLabour {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class IncomeFromScrapSales {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class IncomeFromPartsOil {
    income_from_parts: IncomeFromParts
    income_from_oil: IncomeFromOil
  }
  
  export class IncomeFromParts {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class IncomeFromOil {
    unit: any
    margin: any
    gross_margin: any
  }
  
  export class Expences {
    rent: Rent
    establishment_exps: EstablishmentExps
    salary_incentives: any
    promotional_exps: PromotionalExps
    secondary_channel_commissions: any
    interest: any
  }
  
  export class Rent {
    showroom: any
    work_shop_spares: any
  }
  
  export class EstablishmentExps {
    electricity: any
    telephone: any
    water: any
    workshop_fuel_consumables: any
    official_conveyance: any
    admin_stationery_exps: any
    dms_maintenance_charges: any
    misc: any
  }
  
  export class PromotionalExps {
    sales_promotional_exps: any
    service_promotional_exps: any
  }
  
  export class Images {
    proprietorship: any
    partnership: any
    private_limited_company: any[]
    public_limited_company: any[]
    photographs: Photographs
    competitors: any[]
    blueprint_showroom: any
    blueprint_service_shop: any
    blueprint_service_shop_list:any[]
    blueprint_showroom_list: any[]
    proprietorship_list: any[]
    partnership_list: any[]
    aadhar_photo: any[]
    pan_photo: any[]
    bank_reference_letters: any[]
    gst_details: any[]
    form16: any[]
    affidavit: any[]
    gst_documents: any[]   
  }
  
  export class Photographs {
    frontage: any
    right_side: any
    left_side: any
    opposite_side: any
    exterior_service_shop: any
    interior_service_shop: any
    exterior_spare_parts: any
  }
  export class AadharPhoto {
    type: string
    doc_url: string
  }
  
  export class PanPhoto {
    type: string
    doc_url: string
  }
  
  export class BankReferenceLetter {
    type: string
    doc_url: string
  }
  
  export class GstDetail {
    type: string
    doc_url: string
  }
  
  export class Form16 {
    type: string
    doc_url: string
  }
  
  export class Affidavit {
    type: string
    doc_url: string
  }
  
  export class GstDocument {
    type: string
    doc_url: string
  }
  export class Agreement {
    agreed_person:any
    agrement_date:any
    place:any
    agreementDealers: AgreementDealers[]
  }
  export class AgreementDealers {
    name:any
    signature:any
  }
  
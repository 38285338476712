/**
 * Created BY HMSPL 
 * Job Card Invoice Service Class
 * Last Modified By Balaji on 22/09/2018
 * Last Modified Description : Created services
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class JobCardInvoiceService {
    constructor(private httpClient: HttpClient) {
    }
    /**
     * Get Job Card invoice List
     * @param {Object} params
     * return Object
     */
    getJobCardInvoiceList(params) {
        const resData = RequestService.postApiURLReq('/JobCard/GetJobcardInvoiceList');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }
    /**
     * Get Job Card List
     * @param {Object} params
     * return Object
     */
    getAllJobCardList(params) {
        const resData = RequestService.getApiURLReq('/JobCard/SearchJobDetailsForInvoice');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get Job Card details based on jobcard id
     * @param {Object} params
     * return Object
     */
    getJobCardDetailsByJobCardId(params) {
        const resData = RequestService.getApiURLReq('/JobCard/GetAllJobCardDetailsByIdForInvoice');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get Job Card invoice details based on invoice id
     * @param {Object} params
     * return Object
     */
    getJobCardInvoiceDetailsByJobCardInvoiceId(params) {
        const resData = RequestService.getApiURLReq('/JobCard/LoadJobcardInvoicedDetailsForViewMode');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Print Job card Invoice
     * @param {Object} params
     * return Object
     */
    printJobCardInvoice(params) {
        const resData = RequestService.getApiURLReq('/JobCard/PrintJCInvoices');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Cancel Job card Invoice
     * @param {Object} params
     * return Object
     */
    cancelJobCardInvoice(params) {
        const resData = RequestService.postApiURLReq('/JobCard/JobcardInvoicecancel');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }
    /**
     * Save Job Card invoice
     * @param {Object} request
     * return Object
     */
    calculateTaxAmount(request) {
        const resData = RequestService.postReq('CalculateInvoiceTax');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    /**
     * Calculate spare discount
     * @param {Object} request
     * return Object
     */
    calculateSpareDiscount(request) {
        const resData = RequestService.postApiURLReq('/JobCard/CalculateSpareDiscount');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    /**
     * Save Job Card invoice
     * @param {Object} request
     * return Object
     */
    saveJobCardInvoice(request) {
        const resData = RequestService.postApiURLReq('/JobCard/SaveJobcardInvoice');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    /**
     * View  Job Card invoice points
     * @param {Object} params
     * return Object
     */
    viewPoints(params) {
        const resData = RequestService.getApiURLReq('/JobCard/ViewCRMRedemption');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Burn Job Card invoice points
     * @param {Object} request
     * return Object
     */
    burnPoints(request) {
        const resData = RequestService.postApiURLReq('/JobCard/UpdateBurnPoints');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }

    /**
     * Calculate Tax Amount
     * @param {Object} request
     * return Object
     */
    calculateTaxAmountNew(request) {
        const resData = RequestService.postApiURLReq('/JobCard/CalculateTaxDetailsNew');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    //added by ankit anjan
    saveJPerformaInvoice(request) {
        const resData = RequestService.postApiURLReq('/JobCard/SavePerformaInvoice');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }

    //added by ankit anjan
    getPerformInvoiceList(params) {
        const resData = RequestService.postApiURLReq('/JobCard/GetPerformInvoiceList');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }
}

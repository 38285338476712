import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import { WarehouseMaster } from 'src/app/api-services/dto/warehouseMaster';
@Component({
  selector: 'app-create-warehouse-master',
  templateUrl: './create-warehouse-master.component.html',
  styleUrls: ['./create-warehouse-master.component.scss']
})
export class CreateWarehouseMasterComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public location: any;
  public rackDesc: any;
  public defaultRack: boolean = false;
  public wareHouse = new WarehouseMaster();
  public isViewMode : boolean = false;
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateWarehouseMasterComponent>) { }

  ngOnInit() {
    if (this.data.type == "MODIFY") {
      this.isViewMode = false;
      this.wareHouse = this.data.value;
      this.wareHouse.dateImplemented = CommonService.setDateTime(this.data.value.dateImplemented);
    }
    if (this.data.type == "VIEW") {
      this.isViewMode = true;
      this.wareHouse = this.data.value;
      this.wareHouse.dateImplemented = CommonService.setDateTime(this.data.value.dateImplemented);
    }
    this.wareHouse.dateImplemented = new Date();
    this.getMasterData();
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  getMasterData() {
    this.isShowPageLoader = true;
    this.masterService.get('', '/warehouse/getMaster').subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.masterData = resp;
            if(this.data.type == "MODIFY" || this.data.type == "VIEW"){
              this.wareHouse.warehouseId = this.data.value.warehouseId;
            }
            else{
              this.wareHouse.warehouseId = resp.warehouseId;
            }
            this.wareHouse.accFinyearStartMonth = Number(resp.finYearStart);
            this.wareHouse.accFinyearEndMonth = Number(resp.finYearEnd);
          }
          if (resp.statusCode == 204) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        })
  }

  saveRackData() {
    this.isShowPageLoader = true;
    let reqObj = {
      "warehouse" : {}
    };
    if(this.wareHouse.warehouseName == undefined || this.wareHouse.warehouseName == null || this.wareHouse.warehouseName == ""){
      this.toastr.info("WarehouseName is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.plantCode == undefined || this.wareHouse.plantCode == null || this.wareHouse.plantCode == ""){
      this.toastr.info("Plant Code is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.emailId == undefined || this.wareHouse.emailId == null || this.wareHouse.emailId == ""){
      this.toastr.info("Email is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.PhoneNo == undefined || this.wareHouse.PhoneNo == null || this.wareHouse.PhoneNo == ""){
      this.toastr.info("Phone No is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.dateImplemented == undefined || this.wareHouse.dateImplemented == null || this.wareHouse.dateImplemented == ""){
      this.toastr.info("Date Implemented is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.stateId == undefined || this.wareHouse.stateId == null || this.wareHouse.stateId == ""){
      this.toastr.info("State Name is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.countryCode == undefined || this.wareHouse.countryCode == null || this.wareHouse.countryCode == ""){
      this.toastr.info("Country is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.addressLine1 == undefined || this.wareHouse.addressLine1 == null || this.wareHouse.addressLine1 == ""){
      this.toastr.info("Address1 is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.addressLine2 == undefined || this.wareHouse.addressLine2 == null || this.wareHouse.addressLine2 == ""){
      this.toastr.info("Address2 is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.addressLine3 == undefined || this.wareHouse.addressLine3 == null || this.wareHouse.addressLine3 == ""){
      this.toastr.info("Address3 is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.panNo == undefined || this.wareHouse.panNo == null || this.wareHouse.panNo == ""){
      this.toastr.info("Pan No is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    if(this.wareHouse.gstInNo == undefined || this.wareHouse.gstInNo == null || this.wareHouse.gstInNo == ""){
      this.toastr.info("GSTIN NO is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    this.wareHouse.dateImplemented = CommonService.getReqDateFormat(this.wareHouse.dateImplemented);
    this.wareHouse.accFinyearEndMonth = Number(this.wareHouse.accFinyearEndMonth);
    this.wareHouse.accFinyearStartMonth = Number(this.wareHouse.accFinyearStartMonth);
    reqObj.warehouse = this.wareHouse;
    this.masterService.post(reqObj, '/warehouse/save').subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.dialogRef.close();
            this.toastr.success(resp.statusMessage)
          }
          if(resp.statusCode == 400){
            this.dialogRef.close();
            this.toastr.info(resp.statusMessage)
          }
          if (resp.statusCode == 204) {
            this.dialogRef.close();
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
          this.dialogRef.close();
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        })
  }

  closePopup() {
    this.dialogRef.close();
  }
}
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { SavePart, JobCardDet, PartIssueDet, RackBinDetailsList, StaticIssueModeDO } from '../../api-services/dto/job-card';
import { ToastrService } from 'ngx-toastr';
import { JobCardService } from '../../api-services/job-card.service';
import { MatDialog } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { data } from '../../api-services/utils/static-data';
import { CommonService } from '../../api-services/common.service';
import { RackComponent } from '../rack/rack.component';
import { debug } from 'util';
import { RecallRefitDialogComponent } from '../recall-refit-dialog/recall-refit-dialog.component';
import { LoginService } from 'src/app/api-services/login.service';
declare var moment: any;
declare var $: any;


@Component({
  selector: 'app-issue-parts',
  templateUrl: './issue-parts.component.html',
  styleUrls: ['./issue-parts.component.scss']
})
export class IssuePartsComponent implements OnInit {
  @ViewChild('createPart') isValidPart: any;
  @Input() jcType: any;
  @Output() totalPartVal = new EventEmitter();
  @Input() sparesReplace: any = '';
  public isShowLocation = true;
  public lang: any;
  public partsHeader = [];
  public partexists: boolean;
  public imode = 0;
  public invalScootapart = false;
  public invalgoodwillpart = false;
  public issueMode = new StaticIssueModeDO();
  savePart = new SavePart();
  jobCardDet = new JobCardDet();
  partIssueDet = new PartIssueDet();
  rackBinDetailsList = new RackBinDetailsList();
  refPartList: Array<any> = [];
  partNoList: Array<any> = [];
  issueModeList: Array<any> = [];
  totalPartValue;
  totalPart;
  prevReqQty;
  taxCalculateParam =
    {
      'SpareTaxDet':
      {
        'DEALERID': '',
        'BranchID': '',
        'custTaxCatId': '',
        'ItemTaxCat': '',
        'ItemTypeId': '',
        'HsnCode': '',
        'CustId': '',
        'CountryCode': '',
        'StateID': '',
        'CustStateID': '',
        'MRP': '',
        'SchemeDiscount_Value': '0',
        'MASTER_DISCOUNT_VALUE': '0',
        'MANUAL_DISCOUNT': '0',
        'LocationId': '',
        'DocumentDate': '',
        'Part_Cost': '',
        'COST_PLUS_MARGIN': '',
        'COST_PLUS': '',
        'AMC_DISC_AMT': 0,
        'Qty': '1',
        'MASTER_DISC_PERC': '0',
        'SchemeDiscount_Per': '0',
        'MANUAL_DISCOUNT_PERC': '0',
        'GST_IN_NO': '',
        'UserId':''
      }
    };

  @Input() jcId;
  @Input() customerId;
  @Input() isRootDisabled = false;
  @Input() custState: any;
  jobCardDo: any;
  vehicle: any;
  modelPartList: any;
  storageLocation = [];
  refLocationId;
  isShowPageLoader = false;
  isDisablePart;
  stateFlagId = 'DEFAULT';
  custGstNo: any;
  public RecallRefit: any;
  public RecallRefitRejectReason: any;
  public RecallRefitParts: any;
  public isRefreshParts: boolean = false;
  constructor(private toastr: ToastrService, private jobCardService: JobCardService
    , public dialog: MatDialog,private loginService:LoginService) { }
  ngOnInit() {
    if (this.sparesReplace != '') {
      this.isShowLocation = false;
    } else {
      this.isShowLocation = true;
    }
    this.lang = CommonService.getLang();
    if (this.lang) {
      //this.issueModeList = data.issueMode;
      if (this.jcId) {
        // this.getPartNo();
        // this.getPartDetailsByJcId();
      }


    }

  }


  //check state 

  checkState() {
    if (this.custState && CommonService.getDealerData().STATE_ID) {
      if (this.custState == CommonService.getDealerData().STATE_ID) {
        if (this.custState == 'KER' && CommonService.getDealerData().STATE_ID == 'KER') {
          this.stateFlagId = 'GST_KER';
        } else {
          this.stateFlagId = 'GST';
        }
      } else if (this.custState != CommonService.getDealerData().STATE_ID) {
        if (CommonService.getDealerData().STATE_ID == 'KER') {
          this.stateFlagId = 'IGST_KER';
        } else {
          this.stateFlagId = 'IGST';
        }
      } else {
        this.stateFlagId = 'DEFAULT';
      }
      if (this.stateFlagId == 'GST_KER') {
        this.partsHeader = [
          {
            name: this.lang.SERVICES.partNo + ' *',
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.reqQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.netIssuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.unitPrice,
            width: 'wmpx-100'
          },
          {
            name: 'MRP',
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partCost,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.manufacture,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxable,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partDescription,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.returnQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.qtyReturn,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.cgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.sgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.kfcInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.uom,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.justification,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.pendingWith,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.warrClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.trnClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.approvedBy,
            width: 'wmpx-100'
          }
        ];
      } else if (this.stateFlagId == 'GST') {
        this.partsHeader = [
          {
            name: this.lang.SERVICES.partNo + ' *',
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.reqQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.netIssuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.unitPrice,
            width: 'wmpx-100'
          },
          {
            name: 'MRP',
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partCost,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.manufacture,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxable,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partDescription,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.returnQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.qtyReturn,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.cgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.sgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.uom,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.justification,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.pendingWith,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.warrClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.trnClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.approvedBy,
            width: 'wmpx-100'
          }
        ];
      } else if (this.stateFlagId == 'IGST_KER') {
        this.partsHeader = [
          {
            name: this.lang.SERVICES.partNo + ' *',
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.reqQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.netIssuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.unitPrice,
            width: 'wmpx-100'
          },
          {
            name: 'MRP',
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partCost,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.manufacture,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxable,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partDescription,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.returnQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.qtyReturn,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.igstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.utgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.kfcInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.uom,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.justification,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.pendingWith,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.warrClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.trnClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.approvedBy,
            width: 'wmpx-100'
          }
        ];
      } else if (this.stateFlagId == 'IGST') {
        this.partsHeader = [
          {
            name: this.lang.SERVICES.partNo + ' *',
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.reqQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.netIssuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.unitPrice,
            width: 'wmpx-100'
          },
          {
            name: 'MRP',
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partCost,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.manufacture,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxable,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partDescription,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.returnQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.qtyReturn,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.igstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.utgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.uom,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.justification,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.pendingWith,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.warrClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.trnClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.approvedBy,
            width: 'wmpx-100'
          }
        ];
      } else {
        this.partsHeader = [
          {
            name: this.lang.SERVICES.partNo + ' *',
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issueMode + ' *',
            width: 'wmpx-150'
          }, {
            name: this.lang.SERVICES.reqQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.netIssuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.unitPrice,
            width: 'wmpx-100'
          },
          {
            name: 'MRP',
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partCost,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.manufacture,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.taxable,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.partDescription,
            width: 'wmpx-200'
          },
          {
            name: this.lang.SERVICES.issuedQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.returnQty,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.qtyReturn,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.schemeDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.masterDisc,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.cgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.sgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.igstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.utgstInPer,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.hsnCode,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.uom,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.justification,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.pendingWith,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.warrClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.trnClaimNo,
            width: 'wmpx-100'
          },
          {
            name: this.lang.SERVICES.approvedBy,
            width: 'wmpx-100'
          }
        ];
      }
    }
  }

  /**
   * Search part no
   * @param {number} index 
   * @param {Object}event 
   */
  getPartNo(index, event) {

    if (event.code != 'ArrowUp' && event.code != 'ArrowDown') {
      let localParam = this.refPartList[index];
      if (localParam && localParam.PartIssueDet && localParam.PartIssueDet.PART_NO && localParam.PartIssueDet.PART_NO.length >= 3) {
        let params = new HttpParams();
        params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
          .set('Country', CommonService.getDealerData().COUNTRY_CODE)
          .set('BranchID', CommonService.getUserData().BRANCH_ID)
          .set('partNo', localParam.PartIssueDet.PART_NO)
          .set('LocID', this.refLocationId)
          .set("Is_Lubricant", null);
        this.jobCardService.getPartNumbers(params).subscribe((resp: any) => {
          if (resp && resp.data && resp.statusCode === 200) {
            this.refPartList[index].partNoList = resp.data;

          } else {
            this.toastr.error(resp.d.message);
          }
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }, error => {
          this.toastr.error(error.error.Message);
          if (error.status == 401) {
            this.loginService.logout();
          }
        });
      }
    }
  }

  /*
  * Get ECatalogParts  
  */
  getECatalogParts(partno) {
    if (this.jcId) {
      this.isShowPageLoader = true;
      let params = new HttpParams();
      params = params.set('ModelID', this.vehicle.MODEL_ID)
        .set('PartNo', partno)
        .set('DealerId', CommonService.getUserData().DEALER_ID)
        .set('BranchId', CommonService.getUserData().BRANCH_ID)
        .set('UserId', CommonService.getUserData().USER_ID);
      this.jobCardService.getEcatalogParts(params).subscribe((resp: any) => {
        if (resp && resp.data && resp.statusCode === 200) {
        }
        else {
          this.toastr.error(resp.message);
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }
      }, error => {
        if (error.status == 401) {
          this.loginService.logout();
        }
      }
      );
    }
  }


  /**
   * Get jobcard part details by jc id
   */
  getPartDetailsByJcId() {
    if (this.jcId) {
      this.isShowPageLoader = true;
      let params = new HttpParams();
      params = params.set('DealerID', CommonService.getUserData().DEALER_ID).
        set('CountryCode', CommonService.getDealerData().COUNTRY_CODE).
        set('BranchID', CommonService.getUserData().BRANCH_ID).
        set('JobCardID', this.jcId).
        set('StateId', CommonService.getDealerData().STATE_ID).
        set('RoleID', CommonService.getUserData().ROLE_ID).
        set('UserId', CommonService.getUserData().USER_ID)
      this.jobCardService.getPartMasterList(params).subscribe((resp: any) => {
        if (resp && resp.data && resp.statusCode === 200) {

          this.issueModeList = resp.data.IssueList;
          if (resp && resp.data && resp.data.JobCardDo) {
            if (resp.data.JobCardDo.STATUS > 1) {
              this.isRootDisabled = true;
            }
            else {
              this.isRootDisabled = false;
            }
            this.jobCardDo = resp.data.JobCardDo;
            this.vehicle = resp.data.Vehicle;
            this.modelPartList = resp.data.ModelPartList ? resp.data.ModelPartList[0] : {};
          }
          if (resp && resp.data && resp.data.StorageLocationList && resp.data.StorageLocationList.length > 0) {
            this.storageLocation = resp.data.StorageLocationList ? resp.data.StorageLocationList : [];
            this.refLocationId = this.storageLocation[0].LOCATION_ID;
          }
          if (resp && resp.data && resp.data.JobCardDo && resp.data.JobCardDo.JCSparesIssueList &&
            resp.data.JobCardDo.JCSparesIssueList.length > 0) {
            this.refPartList = [];
            resp.data.JobCardDo.JCSparesIssueList.filter(key => {
              let savePart = new SavePart();
              let partIssueDet = new PartIssueDet();
              let jobCardDet = new JobCardDet();
              partIssueDet.SPARE_PART_ID = key.SPARE_PART_ID;
              partIssueDet.MODE_ID = key.MODE_ID;
              //partIssueDet.REQ_QTY = '';//Commented by dinesh
              partIssueDet.REQ_QTY = '';
              partIssueDet.NetREQ_QTY = key.NetREQ_QTY;
              partIssueDet.UNIT_PRICE = key.UNIT_PRICE;
              partIssueDet.ITEM_TYPE_ID = key.ITEM_TYPE_ID;
              partIssueDet.MRP = key.MRP;
              partIssueDet.RACK_ID = key.RACK_ID ? key.RACK_ID : 0;
              partIssueDet.PART_RK_BN_ID = key.PART_RK_BN_ID;
              partIssueDet.JCARD_SPARE_ID = key.JCARD_SPARE_ID ? key.JCARD_SPARE_ID : 0;
              partIssueDet.Part_Cost = key.Part_Cost;
              partIssueDet.MANUF_ID = key.MANUF_ID;
              partIssueDet.isTaxable = key.TAXABLE;
              partIssueDet.LOCATION_ID = key.LOCATION_ID;//this.refLocationId;
              partIssueDet.PART_DESCRIPTION = key.PART_DESCRIPTION;
              partIssueDet.NetISSUED_QTY = key.NetISSUED_QTY;
              partIssueDet.RET_QTY = key.RET_QTY;
              partIssueDet.QTY_TobeReturned = key.QTY_TobeReturned;
              partIssueDet.SchemeDiscount_Value = key.DISC_AMT ? key.DISC_AMT : key.SchemeDiscount_Value; //key.SchemeDiscount_Value;
              partIssueDet.MASTER_DISCOUNT_VALUE = key.DISC_VALUE ? key.DISC_VALUE : key.MASTER_DISCOUNT_VALUE; //key.MASTER_DISCOUNT_VALUE;
              partIssueDet.CGST = key.CGST;
              partIssueDet.PART_NO = key.PART_NO;
              partIssueDet.SGST = key.SGST;
              partIssueDet.IGST = key.IGST;
              partIssueDet.UTGST = key.UTGST;
              partIssueDet.TAX_FIELD1 = key.TAX_FIELD1;
              partIssueDet.HSN_CODE = key.HSN_CODE;
              partIssueDet.UOM_ID = key.UOM_ID;
              partIssueDet.JUSTIFICATION = key.JUSTIFICATION;
              partIssueDet.AMC_DISC_AMT = key.AMC_DISC_AMT; //AMC Discount Per
              partIssueDet.MASTER_DISC_PERC = key.MASTER_DISC_PERC; // Master Disc Per
              partIssueDet.TAX_VALUE = key.TAX_VALUE;
              partIssueDet.Pending = key.PENDING;
              partIssueDet.DLR_WAR_CLM_NO = key.DLR_WAR_CLM_NO;
              partIssueDet.DLR_TRAN_CLAIM_NO = key.DLR_TRAN_CLAIM_NO;
              partIssueDet.Approved_by = key.Approved_by;

              partIssueDet.IS_WEAR_TEAR = key.IS_WEAR_TEAR;
              partIssueDet.FROM_KM = key.FROM_KM;
              partIssueDet.TO_KM = key.TO_KM;
              partIssueDet.NO_OF_VALID_DAYS = key.NO_OF_VALID_DAYS;

              savePart.CountryCode = CommonService.getDealerData().COUNTRY_CODE;
              savePart.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
              jobCardDet.JOB_CARD_ID = this.jcId;
              savePart.PartIssueDet = partIssueDet;
              savePart.JobCardDet = jobCardDet;
              this.refPartList.push(savePart);
              //Adding empty 
              // this.addemptyPart();
            });
            if (resp.data.RecallRefitMessage) {
              this.checkRecallRefit();
              // this.toastr.info(resp.data.RecallRefitMessage);//Sapna
            }
            this.calculatePartAmt();
          }
          this.LoadDefParts();
        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
      });

    }
  }


  issueModeValidation(index) {
    let localParam = this.refPartList[index];
    if (this.vehicle.IS_OLD_VEHICLE) {
      if (localParam.PartIssueDet.MODE_ID == 2 || localParam.PartIssueDet.MODE_ID == 8 ||
        localParam.PartIssueDet.MODE_ID == 6 || localParam.PartIssueDet.MODE_ID == 5) {
        this.toastr.error(this.lang.Message.JOB_CARD.INVALID_VEHI_WARR);
        this.refPartList[index].PartIssueDet.MODE_ID = 1;
      }
    }
    if (localParam.PartIssueDet.MODE_ID == 10) {
      if (localParam.PartIssueDet.MANUF_ID == 1) {
        if (!localParam.PartIssueDet.IS_ZMC) {
          this.toastr.error(this.lang.Message.JOB_CARD.INVALID_ZMC);
          this.refPartList[index].PartIssueDet.MODE_ID = this.imode;
          this.imode = this.imode - 1;
        }
      }
    }
    if (localParam.PartIssueDet.MANUF_ID == 1) {
      if (localParam.PartIssueDet.MODE_ID == 9 && localParam.PartIssueDet.PART_TYPE_ID != null) {
        if (localParam.PartIssueDet.PART_TYPE_ID == '' || localParam.PartIssueDet.PART_TYPE_ID != '' &&
          localParam.PartIssueDet.PART_TYPE_ID == 2) {
          this.toastr.error(this.lang.Message.JOB_CARD.INVALID_ACCES);
          this.refPartList[index].PartIssueDet.MODE_ID = 1;
        }
      }
      if (localParam.PartIssueDet.MODE_ID == 2 && this.jobCardDo.IS_CWI_EXT_WARRANTY) {
        if (localParam.PartIssueDet.PART_TYPE_ID != '' && localParam.PartIssueDet.PART_TYPE_ID == 2) {
          this.toastr.error(this.lang.Message.JOB_CARD.INVALID_WARRANTY);
          this.refPartList[index].PartIssueDet.MODE_ID = 1;
        } if (!localParam.PartIssueDet.WARRANTY_APPLICABLE) {
          this.toastr.error(this.lang.Message.JOB_CARD.INVALID_WARRANTY);
          this.refPartList[index].PartIssueDet.MODE_ID = 1;
        }
      } else {
        if (localParam.PartIssueDet.MODE_ID == 2 && localParam.PartIssueDet.PART_TYPE_ID != '' &&
          localParam.PartIssueDet.PART_TYPE_ID == 2) {
          this.toastr.error(this.lang.Message.JOB_CARD.INVALID_WARRANTY);
          this.refPartList[index].PartIssueDet.MODE_ID = 1;
        }
      }



      if (localParam.PartIssueDet.IS_WEAR_TEAR == true) {
        if (localParam.PartIssueDet.MODE_ID == 2) {
          if (this.jobCardDo.KILOMETERS >= localParam.PartIssueDet.FROM_KM && this.jobCardDo.KILOMETERS <= localParam.PartIssueDet.TO_KM) {
            var date1: any = new Date();
            var date2: any = new Date(this.vehicle.SALE_DATE);
            var diffDays: any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));
            if (diffDays <= localParam.PartIssueDet.NO_OF_VALID_DAYS) {

            }
            else {
              this.toastr.error(" Issue Mode Warranty Not Applicable for this Part : " + localParam.PartIssueDet.PART_NO);
              this.refPartList[index].PartIssueDet.MODE_ID = 1;
            }
          }
          else {
            this.toastr.error(" Issue Mode Warranty Not Applicable for this Part : " + localParam.PartIssueDet.PART_NO);
            this.refPartList[index].PartIssueDet.MODE_ID = 1;
          }
        }
      }



      if (localParam.PartIssueDet.MODE_ID == 2 && CommonService.getDealerData().COUNTRY_CODE == 'NG' ||
        CommonService.getDealerData().COUNTRY_CODE == 'NG3W') {
        if (this.vehicle.PART_ID == this.modelPartList.MODEL_PART_ID) {
          let calculateDate = moment(this.vehicle.SALE_DATE).add(this.modelPartList.DATE_SALE_TO_MONTH, 'months').format('DD/MM/YYYY');
          let currentDate = moment().format('DD/MM/YYYY');
          if (calculateDate < currentDate || this.jobCardDo.KILOMETERS <= this.modelPartList.KM_VALID) {

          } else {
            this.toastr.error(this.lang.Message.JOB_CARD.INVALID_WARRANTY_KILO +
              this.modelPartList.KM_VALID + this.lang.Message.JOB_CARD.INVALID_WARRANTY_PERIOD +
              calculateDate);
            this.refPartList[index].PartIssueDet.MODE_ID = 1;
          }
        }
      }
    } else {
      if (localParam.PartIssueDet.MODE_ID == 2) {
        this.toastr.error(this.lang.Message.JOB_CARD.INVALID_WARRANTY_TVS);
        this.refPartList[index].PartIssueDet.MODE_ID = 1;
      }
    }
  }

  issueModeValidationnew(index) {
    let localParam = this.refPartList[index];
    if (localParam.PartIssueDet.MODE_ID != "") {
      this.issueMode.PART_NO = localParam.PartIssueDet.PART_NO;
      this.issueMode.DEALER_ID = CommonService.getUserData().DEALER_ID;
      this.issueMode.COUNTRY_CODE = CommonService.getDealerData().COUNTRY_CODE;   
      this.issueMode.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
      this.issueMode.USER_ID = CommonService.getUserData().USER_ID;
      this.issueMode.ISSUE_MODE_ID = localParam.PartIssueDet.MODE_ID;      
      this.issueMode.PROD_MON = this.vehicle.PROD_MON;
      this.issueMode.PROD_YR = this.vehicle.PROD_YR;
      this.issueMode.MCS_TYPE_ID = this.vehicle.MCS_TYPE_ID;
      this.issueMode.CATEGORY_ID = this.vehicle.CATEGORY_ID;
      this.issueMode.MODEL_ID = this.vehicle.MODEL_ID;         
      this.issueMode.SALE_DATE = this.vehicle.SALE_DATE;
      this.issueMode.PROD_MON = this.vehicle.PROD_MON;
      this.issueMode.PROD_YR = this.vehicle.PROD_YR;
      this.issueMode.IsOLDVehicle = this.vehicle.IS_OLD_VEHICLE;
      this.issueMode.FRAME_NO = this.jobCardDo.FRAME_NO;
      this.issueMode.KILOMETERS = this.jobCardDo.KILOMETERS;
      this.issueMode.IS_CWI_EXT_WARRANTY = this.jobCardDo.IS_CWI_EXT_WARRANTY;
      this.issueMode.JOB_TYPE_ID = this.jobCardDo.JOB_TYPE_ID;      
      this.issueMode.INS_HAND_BY = this.jobCardDo.InsuranceDetails.INS_HAND_BY;
      this.issueMode.IS_RECALL_REFIT = this.jobCardDo.IS_RECALL_REFIT;      
      //this.issueMode.IS_EXT_WAR = localParam.PartIssueDet.IS_EXT_WAR;
      //this.issueMode.PART_TYPE_ID = localParam.PartIssueDet.PART_TYPE_ID;
      //this.issueMode.IS_ZMC = localParam.PartIssueDet.IS_ZMC;
      //this.issueMode.IS_WEAR_TEAR = localParam.PartIssueDet.IS_WEAR_TEAR;
      //this.issueMode.FROM_KM = localParam.PartIssueDet.FROM_KM;
      //this.issueMode.TO_KM = localParam.PartIssueDet.TO_KM
      //this.issueMode.NO_OF_VALID_DAYS = localParam.PartIssueDet.NO_OF_VALID_DAYS;
      //this.issueMode.MANUF_ID = localParam.PartIssueDet.MANUF_ID;      
      this.isShowPageLoader = true;
      this.jobCardService.checkIssueMode(this.issueMode).subscribe((resp: any) => {
        if (resp && resp.statusCode && resp.statusCode == 200) {
        } else {
          this.toastr.error(resp.message);
          this.refPartList[index].PartIssueDet.MODE_ID = 1;
          this.isShowPageLoader = false;
        }
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }


  addemptyPart() {
    let savePart = new SavePart();
    let partIssueDet = new PartIssueDet();
    let jobCardDet = new JobCardDet();
    partIssueDet.JOB_CARD_ID = this.jcId;
    partIssueDet.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    partIssueDet.DEALER_ID = CommonService.getUserData().DEALER_ID;
    partIssueDet.SPARE_PART_ID = 0;
    partIssueDet.MODE_ID = 1;
    partIssueDet.RACK_ID = 0;
    partIssueDet.PART_RK_BN_ID = 0;
    partIssueDet.JCARD_SPARE_ID = 0;
    partIssueDet.partNoList = [];
    savePart.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
    jobCardDet.JOB_CARD_ID = this.jcId;
    savePart.PartIssueDet = partIssueDet;
    savePart.JobCardDet = jobCardDet;
    this.refPartList.push(savePart);
    $('.inlineTable').scrollTop($('.inlineTable')[0].scrollHeight);
  }

  /**
   * Add New Part
   */
  addPart() {
    let lastElem = this.refPartList[this.refPartList.length - 1];
    //  if (this.validatePart(this.refPartList)) {
    //console.log(lastElem);
    if (this.refPartList.length == 0 || lastElem && lastElem.PartIssueDet && lastElem.PartIssueDet.SPARE_PART_ID != 0) {
      let savePart = new SavePart();
      let partIssueDet = new PartIssueDet();
      let jobCardDet = new JobCardDet();
      partIssueDet.JOB_CARD_ID = this.jcId;
      partIssueDet.BRANCH_ID = CommonService.getUserData().BRANCH;
      partIssueDet.DEALER_ID = CommonService.getUserData().DEALER_ID;
      partIssueDet.SPARE_PART_ID = 0;
      partIssueDet.MODE_ID = 1;
      partIssueDet.RACK_ID = 0;
      partIssueDet.PART_RK_BN_ID = 0;
      partIssueDet.JCARD_SPARE_ID = 0;
      partIssueDet.partNoList = [];
      savePart.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
      jobCardDet.JOB_CARD_ID = this.jcId;
      savePart.PartIssueDet = partIssueDet;
      savePart.JobCardDet = jobCardDet;
      this.refPartList.push(savePart);
      $('.inlineTable').scrollTop($('.inlineTable')[0].scrollHeight);
    }
    //} else {
    //  this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_PARTNO);
    //}
  }
  /**
   * Get part details based on required quantity
   * @param {Number} index 
   */
  getPartDetails(index, obj) {

    let localParam = this.refPartList[index];
    let refPartId = 0;
    this.refPartList.filter(key => {
      if (this.refPartList.indexOf(key) != index) {
        if (key.PartIssueDet.PART_NO == localParam.PartIssueDet.PART_NO) {
          refPartId++;
        }
      }
    });
    if (refPartId == 0 || obj) {
      if (localParam.PartIssueDet.PART_NO && localParam.PartIssueDet.MODE_ID) {
        this.isShowPageLoader = true;
        let params = new HttpParams();
        params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
          .set('partNo', localParam.PartIssueDet.PART_NO)
          .set('CustCode', this.customerId)
          .set('IssueModeID', localParam.PartIssueDet.MODE_ID)
          .set('BranchID', CommonService.getUserData().BRANCH_ID)
          .set('LocationID', this.refLocationId)//.set('LocationID', '1')
          .set('LocationName', 'spare')
          .set('StateId', CommonService.getDealerData().STATE_ID)
          .set('CountryCode', CommonService.getDealerData().COUNTRY_CODE)
          .set('JobCardID', this.jcId)
          .set('UserId', CommonService.getUserData().USER_ID);
        this.jobCardService.getPartDetailsJobCardNew(params).subscribe((resp: any) => {
          if (resp && resp.data && resp.statusCode === 200) {
            if (this.vehicle.MODEL_ID.slice(0, 5) == "00001" || this.vehicle.MODEL_ID.slice(0, 5) == "00002") {
              if (resp.data.PART_TYPE_ID == 4) {
                this.invalScootapart = true;
              }
              else {
                this.invalScootapart = false;
              }
            }
            if (this.invalScootapart == false) {
              this.refPartList[index].PartIssueDet.SPARE_PART_ID = resp.data.SPARE_PART_ID;
              this.refPartList[index].PartIssueDet.REQ_QTY = resp.data.REQ_QTY;
              this.refPartList[index].PartIssueDet.NetREQ_QTY = resp.data.NetREQ_QTY;
              this.refPartList[index].PartIssueDet.UNIT_PRICE = resp.data.UNIT_PRICE;
              this.refPartList[index].PartIssueDet.MRP = resp.data.MRP;
              this.refPartList[index].PartIssueDet.Part_Cost = resp.data.Part_Cost;
              this.refPartList[index].PartIssueDet.MANUF_ID = resp.data.MANUF_ID;
              this.refPartList[index].PartIssueDet.isTaxable = resp.data.TAXABLE;
              this.refPartList[index].PartIssueDet.PART_DESCRIPTION = resp.data.PART_DESCRIPTION;
              this.refPartList[index].PartIssueDet.NetISSUED_QTY = resp.data.NetISSUED_QTY;
              //this.refPartList[index].PartIssueDet.RET_QTY = resp.data.RET_QTY;
              this.refPartList[index].PartIssueDet.LOCATION_ID = this.refLocationId;
              //this.refPartList[index].PartIssueDet.QTY_TobeReturned = resp.data.QTY_TobeReturned;
              //this.refPartList[index].PartIssueDet.SchemeDiscount_Value = resp.data.SchemeDiscount_Value;
              //this.refPartList[index].PartIssueDet.MASTER_DISCOUNT_VALUE = resp.data.MASTER_DISCOUNT_VALUE;
              //this.refPartList[index].PartIssueDet.AMC_DISC_AMT = resp.data.AMC_DISC_AMT; //AMC Discount Per
              //this.refPartList[index].PartIssueDet.MASTER_DISC_PERC = resp.data.MASTER_DISC_PERC; // Master Disc Per
              //this.refPartList[index].PartIssueDet.CGST = resp.data.CGST;
              //this.refPartList[index].PartIssueDet.SGST = resp.data.SGST;
              //this.refPartList[index].PartIssueDet.IGST = resp.data.IGST;
              //this.refPartList[index].PartIssueDet.UTGST = resp.data.UTGST;
              this.refPartList[index].PartIssueDet.HSN_CODE = resp.data.HSN_CODE;
              this.refPartList[index].PartIssueDet.UOM_ID = resp.data.UOM_ID;
              this.refPartList[index].PartIssueDet.ITEM_TYPE_ID = resp.data.ITEM_TYPE_ID;
              //this.refPartList[index].PartIssueDet.RACK_ID = resp.data.RACK_ID ? resp.data.RACK_ID : 0;
              //this.refPartList[index].PartIssueDet.PART_RK_BN_ID = resp.data.PART_RK_BN_ID ? resp.data.PART_RK_BN_ID : 0;
              this.refPartList[index].PartIssueDet.JCARD_SPARE_ID = resp.data.JCARD_SPARE_ID ? resp.data.JCARD_SPARE_ID : 0;
              this.refPartList[index].PartIssueDet.ITEM_TAX_CAT_ID = resp.data.ITEM_TAX_CAT_ID;
              this.refPartList[index].PartIssueDet.IS_ZMC = resp.data.IS_ZMC;
              this.refPartList[index].PartIssueDet.PART_TYPE_ID = resp.data.PART_TYPE_ID;
              this.refPartList[index].PartIssueDet.WARRANTY_APPLICABLE = resp.data.WARRANTY_APPLICABLE;

              this.refPartList[index].PartIssueDet.IS_WEAR_TEAR = resp.data.IS_WEAR_TEAR;
              this.refPartList[index].PartIssueDet.FROM_KM = resp.data.FROM_KM;
              this.refPartList[index].PartIssueDet.TO_KM = resp.data.TO_KM;
              this.refPartList[index].PartIssueDet.NO_OF_VALID_DAYS = resp.data.NO_OF_VALID_DAYS;
              this.refPartList[index].PartIssueDet.IS_EXT_WAR = resp.data.IS_EXT_WAR;
              this.refPartList[index].CountryCode = CommonService.getDealerData().COUNTRY_CODE;
              this.refPartList[index].JobCardDet.JOB_CARD_ID = this.jcId;
              if (obj) {
                this.refPartList[index].PartIssueDet.REQ_QTY = obj.qty;
                this.refPartList[index].PartIssueDet.Part_Cost = obj.cost;
                this.refPartList[index].PartIssueDet.MRP = obj.mrp;
                this.assignTaxParam(this.refPartList[this.refPartList.length - 1]);
              }
              if (localParam.PartIssueDet.MODE_ID == 2 || localParam.PartIssueDet.MODE_ID == 8)//(this.vehicle.MODEL_ID == "1" || this.vehicle.MODEL_ID == "2")
              {
                this.getECatalogParts(localParam.PartIssueDet.PART_NO);
              }
            }
            else {
              this.toastr.error(this.lang.Message.JOB_CARD.INVALID_FOR_MC_MP);
              this.refPartList[index].PartIssueDet.PART_NO = '';
              this.refPartList[index].partNoList = [];
            }
          } else {
            this.toastr.error(resp.d.message);
          }
          this.isShowPageLoader = false;
          if (this.refPartList[index].PartIssueDet.MODE_ID == 10) {
            if (this.refPartList[index].PartIssueDet.MANUF_ID == 1) {
              if (!this.refPartList[index].PartIssueDet.IS_ZMC) {
                this.toastr.error(this.lang.Message.JOB_CARD.INVALID_ZMC);
                this.refPartList[index].PartIssueDet.MODE_ID = this.imode;
                this.imode = this.imode - 1;
                //localParam.PartIssueDet.MODE_ID == 1;
              }
            }
          }
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }, error => {
          this.toastr.error(error.error.Message);
          this.isShowPageLoader = false;
          if (error.status == 401) {
            this.loginService.logout();
          }
        });
      }
    } else {
      this.toastr.error(this.lang.Message.JOB_CARD.NOTEXIST_PART);
      this.refPartList[index].PartIssueDet.PART_NO = '';
      this.refPartList[index].partNoList = [];
    }
  }

  validateRack(localParam) {
    let valid = true;
    if (Number(localParam.PartIssueDet.REQ_QTY)) {
      if (this.jcType == 14 && (localParam.PartIssueDet.SPARE_PART_ID && localParam.PartIssueDet.REQ_QTY && localParam.PartIssueDet.REQ_QTY > 0 && localParam.PartIssueDet.MODE_ID)) {
        return valid;
      } else if (localParam.PartIssueDet.SPARE_PART_ID && localParam.PartIssueDet.REQ_QTY && localParam.PartIssueDet.REQ_QTY > 0 && localParam.PartIssueDet.MODE_ID && this.customerId) {
        return valid;
      } else {
        this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_REQTUANTITY);
        return valid = false;
      }
    }
    else {
      this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_REQTUANTITY);
      return valid = false;
    }
  }

  /**
   * Get rack details based on required quantity  
   * @param {Number} index 
   */
  getRackList(index) {
    let localParam = this.refPartList[index];
    let refPartParam = JSON.parse(JSON.stringify(this.refPartList[index]))
    if (this.validateRack(localParam)) {
      let params = new HttpParams();
      params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
        .set('BranchID', CommonService.getUserData().BRANCH_ID)
        .set('ManufId', localParam.PartIssueDet.MANUF_ID)
        .set('SparePartId', localParam.PartIssueDet.SPARE_PART_ID)
        .set('locatationId', this.refLocationId)
        .set('customerId', this.customerId ? this.customerId : 0)
        .set('StateId', CommonService.getDealerData().STATE_ID)
        .set('JobCardID', this.jcId)
        .set('CountryCode', CommonService.getDealerData().COUNTRY_CODE)
        .set('UserId', CommonService.getUserData().USER_ID)
      this.isShowPageLoader = true;
      this.jobCardService.getRackListJobCardNew(params).subscribe((resp: any) => {
        if (resp && resp.data && resp.statusCode === 200) {
          if (resp && resp.data && resp.data._RackList && resp.data._RackList.length > 1) {
            this.custGstNo = resp.data.GST_IN_NO;
            let dataObj = { data: resp.data._RackList, partNo: localParam.PartIssueDet.PART_NO, reqQty: localParam.PartIssueDet.REQ_QTY };
            let dialogRef = this.dialog.open(RackComponent, {
              width: '60%',
              height: 'auto',
              data: dataObj,
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                if (result && result.length > 0) {
                  let refVar = 0;
                  let totReqQty: number = 0;
                  result.filter(item => {
                    if (parseFloat(item.RequiredQty) >= 0) {
                      totReqQty += parseFloat(item.RequiredQty);
                    }
                  });
                  result.filter(key => {
                    if (result.length == result.indexOf(key) + 1) {
                      this.isRefreshParts = true;
                    } else {
                      this.isRefreshParts = false;
                    }
                    if (parseFloat(key.RequiredQty) > 0 && refVar == 0) {
                      localParam.PartIssueDet.REQ_QTY = key.RequiredQty;
                      localParam.PartIssueDet.MRP = key.MRP;
                      localParam.PartIssueDet.Part_Cost = key.Cost;
                      localParam.PartIssueDet.COST_PLUS = key.COST_PLUS;
                      localParam.PartIssueDet.COST_PLUS_MARGIN = key.COST_PLUS_MARGIN;
                      localParam.PartIssueDet.AMC_DISC_AMT = resp.data.AMC_DISC_AMT; //AMC Discount Per
                      localParam.PartIssueDet.MASTER_DISC_PERC = resp.data.MASTER_DISC_PERC; // Master Disc Per                     
                      localParam.PartIssueDet.RACK_ID = key.RackNo ? key.RackNo : 0;
                      localParam.PartIssueDet.PART_RK_BN_ID = key.PartRKBNID ? key.PartRKBNID : 0;
                      let rack = new RackBinDetailsList();
                      rack.DEALER_ID = key.DEALER_ID;
                      rack.BRANCH_ID = key.BRANCH_ID;
                      rack.SPARE_PART_ID = key.SPARE_PART_ID;
                      rack.LOCATION_ID = key.LOCATION_ID;
                      rack.RackNo = key.RackNo;
                      rack.MRP = key.MRP;
                      rack.PartRKBNID = key.PartRKBNID;
                      rack.RequiredQty = key.RequiredQty;
                      rack.isTaxable = key.isTaxable;
                      localParam.PartIssueDet.RackBinDetailsList = [];
                      localParam.PartIssueDet.RackBinDetailsList.push(rack);
                      //console.log(' key.PartRKBNID', key.PartRKBNID);
                      this.assignTaxParam(localParam);
                      refVar++;
                    } else if (key.RequiredQty > 0 && refVar != 0) {
                      let newPartItem = JSON.parse(JSON.stringify(refPartParam));
                      newPartItem.PartIssueDet.REQ_QTY = key.RequiredQty;
                      newPartItem.PartIssueDet.MRP = key.MRP;
                      newPartItem.PartIssueDet.Part_Cost = key.Cost;
                      newPartItem.PartIssueDet.COST_PLUS = key.COST_PLUS;
                      newPartItem.PartIssueDet.COST_PLUS_MARGIN = key.COST_PLUS_MARGIN;
                      newPartItem.PartIssueDet.AMC_DISC_AMT = resp.data.AMC_DISC_AMT; //AMC Discount Per
                      newPartItem.PartIssueDet.MASTER_DISC_PERC = resp.data.MASTER_DISC_PERC; // Master Disc Per                     
                      newPartItem.PartIssueDet.RACK_ID = key.RackNo ? key.RackNo : 0;
                      newPartItem.PartIssueDet.PART_RK_BN_ID = key.PartRKBNID ? key.PartRKBNID : 0;
                      newPartItem.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
                      let rack = new RackBinDetailsList();
                      rack.DEALER_ID = key.DEALER_ID;
                      rack.BRANCH_ID = key.BRANCH_ID;
                      rack.SPARE_PART_ID = key.SPARE_PART_ID;
                      rack.LOCATION_ID = key.LOCATION_ID;
                      rack.RackNo = key.RackNo;
                      rack.MRP = key.MRP;
                      rack.PartRKBNID = key.PartRKBNID;
                      rack.RequiredQty = key.RequiredQty;
                      rack.isTaxable = key.isTaxable;
                      newPartItem.PartIssueDet.RackBinDetailsList = [];
                      newPartItem.PartIssueDet.RackBinDetailsList.push(rack);
                      // this.refPartList.splice(this.refPartList.indexOf(localParam)+1,0,newPartItem);
                      this.refPartList.push(newPartItem)
                      //console.log('key.PartRKBNID', this.refPartList);
                      this.assignTaxParam(newPartItem);
                    }
                  });
                } else {
                  this.toastr.error(this.lang.Message.JOB_CARD.NOTAVIL_PART);
                  this.refPartList[index].PartIssueDet.REQ_QTY = '';
                  this.isShowPageLoader = false;
                }
              } else {
                this.refPartList[index].PartIssueDet.REQ_QTY = '';
                this.isShowPageLoader = false;
              }
            });
          } else if (resp && resp.data && resp.data._RackList.length == 1) {
            this.isRefreshParts = true;
            localParam.PartIssueDet.MRP = resp.data._RackList[0].MRP;
            localParam.PartIssueDet.Part_Cost = resp.data._RackList[0].Cost;
            localParam.PartIssueDet.COST_PLUS = resp.data._RackList[0].COST_PLUS;
            localParam.PartIssueDet.COST_PLUS_MARGIN = resp.data._RackList[0].COST_PLUS_MARGIN;
            localParam.PartIssueDet.AMC_DISC_AMT = resp.data.AMC_DISC_AMT; //AMC Discount Per
            localParam.PartIssueDet.MASTER_DISC_PERC = resp.data.MASTER_DISC_PERC; // Master Disc Per
            let rack = new RackBinDetailsList();
            rack.DEALER_ID = resp.data._RackList[0].DEALER_ID;
            rack.BRANCH_ID = resp.data._RackList[0].BRANCH_ID;
            rack.SPARE_PART_ID = resp.data._RackList[0].SPARE_PART_ID;
            rack.LOCATION_ID = resp.data._RackList[0].LOCATION_ID;
            rack.RackNo = resp.data._RackList[0].RackNo;
            rack.MRP = resp.data._RackList[0].MRP;
            rack.PartRKBNID = resp.data._RackList[0].PartRKBNID;
            rack.RequiredQty = localParam.PartIssueDet.REQ_QTY;
            rack.isTaxable = resp.data._RackList[0].isTaxable;
            localParam.PartIssueDet.RackBinDetailsList = [];
            localParam.PartIssueDet.RackBinDetailsList.push(rack);
            this.assignTaxParam(localParam);
          } else {
            if (localParam.PART_NO) {
              this.refPartList[index].PartIssueDet.PART_NO = '';
              this.refPartList[index].partNoList = [];
              this.refPartList[index].PartIssueDet.REQ_QTY = '';
              this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_STOCK + localParam.PART_NO);
            } else {
              if (this.refPartList[index].PartIssueDet.NetISSUED_QTY == '') {
                this.refPartList[index].PartIssueDet.PART_NO = '';
                this.refPartList[index].partNoList = [];
                this.refPartList[index].PartIssueDet.REQ_QTY = '';
              }
              else {
                this.refPartList[index].partNoList = [];
                this.refPartList[index].PartIssueDet.REQ_QTY = '';
              }
              this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_STOCK);
            }
            this.isShowPageLoader = false;
          }
        } else {
          this.refPartList[index].PartIssueDet.REQ_QTY = '';
          this.toastr.error(resp.message);
          this.isShowPageLoader = false;
        }
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    } else {
      this.refPartList[index].PartIssueDet.REQ_QTY = '';
      //this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_REQTUANTITY);
    }
  }
  assignTaxParam(localParam) {
    this.taxCalculateParam.SpareTaxDet.DEALERID = CommonService.getUserData().DEALER_ID;
    this.taxCalculateParam.SpareTaxDet.BranchID = CommonService.getUserData().BRANCH_ID
    this.taxCalculateParam.SpareTaxDet.UserId= CommonService.getUserData().USER_ID;
    this.taxCalculateParam.SpareTaxDet.custTaxCatId = this.jobCardDo.CustomerDetails && this.jobCardDo.CustomerDetails.CUST_TAX_CAT_ID ? this.jobCardDo.CustomerDetails.CUST_TAX_CAT_ID : 0;
    this.taxCalculateParam.SpareTaxDet.ItemTaxCat = localParam.PartIssueDet.ITEM_TAX_CAT_ID;
    this.taxCalculateParam.SpareTaxDet.ItemTypeId = localParam.PartIssueDet.ITEM_TYPE_ID;
    this.taxCalculateParam.SpareTaxDet.HsnCode = localParam.PartIssueDet.HSN_CODE;
    this.taxCalculateParam.SpareTaxDet.CustId = this.jobCardDo.CustomerDetails && this.jobCardDo.CustomerDetails.CUSTOMER_ID ? this.jobCardDo.CustomerDetails.CUSTOMER_ID : 0;
    this.taxCalculateParam.SpareTaxDet.CountryCode = CommonService.getDealerData().COUNTRY_CODE;
    //console.log(this.jobCardDo.CustomerDetails.CommunicationAddress.STATE_ID, this.jobCardDo.CustomerDetails.CommunicationAddress);
    this.taxCalculateParam.SpareTaxDet.CustStateID = this.jobCardDo.CustomerDetails && this.jobCardDo.CustomerDetails.CommunicationAddress.STATE_ID ? this.jobCardDo.CustomerDetails.CommunicationAddress.STATE_ID : 0;
    this.taxCalculateParam.SpareTaxDet.StateID = CommonService.getDealerData().STATE_ID;
    if (CommonService.getDealerData().SparePricePickup === 'RRP') {
      this.taxCalculateParam.SpareTaxDet.MRP = localParam.PartIssueDet.UNIT_PRICE;
    } else {
      this.taxCalculateParam.SpareTaxDet.MRP = localParam.PartIssueDet.MRP;
    }
    //this.taxCalculateParam.SpareTaxDet.SchemeDiscount_Value = localParam.PartIssueDet.SchemeDiscount_Value ? localParam.PartIssueDet.SchemeDiscount_Value : 0;
    //this.taxCalculateParam.SpareTaxDet.MASTER_DISCOUNT_VALUE = localParam.PartIssueDet.MASTER_DISCOUNT_VALUE ? localParam.PartIssueDet.MASTER_DISCOUNT_VALUE : 0;
    //this.taxCalculateParam.SpareTaxDet.MANUAL_DISCOUNT = localParam.PartIssueDet.MANUAL_DISCOUNT ? localParam.PartIssueDet.MANUAL_DISCOUNT : 0;
    this.taxCalculateParam.SpareTaxDet.LocationId = this.refLocationId;
    this.taxCalculateParam.SpareTaxDet.DocumentDate = localParam.PartIssueDet.MANUAL_DISCOUNT;
    this.taxCalculateParam.SpareTaxDet.Part_Cost = localParam.PartIssueDet.Part_Cost;
    //this.taxCalculateParam.SpareTaxDet.COST_PLUS_MARGIN = 0;
    //this.taxCalculateParam.SpareTaxDet.AMC_DISC_AMT = 0;
    this.taxCalculateParam.SpareTaxDet.COST_PLUS_MARGIN = localParam.PartIssueDet.COST_PLUS_MARGIN;
    this.taxCalculateParam.SpareTaxDet.COST_PLUS = localParam.PartIssueDet.COST_PLUS;
    this.taxCalculateParam.SpareTaxDet.Qty = localParam.PartIssueDet.REQ_QTY; //localParam.REQ_QTY;
    this.taxCalculateParam.SpareTaxDet.AMC_DISC_AMT = localParam.PartIssueDet.AMC_DISC_AMT ? localParam.PartIssueDet.AMC_DISC_AMT : 0;
    //this.taxCalculateParam.SpareTaxDet.MASTER_DISC_PERC = localParam.PartIssueDet.MASTER_DISC_PERC ? localParam.PartIssueDet.MASTER_DISC_PERC : 0;
    //this.taxCalculateParam.SpareTaxDet.SchemeDiscount_Per = localParam.PartIssueDet.SchemeDiscount_Per ? localParam.PartIssueDet.SchemeDiscount_Per : 0;
    //this.taxCalculateParam.SpareTaxDet.MANUAL_DISCOUNT_PERC = localParam.PartIssueDet.MANUAL_DISCOUNT_PERC ? localParam.PartIssueDet.MANUAL_DISCOUNT_PERC : 0;
    this.taxCalculateParam.SpareTaxDet.GST_IN_NO = this.custGstNo;
    this.calculateTax(this.refPartList.indexOf(localParam));
  }
  /**
   * Validating returned and issued quantity
   * @param index 
   */
  returnQty(index) {
    if (Number(this.refPartList[index].PartIssueDet.QTY_TobeReturned)) {
      //  this.isRootDisabled = false;

      // this.isDisablePart = false;
      if (this.refPartList[index].PartIssueDet.QTY_TobeReturned > this.refPartList[index].PartIssueDet.NetISSUED_QTY) {
        this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_RETQTY);
        this.refPartList[index].PartIssueDet.QTY_TobeReturned = 0;
      }
    }
    else {
      this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_REQTUANTITY);
      this.refPartList[index].PartIssueDet.QTY_TobeReturned = 0;
    }
  }
  /**
   * Calculating tax after entering rack details
   * @param {Number} index
   * If tax applicable only tax will gets calculate(ITEM_TAX_CAT_ID exist)
   */

  calculateTax(index) {
    this.isShowPageLoader = true;
    if (this.refPartList[index].PartIssueDet.ITEM_TAX_CAT_ID != 2 || this.refPartList[index].PartIssueDet.ITEM_TAX_CAT_ID != 8) {
      this.jobCardService.calculateSpareTaxNew(this.taxCalculateParam.SpareTaxDet).subscribe((resp: any) => {
        if (resp && resp.statusCode == 200 && resp.data) {
          this.refPartList[index].PartIssueDet.CGST = resp.data.CGST;
          this.refPartList[index].PartIssueDet.SGST = resp.data.SGST;
          this.refPartList[index].PartIssueDet.IGST = resp.data.IGST;
          this.refPartList[index].PartIssueDet.UTGST = resp.data.UTGST;
          this.refPartList[index].PartIssueDet.TAX_FIELD1 = resp.data.TAX_FIELD1;
          this.refPartList[index].PartIssueDet.TAX_VALUE = resp.data.TAX_VALUE;
          this.refPartList[index].PartIssueDet.TAX_RATE = resp.data.TAX_RATE;
          this.refPartList[index].PartIssueDet.DISC_AMT = resp.data.MASTER_DISCOUNT_VALUE ? resp.data.MASTER_DISCOUNT_VALUE : 0;
          this.refPartList[index].PartIssueDet.DISC_VALUE = resp.data.SchemeDiscount_Value ? resp.data.SchemeDiscount_Value : 0;
          this.refPartList[index].PartIssueDet.Rate = resp.data.Rate;
          this.refPartList[index].PartIssueDet.isTaxable = true;
          this.savePartItem(this.refPartList[index]);
        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    } else {
      this.savePartItem(this.refPartList[index]);
      this.isShowPageLoader = false;
    }
  }
  getIsValidPart() {
    if (this.getPartCount() > 0 && this.isValidPart.dirty && this.isValidPart.valid && !this.isDisablePart) {
      return true;
    } else {
      return false;
    }
  }
  getPartCount(): any {
    let partCount = this.refPartList.filter(val => {
      return val.PartIssueDet.SPARE_PART_ID && val.PartIssueDet.MODE_ID && val.PartIssueDet.REQ_QTY;
    });
    return partCount.length;
  }
  /**
   * 
   * @param savePartParam 
   */
  calculatePartAmt() {
    let totalPart: number = 0;
    let temp1: number = 0;
    let Amount: number = 0;
    // let lineUnitPrice: number = 0;
    // let lineTaxValue: number = 0;
    // let lineDiscValue: number = 0;

    let totalPartValue: number = 0;
    let totalTaxValue: number = 0;
    if (this.refPartList && this.refPartList.length > 0) {
      this.refPartList.filter(val => {
        if (val.PartIssueDet.SPARE_PART_ID && val.PartIssueDet.MODE_ID && (val.PartIssueDet.MODE_ID != 2 && val.PartIssueDet.MODE_ID != 3 && val.PartIssueDet.MODE_ID != 4 && val.PartIssueDet.MODE_ID != 5) && val.PartIssueDet.NetISSUED_QTY) {
          //totalPartValue += val.PartIssueDet.MRP ? parseFloat(val.PartIssueDet.MRP) * parseFloat(val.PartIssueDet.NetISSUED_QTY) : 0;
          //ADD
          let lineUnitPrice: number = 0;
          let lineTaxValue: number = 0;
          let lineUnitwithTax: number = 0;
          let lineDiscValue: number = 0;
          let lineUnitMinusDisc: number = 0;


          lineUnitPrice = val.PartIssueDet.UNIT_PRICE ? parseFloat(val.PartIssueDet.UNIT_PRICE) * parseFloat(val.PartIssueDet.NetISSUED_QTY) : 0;
          lineTaxValue = val.PartIssueDet.TAX_VALUE ? parseFloat(val.PartIssueDet.TAX_VALUE) : 0;
          lineUnitwithTax = lineUnitPrice + lineTaxValue;
          if (val.PartIssueDet.SchemeDiscount_Value) {
            lineDiscValue = val.PartIssueDet.SchemeDiscount_Value;
            lineUnitMinusDisc = lineUnitwithTax - lineDiscValue;
          }
          else {
            lineUnitMinusDisc = lineUnitwithTax;
          }

          if (lineUnitMinusDisc > 0) {
            totalPartValue += lineUnitMinusDisc;
          }

          totalPart += val.PartIssueDet.NetISSUED_QTY ? parseFloat(val.PartIssueDet.NetISSUED_QTY) : 0;
          // //END

          // if (val.PartIssueDet.DISC_AMT > 0)
          // {

          //   temp1 =  ((( (val.PartIssueDet.ISSUED_QTY) -  (val.PartIssueDet.RET_QTY)) *  (val.PartIssueDet.UNIT_PRICE)) - (( (val.PartIssueDet.ISSUED_QTY) -  (val.PartIssueDet.RET_QTY)) * (( (val.PartIssueDet.DISC_AMT) / ( (val.PartIssueDet.ISSUED_QTY) -  (val.PartIssueDet.RET_QTY)))) -  (val.PartIssueDet.DISC_VALUE)));

          // }
          // else
          // {

          //   temp1 =  ((( (val.PartIssueDet.ISSUED_QTY) -  (val.PartIssueDet.RET_QTY)) *  (val.PartIssueDet.UNIT_PRICE)) -  (val.PartIssueDet.DISC_VALUE));

          // }
          // Amount +=  (temp1) + ( (val.PartIssueDet.TAX_VALUE));
          //temp = (temp1 * Convert.ToDecimal(item.TAX_RATE)) / ProjectConstants.iHundred;
          // Amount += Convert.ToDecimal(temp1) + (Convert.ToDecimal(item.TAX_VALUE));
        }
      });
    } else {
      this.refPartList = [];
    }
    this.totalPart = totalPart.toFixed(2);
    this.totalPartValue = totalPartValue.toFixed(2);
    this.totalPartVal.emit(this.totalPartValue);
    this.getPartCount();
  }
  /**
   * Save Issue Part
   * @param savePartParam 
   */
  savePartItem(savePartParam) {
    if (savePartParam.PartIssueDet.MODE_ID == 4 && !savePartParam.PartIssueDet.JUSTIFICATION) {
      this.toastr.error(this.lang.Message.JOB_CARD.MAND_JUSTIFICATION);
      savePartParam.PartIssueDet.REQ_QTY = '';
      this.isShowPageLoader = false;
    }
    else {
      savePartParam.PartIssueDet.REQ_QTY_ENTERED = savePartParam.PartIssueDet.REQ_QTY;
      //savePartParam.PartIssueDet.Rate = savePartParam.PartIssueDet.MRP - savePartParam.PartIssueDet.TAX_VALUE;
      savePartParam.PartIssueDet.DEALER_ID = CommonService.getUserData().DEALER_ID;
      savePartParam.PartIssueDet.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
      savePartParam.PartIssueDet.LOCATION_ID = this.refLocationId;
      savePartParam.SparePricePickup = CommonService.getDealerData().SparePricePickup;
      savePartParam.UserId=CommonService.getUserData().USER_ID;
      savePartParam.DealerID=CommonService.getUserData().DEALER_ID;
      savePartParam.BranchID=CommonService.getUserData().BRANCH_ID;
      this.isShowPageLoader = true;
      this.jobCardService.saveJobCardPart(savePartParam).subscribe((resp: any) => {
        if (resp && resp.statusCode && resp.statusCode == 200) {
          this.toastr.success(this.lang.Message.JOB_CARD.PART_SUCCESS);
          if (this.isRefreshParts) {
            this.getPartDetailsByJcId();
            this.isRefreshParts = false;
          }
        } else {
          this.toastr.error(resp.message);
          this.isShowPageLoader = false;
        }
        this.isDisablePart = true;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }
  /**
   * Validate Part
   * @param {Object} parts
  */
  validatePart(parts) {
    let valid = true;
    if (parts.length == 0) {
      this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_PART);
      return valid = false;
    }
    if (parts.length > 0) {
      parts.filter(val => {
        if (!val.PartIssueDet.SPARE_PART_ID || val.PartIssueDet.SPARE_PART_ID <= -1
          || !val.PartIssueDet.MODE_ID || val.PartIssueDet.MODE_ID <= -1 || !val.PartIssueDet.NetISSUED_QTY || val.PartIssueDet.NetISSUED_QTY <= -1) {
          this.toastr.warning(this.lang.Message.JOB_CARD.INVALID_PARTNO);
          return valid = false;
        }
      });
    }
    return valid;
  }
  /**
    * Save all parts at a time
    */
  bulkSave() {
    // if (this.validatePart(this.refPartList)) {

    let bulkSaveParam = JSON.parse(JSON.stringify(this.refPartList));
    let partRetList = [];
    let partIssueList = [];
    let recallRefitPartsList = [];//Added By Sapna for Recall Refit
    this.invalgoodwillpart = false;
    bulkSaveParam.filter(chk => {
      if (chk.PartIssueDet.MODE_ID == 4 && !chk.PartIssueDet.JUSTIFICATION) {
        this.toastr.error(this.lang.Message.JOB_CARD.MAND_JUSTIFICATION);
        this.isShowPageLoader = false;
        this.invalgoodwillpart = true;
      }
    });

    if (this.invalgoodwillpart == false) {
      bulkSaveParam.filter(val => {
        if (val.PartIssueDet.SPARE_PART_ID > 0) {
          if (val.PartIssueDet.QTY_TobeReturned > 0) {
            partRetList.push({
              DEALER_ID: CommonService.getUserData().DEALER_ID,
              BRANCH_ID: CommonService.getUserData().BRANCH_ID,
              SPARE_PART_ID: val.PartIssueDet.SPARE_PART_ID,
              LOCATION_ID: val.PartIssueDet.LOCATION_ID,
              RACK_ID: val.PartIssueDet.RACK_ID,
              MRP: val.PartIssueDet.MRP,
              PART_RK_BN_ID: val.PartIssueDet.PART_RK_BN_ID,
              QTY_TobeReturned: val.PartIssueDet.QTY_TobeReturned,
              JCARD_SPARE_ID: val.PartIssueDet.JCARD_SPARE_ID,
              RackNo: val.PartIssueDet.RACK_ID,
              PartRKBNID: val.PartIssueDet.PART_RK_BN_ID,
              RequiredQty: val.PartIssueDet.REQ_QTY ? val.PartIssueDet.REQ_QTY : 0,
              ReturnQty: val.PartIssueDet.QTY_TobeReturned,
              MODIFIED_BY: CommonService.getUserData().USER_ID,
              MODE_ID: val.PartIssueDet.MODE_ID,
              ISSUED_QTY: val.PartIssueDet.NetISSUED_QTY ? val.PartIssueDet.NetISSUED_QTY : 0,
              RET_QTY: val.PartIssueDet.QTY_TobeReturned,
              JUSTIFICATION: val.PartIssueDet.JUSTIFICATION,
              IS_JUSTIFY_CHANGE: val.PartIssueDet.JUSTIFICATION && val.PartIssueDet.JUSTIFICATION != '' ? true : false,
            });
          }
          partIssueList.push({
            DEALER_ID: CommonService.getUserData().DEALER_ID,
            BRANCH_ID: CommonService.getUserData().BRANCH_ID,
            JCARD_SPARE_ID: val.PartIssueDet.JCARD_SPARE_ID,
            SPARE_PART_ID: val.PartIssueDet.SPARE_PART_ID,
            PART_RK_BN_ID: val.PartIssueDet.PART_RK_BN_ID,
            MODE_ID: val.PartIssueDet.MODE_ID,
            JUSTIFICATION: val.PartIssueDet.JUSTIFICATION,
            IS_JUSTIFY_CHANGE: val.PartIssueDet.JUSTIFICATION && val.PartIssueDet.JUSTIFICATION != '' ? true : false,
          });
          //Added for Recall Refit
          recallRefitPartsList.push({
            DEALER_ID: CommonService.getUserData().DEALER_ID,
            BRANCH_ID: CommonService.getUserData().BRANCH_ID,
            FRAME_NO: this.jobCardDo.FRAME_NO,
            PART_NO: val.PartIssueDet.PART_NO,
            PART_RK_BN_ID: val.PartIssueDet.PART_RK_BN_ID,
            MODE_ID: val.PartIssueDet.MODE_ID,
            JUSTIFICATION: val.PartIssueDet.JUSTIFICATION,
            IS_JUSTIFY_CHANGE: val.PartIssueDet.JUSTIFICATION && val.PartIssueDet.JUSTIFICATION != '' ? true : false,
          });
        }
      });
      let reqObj = {
        PartReturnList: partRetList,
        PartIssueChangeList: partIssueList,
        CountryCode: CommonService.getDealerData().COUNTRY_CODE,
        DealerID: CommonService.getUserData().DEALER_ID,
        BranchID: CommonService.getUserData().BRANCH_ID,
        UserId: CommonService.getUserData().USER_ID
      }
      let req = {
        recallRefitParts: recallRefitPartsList,
        DealerID: CommonService.getUserData().DEALER_ID,
        BranchID: CommonService.getUserData().BRANCH_ID,
        UserId: CommonService.getUserData().USER_ID
      }
      this.isShowPageLoader = true;


      this.jobCardService.bulkSave(reqObj).subscribe((resp: any) => {
        if (resp && resp.statusCode && resp.statusCode == 200) {
          this.toastr.success(this.lang.Message.JOB_CARD.PART_SUCCESS);
          this.jobCardService.updateRecallRefit(req).subscribe((resp: any) => {
            if (resp && resp.statusCode && resp.statusCode == 200) {
              this.toastr.success("Recall Refit Parts are Updated successfully");
            }
          });
          this.getPartDetailsByJcId();
        } else {
          this.toastr.error(resp.message);
          this.isShowPageLoader = false;
        }
        this.isDisablePart = true;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }

  allowQuantity(evt, type) {
    evt = evt || window.event;
    var charCode = evt.which || evt.keyCode;
    var charStr = String.fromCharCode(charCode);
    if (/^[0-9]{1,100000}$/.test(charStr) && (type == 'EA' || type == 'each')) {
      return true;
    }
    else if (/^[0-9.,]+$/.test(charStr) && (type != 'EA' && type != 'each')) {
      return true;
    } else {
      return false;
    }
  }


  LoadDefParts() {
    let params = new HttpParams();
    params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
      .set('BranchID', CommonService.getUserData().BRANCH_ID)
      .set('CustCode', this.jcId)
      .set('LocationID', this.refLocationId)//.set('LocationID', '1')
      //.set('LocationName', 'spare')
      .set('StateId', CommonService.getDealerData().STATE_ID)
      .set('CountryCode', CommonService.getDealerData().COUNTRY_CODE)
      .set('Jcardid', this.jcId)
      .set('UserId', CommonService.getUserData().USER_ID);
    this.jobCardService.LoadDefIssueParts(params).subscribe((resp: any) => {
      if (resp && resp.data && resp.statusCode === 200) {

        this.partNoList = this.refPartList;
        // this.refPartList = []; 
        resp.data.filter(key => {
          this.partexists = false;
          //this.partNoList.filter(key11 => {

          //  if (key11.PartIssueDet.part_no !=key.part_no) {          
          //  this.partexists = true;      
          //}   
          // else{this.partexists = false;   }
          // });
          this.partexists = false;
          for (var keydd in this.refPartList) {
            if (this.refPartList[keydd].PartIssueDet.PART_NO == key.PART_NO) {
              this.partexists = true;
            }
          }
          if (this.partexists == false) {
            let savePart = new SavePart();
            let partIssueDet = new PartIssueDet();
            let jobCardDet = new JobCardDet();
            partIssueDet.SPARE_PART_ID = key.SPARE_PART_ID;
            partIssueDet.REQ_QTY = key.REQ_QTY;
            partIssueDet.NetREQ_QTY = key.NetREQ_QTY;
            partIssueDet.UNIT_PRICE = key.UNIT_PRICE;
            partIssueDet.MRP = key.MRP;
            partIssueDet.Part_Cost = key.Part_Cost;
            partIssueDet.MANUF_ID = key.MANUF_ID;
            partIssueDet.isTaxable = key.isTaxable;
            partIssueDet.MODE_ID = 1;
            partIssueDet.PART_NO = key.PART_NO;
            partIssueDet.PART_DESCRIPTION = key.PART_DESCRIPTION;
            partIssueDet.NetISSUED_QTY = key.NetISSUED_QTY;
            partIssueDet.RET_QTY = key.RET_QTY;
            partIssueDet.LOCATION_ID = this.refLocationId;
            partIssueDet.QTY_TobeReturned = key.QTY_TobeReturned;
            partIssueDet.SchemeDiscount_Value = key.SchemeDiscount_Value;
            partIssueDet.MASTER_DISCOUNT_VALUE = key.MASTER_DISCOUNT_VALUE;
            partIssueDet.CGST = key.CGST;
            partIssueDet.SGST = key.SGST;
            partIssueDet.IGST = key.IGST;
            partIssueDet.UTGST = key.UTGST;
            partIssueDet.TAX_FIELD1 = key.TAX_FIELD1;
            partIssueDet.HSN_CODE = key.HSN_CODE;
            partIssueDet.UOM_ID = key.UOM_ID;
            partIssueDet.ITEM_TYPE_ID = key.ITEM_TYPE_ID;
            partIssueDet.RACK_ID = key.RACK_ID ? key.RACK_ID : 0;
            partIssueDet.PART_RK_BN_ID = key.PART_RK_BN_ID ? key.PART_RK_BN_ID : 0;
            partIssueDet.JCARD_SPARE_ID = key.JCARD_SPARE_ID ? key.JCARD_SPARE_ID : 0;
            partIssueDet.ITEM_TAX_CAT_ID = key.ITEM_TAX_CAT_ID;
            partIssueDet.IS_ZMC = key.IS_ZMC;
            partIssueDet.PART_TYPE_ID = key.PART_TYPE_ID;
            partIssueDet.WARRANTY_APPLICABLE = key.WARRANTY_APPLICABLE;
            savePart.CountryCode = CommonService.getDealerData().COUNTRY_CODE;
            savePart.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
            jobCardDet.JOB_CARD_ID = this.jcId;
            savePart.PartIssueDet = partIssueDet;
            savePart.JobCardDet = jobCardDet;
            this.refPartList.push(savePart);
            //this.addPart();       
            this.calculatePartAmt();
            // this.addPart();
          }
        });
      } else {
        this.toastr.error(resp.d.message);
      }
      this.addPart();
      if (resp && resp.statusCode == 401) {
        this.loginService.logout();
      }
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
      if (error.status == 401) {
        this.loginService.logout();
      }
    });

  }
  /**
   * Check Recall Refit
   */
  checkRecallRefit() {
    if (this.jobCardDo.JOB_CARD_ID == undefined || this.jobCardDo.JOB_CARD_ID == null) {
      this.jobCardDo.JOB_CARD_ID = 0;
    }
    if (this.jobCardDo.FRAME_NO != '') {
      this.isShowPageLoader = true;
      let params = new HttpParams();
      params = params.set('frameNo', this.jobCardDo.FRAME_NO)
        .set('jobCardNo', this.jobCardDo.JOB_CARD_ID.toString())
        .set('DealerId', CommonService.getUserData().DEALER_ID)
        .set('BranchId', CommonService.getUserData().BRANCH_ID)
        .set('isIssueSpares', "true")
        .set('UserId', CommonService.getUserData().USER_ID)
      this.jobCardService.getRecallRefitStatus(params).subscribe((resp: any) => {
        if (resp && resp.data && resp.statusCode === 200) {
          
          this.RecallRefit = resp.data.recallRefit;
          this.RecallRefitRejectReason = resp.data.recallRefitReasonDo;
          localStorage.setItem('rejectReason', JSON.stringify(this.RecallRefitRejectReason));
          if (this.RecallRefit.ACTIVE == true) {
            this.isShowPageLoader = true;
            let params = new HttpParams();
            params = params.set('frameNo', this.jobCardDo.FRAME_NO)
            .set('DealerId', CommonService.getUserData().DEALER_ID)
            .set('BranchId', CommonService.getUserData().BRANCH_ID)
            .set('UserId', CommonService.getUserData().USER_ID);
            this.jobCardService.getRecallRefitParts(params).subscribe((resp: any) => {
              if (resp && resp.data && resp.statusCode === 200) {
                debugger;
                this.RecallRefitParts = resp.data;
                var i: number = 0
                for (i = 0; i < this.RecallRefitParts.length; i++) {
                  this.refPartList.forEach(x => {
                    debugger;
                    if (x.PartIssueDet.PART_NO == this.RecallRefitParts[i].PART_NO) {
                      if (x.PartIssueDet.NetREQ_QTY == 0) {
                        debugger;
                        this.RecallRefitParts[i].REMARKS = this.RecallRefit.REMARKS;
                        const dialogRef = this.dialog.open(RecallRefitDialogComponent, {
                          width: '500px',
                          data: { userData: this.RecallRefitParts[i] },
                          disableClose: true
                        });
                        dialogRef.afterClosed().subscribe(result => {
                          if (result && result.length > 0) {
                          }
                        });
                      }
                    }
                  });
                }
              } else {
                this.toastr.error(resp.message);
              }
            });
          }
        }
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }
}

/**
 * Created BY HMSPL 
 * Last Last Modified By Licko on 21/04/2018
 * Last Modified Description : Added Comment
 */
 import { NgModule } from '@angular/core';
 import { CommonModule } from '@angular/common';
 // import { HttpClientModule } from '@angular/common/http';
 import { NgxDatatableModule } from '@swimlane/ngx-datatable';
 import { AccordionModule, TabsModule } from 'ngx-bootstrap';
 import { DropdownModule } from 'primeng/dropdown';
 import { CalendarModule } from 'primeng/calendar';
 import { Ng2FilterPipeModule } from 'ng2-filter-pipe';
 import { RouterModule } from '@angular/router';
 import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 import { ColorPickerModule } from 'ngx-color-picker';
 import {OnlyAlphaNumericDirective} from '../directive/only-alpha-numeric.directive';
 import {BlockSpecialCharsDirective} from '../directive/block-special-chars.directive';
 import { PndeowDisplayComponent } from './pndeow-display/pndeow-display.component';
 
 /**
  * Custom Compoments
  */
 import {
   MatStepperModule,
   MatMenuModule,
   MatIconModule,
   MatCardModule,
   MatDatepickerModule,
   MatDialogModule,
   MatInputModule,
   MatProgressSpinnerModule,
   MatProgressBarModule,
   MAT_DIALOG_DEFAULT_OPTIONS,
   MatTabsModule,
   MatSlideToggleModule,
   MAT_DIALOG_DATA,
   MatDialogRef,
 } from '@angular/material';
 import { MatAutocompleteModule } from '@angular/material/autocomplete';
 import {MatTooltipModule} from '@angular/material/tooltip';
 import { AutoCompleteModule } from 'primeng/autocomplete';
 
 /**
  * Custom Pipes
  */
 import { PrimeDropdownClose } from '../directive/p-dropdown-close.directive';
 import { OnlyNumber, RestrictSpace } from '../directive/onlyNumber.directive';
 import { DebounceDirective } from '../directive/input-debounce.directive';
 
 /**
  * Custom Pipes
  */
 import { UnitsPipe } from '../pipes/units.pipe';
 import { MomentDatePipe, MomentFilterDatePipe } from '../pipes/moment-date.pipe';
 import { CapitalizePipe } from '../pipes/capitalize.pipe';
 import { SearchPipe } from '../pipes/custom-filter.pipe';
 /**
  * Custom Components
  */
 import { ExcelUploadComponent } from './excel-upload/excel-upload.component';
 import { NavBarComponent, LogoutDialogComponent } from './nav-bar/nav-bar.component';
 import { QuickDetailComponent } from './quick-detail/quick-detail.component';
 // import { InlineTableComponent } from './inline-table/inline-table.component';
 // import { SubmenuPopupComponent } from './submenu.component';
 import { FilterCardComponent } from './filter-card/filter-card.component';
 import { CreateCustomerComponent } from './create-customer/create-customer.component';
 import { FilterInputComponent, FilterSelectComponent, FilterDateComponent } from './table-filter/table-filter.component';
 import { CustomiseTableComponent } from './customise-table/customise-table.component';
 import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
 import { IssueLabourComponent } from './issue-labour/issue-labour.component';
 import { IssuePartsComponent } from './issue-parts/issue-parts.component';
 import { PageLoaderComponent } from '../shared/page-loader/page-loader';
 import { ServiceAppointmentCreateComponent } from '../service/service-appointment/service-appointment-create/service-appointment-create.component';
 import { RackComponent } from './rack/rack.component';
 import { AscFscClaimDialogComponent } from '../service/claims/asc-fsc-claim/asc-fsc-claim-create/asc-fsc-claim-dialog.component';
 import { ViewComplaintComponent } from '../service/job-card/view-complaint/view-complaint';
 import { JobCardSearchComponent } from './job-card-search/job-card-search.component';
 import { ListCardComponent } from './list-card/list-card.component';
 import { AdvancedSearchComponent } from '../service/service-follow-up/advanced-search/advanced-search.component';
 import { MatSelectModule } from '@angular/material';
 import { PhotoContainerComponent } from './upload-images';
 import { ThemeDirective } from '../directive/theme-directive';
 import { ColorPickerComponent } from './color-picker/color-picker.component';
 import { PaymentVoucherComponent ,CustomDialog} from './payment-voucher/payment-voucher.component';
 import { ReceiptVoucherComponent } from './receipt-voucher/receipt-voucher.component';
 import { VendorSearchComponent } from './vendor-search/vendor-search.component';
 import { PartSearchComponent } from './part-search/part-search.component';
 //import {PaymentVoucherComponent} from '../shared/payment-voucher/payment-voucher.component';
 import { GrnApsSearchComponent } from './grn-aps-search/grn-aps-search.component';
 import { InvoiceComponent } from './invoice/invoice.component';
 import { AddLocationComponent } from './add-location/add-location.component';
 import { from } from 'rxjs';
 import { SalesSearchComponent } from './sales-search/sales-search.component';
 import { SalesEnquiryComponent } from './sales-enquiry/sales-enquiry.component';
 import { ViewSalesScheme } from './sales-scheme/sales-scheme';
 import { ViewSpareScheme } from './spare-scheme/spare-scheme';
 import { SalesAllotmentComponent } from './sales-allotment/sales-allotment.component';
 import { SalesAccessoriesComponent } from './sales-accessories/sales-accessories.component';
 import { SalesHpHistoryComponent } from './sales-hp-history/sales-hp-history.component';
 import { VehicleSchemeComponent } from './vehicle-scheme/vehicle-scheme.component';
 import { RegistrationInsuranceInvoiceComponent } from './registration-insurance-invoice/registration-insurance-invoice.component';
 import { SalesMapInvoiceComponent } from './sales-map-invoice/sales-map-invoice.component';
 import { SalesCICComponent } from './sales-cic/sales-cic.component';
 import { SalesCreateCustomerComponent } from './sales-create-customer/sales-create-customer.component';
 import { OnlineReceiptVoucherComponent } from './online-receipt-voucher/online-receipt-voucher.component';
 import { UpdateJobcardStatusComponent } from './updatejobcard-status/updatejobcard-status.component';
 import { UserMappingComponent } from './user-mapping/user-mapping.component';
 import { WarrantyPrintComponent } from './warranty-print/warranty-print.component';
 import { InsurancePolicyComponent } from './insurance-policy/insurance-policy.component';
 import { InformationDialogComponent } from './information-dialog/information-dialog.component';
 import { AttendanceScreenComponent } from './attendance-screen/attendance-screen.component';
 import { RecallRefitDialogComponent } from './recall-refit-dialog/recall-refit-dialog.component';
 import { RecallRefitRejectionComponent } from './recall-refit-rejection/recall-refit-rejection.component';
 import { MandatoryPartsComponent } from './mandatory-parts/mandatory-parts.component';
 import { RejectRemarksComponent } from './reject-remarks/reject-remarks.component';
 import { RescheduleComponent } from './reschedule/reschedule.component';
 import { PndeowComponent } from './pndeow/pndeow.component';
 import { CommonConfirmationDialogComponent } from './common-confirmation-dialog/common-confirmation-dialog.component';
 import { SearchSalesPersonComponent } from './search-sales-person/search-sales-person.component';
 import { PmpReasonComponent } from './pmp-reason/pmp-reason.component';
 import { DigiSignComponent } from './digi-sign/digi-sign.component';
 import { DigiGeneratePdfComponent } from './digi-generate-pdf/digi-generate-pdf.component';
 import {SalesVehicleReturnComponent} from '../sales/return-process/vehicle-return/sales-vehicle-return/sales-vehicle-return.component';
 import { MobileOtpDialogComponent } from './mobile-otp-dialog/mobile-otp-dialog.component';
 import { AppsettingsService } from './appsettings.service';
 import { AppSettings } from './appsettings';
 import {SparesGrnPaymentComponent} from './spares-grn-payment/spares-grn-payment.component';
 import {MiscellanousPurchaseJvComponent} from './miscellanous-purchase-jv/miscellanous-purchase-jv.component'
 import { BookingAdvanceVoucherComponent } from './booking-advance-voucher/booking-advance-voucher.component';
 import { JobcardAdvanceVoucherComponent } from './jobcard-advance-voucher/jobcard-advance-voucher.component';
 import {VehicleSalesReturnPaymentComponent} from './vehicle-sales-return-payment/vehicle-sales-return-payment.component';
 import {JobCardRefundsComponent} from './job-card-refund/job-card-refund.component';
 import {StockTranferGrn} from './stock-transfer-grn/stock-transfer-grn.component';
 import {InvoiceDetailHpVoucherComponent} from './invoice-detail-hp-voucher/invoice-detail-hp-voucher.component';
 import{SparesGrnPaymentJvComponent} from './spare-grn-payment-jv/spare-grn-payment-jv.component';
 import {VendorPaymentJvComponent} from './vendor-payment-jv-component/vendor-payment-jv.component';
 import {InsurancePaymentComponent} from './insurance-payment/insurance-payment.component';
 import {RegisterPaymentComponent} from './registration-payment/registration-payment.component';
 import{AscWarrantyClaimPaymentComponent} from './asc-warranty-claim-payment/asc-warranty-claim-payment.component';
 import {AscCouponClaimPaymentComponent} from './asc-coupon-claim-payment/asc-coupon-claim-payment.component';
 import {SpareCommissionPaymentComponent} from './spare-commission-payment/spare-commission-payment.component';
 import{VehicleAccessoriesReturnPaymentComponent} from './vehicle-accessories-return-payment/vehicle-accessories-return-payment.component';
 import {SpareSalesReturnPaymentComponent} from './spare-sales-return-payment/spare-sales-return-payment.component';
 import {VehicleCommissionPaymentComponent} from './vehiclecommissionpayment/vehicle-commission-payment.component';
 import { AmcReceiptVoucherComponent } from './amc-receipt-voucher/amc-receipt-voucher.component';
 import { VehicleReceiptVoucherComponent } from './vehicle-receipt-voucher/vehicle-receipt-voucher.component';
 import { ServiceReceiptVoucherComponent } from './service-receipt-voucher/service-receipt-voucher.component';
 import { SparesReceiptVoucherComponent } from './spares-receipt-voucher/spares-receipt-voucher.component';
import { GeneralLedgerComponent } from './general-ledger/general-ledger.component';
import {CommisionPaymentComponent} from './commision-payment/commision-payment.component';
import { BookingAdvanceRefundComponent } from './booking-advance-refund/booking-advance-refund.component';
import { VendorBillPayment } from './vendor-bill-payment/vendor-bill-payment.component';
import { CreateRepairRequestComponent } from './create-repair-request/create-repair-request.component';

import { AscCouponClaimComponent } from './asc-coupon-claim/asc-coupon-claim.component';
import { AscWarrantyClaimComponent } from './asc-warranty-claim/asc-warranty-claim.component';
import { VendorOutworkBillComponent } from './vendor-outwork-bill/vendor-outwork-bill.component';
import { ExcessClaimCreditComponent } from './excess-claim-credit/excess-claim-credit.component';
import { SpareClaimDebitNoteComponent } from './spare-claim-debit-note/spare-claim-debit-note.component';
import { DebitCouponWarrantyClaimComponent } from './debit-coupon-warranty-claim/debit-coupon-warranty-claim.component';
import { DebitnoteWarrantyClaimComponent } from './debitnote-warranty-claim/debitnote-warranty-claim.component';
import { SearchBankComponent } from './search-bank/search-bank.component';
import {CreditNoteCommPayComponent} from'./credit-note-comm-pay/credit-note-comm-pay.component';
import { FrameNumberSearchComponent } from './frame-number-search/frame-number-search.component';
import { SearchCreditDebitNumComponent } from 'src/app/shared/search-credit-debit-num/search-credit-debit-num.component';
import { ReportPopupComponent } from './report-popup/report-popup.component';
import { CreateRackMasterComponent } from './create-rack-master/create-rack-master.component';
import { GrnOthersExcelUploadComponent } from './grn-others-excel-upload/grn-others-excel-upload.component';
import { GrnPartCostComponent } from './grn-part-cost/grn-part-cost.component';
import { CreateDealerMasterComponent } from './create-dealer-master/create-dealer-master.component';
import { CreateBranchMasterComponent } from './create-branch-master/create-branch-master.component';
import { AddCompanyDetailsComponent } from './add-company-details/add-company-details.component';
import { VehicleMasterCreateComponent } from './vehicle-master-create/vehicle-master-create.component';
import { DeliveryChalanNoSearchComponent } from './delivery-chalan-no-search/delivery-chalan-no-search.component';
import { DeliveryChalanNoSearchGrnComponent } from './delivery-chalan-no-search-grn/delivery-chalan-no-search-grn.component';
import { DealerOnboardComponent } from './dealer-onboard/dealer-onboard.component';
import { WarehouseCustomerMasterComponent } from './warehouse-customer-master/warehouse-customer-master.component';
import { CreateWarehouseMasterComponent } from './create-warehouse-master/create-warehouse-master.component';
import { WarehousePartCostComponent } from './warehouse-part-cost/warehouse-part-cost.component';
import { WarehousePartPriceComponent } from './warehouse-part-price/warehouse-part-price.component';
import { PartCostComponent } from './part-cost/part-cost.component';
import { PartPriceComponent } from './part-price/part-price.component';
import { WarehouseExcelUploadComponent } from './warehouse-excel-upload/warehouse-excel-upload.component';
import { VehicleProcurementExcelUploadComponent } from './vehicle-procurement-excel-upload/vehicle-procurement-excel-upload.component';
import { CreateUomMasterComponent } from './create-uom-master/create-uom-master.component';
import { CreateHsnCodeMasterComponent } from './create-hsn-code-master/create-hsn-code-master.component';
import { CreateHsnPercentageMasterComponent } from './create-hsn-percentage-master/create-hsn-percentage-master.component';
import { CreateBrandMasterComponent } from './create-brand-master/create-brand-master.component';
import { VehicleExcelBulkUploadComponent } from './vehicle-excel-bulk-upload/vehicle-excel-bulk-upload.component';
import { PartExcelBulkUploadComponent } from './part-excel-bulk-upload/part-excel-bulk-upload.component';

 @NgModule({
   imports: [
     CommonModule,
     NgxDatatableModule,
     MatMenuModule,
     MatIconModule,
     MatCardModule,
     MatDatepickerModule,
     MatProgressSpinnerModule,
     MatProgressBarModule,
     MatDialogModule,
     MatTooltipModule,
     DropdownModule,
     MatAutocompleteModule,
     MatInputModule,
     ReactiveFormsModule,
     AccordionModule.forRoot(),
     TabsModule.forRoot(),
     Ng2FilterPipeModule,
     FormsModule,
     CalendarModule,
     AutoCompleteModule,
     RouterModule,
     MatSelectModule,
     ColorPickerModule,
     MatTabsModule,
     MatSlideToggleModule,
     MatStepperModule
   ],
   declarations: [
      OnlyAlphaNumericDirective,
     BlockSpecialCharsDirective,
     ExcelUploadComponent,
    
     OnlyNumber,
     PrimeDropdownClose,
     NavBarComponent,
     LogoutDialogComponent,
     DebounceDirective,
     FilterCardComponent,
     UnitsPipe,
     MomentDatePipe,
     MomentFilterDatePipe,
     QuickDetailComponent,
     CreateCustomerComponent,
     FilterInputComponent,
     FilterSelectComponent,
     FilterDateComponent,
     CustomiseTableComponent,
     ConfirmationDialogComponent,
     IssueLabourComponent,
     IssuePartsComponent,
     PageLoaderComponent,
     CapitalizePipe,
     ServiceAppointmentCreateComponent,
     RackComponent,
     AscFscClaimDialogComponent,
     ViewComplaintComponent,
     JobCardSearchComponent,
     ListCardComponent,
     AdvancedSearchComponent,
     PhotoContainerComponent,
     ThemeDirective,
     ColorPickerComponent,
     PaymentVoucherComponent,
     ReceiptVoucherComponent,
     VendorSearchComponent,
     PartSearchComponent,
     GrnApsSearchComponent,
     PaymentVoucherComponent,
     CustomDialog,
     RestrictSpace,
     InvoiceComponent,
     PartCostComponent,
     PartPriceComponent,
     AddLocationComponent,
     SearchPipe,
     SalesSearchComponent,
     SalesEnquiryComponent,
     ViewSalesScheme,
     ViewSpareScheme,
     SalesAllotmentComponent,
     SalesAccessoriesComponent,
     SalesHpHistoryComponent,
     VehicleSchemeComponent,
     RegistrationInsuranceInvoiceComponent,
     SalesMapInvoiceComponent,
     SalesCICComponent,
     SalesCreateCustomerComponent,
     OnlineReceiptVoucherComponent,
     UpdateJobcardStatusComponent,
     UserMappingComponent,
     WarrantyPrintComponent,
     InsurancePolicyComponent,
     InformationDialogComponent,
     AttendanceScreenComponent,
     RecallRefitDialogComponent,
     RecallRefitRejectionComponent,
       MandatoryPartsComponent,
       RejectRemarksComponent,
       RescheduleComponent,
       PndeowComponent,
     PmpReasonComponent ,
 
       CommonConfirmationDialogComponent,
       SearchSalesPersonComponent,
       DigiSignComponent,
       DigiGeneratePdfComponent,
       SalesVehicleReturnComponent,
       MobileOtpDialogComponent,
       BookingAdvanceVoucherComponent,
       StockTranferGrn,
       InvoiceDetailHpVoucherComponent,
       MiscellanousPurchaseJvComponent,
       VendorPaymentJvComponent,
       SparesGrnPaymentJvComponent,
       BookingAdvanceRefundComponent,
       VehicleSalesReturnPaymentComponent,
       SpareSalesReturnPaymentComponent,
       JobCardRefundsComponent,
       VendorBillPayment,
       CommisionPaymentComponent,
       SparesGrnPaymentComponent,
       InsurancePaymentComponent,
       RegisterPaymentComponent,
       AscWarrantyClaimPaymentComponent,
       AscCouponClaimPaymentComponent,
       VehicleAccessoriesReturnPaymentComponent,
       VehicleCommissionPaymentComponent,
       JobcardAdvanceVoucherComponent,
       SpareCommissionPaymentComponent,
       AmcReceiptVoucherComponent,
       VehicleReceiptVoucherComponent,
       ServiceReceiptVoucherComponent,
       SparesReceiptVoucherComponent,
       GeneralLedgerComponent,

       AscCouponClaimComponent,
       AscWarrantyClaimComponent,
       VendorOutworkBillComponent,
       VehicleCommissionPaymentComponent,
       SpareCommissionPaymentComponent,
       CommisionPaymentComponent,
       ExcessClaimCreditComponent,
       VendorSearchComponent,
       SpareClaimDebitNoteComponent,
       DebitCouponWarrantyClaimComponent,
       DebitCouponWarrantyClaimComponent,
       DebitnoteWarrantyClaimComponent,
       SearchBankComponent,
       PndeowDisplayComponent,
       CreditNoteCommPayComponent,
       FrameNumberSearchComponent,
       SearchCreditDebitNumComponent,
       CreateRepairRequestComponent,
       ReportPopupComponent,
       CreateRackMasterComponent,
       GrnOthersExcelUploadComponent,
       GrnPartCostComponent,
       CreateDealerMasterComponent,
       CreateBranchMasterComponent,
       AddCompanyDetailsComponent,
       VehicleMasterCreateComponent,
       DeliveryChalanNoSearchComponent,
       DeliveryChalanNoSearchGrnComponent,
       DealerOnboardComponent,
       WarehouseCustomerMasterComponent,
       CreateWarehouseMasterComponent,
       WarehousePartCostComponent,
       WarehousePartPriceComponent,
       WarehouseExcelUploadComponent,
       VehicleProcurementExcelUploadComponent,
       CreateUomMasterComponent,
       CreateHsnCodeMasterComponent,
       CreateHsnPercentageMasterComponent,
       CreateBrandMasterComponent,
       CreateHsnCodeMasterComponent,
       VehicleExcelBulkUploadComponent,
       PartExcelBulkUploadComponent
   ],
   exports: [
     OnlyAlphaNumericDirective,
     BlockSpecialCharsDirective,
     MatMenuModule,
     MatCardModule,
     ExcelUploadComponent,
     
     
     OnlyNumber,
     PrimeDropdownClose,
     DebounceDirective,
     NavBarComponent,
     LogoutDialogComponent,
     FilterCardComponent,
     UnitsPipe,
     MomentDatePipe,
     MomentFilterDatePipe,
     MatDialogModule,
     MatTooltipModule,
     FormsModule,
     NgxDatatableModule,
     QuickDetailComponent,
     MatAutocompleteModule,
     MatInputModule,
     ReactiveFormsModule,
     CreateCustomerComponent,
     CalendarModule,
     FilterInputComponent,
     FilterSelectComponent,
     FilterDateComponent,
     Ng2FilterPipeModule,
     CustomiseTableComponent,
     ConfirmationDialogComponent,
     IssuePartsComponent,
     IssueLabourComponent,
     PageLoaderComponent,
     CapitalizePipe,
     AutoCompleteModule,
     RackComponent,
     ServiceAppointmentCreateComponent,
     JobCardSearchComponent,
     ServiceAppointmentCreateComponent,
     ListCardComponent,
     AdvancedSearchComponent,
     MatSelectModule,
     PhotoContainerComponent,
     ThemeDirective,
     ColorPickerComponent,
     ColorPickerModule,
     PaymentVoucherComponent,
     ReceiptVoucherComponent,
     VendorSearchComponent,
     PartSearchComponent,
     PaymentVoucherComponent,
     GrnApsSearchComponent,
     RestrictSpace,
     InvoiceComponent,
     PartCostComponent,
     PartPriceComponent,
     MatSlideToggleModule,
     AddLocationComponent,
     SearchPipe,
     SalesSearchComponent,
     SalesEnquiryComponent,
     ViewSalesScheme,
     ViewSpareScheme,
     SalesAllotmentComponent,
     SalesAccessoriesComponent,
     SalesHpHistoryComponent,
     VehicleSchemeComponent,
     RegistrationInsuranceInvoiceComponent,
     SalesMapInvoiceComponent,
     SalesCICComponent,
     SalesCreateCustomerComponent,
     OnlineReceiptVoucherComponent,
     UpdateJobcardStatusComponent,
     UserMappingComponent,
     WarrantyPrintComponent,
     InsurancePolicyComponent,
     InformationDialogComponent,
     AttendanceScreenComponent,
     RecallRefitDialogComponent,
     RecallRefitRejectionComponent,
       MandatoryPartsComponent,
       RejectRemarksComponent,
       RescheduleComponent,
       PndeowComponent,
       SearchSalesPersonComponent
       //,
      ,PmpReasonComponent,
      SalesVehicleReturnComponent,
      MobileOtpDialogComponent, 
      BookingAdvanceVoucherComponent,
      StockTranferGrn,
      InvoiceDetailHpVoucherComponent,
      MiscellanousPurchaseJvComponent,
      VendorPaymentJvComponent,
      SparesGrnPaymentJvComponent,
      BookingAdvanceRefundComponent,
      VehicleSalesReturnPaymentComponent,
      SpareSalesReturnPaymentComponent,
      JobCardRefundsComponent,
      RegisterPaymentComponent,
      SparesGrnPaymentComponent,
      VendorBillPayment,
      CommisionPaymentComponent,
      InsurancePaymentComponent,
      AscWarrantyClaimPaymentComponent,
      AscCouponClaimPaymentComponent,
      SpareCommissionPaymentComponent,
      VehicleAccessoriesReturnPaymentComponent,
      VehicleCommissionPaymentComponent,
      JobcardAdvanceVoucherComponent,
      AmcReceiptVoucherComponent,
      VehicleReceiptVoucherComponent,
      ServiceReceiptVoucherComponent,
      SparesReceiptVoucherComponent,
      GeneralLedgerComponent,

      AscCouponClaimComponent,
      AscWarrantyClaimComponent,
      VendorOutworkBillComponent,
      VehicleCommissionPaymentComponent,
      SpareCommissionPaymentComponent,
      CommisionPaymentComponent,
      ExcessClaimCreditComponent,
      VendorSearchComponent,
      SpareClaimDebitNoteComponent,
      DebitCouponWarrantyClaimComponent,
      DebitCouponWarrantyClaimComponent,
      DebitnoteWarrantyClaimComponent,
      PndeowDisplayComponent,
      CreditNoteCommPayComponent,
      FrameNumberSearchComponent,
      SearchCreditDebitNumComponent,
      CreateRepairRequestComponent,
      ReportPopupComponent,
      CreateRackMasterComponent,
      GrnOthersExcelUploadComponent,
      GrnPartCostComponent,
      CreateDealerMasterComponent,
      CreateBranchMasterComponent,
      AddCompanyDetailsComponent,
      VehicleMasterCreateComponent,
      DeliveryChalanNoSearchComponent,
      DeliveryChalanNoSearchGrnComponent,
      DealerOnboardComponent,
      WarehouseCustomerMasterComponent,
      CreateWarehouseMasterComponent,
      WarehousePartCostComponent,
      WarehousePartPriceComponent,
      WarehouseExcelUploadComponent,
      VehicleProcurementExcelUploadComponent,
      CreateUomMasterComponent,
      CreateHsnCodeMasterComponent,
      CreateHsnPercentageMasterComponent,
      CreateBrandMasterComponent,
      CreateHsnCodeMasterComponent,
      VehicleExcelBulkUploadComponent,
      PartExcelBulkUploadComponent
   ],
   entryComponents: [JobCardSearchComponent,
     CreateCustomerComponent, RackComponent,
     AscFscClaimDialogComponent,
     ViewComplaintComponent,
     CustomiseTableComponent,
     LogoutDialogComponent,
     ConfirmationDialogComponent,
     ColorPickerComponent,
     AdvancedSearchComponent,
     CustomDialog,
     SalesSearchComponent,
     SalesEnquiryComponent,
     ViewSalesScheme,
     ViewSpareScheme,
     SalesAllotmentComponent,
     ReceiptVoucherComponent,
     SalesAccessoriesComponent,
     SalesHpHistoryComponent,
     VehicleSchemeComponent,
     RegistrationInsuranceInvoiceComponent,
     SalesMapInvoiceComponent,
     SalesCICComponent,
     SalesCreateCustomerComponent,
     OnlineReceiptVoucherComponent,
     UpdateJobcardStatusComponent,
     UserMappingComponent,
     WarrantyPrintComponent,
     InsurancePolicyComponent,
     InformationDialogComponent,
     AttendanceScreenComponent,
     RecallRefitDialogComponent,
     RecallRefitRejectionComponent,
       MandatoryPartsComponent,
       RejectRemarksComponent,
       RescheduleComponent,
       PndeowComponent,
       SearchSalesPersonComponent,
       PaymentVoucherComponent
       //,
      , PmpReasonComponent
      ,DigiSignComponent
      ,DigiGeneratePdfComponent,
      SalesVehicleReturnComponent,
      MobileOtpDialogComponent, 
      BookingAdvanceVoucherComponent,
      StockTranferGrn,
      InvoiceDetailHpVoucherComponent,
      MiscellanousPurchaseJvComponent,
      VendorPaymentJvComponent,
      SparesGrnPaymentJvComponent,
      BookingAdvanceRefundComponent,
      VehicleSalesReturnPaymentComponent,
      SpareSalesReturnPaymentComponent,
      JobCardRefundsComponent,
      VendorBillPayment,
      CommisionPaymentComponent,
      RegisterPaymentComponent,
      InsurancePaymentComponent,
      SparesGrnPaymentComponent,
      AscWarrantyClaimPaymentComponent,
      AscCouponClaimPaymentComponent,
      SpareCommissionPaymentComponent,
      VehicleAccessoriesReturnPaymentComponent,
      VehicleCommissionPaymentComponent,
      JobcardAdvanceVoucherComponent,
      AmcReceiptVoucherComponent,
      VehicleReceiptVoucherComponent,
      ServiceReceiptVoucherComponent,
      SparesReceiptVoucherComponent,
      GeneralLedgerComponent,

      AscCouponClaimComponent,
      AscWarrantyClaimComponent,
      VendorOutworkBillComponent,
      VehicleCommissionPaymentComponent,
      SpareCommissionPaymentComponent,
      CommisionPaymentComponent,
      ExcessClaimCreditComponent,
      VendorSearchComponent,
      SpareClaimDebitNoteComponent,
      DebitCouponWarrantyClaimComponent,
      DebitCouponWarrantyClaimComponent,
      DebitnoteWarrantyClaimComponent,
      SearchBankComponent,
      PndeowDisplayComponent,
      CreditNoteCommPayComponent,
      FrameNumberSearchComponent,
      SearchCreditDebitNumComponent,
      CreateRepairRequestComponent,
      ReportPopupComponent,
      CreateRackMasterComponent,
      GrnOthersExcelUploadComponent,
      GrnPartCostComponent,
      CreateDealerMasterComponent,
      CreateBranchMasterComponent,
      AddCompanyDetailsComponent,
      VehicleMasterCreateComponent,
      DeliveryChalanNoSearchComponent,
      DeliveryChalanNoSearchGrnComponent,
      DealerOnboardComponent,
      WarehouseCustomerMasterComponent,
      CreateWarehouseMasterComponent,
      WarehousePartCostComponent,
      WarehousePartPriceComponent,
      WarehouseExcelUploadComponent,
      VehicleProcurementExcelUploadComponent,
      CreateUomMasterComponent,
      CreateHsnCodeMasterComponent,
      CreateHsnPercentageMasterComponent,
      CreateBrandMasterComponent,
      CreateHsnCodeMasterComponent,
      VehicleExcelBulkUploadComponent,
      PartExcelBulkUploadComponent
   ],
 
   providers: [
     { provide: MAT_DIALOG_DATA, useValue: {} },
     { provide: MatDialogRef, useValue: {} }
      ,DigiGeneratePdfComponent
     , SalesVehicleReturnComponent
   ]
 })
 export class SharedModule { }
 
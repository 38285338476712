/**
 * Created BY V.Dineshkumar 
 * Service Request Service
 * Last Last Modified By V.Dineshkumar on 06/10/2021
 * Last Modified Description : 
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceRequestService {
    constructor(private httpClient: HttpClient) {
    }

    /**
        * Get Repair Request List
        * @param {Object} params
        * return Object
        */
    getRepairRequestlist(params) {
        const resData = RequestService.getApiURLReq('/ServApp/GetRepairRequestList');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }


    /**
* Save Service Request
* @param {Object} params
* return Object
*/
    saveServiceRequest(params) {
        const resData = RequestService.postApiURLReq('/ServApp/SaveServiceRequest');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }


    /**
* Send SMS Parts Availability Service Request
* @param {Object} params
* return Object
*/
    sendSMSPartsAvailability(params) {
        const resData = RequestService.postApiURLReq('/ServApp/SendPartsAvailableSMS');
        return this.httpClient.post(resData.url, params, { headers: resData.headers });
    }

    /**
 * Check Repair Request Exists
 * @param {Object} params
 * return Object
 */
    checkRepairRequestExists(params) {
        const resData = RequestService.getApiURLReq('/ServApp/CheckRepairRequestExists');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }


    /**
      * Get details based on Id
      * @param {Object} params
      * return Object
      */
    getRepairRequestDetailsbyId(params) {
        const resData = RequestService.getApiURLReq('/ServApp/GetRepairRequestforModify');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
}
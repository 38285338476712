export class WarehouseMaster {
    warehouseId: number
    warehouseName: string
    plantCode: string
    emailId: string
    addressLine1: string
    addressLine2: string
    addressLine3: string
    City: string
    stateId: string
    PhoneNo: string
    Fax: string
    pinCode: string
    panNo: string
    countryCode: string
    accFinyearStartMonth: number
    accFinyearEndMonth: number
    areaId: string
    dateImplemented: any
    gstInNo:any
  }
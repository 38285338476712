import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { PartSearchComponent } from '../part-search/part-search.component';

@Component({
  selector: 'app-insurance-payment',
  templateUrl: './insurance-payment.component.html',
  styleUrls: ['./insurance-payment.component.scss']
})
export class InsurancePaymentComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public maxDate:any = new Date();
  public voucherType: any = 2;
  public status: any = [];
  public partRows: any = [];
  public insPaymentLists: any = [];
  public insuranceCompanyLists: any=[];
  
  constructor(
    private dialogRef: MatDialogRef<InsurancePaymentComponent>,
    private masterService: MasterService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
//   $scope.$on('$viewContentLoaded', function() {
//     //call it here
// });
  ngOnInit() {   
    {
      let reqObj: any = {};
      // if(!this.apsGrn.APS_Code){
      // this.toastr.error('Vendor name is empty');
      // return;
      // }
      reqObj.dealerId = CommonService.getUserData().DEALER_ID;      
      reqObj.branchId = CommonService.getUserData().BRANCH_ID;
      reqObj.vendorId = CommonService.getUserData().vendorId;
      reqObj.COMPANY_NAME="";
      reqObj.vendorType=2;
      reqObj.active=true;
      // reqObj = Object.assign(reqObj, this.apsGrn);
      for (const key in reqObj) {
        if (reqObj[key] == '') {
          delete reqObj[key];
        }
      }
      this.isShowPageLoader = true;
      this.masterService.get('', '/Accounts/GetAccountVoucherMasterData?dealerId=' + CommonService.getUserData().DEALER_ID +
      '&branchId	=' + CommonService.getUserData().BRANCH_ID + '&voucherType	=' + this.voucherType + '&countryCode=' +
      CommonService.getDealerData().COUNTRY_CODE).subscribe(
        (resp: any) => {
         
          if (resp && resp.statusCode == 200) {
            this.isShowPageLoader = false;
            this.insuranceCompanyLists = resp.insuranceCompanyList ;
          
          } 
          else if (resp && resp.statusCode == 204) {
            this.insuranceCompanyLists = [];
            this.isShowPageLoader = false;
            return this.toastr.error(resp.statusMessage);
          }
          else {
            this.isShowPageLoader = false;
          }
        }, error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        })
      }
    if (this.data) {
      this.insuranceCompanyLists = this.data.insuranceCompanyList && this.data.insuranceCompanyList.length > 0 ? this.data.insuranceCompanyList : [];
      this.insuranceCompanyLists.forEach(value => {
        if(this.data.COMPANY_NAME == value) {
        }
      });
      
    }
    this.inlineFilter = {
      COMPANY_NAME:'',
      TOT_AMT:'',
      CREATED_ON:'',
    };
    
  }

  searchList() {
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reqObj.COMPANY_NAME= (<HTMLSelectElement>document.getElementById('inscomany')).value;
    reqObj.IS_USER_CONTROL=true;
    reqObj.INS_PAY_DATE_FROM = fromDateFormat;
    reqObj.INS_PAY_DATE_TO = toDateFormat;
    reqObj.TOT_AMT=0;
    reqObj.INS_COMP_ID=0;
    this.isShowPageLoader = true;
    this.masterService.post(reqObj, '/Accounts/SearchInsurancePaymentList').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.insPaymentLists = resp.insPaymentList ;
        
        } 
        else if (resp && resp.statusCode == 204) {
          this.insPaymentLists=[];
          
          this.isShowPageLoader = false;
          return this.toastr.error(resp.statusMessage);
        }
        else {
          this.isShowPageLoader = false;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      })
     
    }
  selectedItem() {
      if (this.selected[0].STATUS == 2 || this.selected[0].STATUS == 3) {
      this.toastr.error('GRN is completed.Please select the another invoice...');
      return;
    }
    let closeObj = { obj: this.selected[0]};
    this.dialogRef.close(closeObj);
  }

  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

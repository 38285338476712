import { Component, OnInit, Inject, Testability } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/api-services/master.service';
import { id } from '@swimlane/ngx-datatable/release/utils';

@Component({
  selector: 'app-miscellanous-purchase-jv',
  templateUrl: './miscellanous-purchase-jv.component.html',
  styleUrls: ['./miscellanous-purchase-jv.component.scss']
})
export class MiscellanousPurchaseJvComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public voucherType: any;
  public apsGrn: any = {};
  public vendorTypeLists: any = [];
  public vendorRows: any = [];
  public vendorLists: any =[];
  public VendorTypes:any=[];
  public sparesGRNPaymentLists: any = [];
  public selectedvendorTypeLists :any=[];
  public isShowPageLoader: boolean;
  public maxDate:any = new Date();
  constructor(
    private dialogRef: MatDialogRef<MiscellanousPurchaseJvComponent>,
    private masterService: MasterService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  ngOnInit() {   
    
    {
      this.isShowPageLoader = true;
      this.masterService.get('', '/DMSMaster/VendorMaster/GetVendorMasterDetails?dealerId=' + CommonService.getUserData().DEALER_ID +
      '&branchId	=' + CommonService.getUserData().BRANCH_ID + '&countryCode=' +
      CommonService.getDealerData().COUNTRY_CODE).subscribe(
        (resp: any) => {
         console.log(resp);
          if (resp && resp.statusCode == 200) {
            this.isShowPageLoader = false;
            this.vendorTypeLists = resp.vendorTypeList;
          
          } 
          else if (resp && resp.statusCode == 204) {
            this.vendorTypeLists = [];
            console.log('Sarath',this.vendorTypeLists);
            this.isShowPageLoader = false;
            return this.toastr.error(resp.statusMessage);
          }
          else {
            this.isShowPageLoader = false;
          }
        }, error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        })
      }
    if (this.data) {
      this.vendorTypeLists = this.data.vendorTypeList && this.data.vendorTypeList.length > 0 ? this.data.vendorTypeList : [];
      this.vendorTypeLists.forEach(value => {
        if(this.data.VENDOR_TYPE == value) {
          this.apsGrn.APS_Code = value.APS_Code;
        }else if (this.data.VENDOR_TYPE_ID ==id){
          this.apsGrn.APS_Code = value.APS_Code;
        }
      });
      
    }
    
      let test= this.vendorTypeLists.VENDOR_TYPE_ID;
    this.inlineFilter = {
      SPARE_GRN_NO:'',
      INVOICE_NO:'',
      VENDOR_NAME:'',
    };
    
  }

  apsGrnSearch() {
    let reqObj: any = {};
       
    reqObj.dealerId = CommonService.getUserData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;    
    reqObj.vendorTypeId= (<HTMLSelectElement>document.getElementById('vendorname')).value;    
    reqObj.vendorName='';
    reqObj.active= true;
    this.isShowPageLoader = true;
    this.masterService.post(reqObj, '/DMSMaster/VendorMaster/SearchVendor').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.vendorLists = resp.vendorList;
        
        } 
        else if (resp && resp.statusCode == 204) {
          this.vendorLists = [];
          this.isShowPageLoader = false;
          return this.toastr.error(resp.statusMessage);
        }
        else {
          this.isShowPageLoader = false;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      })
     
    }
  selectedItem() {
      if (this.selected[0].STATUS == 2 || this.selected[0].STATUS == 3) {
      this.toastr.error('GRN is completed.Please select the another invoice...');
      return;
    }
    let closeObj = { obj: this.selected[0]};
    this.dialogRef.close(closeObj);
  }

  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

export class SalesPartInfo {
    VEHICLE_ID: number;
    MODEL_ID: string;
    BRAND_ID: number;
    PART_ID: string;
    QTY: number;
    EX_SHRM_PRICE: number;
    MANUAL_DISC: number;
    CGST: number;
    SGST: number;
    IGST: number;
    UTGST: number;
    MODEL_DESC: string;
    PART_DESC: string;
    BRAND_DESC: string;
    FRAME_NO: string;
}

export class SalesInfo {
    DEALER_ID: number;
    BRANCH_ID: number;
    INVOICE_ID: number;
    INVOICE_NO: number;
    INVOICE_DATE?: any;
    BILL_TYPE: any;
    PAYMENT_MODE_ID: number;
    VEH_SAL_ENQ_ID: number;
    CUSTOMER_ID: number;
    REFERAL_ID: number;
    END_USER_ID: number;
    COMPANY_ID: number;
    DISC_VALUE: number;
    TOT_AMT: number;
    HP_UNRECON_VAL: number;
    ROUND_OFF: number;
    DELIVERED_BY: number;
    REMARKS?: any;
    FIN_YEAR: number;
    SalesPartInfo: SalesPartInfo[];
    ENQUIRY_NO: number;
    ENQUIRY_DATE: Date;
    HP_COMP_ID: number;
    HP_COMP_NAME: string;
    CUST_NAME: string;
    REFERAL_NAME: string;
    MOBILE_NO: string;
    MOBILE_NO_2?: any;
    CREDIT_AVAILABLE: number;
    CUST_ADDRESS: string;
    ProcurementCost:any;
    MOBILE_OTP:any;
}

export class CompanyList {
    companyID: number;
    companyName: string;
}

export class EmployeeList {
    employeeID: number;
    employeeName: string;
}

export class InvoiceRootObject {
    SalesInfo: SalesInfo;
    companyList: CompanyList[];
    employeeList: EmployeeList[];
}




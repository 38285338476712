import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { PartSearchComponent } from '../part-search/part-search.component';

@Component({
  selector: 'app-commision-payment',
  templateUrl: 'commision-payment.component.html',
  styleUrls: ['commision-payment.component.scss']
})
export class CommisionPaymentComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public maxDate:any = new Date();
  public partRows: any = [];
  public commissionPaymentLists: any = [];
  constructor(
    private dialogRef: MatDialogRef<CommisionPaymentComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.inlineFilter = {
        COMM_PAY_NO: '',
        SAL_THR_ASC_ID: '',
        SaleThroughASCName: '',
        COMM_PAY_DT:'',
      };
    }
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  // searchList() {
  //     this.getVehiclePaymentCommissionList();
  // }
  /**
   * select search list
   */
  selectedItem() {
    if (this.isActive === 'tvsInvoiceSearch') {
      if (this.selected[0].INVOICE_STATUS == 2 || this.selected[0].INVOICE_STATUS == 3) {
        this.toastr.error('GRN is completed.Please select the another invoice...');
        return;
      }
      this.selected[0].TYPE = this.part.invoiceTypeId;
    }
    let closeObj = { 
      obj: this.selected[0],
      typeId: this.data.voucherId
    };
    this.dialogRef.close(closeObj);
  }

  //grn-tvs invoice popup
  searchList() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Booking From Date is empty');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Booking to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
    reqObj.COMM_PAY_NO = this.part.COMM_PAY_NO ? Number(this.part.COMM_PAY_NO) : null;
    reqObj.SAL_THR_ASC_ID = this.part.SAL_THR_ASC_ID ? Number(this.part.SAL_THR_ASC_ID) : null;
    reqObj.COMM_PAY_DT_FROM = fromDateFormat;
    reqObj.COMM_PAY_DT_TO = toDateFormat;
    reqObj.CUST_NAME = this.part.CUST_NAME ? this.part.CUST_NAME : null;
    reqObj.COMPANY_ID=0;
    reqObj.COMM_AMT=0;
    reqObj.PAY_VCHR_ID=0;
    reqObj.CREDIT_NOTE_ID=0;
    reqObj.STATUS=0;
    reqObj.COMM_TYPE=0;
    reqObj.CREATED_BY=0;
    reqObj.MODIFIED_BY=0;
    reqObj.FIN_YEAR=CommonService.getDealerData().FIN_YEAR;
    reqObj.ROWSTATE=0;
    reqObj.ACTIVE=true;
    reqObj.SaleThroughASCName="";
    reqObj.Comm_Pay_Status="";
    reqObj.SL_CODE=0;
    reqObj.INV_TYPE=0;
    this.commissionPaymentLists = [];
    this.masterServices.post(reqObj, '/Accounts/SearchCommissionPaymentList').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.commissionPaymentLists = resp.commissionPaymentList;
      } else if (resp && resp.statusCode == 204) {
        this.commissionPaymentLists = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import { branchData } from 'src/app/api-services/dto/branchData';
import { AddCompanyDetailsComponent } from '../add-company-details/add-company-details.component';
@Component({
  selector: 'app-create-branch-master',
  templateUrl: './create-branch-master.component.html',
  styleUrls: ['./create-branch-master.component.scss']
})
export class CreateBranchMasterComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public stateList: any;
  public branchData = new branchData();
  public userData: any;
  public companyList = [
    {
      "BRANCH_ID":'',
      "COMPANY_ID": '',
      "COMPANY_NAME": '',
      "ACTIVE": false,
      "FIN_YR_CLOSE_ALLWD": false,
      "ROW_STATE": 0
    }
  ];
  constructor(private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateBranchMasterComponent>) { }

  ngOnInit() {
    console.log("Modify Data", this.data.value);
    if (this.data.type == "MODIFY") {
      this.getBranchDetails();
    }
    this.getBranchMaster();
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  getBranchMaster() {
    this.isShowPageLoader = true;
    this.branchData.IS_ACTIVE = true;
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.masterService.get('', '/DMSMaster/BranchMaster?countryCode=' +
      this.userData.data.COUNTRY_CODE).subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.stateList = resp.stateList;
            if (this.branchData.PRI_STATE == null || this.branchData.PRI_STATE == undefined) {
              this.branchData.PRI_STATE = -1;
            } else {
              this.stateList.filter(key => {
                if (this.branchData.PRI_STATE == key.STATE_NAME) {
                  this.branchData.PRI_STATE = key.STATE_ID
                }
              });
            }
            if (this.branchData.ALT_STATE == null || this.branchData.ALT_STATE == undefined) {
              this.branchData.ALT_STATE = -1;
            }
            else {
              this.stateList.filter(key => {
                if (this.branchData.ALT_STATE == key.STATE_NAME) {
                  this.branchData.ALT_STATE = key.STATE_ID
                }
              });
            }
          }
          if (resp.statusCode == 204) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        })
  }

  getBranchDetails() {
    this.isShowPageLoader = true;
    this.masterService.get('', '/DMSMaster/GetBranchDetail?DealerId=' +
      CommonService.getUserData().DEALER_ID + '&BranchId=' + this.data.value.BRANCH_ID).subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.branchData.BRANCH_ID = resp.branchDetails.BRANCH_ID;
            this.branchData.BRANCH_NAME = resp.branchDetails.BRANCH_NAME;
            this.branchData.IS_ACTIVE = resp.branchDetails.ACTIVE;
            this.branchData.SAP_CODE = resp.branchDetails.SAP_BRANCH_ID;
            this.branchData.PRI_ADDRESS_ID = resp.branchDetails.PrimaryAddress.ADDRESS_ID;
            this.branchData.PRI_ADDRESS1 = resp.branchDetails.PrimaryAddress.ADDRESS_LINE_1;
            this.branchData.PRI_ADDRESS2 = resp.branchDetails.PrimaryAddress.ADDRESS_LINE_2;
            this.branchData.PRI_ADDRESS3 = resp.branchDetails.PrimaryAddress.ADDRESS_LINE_3;
            this.branchData.PRI_STATE = resp.branchDetails.PrimaryAddress.STATE_ID;
            this.branchData.PRI_PIN_CODE = resp.branchDetails.PrimaryAddress.PIN_CODE;
            this.branchData.PRI_PHONE_NO = resp.branchDetails.PrimaryAddress.PHONE_NO;
            this.branchData.PRI_WORKSHOP_PHONE_NO = resp.branchDetails.PrimaryAddress.WORKSHOP_PHONE_NO;
            this.branchData.PRI_PART_PHONE_NO = resp.branchDetails.PrimaryAddress.PART_PHONE_NO;
            this.branchData.PRI_CITY = resp.branchDetails.PrimaryAddress.CITY;
            this.branchData.PRI_FAX = resp.branchDetails.PrimaryAddress.FAX;
            this.branchData.PRI_EXPRESS_SERVICE = resp.branchDetails.PrimaryAddress.Express_Service;
            this.branchData.PRI_EXPERT_WHEEL = resp.branchDetails.PrimaryAddress.Expert_on_Wheels;
            this.branchData.PRI_PICK_DROP = resp.branchDetails.PrimaryAddress.Pick_Drop;
            this.branchData.PRI_MILEAGE_CHECK = resp.branchDetails.PrimaryAddress.Mileage_Checking;
            this.branchData.ALT_ADDRESS_ID = resp.branchDetails.AlternateAddress.ADDRESS_ID;
            this.branchData.ALT_ADDRESS1 = resp.branchDetails.AlternateAddress.ADDRESS_LINE_1;
            this.branchData.ALT_ADDRESS2 = resp.branchDetails.AlternateAddress.ADDRESS_LINE_2;
            this.branchData.ALT_ADDRESS3 = resp.branchDetails.AlternateAddress.ADDRESS_LINE_3;
            this.branchData.ALT_STATE = resp.branchDetails.AlternateAddress.STATE_ID;
            this.branchData.ALT_PIN_CODE = resp.branchDetails.AlternateAddress.PIN_CODE;
            this.branchData.ALT_PHONE_NO = resp.branchDetails.AlternateAddress.PHONE_NO;
            this.branchData.ALT_WORKSHOP_PHONE_NO = resp.branchDetails.AlternateAddress.WORKSHOP_PHONE_NO;
            this.branchData.ALT_PART_PHONE_NO = resp.branchDetails.AlternateAddress.PART_PHONE_NO;
            this.branchData.ALT_CITY = resp.branchDetails.AlternateAddress.CITY;
            this.branchData.ALT_FAX = resp.branchDetails.AlternateAddress.FAX;
            this.branchData.ALT_IS_SERVICE = resp.branchDetails.ALT_PRINT_SERVICE;
            this.branchData.ALT_IS_SPARE = resp.branchDetails.ALT_PRINT_SPARE;
            for (var i = 0; i < resp.branchDetails.companyList.length; i++) {
              this.companyList[i].COMPANY_ID = resp.branchDetails.companyList[i].COMPANY_ID;
              this.companyList[i].COMPANY_NAME = resp.branchDetails.companyList[i].COMPANY_NAME;
              this.companyList[i].ACTIVE = resp.branchDetails.companyList[i].ACTIVE;
              this.companyList[i].FIN_YR_CLOSE_ALLWD = resp.branchDetails.companyList[i].FIN_YR_CLOSE_ALLWD;
              this.companyList[i].ROW_STATE = 1;
              if(i < resp.branchDetails.companyList.length-1){
                this.companyList.push(
                  {
                    "BRANCH_ID":'',
                    "COMPANY_ID": '',
                    "COMPANY_NAME": '',
                    "ACTIVE": false,
                    "FIN_YR_CLOSE_ALLWD": false,
                    "ROW_STATE": 0
                  });
              }
            }
          }
          if (resp.statusCode == 204) {
            this.toastr.info(resp.statusMessage);
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        })
  }

  closePopup() {
    this.dialogRef.close();
  }

  saveBranchDetails() {
    this.isShowPageLoader = true;
    for(var i=0;i< this.companyList.length;i++){
      this.companyList[i].BRANCH_ID = this.branchData.BRANCH_ID ? this.branchData.BRANCH_ID : 0;
    }
    let reqObj: any = {
      "branchDet": {
        "ROW_STATE": 0, //C-0 M-1
        "MODIFIED_BY": CommonService.getUserData().USER_ID, //UserID sess
        "CREATED_BY": CommonService.getUserData().USER_ID, //UserID sess
        "DEALER_ID": CommonService.getUserData().DEALER_ID,
        "BRANCH_ID": this.branchData.BRANCH_ID ? this.branchData.BRANCH_ID : 0,
        "BRANCH_NAME": this.branchData.BRANCH_NAME ? this.branchData.BRANCH_NAME : '',
        "VAT_NO": "", //def
        "CST_NO": "", //def
        "LST_NO": "", //def
        "CLOSE_ALLOW": false, //def
        "ALT_PRINT_SERVICE": this.branchData.ALT_IS_SERVICE,
        "ALT_PRINT_SPARE": this.branchData.ALT_IS_SPARE,
        "ACTIVE": this.branchData.IS_ACTIVE,
        "SAP_BRANCH_ID": this.branchData.SAP_CODE ? this.branchData.SAP_CODE : '',
        "PrimaryAddress": {
          "ADDRESS_ID": this.branchData.PRI_ADDRESS_ID ? this.branchData.PRI_ADDRESS_ID : 0,
          "EMAIL_ID": "",
          "ADDRESS_LINE_1": this.branchData.PRI_ADDRESS1 ? this.branchData.PRI_ADDRESS1 : '',
          "ADDRESS_LINE_2": this.branchData.PRI_ADDRESS2 ? this.branchData.PRI_ADDRESS2 : '',
          "ADDRESS_LINE_3": this.branchData.PRI_ADDRESS3 ? this.branchData.PRI_ADDRESS3 : '',
          "CITY": this.branchData.PRI_CITY ? this.branchData.PRI_CITY : '',
          "STATE_ID": this.branchData.PRI_STATE ? this.branchData.PRI_STATE : '',
          "PIN_CODE": this.branchData.PRI_PIN_CODE ? this.branchData.PRI_PIN_CODE : '',
          "PHONE_NO": this.branchData.PRI_PHONE_NO ? this.branchData.PRI_PHONE_NO : '',
          "WORKSHOP_PHONE_NO": this.branchData.PRI_WORKSHOP_PHONE_NO ? this.branchData.PRI_WORKSHOP_PHONE_NO : '',
          "PART_PHONE_NO": this.branchData.PRI_PART_PHONE_NO ? this.branchData.PRI_PART_PHONE_NO : '',
          "FAX": this.branchData.PRI_FAX ? this.branchData.PRI_FAX : '',
          "Express_Service": this.branchData.PRI_EXPRESS_SERVICE ? this.branchData.PRI_EXPRESS_SERVICE : '',
          "Expert_on_Wheels": this.branchData.PRI_EXPERT_WHEEL ? this.branchData.PRI_EXPERT_WHEEL : '',
          "Pick_Drop": this.branchData.PRI_PICK_DROP ? this.branchData.PRI_PICK_DROP : '',
          "Mileage_Checking": this.branchData.PRI_MILEAGE_CHECK ? this.branchData.PRI_MILEAGE_CHECK : ''
        },
        "AlternateAddress": {
          "ADDRESS_ID": this.branchData.ALT_ADDRESS_ID ? this.branchData.ALT_ADDRESS_ID : 0,
          "EMAIL_ID": "",
          "ADDRESS_LINE_1": this.branchData.ALT_ADDRESS1 ? this.branchData.ALT_ADDRESS1 : '',
          "ADDRESS_LINE_2": this.branchData.ALT_ADDRESS2 ? this.branchData.ALT_ADDRESS2 : '',
          "ADDRESS_LINE_3": this.branchData.ALT_ADDRESS3 ? this.branchData.ALT_ADDRESS3 : '',
          "CITY": this.branchData.ALT_CITY ? this.branchData.ALT_CITY : '',
          "STATE_ID": this.branchData.ALT_STATE ? this.branchData.ALT_STATE : '',
          "PIN_CODE": this.branchData.ALT_PIN_CODE ? this.branchData.ALT_PIN_CODE : '',
          "PHONE_NO": this.branchData.ALT_PHONE_NO ? this.branchData.ALT_PHONE_NO : '',
          "WORKSHOP_PHONE_NO": this.branchData.ALT_WORKSHOP_PHONE_NO ? this.branchData.ALT_WORKSHOP_PHONE_NO : '',
          "PART_PHONE_NO": this.branchData.ALT_PART_PHONE_NO ? this.branchData.ALT_PART_PHONE_NO : '',
          "FAX": this.branchData.ALT_FAX ? this.branchData.ALT_FAX : '',
        },
        "companyList": this.companyList
        // "companyList": [
        //   {
        //     "DEALER_ID": CommonService.getUserData().DEALER_ID,
        //     "BRANCH_ID": this.branchData.BRANCH_ID ? this.branchData.BRANCH_ID : 0,
        //     "COMPANY_ID": this.branchData.COMPANY_ID ? this.branchData.COMPANY_ID : 0,
        //     "COMPANY_NAME": this.branchData.COMPANY_NAME ? this.branchData.COMPANY_NAME : '',
        //     "ACTIVE": this.branchData.MAPPING_STATUS,
        //     "FIN_YR_CLOSE_ALLWD": this.branchData.YEAR_CLOSURE,
        //     "ROW_STATE": this.branchData.COMPANY_ROW_STATE ? this.branchData.COMPANY_ROW_STATE : 0 //D-2 A-0
        //   }
        // ],
      },
      "FIN_YEAR": CommonService.getDealerData().FIN_YEAR //session
    };
    if (this.data.type == "MODIFY") {
      reqObj.branchDet.ROW_STATE = 1;
    }
    if (this.branchData.SAP_CODE == '' && this.userData.data.BUSINESS_TYPE_ID == 1
      && this.userData.data.COUNTRY_CODE) {
      this.toastr.info("SAP Code is Mandatory");
      this.isShowPageLoader = false;
      return;
    }
    this.masterService.post(reqObj, '/DMSMaster/SaveBranchDetail').subscribe(
      (resp: any) => {
        if (resp.statusCode == 200) {
          this.dialogRef.close();
          this.toastr.success(resp.statusMessage)
        }
        if (resp.statusCode == 400) {
          this.toastr.info(resp.statusMessage)
        }
        if (resp.statusCode == 204) {
          this.toastr.info(resp.statusMessage);
        }
        this.isShowPageLoader = false;
      },
      error => {
        if (error.statusCode == 401) {
          this.toastr.error(error.error.Message);
        }
      })
  }

  addCompanyDetails(type, row) {
    type = this.data.type;
    row = this.branchData;
    const dialogRef = this.dialog.open(AddCompanyDetailsComponent, {
      width: '50%',
      height: 'auto',
      data: { value: type, row },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(this.companyList[0].COMPANY_ID == ''){
          this.companyList = [];
        }
        this.companyList.push(result[0]);
        console.log("companyList", this.companyList);
      }
    });
  }

  deleteCompanyList(COMPANY_ID) {
    for(var i =0;i<this.companyList.length;i++){
      if(this.companyList[i].COMPANY_ID == COMPANY_ID){
        if(this.companyList[i].ROW_STATE == 1){
          this.companyList[i].ROW_STATE = 2;
        }
        if(this.companyList[i].ROW_STATE == 0){
          this.companyList = this.companyList.filter(key => key.COMPANY_ID != COMPANY_ID);
        }
      }
    }
    // this.branchData.COMPANY_ID = "";
    // this.branchData.COMPANY_NAME = "";
    // this.branchData.MAPPING_STATUS = false;
    // this.branchData.YEAR_CLOSURE = false;
  }

}

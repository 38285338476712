import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import { vehicleData } from 'src/app/api-services/dto/vehicle-data';
import { JobCardSearchComponent } from '../job-card-search/job-card-search.component';
@Component({
  selector: 'app-vehicle-master-create',
  templateUrl: './vehicle-master-create.component.html',
  styleUrls: ['./vehicle-master-create.component.scss']
})
export class VehicleMasterCreateComponent implements OnInit {
  public lang: any;
  public isShowPageLoader = false;
  public masterData: any;
  public vehicleData = new vehicleData();
  public isTypeDisable: boolean = false;
  public insuranceList: any = [];
  constructor(private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehicleMasterCreateComponent>) { }

  ngOnInit() {
    this.createVehicle();
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
  createVehicle() {
    this.isShowPageLoader = true;
    this.masterService.get('', '/DMSMaster/VehicleCreateMasterData').subscribe(
      (resp: any) => {
        if (resp.statusCode == 200) {
          this.masterData = resp;
          this.vehicleData.INSURANCE_COMPANY = -1;
          if (this.data.type == 'MODIFY') {
            this.GetUserDetail();
          }
        }
        if (resp.statusCode != 200) {
          this.toastr.info(resp.statusMessage);
          this.loginService.logout();
        }
        this.isShowPageLoader = false;
      },
      error => {
        if (error.statusCode == 401) {
          this.toastr.error(error.error.Message);
        }
      })
  }

  GetUserDetail() {
    this.isShowPageLoader = true;
    this.masterService.get('', '/DMSMaster/GetVehicleDetail?DealerId=' +
      CommonService.getUserData().DEALER_ID + '&BranchId=' + CommonService.getUserData().BRANCH_ID +
      '&vehicleId=' + this.data.value.VEHICLE_ID +
      '&countryCode=' + CommonService.getDealerData().COUNTRY_CODE).subscribe(
        (resp: any) => {
          if (resp.statusCode == 200) {
            this.vehicleData.FRAME_NO = resp.vehicleDetails.FRAME_NO;
            this.vehicleData.REG_NO = resp.vehicleDetails.REG_NO;
            this.vehicleData.MODEL = resp.vehicleDetails.MODEL_DESC;
            this.vehicleData.KEY_NO = resp.vehicleDetails.KEY_NO;
            this.vehicleData.PART = resp.vehicleDetails.PART_DESC;
            this.vehicleData.SALES_TROUGH = resp.vehicleDetails.SALE_THR_ASC_NAME;
            this.vehicleData.ENGINE_NO = resp.vehicleDetails.ENGINE_NO;
            this.vehicleData.UNIQUE_NO = resp.vehicleDetails.UNIQUE_NO;
            this.vehicleData.SUB_CATEGORY = resp.vehicleDetails.SubCat_DESC;
            this.vehicleData.REFERRAL_NAME = resp.vehicleDetails.REF_CUST_NAME;
            this.vehicleData.SALE_DATE = CommonService.setDateTime(resp.vehicleDetails.SALE_DATE);
            this.vehicleData.SERVICE_BOOKLET_NO = resp.vehicleDetails.Service_Booklet_No;
            this.vehicleData.CWI_POLICY_NO = resp.vehicleDetails.POLICY_NO;
            this.vehicleData.CWI_WARRANTY_NO = resp.vehicleDetails.WARRANTY_BOOKELET_NO;
            this.vehicleData.INSURANCE_CUST_ID = resp.vehicleDetails.INS_CUST_ID;
            this.vehicleData.INSURANCE_POLICY_NO = resp.vehicleDetails.INS_POLICY_NO;
            this.vehicleData.INSURANCE_POLICY_DATE = resp.vehicleDetails.INS_POLICY_DT;
            this.vehicleData.INSURANCE_VALID_TO = resp.vehicleDetails.INS_VALID_TILL;
            this.vehicleData.AMC_NO = resp.vehicleDetails.AMC_ID;
            // this.vehicleData.AMC_DESC = resp.vehicleDetails.INS_POLICY_DT;
            // this.vehicleData.AMC_VALID_FROM = resp.vehicleDetails.INS_POLICY_DT;
            // this.vehicleData.AMC_VALID_TO = resp.vehicleDetails.INS_POLICY_DT;
            this.vehicleData.REAR_TYRE_NO = resp.vehicleDetails.REAR_TYRE_MAKE;
            this.vehicleData.FRONT_TYRE_NO = resp.vehicleDetails.FRONT_TYRE_MAKE;
            this.vehicleData.REAR_RIGHT_TYRE_NO = resp.vehicleDetails.FIELD_1;
            this.vehicleData.REDUCER_KIT_NO = resp.vehicleDetails.FIELD_3;
            this.vehicleData.BATTERY_MAKE = resp.vehicleDetails.BATTERY_MAKE;
            this.vehicleData.BATTERY_NO = resp.vehicleDetails.BATTERY_NO;
            this.vehicleData.CURRENT_READING = resp.vehicleDetails.BATTERY_NO;
            this.vehicleData.REPLACED_AT = resp.vehicleDetails.REPLACED_AT;
            this.vehicleData.CUMULATIVE_READING = resp.vehicleDetails.CUM_ODOM_READ;
            this.vehicleData.GRN_DATE = CommonService.setDateTime(resp.vehicleDetails.GRN_DATE);
            // this.vehicleData.INVOICE_NO = resp.vehicleDetails.INS_POLICY_DT;
            this.vehicleData.PRODUCTION_MONTH = resp.vehicleDetails.PROD_MON;
            this.vehicleData.PRODUCTION_YEAR = resp.vehicleDetails.PROD_YR;
            this.vehicleData.TVS_INVOICE_PRICE = resp.vehicleDetails.TVS_INV_PRICE;
            this.vehicleData.PLANT = resp.vehicleDetails.PLANT_ID;
            this.vehicleData.TAX_PAID = resp.vehicleDetails.TaxValue;
            this.vehicleData.SHOWROOM_PRICE = resp.vehicleDetails.EX_SHRM_PRICE;
            if (resp.vehicleDetails.INS_COMP_ID == null) {
              this.isTypeDisable = true;
            }
            else {
              this.masterData.insuranceCompanList.filter(key => {
                if (key.INS_COMP_ID == resp.vehicleDetails.INS_COMP_ID) {
                  this.vehicleData.INSURANCE_COMPANY = key.INS_COMP_ID;
                }
              });
              this.masterData.insuranceList.filter(key => {
                if (key.INS_COMP_ID == this.vehicleData.INSURANCE_COMPANY) {
                  this.insuranceList.push(key);
                  this.vehicleData.INSURANCE_TYPE = key.INS_TYPE_ID;
                  this.isTypeDisable = true;
                }
              });
            }
          }
          if (resp.statusCode == 500) {
            this.toastr.error(resp.statusMessage);
            this.loginService.logout();
          }
          this.isShowPageLoader = false;
        },
        error => {
          if (error.statusCode == 401) {
            this.toastr.error(error.error.Message);
          }
        })
  }

  changeCompany() {
    if (this.vehicleData.INSURANCE_COMPANY == -1) {
      this.isTypeDisable = false;
      this.vehicleData.INSURANCE_TYPE = -1;
    }
    else {
      this.isTypeDisable = true;
      this.insuranceList = [];
      this.masterData.insuranceList.filter(key => {
        if (key.INS_COMP_ID == this.vehicleData.INSURANCE_COMPANY) {
          this.insuranceList.push(key);
          this.vehicleData.INSURANCE_TYPE = -1;
        }
      });
    }
  }

  customerDetails(str, category) {
    const dialogRef = this.dialog.open(JobCardSearchComponent, {
      width: '1200px',
      data: { key: str },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("Result", result);
        if (category == 'saleThrough') {
          // this.vehicleData.INSURANCE_TYPE = result.obj.CUSTOMER_ID;
          this.vehicleData.SALES_TROUGH = result.obj.CUST_NAME;
        }
        if (category == 'ReferralName') {
          // this.vehicleData.INSURANCE_TYPE = result.obj.REF_CUST_ID;
          this.vehicleData.REFERRAL_NAME = result.obj.REF_CUST_NAME;
        }
      }
    });
  }

  saveVehicleDetails() {
    this.isShowPageLoader = true;
    let reqObj = {
      "Dealer_ID": CommonService.getUserData().DEALER_ID,
      "ROW_STATE": 0,
      "VEHICLE_ID": this.vehicleData.SERVICE_BOOKLET_NO,
      "IS_OLD_VEHICLE": null,
      "Service_Booklet_No": this.vehicleData.SERVICE_BOOKLET_NO,
      "REG_NO": this.vehicleData.REG_NO,
      "UNIQUE_NO": this.vehicleData.UNIQUE_NO,
      "MODEL_ID": this.vehicleData.MODEL,
      "PART_ID": this.vehicleData.PART,
      "ENGINE_NO": this.vehicleData.ENGINE_NO,
      "FRAME_NO": this.vehicleData.FRAME_NO,
      "KEY_NO": this.vehicleData.KEY_NO,
      "REF_CUST_ID": this.vehicleData.REFERRAL_NAME,
      "SALE_THR_ASC_ID": this.vehicleData.SALES_TROUGH,
      "INS_COMP_ID": this.vehicleData.INSURANCE_COMPANY,
      "INS_TYPE_ID": this.vehicleData.INSURANCE_TYPE,
      "INS_CUST_ID": this.vehicleData.INSURANCE_CUST_ID,
      "INS_POLICY_NO": this.vehicleData.INSURANCE_POLICY_NO,
      "INS_POLICY_DT": this.vehicleData.INSURANCE_POLICY_DATE,
      "INS_VALID_TILL": this.vehicleData.INSURANCE_VALID_TO,
      "AMC_ID": this.vehicleData.AMC_NO,
      "REAR_TYRE_MAKE": this.vehicleData.REAR_TYRE_NO,
      "FRONT_TYRE_MAKE": this.vehicleData.FRONT_TYRE_NO,
      "FIELD_1": this.vehicleData.REAR_RIGHT_TYRE_NO,
      "FIELD_2": this.vehicleData.SERVICE_BOOKLET_NO,
      "FIELD_3": this.vehicleData.REDUCER_KIT_NO,
      "BATTERY_MAKE": this.vehicleData.BATTERY_MAKE,
      "POLICY_NO": this.vehicleData.CWI_POLICY_NO,
      "WARRANTY_BOOKELET_NO": this.vehicleData.CWI_WARRANTY_NO,
      "BATTERY_NO": this.vehicleData.BATTERY_NO,
      "CUR_ODOM_READ": this.vehicleData.CURRENT_READING,
      "REPLACED_AT": this.vehicleData.REPLACED_AT,
      "CUM_ODOM_READ": this.vehicleData.CUMULATIVE_READING,
      "GRN_DATE": this.vehicleData.GRN_DATE,
      "SALE_DATE": this.vehicleData.SALE_DATE,
      "STATUS": 5,
      "PROD_MON": this.vehicleData.PRODUCTION_MONTH,
      "PROD_YR": this.vehicleData.PRODUCTION_YEAR,
      "PLANT_ID": this.vehicleData.PLANT,
      "IS_CST_PUR": this.vehicleData.SERVICE_BOOKLET_NO,
      "TVS_INV_PRICE": this.vehicleData.TVS_INVOICE_PRICE,
      "INPUT_TAX_VALUE": this.vehicleData.TAX_PAID,
      "EX_SHRM_PRICE": this.vehicleData.SHOWROOM_PRICE,
      "BASIC_PRICE": this.vehicleData.SERVICE_BOOKLET_NO,
    }
    if (this.data.type == 'MODIFY') {
      reqObj.ROW_STATE = 1;
    }
    this.masterService.post(reqObj, '/DMSMaster/SaveVehicleDetail').subscribe(
      (resp: any) => {
        if (resp.statusCode == 200) {
          this.masterData = resp.vehicleList;
        }
        if (resp.statusCode == 204) {
          this.toastr.info(resp.statusMessage);
        }
        this.isShowPageLoader = false;
      },
      error => {
        if (error.statusCode == 401) {
          this.toastr.error(error.error.Message);
          this.isShowPageLoader = false;
        }
      })
  }

  closePopup() {
    this.dialogRef.close();
  }

}

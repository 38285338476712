import { Component, OnInit,Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'app-common-confirmation-dialog',
  templateUrl: './common-confirmation-dialog.component.html',
  styleUrls: ['./common-confirmation-dialog.component.scss']
})
export class CommonConfirmationDialogComponent implements OnInit {

  // Class Variable
   title:string;
   message:string;
   
  //
  // constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  //   @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
  //   // Update view with given values
  //   this.title = data.title;
  //   this.message = data.message;
  // }
  constructor(public dialogRef:MatDialogRef<CommonConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:ConfirmDialogModel) 
              {
                this.title=data.title;
                this.message=data.message;

               }

  ngOnInit() 
  {
  }

  // onConfirm(): void {
  //   // Close the dialog, return true
  //   this.dialogRef.close(true);
  // }

  // onDismiss(): void {
  //   // Close the dialog, return false
  //   this.dialogRef.close(false);
  // }
  
  onDismiss():void
  {
    //Close the dialog,return false
    this.dialogRef.close(false);
  }

  onConfirm():void{

    // Close the dialog, return true
    this.dialogRef.close(true);
  }
  

}
export class ConfirmDialogModel
  {
    constructor(public title:string,public message:string)
    {

    }
  }

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/api-services/common.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public lang: any;
  public AMCReasonList = [];
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      if (this.data.isAvailReason) {
        if (CommonService.getServAppData()) {
          this.AMCReasonList = CommonService.getServAppData().AMCReasonList;
        }
      }
    }
  }
}

/**
 * Created BY HMSPL 
 * Last Last Modified By Balaji on 10/10/2018
 * Last Modified Description : Added Comment
 */
import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import * as XLSX from 'xlsx';
import { CommonService } from 'src/app/api-services/common.service';
import { ToastrService } from 'ngx-toastr';

type AOA = any[][];

@Component({
	selector: 'app-excel-upload',
	templateUrl: './excel-upload.component.html',
	styleUrls: ['./excel-upload.component.scss']
})
export class ExcelUploadComponent {
	@Input() disabled: boolean = false;
	@ViewChild('fileInput') fileInputVariable: any;
	@Output() changeFile = new EventEmitter();
	lang: any
	// data: AOA = [];
	rawData: AOA = [];
	isShowPageLoader = false;
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: any = 'SheetJS.xlsx';
	constructor(private toastr: ToastrService) {
		if (CommonService.getLang()) {
			this.lang = CommonService.getLang();
		}
	}
	uploadFile(evt: any) {
		this.isShowPageLoader = true;
		if (evt.target.files && evt.target.files.length > 0) {
			let file = evt.target.files[0];
			if (file.name.indexOf('.xls') > -1 || file.name.indexOf('.xlsm') > -1 || file.name.indexOf('.xltm') > -1
				|| file.name.indexOf('.xlsx') > -1 || file.name.indexOf('.xlsm') > -1) {
				if (evt.target.files && evt.target.files.length == 1) {
					let finalData = [];
					/* wire up file reader */
					const target: DataTransfer = <DataTransfer>(evt.target);
					// if (target.files.length !== 1) 
					// throw new Error('Cannot use multiple files');
					const reader: FileReader = new FileReader();
					reader.onload = (e: any) => {
						/* read workbook */
						const bstr: string = e.target.result;
						const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

						/* grab first sheet */
						const wsname: string = wb.SheetNames[0];
						const ws: XLSX.WorkSheet = wb.Sheets[wsname];

						/* save data */
						let tempData = [];
						tempData = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
						tempData.filter(key => {
							finalData.push(key);
						});
						if (finalData.length == 0) {
							this.toastr.warning(this.lang.Message.UPLOAD.EMPLTY_FILE);
							this.clearValues();
						} else {
							this.changeFile.emit(finalData);
							this.clearValues();
						}
					};
					reader.readAsBinaryString(target.files[0]);
				} else if (evt.target.files && evt.target.files.length > 1) {
					this.toastr.warning(this.lang.Message.UPLOAD.INVALID_SINGLE);
					this.clearValues();
				} else {
					this.changeFile.emit(null);
					this.clearValues();
				}
			} else {
				this.toastr.warning(this.lang.Message.UPLOAD.INVALID_FILE);
				this.clearValues();
			}
		}
	}
	clearValues() {
		setTimeout(() => {
			this.fileInputVariable.nativeElement.value = '';
			this.isShowPageLoader = false;
		}, 1000);
	}
	export(): void {
		// /* generate worksheet */
		// const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

		// /* generate workbook and add the worksheet */
		// const wb: XLSX.WorkBook = XLSX.utils.book_new();
		// XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		// /* save to file */
		// XLSX.writeFile(wb, this.fileName);
	}
}



export class Vehicle {
    REG_NO: string;
    MODEL_ID: string;
    PART_ID: string;
    ENGINE_NO: string;
    FRAME_NO: string;
    PROD_MON: string;
    PROD_YR: string;
    KEY_NO: string;
    Service_Booklet_No: string;
    SALE_DATE: any;
    STATUS: string;
    INS_COMP_ID: string;
    ROW_STATE: string;
    DEALER_ID:number;
    VEHICLE_ID:number;
}

export class Customer {
    DEALER_ID: string;
    BRANCH_ID: string;
    CUSTOMER_ID:Number;
    CUST_TY_ID: number;
    CUST_GRP_ID: number;
    CUST_TAX_CAT_ID: number;
    CUST_NAME: string;
    IS_PROSPECT: boolean;
    GENDER: string;
    CREDIT_CARD: boolean;
    MARRIED: boolean;
    SEND_SERV_REM: boolean;
    SL_CODE: number;
    ROW_STATE: string;
    AREA_NAME: string;
    AREA_ID: any;
    CITY: string;
    STATUS:number;
    CREATED_BY: number;
    MODIFIED_BY:number;
}

export class CommunicationAddress {
    ADDRESS_LINE_1: string;
    ADDRESS_LINE_2: string;
    ADDRESS_TYPE: number;
    AREA_ID: number;
    CITY: string;
    PIN_CODE: string;
    STATE_ID: string;
    IS_COMM_ADD_VALID: boolean;
    SAME_AS_COMM: boolean;
    AREA_NAME: string;
    CompleteAddress: string;
    CUSTOMER_ID: number;
    District: string;
    LANDMARK:string;
}

export class CommnAddressPhone {
    STD_CODE: string;
    CONTACT_NO: string;
    CONTACT_NO_2: string;
    EXTN: string;
    PHONE_TYPE: number;
    IS_VALID: boolean;
    IS_VALID_CONTACT_2: boolean;
    IS_PREFERRED: boolean;
    ROW_STATE: string;
    CUSTOMER_ID: number;
    LANDMARK:string;
}

export class LedgerList {
    ACC_GRP_ID: number;
    AccGrpDescription: string;
    ACTIVE: boolean;
    DEALER_ID: number;
    DESCRIPTION: string;
    GL_CODE: number;
    ROW_STATE: string;
    SL_REQ: boolean;
}

export class CustomerRootObject {
    Vehicle: Vehicle;
    Customer: Customer;
    CommunicationAddress: CommunicationAddress;
    CommnAddressPhone: CommnAddressPhone;
    LedgerList: LedgerList[];
}


export class CUSTOMER_ANGULAR_DET
{
    DEALER_ID: number;
    CUSTOMER_ID: number;
    REF_CUST_ID?: number;
    CUST_NAME: string;
    CUSTOMER_TYPE: string;
    REF_CUST_TYPE: string;
    GST_IN_NO: string;
    COM_STATE_ID: string;
    REG_STATE_ID: string;
    COM_FULL_ADDR: string;
    REG_FULL_ADDR: string;
    MOB_PHONE: string;
    COM_PHONE: string;
    CUST_TAX_CAT_ID: number;
    CUST_CAT_ID: number;
    CUST_GRP_ID: number;
    CUST_TY_ID: number;
    SL_CODE?: number;
    VEH_CRED_LIM?: number;
    SPR_CRED_LIM?: number;
    GEN_CRED_LIM?: number;
    IsLastSale: boolean;
}



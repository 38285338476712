/**
 * Created BY HMSPL 
 * Service AMC Service Class
 * Last Modified By Dharvesh on 08/09/2018
 * Last Modified Description : Created Vehicle Service History related services
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceAppoitmentService {
    constructor(private httpClient: HttpClient) {
    }

    /**
   * Get Serviec Appoitment List
   * @param {Object} params 
   * return Object
   */
    getServiceAppoitment(params) {
        const resData = RequestService.getApiURLReq('/Service/SearchServiceAppDetails');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    GetInfoBasedOnFrameNoRegNo(params) {
        const resData = RequestService.getApiURLReq('/Service/GetInfoBasedOnFrameNoRegNo');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    createServiceAppointment(data) {
        const resData = RequestService.postApiURLReq('/Service/SaveServiceAppointmentDetails');
        return this.httpClient.post(resData.url, data, { headers: resData.headers });
    }
    getAppointmentByAppId(params) {
        const resData = RequestService.getApiURLReq('/Service/PopulateServiceAppDetails');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
}
/**
 * Created BY HMSPL 
 * Moment date pipe
 * Last Modified By Balaji on 10/09/2018
 * Last Modified Description : Created pipe for capitalize
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {
    transform(value: any) {
      if (value) {
        return value.charAt(0).toLocaleUpperCase() + value.slice(1);
      }
      return value;
    }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { CommonService } from 'src/app/api-services/common.service';
import { ToastrService } from 'ngx-toastr';
import { PmpSparesDO } from '../../api-services/dto/job-card';
import { JobCardService } from '../../api-services/job-card.service';
import { LoginService } from 'src/app/api-services/login.service';
@Component({
  selector: 'app-pmp-reason',
  templateUrl: './pmp-reason.component.html',
  styleUrls: ['./pmp-reason.component.scss']
})
export class PmpReasonComponent implements OnInit {
  public isShowLoader = false;
  public isRootDisabled = true;
  public lang: any;
  public isShowPageLoader: boolean = false;
  pmpCancelDO = new PmpSparesDO();
  public partsHeader = [];
  refPartList: Array<any> = [];
  cancelReason: Array<any> = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    public dialogRef: MatDialogRef<PmpReasonComponent>,
    private toastr: ToastrService, private loginService: LoginService,
    private jobCardService: JobCardService) {
  }
  ngOnInit() {
    this.lang = CommonService.getLang();
    let appData = CommonService.getServAppData();
    this.cancelReason = appData.PmpCancelReason;
    this.partsHeader = [
      {
        name: this.lang.SERVICES.partNo,
        width: 'wmpx-130'
      },
      {
        name: this.lang.SERVICES.partDescription,
        width: 'wmpx-250'
      },
      {
        name: this.lang.SERVICES.reason + ' *',
        width: 'wmpx-180'
      }, {
        name: this.lang.SERVICES.remarks + ' *',
        width: 'wmpx-200'
      }
    ];
    this.refPartList = this.data.PmpPartList;
    if (this.refPartList && this.refPartList.length) {
      this.refPartList.filter(key => {
        key.DEALER_ID = CommonService.getUserData().DEALER_ID;
        key.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
        key.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
      })
    }
  }

  ClosePage() {
    this.dialogRef.close(false);
  }
  /**
 * Validate Save
 * * @param {Object} jcObj
 */
  validateSave(jcObj) {
    let valid = true;
    let bulkSaveParam = JSON.parse(JSON.stringify(this.refPartList));
    bulkSaveParam.filter(chk => {
      if (chk.REASON_ID == "" || chk.REASON_ID == 0) {
        this.toastr.warning("Kindly Choose Reason for PMP Part No " + chk.PART_NO + "");
        return valid = false;
      }
      else if (chk.REASON_ID == 4 && (chk.REASON_RMKS == "" || chk.REASON_RMKS == null)) {
        this.toastr.warning("Kindly fill remarks for PMP Part No " + chk.PART_NO + "");
        return valid = false;
      }
    });
    return valid;
  }

  bulkSave() {
    let bulkSaveParam = JSON.parse(JSON.stringify(this.refPartList));
    if (this.validateSave(bulkSaveParam)) {
      this.isShowPageLoader = true;
      let req = {
        "DEALER_ID": CommonService.getUserData().DEALER_ID,
        "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
        "USER_ID": CommonService.getUserData().USER_ID,
        "_PmpList": this.refPartList
      };
      this.jobCardService.pmpCancelSave(req).subscribe((resp: any) => {
        if (resp && resp.statusCode && resp.statusCode == 200) {
          this.toastr.success(this.lang.Message.JOB_CARD.PMP_Saved_Successfully);
          this.dialogRef.close(resp);
          this.isShowPageLoader = false;
        } else {
          this.toastr.error(resp.message);
          this.isShowPageLoader = false;
        }
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
  }
}
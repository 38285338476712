import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-vendor-bill-payment',
  templateUrl: './vendor-bill-payment.component.html',
  styleUrls: ['./vendor-bill-payment.component.scss']
})
export class VendorBillPayment implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public apsGrn: any = {};
  public part: any={};
  public vendorList: any = [];
  public vendorRows: any = [];
  public vendorBillPaymentLists: any = [];
  public grnvalue: string="";
  // public status: any = [
  //   {
  //     name: 'GRN completed',
  //     value: '0'
  //   },
  //   {
  //     name: 'GRN not completed',
  //     value: '2'
  //   }
  // ]
  // public GRN_TYPE: any = [
  //   {
  //     name: 'GRN_TVS',
  //     value: '1'
  //   },
  //   {
  //     name: 'GRN_AMD',
  //     value: '2'
  //   },
  //   {
  //     name: 'OTHER_VENDOR_GRN',
  //     value: '3'
  //   },
  // ];
   public listtypes = [
    {  value:1, name: "GRN_TVS" },
    { value:2, name: "GRN_AMD" },
    {  value:3, name: "OTHER_VENDOR_GRN" },
  ];
  public isShowPageLoader: boolean;

  constructor(
    private dialogRef: MatDialogRef<VendorBillPayment>,
    private masterService: MasterService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  ngOnInit() {   
    if (this.data) {
      this.vendorList = this.data.vendorList && this.data.vendorList.length > 0 ? this.data.vendorList : [];
      this.vendorList.forEach(value => {
        if(this.data.vendorName == value) {
          this.apsGrn.APS_Code = value.APS_Code;
        }
      });
    }
    this.inlineFilter = {
        GRN_ID: '',
        GRN_DATE: '',
        GRN_TYPE_ID:'',
        TVS_INVOICE_NO:'',
        TVS_INVOICE_DATE:'',
           
    };
  }

  apsGrnSearch() {
    let bookingAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('Booking From Date is empty');
      bookingAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('Booking to Date is empty ');
      bookingAdvanceError++;
    }
    if (bookingAdvanceError > 0) {
      return false;
    }
  
    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.GRN_TYPE_ID= (<HTMLSelectElement>document.getElementById('Grn')).value;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
    reqObj.TVS_INVOICE_NO=0;
     reqObj.TVS_INVOICE_DATE_FROM = fromDateFormat;
     reqObj.TVS_INVOICE_DATE_TO = toDateFormat;
    this.vendorBillPaymentLists=[];    
    this.isShowPageLoader = true;
    this.masterService.post(reqObj, '/Accounts/SearchVendorBillPaymentList').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.vendorBillPaymentLists = resp.vendorBillPaymentList ;
        
        } 
        else if (resp && resp.statusCode == 204) {
          this.vendorBillPaymentLists = [];
          this.isShowPageLoader = false;
          return this.toastr.error(resp.statusMessage);
        }
        else {
          this.isShowPageLoader = false;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      })
    }
  selectedItem() {
      if (this.selected[0].STATUS == 2 || this.selected[0].STATUS == 3) {
      this.toastr.error('GRN is completed.Please select the another invoice...');
      return;
    }
    let closeObj = { obj: this.selected[0]};
    this.dialogRef.close(closeObj);
  }

  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { MasterSales } from 'src/app/api-services/master.sales';
import { DigiSignComponent } from 'src/app/shared/digi-sign/digi-sign.component';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-digi-generate-pdf',
  templateUrl: './digi-generate-pdf.component.html',
  styleUrls: ['./digi-generate-pdf.component.scss']
})
export class DigiGeneratePdfComponent implements OnInit {
  public lang: any;
  public selected = [];
  public remSelected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public warData: any;
  public eSignData: any;
  constructor(
    public dialogRef: MatDialogRef<DigiGeneratePdfComponent>,public dialog: MatDialog,
    private toastr: ToastrService,
    private masterServices: MasterService, private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    this.warData = this.data;
  }

  ngOnInit() {

  }



  UploadEsignPDF(row,index){

    var respWar: any;
    this.isShowPageLoader = true;
    this.isShowPageLoader = true;
    let reqObj: any = {};
    reqObj.DEALER_ID=CommonService.getUserData().DEALER_ID;
    reqObj.BRANCH_ID=CommonService.getUserData().BRANCH_ID;
    reqObj.DLR_WAR_CLM_ID= row.DLR_WAR_CLM_ID ? row.DLR_WAR_CLM_ID : 0;
    reqObj.PLANT=row.PLANT;
    reqObj.PDF_NAME=row.INVOICE_PDF;
   
    this.masterSales.post(reqObj, '/Service/DigiSignUploadFile').subscribe((resp: any) => {
      if (resp && resp.data && resp.statusCode === 200 ) {
        respWar=resp.data;
        if(respWar !=null){
          this.eSignData=respWar;
          const dialogRef = this.dialog.open(DigiSignComponent, {
            width: '80%',
            minHeight: '250px',
            maxHeight: '700px',
            height: 'auto',
            panelClass: 'paymentPopUpData',
            data: this.eSignData[0].decodedWidgetUrl,
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe(result => {
           // alert(result);
            if (result=="true") {
              this.warData=[];
              this.isShowPageLoader = true;
              let reqObj: any = {};
              reqObj.DEALER_ID=CommonService.getUserData().DEALER_ID;
              reqObj.BRANCH_ID=CommonService.getUserData().BRANCH_ID;
              reqObj.DLR_WAR_CLM_ID= row.DLR_WAR_CLM_ID ? row.DLR_WAR_CLM_ID : 0;
              this.masterSales.post(reqObj, '/Service/GetWarClmPdfGenerated').subscribe(
                (resp: any) => {
                  if (resp && resp.statusCode === 200 && resp.data) {
                    this.warData=resp.data;
                  } else {
                    this.toastr.error(resp.message);
                  }
                  this.isShowPageLoader = false;
                },
                (error) => {
                  this.toastr.error(error.error.Message);
                  this.isShowPageLoader = false;
                }
              );
  
            } 
          });
        }
       
      } else {
        this.toastr.error(resp.message);
      }
      this.isShowPageLoader = false;
    }, error => {
      this.toastr.error(error.error.Message);
      this.isShowPageLoader = false;
    });

  }

  DownloadEsignPDF(row,index){
    this.isShowPageLoader = true;
    let reqObj: any = {};
    reqObj.DEALER_ID=CommonService.getUserData().DEALER_ID;
    reqObj.BRANCH_ID=CommonService.getUserData().BRANCH_ID;
    reqObj.DLR_WAR_CLM_ID= row.DLR_WAR_CLM_ID ? row.DLR_WAR_CLM_ID : 0;
    reqObj.PLANT= row.PLANT;
    reqObj.UUID= row.UUID;
    this.masterSales.post(reqObj, '/Service/DownloadSignedFile').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode === 200 && resp.data) {
         // alert(resp.data);
          this.openPrint(resp.data);
        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
      },
      (error) => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
      }
    );

  }

  openPrint(filepath) {
    let popupWinindow
    let innerContents = filepath;
    popupWinindow = window.open(filepath, '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no', true);
  }

}

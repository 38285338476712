import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { LoginService } from 'src/app/api-services/login.service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
@Component({
  selector: 'app-part-excel-bulk-upload',
  templateUrl: './part-excel-bulk-upload.component.html',
  styleUrls: ['./part-excel-bulk-upload.component.scss']
})
export class PartExcelBulkUploadComponent implements OnInit {
  public isShowPageLoader: boolean = false;
  public lang: any;
  public rawHeader: any = [];
  public tabledata: any = [];
  public exceltoJson: any = {};
  public tableheader: any = [];
  public orderList: any = [];
  public bulkUpload: any;
  public result: any = [];
  public isActiveResult: boolean = false;
  constructor(private toastr: ToastrService,
    private router: Router,
    private masterService: MasterService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PartExcelBulkUploadComponent>) { }

  ngOnInit() {
    this.bulkUpload = this.data.value;
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }

  getFileExtension(filename){
    const extension = filename.split('.').pop();
    return extension;
  }

  onFileChange(event: any) {
    this.isShowPageLoader = true;
    this.exceltoJson = {};
    let headerJson = {};
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    // if (target.files.length !== 1) {
    //   throw new Error('Cannot use multiple files');
    // }
    const reader: FileReader = new FileReader();
    if (target.files.length != 0) {
      reader.readAsBinaryString(target.files[0]);
      console.log("filename", target.files[0].name);
      if(this.getFileExtension(target.files[0].name) == "xlsx" || this.getFileExtension(target.files[0].name) == "xls"){
        this.exceltoJson['filename'] = target.files[0].name;
        reader.onload = (e: any) => {
          this.isShowPageLoader = true;
          /* create workbook */
          const binarystr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
          for (var i = 0; i < wb.SheetNames.length; ++i) {
            const wsname: string = wb.SheetNames[i];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
            this.exceltoJson[`sheet${i + 1}`] = data;
            const headers = this.get_header_row(ws);
            headerJson[`header${i + 1}`] = headers;
            console.log("json", headers)
          }
          this.exceltoJson['headers'] = headerJson;
          console.log("result", this.exceltoJson);
          this.tableheader = this.exceltoJson.headers.header1;

          console.log("header", this.tableheader);
          if(this.tableheader[0] != "DealerId" ||
            this.tableheader[1] != "PartNumber" || 
            this.tableheader[2] != "PartDescription" || 
            this.tableheader[3] != "ManufacturerName" ||
            this.tableheader[4] != "HsnCode" || 
            this.tableheader[5] != "PartCost" || 
            this.tableheader[6] != "MRP" ||
            this.tableheader[7] != "Quantity"){
            this.toastr.info("Invalid Excel");
            this.isShowPageLoader = false;
            return;
          }
          for (var i = 0; i < this.exceltoJson.sheet1.length; i++) {
            if(this.bulkUpload == true){
              this.tabledata.push({
                DealerId: '',
                PartNumber: '',
                PartDescription: '',
                ManufacturerName: '',
                HsnCode: '',
                PartCost: '',
                MRP: '',
                Quantity: '',
              });
              //DealerId
              this.tabledata[i].DealerId = this.exceltoJson.sheet1[i]["DealerId"] ? this.exceltoJson.sheet1[i]["DealerId"] : null;
              this.tabledata[i].PartNumber = this.exceltoJson.sheet1[i]["PartNumber"] ? this.exceltoJson.sheet1[i]["PartNumber"] : null;
              this.tabledata[i].PartDescription = this.exceltoJson.sheet1[i]["PartDescription"] ? this.exceltoJson.sheet1[i]["PartDescription"] : null;
              this.tabledata[i].ManufacturerName = this.exceltoJson.sheet1[i]["ManufacturerName"] ? this.exceltoJson.sheet1[i]["ManufacturerName"] : null;
              this.tabledata[i].HsnCode = this.exceltoJson.sheet1[i]["HsnCode"] ? this.exceltoJson.sheet1[i]["HsnCode"] : null;
              this.tabledata[i].PartCost = this.exceltoJson.sheet1[i]["PartCost"] ? this.exceltoJson.sheet1[i]["PartCost"] : null;
              this.tabledata[i].MRP = this.exceltoJson.sheet1[i]["MRP"] ? this.exceltoJson.sheet1[i]["MRP"] : null;
              this.tabledata[i].Quantity = this.exceltoJson.sheet1[i]["Quantity"] ? this.exceltoJson.sheet1[i]["Quantity"] : null;
             }
          }
          // console.log("tabledata Before", this.tabledata);
          // if (this.tabledata.length > 1) {
          //   this.tabledata.pop();
          // }
          console.log("tabledata", this.tabledata);
          this.isShowPageLoader = false;
        }
      }
      else{
        this.isShowPageLoader = false;
        this.toastr.error("Please Upload a Spreadsheet");
        return;
      };
    } else {
      this.tabledata = [];
      this.isShowPageLoader = false;
    }
  }

  get_header_row(sheet) {
    this.isShowPageLoader = true;
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
      // console.log("cell",cell)
      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
    }
    this.isShowPageLoader = false;
    return headers;
  }

  excelUpload() {
    this.isShowPageLoader = true;
    if (this.tabledata.length == 0) {
      this.toastr.info("No Data Available");
      this.isShowPageLoader = false;
      return;
    }
    let reqObj: any = {
      "Parts":[]
  }
    reqObj.Parts = this.tabledata;
    this.masterService.getExcel(reqObj, '/v1/BulkUploadParts/UploadFromExcel').subscribe(
      (resp: any) => {  
        if(resp.status == 204){
          this.toastr.info("No Data Available");
          this.isShowPageLoader = false;
        }
        if(resp.status == 200){
          var Name = resp.headers.get('content-disposition');
          resp.body.fileName = Name.slice(21);
          this.isShowPageLoader = false;
          const blob = new Blob([resp.body], { type: resp.body.type });
          fs.saveAs(blob, resp.body.fileName);
          this.dialogRef.close();
        }
      }, error => {
          this.toastr.error(error.statusText);
          this.isShowPageLoader = false;
      });
  }

  sampleExcel() {
    var host = document.location.origin;
    let link = document.createElement("a");
    link.download = "partBulkUpload.xlsx";
    // link.href = "src/assets/partBulkUpload.xlsx"; // Local
    link.href = host + "/assets/partBulkUpload.xlsx"; // Live
    link.click();
  }

}
import { Directive,ElementRef,HostListener } from '@angular/core';

@Directive({
  selector: '[appBlockSpecialChars]'
})
export class BlockSpecialCharsDirective {

  constructor() { }

  @HostListener("keydown",['$event']) ngOnChanges(event)
   {
     //
     //let elementValue=this.el.nativeElement.value;
    // console.log(elementValue);
    // var letters = /^[0-9a-zA-Z]+$/; 
    // if(letters).te
   // event.keyCode < 48 || event.keyCode > 57)
      //if (/^[0-9a-zA-Z]+$/.test(elementValue)) {
       // = ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || keyCode == 32 || (keyCode >= 97 && keyCode <= 122) || (specialKeys.indexOf(e.keyCode) != -1 && e.charCode != e.keyCode));

       //

  //var ret = ((keyCode > 64 && keyCode < 91) || (keyCode > 96 && keyCode < 123) || keyCode == 8 || (keyCode >= 48 && keyCode <= 57));
     if((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || event.keyCode == 8 )
        {
              return true;
        } else {
              event.preventDefault();
              return false;
            }
    
   }

}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/api-services/common.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-asc-fsc-claim-dialog',
    template: `
    <div class="basicInfoView ">
    
    <div class="sectionHeader p-12">
    {{lang?.MENU?.ASCFSCClaim}}
    </div>

    <div class="row mlr-0 p-12">
    {{lang?.Message?.ASCFSC?.INVALID_FILE_DATA}}
    </div>

    <div class="row mlr-0 p-12" *ngIf="data && data.InvalidFrameList && data.InvalidFrameList.length>0">
        {{lang?.Message?.ASCFSC?.INVALID_FRAMENO}} - {{data.InvalidFrameList.length}}
        <i (click)="downloadData('INVALID_FRAME',data.InvalidFrameList)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
        </i>
    </div>  

    <div class="row mlr-0 p-12" *ngIf="data && data.DuplicateClaimList && data.DuplicateClaimList.length>0">
        {{lang?.Message?.ASCFSC?.DUP_FRAME}} - {{data.DuplicateClaimList.length}}
        <i (click)="downloadData('DUP_FRAME',data.DuplicateClaimList)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
        </i>
    </div> 

    <div class="row mlr-0 p-12" *ngIf="data && data.CustomerNotMappedList && data.CustomerNotMappedList.length>0">
        {{lang?.Message?.ASCFSC?.CUST_NOT_MAPPED_FRAME}} - {{data.CustomerNotMappedList.length}}
        <i (click)="downloadData('CUST_NOT_MAPPED_FRAME',data.CustomerNotMappedList)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
        </i>
    </div> 

    <div class="row mlr-0 p-12" *ngIf="data && data.CouponChargeEmptyList && data.CouponChargeEmptyList.length>0">
    {{lang?.Message?.ASCFSC?.CPN_CHRG_NOT_AVL}} - {{data.CouponChargeEmptyList.length}}
    <i (click)="downloadData('CPN_CHRG_NOT_AVL',data.CouponChargeEmptyList)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
    </i>
   </div> 

   <div class="row mlr-0 p-12" *ngIf="data && data.InvalidMobileNoList && data.InvalidMobileNoList.length>0">
   {{lang?.Message?.ASCFSC?.INVALID_MOB}} - {{data.InvalidMobileNoList.length}}
   <i (click)="downloadData('INVALID_MOB',data.InvalidMobileNoList)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
   </i>
   </div> 

   <div class="row mlr-0 p-12" *ngIf="data && data.NotInvoicedFrame && data.NotInvoicedFrame.length>0">
   {{lang?.Message?.ASCFSC?.NOT_INVOICED}} - {{data.NotInvoicedFrame.length}}
   <i (click)="downloadData('NOT_INVOICED',data.NotInvoicedFrame)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
   </i>
   </div> 

   <div class="row mlr-0 p-12" *ngIf="data && data.InvalidJobTypeList && data.InvalidJobTypeList.length>0">
   {{lang?.Message?.ASCFSC?.NOT_VALID_JTYPE}} - {{data.InvalidJobTypeList.length}}
   <i (click)="downloadData('NOT_VALID_JTYPE',data.InvalidJobTypeList)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
   </i>
   </div>  

   
   <div class="row mlr-0 p-12" *ngIf="data && data.NotValidList && data.NotValidList.length>0">
   {{lang?.Message?.ASCFSC?.INVALID_DATA}} - {{data.NotValidList.length}}
   <i (click)="downloadData('ASC_WARRANTY',data.NotValidList)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
   </i>
   </div>  


   <div class="row mlr-0 p-12" *ngIf="data && data.MboNotInvoiceList && data.MboNotInvoiceList.length>0">
   {{lang?.Message?.ASCFSC?.MBO_NOT_INVOICED}} - {{data.MboNotInvoiceList.length}}
   <i (click)="downloadData('MBO_NOT_INVOICED',data.MboNotInvoiceList)" title="{{lang?.GENERAL?.download}}" class="fa fa-download" aria-hidden="true" style="font-size: 20px;padding: 0px 10px;color: #1a6aa7;">
   </i>
   </div> 

    <div class="row mlr-0 p-12 flex align-right">
        <button class="cancelBtn mr-12" (click)="closeDialog()">{{lang?.GENERAL?.closeBtn}}</button>
    </div>

    </div>
    `,
    styles: [`
    .basicInfoView {
        background-color: #f6fbff;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 140px);
        overflow-x: hidden;
        overflow-y: auto;
        min-height: 200px;
    }
    .sectionHeader {
      width: 100%;
      border-bottom: 1px solid #dcdcdc;
      background-color: #d7edff;
      opacity: 0.9;
      color: #202020;
      font-family: Roboto;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 700;
      padding: 12px 12px 12px 12px;
    }
    label {
      color: #00599d !important;
    }
    span {
      opacity: 0.88;
      color: #1c1c1c;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
    }
    `]
})
export class AscFscClaimDialogComponent {
    public lang: any;

    constructor(private dialogRef: MatDialogRef<AscFscClaimDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }
    ngOnInit() {
        if (CommonService.getLang()) {
            this.lang = CommonService.getLang();
        }
    }
    /**
     * @param {String} 
     * close popup search dialog
    */
    closeDialog(): void {
        this.dialogRef.close();
    }
    downloadData(str, list) {
        let finalArr = [];
        list.filter(val => {
            if (str === 'INVALID_FRAME') {
                finalArr.push({
                    FRAME_NO: val.FRAME_NO
                })
            } else if (str === 'DUP_FRAME') {
                finalArr.push({
                    FRAME_NO: val.FRAME_NO,
                    jobtypedesc: val.jobtypedesc
                })
            }
            else if (str === 'CUST_NOT_MAPPED_FRAME') {
                finalArr.push({
                    FRAME_NO: val.FRAME_NO,
                    jobtypedesc: val.jobtypedesc
                })
            }
            else if (str === 'CPN_CHRG_NOT_AVL') {
                finalArr.push({
                    FRAME_NO: val.FRAME_NO,
                    jobtypedesc: val.jobtypedesc
                })
            }
            else if (str === 'INVALID_MOB') {
                finalArr.push({
                    FRAME_NO: val.FRAME_NO,
                    jobtypedesc: val.jobtypedesc
                })
            }
            else if (str === 'NOT_INVOICED') {
                finalArr.push({
                    FRAME_NO: val.FRAME_NO,
                    jobtypedesc: val.jobtypedesc
                })
            }
            else if (str === 'NOT_VALID_JTYPE') {
                finalArr.push({
                    FRAME_NO: val.FRAME_NO,
                    jobtypedesc: val.jobtypedesc
                })
            }
            else if (str === 'MBO_NOT_INVOICED') {
                finalArr.push({
                    FRAME_NO: val.FRAME_NO,
                    jobtypedesc: val.jobtypedesc
                })
            }
            else if (str === 'ASC_WARRANTY') {
                finalArr.push(val)
            }
        });
        const workBook = XLSX.utils.book_new(); // create a new blank book
        const workSheet = XLSX.utils.json_to_sheet(finalArr);
        if (str === 'INVALID_FRAME') {
            var wscols = [
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
        } else if (str === 'DUP_FRAME') {
            var wscols = [
                { wch: 25 },
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
            workSheet['B1'].v = "JOB TYPE";
        } else if (str === 'CUST_NOT_MAPPED_FRAME') {
            var wscols = [
                { wch: 25 },
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
            workSheet['B1'].v = "JOB TYPE";
        }
        else if (str === 'CPN_CHRG_NOT_AVL') {
            var wscols = [
                { wch: 25 },
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
            workSheet['B1'].v = "JOB TYPE";
        }
        else if (str === 'INVALID_MOB') {
            var wscols = [
                { wch: 25 },
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
            workSheet['B1'].v = "JOB TYPE";
        }
        else if (str === 'NOT_INVOICED') {
            var wscols = [
                { wch: 25 },
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
            workSheet['B1'].v = "JOB TYPE";
        }
        else if (str === 'NOT_VALID_JTYPE') {
            var wscols = [
                { wch: 25 },
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
            workSheet['B1'].v = "JOB TYPE";
        }
        else if (str === 'ASC_WARRANTY') {
            var wscols = [
                { wch: 25 },
                { wch: 25 },
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
            workSheet['B1'].v = "ITEM NO";
            workSheet['C1'].v = "TYPE OF INVALID";
        }
        else if (str === 'MBO_NOT_INVOICED') {
            var wscols = [
                { wch: 25 },
                { wch: 25 },
                { wch: 25 }
            ];
            workSheet['A1'].v = "FRAME NO";
            workSheet['B1'].v = "JOB TYPE";
        }
        workSheet['!cols'] = wscols;
        XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
        if (str === 'INVALID_FRAME') {
            XLSX.writeFile(workBook, 'invalidFrameNo.xlsx');
        } else if (str === 'DUP_FRAME') {
            XLSX.writeFile(workBook, 'duplicateFrameNo.xlsx');
        }
        else if (str === 'CUST_NOT_MAPPED_FRAME') {
            XLSX.writeFile(workBook, 'customernotmappedFrameNo.xlsx');
        }
        else if (str === 'CPN_CHRG_NOT_AVL') {
            XLSX.writeFile(workBook, 'cpnchargenotavlFrameNo.xlsx');
        }
        else if (str === 'INVALID_MOB') {
            XLSX.writeFile(workBook, 'invalidmobFrameNo.xlsx');
        }
        else if (str === 'NOT_INVOICED') {
            XLSX.writeFile(workBook, 'notinvoicedframeno.xlsx');
        }
        else if (str === 'NOT_VALID_JTYPE') {
            XLSX.writeFile(workBook, 'notvalidJobtypeframeno.xlsx');
        }
        else if (str === 'ASC_WARRANTY') {
            XLSX.writeFile(workBook, 'ascWarrantyInvalidData.xlsx');
        }
        else if (str === 'MBO_NOT_INVOICED') {
            XLSX.writeFile(workBook, 'NotInvoicebyMBOData.xlsx');
        }
    }
}
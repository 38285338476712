import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-jobcard-advance-voucher',
  templateUrl: './jobcard-advance-voucher.component.html',
  styleUrls: ['./jobcard-advance-voucher.component.scss']
})
export class JobcardAdvanceVoucherComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public part: any = {};
  public masterDetails: any;
  public status: any = [];
  public maxDate:any = new Date();
  public partRows: any = [];
  
  public jobcardAdvanceRows: any = [];
  constructor(
    private dialogRef: MatDialogRef<JobcardAdvanceVoucherComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    if (this.data) {
      this.isActive = this.data && this.data.type ? this.data.type : '';
      this.inlineFilter = {
        JOB_CARD_NO: '',
        JOB_CARD_DATE: '',
        CUSTOMER_ID: '',
        CUST_NAME: '',
        REGIS_NO: '',
        JOB_TYPE_DESC: '',
        FRAME_NO: '',
        STATUS: '',
      };
      this.status = [
        {
          name: 'UnAllocated',
          value: '0'
        },
        {
          name: 'Allocated',
          value: '1'
        },
        {
          name: 'Invoiced',
          value: '4'
        },
        {
          name: 'JobComplete',
          value: '5'
        },
        {
          name: 'Closed',
          value: '6'
        }
      ];
    }
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  /**
   * select search list
   */
  selectedItem() {
    if (this.selected[0].STATUS == 6) {
      this.toastr.error('Job card is closed.Please select the another Job card...');
      return;
    }
    let closeObj = { obj: this.selected[0] };
    this.dialogRef.close(closeObj);
  }

  getJobcardAdvanceSearch() {
    let jobcardAdvanceError: any = 0;
    if (this.part.todate && !this.part.fromdate) {
      this.toastr.error('From Date is empty ');
      jobcardAdvanceError++;
    }
    if (this.part.fromdate && !this.part.todate) {
      this.toastr.error('To Date is empty ');
      jobcardAdvanceError++;
    }
    if (jobcardAdvanceError > 0) {
      return false;
    }

    this.isShowPageLoader = true;
    let reqObj: any = {};
    let toDateFormat: any;
    let fromDateFormat: any;
    fromDateFormat = this.part.fromdate ? CommonService.getReqDateFormat(this.part.fromdate).split('T')[0] : null;
    toDateFormat = this.part.todate ? CommonService.getReqDateFormat(this.part.todate).split('T')[0] : null;
    reqObj.DEALER_ID = CommonService.getDealerData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
    reqObj.JOB_CARD_NO = this.part.JOB_CARD_NO ? Number(this.part.JOB_CARD_NO) : null;
    reqObj.CUSTOMER_ID = this.part.CUSTOMER_ID ? Number(this.part.CUSTOMER_ID) : null;
    reqObj.DATE_FROM = fromDateFormat;
    reqObj.DATE_TO = toDateFormat;

    this.masterServices.post(reqObj, '/Accounts/SearchJobCardAdvanceList').subscribe((resp: any) => {
      if (resp && resp.statusCode == 200) {
        this.isShowPageLoader = false;
        this.jobcardAdvanceRows = resp.JobCardAdvance;
      } else if (resp && resp.statusCode == 204) {
        this.jobcardAdvanceRows = [];
        this.isShowPageLoader = false;
        return this.toastr.error(resp.statusMessage);
      }
      else {
        this.isShowPageLoader = false;
      }
    }, error => {
      this.isShowPageLoader = false;
      this.toastr.error(error.error.Message);
    })
  }

  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/api-services/common.service';
import { ThemeService } from 'src/app/api-services/utils/theme-service';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { isNull } from 'util';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
  public themeData:any;
  public lang:any;
  selectedTheme='default';
  selectedColor='#00599d';
  selectedFont = 'roboto';
  isShowPageLoader: boolean;
 
  constructor(public dialogRef: MatDialogRef<ColorPickerComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,private themeService:ThemeService,
     private masterServices :MasterService) {
    if(localStorage.getItem('theme')){
      let presentTheme = JSON.parse(localStorage.getItem('theme'));
      if(presentTheme.isGradiant){
        this.selectTheme(presentTheme.category);
      }else{
        this.selectedColor = presentTheme.color;
        this.selectColorPicker(presentTheme.color);
      }
    }
    if(localStorage.getItem('font')){
      this.selectedFont = localStorage.getItem('font');
    }
   }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
      this.getUserTheme(); 
    }
  }
  selectColorPicker(event){   
    let colorObj={
      '--background': event,
      '--bg-header': event,
      '--text-color': event,
      '--inner-header': this.changeRgba(event),
      isGradiant:false
    }
    this.selectedTheme = 'default';
    this.themeService.setActiveThem(colorObj);
    localStorage.setItem('theme',JSON.stringify({isGradiant:false,color:event}));
    localStorage.setItem('colorObj',JSON.stringify(colorObj));
    console.log(event);
    let flag = false;
     this.saveUserTheme(event,flag);
     
  }
  changeRgba(hexCode){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexCode)){
        c= hexCode.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.5)';
    }
  }

  selectTheme(category) {
    this.selectedTheme = category;
    let obj ={
      category:category,
      isGradiant:true
    }
    this.themeService.setActiveThem(obj);
    localStorage.setItem('theme',JSON.stringify(obj));
      let flag = true;
      this.saveUserTheme(category,flag);
  }
  selectFont(){
    this.themeService.setActiveFont(this.selectedFont);
    localStorage.setItem('font',this.selectedFont);
  }
  getUserTheme() {    
    this.masterServices.get('', '/Setting/Theme/getUserTheme?dealerID='
     + CommonService.getUserData().DEALER_ID
     + '&branchId=' + CommonService.getUserData().BRANCH_ID).subscribe(
      (resp:any) => {
        console.log(resp.themeDetails);
        localStorage.setItem('userThemeDetails', JSON.stringify(resp.themeDetails));
      },
      error => {
        this.isShowPageLoader = false;
        // this.toastr.error(error.error.Message);
      }
    )
  }

  saveUserTheme(val,flag) {
    let userThemeDetails:any = {};
    console.log("usertheme::", typeof localStorage.getItem('userThemeDetails'));
       userThemeDetails = localStorage.getItem('userThemeDetails');
      let saveUserObj = {
          "dealerId": CommonService.getUserData().DEALER_ID,
          "branchId": CommonService.getUserData().BRANCH_ID,
          "background": flag == false ? val : null ,
          "backgroundHeader": flag == false ? val : null ,
          "textColor": flag == false ? val : null ,
          "innerHeader": flag == false ? val : null ,
          "gradiantName":  this.selectedTheme,
          "isGradiant": flag,
          "userId":  CommonService.getUserData().USER_ID,
          "mode": userThemeDetails == 'null' ? "create" : "modify"
        } 
    this.masterServices.post(saveUserObj, '/Setting/Theme/saveAndUpdateTheme?dealerID=' + CommonService.getUserData().DEALER_ID
     + '&branchId=' + CommonService.getUserData().BRANCH_ID).subscribe(
      (resp:any) => {
        console.log(resp);
        this.getUserTheme();
        // this.themeData = resp;
      },
      error => {
        this.isShowPageLoader = false;
        // this.toastr.error(error.error.Message);
      }
    )
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ApprovalService } from 'src/app/api-services/approvals.service';
import { CommonService } from 'src/app/api-services/common.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/api-services/login.service';
@Component({
  selector: 'app-pndeow',
  templateUrl: './pndeow.component.html',
  styleUrls: ['./pndeow.component.scss']
})
export class PndeowComponent implements OnInit {
  public isShowLoader = false;
  public isRootDisabled = true;
  public lang: any;
  public createCustomer: any = {};
  public selectedCustomer: any;
  public customerType = [];
  public maxDate: any = new Date();
  public areaList = [];
  public empDetails = [];
  public isDisable: boolean = false;
  public issaledtDisable: boolean = false;
  public isShowPageLoader: boolean = false;
  public alphaPattern = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$';
  refFrameNo;
  refModelName;
  refPartName;
  public minDate: any = CommonService.setDateTime(new Date());
  approvalClaim = {
    "DEALER_ID": CommonService.getUserData().DEALER_ID,
    "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
    "CREATED_BY": CommonService.getUserData().USER_ID,
    "APPOINMENT_ID": this.data.appId,
    "TYPE": this.data.typedesc,
    "STAFF_ID": '',
    "STAFF_NAME": '',
    "VEHICLE_DETAILS": '',
    "REG_NO": '',
    "START_TIME": null,
    "MOBILE_NO": '',
    "IS_AGENCY": false
  };



  stateList: Array<any> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    public dialogRef: MatDialogRef<PndeowComponent>,
    private toastr: ToastrService,
    private ApproveService: ApprovalService,
    private loginService: LoginService) {

  }
  ngOnInit() {

    this.approvalClaim.IS_AGENCY = false;
    this.approvalClaim.STAFF_ID = "-1";
    let appData = CommonService.getServAppData();
    this.empDetails = appData.EmployeeList.filter(key => {
      if (key.EMP_GROUP_ID == 2) {
        return key;
      }
    });
    var curdate = CommonService.getCurrentDateTimetemp();
    this.minDate = CommonService.setDateTime(curdate);
  }

  ClosePage() {
    this.dialogRef.close(false);
  }

  /**
* Accept Approval
* @param {Object} invoiceObj
* @param {Number} index
*/
  allocateAppoint() {
    new Date()
    var DD: any = CommonService.setDateTime(new Date());
    this.approvalClaim.START_TIME = CommonService.getReqDateTimeFormat(this.approvalClaim.START_TIME);
    var minimumdate: any = CommonService.getReqDateTimeFormat(DD);
    if (this.approvalClaim.START_TIME < minimumdate) {
      this.approvalClaim.START_TIME = "";
      var curdate = CommonService.getCurrentDateTimetemp();
      this.minDate = CommonService.setDateTime(curdate);
      this.toastr.warning("Please Select Start Time greater than Current Date Time");
    }
    else {
      if (this.approvalClaim.STAFF_ID != "" && this.approvalClaim.STAFF_ID != "-1") {
        var name: any;
        name = this.empDetails.filter(key => {
          if (key.EMPLOYEE_ID == this.approvalClaim.STAFF_ID) {
            return key;
          }
        });
        this.approvalClaim.STAFF_NAME = name[0].NAME;
      }
      this.approvalClaim.START_TIME = CommonService.getReqDateTimeFormat(this.approvalClaim.START_TIME);
      this.isShowPageLoader = true;
      this.ApproveService.updateAllocation(this.approvalClaim).subscribe((resp: any) => {
        if (resp && resp.data && resp.statusCode === 200) {
          this.dialogRef.close(resp);
        }
        else {
          this.toastr.error(resp.message);
          if (resp.message != "Same Employee already alloted in 1hr Difference , Pls Change the Time or Employee") {
            this.dialogRef.close(resp);
          }
          else {
            this.approvalClaim.START_TIME = "";
            var curdate = CommonService.getCurrentDateTimetemp();
            this.minDate = CommonService.setDateTime(curdate);
          }
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }
        this.isShowPageLoader = false;
      }, error => {
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
        this.toastr.error(error.error.Message);
      });
    }
  }
}
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import {HttpClient} from '@angular/common/http';
import {PaymentMode} from './dto/enumDO';

@Injectable({
  providedIn: 'root'
})
export class VehicleReturnService {

  private sectionArr=[];
  private billType=[];
  private vehicleInfoHeader=[];
  private voucherInfoHeader=[];
  private payMode =[];
  constructor(private httpClient: HttpClient) { }
  
  // Tab Section Initialization
   
  tabInitialization()
  {
     this.sectionArr=[
       {
         id:1,
         name:'Basic Info',
         isActive:true,
         isDisabled:false,
         isActiveStatus:false

       },
       {
         id:2,
         name:'Accessory Info',
         isActive:false,
         isDisabled:false,
         isActiveStatus:false
       },
       {
         id:3,
         name:'Payment Info',
         isActive:false,
         isDisabled:false,
         isActiveStatus:false
       },
       {
         id:4,
         name:'Other Info',
         isActive:false,
         isDisabled:false,
         isActiveStatus:false
       }
     ]
  

     return this.sectionArr;

  }
  // 
  vehicleInfoHeaderInitialization(lang:any)
  {
    return this.vehicleInfoHeader=[
      {
        name:lang.SALES.select,
        width:'wmpx-50'
      },
      {
        name:lang.SERVICES.model,
        width:'wmpx-150'
      },
      {
        name:lang.PART.partDesc,
        width:'wmpx-150'
      },
      {
        name:lang.SERVICES.frameNo,
        width:'wmpx-150'
      },
      {
        name:lang.SERVICES.engineNo,
        width:'wmpx-150'
      },
      {
        name:lang.SERVICES.keyNo,
        width:'wmpx-150'
      },
      {
        name:lang.SALES.batteryN,
        width:'wmpx-150'
      },
      {
        name:lang.PART.price,
        width:'wmpx-150'
      },
      {
        name:lang.SERVICES.schemeDisc,
        width:'wmpx-150'
      },
      {
        name:lang.SERVICES.discount,
        width:'wmpx-150'
      },
      {
        name:lang.SERVICES.manualDisc,
        width:'wmpx-150'
      },
      {
        name:lang.SALES.taxRate,
        width:'wmpx-150'
      },
      {
        name:lang.SALES.CGST,
        width:'wmpx-150'
      },
      {
        name:lang.SALES.SGST,
        width:'wmpx-150'
      },
      {
        name:lang.SALES.IGST,
        width:'wmpx-150'
      },
      {
       name:lang.SALES.UTGST,
       width:'wmpx-150'
      },
      {
        name:lang.SALES.HSN,
        width:'wmpx-150'
       },
      {
       name:lang.SALES.totalAmount,
       width:'wmpx-150'
      },
      {
        name:lang.PART.storeLocation,
        width:'wmpx-250'
      }
    ]


  }
  
  selectVehicleInvoiceDetails(paramObj:any)
  {
    // const resData=RequestService.postApiURLReq('/Customer/GetSalesPersonSearchDetail');
    //     return this.httpClient.post(resData.url,request, {headers:resData.headers});
     if(paramObj !=null && paramObj !=undefined)
     {
       const resData=RequestService.postApiURLReq('/VehicleReturn/getSelectedVehicleDetails');
       return this.httpClient.post(resData.url,paramObj,{headers:resData.headers});
     }
  }

  getBillTypeValues()
  {
    return  this.billType=[
      {
        value:-1,
        desc:'Select'

      },
      {
        value:1,
        desc:'Cash'
      },
      {
        value:2,
        desc:'Credit'
      }
    ]
  }

  getPaymentMode()
  {
    
   return this.payMode=[
     {name:'Select',val:-1},
     {name:'Cash',val:1},
     {name:'CreditCard',val:2},
     {name:'DD',val:3},
     {name:'Cheque',val:4},
     {name:'Transfer',val:5},
     {name:'NEFT_RTGS',val:6},
     {name:'BHARATHQR',val:7},
     {name:'GOOGLEPAY',val:8},
     {name:'PHONEPAY',val:9},
     {name:'PAYTM',val:10},

    ]

  }
  
  voucherInfoHeaderInitialization(lang:any)
  {
    return this.voucherInfoHeader=[

     {
       name:lang.SERVICES.voucherNo,
       width:'wmpx-150'
     },
     {
       name:lang.SERVICES.voucherDate,
       width:'wmpx-150'
     },
     {
       name:lang.SERVICES.voucherType,
       width:'wmpx-150'
     },
     {
       name:lang.SALES.paymenyMode,
       width:'wmpx-150'
     },
     {
       name:lang.SERVICES.insNo,
       width:'wmpx-150'
     },
     {
       name:lang.SERVICES.insDate,
       width:'wmpx-150'
     },
     {
       name:lang.ACCOUNT.insAmt,
       width:'wmpx-150'
     },
     {
       name:lang.SALES.instAmt,
       width:'wmpx-150'
     },
     {
       name:lang.PART.bankName,
       width:'wmpx-150'
     },
     {
       name:lang.SERVICES.ccType,
       width:'wmpx-150'
     },
     {
       name:lang.PART.companyCode,
       width:'wmpx-150'
     },
     {
       name:lang.PART.voucherStatus,
       width:'wmpx-150'

     },
     {
      name:lang.PART.bankReconcilationNo,
      width:'wmpx-150'

    },
    {
      name:lang.PART.accountReconcilationNo,
      width:'wmpx-150'

    }

    ]

  }

  taxControlsVisibility(vehInfoList:any)
  {
    let vehInfoLst:any;
    vehInfoLst=vehInfoList;
    let cgstTotal:number=0;
    let sgstTotal:number=0;
    let igstTotal:number=0;
    let utgstTotal:number=0;

    let res=vehInfoList.map(x =>  {
      return {
        cgst:cgstTotal=cgstTotal+x.CGST,
        sgst:sgstTotal=sgstTotal+x.SGST,
        igst:igstTotal=igstTotal+x.IGST,
        utgst:utgstTotal=utgstTotal+x.UTGST

      }

    })

   return res;
  }
  
  getOtherControlValuesOnSelectClick(vehicleReturnInfoAngDos:any)
  {
   
      const resData=RequestService.postApiURLReq('/VehicleReturn/getOtherControlValues');
      return this.httpClient.post(resData.url,vehicleReturnInfoAngDos,{headers:resData.headers});

  }
  saveVehicleReturn(reqParams:any)
  {
    if(reqParams !=null && reqParams !=undefined)
    {
      const resData= RequestService.postApiURLReq('/VehicleReturn/SaveVehicleReturn');
      return this.httpClient.post(resData.url,reqParams,{headers:resData.headers});
     
    }
  }

  //VehicleReturn/SearchVehicleReturn
  searchVehicleReturn(reqParams:any)
  {
    if(reqParams !=null && reqParams !=undefined)
    {
      const resData=RequestService.postApiURLReq('/VehicleReturn/SearchVehicleReturn');
      return this.httpClient.post(resData.url,reqParams,{headers:resData.headers});
    }
  }
   
  getOrderDetailsByID(reqParams:any)
  {
     if(reqParams !=null && reqParams !=undefined)
     {
       const resData=RequestService.postApiURLReq('/VehicleReturn/VehicleReturnDetailById');
       return this.httpClient.post(resData.url,reqParams,{headers:resData.headers});

     }
  }

  getVoucherTypeList(businessType:number)
  {
    debugger;
   if(businessType !=null && businessType !=undefined) 
   {
    //const resData=RequestService.getApiURLReq('/VehicleReturn/GetVoucherType');
     //return this.httpClient.get(resData.url,businessType);
     const resData=RequestService.uvdReq('/Voucher/FetchVoucherType');
     return this.httpClient.get(resData.url);
   } 

  }

  savePaymmentVoucher(reqParams:any)
  {
     const resData=RequestService.postApiURLReq("/VehicleReturn/SavePaymentVoucher")
     return this.httpClient.post(resData.url,reqParams,{headers:resData.headers});
  }
  printVehicleReturnInvoice(reqParams:any)
  {
    const resData=RequestService.postApiURLReq("/VehicleReturn/Print");
    return this.httpClient.post(resData.url,reqParams,{headers:resData.headers});
  }
}

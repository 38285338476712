import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MasterService } from 'src/app/api-services/master.service';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { VehicleAllotment, AllotmentDetail, AllotmentPartStock, Vehicle } from 'src/app/api-services/dto/booking'

@Component({
  selector: 'app-sales-allotment',
  templateUrl: './sales-allotment.component.html',
  styleUrls: ['./sales-allotment.component.scss']
})
export class SalesAllotmentComponent implements OnInit {

  public lang: any;
  public selected = [];
  public remSelected = [];
  public isActive: any;
  public isShowPageLoader: any = false;
  public inlineFilter: any = {};
  public selectedFrameFilter: any = {};
  public sales: any = {};
  public salesRows: any = [];
  public storageLoc: any = [];
  customerObj: any = [];
  public allotmentDet = new AllotmentDetail();
  public vehAllotment = new VehicleAllotment();
  public bookingData: any;
  public allotPartStock = new AllotmentPartStock;
  public vehicleStockList: Array<Vehicle> = [];
  public vehPartStockList: Array<Vehicle> = [];
  public selectedFrameList: Array<Vehicle> = [];
  public frameHeader: Array<any> = [];
  public othrDropdownSelected: any = 0;
  public isDisable: boolean = true;
  public isView:boolean;
  public isRootDisabled:boolean;
  public evFlag:any;
  public isEvDisable: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SalesAllotmentComponent>,
    private toastr: ToastrService,
    private masterServices: MasterService, private masterSales: MasterSales,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
    this.bookingData = this.data;
  }

  ngOnInit() {
   // alert("hi");
    this.lang = CommonService.getLang();
     this.evFlag = CommonService.getDealerData().DealerConfigSetting.IS_EV_NRML;
     this.inlineFilter = {
      FRAME_NO: '',
      ENGINE_NO: '',
      GRN_DATE: '',
      VEHICLE_ID: ''
    };

    if (this.evFlag == 1 || this.evFlag == 2) {
      this.isEvDisable=true;
     
    }
    else {
      this.isEvDisable=false;
     
    }
    this.selectedFrameFilter = {
      FRAME_NO: '',
      ENGINE_NO: '',
      VEHICLE_ID: ''
    };

    this.frameHeader = [
      {
        name: this.lang.SERVICES.frameNo,
        width: 'wmpx-120'
      },
      {
        name: this.lang.SERVICES.engineNo,
        width: 'wmpx-100'
      },
      {
        name: this.lang.SALES.select,
        width: 'wmpx-100'
      },
    ];

    if (this.bookingData) {
      this.allotmentDet = new AllotmentDetail();
      this.allotmentDet.BOOKING_NO = this.bookingData.BookingNo;
      this.allotmentDet.CUST_NAME = this.bookingData.CustName;
      this.allotmentDet.CUSTOMER_TYPE = this.bookingData.CustType;
      this.allotmentDet.BOOKING_DATE = this.bookingData.BookingDate;
      this.allotmentDet.BOOKING_VALUE = this.bookingData.BookingValue;
      this.allotmentDet.BOOKED_QTY = this.bookingData.BookedQty;
      this.allotmentDet.ALLOTED_QTY = this.bookingData.AllotedQty;
      this.allotmentDet.PART_DESC = this.bookingData.PartDesc;
      this.allotmentDet.PART_ID = this.bookingData.PartId;
      this.allotmentDet.CUSTOMER_ID = this.bookingData.CustomerId;
      this.allotmentDet.BOOK_PART_ID = this.bookingData.SelectedItem;
      this.allotmentDet.CUST_CAT_ID = this.bookingData.CustCatId;
      // this.isActive = this.data && this.data.type ? this.data.type : '';
      // this.isActive ="enquiryNo";
    }
    this.loadLocation();

  }
  closeDialog() {
    this.dialogRef.close(false);
  }


  loadLocation() {
    var locData: any;
    this.masterSales.get('', '/Sales/LoadLocationForAllotment?DEALER_ID=' + CommonService.getDealerData().DEALER_ID + '&BRANCH_ID=' + CommonService.getDealerData().BRANCH_ID + '&CustCat=' + this.allotmentDet.CUST_CAT_ID).subscribe(
      resp => {
        locData = resp;
        this.storageLoc = locData.data;
        this.allotmentDet.STORAGE_LOC = this.storageLoc.length >= 1 ? this.storageLoc[0].LOCATION_ID : '';
        this.displayStock(null);
      },
      error => {
        this.toastr.error('Exchange Location not loaded')
      }
    )
  }
  /**
  * Filter each columns
  * @param {String} value
  * @param {String} prop
 */
  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  /**
* Filter each columns
* @param {String} value
* @param {String} prop
*/
  filterByFrameName(value, prop) {
    if (prop in this.selectedFrameFilter) {
      this.selectedFrameFilter[prop] = value;
    }
  }
  /**
 * select search list
 */
  selectedItem() {
    let exists = 0;
    if (this.selected.length <= 0) {
      this.toastr.warning('Please Select the line item');
    }
    // alert(this.selected.length);
    // let closeObj = { obj: this.selected[0] };
    // this.selectedFrameList.push(this.selected[0]);
    if (this.selectedFrameList.length < this.allotmentDet.BOOKED_QTY) {
      this.selectedFrameList.forEach((itm) => {
        if (itm.VEHICLE_ID == this.selected[0].VEHICLE_ID) {
          exists = 1;
        }
      })
      if (exists == 1) {
        this.toastr.warning('Selected Frame no is already added.');
      }
      else {
        let vh = new Vehicle();
        vh.FRAME_NO = this.selected[0].FRAME_NO;
        vh.ENGINE_NO = this.selected[0].ENGINE_NO;
        vh.VEHICLE_ID = this.selected[0].VEHICLE_ID;
        vh.PART_ID=this.selected[0].PART_ID;
        this.selectedFrameList.push(vh);
      }
    }
    else {
      this.toastr.warning('You have already added Frame details for allotment');

    }
    this.allotmentDet.ALLOTED_QTY = this.selectedFrameList.length;
  }

  removeItem() {
    if (this.remSelected.length < 0) {
      this.toastr.warning('Please Select the line item');

    }
    if (this.selectedFrameList.length > 0) {
      this.selectedFrameList = this.selectedFrameList.filter(h => h.FRAME_NO != this.remSelected[0].FRAME_NO);
      //  this.selectedFrameList.splice(this.remSelected[0]);
    }
    this.allotmentDet.ALLOTED_QTY = this.selectedFrameList.length;
  }

  AddFrame() {
    let exists = 0;
    let veh = new Array<Vehicle>();
    if (this.selectedFrameList.length < this.allotmentDet.BOOKED_QTY) {
      if (this.othrDropdownSelected > 0) {
        veh = this.vehicleStockList.filter(h => h.VEHICLE_ID == this.othrDropdownSelected);
        if (veh[0].PART_ID != this.allotmentDet.PART_ID) {
          var c = confirm("You Have Booked " + this.allotmentDet.PART_DESC + ", But you have Alloted " + veh[0].DESCRIPTION + " Do you want to Allot the Selected Frame. ?");
          if (c == true) {
            this.selectedFrameList.forEach((itm) => {
              if (itm.VEHICLE_ID == veh[0].VEHICLE_ID) {
                exists = 1;
              }
            })
            if (exists == 1) {
              this.toastr.warning('Selected Frame no is already added.');
            }
            else {
              this.selectedFrameList.push(veh[0]);
            }
          }
        }
        else {
          this.selectedFrameList.forEach((itm) => {
            if (itm.VEHICLE_ID == veh[0].VEHICLE_ID) {
              exists = 1;
            }
          })
          if (exists == 1) {
            this.toastr.warning('Selected Frame no is already added.');
          }
          else {
            this.selectedFrameList.push(veh[0]);
          }
        }
      }
      else {
        this.toastr.warning('Select the Frame no in dropdown for allotment');
      }
    }
    else {
      this.toastr.warning('You have already added Frame details for allotment');
    }
    this.allotmentDet.ALLOTED_QTY = this.selectedFrameList.length;
  }

  displayStock(event) {
    var partData: any;
    // const selectEl = event.target;
    // const val = selectEl.value;
    let reqObj: any = {};
    reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reqObj.PART_ID = this.allotmentDet.PART_ID;
    reqObj.STORAGE_LOC = this.allotmentDet.STORAGE_LOC;
    this.isShowPageLoader = true;
    this.masterSales.post(reqObj, '/Sales/LoadVehicleFrameforAllotment').subscribe(
      resp => {
        this.isShowPageLoader = false;
        partData = resp;
        this.allotPartStock = partData.data;
        if (this.allotPartStock) {
          this.vehicleStockList = partData.data.VehicleList;
          this.vehPartStockList = this.vehicleStockList.filter(h => h.PART_ID == this.allotmentDet.PART_ID);
        }
      },
      error => {

        this.toastr.error('Exchange Location not loaded')
      }

    )
    // this.isShowPageLoader = false;
  }

  saveAllotment() {
    let allotDetail = new Array<AllotmentDetail>();

    if (this.selectedFrameList.length <= 0) {
      this.toastr.warning('Please select the Frame for Allocation');
    }
    if (this.selectedFrameList.length < this.allotmentDet.BOOKED_QTY) {
      this.toastr.warning('Please Allocate Frame no for Booked Qty');
    }
    else {
      this.vehAllotment.DEALER_ID = CommonService.getDealerData().DEALER_ID;
      this.vehAllotment.BRANCH_ID = CommonService.getDealerData().BRANCH_ID;
      this.vehAllotment.BOOKING_ID = this.allotmentDet.BOOKING_NO;
      this.vehAllotment.ALLOTMENT_DATE = new Date();
      this.vehAllotment.CUSTOMER_ID = this.allotmentDet.CUSTOMER_ID;
      this.vehAllotment.END_USER_ID = this.allotmentDet.CUSTOMER_ID;
      this.vehAllotment.STATUS = 1;
      this.vehAllotment.REMARKS = "";
      this.vehAllotment.INVOICED = false;
      this.vehAllotment.CREATED_BY = CommonService.getUserData().USER_ID;
      this.vehAllotment.MODIFIED_BY = null;
      this.vehAllotment.FIN_YEAR = CommonService.getDealerData().FIN_YEAR;
      this.vehAllotment.CREATED_ON = new Date();
      this.vehAllotment.AllotedVehicleList = new Array<AllotmentDetail>();
      if (this.selectedFrameList.length > 0) {
        this.selectedFrameList.forEach((itm) => {
          let allotment = new AllotmentDetail();
          allotment = this.allotmentDet;
          allotment.VEHICLE_ID = itm.VEHICLE_ID;
          allotment.PART_ID=itm.PART_ID;
          allotment.VEHICLE_INVOICED = false;
          allotment.ACCESS_SEL = false;
          allotment.LIKE_DT_OF_REGIS = null;
          allotment.ACC_FIT_DATE = null;
          allotment.DELIVERY_DATE = null;
          allotDetail.push(allotment);
        })
        this.vehAllotment.AllotedVehicleList = allotDetail;
        this.vehAllotment.SelectedVehicleList = this.selectedFrameList;
      }
      console.log(this.vehAllotment.AllotedVehicleList);
      this.masterSales.post(this.vehAllotment, '/Sales/SaveAllotment').subscribe((resp: any) => {
        if (resp && resp.statusCode === 200 && resp.data) {
          let closeObj = { obj: resp.data };
          this.toastr.success('Allotment Saved Successfully and your Allotment id: ' + resp.data);
          this.dialogRef.close(closeObj);
        }
      }, error => {
        this.toastr.error(error.error.Message);
        this.isShowPageLoader = false;
      });
    }
  }

}

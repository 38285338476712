/**
 * Created BY HMSPL 
 * Outwork Process Service Class
 * Last Modified By Balaji on 19/09/2018
 * Last Modified Description : Added new API methods
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/api-services/request.service';

@Injectable({
    providedIn: 'root'
})
export class OutworkProcessService {
    constructor(private httpClient: HttpClient) {

    }
    /*********************************************Out work memo API methods********************************** */
    /**
     * Get all outwork memo list
     * @param {Object} params
     * return Object
     */
    getAllOutworkMemoList(params) { 
        const resData = RequestService.getApiURLReq('/OutWork/SearchOutWorkMemo');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get all outwork memo details
     * @param {Object} params
     * return Object
     */
    getOutworkMemoDetails(params) { 
        const resData = RequestService.getApiURLReq('/OutWork/GetOutWorkMemoByNo');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get all outwork memo agency names list
     * @param {Object} params
     * return Object
     */
    getOutworkMemoAgencyNamesList(params) { 
        const resData = RequestService.getApiURLReq('/OutWork/GetVendorByVendorCategory');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Get all outwork memo details based on vendor id
     * @param {Object} params
     * return Object
     */
    getOutworkMemoDetailsByAgencyId(params) {        
        const resData = RequestService.getApiURLReq('/OutWork/GetJobCardDetailsByAgencyCode');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });
    }
    /**
     * Print outwork
     * @param {Object} params
     * return Object
     */
    printOutworkMemo(params) {
        const resData = RequestService.getApiURLReq('/OutWork/GetMemoPrintData');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers }); 
    }
    /**
     * Complete outwork memo
     * @param {Object} params
     * return Object
     */
    completeOutworkMemo(params) {
        const resData = RequestService.getApiURLReq('/OutWork/CloseOutworkMemo');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers });         
    }
    /**
     * Save Outwork Memo
     * @param {Object} request
     * return Object
     */
    saveOutworkMemo(request) { 
        const resData = RequestService.postApiURLReq('/OutWork/SaveJobcardLabourOutworkMemo');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
    /*********************************************Out work payment API methods********************************** */
    /**
     * Get all outwork payment list
     * @param {Object} params
     * return Object
     */
    getAllOutworkPaymentList(params) { 
        const resData = RequestService.getApiURLReq('/OutWorkPayment/SearchOutWorkPayment');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers }); 
    }
    /**
     * Get all outwork payment details
     * @param {Object} params
     * return Object
     */
    getOutworkPaymentDetails(params) { 
        const resData = RequestService.getApiURLReq('/OutWorkPayment/GetOutworkPaymentByNoSearch');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers }); 
    }
    /**
     * Get all outwork payment details based on vendor id
     * @param {Object} params
     * return Object
     */
    getOutworkPaymentDetailsByVendorId(params) { 
        const resData = RequestService.getApiURLReq('/OutWorkPayment/GetMemoDetForPayment');
        return this.httpClient.get(resData.url, { params: params, headers: resData.headers }); 
    }
    /**
    * Save Outwork Payment
    * @param {Object} request
    * return Object
    */
    saveOutworkPayment(request) { 
        const resData = RequestService.postApiURLReq('/OutWorkPayment/SaveOutworkPayment');
        return this.httpClient.post(resData.url, request, { headers: resData.headers });
    }
}

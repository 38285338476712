import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  defaultColor={
    '--background': '#00599d',
    '--bg-header': '#2c4457',
    '--text-color': '#00599d',
    '--inner-header': '#d7edff',
    isGradiant:false
  }

  private activeThem = new Subject();
  private activeFont = new Subject();

  constructor() {
  }
  
  public getActiveTheme() {
    return this.activeThem.asObservable();
  }

  public setActiveThem(obj) {
    this.activeThem.next(obj);
  }

  public getActiveFont() {
    return this.activeFont.asObservable();
  }
  
  public setActiveFont(name) {
    this.activeFont.next(name);
  }
}

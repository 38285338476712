import { HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JobCardService } from 'src/app/api-services/job-card.service';
import { MasterDataService } from 'src/app/api-services/master-data.service';
import { CommonService } from 'src/app/api-services/common.service';
import { LoginService } from 'src/app/api-services/login.service';

@Component({
  selector: 'app-recall-refit-rejection',
  templateUrl: './recall-refit-rejection.component.html',
  styleUrls: ['./recall-refit-rejection.component.scss']
})
export class RecallRefitRejectionComponent implements OnInit {

  public PART_DESC: any;
  public rejectReasonId:any;
  public REJECT_REASON: any = [];
  @Input() displayData: any = [];
  public isShowPageLoader: boolean = false;

  constructor(private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    public route: ActivatedRoute,
    private MasterDataService: MasterDataService,
    private jobCardService: JobCardService,private loginService:LoginService,
    public dialogRef: MatDialogRef<RecallRefitRejectionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.displayData = this.data;
  }

  ngOnInit() {
    debugger;
    this.REJECT_REASON = JSON.parse(localStorage.getItem('rejectReason'));
    this.rejectReasonId = -1;
    this.PART_DESC = this.displayData.userData.userData.PART_NO + ' ' + this.displayData.userData.userData.DESCRIPTION;
    this.displayData;
  }
  saveRejectionReason()
  {
    this.isShowPageLoader = true;
  /*  let params = new HttpParams();
    params = params.set('recallRefitTaskId', this.displayData.userData.userData.RECALL_REFIT_TASK_ID)
      .set('rejectReasonId', this.rejectReasonId)
      .set('recallRefitId', this.displayData.userData.userData.RECALL_REFIT_ID);*/
      let reqObj: any = {};
      reqObj.DealerId = CommonService.getUserData().DEALER_ID;
      reqObj.BranchId = CommonService.getUserData().BRANCH_ID;
      reqObj.UserId = CommonService.getUserData().USER_ID;
      reqObj.RecallRefitTaskId=this.displayData.userData.userData.RECALL_REFIT_TASK_ID;
      reqObj.RejectReasonId=this.rejectReasonId;
      reqObj.RecallRefitId=this.displayData.userData.userData.RECALL_REFIT_ID;
      this.jobCardService.saveRecallRefitRejectReason(reqObj).subscribe((resp: any) => {
        if (resp && resp.data && resp.statusCode === 200) {
          this.dialogRef.close(resp.data);
        } else {
          this.toastr.error(resp.message);
        }
        this.isShowPageLoader = false;
        if (resp && resp.statusCode == 401) {
          this.loginService.logout();
        }
      }, error => {
        this.toastr.error(error.error.Message);
        if (error.status == 401) {
          this.loginService.logout();
        }
      });
    }
}

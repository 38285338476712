import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/api-services/common.service';
import { PendingJobcardstatusDO, PendingstatusRootObject, MandatoryPartsDO } from 'src/app/api-services/dto/job-card';
import { JobCardService } from 'src/app/api-services/job-card.service';
import { HttpParams } from '@angular/common/http';
import { LoginService } from 'src/app/api-services/login.service';
declare var $: any;

@Component({
  selector: 'app-mandatory-parts',
  templateUrl: './mandatory-parts.component.html',
  styleUrls: ['./mandatory-parts.component.scss'],
})
export class MandatoryPartsComponent implements OnInit {
  public lang: any;

  public selected = [];
  public pendingstatusList = [];
  public refPartList = [];
  public jobcardDetails: Array<PendingJobcardstatusDO> = [];
  public updatejobcardDetails: Array<PendingJobcardstatusDO> = [];
  public pendingstatusRootObject = new PendingstatusRootObject();
  public isShowPageLoader = false;
  public minDate = new Date();
  public partsHeader = [];
  refLocationId;
  type;
  public JobcardId; 
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MandatoryPartsComponent>,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private jobCardService: JobCardService,
    private loginService:LoginService
  ) {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
      this.type = this.data.type;
      this.JobcardId = this.data.JobcardId;
      if (this.data.refPartList) {
        this.refPartList = this.data.refPartList;
        var i: number = 0
        /*
        for( i=0;i<this.refPartList.length;i++)
        {

          this.refPartList[i].SPARE_PART_ID =  resp.data.SPARE_PART_ID;
          this.refPartList[i].DESCRIPTION = resp.data.DESCRIPTION;
          this.refPartList[i].PART_NO = resp.data.PART_NO;

          this.getPartDetails(i,undefined)
        }
        */
    }
  }


  ngOnInit() {
    this.lang = CommonService.getLang();
    if (CommonService.getServAppData()) {
      this.pendingstatusList = CommonService.getServAppData().pendingStatus;
    }
    this.partsHeader = [
      {
        name: this.lang.SERVICES.partNo + ' *',
        width: 'wmpx-200'
      },
      {
        name: this.lang.SERVICES.partDescription + ' *',
        width: 'wmpx-300'
      },
      {
        name: this.lang.SERVICES.qty + ' *',
        width: 'wmpx-100'
      }
    ];
     this.addemptyPart();
     
  }

  /**
 * Get part details based on required quantity
 * @param {Number} index 
 */
  getPartDetails(index, obj) {

    let localParam = this.refPartList[index];
    let refPartId = 0;
    this.refPartList.filter(key => {
      if (this.refPartList.indexOf(key) != index) {
        if (key.PART_NO == localParam.PART_NO) {
          refPartId++;
        }
      }
    });
    if (refPartId == 0 || obj) {
      if (localParam.PART_NO) {
        this.isShowPageLoader = true;
        let params = new HttpParams();
        params = params.set('SparePartID', localParam.PART_NO)
        .set('DealerId', CommonService.getUserData().DEALER_ID)
        .set('BranchId', CommonService.getUserData().BRANCH_ID)
        .set('UserId', CommonService.getUserData().USER_ID);
        this.jobCardService.PartNumberDescription(params).subscribe((resp: any) => {
          if (resp && resp.data && resp.statusCode === 200) {
            this.refPartList[index].SPARE_PART_ID = resp.data.SPARE_PART_ID;
            this.refPartList[index].DESCRIPTION = resp.data.DESCRIPTION;
            this.refPartList[index].PART_NO = resp.data.PART_NO;
            this.refPartList[index].QTY=resp.data.QTY;
            this.isShowPageLoader = true;

          } else {
            this.toastr.error(resp.d.message);
          }
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        }, error => {
          this.toastr.error(error.error.Message);
          this.isShowPageLoader = false;
          if (error.status == 401) {
            this.loginService.logout();
          }
        });
      }
    }

  }

  // /**
  // * Save Mandatory Job Card
  // */
  saveMandatoryJobCard() {
    let bulkSaveParam = JSON.parse(JSON.stringify(this.refPartList));   
    let partIssueList = [];      



    if (this.refPartList.length > 0) {
      this.refPartList.forEach((val) => {
        if (val.SPARE_PART_ID > 0) {
        let enqDet = {
          "DEALER_ID": CommonService.getUserData().DEALER_ID,
          "BRANCH_ID": CommonService.getUserData().BRANCH_ID,
          "JOB_CARD_ID": this.data.JobcardId,
          "SPARE_PART_ID":  val.SPARE_PART_ID,
          "QTY": val.QTY,
          "CREATED_BY":  CommonService.getUserData().USER_ID
        }
        partIssueList.push(enqDet);
      }
      });
    
    }

    /*  
    bulkSaveParam.filter(val => {
       // if (val.SPARE_PART_ID > 0) {
          partIssueList.push({
            DEALER_ID: CommonService.getUserData().DEALER_ID,
            BRANCH_ID: CommonService.getUserData().BRANCH_ID,
            JOB_CARD_ID: this.data.JobcardId,
            SPARE_PART_ID: val.SPARE_PART_ID,
            QTY: val.QUANTITY,
            CREATED_BY: CommonService.getUserData().USER_ID
          });
       // }
      }); 
      */
      let reqObj = {
        MandatoryList: partIssueList, 
        DealerId: CommonService.getUserData().DEALER_ID,
        BranchId: CommonService.getUserData().BRANCH_ID,
        UserId: CommonService.getUserData().USER_ID      
      }
      
      this.isShowPageLoader = true;
      this.jobCardService.saveMandatoryJobCardDetails(reqObj).subscribe((resp: any) => {
        if (resp && resp.statusCode && resp.statusCode == 200) {
          this.toastr.success(this.lang.Message.JOB_CARD.PART_SUCCESS);
          this.isShowPageLoader = false;
        } else {
          this.toastr.error(resp.message);
          this.isShowPageLoader = false;
          if (resp && resp.statusCode == 401) {
            this.loginService.logout();
          }
        } 
      }, error => {
        this.isShowPageLoader = false;
        if (error.status == 401) {
          this.loginService.logout();
        }
        this.toastr.error(error.error.Message);
      });     
  }

  addemptyPart() {
    if (this.refPartList.length < 3) {
      let partIssueDet = new MandatoryPartsDO();
      partIssueDet.JOB_CARD_ID = 0;
      partIssueDet.QUANTITY = 1;
      partIssueDet.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
      partIssueDet.DEALER_ID = CommonService.getUserData().DEALER_ID;
      partIssueDet.SPARE_PART_ID = 0;
      partIssueDet.PART_NO = "";
      partIssueDet.PART_DESCRIPTION = "";
      partIssueDet.uniqId = Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
      this.refPartList.push(partIssueDet);
      $('.inlineTable').scrollTop($('.inlineTable')[0].scrollHeight);
    }
    else {
      this.toastr.error("More than 3 Line item cannot be added");
    }
  }

  /**
   * Search part no
   * @param {number} index 
   * @param {Object}event 
   */
  getPartNo(index, event) {
    if (event.code != 'ArrowUp' && event.code != 'ArrowDown') {
      let localParam = this.refPartList[index];
      if (localParam && localParam.PART_NO && localParam.PART_NO.length >= 3) {
        let params = new HttpParams();
        params = params.set('DealerID', CommonService.getUserData().DEALER_ID)
          .set('Country', CommonService.getDealerData().COUNTRY_CODE)
          .set('BranchID', CommonService.getUserData().BRANCH_ID)
          .set('partNo', localParam.PART_NO)
          .set('LocID', "1")
          .set("Is_Lubricant", null);
        this.jobCardService.getPartNumbers(params).subscribe((resp: any) => {
          if (resp && resp.data && resp.statusCode === 200) {
            this.refPartList[index].partNoList = resp.data;            
          } else {
            this.toastr.error(resp.d.message);
            if (resp && resp.statusCode == 401) {
              this.loginService.logout();
            }
          }
        }, error => {
          this.toastr.error(error.error.Message);
          if (error.status == 401) {
            this.loginService.logout();
          }
        });
      }
    }
  }


  goBack() {
    this.dialogRef.close(false);
  }
}

/**
 * Created BY HMSPL 
 * Common Service Class
 * Last Modified By Dharvesh on 09/10/2018
 * Last Modified Description :Common Service Call
 */

import { Injectable } from '@angular/core';
import { config } from './utils/web.config';
import { HttpClient, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable,Subject } from 'rxjs/Rx';
import { map, filter, tap } from 'rxjs/operators';

declare var moment: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private subject = new Subject<any>();
  constructor() {
  }

  // subjec next function ***/
  sendMessage(message) {
    this.subject.next({ name: message });
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  //*** end subject next */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('Started http');
    const started = Date.now();
    return next.handle(req).pipe(tap(event => {
      const elapsed = Date.now() - started;
      console.log(`Request for running ${elapsed} ms.`);
    }, error => {
      console.error('NICE ERROR', error)
    })
    )
  }
  /**
   * 
   * Get Business Type ID
   * 
   * 
   */

  static getBusinessType()
  {
      if(localStorage.getItem('userData'))
      {
        let userData = JSON.parse(localStorage.getItem('userData'));
        if(userData && userData.data)
           return userData.data.BUSINESS_TYPE_ID;
        
      }

  }
  /**
  * Get User Data
  */
  static getUserData() {
    if (localStorage.getItem('userData')) {
      let userData = JSON.parse(localStorage.getItem('userData'));
      if (userData && userData && userData.data && userData.data.User) {
        return userData.data.User;
      }
    } else {
      return '';
    }
  }
  /**
  * Get Dealer Data
  */
  static getDealerData() {
    if (localStorage.getItem('userData')) {
      let userData = JSON.parse(localStorage.getItem('userData'));
      if (userData && userData.data && userData.data.DealerDetail && userData.data.DealerDetail.COUNTRY_CODE) {
        return userData.data.DealerDetail;
      }
    } else {
      return '';
    }
  }
  /**
  * Get Config Data
  */
  static getConfigValues() {
    if (localStorage.getItem('userData')) {
      let userData = JSON.parse(localStorage.getItem('userData'));
      if (userData && userData.data && userData.data.ConfigVal) {
        return userData.data.ConfigVal;
      }
    } else {
      return '';
    }
  }

  static generalFormat(str) {
    if (str) {
      let format = moment(str).format('DD/MM/YYYY');
      if (format === '01/01/0001' || format === 'Invalid date') {
        return '';
      } else {
        return format
      }
    } else {
      return ''
    }
  }
  static generalFormatYYYYMMDD(str) {
    if (str) {
      let format = moment(str).format('YYYY/MM/DD');
      if (format === '01/01/0001' || format === 'Invalid date') {
        return '';
      } else {
        return format
      }
    } else {
      return ''
    }
  }
  static setListDateFormat(str) {
    if (str) {
      let format = moment(str, 'YYYY-MM-DD').format('DD/MM/YYYY');
      if (format === '01/01/0001' || format === 'Invalid date') {
        return '';
      } else {
        return format
      }
    } else {
      return ''
    }
  }
  static setDateTime(str) {
    if (this.getReqDateFormat(str)) {
      return new Date(str);
    } else {
      return '';
    }
  }
  static setTime(str) {    
    var options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
     // let format = moment(str).format('H:mm');
    if (this.getReqDateFormat(str)) {
      var date = new Date(str);
      var timeString = date.toLocaleString('en-US', options);
      return timeString;
    } else {
      return '';
    }
  }
  static setTime24(str) {    
    var options = {
      hour: 'numeric',
      minute: 'numeric',
      hour24: true
    };
     // let format = moment(str).format('H:mm');
    if (this.getReqDateFormat(str)) {
      var date = new Date(str);
      var timeString = date.toLocaleString('en-US', options);
      return timeString;
    } else {
      return '';
    }
  }
  static setDate(str) {
    if (str) {
      str = str.toString();
      let formatDt = '';
      if (str && str.indexOf('-') > -1) {
        formatDt = moment.utc(str, 'YYYY-MM-DD').utcOffset(moment().format("ZZ")).format('YYYY-MM-DD');
      } else if (str && str.indexOf('/') > -1) {
        formatDt = moment.utc(str, 'DD/MM/YYYY').utcOffset(moment().format("ZZ")).format('YYYY-MM-DD');
      } else if (str && moment(str).isValid()) {
        formatDt = moment.utc(str).utcOffset(moment().format("ZZ")).format('YYYY-MM-DD');
      }
      let temp = formatDt.toString().slice(0, 10);
      if (temp === '0001-01-01' || formatDt === '01/01/0001' || formatDt === 'Invalid date') {
        return '';
      } else {
        return formatDt
      }
    } else {
      return '';
    }

  }
  static getLang() {
    if (localStorage.getItem('lang')) {
      return JSON.parse(localStorage.getItem('lang'));
    } else {
      return '';
    }
  }
  static getServAppData() {
    if (localStorage.getItem('appData') && JSON.parse(localStorage.getItem('appData')) && JSON.parse(localStorage.getItem('appData')).service) {
      return JSON.parse(localStorage.getItem('appData')).service;
    } else {
      return '';
    }
  }
  static nextYear() {
    return this.setDateTime(moment().add(1, 'year').format());
  }
  static nextMonth() {
    return this.setDateTime(moment().add(1, 'month').format());
  }

  /**
   * is from is less than to date
   * @param str 
   */
  static isFromLessthanto(from, to) {
    let fromDt = this.getReqDateFormat(from).slice(0, 10);
    let toDt = this.getReqDateFormat(to).slice(0, 10);
    return moment(toDt).isBefore(fromDt);
  }
  /**
   * @param {String} str
   * Get request date time format
  */
  static getReqDateFormat(str) {
    if (str) {
      str = str.toString();
      let formatDt = '';
      if (str && str.indexOf('-') > -1) {
        formatDt = moment.utc(str, 'YYYY-MM-DDTHH:mm:ss').utcOffset(moment().format("ZZ")).format('YYYY-MM-DDTHH:mm:ss');
      } else if (str && str.indexOf('/') > -1) {
        formatDt = moment.utc(str, 'DD/MM/YYYY').utcOffset(moment().format("ZZ")).format('YYYY-MM-DDTHH:mm:ss');
      } else if (str && moment(str).isValid()) {
        formatDt = moment.utc(str).utcOffset(moment().format("ZZ")).format('YYYY-MM-DDTHH:mm:ss');
      }
      let temp = formatDt.toString().slice(0, 10);
      if (temp === '0001-01-01' || formatDt === '01/01/0001' || formatDt === 'Invalid date') {
        return '';
      } else {
        return formatDt
      }
    } else {
      return '';
    }

  }
  /**
   * @param {String} str
   * Get request date time format
  */
  static getReqDateTimeFormat(str) {
    if (str) {
      str = str.toString();
      let formatDt = '';
      formatDt = moment(str).format('YYYY-MM-DDTHH:mm:ss');
      if (formatDt === '01/01/0001' || formatDt === 'Invalid date') {
        return '';
      } else {
        return formatDt
      }
    } else {
      return '';
    }
  }
  static currentFinYear() {
    return moment().startOf('quarter').format('YYYY');
  }
  static addDayToCurrentDate(days, minutes?: any) {
    if (days) {
      return moment(new Date()).add(days, 'day').format("DD/MM/YYYY");
    } else if (minutes) {
      return moment(new Date()).add(minutes, 'minutes').format("DD/MM/YYYY");
    }
  }
  /**
    * @param {String} str
    * Get current date
    */
  static getCurrentDate() {
    return moment(new Date()).format("DD/MM/YYYY");
  }
    /**
    * @param {String} str
    * Get current date time
    */
   static getCurrentDateTime() {
    return moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
  }
   /**
    * @param {String} str
    * Get current date time without Time 
    */
  static getCurrentDateTimetemp() {
    return moment(new Date()).format("YYYY-MM-DDT00:00:00");
  }

  /**
    * Get start week date
    */
  static getStartOfWeek() {
    return moment().startOf('isoWeek').format("DD/MM/YYYY");
  }
  /**
    * Get end week date
    */
  static getEndOfWeek() {
    return moment().endOf('isoWeek').format("DD/MM/YYYY");
  }
  /**
    * Get start week month
    */
  static getStartOfMonth() {
    return moment().startOf('month').format("DD/MM/YYYY");
  }
  /**
    * Get end week month
    */
  static getEndOfMonth() {
    return moment().endOf('month').format("DD/MM/YYYY");
  }
  /**
    * Get ASC min date
    */
  static getASCMinDate() {
    return this.setDateTime(moment().subtract(config.ascMinDays, 'days').format("MM/DD/YYYY"));
  }
  /**
    * Get ASC min date
    */
   static getASCMinDateDynamic(days) {
    return this.setDateTime(moment().subtract(days, 'days').format("MM/DD/YYYY"));
  }
  /**
    * Get end week month
    */
  static getPastTenYears() {
    let yearAgo = [];
    for (var i = 0; i < 10; i++) {
      yearAgo.push(parseInt(moment().subtract(i, 'years').format("YYYY")));
    }
    return yearAgo;
  }
  /**
   * Get filter Obj
   * @param data 
   */
  static getFilterObj() {
    return {
      week: {
        fromDate: this.getStartOfWeek(),
        toDate: this.getEndOfWeek()
      },
      month: {
        fromDate: this.getStartOfMonth(),
        toDate: this.getEndOfMonth()
      }
    }
  }
  static getDateFilter() {
    return [{
      name: this.getLang().SERVICES.thisWeek,
      id: 1
    }, {
      name: this.getLang().SERVICES.thisMonth,
      id: 2
    }, {
      name: this.getLang().SERVICES.custom,
      id: 3
    }]
  }
  /**
   *For PSF Page
   */
  static getDateFilterPSF() {
    return [{
      name: this.getLang().SERVICES.custom,
      id: 1
    }, {
      name: this.getLang().SERVICES.thisMonth,
      id: 2
    }, {
      name: this.getLang().SERVICES.thisWeek,
      id: 3
    }]
  }

  /**
   * 
   * @param getFinancialYear
   */
  static financialYear() {
    let finYear;
    let  today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      finYear = (today.getFullYear() - 1);
    } else {
      finYear = today.getFullYear();
    }
    return finYear;
}
  /**
   * Print data
   */
  static printData(data) {
    let printContents, popupWin;
    printContents = data;
    popupWin = window.open('', '_blank', 'resizable=yes,left=0,top=0,width=1200,height=600,toolbar=0,scrollbars=yes,status=0');
    if (popupWin && popupWin.document) {
      popupWin.document.write(`
      <html>
        <head>
          <style>
            body,html{
              overflow:auto;
            }
          </style>
          <title>Print</title>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
        </html>`
      );
      popupWin.document.close();
    }
    else {
      alert('Please allow popup');
    }
  }

  static getSalesAppData() {
    if (localStorage.getItem('salesappData') && JSON.parse(localStorage.getItem('salesappData')) && JSON.parse(localStorage.getItem('salesappData')).sales) {
      return JSON.parse(localStorage.getItem('salesappData')).sales;
    } else {
      return '';
    }
  }

    /**
    * Get different hours between two days
    */
   static diff_hours(dt1,dt2) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }
}



    export class VoucherAccDetail {
        GL_CODE: string;
        Gen_Desc: string;
        SL_CODE: string;
        Sub_Desc:string;
        ACC_VALUE: string;
        VCHR_VALUE:string;
        CREDIT_LIMIT_TYPE: string;
        DEBIT_CREDIT: string;
        IS_DEBIT: any;
    }

    export class LedgerEntryDet {
        DEALER_ID: string;
        VOUCHER_DATE: any;
        FIN_YEAR: any;
        VOUCHER_SUB_TYPE: string;
        PARTY_CAT: string;
        PARTY_CODE: string;
        VOUCHER_STATUS: string;
        NARRATION: string;
        DOC_ID: string;
        payment_mode_id: string;
        VOUCHER_TYPE: string;
        COMPANY_ID: any;
        bank_id?: any;
        GL_CODE: string;
        Gen_Desc: string;
        SL_CODE: string;
        Sub_Desc: string;
        VCHR_VALUE: string;
        IS_DEBIT: string;
    }

    export class PaymentVoucher {
        DEALER_ID: number;
        BRANCH_ID: number;
        VOUCHER_ID: number;
        VOUCHER_NO: number;
        CREATED_BY: string;
        VOUCHER_DT: any;
        VCHR_TYPE_ID: string;
        VCHR_VALUE: number;
        VCHR_STATUS: number;
        VCHR_NO:number;
        FIN_YEAR: string;
        COMPANY_ID: string;
        company_name:any;
        PAYMENT_MODE_ID: string;
        DOCUMENT_ID: string;
        DOC_TYPE: number;
        DOC_DATE: any;
        BASE_DOC_TYPE: string;
        PARTY_CODE: string;
        PARTY_NAME: string;
        INSTR_NO?: any;
        INSTR_DATE?: any;
        INSTR_AMT?: any;
        INSTRUMENT_ON?: any;
        ACCOUNT_NO?: any;
        PARTY_CAT: string;
        CRED_CARD_TY_ID?: any;
        CRED_CARD_EXP_DT?: any;
        APPROVAL_NO?: any;
        BANK_BRANCH?: any;
        BANK_ID?: any;
        BASE_VOUCHER_ID?: any;
        NARRATION: string;
        UNRECON_VAL: string;
        CRED_LMT_TYPE: number;
        TDS_APPLIED: string;
        ACTIVE: string;
        DR:number;
        CR:number;
        CREDIT_LIMIT_TYPE:string;
        VoucherAccDetail: VoucherAccDetail[];
        LedgerEntryDet: LedgerEntryDet[];
    }



import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/api-services/master.service';

@Component({
  selector: 'app-spares-grn-payment',
  templateUrl: './spares-grn-payment.component.html',
  styleUrls: ['./spares-grn-payment.component.scss']
})
export class SparesGrnPaymentComponent implements OnInit {

  public lang: any;
  public selected = [];
  public isActive: any;
  public inlineFilter: any = {};
  public apsGrn: any = {};
  public vendorLists: any = [];
  public vendorRows: any = [];
  public maxDate:any = new Date();
  public sparesGRNPaymentLists: any = [];
  public isShowPageLoader: boolean;
  public selectedVendorList :any=[];

  constructor(
    private dialogRef: MatDialogRef<SparesGrnPaymentComponent>,
    private masterService: MasterService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }
  }
//   $scope.$on('$viewContentLoaded', function() {
//     //call it here
// });
  ngOnInit() {   
    {
      let reqObj: any = {};
      // if(!this.apsGrn.APS_Code){
      // this.toastr.error('Vendor name is empty');
      // return;
      // }
      reqObj.dealerId = CommonService.getUserData().DEALER_ID;      
      reqObj.branchId = CommonService.getUserData().BRANCH_ID;
      reqObj.vendorId = CommonService.getUserData().vendorId;
      reqObj.vendorName="";
      reqObj.vendorTypeId=4;
      reqObj.active=true;
      reqObj.APS_Code = this.apsGrn.APS_Code;
      reqObj.invoiceNo = this.apsGrn.invoiceNo;
      reqObj.fromdate = this.apsGrn.fromdate ? CommonService.getReqDateFormat(this.apsGrn.fromdate):'';
      reqObj.todate =  this.apsGrn.todate ? CommonService.getReqDateFormat(this.apsGrn.todate):'';
      // reqObj = Object.assign(reqObj, this.apsGrn);
      for (const key in reqObj) {
        if (reqObj[key] == '') {
          delete reqObj[key];
        }
      }
      this.isShowPageLoader = true;
      this.masterService.post(reqObj, '/DMSMaster/VendorMaster/SearchVendor').subscribe(
        (resp: any) => {
         
          if (resp && resp.statusCode == 200) {
            this.isShowPageLoader = false;
            this.vendorLists = resp.vendorList ;
          
          } 
          else if (resp && resp.statusCode == 204) {
            this.vendorLists = [];
            this.isShowPageLoader = false;
            return this.toastr.error(resp.statusMessage);
          }
          else {
            this.isShowPageLoader = false;
          }
        }, error => {
          this.isShowPageLoader = false;
          this.toastr.error(error.error.Message);
        })
      }
    if (this.data) {
      this.vendorLists = this.data.vendorList && this.data.vendorList.length > 0 ? this.data.vendorList : [];
      this.vendorLists.forEach(value => {
        if(this.data.vendorName == value) {
          this.apsGrn.APS_Code = value.APS_Code;
        }
      });
      
    }
    this.inlineFilter = {
      SPARE_GRN_NO:'',
      GRN_VALUE:'',
      INVOICE_NO:'',
      VENDOR_NAME:'',
    };
    
  }

  apsGrnSearch() {
    let reqObj: any = {};
    // if(!this.apsGrn.APS_Code){
    // this.toastr.error('Vendor name is empty');
    // return;
    // }
    reqObj.DEALER_ID = CommonService.getUserData().DEALER_ID;
    reqObj.BRANCH_ID = CommonService.getUserData().BRANCH_ID;
    reqObj.invoiceNo = this.apsGrn.invoiceNo;
    reqObj.VENDOR_NAME= (<HTMLSelectElement>document.getElementById('vendorname')).value;
    reqObj.COMPANY_ID=0;
    reqObj.GRN_VALUE=0;
    reqObj.INVOICE_NO=0;
    reqObj.SPARE_GRN_ID=0;
    reqObj.ACCT_SRCH_TVS=0;
    reqObj.SPARE_GRN_NO=0;
    reqObj.VENDOR_NAME="";
    reqObj.FIN_YEAR=CommonService.getDealerData().FIN_YEAR;
    reqObj.fromdate = this.apsGrn.fromdate ? CommonService.getReqDateFormat(this.apsGrn.fromdate):'';
    reqObj.todate =  this.apsGrn.todate ? CommonService.getReqDateFormat(this.apsGrn.todate):'';
    // reqObj = Object.assign(reqObj, this.apsGrn);
    // for (const key in reqObj) {
    //   if (reqObj[key] == '') {
    //     delete reqObj[key];
    //   }
    // }
    this.isShowPageLoader = true;
    this.masterService.post(reqObj, '/Accounts/SearchSparesGRNPaymentList').subscribe(
      (resp: any) => {
        if (resp && resp.statusCode == 200) {
          this.isShowPageLoader = false;
          this.sparesGRNPaymentLists = resp.sparesGRNPaymentList ;
        
        } 
        else if (resp && resp.statusCode == 204) {
          this.vendorLists=[];
          this.sparesGRNPaymentLists = [];
          this.isShowPageLoader = false;
          return this.toastr.error(resp.statusMessage);
        }
        else {
          this.isShowPageLoader = false;
        }
      }, error => {
        this.isShowPageLoader = false;
        this.toastr.error(error.error.Message);
      })
     
    }
  selectedItem() {
      if (this.selected[0].STATUS == 2 || this.selected[0].STATUS == 3) {
      this.toastr.error('GRN is completed.Please select the another invoice...');
      return;
    }
    let closeObj = { obj: this.selected[0]};
    this.dialogRef.close(closeObj);
  }

  filterByName(value, prop) {
    if (prop in this.inlineFilter) {
      this.inlineFilter[prop] = value;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}

// /**
//  * Created BY HMSPL 
//  * search
//  * Last Modified By Premleo on 24/02/2020
//  * Last Modified Description : Created pipe for local search
//  */
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'LockFilter'
})
export class SearchPipe implements PipeTransform {
    transform(value: any, args?: any, property?: string): any {
        if(!value)return null;
        if(!args)return value;
        args = args.toLowerCase();
        if(property){
            return value.filter(function(item){
                return JSON.stringify(item[property]).toLowerCase().includes(args);
            }); 
        }
        else{
            return value.filter(function(item){
                return JSON.stringify(item).toLowerCase().includes(args);
            });
        }
       
    }
}

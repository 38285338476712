import { Component, Inject, OnInit } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import {ToastrService} from 'ngx-toastr';
import {MasterDataService} from '../.././api-services/master-data.service';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-search-sales-person',
  templateUrl: './search-sales-person.component.html',
  styleUrls: ['./search-sales-person.component.scss']
})
export class SearchSalesPersonComponent implements OnInit {
// Class Variables 
public lang : any;
public empGroupList =[];
public empSubGroupList=[];
public salesPersonList=[];
public selected=[];
public isShowPageLoader:boolean=false;
public search:any={
'EMPLOYEE_ID':'',
'NAME':'',
'EMP_GROUP_ID':0,
'EMP_SUBGRP_ID':0
};

//

selectedItem()
{
   let closeObj= {obj:this.selected[0]};
   this.dialogRef.close(closeObj);
    
}

checkValid()
{
  if(this.search.EMPLOYEE_ID || this.search.NAME || this.search.EMP_GROUP_ID !=0 || this.search.EMP_SUBGRP_ID !=0)
     return false;
     else
     return true;

}
validateSearchDetails()
{
  if(this.search.EMPLOYEE_ID =='' && this.search.NAME =='' && this.search.EMP_GROUP_ID ==0 && this.search.EMP_SUBGRP_ID ==0)
     return false;
  else 
     return true;
     
}

  constructor(private toastrService:ToastrService,private masterDataService:MasterDataService,
              private  dialogRef:MatDialogRef<SearchSalesPersonComponent>,@Inject (MAT_DIALOG_DATA) public data:any) { }

  pageLoadFunctions()
  {
    this.getMasterData();
    this.getColumnsOfTable();
    //this.validateSearchDetails();
  }

  getMasterData()
  {
    this.getEmployeeGroup();
    this.getEmployeeSubGroup();
  }
  getEmployeeSubGroup()
  {
    this.empSubGroupList=CommonService.getSalesAppData().employeeSubGroupList;
    
  }
  getEmployeeGroup()
  {
    this.empGroupList=CommonService.getSalesAppData().employeeGroupList;
  }
  ngOnInit() {
    if(CommonService.getLang())
        this.lang=CommonService.getLang();

    this.pageLoadFunctions();
     
  }

  getColumnsOfTable()
  {
    this.data = {
      columns: [
        { name: this.lang.MASTER.employeeId, prop: 'EMPLOYEE_ID' },
        { name: this.lang.SALES.empName, prop: 'NAME' },
        { name: this.lang.SALES.employeeSubGroup, prop: 'DESCRIPTION' },
        { name: this.lang.SALES.employeeGroup, prop: 'GROUP_DESC' },
      
      ]
    };
  }

  getSalesPerson()
  {
    if(this.validateSearchDetails)
      {
        this.isShowPageLoader=true;
        let reqParam={};
        reqParam={
         'DEALER_ID':CommonService.getDealerData().DEALER_ID,
         'BRANCH_ID':CommonService.getDealerData().BRANCH_ID,
         'EMPLOYEE_ID':this.search.EMPLOYEE_ID == ''?0:this.search.EMPLOYEE_ID,
         'NAME':this.search.NAME,
         'EMP_GROUP_ID':this.search.EMP_GROUP_ID,
         'EMP_SUBGRP_ID':this.search.EMP_SUBGRP_ID,
         'ACTIVE':true
        };
        this.masterDataService.getSalesPersonSearchDetail(reqParam).subscribe((resp:any) => {
         
          if(resp !=null && resp.statusCode==200)
          {
             if(resp.data !=null && resp.data.length >0)
             {
                 this.data.rows=[];
                 this.data.rows=resp.data;
                 this.isShowPageLoader=false;
             
             }
             else if(resp.data.length ==0)
             {
                 this.isShowPageLoader=false;
                 return ;
             }
             else if(resp.data == null)
             {
              this.isShowPageLoader=false;
              return this.toastrService.warning(resp.Message);
             }

          }
          else if(resp.statusCode==500)
          {

            this.isShowPageLoader=false;
            return this.toastrService.error(resp.Message);
              
          }
         
        })
       
      }
    else 
      return this.toastrService.warning('Please enter atleast one search criteria to proceed');
   
  }

  closeDialog()
  {
    this.dialogRef.close();
  }

}

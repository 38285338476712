import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
@Component({
  selector: 'app-pndeow-display',
  templateUrl: './pndeow-display.component.html',
  styleUrls: ['./pndeow-display.component.scss']
})
export class PndeowDisplayComponent implements OnInit {
  public isShowPageLoader: boolean = false;
  approvalClaim = {
    //Pick up details
    "STAFF_NAME": this.data.STAFF_NAME,
    "VEHICLE_DETAILS": this.data.VEHICLE_DETAILS,
    "REG_NO": this.data.REG_NO,
    "DISP_START_TIME": this.data.DISP_START_TIME,
    "MOBILE_NO": this.data.MOBILE_NO,
    "WORK_SHOP_MANAGER_NAME": this.data.WORK_SHOP_MANAGER_NAME,
    "WORK_SHOP_MANAGER_MOBILE": this.data.WORK_SHOP_MANAGER_MOBILE,
    "IS_AGENCY": this.data.IS_AGENCY,
    "IS_ALLOCATED": this.data.IS_ALLOCATED,
    //Drop Details
    "DROP_STAFF_NAME": this.data.DROP_STAFF_NAME,
    "DROP_VEHICLE_DETAILS": this.data.DROP_VEHICLE_DETAILS,
    "DROP_REG_NO": this.data.DROP_REG_NO,
    "DISP_DROP_START_TIME": this.data.DISP_DROP_START_TIME,
    "DROP_MOBILE_NO": this.data.DROP_MOBILE_NO,
    "DROP_WORK_SHOP_MANAGER_NAME": this.data.DROP_WORK_SHOP_MANAGER_NAME,
    "DROP_WORK_SHOP_MANAGER_MOBILE": this.data.DROP_WORK_SHOP_MANAGER_MOBILE,
    "DROP_IS_AGENCY": this.data.DROP_IS_AGENCY,
    "IS_DROP_INITIATED": this.data.IS_DROP_INITIATED,
  };
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    public dialogRef: MatDialogRef<PndeowDisplayComponent>,
  ) {

  }

  ngOnInit() {
  }

  ClosePage() {
    this.dialogRef.close(false);
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from 'src/app/api-services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterSales } from 'src/app/api-services/master.sales';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insurance-policy',
  templateUrl: './insurance-policy.component.html',
  styleUrls: ['./insurance-policy.component.scss'],
})
export class InsurancePolicyComponent implements OnInit {
  public lang: any;
  public isActive: any;
  public maleList = [];
  public femaleList = [];
  public nomineeList = [];
  public nomineeType: any;
  public gender: any;
  public nomineeName: any;
  public nomineeAge: any;
  public isShowPageLoader = false;
  public invoiceID: any;
  public VehicleID: any;
  public custtypeID: any;

  constructor(
    private dialogRef: MatDialogRef<InsurancePolicyComponent>,
    private toastr: ToastrService,
    private masterSales: MasterSales,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.invoiceID = this.data.vehInvoiceID;
      this.VehicleID = this.data.vehicleID;
      this.custtypeID = this.data.custTypeID;
    }
  }

  ngOnInit() {
    if (CommonService.getLang()) {
      this.lang = CommonService.getLang();
    }

    if (CommonService.getSalesAppData()) {
        this.maleList = CommonService.getSalesAppData().maleNominees;
        this.femaleList = CommonService.getSalesAppData().femaleNominees;
        this.nomineeType = -1;
      }
  }

  changeNomineeType() {
    if (this.gender === 1) {
      this.nomineeList = this.maleList;
      this.nomineeType = -1;
    } else if (this.gender === 2) {
      this.nomineeList = this.femaleList;
      this.nomineeType = -1;
    }
  }

  createInsurancePolicy() {
    if (this.validation()) {
        this.isShowPageLoader = true;
        let params = new HttpParams();
        params = params
        .set('DEALER_ID', CommonService.getUserData().DEALER_ID)
        .set('BRANCH_ID', CommonService.getUserData().BRANCH_ID)
        .set('COUNTRY_CODE', CommonService.getDealerData().COUNTRY_CODE)
        .set('UserID', CommonService.getUserData().USER_ID)
        .set('InvoiceID', this.invoiceID)
        .set('NomineeName', this.nomineeName)
        .set('CUST_TY_ID', this.custtypeID)
        .set('gender', this.gender)
        .set('NomineeAge', this.nomineeAge)
        .set('relation', this.nomineeType)
        .set('VehicleId', this.VehicleID);
      this.masterSales
        .get(params, '/Sales/CreateInsurancePolicy')
        .subscribe(
          (resp: any) => {
            if (resp && resp.statusCode === 200 && resp.data && resp.message === 'Success') {
              if (resp.data.SuccessMsg != null && resp.data.SuccessMsg !== '') {
                this.toastr.success(resp.data.SuccessMsg);
                this.closeDialog();
              } else if (resp.data.FailedMsg != null && resp.data.FailedMsg !== '') {
                this.toastr.error(resp.data.FailedMsg);
                this.closeDialog();
              }
              this.closeDialog();
            } else {
              this.toastr.error(resp.message);
              this.closeDialog();
            }
            this.isShowPageLoader = false;
          },
          (error) => {
            this.toastr.error(error.error.Message);
            this.isShowPageLoader = false;
          }
        );
    }
  }

  validation() {
    let custError: any = 0;
    if (this.nomineeName == null || this.nomineeName == '') {
      this.toastr.warning('Please enter the NomineeName');
      custError++;
    }
    if (this.nomineeAge == null || this.nomineeAge == '' || this.nomineeAge == 0) {
      this.toastr.warning('Please enter the NomineenAge');
      custError++;
    }
    if (this.gender == null || this.gender == '') {
      this.toastr.warning('Please select the Gender');
      custError++;
    }
    if (this.nomineeType == '-1' || this.nomineeType == '') {
      this.toastr.warning('Please select the Nominee Relationship');
      custError++;
    }
    if (custError > 0) {
      return false;
    } else {
      return true;
    }
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
